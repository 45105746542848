import TableCell from '@material-ui/core/TableCell';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import PrintableComponent from '~/components/PrintableComponent';
import ProductSoftwareService from '~/services/product-software-service';
import LicenseLogsService from '~/services/license-logs-service';
import { showMessageError } from '~/util/errorutils';
import {
  formatCpfCnpj,
  inputNumber,
  inputVersion,
  unFormatCpfCnpj,
} from '~/util/stringutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';
import SearchComponent from './SearchComponent';
import * as S from './styles';

function MonitorKey() {
  const [loading, setLoading] = useState(false);
  const [inputCpfCnpj, setInputCpfCnpj] = useState('');
  const [inputRazaoSocial, setInputRazaoSocial] = useState('');
  const [inputSoftware, setInputSoftware] = useState('');
  const [inputSerie, setInputSerie] = useState('');
  const [inputVersao, setInputVersao] = useState('');
  const [inputControle, setInputControle] = useState('');
  const [inputResponsavel, setInputResponsavel] = useState('');
  const [inputDataInicio, setInputDataInicio] = useState('');
  const [inputDataFim, setInputDataFim] = useState('');
  const [softwares, setSoftwares] = useState([]);
  const [searchResponse, setSearchResponse] = useState([]);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    cpfCnpj: '',
    razaoSocial: '',
    software: '',
    serie: '',
    versao: '',
    controle: '',
    responsavel: '',
    dataInicio: '',
    dataFim: '',
  });

  let PrintableElement = React.useRef();

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      try {
        const [softwares] = await Promise.all([
          ProductSoftwareService.getProductSoftwares({}),
          findAllByFilter(pageParameters),
        ]);

        setSoftwares(softwares.resultado);
      } catch (error) {
        setSoftwares([]);
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInputCpfCnpjChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputCpfCnpj(value);
  }

  function handleInputRazaoSocialChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputRazaoSocial(value);
  }

  function handleInputSoftwareChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputSoftware(value);
  }

  function handleInputSerieChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputSerie(inputNumber(value));
  }

  function handleInputVersaoChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputVersao(inputVersion(value));
  }

  function handleInputControleChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputControle(inputNumber(value));
  }

  function handleInputResponsavelChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputResponsavel(value);
  }

  function handleInputDataInicioChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputDataInicio(value);
  }

  function handleInputDataFimChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputDataFim(value);
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};

    if (inputCpfCnpj) {
      data.cpfCnpj = unFormatCpfCnpj(inputCpfCnpj.trim());
      count += 1;
    }

    if (inputRazaoSocial) {
      data.razaoSocial = inputRazaoSocial.trim();
      count += 1;
    }

    if (inputSoftware && inputSoftware !== '0') {
      const [software] = softwares.filter(software => {
        return software.id === parseInt(inputSoftware);
      });

      if (software?.nome) {
        data.software = software.nome;
        count += 1;
      }
    }

    if (inputSerie) {
      data.serie = inputSerie.trim();
      count += 1;
    }

    if (inputVersao) {
      data.versao = inputVersao.trim();
      count += 1;
    }

    if (inputControle) {
      data.controle = inputControle.trim();
      count += 1;
    }

    if (inputResponsavel) {
      data.responsavel = inputResponsavel.trim();
      count += 1;
    }

    if (inputDataInicio) {
      data.dataInicio = inputDataInicio.trim();
      count += 1;
    }

    if (inputDataFim) {
      data.dataFim = inputDataFim.trim();
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    await success(data);
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  async function findAllByFilterReport(formDataParam) {
    setLoading(true);
    try {
      const response = await LicenseLogsService.getLogsChave({
        ...(formData || formDataParam),
      });
      if (response.resultado.length) {
        handleSetSearchResponseReport(response.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos Logs de chave para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams, formDataParams) {
    setPageParams(pageParams);
    setFormData(formDataParams);
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const response = await LicenseLogsService.getLogsChave({
        ...(formDataParams || formData),
        limit: page * rowsPerPage + rowsPerPage,
        offset: page * rowsPerPage,
      });

      if (response.resultado.length) {
        handleSetSearchResponse(response.resultado, response.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos Logs de chave para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handlePrintSoftware(softwareId) {
    const [software] = softwares.filter(software => {
      return software.id === parseInt(softwareId);
    });

    return software && software.nome;
  }

  function handleSubmit(event) {
    event.preventDefault();

    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  const renderRowComponent = (row) => {
    let chave;
    let keyPart1;
    let keyPart2;

    const isKeyValid = () => {
      return row.chave && row.chave !== 'invalid signature';
    };

    if (isKeyValid()) {
      chave = row.chave.split('.');
      keyPart1 = `${chave[0]}.${chave[1]}.${chave[2]}.${chave[3]}.`;
      keyPart2 = `${chave[4]}.${chave[5]}.${chave[6]}`;
    }

    const nomeCliente = row.clienteNome ? row.clienteNome : '---';
    return [
      <TableCell key='row_monitor_key_0'>
        <S.TableCellContent>
          <S.TableCellValue>
            <span title={nomeCliente}>{nomeCliente}</span>
            <br />
            <small>
              {row.cpfCnpj ? formatCpfCnpj(row.cpfCnpj) : '---'}
            </small>
          </S.TableCellValue>
        </S.TableCellContent>
      </TableCell>,
      <TableCell key='row_monitor_key_1'>
        {row.softwareNome ? row.softwareNome : '---'}
      </TableCell>,
      <TableCell key='row_monitor_key_2'>
        {row.serieVersaoControle ? row.serieVersaoControle : '---'}
      </TableCell>,
      <TableCell key='row_monitor_key_3'>
        {isKeyValid() ? (
          <small>
            <div>{keyPart1}</div>
            <div>{keyPart2}</div>
          </small>
        ) : '---'}
      </TableCell>,
      <TableCell key='row_monitor_key_4'>
        {row.geradoEm ? row.geradoEm : '---'}
      </TableCell>,
    ];
  };

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Monitorar Chaves"
        handlePageUpdate={() => findAllByFilterReport(pageParameters)}
        filters={[
          { name: 'CPF/CNPJ', value: inputCpfCnpj },
          { name: 'Razão Social', value: inputRazaoSocial },
          { name: 'Software', value: handlePrintSoftware(inputSoftware) },
          { name: 'Série', value: inputSerie },
          { name: 'Controle', value: inputControle },
          { name: 'Responsavel', value: inputResponsavel },
          { name: 'De', value: inputDataInicio },
          { name: 'Até', value: inputDataFim },
        ]}
        headerLabels={[
          { text: 'Cliente', width: '30%' },
          { text: 'Software', width: '13%' },
          { text: 'Série/Versão/Controle', width: '13%' },
          { text: 'Chave', width: '20%' },
          { text: 'Gerado em', width: 'auto' },
        ]}
        dataObjects={searchResponseReport}
        renderRowComponent={renderRowComponent}
      />
      <>
        <Modal loading show={loading} />
        <SidebarPageHeader
          mainMenu="Licença"
          pageName="Monitorar Chaves"
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await validateForm(async (data) => {
              await findAllByFilterReport(pageParameters, data);
            });
          }}
        />
        <SearchComponent
          countFilter={countFilter}
          handleSubmit={handleSubmit}
          softwares={softwares}
          cpfCnpjValue={inputCpfCnpj}
          razaoSocialValue={inputRazaoSocial}
          serieValue={inputSerie}
          softwareValue={inputSoftware}
          versaoValue={inputVersao}
          controleValue={inputControle}
          responsavelValue={inputResponsavel}
          dataInicioValue={inputDataInicio}
          dataFimValue={inputDataFim}
          handleCpfCnpjChange={handleInputCpfCnpjChange}
          handleRazaoSocialChange={handleInputRazaoSocialChange}
          handleSerieChange={handleInputSerieChange}
          handleSoftwareChange={handleInputSoftwareChange}
          handleVersaoChange={handleInputVersaoChange}
          handleControleChange={handleInputControleChange}
          handleResponsavelChange={handleInputResponsavelChange}
          handleDataInicioChange={handleInputDataInicioChange}
          handleDataFimChange={handleInputDataFimChange}
        />
        <TableComponent
          headerLabels={[
            { text: 'Cliente', width: '30%' },
            { text: 'Software', width: '13%' },
            { text: 'Série/Versão/Controle', width: '13%' },
            { text: 'Chave', width: '20%' },
            { text: 'Gerado em', width: 'auto' },
          ]}
          dataObjects={searchResponse}
          renderRowComponent={renderRowComponent}
          useCustomActions
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
        />
      </>
    </>
  );
}

MonitorKey.propTypes = {};

export default MonitorKey;
