import styled from 'styled-components';

export const Container = styled.div`
`;

export const ImageContent = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px !important;
  
  img {
    height: 200px;
    width: 200px;
    border-radius: 100%;
    border: 2x solid #ccc;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  }

  span {
    font-size: 12px;
  }
`;

export const FormContent = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  padding: 0 25px !important;
  
  @media (max-width: 450px) {
    width: 100%;
  }

  span {
    font-size: 16px !important;
  }
`;

export const Form = styled.form`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  
  @media (max-width: 450px) {
    width: 100%;
    flex-direction: column;
  }
  
  span {
    color: #707070;
  }
`;

export const ButtonContainer = styled.div`
  padding: 2% 0px;
  display: flex;
  justify-content: flex-end;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #c9c7c7;
  border-style: solid;
`;

export const SubmitButton = styled.button`
  padding: 12px 30px;
  border-radius: 4px;
  border-color: #b1aeae;
  border-width: 1px;
  margin: 0px 5px;
  font-size: 16px;
  background-color: #eaeaea;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.16);
  @media (max-width: 450px) {
    font-size: 12px;
    padding: 6px 15px;
  }
`;

export const ResultsTable = styled.table`
  border-collapse: collapse;
  margin: 5px 0px;

  tr {
    th {
      box-shadow: 0 1px 6px rgb(0, 0, 0, 0.16);
      background: white;
      width: 100%;
      color: #979797;
      font-weight: normal;
      font-size: 16px;
      text-align: start;
      padding: 15px 22px;

      @media (max-width: 450px) {
        font-size: 14px;
        padding: 6px 15px;
      }
    }
    th:first-child {
      border-right: 1px solid #cccccc;
    }

    td {
      :first-child {
        padding: 15px 22px;
        @media (max-width: 450px) {
          padding: 6px 15px;
        }
      }
      padding: 10px;
      text-align: start;
      border-bottom: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
      color: #979797;
      font-size: 15px;
      @media (max-width: 450px) {
        padding: 6px;
        font-size: 13px;
      }
    }

    td:last-child {
      border-right: 0px;
    }
  }
`;
