import { Fragment } from 'react';
import styled from 'styled-components';
import {
  MdDragHandle,
  MdShortText,
  MdSubject,
  MdRadioButtonChecked,
  MdCheckBox,
} from 'react-icons/md';

export const FormMultipleOptionsContainer = Fragment;

export const FormItemType = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 10px;
`;

export const ContainerTextInputs = styled.div`
  padding: 0 10px;
`;

export const DragIcon = styled(MdDragHandle)`
  width: 30px;
  height: 30px;
  color: #0000008A;
  align-self: center;
`;

export const ShortTextIcon = styled(MdShortText)`
  width: 20px;
  height: 20px;
  color: #0000008A;
  align-self: center;
`;

export const SubjectIcon = styled(MdSubject)`
  width: 20px;
  height: 20px;
  color: #0000008A;
  align-self: center;
`;

export const RadioButtonIcon = styled(MdRadioButtonChecked)`
  width: 20px;
  height: 20px;
  color: #0000008A;
  align-self: center;
`;

export const CheckBoxIcon = styled(MdCheckBox)`
  width: 20px;
  height: 20px;
  color: #0000008A;
  align-self: center;
`;

export const FormActions = styled.div`
  color: var(--cm-primary);
  padding: 0 0 10px 10px;

  .disabled {
    color: #C4C4C4;

    cursor: not-allowed;
  }
`;

export const ButtonAdd = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  color: var(--cm-primary);
  text-decoration: underline;
`;

export const SelectOption = styled.div`
  display: flex;

  img {
    margin-right: 10px;
  }
`;
