import React from 'react';
import * as S from './styles';

export function Copyright() {
  return (
    <S.CopyrightContent>
      <small>
        &copy; 2003-2023 CASA MAGALHÃES, Inc. Todos os direitos reservados.
      </small>
    </S.CopyrightContent>
  );
}
