import React from 'react';
import * as S from './styles';

// eslint-disable-next-line react/prop-types
export function BackgroundLogin({ children }) {
  return (
    <S.BackgroundCover>
      {children}
    </S.BackgroundCover>
  );
}
