import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import permissions from '~/util/permissions';
import InputMask from 'react-input-mask';
import PersonEmployeeNew from '~/model/person-employee-new';
import PersonResaleService from '~/services/person-resale-service';
import { AdminGroupAccessService } from '~/services/admin-group-access-service';
import { InvitationService } from '~/services/person/invitation';
import { showMessageError } from '~/util/errorutils';
import { AutoCompleteOn } from '~/components/AutoCompleteOn';
import PageHeaderWithButtons from '~/components/PageHeaderWithButtons';
import {
  validaCpf,
  validateEmail,
  inputNumber,
  unFormatCpfCnpj,
  formatCpfCnpj,
  onlyText,
} from '~/util/stringutils';

class AddInvitationEmployee extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    user: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    const { history } = props;
    this.history = history;
    this.accessGroups = [];
    this.user = props.user;

    this.userCanEditChangeEmployee = props.permissions
      .includes(permissions['edit_mudanca-colaborador-revenda']);

    this.state = {
      loading: true,
      showSuggestions: false,
      timeout: null,
      inputResaleValue: '',
      resales: [],
      resale: {},

      // formData
      idRevenda: 0,
      inputCpfCnpj: '',
      inputNome: '',
      inputEmail: '',
      selectGrupo: 0,

      // errors
      error_grupoId: false,
      error_cpfCnpj: false,
      error_inputNome: false,
      error_email: false,
      error_revendaId: false,

      // errors message
      error_cpfCnpj_message: 'Campo obrigatório',
      error_email_message: 'Campo obrigatório',
      error_revendaId_message: 'Campo obrigatório',
    };
  }

  /* LifeCicle:: Inicializa quando monta a DOM */
  componentDidMount() {
    Promise.all([
      AdminGroupAccessService.getAccessGroup(''),
      AdminGroupAccessService.getGroup(this.user.grupo.id),
      PersonResaleService.getPersonResales({
        cpfCnpj: this.user.revenda.cpfCnpj,
      }),
    ])
      .then(values => {
        if (values.length) {
          const [allGroups, group, userResale] = values;

          if (allGroups.resultado.length) {
            const groupsIds = [...allGroups.resultado.map((_) => _.id)];
            const groups = [...allGroups.resultado];

            if ((!!group) && !groupsIds.includes(group.id)) {
              groups.push(group);
            }

            this.accessGroups.push(...groups);
          } else {
            toast('Não existe Grupo de Acesso cadastrado!', {
              type: toast.TYPE.ERROR,
            });
          }

          if (userResale.resultado.length) {
            const [resale] = userResale.resultado;

            this.handleVerifyRevenda(resale);
          }
        }
      })
      .catch(() => {
        toast('Erro ao carregar informações.', { type: toast.TYPE.ERROR });
        setTimeout(() => this.history.goBack(), 100);
      })
      .finally(() => this.setLoading(false));
  }

  setInputValue = b => this.setState({ inputResaleValue: b });

  setLoading = b => this.setState({ loading: b });

  setInputCpfCnpj = b => this.setState({ inputCpfCnpj: b });

  setInputNome = b => this.setState({ inputNome: b });

  setSelectGrupo = b => this.setState({ selectGrupo: b });

  setInputEmail = b => this.setState({ inputEmail: b });

  setRevendaId = b => this.setState({ idRevenda: b });

  handleSelectGrupoChange = event => {
    const {
      target: { value },
    } = event;
    this.setSelectGrupo(value);
    this.handleErrorsGrupoId(false);
  };

  handleInputCpfCnpjChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCpfCnpj(inputNumber(value));
    this.handleErrorsCpfCnpj(false, 'Campo obrigatório');
  };

  handleInputNomeChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputNome(value);
    this.handleErrorsInputNome(false);
  };

  handleInputEmailChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputEmail(value);
    this.handleErrorsEmail(false, 'Campo obrigatório');
  };

  handleErrorsGrupoId = error_grupoId => {
    this.setState({ error_grupoId });
  };

  handleErrorsCpfCnpj = (error_cpfCnpj, error_cpfCnpj_message) => {
    this.setState({ error_cpfCnpj, error_cpfCnpj_message });
  };

  handleErrorsInputNome = error_inputNome => {
    this.setState({ error_inputNome });
  };

  handleErrorsRevendaId = (error_revendaId) => {
    this.setState({ error_revendaId });
  }

  handleErrorsEmail = (error_email, error_email_message) => {
    this.setState({ error_email, error_email_message });
  };

  validateForm(success) {
    const formData = {};
    let errors_temp = false;

    if (!this.state.idRevenda && this.userCanEditChangeEmployee) {
      this.handleErrorsRevendaId(true);
      errors_temp = true;
    }

    if (!this.state.inputCpfCnpj || !this.state.inputCpfCnpj.length) {
      this.handleErrorsCpfCnpj(true, 'Campo obrigatório');
      errors_temp = true;
    } else {
      formData.cpfCnpj = unFormatCpfCnpj(this.state.inputCpfCnpj);

      if (formData.cpfCnpj.length === 11 && !validaCpf(formData.cpfCnpj)) {
        this.handleErrorsCpfCnpj(true, 'Digite um CPF válido');
        errors_temp = true;
      } else if (formData.cpfCnpj.length < 11) {
        this.handleErrorsCpfCnpj(true, 'Digite um CPF válido');
        errors_temp = true;
      }
    }

    if (!this.state.inputNome || !this.state.inputNome.length) {
      this.handleErrorsInputNome(true);
      errors_temp = true;
    }

    formData.nome = this.state.inputNome.trim();

    if (!this.state.selectGrupo) {
      this.handleErrorsGrupoId(true);
      errors_temp = true;
    } else {
      formData.grupoId = this.state.selectGrupo;
    }

    if (!this.state.inputEmail || !this.state.inputEmail.length) {
      this.handleErrorsEmail(true, 'Campo obrigatório');
      errors_temp = true;
    } else {
      formData.email = this.state.inputEmail.trim();
      if (!validateEmail(formData.email)) {
        this.handleErrorsEmail(true, 'Digite um email válido');
        errors_temp = true;
      }
    }

    if (errors_temp) {
      return;
    }

    const invite = { ...formData };
    const newFormData = new PersonEmployeeNew({
      ...formData,
      nomeSocial: formData.nome,
      revendaId: this.state.idRevenda,
    });

    success(invite, newFormData);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.validateForm(async (convite, usuarioParaCriar) => {
      this.setLoading(true);
      try {
        const result = await InvitationService
          .createInvitationEmployee(convite, usuarioParaCriar);
        toast(result.message, { type: toast.TYPE.SUCCESS });
        setTimeout(() => this.history.goBack(), 100);
      } catch (error) {
        showMessageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  };

  handleVerifyRevenda = resale => {
    if (resale) {
      this.setState({ resale });
      this.setRevendaId(resale.id);

      this.setInputValue(
        this.renderSuggestion(resale),
      );

      this.setLoading(true);
    }
  };

  handleAccessGroupResale = resale => {
    Promise.all([
      AdminGroupAccessService.getGroup(resale.grupoId),
      AdminGroupAccessService.getAccessGroup(null, { usuarioId: resale.usuarioId })
    ]).then((values) => {
      if (values.length) {
        const accessGroupsResale = [];
        const idsAdded = [values[0].id];
        accessGroupsResale.push(values[0]);

        if (values[1].resultado) {
          values[1].resultado.forEach(group => {
            if (!idsAdded.includes(group.id))
              accessGroupsResale.push(group);
          });
        }

        this.accessGroups = [];
        this.accessGroups.push(...accessGroupsResale);
      }
    })
      .catch(() => {
        toast('Erro ao carregar grupos de acesso', {
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => {
        this.setSelectGrupo('');
        this.setLoading(false);
      });
  }

  getResales = async (value) => {
    try {
      const data = await PersonResaleService.getPersonResales({
        cpfCnpj: unFormatCpfCnpj(value.trim().toLowerCase()) || null,
        nome: onlyText(value.trim().toLowerCase()) || null,
        offset: 0,
        limit: 0,
      });

      return data.resultado;
    } catch (error) {
      showMessageError(error);

      return [];
    }
  }

  getResalesChange = async (event) => {
    const {
      target: { value: valueToFind }
    } = event;

    if (valueToFind === '') {
      this.setState({
        resales: [],
        showSuggestions: false,
      });

      this.setRevendaId(0);
    } else {
      clearTimeout(this.state.timeout);

      const timer = setTimeout(() => {
        const asyncSetTimeOut = async () => {
          const resales = await this.getResales(valueToFind);
          if (resales.length) {
            this.setState({
              resales,
              showSuggestions: true,
            });
          } else {
            this.setState({
              resales: [],
              showSuggestions: true,
            });
          }
        };

        asyncSetTimeOut();
      }, 500); // 0.5 segundos

      this.setState({ timeout: timer });
    }

    this.setInputValue(valueToFind);
  };

  getSuggestionLabel = suggestion => {
    if (suggestion) {
      return this.renderSuggestion(suggestion);
    } else {
      return '';
    }
  }

  getSuggestionValue = suggestion => {
    if (suggestion) {
      return suggestion;
    } else {
      return '';
    }
  }

  getSuggestionKey = suggestion => {
    if (suggestion) {
      return suggestion.id;
    } else {
      return '';
    }
  }

  renderSuggestion = suggestion => {
    return `${formatCpfCnpj(suggestion.cpfCnpj)} - ${suggestion.nomeFantasia}`;
  }

  handleOnClick = suggestion => {
    const [resaleSelected] = this.state.resales.filter(
      suggItem => suggItem.id === suggestion.id
    );

    this.handleVerifyRevenda(resaleSelected);
    this.handleAccessGroupResale(resaleSelected);
    this.handleErrorsRevendaId(false);

    this.setState({ showSuggestions: false });
  }

  render() {
    const {
      showSuggestions,
      inputResaleValue,
      resales,
      loading,
      inputCpfCnpj,
      inputNome,
      inputEmail,
      selectGrupo,
    } = this.state;

    const {
      error_grupoId,
      error_cpfCnpj,
      error_inputNome,
      error_email,
      error_revendaId,
      error_cpfCnpj_message,
      error_email_message,
      error_revendaId_message,
    } = this.state;

    return (
      <div className="defaultFormContainer">
        <Modal show={loading} loading />
        <PageHeaderWithButtons
          mainMenu={'Convite'}
          pageName={'Novo Convite'}
          button1Label={'ENVIAR CONVITE'}
          button1Handle={this.handleSubmit}
          button2Label="CANCELAR"
          button2Handle={this.history.goBack}
        />
        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <div className="infoCard">
            <span id="title">Dados para convite</span>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex">
                <span>Nome Completo *</span>
                <input
                  type="text"
                  name="nome"
                  value={inputNome}
                  onChange={this.handleInputNomeChange}
                />
                {error_inputNome && (
                  <small style={{ color: 'red' }}>
                    Campo obrigatório
                  </small>
                )}
              </div>
              <div className="inputHolder noMarginRight">
                <span>CPF *</span>
                <InputMask
                  mask="999.999.999-99"
                  name="cpfCnpj"
                  value={inputCpfCnpj}
                  onChange={this.handleInputCpfCnpjChange}
                />
                {error_cpfCnpj && (
                  <small style={{ color: 'red' }}>
                    {error_cpfCnpj_message}
                  </small>
                )}
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex">
                <span>E-mail *</span>
                <input
                  type="text"
                  name="nome"
                  value={inputEmail}
                  onChange={this.handleInputEmailChange}
                />
                {error_email && (
                  <small style={{ color: 'red' }}>
                    {error_email_message}
                  </small>
                )}
              </div>
              <div className="inputHolder noMarginRight">
                <span>Grupo de Acesso *</span>
                <select
                  name="grupoId"
                  value={selectGrupo}
                  onChange={this.handleSelectGrupoChange}
                >
                  <option value={''}>Selecione</option>
                  {this.accessGroups.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </select>
                {error_grupoId && (
                  <small style={{ color: 'red' }}>
                    Campo obrigatório
                  </small>
                )}
              </div>
            </div>
          </div>
          {this.userCanEditChangeEmployee && (
            <div className="infoCard">
              <span id="title">Revenda</span>
              <div className="rowContainer">
                <div className="inputHolder defaultFlex noMarginRight">
                  <span>De qual revenda? *</span>
                  <span id="helpText">
                    Ao digitar, o campo irá auto completar. A busca da revenda
                    poderá ser feita pelo CNPJ ou Nome Fantasia.
                  </span>
                  <AutoCompleteOn
                    inputDataValue={inputResaleValue}
                    suggestions={resales}
                    isShowSuggestions={showSuggestions}
                    handleDataChange={this.getResalesChange}
                    handleGetSuggestionKey={this.getSuggestionKey}
                    handleGetSuggestionLabel={this.getSuggestionLabel}
                    handleOnClick={this.handleOnClick}
                    isDisabled={!this.userCanEditChangeEmployee}
                  />
                  {error_revendaId && (
                    <small style={{ color: 'red' }}>
                      {error_revendaId_message}
                    </small>
                  )}
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const getUser = state => state.user.data;
const getPermissions = state => state.user.data.permissions;

export default connect(state => ({
  user: getUser(state),
  permissions: getPermissions(state),
}))(AddInvitationEmployee);
