import { Fragment } from 'react';
import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const ViewerAnswerForm = Fragment;
export const ViewerClientResale = Fragment;
export const ViewerTitle = Fragment;
export const ViewerAnswerContainer = Fragment;
export const ViewerOptionContainer = Fragment;
export const ViewerDescriptionContainer = Fragment;

export const ErrorView = styled.small`
  color: red;
  margin-top: 5px;
`;

export const Container = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;
  min-width: 160px;

  span {
    color: var(--cm-primary);
  }

  input, textarea {
    height: 33px;
    border-radius: 6px;
    border: 1px solid #DADAE3;
    padding: 8px 15px;
    margin-top: 5px;
    margin-bottom: 0;
  }

  input:focus, textarea:focus {
    border: 1px solid var(--cm-primary);
  }

  input:disabled, textarea:disabled {
    background-color: transparent;
  }

  textarea {
    height: auto;
  }
`;

export const ViewerContainer = styled.div`
  background-color: #FFFFFF;
  border: 1px solid #C5C5D4;
  border-radius: 10px;
  padding: 24px;

  span.title-required {
    color: #FD1616;
  }

  form {
    background: none;
    margin: 0;
    padding: 0;
    border: none;
  }
`;

export const ViewerSoftwareModule = styled.div`
  display: flex;
  margin-bottom: 10px;
  color: var(--cm-primary);

  span + span {
    margin-left: 40px;
  }
`;

export const ViewerContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  max-width: 100%;
`;

export const ViewerImage = styled.div`
  display: flex;
  width: 100%;
  padding: 0 40px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};

  img {
    height: 76px;
    resize: contain;
  }
`;

export const ViewerTitle1 = styled.div`
  display: flex;
  justify-content: center;
  color: var(--cm-primary);
  padding: 0 40px;

  h1 {
    text-align: center;
  }
`;

export const ViewerTitle2 = styled.div`
  color: var(--cm-primary);
`;

export const ViewerDescription = styled.p`
  color: #444444;
  margin-top: 10px;
`;

export const ViewerAnswer = styled.b`
  color: #444444;
`;

export const ViewerOptions = styled.div`
  display: flex;
  flex-direction: column;

  input[type='radio'].viewer-option,
  input[type='checkbox'].viewer-option {
    width: 11px !important;
  }

  div {
    input[type='text'] {
      width: 100%;
    }
  }
`;

export const ViewerOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
`;

export const ViewerOption = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 25%;

  @media (max-width: 1050px) {
    width: 50%;
  }

  @media (max-width: 800px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  label {
    width: 100%;
    color: #444444;
  }
`;

export const ViewerOptionOther = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewerOther = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  label {
    color: #444444;
    margin-right: 20px;
  }
`;

export const ErrorField = styled.small`
  color: red;
  margin-top: 5px;
`;

export const ViewLogs = styled.section`
  margin-top: 30px;
  background-color: #F6F7FB;
  border: 1px solid #C5C5D4;
  padding: 8px;
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
    color: #444444;
  }

  header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #C5C5D4;

    div {
      display: flex;

      div {
        display: flex;
        flex-direction: column;
        margin-right: 20px;
      }
    }

    .statusFormularioResposta {
      display: flex;
      flex-direction: column;

      select {
        height: 33px;
        border-radius: 6px;
        border: 1px solid #DADAE3;
        padding: 0px 15px;
        margin-top: 5px;
      }
    }
  }

  main {
    label {
      color: #444444;
    }

    p {
      margin-top: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row;

      span {
        color: #444444;
        width: 95%;
      }

      svg {
        margin: 0 10px;
        width: 5px;
        height: 5px;
      }
    }
  }
`;

export const InputData = styled(InputMask)`
  height: 30px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 0 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  &::placeholder {
    color: #999;
  }
`;
