import { ATTEMPT_KEY } from './auth';

export const PASSWORD_ATTEMPTS = {
  KEY: ATTEMPT_KEY,
  MAXIMUM: 5,
  TIME: 60 * 1000, // 1m
};

export const passwordCriteria = [
  {
    type: 1,
    description: 'Ter no mínimo 10 caracteres no total.',
  },
  {
    type: 2,
    description: 'Conter pelo menos uma letra maiúscula.',
  },
  {
    type: 3,
    description: 'Conter pelo menos uma letra minúscula.',
  },
  {
    type: 4,
    description: 'Ter pelo menos um número.',
  },
  {
    type: 5,
    description: 'Ter pelo menos um caractere especial.',
  },
];

export const passwordCheck = (password) => {
  const checkPassword = password.trim();
  const errors = [];
  const verifications = {
    length: {
      minimum: 10,
      type: 1,
      message: 'A senha deve ter no mínimo 10 caracteres no total.',
    },
    upperCase: {
      regex: /[A-Z]/g,
      type: 2,
      message: 'A senha deve conter pelo menos uma letra maiúscula.',
    },
    lowerCase: {
      regex: /[a-z]/g,
      type: 3,
      message: 'A senha deve conter pelo menos uma letra minúscula.',
    },
    numbers: {
      regex: /\d/g,
      type: 4,
      message: 'A senha deve ter pelo menos um número.',
    },
    specials: {
      regex: /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
      type: 5,
      message: 'A senha deve ter pelo menos um caractere especial.',
    }
  };

  if (checkPassword.length < verifications.length.minimum) {
    errors.push(verifications.length);
  }

  if (!verifications.upperCase.regex.test(checkPassword)) {
    errors.push(verifications.upperCase);
  }

  if (!verifications.lowerCase.regex.test(checkPassword)) {
    errors.push(verifications.lowerCase);
  }

  if (!verifications.numbers.regex.test(checkPassword)) {
    errors.push(verifications.numbers);
  }

  if (!verifications.specials.regex.test(checkPassword)) {
    errors.push(verifications.specials);
  }

  return {
    success: !errors?.length,
    message: errors?.[0]?.message,
    errors
  };
};
