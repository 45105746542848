/* eslint-disable react/prop-types */
import React from 'react';
import { Container } from '../styles';

export default function License({ license }) {
  return (
    <Container>
      <div>
        <span>Licença:</span>
        <span>{license.serie}</span>
      </div>
      <div>
        <span>Software:</span>
        <span>{license.software}</span>
      </div>
      <div>
        <span>Tipo de Cancelamento:</span>
        <span>{license.tipo}</span>
      </div>
    </Container>
  );
}
