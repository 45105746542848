import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import PrintableComponent from '~/components/PrintableComponent';
import { productPaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import ProductModalityService from '~/services/product-modality-service';
import ProductModuleService from '~/services/product-module-service';
import ProductSoftwareService from '~/services/product-software-service';
import { showMessageError } from '~/util/errorutils';
import { inputNumber } from '~/util/stringutils';
import permissions from '~/util/permissions';
import { accessDenied } from '~/util/accessDenied';
import { StatusType } from '~/components/StatusType';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';
import SearchComponent from './SearchComponent';
import { useFitler } from '~/hook/useFilter';
import {
  CancelButton,
  Container,
  DeleteButton,
  ModalContainer,
} from './styles';
import './styles.scss';

function ProductModule({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(true);
  const [inputCodigo, setInputCodigo] = useState('');
  const [inputName, setInputName] = useState('');
  const [selectSoftware, setSelectSoftware] = useState('');
  const [selectModality, setSelectModality] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [productSoftwares, setProductSoftwares] = useState([]);
  const [productModalities, setProductModalities] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    id: '',
    text: '',
    moduleId: null,
    softwareId: null,
  });

  let PrintableElement = React.useRef();

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions.create_modulo),
    [userPermissions]
  );
  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions.edit_modulo),
    [userPermissions]
  );
  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions.delete_modulo),
    [userPermissions]
  );

  useEffect(() => {
    Promise.all([
      ProductSoftwareService.getProductSoftwares({}),
      ProductModalityService.getProductModalities({}),
      findAllByFilter(pageParameters, handleFilters()),
    ])
      .then(values => {
        if (values.length) {
          if (values[0].resultado.length) {
            setProductSoftwares(values[0].resultado);
          }
          if (values[1].resultado.length) {
            setProductModalities(values[1].resultado);
          }
        }
      })
      .catch(() => {
        toast('Erro ao consultar softwares e/ou modalidades para consulta.', {
          type: toast.TYPE.ERROR,
        });

        history.push(productPaths.productModule);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilters() {
    let count = 0;

    if (filters.id) {
      setInputCodigo(filters.id);
      count += 1;
    }
    if (filters.text) {
      setInputName(filters.text);
      count += 1;
    }
    if (filters.softwareId) {
      setSelectSoftware(filters.softwareId);
      count += 1;
    }
    if (filters.modalidadeId) {
      setSelectModality(filters.modalidadeId);
      count += 1;
    }

    setCountFilter(count);

    return filters;
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleInputCodigoChange(event) {
    const {
      target: { value },
    } = event;

    setInputCodigo(inputNumber(value));
  }

  function handleInputNameChange(event) {
    const {
      target: { value },
    } = event;

    setInputName(value);
  }

  function handleSelectSoftwareChange(event) {
    const {
      target: { value },
    } = event;

    setSelectSoftware(value);
  }

  function handleSelectModalityChange(event) {
    const {
      target: { value },
    } = event;

    setSelectModality(value);
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};
    if (inputCodigo) {
      data.id = inputCodigo.trim();
      count += 1;
    }
    if (inputName.length) {
      data.text = inputName.trim();
      count += 1;
    }
    if (selectSoftware.length) {
      data.softwareId = parseInt(selectSoftware, 10);
      count += 1;
    }
    if (selectModality.length) {
      data.modalidadeId = parseInt(selectModality, 10);
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    setFilters(data);
    await success(data);
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  async function findAllByFilterReport(pageParams, formDataParam) {
    setLoading(true);
    try {
      const data = await ProductModuleService.getProductModules({
        ...formDataParam,
      });
      if (data.resultado.length) {
        handleSetSearchResponseReport(data.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos produtos de softwares para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams, data) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const response = await ProductModuleService.getProductModules({
        ...(data || formData),
        limit: page * rowsPerPage + rowsPerPage,
        offset: page * rowsPerPage,
      });
      if (response.resultado.length) {
        handleSetSearchResponse(response.resultado, response.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos produtos de softwares para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  async function handleDelete(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const { data: response } = await api.delete(
        endpoints.product.module.deleteProductModule(deleteItem.id)
      );
      if (!response.data.ok) {
        toast('Erro ao deletar o módulo do produto!', {
          type: toast.TYPE.ERROR,
        });
      } else {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteItem.id
        );
        handleSetSearchResponse(newSearchResponse, totalItems - 1);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o módulo do produto!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  const renderRowComponent = (row) => {
    return [
      <TableCell key='row_module_1'>{row.id}</TableCell>,
      <TableCell key='row_module_2'>{row.nome}</TableCell>,
      <TableCell key='row_module_3' align="right">
        <CurrencyFormat
          value={row.precoAdesao ? row.precoAdesao : ''}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          prefix="R$ "
        />
      </TableCell>,
      <TableCell key='row_module_4' align="right">
        <CurrencyFormat
          value={row.precoMensal ? row.precoMensal : ''}
          displayType="text"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          prefix="R$ "
        />
      </TableCell>,
      <TableCell key='row_module_5' align="center">
        <StatusType ativo={row.ativo} />
      </TableCell>,
      <TableCell key='row_module_6' className="actionCell">
        {
          <div className="actionButtonsHolder">
            <FaRegEdit
              className="icon"
              disabled={!userCanEdit}
              onClick={() =>
                history.push(productPaths.editProductModule, {
                  id: row.id,
                })
              }
              title="Editar"
            />
            <hr />
            <FaRegTrashAlt
              className="icon"
              disabled={!userCanDelete}
              onClick={userCanDelete
                ? () => setDeleteItem(row)
                : () => accessDenied(history)
              }
              title="Deletar"
            />
          </div>
        }
      </TableCell>,
    ];
  };

  const renderCustomHeader = () => [
    <TableRow key='row_module_header_1'>
      <TableCell colSpan={2} />
      <TableCell colSpan={2} align="center">
        Preço
      </TableCell>
      <TableCell colSpan={2} />
    </TableRow>,
    <TableRow key='row_module_header_2'>
      <TableCell style={{ width: '8%' }}>Código</TableCell>
      <TableCell style={{ width: 'auto' }}>Produto</TableCell>
      <TableCell align="center" style={{ width: '10%' }}>
        Venda
      </TableCell>
      <TableCell align="center" style={{ width: '10%' }}>
        Manutenção
      </TableCell>
      <TableCell align="center" style={{ width: '120px' }}>
        Status
      </TableCell>
      <TableCell align="center" style={{ width: '10%' }}>
        Ação
      </TableCell>
    </TableRow>,
  ];

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Módulo"
        renderRowComponent={renderRowComponent}
        renderCustomHeader={renderCustomHeader}
        useCustomActions
        filters={[
          { name: 'Código', value: inputCodigo },
          { name: 'Produto', value: inputName },
          { name: 'Modalidade', value: selectModality },
          { name: 'Software', value: selectSoftware },
        ]}
        dataObjects={searchResponseReport}
      />
      <Container>
        <Modal loading={loading} show={loading || !!deleteItem}>
          <ModalContainer>
            <h2>Confirmar exclusão</h2>
            <span>
              Tem certeza que deseja deletar o módulo de produto:
              {` ${!!deleteItem &&
                ((!!deleteItem.nome && deleteItem.nome) || deleteItem.id)}?`}
            </span>
            <div>
              <CancelButton onClick={() => setDeleteItem(null)}>
                Fechar
              </CancelButton>
              <DeleteButton onClick={handleDelete}>Deletar</DeleteButton>
            </div>
          </ModalContainer>
        </Modal>

        <SidebarPageHeader
          mainMenu="Produto"
          pageName="Módulo"
          button1={{
            label: 'Novo Módulo',
            link: productPaths.addProductModule,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await validateForm(async data => {
              await findAllByFilterReport(pageParameters, data);
            });
          }}
          openButtonSelect
        />

        <SearchComponent
          countFilter={countFilter}
          codeValue={inputCodigo}
          nameValue={inputName}
          handleCodeChange={handleInputCodigoChange}
          handleNameChange={handleInputNameChange}
          handleSubmit={handleSubmit}
          selectedSoftware={selectSoftware}
          handleSoftwareChange={handleSelectSoftwareChange}
          softwares={productSoftwares}
          selectedModality={selectModality}
          handleModalityChange={handleSelectModalityChange}
          modalities={productModalities}
        />

        <TableComponent
          dataObjects={searchResponse}
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
          renderRowComponent={renderRowComponent}
          renderCustomHeader={renderCustomHeader}
          useCustomActions
        />
      </Container>
    </>
  );
}

ProductModule.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ProductModule;
