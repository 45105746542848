import api, { endpoints } from '~/services/api';

class LineBusinessService {
  static getLineBusiness = async (text, pager) => {
    const { data: response } = await api.post(
      endpoints.person.lineBusiness.getLineBusiness,
      pager
        ? {
          text: text,
          ...pager,
        }
        : {
          text: text,
        }
    );

    return response.data;
  };

  static createLineBusiness = async ({ nome }) => {
    const { data: response } = await api.post(
      endpoints.person.lineBusiness.createLineBusiness, { nome }
    );

    return response;
  }

  static updateLineBusiness = async ({ id, nome }) => {
    const { data: response } = await api.put(
      endpoints.person.lineBusiness.updateLineBusiness, { id, nome },
    );

    return response;
  }

  static deleteLineBusiness = async (id) => {
    const { data: response } = await api.delete(
      endpoints.person.lineBusiness.deleteLineBusiness(id),
    );

    return response;
  }
}

export { LineBusinessService };
