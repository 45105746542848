import styled from 'styled-components';

const getMinWidthIsCollapse = ({ isCollapse }) => isCollapse ? '250px' : '75px';
const getIsModalActive = ({ isModalActive }) => isModalActive ? 0 : 'none';

export const HeaderContainer = styled.div`
  flex-direction: column;
  display: flex;
  height: 100vh;
  max-width: 250px;
  width: ${getMinWidthIsCollapse};
  min-width: ${getMinWidthIsCollapse};
  z-index: ${getIsModalActive};
`;

export const HeaderImgMarca = styled.div`
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  height: 75px;
  width: ${getMinWidthIsCollapse};
  min-width: ${getMinWidthIsCollapse};
  max-width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cm-secondary);
  border: 1px solid var(--cm-primary-shade);

  img {
    height: 58px;
    cursor: pointer;
  }
`;

export const ProfileContainer = styled.div`
  z-index: 4;
  margin-top: 73px;
  height: 75px;
  width: ${getMinWidthIsCollapse};
  min-width: ${getMinWidthIsCollapse};
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--cm-primary);
  border: 1px solid var(--cm-primary-shade);
  color: var(--cm-primary-contrast);
  border-bottom: none;
`;

export const ProfileNav = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 75px;
`;

export const ProfileContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .picture {
    padding: 0 5px;

    img {
      height: 50px;
      width: 50px;
      border-radius: 100%;
      border: 2px solid var(--cm-secondary);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
    }

    span {
      font-size: 12px;
    }
  }

  .nameContainer {
    display: flex;
    flex-direction: column;
    align-self: center;
    margin: 0 10px;
    width: 140px;

    #name {
      min-width: 140px;
      max-width: 140px;
      font-weight: bold;
      font-size: 12pt;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    #type {
      color: var(--cm-tertiary-shade);
      font-size: 10pt;
    }
  }
`;

const getDisplayIsCollapse = ({ isCollapse }) => isCollapse ? 'flex' : 'none';

export const ProfileContentOptions = styled.div`
  display: ${getDisplayIsCollapse};
  align-items: center;
`;

const getZIndex = ({ isCollapse }) => isCollapse ? 4 : 0;

export const ProfileOptionsMenuContainer = styled.nav`
  z-index: ${getZIndex};
  background-color: var(--cm-primary);
  border: 1px solid var(--cm-primary-shade);
  color: var(--cm-primary-contrast);
  border-top: none;
  display: ${getDisplayIsCollapse};
  flex-direction: column;
  padding-bottom: 12px;
  gap: 10px;
`;

export const OptionMenu = styled.div`
  border: 1px solid var(--cm-primary);
  color: var(--cm-primary-contrast);
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 2px;
  margin: 0 16px;

  cursor: pointer;

  &:is(:hover, :focus) {
    border-radius: 12px;
    border: 1px solid var(--cm-primary-shade);
    background-color: var(--cm-primary-tint);
  }

  .option-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
  }

  span {
    font-size: 12pt;
  }
`;

export const NotificationContainer = styled.div`
  background-color: var(--cm-tertiary);
  color: var(--cm-tertiary-contrast);

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 0 12px 22px;
  border: 1px solid var(--cm-primary-shade);

  label {
    margin-left: 15px;
  }

  .option-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
`;
