import styled from 'styled-components';

export const AttachmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  height: 50px;
  padding: 0 8px;
  background-color: #FFFFFF;
  border: 1px solid var(--cm-primary);
  border-radius: 8px;
  margin-right: 10px;
  margin-top: 10px;

  span {
    color: #000000;
    text-decoration: none;
    max-width: 80%;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    cursor: default;
  }

  svg {
    color: #0000008A;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
`;
