import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import CurrencyFormat from 'react-currency-format';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  span.orderItemsTableHeader {
    color: var(--cm-primary);
    font-weight: 600;
    text-align: center;
  }
`;

export const TableCellContent = styled(TableCell)`
  background-color: #E4EAFC;

  span.title {
    color: black;
    font-weight: bold;
  }
`;

export const CurrencyFormatValue = styled(CurrencyFormat).attrs({
  displayType: 'text',
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  prefix: 'R$ ',
})``;
