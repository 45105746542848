import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import Modal from '~/components/Modal';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Steps } from './Steps';
import { Stepper } from '../Items/Stepper';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { commercialPaths } from '~/routes/paths';
import { USER_TYPE, FORM_FILLED_ENUM, PERSON_STATUS_ENUM } from '~/util/domainutils';
import ProductModalityService from '~/services/product-modality-service';
import ProductSoftwareService from '~/services/product-software-service';
import { CommercialOrderService } from '~/services/commercial-order-service';
import { FormRegisterService } from '~/services/form-register-service';
import { PaymentsService } from '~/services/commercial/payment';
import { showMessageError } from '~/util/errorutils';
import { handleMoveElement } from '~/util/scrollutils';
import { FormGenerate } from '~/model/form-generate';
import { useForm } from 'react-hook-form';
import permissions from '~/util/permissions';
import * as S from './styles';

function AddOrder({ history }) {
  const { PREENCHIDO, NAO_PREENCHIDO } = FORM_FILLED_ENUM;
  const OTHER_INPUT = { KEY: '_option_other', VALUE: 'Outro' };
  const ANSWERED_FORMS_STATUS = 'ANSWERED_FORMS';

  const user = useSelector(state => state.user.data);
  const userRevenda = user.revenda;
  const userCanEdit = user.revenda.comissionada;
  const userPermissions = user.permissions;
  const userCreateOrderId = user.id;

  const getUserRevendaId = () => {
    return (user.tipo === USER_TYPE.REVENDA) ? user.id : user.revenda.id;
  };

  const canShowAgainDialogModality = useSelector((state) => {
    return state.lifecycle.onShowAgainDialogModality;
  });

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [inputClienteValue, setInputValue] = useState('');
  const [clienteId, setClienteId] = useState(null);
  const [client, setClient] = useState({});
  const [selectedSoftware, setSelectedSoftware] = useState(null);
  const [selectedModality, setSelectedModality] = useState(null);
  const [softwares, setSoftwares] = useState([]);
  const [modules, setModules] = useState([]);
  const [modulesTotal, setModulesTotal] = useState([]);
  const [modalities, setModalities] = useState([]);
  const [items, setItems] = useState([]);

  const [activeSteps, setActiveSteps] = useState([
    'SELECT_CLIENT',
    'SELECT_PRODUCTS',
    'PAYMENT_METHODS',
    'COMPLETE_ORDER',
    // ANSWERED_FORMS_STATUS,
  ]);

  const [mesInicio, setMesInicio] = useState(moment().add(1, 'month').month());
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [planosPagamento, setPlanosPagamento] = useState([]);
  const [formaPagamentoId, setFormaPagamentoId] = useState(0);
  const [planoPagamentoId, setPlanoPagamentoId] = useState(0);
  const [carenciaAtivada, setCarenciaAtivada] = useState(false);
  const [periodoCarencia, setPeriodoCarencia] = useState(3);
  const [orderConfirmation, setOrderConfirmation] = useState(false);
  const [observation, setObservation] = useState('');

  const [forms, setForms] = useState([]);
  const [formularios, setFormularios] = useState([]);
  const [pessoasFormulario, setPessoasFormularios] = useState({});
  const [formCurrentIndex, setFormCurrentIndex] = useState(0);
  const [formCurrentId, setFormCurrentId] = useState(0);
  const [answerForms, setAnswerForms] = useState([]);
  const [answerFormsIds, setAnswerFormsIds] = useState([]);
  const [allFormsToAnswer, setAllFormsToAnswer] = useState([]);
  const [formCheckboxTemp, setFormCheckboxTemp] = useState([]);
  const [formsToAnswer, setFormsToAnswer] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: {} });

  const userCanViewSaveDraft = useMemo(
    () => userPermissions.includes(permissions['view_salvar-rascunho']),
    [userPermissions]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([
      ProductModalityService.getProductModalities({}),
      ProductSoftwareService.getProductSoftwaresComLogo(),
      PaymentsService.getGracePeriodPayment(),
    ])
      .then((values) => {
        if (values.length) {
          const [temp_modalidades, temp_softwares, temp_carencia] = values;

          let errorNotExist = false;
          if (!temp_modalidades.resultado.length) {
            errorNotExist = true;
            toast('Não existem modalidades cadastradas.', {
              type: toast.TYPE.ERROR,
            });
          }

          if (!temp_softwares.resultado.length) {
            errorNotExist = true;
            toast('Não existem softwares cadastradas.', {
              type: toast.TYPE.ERROR,
            });
          }

          if (!temp_carencia.length) {
            errorNotExist = true;
            toast('Não existe carência cadastradas.', {
              type: toast.TYPE.ERROR,
            });
          }

          if (errorNotExist) {
            history.push(commercialPaths.commercialOrder);
            return;
          }

          setSoftwares(orderBy([...temp_softwares.resultado], 'ordem'));
          setModalities([...temp_modalidades.resultado]);
          setCarenciaAtivada(temp_carencia[0].value === 'true');
          setPeriodoCarencia(Number(temp_carencia[1].value));
        } else {
          history.push(commercialPaths.commercialOrder);
        }
      })
      .catch(() => {
        toast('Erro ao consultar dados para montagem do pedido.', {
          type: toast.TYPE.ERROR,
        });

        setTimeout(() => {
          history.push(commercialPaths.commercialOrder);
        }, 100); // 0.10 segundos
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *    SELECIONE O CLIENTE
   */

  function handleSelectClientSubmit() {
    const { BLOCKED } = PERSON_STATUS_ENUM;

    if (clienteId === null) {
      toast('Escolha um cliente é obrigatório!', { type: toast.TYPE.ERROR });
      return;
    }

    if (client.status === BLOCKED.value) {
      toast('Cliente bloqueado!', { type: toast.TYPE.ERROR });
      return;
    }

    setActiveStep(1);
  }

  /**
   *    SELECIONE OS PRODUTOS
   */

  function HANDLE_ACTIVE_STEP_FORM() {
    return activeSteps.includes(ANSWERED_FORMS_STATUS);
  }

  async function handleCanMoveToFillForms(arrAllFormsToAnswer) {
    const formsId = [];

    (arrAllFormsToAnswer || []).map((item) => formsId.push(...item.formsId));

    if (!formsId.length) {
      setLoading(false);
      return;
    }

    try {
      const IS_CHECKED_PEOPLE_FORM = () => {
        const clientChanged = pessoasFormulario?.cliente?.id !== clienteId;
        const hasPeople = ![...Object.keys(pessoasFormulario)].length;
        return hasPeople || clientChanged;
      };

      if (IS_CHECKED_PEOPLE_FORM()) {
        const resPersonForms = await FormRegisterService
          .getPeopleFormsById(clienteId, getUserRevendaId());

        setPessoasFormularios(resPersonForms);
      }

      const formulariosTemp = [...formularios];
      const formulariosExistentes = formulariosTemp.map((item) => item.formId);

      const reqGetAllForms = [];
      formsId.forEach((id) => {
        if (!formulariosExistentes.includes(id)) {
          reqGetAllForms.push(FormRegisterService.getForm(id));
        }
      });

      if (reqGetAllForms.length) {
        const resGetAllForms = await Promise.all(reqGetAllForms);

        const checkboxTemp = [...formCheckboxTemp];
        const idsTemp = formulariosTemp.map((_) => _.formId);
        const indexTemp = formulariosTemp.map((_, index) => index);

        (resGetAllForms || []).forEach((item, index) => {
          const [formResponse] = item.resultado;

          const formGenerate = new FormGenerate(
            formResponse, clienteId, getUserRevendaId(),
          );

          const [
            formViewer, contentItemsFormAnswer,
          ] = formGenerate.getFormViewerAnswer();

          if (!idsTemp.includes(formResponse.id)) {
            const dadosFormulario = {
              formViewer,
              index: indexTemp.length + index,
              formId: formResponse.id,
              contentItemsFormAnswer,
              formData: {},
            };

            formulariosTemp.push(dadosFormulario);
            checkboxTemp.push({
              formId: formResponse.id,
              valuesData: [],
            });
          }
        });

        setFormularios(formulariosTemp);
        setFormCheckboxTemp(checkboxTemp);
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setActiveStep(2);
      setFormCurrentIndex(0);
      setTimeout(() => setLoading(false), 200); // 0.2 segundos
    }
  }

  async function handleCanMoveToPaymentMethods() {
    const resPaymentTypes = await CommercialOrderService.getPaymentTypes();

    if (resPaymentTypes.length) {
      const temp_data = {
        formaPagamentoId: formaPagamentoId ?? 0,
        planoPagamentoId: planoPagamentoId ?? 0,
        formasPagamento: resPaymentTypes,
        planosPagamento: [],
        forms: forms.length ? forms : [],
      };

      setFormasPagamento(temp_data.formasPagamento);
      setPlanosPagamento(temp_data.planosPagamento);
      setFormaPagamentoId(temp_data.formaPagamentoId);
      setPlanoPagamentoId(temp_data.planoPagamentoId);
      setActiveStep(3);
    } else {
      setFormasPagamento([]);

      toast('Não encontramos formas de pagamento!', {
        type: toast.TYPE.ERROR,
      });

      history.push(commercialPaths.commercialOrder);
    }

    setTimeout(() => setLoading(false), 200); // 0.2 segundos
  }

  function validateItensPedidoForm(success) {
    if (!items || !items.length) {
      toast('Adicione pelo menos um módulo no sistema para o carrinho.', {
        type: toast.TYPE.ERROR,
      });

      return;
    }

    success();
  }

  async function handleItensPedidoSubmit() {
    validateItensPedidoForm(async () => {
      setLoading(true);
      try {
        if (HANDLE_ACTIVE_STEP_FORM()) {
          const requestsToGetForms = [];

          allFormsToAnswer.forEach((item) => {
            if (item.formsId.length) {
              item.formsId.forEach((id) => {
                requestsToGetForms.push(
                  FormRegisterService.getFormsById(id),
                );
              });
            }
          });

          const responseForms = await Promise.all(requestsToGetForms);

          const getFormsTemp = [];
          responseForms.forEach((item) => {
            const form = (item.resultado ?? []);

            if (form.length) {
              const isAnswered = !!find(answerForms, { idForm: form[0].id });

              getFormsTemp.push({
                idForm: form[0].id, // ID do formulário
                idAnswerForm: null, // ID da resposta do formulário
                formName: form[0].name,
                moduleName: form[0].moduleName,
                status: isAnswered ? PREENCHIDO : NAO_PREENCHIDO
              });
            }
          });

          setForms(getFormsTemp);
          handleCanMoveToFillForms(allFormsToAnswer);
        } else {
          handleCanMoveToPaymentMethods();
        }
      } catch (error) {
        showMessageError(error);
      }
    });
  }

  /**
   *    PREENCHA OS FORMULÁRIOS
   */

  function handleValuesFormulariosChange(formData, index) {
    const formulariosTemp = [...formularios];
    formulariosTemp[index].formData = formData;
    reset(formData);
    setFormularios(formulariosTemp);
  }

  function validateRequiredFields(updatedFormData, success) {
    let formOK = false;
    const [oldItem] = formularios.filter((_) => _.formId === formCurrentId);

    if (oldItem) {
      for (const keyItem in updatedFormData) {
        if (typeof oldItem.formData[keyItem] === 'string') {
          oldItem.formData[keyItem] = (updatedFormData[keyItem] ?? '').trim();
        } else {
          oldItem.formData[keyItem] = updatedFormData[keyItem];
        }
      }

      const formatAttrName = (item, form) => {
        return `${item.formViewer.uuid}---${form.answerContent.attrName}`;
      };

      const itemsAnswerRequired = [];
      oldItem.contentItemsFormAnswer.forEach((form) => {
        if (form.required) {
          itemsAnswerRequired.push(formatAttrName(oldItem, form));
        }
      });

      const unfilledFields = [];
      for (const key in oldItem.formData) {
        const keyWithoutOther = key.replace(OTHER_INPUT.KEY, '');

        if (itemsAnswerRequired.includes(keyWithoutOther)) {
          const valueData = oldItem.formData[key];

          if (valueData && valueData.length > 0) {
            unfilledFields.push({ key, filled: true });
          } else {
            const IS_INCLUDES_OTHER = () => {
              let keyOther = key;

              if (key.includes(OTHER_INPUT.KEY)) {
                keyOther = key.replace(OTHER_INPUT.KEY, '');
              } else {
                return false;
              }

              const valueDataOther = oldItem.formData[keyOther];

              if (!(valueDataOther && valueDataOther.length > 0)) return false;

              if (typeof valueDataOther === 'object') {
                const IS_OTHER_CHECK = () => {
                  if (!valueDataOther.includes(OTHER_INPUT.VALUE)
                    && (valueData && (
                      valueData.length === 0 || valueData === ''
                    ))
                  ) {
                    return true;
                  }

                  return valueDataOther.includes(OTHER_INPUT.VALUE)
                    && (valueData && valueData.length > 0);
                };

                if (IS_OTHER_CHECK()) return true;

                return !valueDataOther.includes(OTHER_INPUT.VALUE);
              }

              return valueDataOther !== OTHER_INPUT.VALUE;
            };

            if (IS_INCLUDES_OTHER()) {
              unfilledFields.push({ key, filled: true });
            } else {
              unfilledFields.push({ key, filled: false });
            }
          }
        }
      }

      const IS_FILLED_FIELDS = () => {
        return unfilledFields.every((_) => _.filled === true);
      };

      if (IS_FILLED_FIELDS()) {
        formOK = true;
        setAnswerFormsIds((oldAnswer) => [
          ...new Set([...oldAnswer, formCurrentId])
        ]);
      } else {
        formOK = false;
        setAnswerFormsIds((oldAnswer) => [
          ...new Set([...oldAnswer.filter((id) => id !== formCurrentId)]),
        ]);
      }

      success(updatedFormData, oldItem.index, formOK);
    }

    success(updatedFormData, null, formOK);
  }

  function validateAnswerForm(formIndexOK, formOK, success) {
    const [formTemp] = formularios.filter((_, ind) => ind === formIndexOK);
    const filledIds = [...answerFormsIds];

    if (formTemp && !filledIds.includes(formTemp.formId) && formOK) {
      filledIds.push(formTemp.formId);
    }

    const answers = [];
    formularios.forEach((form) => {
      if (filledIds.includes(form.formId)) {
        for (const keyItem in form.formData) {
          if (typeof form[keyItem] === 'string') {
            form[keyItem] = form[keyItem].trim();
          }
        }

        const formatAttrName = (uuid, attrName) => {
          return `${uuid}---${attrName}`;
        };

        const formDataValues = {};
        for (const keyItem in form.formData) {
          if (keyItem.includes(form.formViewer.uuid)) {
            const keyValue = keyItem.replace(
              `${form.formViewer.uuid}---`, '',
            );

            if (typeof form.formData[keyItem] === 'string') {
              formDataValues[keyValue] = form.formData[keyItem].trim();
            }

            if (typeof form.formData[keyItem] === 'object') {
              formDataValues[keyValue] = form.formData[keyItem];
            }
          }
        }

        const dataAnswerContent = {
          idForm: form.formId,
          formName: form.formViewer.formName,
          extraInfo: JSON.stringify({
            defaultValues: formDataValues,
            attrsName: form.contentItemsFormAnswer.map(
              (input) => input.answerContent.attrName,
            )
          }),
          dataAnswer: form.contentItemsFormAnswer.map(
            (input) => ({
              itemFormId: input.itemFormId,
              answerContent: JSON.stringify(
                form.formData[formatAttrName(
                  form.formViewer.uuid,
                  input.answerContent.attrName,
                )]
              ),
            })
          ),
        };

        answers.push(dataAnswerContent);
      }
    });

    setAnswerForms(answers);

    let formsUpdated = [...forms];
    formsUpdated = formsUpdated.map((form) => {
      return filledIds.includes(form.idForm)
        ? { ...form, status: PREENCHIDO }
        : { ...form };
    });

    setForms(formsUpdated);
    success(formsUpdated);
  }

  function validateFillForms(formsFilleds, success) {
    const indexErrors = [];
    let error_forms = false;

    formsFilleds.forEach((form, index) => {
      if (form.status === NAO_PREENCHIDO) {
        indexErrors.push(index + 1);
        error_forms = true;
      }
    });

    if (error_forms) {
      switch (true) {
        case (indexErrors.length === formsFilleds.length) && indexErrors.length > 1:
          toast(
            'Preencha todos os formulários corretamente',
            { type: toast.TYPE.ERROR }
          );
          break;
        case indexErrors.length === 1:
          toast(
            `O formulário ${indexErrors[0]} precisa ser preenchido corretamente`,
            { type: toast.TYPE.ERROR }
          );
          break;
        case indexErrors.length === 2:
          toast(
            `Os formulários ${indexErrors.join(' e ')} precisam ser`
            + ' preenchidos corretamente',
            { type: toast.TYPE.ERROR }
          );
          break;
        default:
          toast(
            `Os formulários ${indexErrors.join(', ')} precisam ser`
            + ' preenchidos corretamente',
            { type: toast.TYPE.ERROR }
          );
          break;
      }
      return;
    }

    success();
  }

  const handleFillFormsSubmit = (formDataCurrent) => {
    validateRequiredFields(
      formDataCurrent, (formDataChecked, formDataIndex, formDataFilled) => {
        if (![null, undefined].includes(formDataIndex)) {
          handleValuesFormulariosChange(formDataChecked, formDataIndex);
          validateAnswerForm(formDataIndex, formDataFilled, (formsFilleds) => {
            validateFillForms(formsFilleds, async () => {
              setLoading(true);
              try {
                await handleCanMoveToPaymentMethods();
              } catch (error) {
                showMessageError(error);
              } finally {
                setLoading(false);
              }
            });
          });
        }
      }
    );
  };

  const onGetFormData = (formDataCurrent, formDataIndex) => {
    validateRequiredFields(formDataCurrent, (formDataChecked) => {
      handleValuesFormulariosChange(formDataChecked, formDataIndex);
    });
  };

  /**
   *    ESCOLHA A FORMA DE PAGAMENTO
   */

  function validateFormaPagamentoForm(success) {
    if (!formaPagamentoId && !planoPagamentoId) {
      toast(
        'Forma e Plano de Pagamento são obrigatórios.',
        { type: toast.TYPE.ERROR }
      );
      return;
    }

    if (!formaPagamentoId) {
      toast('Forma de Pagamento é obrigatório.', { type: toast.TYPE.ERROR });
      return;
    }

    if (!planoPagamentoId) {
      toast('Plano de Pagamento é obrigatório.', { type: toast.TYPE.ERROR });
      return;
    }

    success();
  }

  async function handleFormaPagamentoSubmit() {
    validateFormaPagamentoForm(() => setActiveStep(4));
  }

  /**
   *    CONCLUIR
   */

  function getDataDraftToSave() {
    const softwares = [];
    softwares.concat([...new Set([...items.map((_) => _.softwareId)])]);

    if (selectedSoftware && !softwares.includes(selectedSoftware.id)) {
      softwares.push(selectedSoftware.id);
    }

    const answers = [];
    if (activeStep >= 2) {
      validateRequiredFields(
        getValues(), (_, formDataIndex, formDataFilled) => {
          if (![null, undefined].includes(formDataIndex)) {
            validateAnswerForm(formDataIndex, formDataFilled, () => {
              const formatAttrName = (uuid, attrName) => {
                return `${uuid}---${attrName}`;
              };

              formularios.forEach((form) => {
                for (const keyItem in form.formData) {
                  if (typeof form[keyItem] === 'string') {
                    form[keyItem] = form[keyItem].trim();
                  }
                }

                const formDataValues = {};
                for (const keyItem in form.formData) {
                  if (keyItem.includes(form.formViewer.uuid)) {
                    const keyValue = keyItem.replace(
                      `${form.formViewer.uuid}---`, '',
                    );

                    if (typeof form.formData[keyItem] === 'string') {
                      formDataValues[keyValue] = form.formData[keyItem].trim();
                    }

                    if (typeof form.formData[keyItem] === 'object') {
                      formDataValues[keyValue] = form.formData[keyItem];
                    }
                  }
                }

                const dataAnswerContent = {
                  idForm: form.formId,
                  formName: form.formViewer.formName,
                  extraInfo: JSON.stringify({
                    defaultValues: formDataValues,
                    attrsName: form.contentItemsFormAnswer.map(
                      (input) => input.answerContent.attrName,
                    )
                  }),
                  dataAnswer: form.contentItemsFormAnswer.map(
                    (input) => ({
                      itemFormId: input.itemFormId,
                      answerContent: JSON.stringify(
                        form.formData[formatAttrName(
                          form.formViewer.uuid,
                          input.answerContent.attrName,
                        )]
                      ),
                    })
                  ),
                };

                answers.push(dataAnswerContent);
              });
            });
          }
        }
      );
    }

    const draftData = {
      activeStep,
      clienteId,
      mesInicio,
      userCreateOrderId,
      softwareId: softwares,
      modalityId: selectedModality ? selectedModality.id : null,
      items,
      formaPagamentoId,
      planoPagamentoId,
      obs: observation,
      formularios: {
        forms,
        formCurrentIndex,
        formCurrentId,
        answerForms: answers,
        answerFormsIds: [...new Set([...answerFormsIds])],
        allFormsToAnswer,
        formCheckboxTemp,
        formsToAnswer,
      }
    };

    return draftData;
  }

  function validateDraftOrderConfirmation(success) {
    handleSelectClientSubmit();
    success();
  }

  function validateSalvarRascunho(success) {
    const formData = {};

    formData.clienteId = client.id;
    formData.mesInicio = isNaN(mesInicio) ? parseInt(mesInicio) : mesInicio;

    if (![undefined, null, 0].includes(formaPagamentoId)) {
      formData.formaPagamentoId = !isNaN(formaPagamentoId)
        ? parseInt(formaPagamentoId)
        : formaPagamentoId;
    } else {
      formData.formaPagamentoId = 1;
    }

    if (![undefined, null, 0].includes(planoPagamentoId)) {
      formData.planoPagamentoId = !isNaN(planoPagamentoId)
        ? parseInt(planoPagamentoId)
        : planoPagamentoId;
    } else {
      formData.planoPagamentoId = 1;
    }

    formData.itemPedidos = [];
    items.forEach((item) => {
      const itemPedido = {};
      itemPedido.softwareId = parseInt(item.softwareId);
      itemPedido.moduloId = parseInt(item.id);
      itemPedido.quantidade = parseInt(item.count);
      itemPedido.valorMensalUnidade = item.precoMensal;
      itemPedido.valorAdesaoUnidade = item.precoAdesao;
      formData.itemPedidos.push(itemPedido);
    });

    formData.obs = observation.trim();

    const formAnswerData = {
      clientId: client.id,
      userCreateOrderId,
      contentItemsFormAnswer: answerForms.map((item) => ({
        formId: item.idForm,
        dataAnswer: item.dataAnswer,
        extraInfo: item.extraInfo,
      }))
    };

    formData.rascunho = JSON.stringify(getDataDraftToSave());
    formData.finalizarRascunho = false;

    success(formData, formAnswerData);
  }

  function handleSalvarRascunhoSubmit() {
    validateDraftOrderConfirmation(() => {
      validateSalvarRascunho(async (formData, formAnswerData) => {
        setLoading(true);
        try {
          const draftOrderCreated = await CommercialOrderService.createDraftOrder(
            formData, formAnswerData,
          );

          toast(draftOrderCreated.message, { type: toast.TYPE.SUCCESS });

          if (draftOrderCreated.data.backgroundMessage) {
            setTimeout(() => {
              const { error, success } = draftOrderCreated.data.backgroundMessage;
              if (!error.length) {
                toast(success[0], { type: toast.TYPE.SUCCESS });
              } else {
                toast(error[0], { type: toast.TYPE.ERROR });
              }
            }, 75); // 0.075 segundos
          }

          setTimeout(() => {
            history.push(commercialPaths.commercialOrder);
          }, 150); // 0.15 segundos
        } catch (error) {
          showMessageError(error);
        } finally {
          setLoading(false);
        }
      });
    });
  }

  async function validateFillFormsBeforeSubmit(success) {
    setLoading(true);
    try {
      let orderCanCreate = true;
      let allFormsToAnswerBefore = [];

      const itemsObjId = (items.map(_ => _.id) || []);
      if ((answerFormsIds.length > 0)) {
        itemsObjId.forEach((modObjId) => {
          const moduleObj = allFormsToAnswer.find(_ => _.moduleId === modObjId);
          if (moduleObj) {
            (moduleObj || []).formsId.forEach((formObjId) => {
              const formObj = formularios.find(_ => _.formId === formObjId);
              if (!formObj) { orderCanCreate = false; }
            });
          }
        });
      } else {
        const reqVerifyModule = itemsObjId.map((itemModuleId) => {
          return FormRegisterService.getFormsByModuleId(itemModuleId);
        });

        if (reqVerifyModule) {
          const moduleToCheck = await Promise.all(reqVerifyModule);
          if (moduleToCheck) {
            moduleToCheck.forEach((moduleObj, index) => {
              const moduleTempId = itemsObjId
                .find((_, indItems) => indItems === index);

              if (moduleObj.resultado.length) {
                allFormsToAnswerBefore.push({
                  moduleId: moduleObj.resultado[0].moduleId,
                  formsId: (moduleObj.resultado || []).map((_) => _.id),
                  exist: true,
                });

                orderCanCreate = false;
              } else {
                allFormsToAnswerBefore.push({
                  moduleId: moduleTempId,
                  formsId: [],
                  exist: false,
                });
              }
            });
          }
        }
      }

      if (!orderCanCreate) {
        const requestsToGetForms = [];
        allFormsToAnswerBefore.forEach((item) => {
          if (item.formsId.length) {
            item.formsId.forEach((id) => {
              requestsToGetForms.push(FormRegisterService.getFormsById(id));
            });
          }
        });

        if (requestsToGetForms.length) {
          const responseForms = await Promise.all(requestsToGetForms);

          const getFormsTemp = [];
          responseForms.forEach((item) => {
            const form = (item.resultado ?? []);

            if (form.length) {
              getFormsTemp.push({
                idForm: form[0].id, // ID do formulário
                idAnswerForm: null, // ID da resposta do formulário
                formName: form[0].name,
                moduleName: form[0].moduleName,
                status: NAO_PREENCHIDO
              });
            }
          });

          setForms(getFormsTemp);
        }

        handleCanMoveToFillForms(allFormsToAnswerBefore);

        if (!activeSteps.includes(ANSWERED_FORMS_STATUS)) {
          activeSteps.push(ANSWERED_FORMS_STATUS);
        }

        setAllFormsToAnswer(allFormsToAnswerBefore);
        toast.error('Preencha o formulário para prosseguir com seu pedido.');
        return;
      }
    } catch (error) {
      showMessageError(error);
      return;
    }

    success();
  }

  function validateOrderConfirmation(success) {
    if (!orderConfirmation) {
      toast('Confirmação de revisão de pedido é obrigatório!', {
        type: toast.TYPE.ERROR,
      });

      handleMoveElement('endTermo');
      return;
    }

    success();
  }

  function validateFinalizarPedidoForm(success) {
    const formData = {};

    formData.clienteId = client.id;
    formData.obs = observation.trim();
    formData.mesInicio = isNaN(mesInicio) ? parseInt(mesInicio) : mesInicio;

    formData.formaPagamentoId = !isNaN(formaPagamentoId)
      ? parseInt(formaPagamentoId)
      : formaPagamentoId;

    formData.planoPagamentoId = !isNaN(planoPagamentoId)
      ? parseInt(planoPagamentoId)
      : planoPagamentoId;

    formData.itemPedidos = [];
    items.forEach((item) => {
      const itemPedido = {};
      itemPedido.softwareId = parseInt(item.softwareId);
      itemPedido.moduloId = parseInt(item.id);
      itemPedido.quantidade = parseInt(item.count);
      itemPedido.valorMensalUnidade = item.precoMensal;
      itemPedido.valorAdesaoUnidade = item.precoAdesao;
      formData.itemPedidos.push(itemPedido);
    });

    const formAnswerData = {
      clientId: client.id,
      userCreateOrderId,
      contentItemsFormAnswer: answerForms.map(
        (answer) => ({
          formId: answer.idForm,
          dataAnswer: answer.dataAnswer,
          extraInfo: answer.extraInfo,
        })
      ),
    };

    success(formData, formAnswerData);
  }

  function handleFinalizarPedidoSubmit() {
    validateFillFormsBeforeSubmit(() => {
      setLoading(false);
      validateOrderConfirmation(() => {
        validateFinalizarPedidoForm(async (formData, formAnswerData) => {
          setLoading(true);
          try {
            const orderCreated = await CommercialOrderService
              .createOrder(formData, formAnswerData);

            toast(orderCreated.message, { type: toast.TYPE.SUCCESS });

            if (orderCreated.data.backgroundMessage) {
              setTimeout(() => {
                const { error, success } = orderCreated.data.backgroundMessage;
                if (!error.length) {
                  toast(success[0], { type: toast.TYPE.SUCCESS });
                } else {
                  toast(error[0], { type: toast.TYPE.ERROR });
                }
              }, 75); // 0.075 segundos
            }

            setTimeout(() => {
              history.push(commercialPaths.commercialOrder);
            }, 150); // 0.015 segundos
          } catch (error) {
            showMessageError(error);
          } finally {
            setLoading(false);
          }
        });
      });
    });
  }

  /**
   *    GLOBAL
   */

  function handleLinkToFormCurrent(formId) {
    setLoading(true);
    handleMoveElement('orderTop');

    const [oldItem] = formularios.filter((_) => _.formId === formId);

    setFormCurrentId(oldItem.formId);
    setFormCurrentIndex(oldItem.index);
    reset(oldItem.formData);

    setActiveStep(2);
    setLoading(false);
  }

  async function handleAddModule(addModule) {
    try {
      setLoading(true);

      const itemsTemp = [...items];
      const hasItem = itemsTemp.filter((item) => item.id === addModule.id);

      if (hasItem && hasItem.length) {
        hasItem[0].count++;
      } else {
        addModule = cloneDeep(addModule);

        let totalDesconto = 0.0;
        userRevenda.descontos.forEach(descontoRevenda => {
          totalDesconto += descontoRevenda.desconto.valor;
        });

        if (addModule.descontoAtivo && totalDesconto > 0) {
          const pctTotal = totalDesconto / 100;
          addModule.precoAdesao = addModule.precoAdesao
            ? addModule.precoAdesao - addModule.precoAdesao * pctTotal
            : 0;
          addModule.precoMensal = addModule.precoMensal
            ? addModule.precoMensal - addModule.precoMensal * pctTotal
            : 0;
        }

        addModule.count = 1;
        itemsTemp.push(addModule);

        let formulariosResponder = [...allFormsToAnswer];
        const moduleWithForm = formulariosResponder.filter(
          (_) => _.moduleId === addModule.id,
        );

        let moduleCheckFormTemp = null;
        if (!moduleWithForm.length) {
          moduleCheckFormTemp = await FormRegisterService.getFormsByModuleId(
            addModule.id,
          );
        }

        const IS_CHECKED = () => {
          return moduleCheckFormTemp && moduleCheckFormTemp.resultado.length;
        };

        let activeStepsTemp = [...activeSteps];

        if (IS_CHECKED()) {
          const formularios = moduleCheckFormTemp.resultado;
          const formulariosExistentes = formularios.filter(
            (_) => _.moduleId === addModule.id,
          );

          if (formulariosExistentes.length) {
            const dataFormulario = {
              moduleId: addModule.id,
              formsId: formularios.map((_) => _.id),
              exist: true,
            };

            formulariosResponder.push(dataFormulario);

            if (!activeStepsTemp.includes(ANSWERED_FORMS_STATUS)) {
              activeStepsTemp.push(ANSWERED_FORMS_STATUS);
            }
          }
        } else {
          const dataFormulario = {
            moduleId: addModule.id,
            formsId: [],
            exist: false,
          };

          const modulesWithoutForms = formulariosResponder.filter(
            (_) => _.moduleId === addModule.id
          );

          if (!modulesWithoutForms.length) {
            formulariosResponder.push(dataFormulario);
          }
        }

        setActiveSteps(activeStepsTemp);
        setAllFormsToAnswer(formulariosResponder);
      }

      setItems(itemsTemp);
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleRemoveModule(remModule) {
    try {
      setLoading(true);

      const itemsCurrent = [...items];
      const allFormsToAnswerCurrent = [...allFormsToAnswer];
      const activeStepsCurrent = [...activeSteps];

      const hasItem = itemsCurrent.filter((item) => item.id === remModule.id);

      let allFormsToAnswerTemp = allFormsToAnswerCurrent;
      let activeStepsTemp = activeStepsCurrent;
      if (hasItem && hasItem.length) {
        hasItem[0].count--;

        if (hasItem[0].count <= 0) {
          hasItem[0].count = 0;
          const index = itemsCurrent.findIndex((item) => item.id === hasItem[0].id);
          if (index < 0) {
            throw new Error('Ocorreu um erro ao excluir o módulo do Pedido!');
          }
          itemsCurrent.splice(index, 1);
        }

        if (hasItem[0].count === 0) {
          if (allFormsToAnswerCurrent.length) {
            const [hasForm] = allFormsToAnswerCurrent.filter(
              item => item.moduleId === remModule.id,
            );

            if (hasForm) {
              const [formsToRemove] = allFormsToAnswerCurrent.filter(
                item => item.moduleId === remModule.id,
              );

              allFormsToAnswerTemp = allFormsToAnswerCurrent.filter(
                item => item.moduleId !== remModule.id,
              );

              const hasModuleWithForm = allFormsToAnswerTemp.filter((module) => {
                return module.formsId.length && module;
              });

              if (!hasModuleWithForm.length) {
                activeStepsTemp = activeStepsCurrent.filter(
                  step => step !== ANSWERED_FORMS_STATUS
                );
              }

              if (formsToRemove.formsId.length) {
                formsToRemove.formsId.forEach((formId) => {
                  setForms((oldForms) => [
                    ...oldForms.filter((_) => _.idForm !== formId),
                  ]);

                  setFormularios((oldForms) => {
                    const newForms = [
                      ...oldForms.filter((_) => _.formId !== formId),
                    ];

                    const arrUpdatedInd = newForms.map((formData, index) => {
                      return { ...formData, index };
                    });

                    return [...arrUpdatedInd];
                  });

                  setFormCheckboxTemp((oldForms) => [
                    ...oldForms.filter((_) => _.formId !== formId),
                  ]);

                  setAnswerForms((oldForms) => [
                    ...oldForms.filter((_) => _.idForm !== formId),
                  ]);

                  setAnswerFormsIds((oldForms) => [
                    ...oldForms.filter((id) => id !== formId),
                  ]);
                });
              }
            }
          }
        }

        setItems(itemsCurrent);
        setActiveSteps(activeStepsTemp);
        setAllFormsToAnswer(allFormsToAnswerTemp);
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function calculateTotalAdesao(items) {
    let sum = 0.0;
    items.forEach((item) => {
      const { precoAdesao } = item;
      if (precoAdesao) sum += precoAdesao * item.count;
    });
    return sum;
  }

  function calculateTotalMensalidade(items) {
    let sum = 0.0;
    items.forEach((item) => {
      const { precoMensal } = item;
      if (precoMensal) sum += precoMensal * item.count;
    });
    return sum;
  }

  function handleOrderCancel() {
    return history.push(commercialPaths.commercialOrder);
  }

  const stepsDataObjects = [
    {
      title: 'Cliente',
      number: 1,
      handleEnabledStep: activeStep > 0,
      handleIsStep: activeStep === 0,
      handleClick: () => setActiveStep(0),
      active: true,
      showDivider: true,
    },
    {
      title: 'Produtos',
      number: 2,
      handleEnabledStep: activeStep > 1,
      handleIsStep: activeStep === 1,
      handleClick: handleSelectClientSubmit,
      active: true,
      showDivider: true,
    },
    {
      title: 'Formulários',
      number: 3,
      handleEnabledStep: activeStep > 2,
      handleIsStep: activeStep === 2,
      handleClick: () => setActiveStep(2),
      active: HANDLE_ACTIVE_STEP_FORM(),
      showDivider: true,
    },
    {
      title: 'Forma de Pagamento',
      number: HANDLE_ACTIVE_STEP_FORM() ? 4 : 3,
      handleEnabledStep: activeStep > 3,
      handleIsStep: activeStep === 3,
      handleClick: () => setActiveStep(3),
      active: true,
      showDivider: true,
    },
    {
      title: 'Concluir',
      number: HANDLE_ACTIVE_STEP_FORM() ? 5 : 4,
      handleEnabledStep: activeStep > 4,
      handleIsStep: activeStep === 4,
      handleClick: () => setActiveStep(4),
      active: true,
      showDivider: false,
    },
  ];

  const HANDLE_ON_CLICK_SUBMIT = {
    0: handleSelectClientSubmit,
    1: handleItensPedidoSubmit,
    2: handleSubmit(handleFillFormsSubmit),
    3: handleFormaPagamentoSubmit,
    4: handleFinalizarPedidoSubmit,
  };

  const tableShoppingCart = {
    softwares,
    handleRemoveModule,
    handleAddModule,
    calculateTotalAdesao,
    calculateTotalMensalidade,
  };

  const selectClients = {
    client,
    setClient,
    setClienteId,
    inputClienteValue,
    setInputValue,
  };

  const selectProducts = {
    selectedSoftware,
    selectedModality,
    modules,
    modulesTotal,
    modalities,
    items,
    forms,
    allFormsToAnswer,
    answerForms,
    formsToAnswer,
    activeSteps,
    setSelectedSoftware,
    setSelectedModality,
    setModules,
    setModulesTotal,
    setItems,
    setForms,
    setAllFormsToAnswer,
    setAnswerForms,
    setFormsToAnswer,
    setActiveSteps,
    handleSubmit: handleItensPedidoSubmit,
    canShowAgainDialogModality,
  };

  const fillForms = {
    forms,
    answerFormsIds,
    allFormsToAnswer,
    formsToAnswer,
    formularios,
    pessoasFormulario,
    register,
    watch,
    getValues,
    setValue,
    reset,
    errors,
    onSubmit: () => handleSubmit(handleFillFormsSubmit),
    formCurrentIndex,
    setFormCurrentIndex,
    setFormCurrentId,
    onGetFormData,
    formCheckboxTemp,
    setFormCheckboxTemp,
  };

  const choosePaymentForm = {
    items,
    setItems,
    mesInicio,
    setMesInicio,
    carenciaAtivada,
    periodoCarencia,
    formasPagamento,
    planosPagamento,
    formaPagamentoId,
    planoPagamentoId,
    setFormasPagamento,
    setPlanosPagamento,
    setFormaPagamentoId,
    setPlanoPagamentoId,
    handleSubmit: handleFormaPagamentoSubmit,
  };

  const conclude = {
    user,
    items,
    mesInicio,
    observation,
    cliente: client,
    formularios,
    register,
    watch,
    setValue,
    errors,
    reset,
    pessoasFormulario,
    formasPagamento,
    formaPagamentoId,
    planoPagamentoId,
    selectedModality,
    orderConfirmation,
    answerForms,
    setObservation,
    setOrderConfirmation,
    handleLinkToFormCurrent,
  };

  return (
    <S.AddOrderContainer>
      <Modal show={loading} loading />
      <div id='orderTop' />
      <SidebarPageHeader
        mainMenu="Comercial"
        pageName="Novo Pedido"
        breadcrumbs={[{
          label: 'Pedido',
          link: commercialPaths.commercialOrder,
        }]}
        customButtonsBeforeReport={[
          <button key='button_order_cancel'
            type="button"
            className="buttonBase baseWebMobile addMargin"
            onClick={handleOrderCancel}
          >
            CANCELAR
          </button>,
          userCanViewSaveDraft && (
            <button key='button_order_save_draft'
              type="button"
              className="buttonBase baseWebMobile addMargin"
              onClick={handleSalvarRascunhoSubmit}
            >
              SALVAR RASCUNHO
            </button>
          ),
          <button key='button_order_complete'
            type="button"
            className="buttonBase baseWebMobile addMargin baseMain"
            onClick={HANDLE_ON_CLICK_SUBMIT[activeStep]}
          >
            {activeStep === 4 ? 'FINALIZAR PEDIDO' : 'AVANÇAR'}
          </button>,
        ]}
        openButtonSelect
      />
      <Stepper
        customHeader={(<span>Etapas do pedido:</span>)}
        stepsDataObjects={stepsDataObjects}
      />
      <Steps
        index={activeStep}
        userCanEdit={userCanEdit}
        setLoading={setLoading}
        tableShoppingCart={tableShoppingCart}
        dataObject={{
          selectClients,
          selectProducts,
          fillForms,
          choosePaymentForm,
          conclude,
        }}
      />
    </S.AddOrderContainer>
  );
}

AddOrder.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default AddOrder;
