import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { productPaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';

class AddProductModality extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { history } = props;
    this.history = history;

    this.state = {
      loading: false,
      inputNome: '',

      // errors
      error_inputNome: false,
    };
  }

  /* LifeCicle:: Inicializa quando monta a DOM */
  componentDidMount() {
    // this.setLoading(false);
  }

  setLoading = b => this.setState({ loading: b });

  setInputNome = b => this.setState({ inputNome: b });

  handleInputNomeChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputNome(value);
    this.handleErrorsInputNome(false);
  };

  handleErrorsInputNome = (error_inputNome) => {
    this.setState({ error_inputNome });
  }

  validateForm(success) {
    let errors_temp = false;
    const formData = {};

    if (!this.state.inputNome || !this.state.inputNome.length) {
      this.handleErrorsInputNome(true);
      errors_temp = true;
    }

    if (errors_temp) {
      return;
    }

    formData.nome = this.state.inputNome.trim();

    success(formData);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.validateForm(async formData => {
      this.setLoading(true);
      try {
        const { data } = await api.post(
          endpoints.product.modality.createProductModality,
          formData
        );
        toast(data.message, { type: toast.TYPE.SUCCESS });
        this.setLoading(false);
        this.history.push(productPaths.productModality);
      } catch (error) {
        this.setLoading(false);
        showMessageError(error);
      }
    });
  };

  render() {
    return (
      <div className="defaultFormContainer">
        <Modal show={this.state.loading} loading />
        <SidebarPageHeader
          mainMenu="Produto"
          pageName="Nova Modalidade"
          breadcrumbs={[{
            label: 'Modalidade',
            link: productPaths.productModality,
          }]}
          button1={{
            label: 'Salvar',
            onClick: () => this.handleSubmit,
            main: true,
          }}
          openButtonSelect
          buttonCancelHandler={() => this.history.goBack()}
        />

        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <div className="infoCard">
            <span id="title">Dados da Modalidade</span>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Nome da Modalidade *</span>
                <input
                  type="text"
                  name="nome"
                  value={this.state.inputNome}
                  onChange={this.handleInputNomeChange}
                />
                {this.state.error_inputNome && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default AddProductModality;
