import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { commercialPaths } from '~/routes/paths';
import { ReasonSolicitationService } from '~/services/commercial/reasonsolicitation';
import { showMessageError } from '~/util/errorutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { Radio } from '~/components/Radio';

export default function EditReasonSolicitation({ history, location }) {
  const [reasonId, setReasonId] = useState(null);
  const [inputReason, setInputReason] = useState('');
  const [inputAvailability, setInputAvailability] = useState(false);
  const [inputClientExist, setInputClientExist] = useState(false);
  const [errorReason, setErrorReason] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!location.state) history.push(commercialPaths.reasonSolicitation);
    else {
      setReasonId(location.state.id);
      setInputReason(location.state.motivo);
      setInputAvailability(location.state.ativo);
      setInputClientExist(location.state.existeCliente);
    }
  }, [history, location.state]);

  function handleInputReasonChange(event) {
    const {
      target: { value },
    } = event;

    setInputReason(value);
    setErrorReason(false);
  }

  function handleInputAvailabilityChange(event) {
    const {
      target: { value },
    } = event;

    setInputAvailability(value === 'true');
  }

  function handleInputClientExistChange(event) {
    const {
      target: { value },
    } = event;

    setInputClientExist(value !== 'true');
  }

  function validadeForm(success) {
    const data = {};
    let error_temp = false;

    if (!inputReason || !inputReason.length) {
      setErrorReason(true);
      error_temp = true;
    }

    if (error_temp) {
      return;
    }

    data.id = Number(reasonId);
    data.motivo = inputReason.trim();
    data.ativo = Boolean(inputAvailability);
    data.existeCliente = Boolean(inputClientExist);

    success(data);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    validadeForm(async (formData) => {
      setLoading(true);
      try {
        const result = await ReasonSolicitationService
          .updateReasonSolicitation(formData);

        toast(result.message, { type: toast.TYPE.SUCCESS });

        setTimeout(() => {
          history.push(commercialPaths.reasonSolicitation);
        }, 100);
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });
  }

  return (
    <div className="defaultFormContainer">
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Comercial"
        pageName="Editar Motivo"
        breadcrumbs={[{
          label: 'Motivo de Solicitação',
          link: commercialPaths.reasonSolicitation,
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => handleSubmit,
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={() => history.goBack()}
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <span id="title">Dados do Motivo</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Nome *</span>
              <input
                type="text"
                name="nome"
                value={inputReason}
                onChange={handleInputReasonChange}
              />
              {errorReason && (
                <small style={{ color: 'red' }}>
                  Campo obrigatório
                </small>
              )}
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Disponibilidade *</span>
          <div className="rowContainer">
            <Radio
              name="availability"
              labelHorizontal='Disponível'
              value={true}
              outlineSelect={inputAvailability === true}
              onChange={handleInputAvailabilityChange}
            />
            <Radio
              name="availability"
              labelHorizontal='Indisponível'
              value={false}
              outlineSelect={inputAvailability === false}
              onChange={handleInputAvailabilityChange}
            />
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Pré-cadastro de novo cliente</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <div className="radioHolder justifyFlexStart">
                <div className="inputBoxHolder">
                  <input
                    type="checkbox"
                    value={inputClientExist}
                    checked={inputClientExist}
                    onChange={handleInputClientExistChange}
                  />
                  <span className="checkmark">
                    {inputClientExist && (
                      <div className="markHolder">
                        <div className="checkmark_stem" />
                        <div className="checkmark_kick" />
                      </div>
                    )}
                  </span>
                </div>
                <span id="radioLabel">
                  Adiciona uma nova etapa para pré-cadastro de novo cliente
                </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

EditReasonSolicitation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number.isRequired,
      motivo: PropTypes.string.isRequired,
      tag: PropTypes.string.isRequired,
      ativo: PropTypes.bool.isRequired,
      existeCliente: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};
