import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FORM_ITEM_ASNWER_TYPE as TAGS } from '~/util/domainutils';
import { FormTitle } from '../FormTitle';
import { FormImage } from '../FormImage';
import { FormClientResale } from '../FormClientResale';
import { FormAnswer } from '../FormAnswer';
import { FormRequired } from '../FormRequired';
import { DraggableItem, DroppableContainer, DraggableHandle } from './styles';
import { DragIcon } from '../styles';
import '../styles.scss';

import CmImgBlue from
  '~/assets/marca/preferencial/PNG/CM-marca_preferencial-positiva-RGB.png';

function FormDragDropContext({
  form, setForm, errorForm, setErrorFormItems, handleRemoveItemForm,
  handleDuplicateItemForm,
}) {
  const [image, setImage] = useState(CmImgBlue);

  function reorderDragDrog(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  }

  function onDragEnd(result) {
    if (!result.destination) return;

    if (result.type === 'form-drag') {
      const newOrderFormItems = reorderDragDrog(
        form,
        result.source.index,
        result.destination.index
      );

      setForm(newOrderFormItems);
    }
  }

  const handleItem = useCallback((formItem, index) => {
    const FORM_ITEM_TAG = {
      [`${TAGS.TITLE.tag}`]: (
        <FormTitle
          form={form}
          setForm={setForm}
          formItem={formItem}
          formItemIndex={index}
          errorForm={errorForm}
          setErrorFormItems={setErrorFormItems}
          itemTypeId={TAGS.TITLE.id}
          itemType={TAGS.TITLE.tag}
        />
      ),
      [`${TAGS.IMAGE.tag}`]: (
        <FormImage
          form={form}
          setForm={setForm}
          formItemIndex={index}
          image={image}
          setImage={setImage}
        />
      ),
      [`${TAGS.CLIENT_RESALE.tag}`]: (
        <FormClientResale
          clientId={0}
          resaleId={0}
          showData={false}
          showDefaultData={true}
        />
      ),
      [`${TAGS.ANSWER.tag}`]: (
        <FormAnswer
          form={form}
          setForm={setForm}
          formItem={formItem}
          formItemIndex={index}
          errorForm={errorForm}
          setErrorFormItems={setErrorFormItems}
          itemTypeId={formItem.itemData.itemTypeId}
        />
      ),
    };

    return FORM_ITEM_TAG[formItem.itemTag];
  }, [
    form,
    image,
    setForm,
    errorForm,
    setErrorFormItems,
  ]);

  const handleFormRequired = useCallback((index, formData) => {
    return (
      <FormRequired
        form={form}
        setForm={setForm}
        formItemIndex={index}
        formItemRequiredValue={form[index].itemData.required}
        isTitle={formData.isTitle}
        showRequired={formData.showRequired}
        handleRemoveItemForm={
          () => handleRemoveItemForm(formData.id)
        }
        handleDuplicateItemForm={
          () => handleDuplicateItemForm(formData.id, index)
        }
        handleRemoveActions={formData.unshowActions}
      />
    );
  }, [form, setForm, handleRemoveItemForm, handleDuplicateItemForm]);

  const handleErrorForm = useCallback((itemId) => {
    const [errorRequired] = errorForm.filter(error => error.itemId === itemId);

    return (
      <>
        {errorRequired && errorRequired.errorItem && (
          <small style={{ color: 'red', marginLeft: 7 }}>
            Preencha os campos obrigatórios
          </small>
        )}
      </>
    );
  }, [errorForm]);

  const handleErrorFocus = useCallback((itemId, itemTag) => {
    const [errorFocus] = errorForm.filter(error => error.itemId === itemId);

    return (
      <>
        {(itemTag !== TAGS.CLIENT_RESALE.tag
          || itemTag !== TAGS.IMAGE.tag)
          && errorFocus
          && errorFocus.errorItem
          && (
            <div id='fieldsRequiredId' />
          )}
      </>
    );
  }, [errorForm]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={'droppable-form'}
        direction={'vertical'}
        type={'form-drag'}
      >
        {(provided, snapshot) => (
          <DroppableContainer
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
          >
            {form.map((formItem, index) => (
              <Draggable
                key={`${formItem.id}`}
                draggableId={`${formItem.id}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <DraggableItem
                    ref={provided.innerRef}
                    isDragging={snapshot.isDragging}
                    style={{ ...provided.draggableProps.style }}
                    {...provided.draggableProps}
                  >
                    {handleErrorFocus(formItem.id)}
                    <DraggableHandle {...provided.dragHandleProps}>
                      <DragIcon />
                    </DraggableHandle>
                    {handleItem(formItem, index)}
                    {handleFormRequired(index, formItem)}
                    {handleErrorForm(formItem.id)}
                  </DraggableItem>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </DroppableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
}

FormDragDropContext.propTypes = {
  form: PropTypes.array,
  setForm: PropTypes.func,
  errorForm: PropTypes.array,
  setErrorFormItems: PropTypes.func,
  handleRemoveItemForm: PropTypes.func,
  handleDuplicateItemForm: PropTypes.func,
};

export { FormDragDropContext };
