import React from 'react';
import * as S from './styles';

function InputCheckbox({ ...rest }) {
  return (
    <S.Container>
      <label className="container">
        <input type="checkbox" checked="checked" {...rest} />
        <span className="checkmark"></span>
      </label>
    </S.Container>
  );
}

export { InputCheckbox };
