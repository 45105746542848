import styled from 'styled-components';
import CpfCnpj from '@react-br-forms/cpf-cnpj-mask';

export const InputCpfCnpj = styled(CpfCnpj)`
  height: 30px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 0 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  &::placeholder {
    color: #999;
  }
`;
