import styled from 'styled-components';

export const ModalContentHeader = styled.h3`
  margin-right: 12px;
  color: var(--cm-primary);
`;

export const ModalContentScroll = styled.main`
  margin-top: 20px;
  overflow-y: auto;
  height: 500px;
  border: 1px solid #C5C5D4;
  border-radius: 12px 0 0 12px;

  @media (min-height: 700px) {
    height: 620px;
  }

  @media (max-height: 500px) {
    height: 300px;
  }

  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #616161e5; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid white; /* creates padding around scroll thumb */
  }
`;

export const ModalContentActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;

  button.handleSave {
    color: var(--cm-primary-contrast);
    background-color: var(--cm-primary);
    border: 1px solid var(--cm-primary-shade);
  }
`;
