/* eslint-disable react/prop-types */
import React from 'react';
import * as S from './styles';

function SelectAutoComplete(props) {
  const {
    options,
    customLabel,
    value,
    onChange,
  } = props;

  return (
    <S.SelectDefault
      options={options}
      formatGroupLabel={customLabel}
      value={value}
      onChange={onChange}
    />
  );
}

export { SelectAutoComplete };
