import { formPaths } from '~/routes/paths';

import AddRegister from '~/pages/Form/Register/AddRegister';
import EditRegister from '~/pages/Form/Register/EditRegister';
import FormMaintenance from '~/pages/Form/Maintenance';
import EditMaintenance from '~/pages/Form/Maintenance/EditMaintenance';

import FormRegister from '~/pages/Form/Register';
import ViewerForm from '~/pages/Form/Viewers/ViewerForm';
import ViewerAnswerFilled from '~/pages/Form/Viewers/ViewerAnswerFilled';

import permissions from '~/util/permissions';

const formRoutes = [
  {
    key: 1,
    exact: true,
    hideSidebar: false,
    name: 'Cadastro',
    path: formPaths.formRegister,
    component: FormRegister,
    permission: permissions['view_form-register'],
  },
  {
    key: 2,
    exact: true,
    hideSidebar: true,
    name: 'Novo Formulário',
    path: formPaths.addFormRegister,
    component: AddRegister,
    permission: permissions['create_form-register'],
  },
  {
    key: 3,
    exact: true,
    hideSidebar: true,
    name: 'Editar Formulário',
    path: formPaths.editFormRegister,
    component: EditRegister,
    permission: permissions['edit_form-register'],
  },
  {
    key: 4,
    exact: true,
    hideSidebar: false,
    name: 'Manutenção',
    path: formPaths.formMaintenance,
    component: FormMaintenance,
    permission: permissions['view_form-maintenance'],
  },
  {
    key: 5,
    exact: true,
    hideSidebar: true,
    name: 'Editar Resposta Formulário',
    path: formPaths.editFormMaintenance,
    component: EditMaintenance,
    permission: permissions['edit_form-maintenance'],
  },
  {
    key: 6,
    exact: true,
    hideSidebar: true,
    name: 'Visualização',
    path: formPaths.formViewer,
    component: ViewerForm,
    permission: permissions['view_form-register'],
  },
  {
    key: 7,
    exact: true,
    hideSidebar: true,
    name: 'Visualização Preenchida',
    path: formPaths.formAnswerViewerFilled,
    component: ViewerAnswerFilled,
    permission: permissions['view_form-maintenance'],
  },
];

export default formRoutes;
