import React from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import { MsalProvider } from '@azure/msal-react';
import 'react-toastify/dist/ReactToastify.css';

import Routes from '~/routes';
import GlobalStyles, { Container, RoutesContent } from '~/styles';

import SignIn from '~/pages/Authentication/SignIn';
import Logout from './pages/Authentication/Logout';
import SingleSignOn from '~/pages/Authentication/SingleSignOn';
import ValidateMfa from '~/pages/Authentication/ValidateMfa';
import RecoverPassword from '~/pages/Authentication/RecoverPassword';
import RecoverPasswordRequest from '~/pages/Authentication/RecoverPasswordRequest';
import Invitation from '~/pages/Authentication/Invitation';
import { authenticationPaths } from '~/routes/paths';
import { FilterProvider } from '~/contexts/filter';

import { SidebarProvider } from '~/contexts/sidebar';
import SidebarPageBody from '~/components/SidebarPageBody';

import store from './store';
import history from '~/routes/history';

import './styles.scss';

function App({ instance }) {

  return (
    <MsalProvider instance={instance}>
      <SidebarProvider>
        <Provider store={store}>
          <Router history={history}>
            <GlobalStyles />
            <Container>
              <ToastContainer autoClose={2500} />
              <Switch>
                <Route
                  exact
                  path={authenticationPaths.login}
                  component={SignIn}
                />
                <Route
                  exact
                  path={authenticationPaths.logout}
                  component={Logout}
                />
                <Route
                  exact
                  path={authenticationPaths.singleSignOn}
                  component={SingleSignOn}
                />
                <Route
                  exact
                  path={authenticationPaths.validateMfa}
                  component={ValidateMfa}
                />
                <Route
                  exact
                  path={authenticationPaths.invitation}
                  component={Invitation}
                />
                <Route
                  exact
                  path={authenticationPaths.requestPasswordReset}
                  component={RecoverPassword}
                />
                <Route
                  exact
                  path={authenticationPaths.recoverPasswordRequest}
                  component={RecoverPasswordRequest}
                />
                <Route
                  render={() => (
                    <FilterProvider>
                      <SidebarPageBody>
                        <RoutesContent>
                          <Routes />
                        </RoutesContent>
                      </SidebarPageBody>
                    </FilterProvider>
                  )}
                />
              </Switch>
            </Container>
          </Router>
        </Provider>
      </SidebarProvider>
    </MsalProvider>
  );
}

App.propTypes = {
  instance: PropTypes.any
};

export default App;
