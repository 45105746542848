export const FORM_FILLED_ENUM = {
  PREENCHIDO: 'Preenchido',
  NAO_PREENCHIDO: 'Não Preenchido',
};
Object.freeze(FORM_FILLED_ENUM);

export const FORM_STATUS_ENUM = {
  PENDENTE: { id: 1, label: 'Pendente', value: 'PENDENTE' },
  ATENDIDO: { id: 2, label: 'Atendido', value: 'ATENDIDO' },
  RASCUNHO: { id: 3, label: 'Rascunho', value: 'RASCUNHO' },
};
Object.freeze(FORM_STATUS_ENUM);

export const HELPER_STATUS_FORM = {
  IDS: {
    PENDENTE: 1,
    ATENDIDO: 2,
  },
  ID_TO_TAG: {
    1: 'Pendente',
    2: 'Atendido',
  },
  VALUES: {
    PENDENTE: 'Pendente',
    ATENDIDO: 'Atendido',
  },
  ROUTE: {
    1: 'pendente',
    2: 'atendido',
  },
};
Object.freeze(HELPER_STATUS_FORM);

export const FORM_ITEM_ASNWER_TYPE = {
  SHORT_ANSWER: {
    id: 1,
    tag: 'SHORT_ANSWER',
    value: 'Resposta Curta'
  },
  PARAGRAPH: {
    id: 2,
    tag: 'PARAGRAPH',
    value: 'Parágrafo'
  },
  MULTIPLE_CHOICE: {
    id: 3,
    tag: 'MULTIPLE_CHOICE',
    value: 'Múltipla Escolha'
  },
  SELECTION_BOX: {
    id: 4,
    tag: 'SELECTION_BOX',
    value: 'Caixa de Seleção'
  },
  TITLE: {
    id: 5,
    tag: 'TITLE',
    value: 'Titulo e Descrição'
  },
  IMAGE: {
    id: 6,
    tag: 'IMAGE',
    value: 'Imagem'
  },
  ANSWER: {
    id: 7,
    tag: 'ANSWER',
    value: 'Pergunta'
  },
  CLIENT_RESALE: {
    id: 8,
    tag: 'CLIENT_RESALE',
    value: 'Cliente e Revenda'
  },
};
Object.freeze(FORM_ITEM_ASNWER_TYPE);

export const HELPER_ITEMS = {
  ANSWERS: {
    TAG: {
      ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      SHORT_ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      PARAGRAPH: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      MULTIPLE_CHOICE: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      SELECTION_BOX: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      TITLE: FORM_ITEM_ASNWER_TYPE.TITLE.tag,
      IMAGE: FORM_ITEM_ASNWER_TYPE.IMAGE.tag,
      CLIENT_RESALE: FORM_ITEM_ASNWER_TYPE.CLIENT_RESALE.tag,
    },
    IDS: {
      ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      SHORT_ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      PARAGRAPH: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      MULTIPLE_CHOICE: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      SELECTION_BOX: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      TITLE: FORM_ITEM_ASNWER_TYPE.TITLE.id,
      IMAGE: FORM_ITEM_ASNWER_TYPE.IMAGE.id,
      CLIENT_RESALE: FORM_ITEM_ASNWER_TYPE.CLIENT_RESALE.id,
    }
  },
  DEFAULT: {
    TAG: {
      ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      SHORT_ANSWER: FORM_ITEM_ASNWER_TYPE.SHORT_ANSWER.tag,
      PARAGRAPH: FORM_ITEM_ASNWER_TYPE.PARAGRAPH.tag,
      MULTIPLE_CHOICE: FORM_ITEM_ASNWER_TYPE.MULTIPLE_CHOICE.tag,
      SELECTION_BOX: FORM_ITEM_ASNWER_TYPE.SELECTION_BOX.tag,
      TITLE: FORM_ITEM_ASNWER_TYPE.TITLE.tag,
      IMAGE: FORM_ITEM_ASNWER_TYPE.IMAGE.tag,
      CLIENT_RESALE: FORM_ITEM_ASNWER_TYPE.CLIENT_RESALE.tag,
    },
    IDS: {
      ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      SHORT_ANSWER: FORM_ITEM_ASNWER_TYPE.SHORT_ANSWER.id,
      PARAGRAPH: FORM_ITEM_ASNWER_TYPE.PARAGRAPH.id,
      MULTIPLE_CHOICE: FORM_ITEM_ASNWER_TYPE.MULTIPLE_CHOICE.id,
      SELECTION_BOX: FORM_ITEM_ASNWER_TYPE.SELECTION_BOX.id,
      TITLE: FORM_ITEM_ASNWER_TYPE.TITLE.id,
      IMAGE: FORM_ITEM_ASNWER_TYPE.IMAGE.id,
      CLIENT_RESALE: FORM_ITEM_ASNWER_TYPE.CLIENT_RESALE.id,
    }
  }
};
Object.freeze(HELPER_ITEMS);

export const FORM_ITEM_IMAGE_TYPE = {
  ALIGN_LEFT: {
    id: 1,
    tag: 'flex-start',
    value: 'Alinhar à Esquerda'
  },
  ALIGN_CENTER: {
    id: 2,
    tag: 'center',
    value: 'Alinhar ao Centro'
  },
  ALIGN_RIGHT: {
    id: 3,
    tag: 'flex-end',
    value: 'Alinhar à Direita'
  },
  ALTER_IMAGE: {
    id: 4,
    tag: 'alter-image',
    value: 'Alterar'
  },
};
Object.freeze(FORM_ITEM_ASNWER_TYPE);

export const FORM_ACTION_LOG_OPERATION_TYPE = {
  CREATE_RESPONSE_FORM: { TAG: 'CREATE_RESPONSE_FORM' },
  EDIT_VALUE_FIELD: { TAG: 'EDIT_VALUE_FIELD' },
  SING_FORM_RESPOSIBLE: { TAG: 'SING_FORM_RESPOSIBLE' },
  EDIT_ANALYSIS_DATE: { TAG: 'EDIT_ANALYSIS_DATE' },
  CHANGE_STATUS_FORM: { TAG: 'CHANGE_STATUS_FORM' },
};
Object.freeze(FORM_ITEM_ASNWER_TYPE);

export const OTHER_INPUT_UNUM = {
  KEY: '_option_other',
  VALUE: 'Outro',
};
Object.freeze(OTHER_INPUT_UNUM);
