import styled from 'styled-components';
import { Fragment } from 'react';

export const TableHeadBreaks = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
  margin-top: 32px;
`;

export const TableHeadBreaksColumn = styled.span`
  width: 40%;
`;

export const TableReport = Fragment;
export const TableBodyContainer = Fragment;
