import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import { FaCircle } from 'react-icons/fa';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import Modal from '~/components/Modal';
import { toast } from 'react-toastify';
import api, { endpoints } from '~/services/api';
import { formPaths } from '~/routes/paths';
import { v4 as uuid_v4 } from 'uuid';
import { HELPER_STATUS_FORM } from '~/util/domainutils';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import ReactToPrint from 'react-to-print';
import PrintableForm from '~/components/PrintableForm';
import {
  FORM_ITEM_ASNWER_TYPE as TAGS,
  FORM_ACTION_LOG_OPERATION_TYPE as LOGS_TYPE,
} from '~/util/domainutils';
import { FormClientResale } from '../../Items/FormClientResale';
import { useForm } from 'react-hook-form';

import * as S from './styles';

function EditMaintenance({ history, location }) {
  const [responsibleName, setResponsibleName] = useState('');
  const [previousResponsibleName, setPreviousResponsibleName] = useState('');
  const [finishedAt, setFinishedAt] = useState('');
  const [previousFinishedAt, setPreviousFinishedAt] = useState('');
  const [statusId, setStatusId] = useState(0);
  const [previousStatusId, setPreviousStatusId] = useState(0);
  const [logs, setLogs] = useState([]);
  const [previousAnswer, setPreviousAnswer] = useState({});
  const [answeredFilled, setAnsweredFilled] = useState({});
  const [loading, setLoading] = useState(false);
  const [formViewer, setFormViewer] = useState(null);
  const [contentItemsFormAnswer, setContentItemsFormAnswer] = useState([]);
  const [errorResponsible, setErrorResponsible] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm({
    defaultValues: {}
  });

  const {
    id: userId,
    nome: userName,
    permissions: userPermissions,
  } = useSelector(state => state.user.data);

  const componentRef = useRef(null);

  const userCanFinishedFormAnswer = useMemo(
    () => userPermissions.includes(permissions['finished_form-maintenance']),
    [userPermissions]
  );

  useEffect(() => {
    if (!location.state || !location.state.extraInfo) {
      setFormViewer(null);
      history.push(location.state.pathReferenceToBack);
    }

    if (location.state.extraInfo) {
      const extraInfo = JSON.parse(location.state.extraInfo);
      const actionLogs = location.state.actionLogs.map(log => {
        return {
          ...log,
          currentContent: JSON.parse(log.currentContent),
        };
      });
      reset(extraInfo.defaultValues);
      setLogs(actionLogs);
      handleSetDataInitial(
        location.state.responsibleName,
        location.state.finishedAt,
        location.state,
        extraInfo.defaultValues,
        location.state.status.id
      );
    }
  }, [history, location.state, reset]);

  useEffect(() => {
    if (!location.state.idForm) {
      setFormViewer(null);
      history.push(location.state.pathReferenceToBack);
    }

    let cleaningAsyncEffect = false;

    const asyncEffect = async () => {
      if (cleaningAsyncEffect) return;
      setLoading(true);
      try {
        const formViewer = await api.get(
          endpoints.form.register.getForm(location.state.idForm)
        );

        const [formResponse] = formViewer.data.data.resultado;

        let dataFormViewer = {
          formName: formResponse.name,
          softwareName: formResponse.softwareName,
          moduleName: formResponse.moduleName,
          createdAt: formResponse.createdAt
        };

        let contentItemsFormAnswerTemp = [];
        const itemForm = formResponse.itemForms.map((f, index) => {
          if (TAGS.TITLE.tag === f.itemType.tag) {
            const titleType = {
              id: f.id,
              itemId: TAGS.TITLE.id,
              itemTag: TAGS.TITLE.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: f.itemContent,
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: true,
              showRequired: true,
              unshowActions: false,
            };

            return titleType;
          }

          if (TAGS.SHORT_ANSWER.tag === f.itemType.tag
            || TAGS.PARAGRAPH.tag === f.itemType.tag
            || TAGS.MULTIPLE_CHOICE.tag === f.itemType.tag
            || TAGS.SELECTION_BOX.tag === f.itemType.tag
          ) {
            let titleGroup = '';

            titleGroup += formResponse.itemForms[index].statement;
            titleGroup = titleGroup
              .toLowerCase()
              .trim()
              .replace(/\s/g, '_');
            titleGroup = `${index}_${titleGroup}`;
            titleGroup = titleGroup
              .normalize('NFD')
              .replace(/([\u0300-\u036f]|[^_0-9a-zA-Z])/g, '');

            contentItemsFormAnswerTemp.push({
              itemFormId: f.id,
              answerContent: {
                attrName: titleGroup.trim(),
              },
            });

            let itemContent = null;

            if (f.itemContent) {
              itemContent = JSON.parse(f.itemContent);
            }

            const answerType = {
              id: f.id,
              itemId: TAGS.ANSWER.id,
              itemTag: TAGS.ANSWER.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: itemContent ? itemContent : f.itemContent,
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: false,
              showRequired: true,
              unshowActions: false,
            };

            return answerType;
          }

          if (TAGS.IMAGE.tag === f.itemType.tag) {
            const itemContent = JSON.parse(f.itemContent);
            const imageObject = JSON.parse(itemContent.object);

            const imageType = {
              id: f.id,
              itemId: f.itemType.id,
              itemTag: f.itemType.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: {
                  align: itemContent.align,
                  src: itemContent.src,
                  object: imageObject
                },
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: false,
              showRequired: false,
              unshowActions: false,
            };

            return imageType;
          }

          if (TAGS.CLIENT_RESALE.tag === f.itemType.tag) {
            const itemContent = JSON.parse(f.itemContent);

            const clientResaleType = {
              id: f.id,
              itemId: f.itemType.id,
              itemTag: f.itemType.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: {
                  clientId: location.state.idClient,
                  resaleId: location.state.userCreateOrderId,
                  showData: itemContent.showData,
                  showDefaultData: itemContent.showDefaultData,
                },
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: false,
              showRequired: false,
              unshowActions: true,
            };

            return clientResaleType;
          }
        });

        let contentItemsForm = itemForm.filter(item => {
          if (item) return item;
        });

        dataFormViewer = {
          ...dataFormViewer,
          contentItemsForm,
        };

        setContentItemsFormAnswer(contentItemsFormAnswerTemp);
        setFormViewer(dataFormViewer);
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();

    return () => {
      cleaningAsyncEffect = true;
    };
  }, [location.state]);

  function handleSetDataInitial(responsible, finished, state, answer, status) {
    handleSetCurrentData(responsible, finished, state, status);
    handleSetPreviousData(responsible, finished, answer, status);
  }

  function handleSetCurrentData(responsible, finished, state, status) {
    setResponsibleName(responsible);
    setFinishedAt(finished);
    setAnsweredFilled(state);
    setStatusId(status);
  }

  function handleSetPreviousData(responsible, finished, answer, status) {
    setPreviousResponsibleName(responsible);
    setPreviousFinishedAt(finished);
    setPreviousAnswer(answer);
    setPreviousStatusId(status);
  }

  function handleSetResponsibleNameChange(event) {
    const {
      target: {
        value
      }
    } = event;

    setResponsibleName(value);
    setErrorResponsible(false);
  }

  function handleSetFinishedAtChange(event) {
    const {
      target: {
        value
      }
    } = event;

    setFinishedAt(value);
    setErrorDate(false);
  }

  function handleStatusIdChange(event) {
    const {
      target: {
        value
      }
    } = event;

    setStatusId(value);
  }

  function handleViewerClientResale() {
    return (
      <S.ViewerClientResale>
        <S.ViewerSoftwareModule>
          <span><b>Software:</b> {formViewer.softwareName}</span>
          <span><b>Módulo:</b> {formViewer.moduleName}</span>
          <span><b>Solicitado em:</b> {answeredFilled.requestedIn}</span>
        </S.ViewerSoftwareModule>
        <FormClientResale
          clientId={location.state.idClient}
          resaleId={location.state.userCreateOrderId}
          showData={true}
          showDefaultData={false}
          withBorder={true}
        />
      </S.ViewerClientResale>
    );
  }

  function handleViewerImage(item, index) {
    const { src, align } = item.itemData.itemContent;

    return (
      <S.ViewerImage justifyContent={align}>
        <img src={src} alt={`Posição ${index}`} />
      </S.ViewerImage>
    );
  }

  function handleViewerTitle(item) {
    const { statement, description, required } = item.itemData;

    let formatDescription = [];

    if (description) {
      formatDescription = description.split('\n');
    }

    return (
      <S.ViewerTitle>
        {required ? (
          <S.ViewerTitle1>
            {statement && (
              <h1>{statement.toUpperCase()}</h1>
            )}
          </S.ViewerTitle1>
        ) : (
          <S.ViewerTitle2>
            {statement && (
              <b><u>{statement.toUpperCase()}</u></b>
            )}
          </S.ViewerTitle2>
        )}
        {formatDescription.map((description, index) => (
          <S.ViewerDescription key={index}>
            {description}
          </S.ViewerDescription>
        ))}
      </S.ViewerTitle>
    );
  }

  function handleShowOptionOther(other) {
    if (watch(other)) {
      if (typeof watch(other) === 'object') {
        return !watch(other).includes('Outro');
      }

      if (typeof watch(other) === 'string') {
        return watch(other) !== 'Outro';
      }
    }

    return true;
  }

  function handleViewerAnswer(item) {
    const {
      statement,
      description,
      required,
      itemTypeId,
      itemContent,
    } = item.itemData;

    let titleGroup = contentItemsFormAnswer.map(input => {
      if (input.itemFormId === item.id) return input.answerContent.attrName;
    });

    [titleGroup] = titleGroup.filter(attrName => { if (attrName) return attrName; });

    const optionOther = [];
    const optionWithoutOther = [];

    if (itemContent) {
      itemContent.map((option) => {
        if (!option.other) {
          optionWithoutOther.push(option);
        } else {
          optionOther.push(option);
        }
      });
    }

    const radio = { 3: 'radio' };
    const checkbox = { 4: 'checkbox' };
    const HELPERS_TYPE = { ...radio, ...checkbox };

    const getOptionValue = (option) => {
      return typeof option.itemData.value === 'string'
        ? option.itemData.value.trim()
        : option.itemData.value;
    };

    return (
      <S.ViewerAnswerContainer>
        <label htmlFor={titleGroup}>
          <S.ViewerAnswer>
            {statement}{'  '}{required && '*'}
          </S.ViewerAnswer>
        </label>
        {description && (
          <S.ViewerDescriptionContainer>
            <S.ViewerDescription>
              {description}
            </S.ViewerDescription>
          </S.ViewerDescriptionContainer>
        )}
        {itemTypeId === TAGS.SHORT_ANSWER.id && (
          <input
            ref={register()}
            name={titleGroup}
            {...register(titleGroup, { required })}
          />
        )}
        {itemTypeId === TAGS.PARAGRAPH.id && (
          <textarea
            ref={register()}
            name={titleGroup}
            {...register(titleGroup, { required })}
          />
        )}
        {(itemTypeId === TAGS.MULTIPLE_CHOICE.id
          || itemTypeId === TAGS.SELECTION_BOX.id)
          && (
            <S.ViewerOptions>
              {optionWithoutOther && (
                <S.ViewerOptionsContainer>
                  {optionWithoutOther.map((option) => (
                    <S.ViewerOption key={option.id}>
                      {!option.other && (
                        <S.ViewerOptionContainer>
                          <input
                            ref={register()}
                            name={titleGroup}
                            className={'viewer-option'}
                            type={HELPERS_TYPE[itemTypeId]}
                            value={getOptionValue(option)}
                            {...register(titleGroup, { required })}
                          />
                          <label htmlFor={titleGroup}>
                            {option.itemData.value}
                          </label>
                        </S.ViewerOptionContainer>
                      )}
                    </S.ViewerOption>
                  ))}
                </S.ViewerOptionsContainer>
              )}
              {optionOther[0] && (
                <S.ViewerOther>
                  <S.ViewerOptionOther>
                    <input
                      ref={register()}
                      name={titleGroup}
                      className={'viewer-option'}
                      value={optionOther[0].itemData.label}
                      type={HELPERS_TYPE[itemTypeId]}
                      {...register(titleGroup, { required })}
                    />
                    <label htmlFor={optionOther[0].itemData.label}>
                      {'Outro:'}
                    </label>
                  </S.ViewerOptionOther>
                  <input
                    ref={register()}
                    name={`${titleGroup}_option_other`}
                    disabled={handleShowOptionOther(titleGroup)}
                    {...register(
                      `${titleGroup}_option_other`,
                      { required: !handleShowOptionOther(titleGroup) },
                    )}
                  />
                </S.ViewerOther>
              )}
            </S.ViewerOptions>
          )}
        {(errors[titleGroup] || (
          !handleShowOptionOther(titleGroup) && errors[`${titleGroup}_option_other`]
        ))
          && (
            <S.ErrorField>{'Campo obrigatório'}</S.ErrorField>
          )}
      </S.ViewerAnswerContainer>
    );
  }

  function handleScroll(elementId) {
    setTimeout(() => {
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  }

  async function validateAnswerForm(form, success) {
    const data = {
      id: answeredFilled.idAnswer,
      formId: answeredFilled.idForm,
      clientId: answeredFilled.idClient,
      orderId: answeredFilled.idOrder,
      resellerId: answeredFilled.idResale,
      responsibleName: '',
      finishedAt: '',
    };

    for (const formItem in form) {
      if (typeof form[formItem] === 'string') {
        form[formItem] = form[formItem].trim();
      }
    }

    const logsToAdd = [];
    for (const formItem in previousAnswer) {
      if (typeof previousAnswer[formItem] === 'string') {
        let formItemCurrent = form[formItem];

        if (form[formItem] === 'Outro') {
          formItemCurrent = form[`${formItem}_option_other`];
        }

        if (previousAnswer[formItem] !== formItemCurrent) {
          const [itemFormAnswered] = contentItemsFormAnswer.filter(input => {
            return input.answerContent.attrName === formItem;
          });

          if (itemFormAnswered) {
            const [itemForm] = formViewer.contentItemsForm.filter(input => {
              return input.id === itemFormAnswered.itemFormId;
            });

            let currentValue = form[formItem];

            if (form[formItem] === 'Outro') {
              currentValue = form[`${formItem}_option_other`];
            }

            const action = {
              userId,
              operationTypeId: LOGS_TYPE.EDIT_VALUE_FIELD.TAG,
              formAnswerId: answeredFilled.idAnswer,
              previousContent: previousAnswer[formItem],
              currentContent: JSON.stringify({
                currentData: {
                  logId: uuid_v4(),
                  userName,
                  type: itemForm.itemData.itemTypeId,
                },
                item: itemForm.itemData.statement,
                value: currentValue,
              }),
              createdAt: moment().toISOString(),
            };

            logsToAdd.push(action);
          }
        }
      }

      if (typeof previousAnswer[formItem] === 'object') {
        if (previousAnswer[formItem].join() !== form[formItem].join()) {
          const itemsCheckbox = [];
          const itemsToAdd = [];
          const itemsToRemove = [];

          form[formItem].map(itemCheckbox => {
            if (!previousAnswer[formItem].includes(itemCheckbox)) {
              if (!itemsToAdd.includes(itemCheckbox)) {
                if (itemCheckbox === 'Outro') {
                  itemsToAdd.push(form[`${formItem}_option_other`]);
                } else {
                  itemsToAdd.push(itemCheckbox);
                }
              }
            }
            itemsCheckbox.push(itemCheckbox);
          });

          previousAnswer[formItem].map(itemCheckbox => {
            if (!itemsCheckbox.includes(itemCheckbox)) {
              if (!itemsToAdd.includes(itemCheckbox)) {
                if (itemCheckbox === 'Outro') {
                  itemsToRemove.push(previousAnswer[`${formItem}_option_other`]);
                } else {
                  itemsToRemove.push(itemCheckbox);
                }
              }
            }
          });

          const [itemFormAnswered] = contentItemsFormAnswer.filter(input => {
            return input.answerContent.attrName === formItem;
          });

          if (itemFormAnswered) {
            const [itemForm] = formViewer.contentItemsForm.filter(input => {
              return input.id === itemFormAnswered.itemFormId;
            });

            if (itemsToAdd.length) {
              const action = {
                userId,
                operationTypeId: LOGS_TYPE.EDIT_VALUE_FIELD.TAG,
                formAnswerId: answeredFilled.idAnswer,
                previousContent: '',
                currentContent: JSON.stringify({
                  currentData: {
                    logId: uuid_v4(),
                    userName,
                    type: 'checkbox_add',
                  },
                  item: itemForm.itemData.statement,
                  value: itemsToAdd.join(', '),
                }),
                createdAt: moment().toISOString(),
              };

              logsToAdd.push(action);
            }

            if (itemsToRemove.length) {
              const action = {
                userId,
                operationTypeId: LOGS_TYPE.EDIT_VALUE_FIELD.TAG,
                formAnswerId: answeredFilled.idAnswer,
                previousContent: '',
                currentContent: JSON.stringify({
                  currentData: {
                    logId: uuid_v4(),
                    userName,
                    type: 'checkbox_remove',
                  },
                  item: itemForm.itemData.statement,
                  value: itemsToRemove.join(', '),
                }),
                createdAt: moment().toISOString(),
              };

              logsToAdd.push(action);
            }
          }
        }
      }
    }

    data.extraInfo = JSON.stringify({
      defaultValues: form,
      attrsName: contentItemsFormAnswer.map(
        input => input.answerContent.attrName,
      )
    });

    data.itemFormAnswers = contentItemsFormAnswer.map((input) => {
      const [itemCurrent] = answeredFilled.itemAnswers.filter(item => {
        return item.itemFormId === input.itemFormId;
      });

      return {
        id: itemCurrent.id,
        formAnswerId: answeredFilled.idAnswer,
        formId: answeredFilled.idForm,
        itemFormId: input.itemFormId,
        answerContent: JSON.stringify(form[input.answerContent.attrName]),
      };
    });

    if (userCanFinishedFormAnswer) {
      let errors_temp = false;

      if (!responsibleName || !responsibleName.length) {
        setErrorResponsible(true);
        errors_temp = true;
      }

      if (!finishedAt || !finishedAt.length) {
        setErrorDate(true);
        errors_temp = true;
      }

      if (!statusId || parseInt(statusId) === 0) {
        toast('Selecione um Status para o formulário', { type: toast.TYPE.ERROR });
        errors_temp = true;
      }

      if (errors_temp) {
        handleScroll('focusInput');
        return;
      }

      if (previousResponsibleName !== responsibleName) {
        const action = {
          userId,
          operationTypeId: LOGS_TYPE.SING_FORM_RESPOSIBLE.TAG,
          formAnswerId: answeredFilled.idAnswer,
          previousContent: previousResponsibleName
            ? previousResponsibleName : '',
          currentContent: JSON.stringify({
            currentData: {
              logId: uuid_v4(),
              userName,
              type: 'analyst',
            },
            item: 'Analista',
            value: responsibleName,
          }),
          createdAt: moment().toISOString(),
        };

        logsToAdd.push(action);
      }

      data.responsibleName = responsibleName
        ? responsibleName.trim()
        : responsibleName;

      if (previousFinishedAt !== finishedAt) {
        const action = {
          userId,
          operationTypeId: LOGS_TYPE.EDIT_ANALYSIS_DATE.TAG,
          formAnswerId: answeredFilled.idAnswer,
          previousContent: previousFinishedAt
            ? previousFinishedAt : '',
          currentContent: JSON.stringify({
            currentData: {
              logId: uuid_v4(),
              userName,
              type: 'date',
            },
            item: 'Data Análise',
            value: finishedAt,
          }),
          createdAt: moment().toISOString(),
        };

        logsToAdd.push(action);

        data.finishedAt = finishedAt
          ? finishedAt.trim()
          : finishedAt;
      }
    }

    if (parseInt(previousStatusId) !== parseInt(statusId)) {
      const action = {
        userId,
        operationTypeId: LOGS_TYPE.CHANGE_STATUS_FORM.TAG,
        formAnswerId: answeredFilled.idAnswer,
        previousContent: HELPER_STATUS_FORM.ID_TO_TAG[previousStatusId],
        currentContent: JSON.stringify({
          currentData: {
            logId: uuid_v4(),
            userName,
            type: 'status',
          },
          item: 'Status',
          value: HELPER_STATUS_FORM.ID_TO_TAG[statusId],
        }),
        createdAt: moment().toISOString(),
      };

      logsToAdd.push(action);
    }

    data.statusId = parseInt(statusId);
    data.logs = logsToAdd;

    return success(data);
  }

  const onSubmit = useCallback(async (form) => {
    await validateAnswerForm(form, async (data) => {
      setLoading(true);
      try {
        const { data: response } = await api.put(
          endpoints.form.maintenance.updateFormAnswer,
          data,
        );

        if (response.data.data.ok) {
          toast.success(response.message);

          const messageErro = response.data.messages.messagesErrorBackground;
          const messageSuccess = response.data.messages.messagesSuccessBackground;

          setTimeout(() => {
            if (messageErro.length) {
              messageErro.forEach(message => {
                if (message) toast.error(message);
              });
            }
          }, 100); // 0.10 segundos

          setTimeout(() => {
            if (messageSuccess.length) {
              messageSuccess.forEach(message => {
                if (message) toast.success(message);
              });
            }
          }, 200); // 0.20 segundos

          setTimeout(() => {
            history.push(formPaths.formMaintenance);
          }, 300); // 0.25 segundos
        }
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });
  }, [validateAnswerForm]);

  function renderActionLogs(logs) {
    const logType = (log) => {
      return !log.currentContent.currentData.type.toString().indexOf('checkbox');
    };

    const isTypeCheckbox = (type) => {
      return type.toString() === 'checkbox_add' ? 'adicionou' : 'removeu';
    };

    const renderLog = (log) => {
      const type = log.currentContent.currentData.type;
      const userName = log.currentContent.currentData.userName;
      const item = log.currentContent.item;
      const previousContent = log.previousContent;
      const currentContent = log.currentContent.value;

      const createdAt = (date) => {
        return moment(date).format('DD/MM/YYYY, HH:mm:ss');
      };

      let msg_user = (<b>{userName}</b>);
      let msg_update = (<> atualizou <b>{item}</b></>);
      let msg_previous = null;
      let msg_type_checkbox = (<> e {isTypeCheckbox(type)} o(s) item(s) </>);
      let msg_current = null;
      let msg_updatedAt = (<> em {createdAt(log.createdAt)}.</>);

      if (previousContent) {
        msg_previous = (<> de <b>{previousContent}</b></>);
      }

      if (!currentContent) {
        msg_current = (<> deixou o item <b>{item}</b> em branco</>);
      } else {
        if (logType(log)) {
          msg_current = (<> <b>{currentContent}</b></>);
        } else {
          msg_current = (<> para <b>{currentContent}</b></>);
        }
      }

      return (
        <p key={`${log.currentContent.logId}-${log.id}`}>
          <FaCircle />
          {logType(log) ? (
            <span>
              {msg_user}{msg_update}{msg_type_checkbox}{msg_current}{msg_updatedAt}
            </span>
          ) : (
            <span>
              {msg_user}{msg_update}{msg_previous}{msg_current}{msg_updatedAt}
            </span>
          )}
        </p>
      );
    };

    return logs.map(log => renderLog(log));
  }

  return (
    <S.ViewerAnswerForm>
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Formulário"
        pageName="Editar Formulário"
        breadcrumbs={[{
          label: 'Manutenção',
          onClick: () => (() => {
            setFormViewer(null);
            return history.push(location.state.pathReferenceToBack);
          })
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => handleSubmit(onSubmit),
          main: true,
        }}
        customShowReport={(
          <ReactToPrint
            key={'to_viewer_answer_print'}
            trigger={() => (
              <div className="buttonBase baseWebMobile addMargin">
                IMPRIMIR
              </div>
            )}
            content={() => componentRef.current}
          />
        )}
        buttonBackHandler={() => {
          setFormViewer(null);
          return history.push(location.state.pathReferenceToBack);
        }}
        openButtonSelect
      />
      <PrintableForm
        ref={componentRef}
        location={location}
        formViewer={formViewer}
        answeredFilled={answeredFilled}
        contentItemsFormAnswer={contentItemsFormAnswer}
      />
      <S.Container>
        <S.ViewerContainer>
          <span className='title-required'>
            {'Campos com (*) são obrigatórios'}
          </span>
          {formViewer && (
            <form onSubmit={handleSubmit(onSubmit)}>
              {formViewer.contentItemsForm.map((item, index) => (
                <S.ViewerContainerItem key={item.id}>
                  {item.itemTag === TAGS.CLIENT_RESALE.tag && (
                    handleViewerClientResale()
                  )}
                  {item.itemTag === TAGS.IMAGE.tag && (
                    handleViewerImage(item, index)
                  )}
                  {item.itemTag === TAGS.TITLE.tag && (
                    handleViewerTitle(item)
                  )}
                  {item.itemTag === TAGS.ANSWER.tag && (
                    handleViewerAnswer(item, index)
                  )}
                </S.ViewerContainerItem>
              ))}
            </form>
          )}
          {userCanFinishedFormAnswer && (
            <S.ViewLogs>
              <header>
                <div>
                  <div>
                    {errorResponsible && <div id='focusInput' />}
                    <label>Analista *</label>
                    <input
                      type='text'
                      value={responsibleName}
                      onChange={handleSetResponsibleNameChange}
                    />
                    {errorResponsible && (
                      <S.ErrorView>Campo obrigatório</S.ErrorView>
                    )}
                  </div>
                  <div>
                    {errorDate && <div id='focusInput' />}
                    <label>Data Análise</label>
                    <S.InputData
                      type='text'
                      value={finishedAt}
                      onChange={handleSetFinishedAtChange}
                      mask={'99/99/9999'}
                    />
                    {errorDate && (
                      <S.ErrorView>Campo obrigatório</S.ErrorView>
                    )}
                  </div>
                </div>
                <div className='statusFormularioResposta'>
                  <label>Status</label>
                  <select
                    value={statusId}
                    onChange={handleStatusIdChange}
                  >
                    <option value={0}>Selecione</option>
                    <option value={1}>Pendente</option>
                    <option value={2}>Atendido</option>
                  </select>
                </div>
              </header>
              <main>
                <label>Log:</label>
                <br />
                {logs && renderActionLogs(logs)}
              </main>
            </S.ViewLogs>
          )}
        </S.ViewerContainer>
      </S.Container>
    </S.ViewerAnswerForm>
  );
}

EditMaintenance.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      idAnswer: PropTypes.number.isRequired,
      idForm: PropTypes.number.isRequired,
      idOrder: PropTypes.number.isRequired,
      idClient: PropTypes.number.isRequired,
      idResale: PropTypes.number.isRequired,
      userCreateOrderId: PropTypes.number.isRequired,
      status: PropTypes.object.isRequired,
      extraInfo: PropTypes.string.isRequired,
      itemAnswers: PropTypes.array.isRequired,
      actionLogs: PropTypes.array.isRequired,
      responsibleName: PropTypes.string,
      finishedAt: PropTypes.string,
      pathReferenceToBack: PropTypes.string.isRequired,
    }),
  }),
};

export default (EditMaintenance);
