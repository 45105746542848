import styled from 'styled-components';

export const FiltersReportContainer = styled.div`
  margin-top: 32px;
`;

export const FilterItemsReport = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  h3 {
    font-weight: normal;
  }
`;

export const FilterItemsReportContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const FilterItemReport = styled.div`
  margin-left: 20px;
`;
