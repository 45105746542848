import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { commercialPaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import { ReasonSolicitationService } from '~/services/commercial/reasonsolicitation';
import { LineBusinessService } from '~/services/person/linebusiness';
import PersonResaleService from '~/services/person-resale-service';
import PersonEmployeeService from '~/services/person-employee-service';
import { showMessageError } from '~/util/errorutils';
import {
  PERSON_STATUS_ENUM,
  SOLICITATION_MESSAGE_COLORS,
  STATUS_SOLICITATION_ENUM,
  USER_TYPE,
} from '~/util/domainutils';
import permissions from '~/util/permissions';
import Modal from '~/components/Modal';
import { ShowDataClient, ShowDataBackgound } from '~/components/ShowDataClient';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import Attachments from '~/components/Attachments';
import FieldsClientModal from '~/components/FieldsClientModal';
import { AttachmentDownload } from '~/components/AttachmentDownload';
import { handleMoveElement } from '~/util/scrollutils';
import { SolicitationService } from '~/services/commercial/solicitation';
import {
  formatCpfCnpj,
  formatPhone,
  normalizeText,
  unFormatCpfCnpj,
  unFormatPunctuation,
  validePhone,
} from '~/util/stringutils';
import { SUPORT_FILES } from '~/util/fileutils';
import * as S from './styles';
import './styles.scss';

class EditCommercialSolicitation extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        id: PropTypes.number,
      }).isRequired,
    }).isRequired,
    user: PropTypes.shape({}).isRequired,
  };

  constructor(props) {
    super(props);
    const { history, location, user } = props;
    this.user = user;
    this.history = history;
    this.location = location;
    this.search = window.location.search;
    this.refAttachments = React.createRef();
    this.state = {
      solicitationObject: null,
      reasonSolicitations: [],
      loading: true,
      statusAtual: STATUS_SOLICITATION_ENUM.AGUARDANDO_ANALISE_CM.value,
      inputRadioStatus: '',
      inputRevendaCpfCnpj: '',
      inputRevendaRazaoSocial: '',
      inputRevendaNomeFantasia: '',
      inputRevendaResponsavelExecutivo: '',
      inputCpfCnpj: '',
      inputRazaoSocial: '',
      selectTipoSolicitacao: '',
      inputOriginalAssunto: '',
      textareaOriginalTexto: '',
      inputDataHora: '',
      inputAssunto: '',
      textareaTexto: '',
      anexos: [],
      mensagens: [],
      mensagensStatus: [],
      solicitacaoAnexos: [],
      extra: null,
      extraRevendaDefault: null,
      showModalEditClient: null,
      requester: {}
    };
  }

  loadLinkAccess = () => {
    const solicitation_id = Number(this.search.split('?solicitation_id=')[1]);
    const isPathSolicitationId = this.search.includes('?solicitation_id=');
    return {
      status: isPathSolicitationId,
      solicitation_id: isPathSolicitationId ? solicitation_id : undefined,
    };
  }

  loadData = async () => {
    this.setLoading(true);
    const resLinkAccess = this.loadLinkAccess();
    const solObjId = resLinkAccess.status
      ? resLinkAccess.solicitation_id
      : this.location.state.id;

    this.id = solObjId;

    api
      .post(endpoints.commercial.solicitation.getCommercialSolicitations, {
        id: solObjId
      })
      .then((result) => {
        if (result?.data?.data?.resultado) {
          const [solicitation] = result.data.data.resultado;

          this.setState({
            statusAtual: solicitation.statusAtual.status,
            inputRadioStatus: solicitation.statusAtual.status,
            inputCpfCnpj: formatCpfCnpj((solicitation?.manifestante?.cpfCnpj || '')),
            inputRazaoSocial: (solicitation?.manifestante?.razaoSocial || ''),
            selectTipoSolicitacao: solicitation.tipoMotivoId,
            inputDataHora: moment(solicitation.createdAt).format('DD/MM/YYYY, HH:mm:ss'),
            inputOriginalAssunto: solicitation.assunto,
            inputAssunto: solicitation.assunto,
            textareaOriginalTexto: solicitation.texto,
            mensagens: solicitation.mensagems
              ? solicitation.mensagems : [],
            mensagensStatus: solicitation.statusSolicitacaos
              ? solicitation.statusSolicitacaos : [],
            solicitacaoAnexos: solicitation.solicitacaoAnexos
              ? solicitation.solicitacaoAnexos : [],
            extra: solicitation.extra ? JSON.parse(solicitation.extra) : null,
            solicitationObject: solicitation,
            requester: solicitation?.solicitante || {}
          });

          return solicitation;
        } else {
          toast('Erro ao consultar solicitação.', { type: toast.TYPE.ERROR });
          setTimeout(() => {
            this.history.push(commercialPaths.commercialSolicitation);
          }, 100);
        }
      })
      .then((solicitation) => {
        const existDataResale = () => {
          return !this.state.inputRevendaCpfCnpj
            && !this.state.inputRevendaRazaoSocial
            && !this.state.inputRevendaNomeFantasia;
        };

        if (this.user.userCanReplyAsCM && existDataResale()) {
          let getRevData = solicitation.solicitante;

          let sameResale = false;
          let sameResaleId = null;
          if (solicitation.extra) {
            const extreTemp = JSON.parse(solicitation.extra);
            const revendaIdObj = extreTemp?.paraCriar?.cliente?.revendaId;

            if (revendaIdObj) {
              const revId = Number(revendaIdObj);
              if (revId === Number(solicitation.solicitante.revendaId)) {
                sameResale = true;
              }

              sameResaleId = revId;
            } else {
              sameResaleId = Number(solicitation.solicitante.revendaId);
            }
          }

          if (USER_TYPE.REVENDA === solicitation.solicitante.tipo) {
            this.setState({
              inputRevendaCpfCnpj: getRevData
                ? formatCpfCnpj(getRevData.cpfCnpj) : '',
              inputRevendaRazaoSocial: (getRevData.razaoSocial || ''),
              inputRevendaNomeFantasia: (getRevData.nomeFantasia || ''),
            });
          } else {
            PersonResaleService
              .getPersonResales({ id: solicitation.solicitante.revendaId })
              .then((valuesData) => {
                if (valuesData.resultado.length) {
                  getRevData = valuesData.resultado[0];
                  this.setState({
                    inputRevendaCpfCnpj: getRevData
                      ? formatCpfCnpj(getRevData.cpfCnpj) : '',
                    inputRevendaRazaoSocial: (getRevData.razaoSocial || ''),
                    inputRevendaNomeFantasia: (getRevData.nomeFantasia || ''),
                  });
                }
              })
              .catch((error) => {
                showMessageError(error);
                this.setState({
                  inputRevendaCpfCnpj: '',
                  inputRevendaRazaoSocial: '',
                  inputRevendaNomeFantasia: '',
                });
              });
          }

          if (!this.state.extraRevendaDefault) {
            if (sameResale) {
              this.setState({
                extraRevendaDefault: {
                  id: getRevData.id,
                  cpfCnpj: getRevData.cpfCnpj,
                  razaoSocial: getRevData.razaoSocial,
                  nomeFantasia: getRevData.nomeFantasia,
                }
              });
            } else {
              PersonResaleService
                .getPersonResales({ id: sameResaleId })
                .then((valuesData) => {
                  if (valuesData.resultado.length) {
                    getRevData = valuesData.resultado[0];
                    this.setState({
                      extraRevendaDefault: {
                        cpfCnpj: getRevData.cpfCnpj,
                        razaoSocial: getRevData.razaoSocial,
                        nomeFantasia: getRevData.nomeFantasia,
                      }
                    });
                  }
                })
                .catch((error) => {
                  showMessageError(error);
                  this.setState({ extraRevendaDefault: null });
                });
            }
          }
        }

        return solicitation.revenda;
      })
      .then((revenda) => {
        const existDataResponsavelExecutivo = () => {
          return revenda.responsavelExecutivoId
            && !this.state.inputRevendaResponsavelExecutivo;
        };

        if (existDataResponsavelExecutivo()) {
          PersonEmployeeService.getPersonEmployees({
            id: revenda.responsavelExecutivoId,
            revendaId: revenda.revendaId,
          })
            .then((valuesData) => {
              if (valuesData.resultado.length) {
                const employee = valuesData.resultado[0];

                this.setState({
                  inputRevendaResponsavelExecutivo: employee.nomeSocial || '',
                });
              } else {
                this.setState({
                  inputRevendaResponsavelExecutivo: '',
                });
              }
            });
        }
      })
      .then(() => {
        if (!(this.state.reasonSolicitations || []).length) {
          ReasonSolicitationService.getReasonsSolicitation()
            .then(values => {
              if (values && values.resultado.length) {
                const reasonSolicitations = values.resultado
                  .filter((reason) => reason.ativo);

                this.setState({ reasonSolicitations });
              } else {
                this.setState({ reasonSolicitations: [] });
              }
            });
        }
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        showMessageError(error, 'Erro ao consultar solicitação.');
        setTimeout(() => {
          this.history.push(commercialPaths.commercialSolicitation);
        }, 100);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  /* LifeCicle:: Inicializa quando monta a DOM */
  componentDidMount() {
    if (!this.location.state || !this.location.state.id) {
      if (!this.search.includes('?solicitation_id=')) {
        this.setLoading(false);
        toast('Erro ao consultar solicitação', { type: toast.TYPE.ERROR });
        this.history.push(commercialPaths.commercialSolicitation);
      } else {
        this.loadData();
      }
    } else {
      this.loadData();
    }
  }

  setLoading = b => this.setState({ loading: b });

  setShowModalEditClient = b => this.setState({ showModalEditClient: b });

  setInputRadioStatus = b => this.setState({ inputRadioStatus: b });

  setTextareaTexto = b => this.setState({ textareaTexto: b });

  setAnexos = b => this.setState({ anexos: b });

  handleInputCpfCnpjChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCpfCnpj(value);
  };

  handleInputRadioStatusChange = event => {
    const {
      target: { value },
    } = event;

    this.setInputRadioStatus(value);
  };

  handleInputAnexosChange = event => {
    const {
      target: { files },
    } = event;

    const attachments = [];

    if (![...Object.values((files || {}))].every((_) => {
      return SUPORT_FILES.ANEXOS.includes(_.type);
    })) {
      toast.error('Arquivos não suportados foram encontrados');
      return;
    }

    for (const key in this.state.anexos) {
      if (typeof this.state.anexos[key] === 'object') {
        attachments.push(this.state.anexos[key]);
      }
    }

    for (const key in files) {
      if (typeof files[key] === 'object') {
        attachments.push(files[key]);
      }
    }

    if (attachments && attachments.length) {
      this.setAnexos(attachments);
    } else {
      this.setAnexos([]);
    }

    setTimeout(() => {
      this.handleToAttachments();
    }, 200); // 2 milissegundos
  };

  handleTextareaTextoChange = event => {
    const {
      target: { value },
    } = event;
    this.setTextareaTexto(value);
  };

  validateStatusForm(success) {
    const { inputRadioStatus } = this.state;
    const formData = { solicitacaoId: this.id };

    if (this.user.userCanReplyAsCM) {
      if (!inputRadioStatus || !inputRadioStatus.length) {
        toast('Campo Status é obrigatório!', { type: toast.TYPE.ERROR });
        return;
      } else {
        formData.newStatus = inputRadioStatus;
      }
    } else {
      const {
        AGUARDANDO_ANALISE_CM,
        PENDENTE_RETORNO_CM,
        PENDENTE_RETORNO_REVENDA,
      } = STATUS_SOLICITATION_ENUM;

      switch (this.state.statusAtual) {
        case AGUARDANDO_ANALISE_CM.value:
          formData.newStatus = this.state.statusAtual;
          break;
        case PENDENTE_RETORNO_REVENDA.value:
          formData.newStatus = PENDENTE_RETORNO_CM.value;
          break;
        default:
          formData.newStatus = this.state.statusAtual;
          break;
      }
    }

    success(formData);
  }

  validateMessageForm(success) {
    const formData = new FormData();
    formData.append('solicitacaoId', this.id);
    if (!this.state.inputCpfCnpj || !this.state.inputCpfCnpj.trim().length) {
      toast('Campo CPF/CNPJ é obrigatório!', { type: toast.TYPE.ERROR });
      return;
    } else {
      formData.append('cpfCnpj', this.state.inputCpfCnpj.trim());
    }
    if (!this.state.inputAssunto || !this.state.inputAssunto.trim().length) {
      toast('Campo Assunto é obrigatório!', { type: toast.TYPE.ERROR });
      return;
    } else {
      formData.append('assunto', this.state.inputAssunto.trim());
    }
    if (!this.state.textareaTexto || !this.state.textareaTexto.trim().length) {
      toast('Campo Mensagem / Descrição é obrigatório!', {
        type: toast.TYPE.ERROR,
      });
      handleMoveElement('input-message-id');
      return;
    } else {
      formData.append('texto', this.state.textareaTexto.trim());
    }
    if (this.state.anexos && this.state.anexos.length) {
      for (let key = 0; key < this.state.anexos.length; key += 1) {
        formData.append('anexos', this.state.anexos[key]);
      }
    }

    success(formData);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.validateMessageForm((formDataMessage) => {
      this.validateStatusForm(async (formDataStatus) => {
        this.setLoading(true);
        try {
          const [resMessage, resStatus] = await Promise.all([
            SolicitationService.createSolicitationMenssage(formDataMessage),
            SolicitationService.updateSolicitationStatus(formDataStatus),
          ]);

          toast(resMessage.message, { type: toast.TYPE.SUCCESS });

          if (resStatus.data.ok) {
            setTimeout(() => {
              toast(resStatus.message, { type: toast.TYPE.SUCCESS });
            }, 250); // 0.25s

            const { error, success } = resStatus.data.messages;

            if (error.length) {
              error.forEach((msg, index) => {
                if (typeof msg === 'string') {
                  setTimeout(() => {
                    toast(msg, { type: toast.TYPE.ERROR });
                  }, 500 * (index + 1)); // 0.5s, 1s e 1.5s
                }
              });
            }

            if (success.length) {
              success.forEach((msg, index) => {
                if (typeof msg === 'string') {
                  setTimeout(() => {
                    toast(msg, {
                      type: toast.TYPE.SUCCESS,
                      autoClose: 5000, // 5s
                    });
                  }, 500 * (index + 1)); // 0.5s, 1s e 1.5s
                }
              });
            }
          }

          this.setInputRadioStatus('');
          this.setTextareaTexto('');
          this.setAnexos([]);

          this.setLoading(false);
          await this.loadData();
        } catch (error) {
          showMessageError(error);
          this.setLoading(false);
        } finally {
          this.setLoading(false);
        }
      });
    });
  };

  handleDownloadFile = async (event, itemId, fileName) => {
    event.preventDefault();
    this.setLoading(true);
    try {
      const response = await api.get(
        endpoints.commercial.solicitation.getAnexoFile(itemId),
        { responseType: 'blob' }
      );
      let blob = new Blob([response.data]);
      let url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toast('Download de Arquivo realizado com sucesso!', {
          type: toast.TYPE.SUCCESS,
        });
        this.setLoading(false);
      }, 0);
    } catch (error) {
      showMessageError(error, 'Ocorreu um erro inesperado no sistema.');
      this.setLoading(false);
    }
  };

  handleCheckedFinalStatusMessage = (statusAtual) => {
    const aprovado = STATUS_SOLICITATION_ENUM.APROVADO.value;
    const negado = STATUS_SOLICITATION_ENUM.NEGADO.value;
    const noAprovadoOrNegado = '';

    const isAprovado = statusAtual === aprovado;
    if (isAprovado) return 'aprovado';

    const isNegado = statusAtual === negado;
    if (isNegado) return 'negado';

    return noAprovadoOrNegado;
  }

  handleCheckedFinalStatus = (statusFinal) => {
    const aprovado = STATUS_SOLICITATION_ENUM.APROVADO.value;
    const negado = STATUS_SOLICITATION_ENUM.NEGADO.value;
    const noAprovadoOrNegado = true;

    const isAprovado = statusFinal === aprovado;
    if (isAprovado) return !isAprovado;

    const isNegado = statusFinal === negado;
    if (isNegado) return !isNegado;

    const isEncerrado = statusFinal === 'Encerrado';
    if (isEncerrado) return !isEncerrado;

    return noAprovadoOrNegado;
  }

  handleMessageSide = (messageColor) => {
    switch (messageColor) {
      case SOLICITATION_MESSAGE_COLORS[0]:
        return false;
      case SOLICITATION_MESSAGE_COLORS[1]:
        return true;
      default:
        return false;
    }
  }

  handleValidateMessage() {
    const messageStatusOld = false;
    const messageStatusNew = true;

    const messageStatus = this.state.mensagensStatus;
    const messageWithOldStatus = [];

    messageStatus.forEach(message => {
      const OLD_STATUS = ['Novo', 'Em Análise', 'Pendente', 'Encerrado'];
      if (OLD_STATUS.includes(message.status)) {
        messageWithOldStatus.push(message);
      }
    });

    if (messageWithOldStatus.length) return messageStatusOld;

    const amountMessage = this.state.mensagens.length;
    const amountMessageStatus = this.state.mensagensStatus.length;
    if (amountMessage > amountMessageStatus) return messageStatusOld;

    return messageStatusNew;
  }

  handleToAttachments = () => handleMoveElement('attachmentsId');

  onBeforeEditClient = async () => {
    this.setLoading(true);
    try {
      const { resultado } = await LineBusinessService
        .getLineBusiness('');

      if (resultado) {
        this.setShowModalEditClient({
          lineBusinesses: resultado,
        });
      } else {
        this.setShowModalEditClient(null);
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      this.setLoading(false);
    }
  };

  handleSubmitExtra = async (extra) => {
    this.setLoading(true);
    try {
      const result = await SolicitationService
        .updateExtraSolicitation(
          this.location.state.id,
          extra,
          this.state.selectTipoSolicitacao
        );

      if (result?.data?.ok) {
        toast.success(result.message);
        this.setLoading(false);
        await this.loadData();
      }
    } catch (error) {
      showMessageError(error);
      this.onBeforeEditClient();
    } finally {
      this.setLoading(false);
    }
  };

  handleValidateFormExtra = (actions, success) => {
    const {
      setError,
      inputExtra,
      clienteExistente,
      showPermissionStatusClient,
    } = actions;

    const formData = {};

    if (inputExtra.telefone1 && !validePhone(inputExtra.telefone1)) {
      return setError('telefone1', {
        type: 'valueAsNumber',
        message: 'Digite um número válido',
        shouldFocus: true,
      });
    }

    if (inputExtra.telefone2 && !validePhone(inputExtra.telefone2)) {
      return setError('telefone2', {
        type: 'valueAsNumber',
        message: 'Digite um número válido',
        shouldFocus: true,
      });
    }

    if (showPermissionStatusClient) {
      formData.status = inputExtra.status;
    } else {
      formData.status = PERSON_STATUS_ENUM.NOT_BLOCKED.value;
    }

    if (!inputExtra.revendaId || !inputExtra.revendaId.length) {
      formData.revendaId = undefined;
    } else {
      formData.revendaId = inputExtra.revendaId;
    }

    formData.bairro = (inputExtra.bairro || '').trim();
    formData.cep = (inputExtra.cep || '').trim();
    formData.cidade = (inputExtra.cidade || '').trim();
    formData.complemento = (inputExtra.complemento || '').trim();
    formData.estado = (inputExtra.estado || '').trim();
    formData.endereco = (inputExtra.endereco || '').trim();
    formData.pontoReferencia = (inputExtra.pontoReferencia || '').trim();
    formData.razaoSocial = (inputExtra.razaoSocial || '').trim();
    formData.nomeFantasia = (inputExtra.nomeFantasia || '').trim();
    formData.tipoPessoa = inputExtra.tipoPessoa;
    formData.cpfCnpj = unFormatCpfCnpj(inputExtra.cpfCnpj);
    formData.responsavel = (inputExtra.responsavel || '').trim();
    formData.inscricaoEstadual = (inputExtra.inscricaoEstadual || '').trim();
    formData.dataCadastro = inputExtra.dataCadastro;
    formData.email = (inputExtra.email || '');
    formData.telefone1 = unFormatPunctuation((inputExtra.telefone1 || '').trim());
    formData.telefone2 = unFormatPunctuation((inputExtra.telefone2 || '').trim());
    formData.microempresa = inputExtra.microempresa;
    formData.prazoRenovacaoChave = inputExtra.prazoRenovacaoChave;
    formData.ramoAtividadeId = inputExtra.ramoAtividadeId;
    formData.retemICMS = inputExtra.retemICMS;
    formData.statusChave = inputExtra.statusChave;
    formData.status = inputExtra.status;
    formData.revendaId = inputExtra.revendaId;
    formData.cnae = '';

    const extra = JSON.stringify({
      paraCriar: {
        clienteExistente,
        cliente: formData,
      }
    });

    success(extra);
  }

  render() {
    const {
      inputRevendaCpfCnpj,
      inputRevendaRazaoSocial,
      inputRevendaNomeFantasia,
      inputRevendaResponsavelExecutivo,
      inputCpfCnpj,
      selectTipoSolicitacao,
      inputRazaoSocial,
      statusAtual,
      inputRadioStatus,
      mensagens,
      mensagensStatus,
      inputDataHora,
      inputOriginalAssunto,
      textareaOriginalTexto,
      solicitacaoAnexos,
      reasonSolicitations,
      extra,
      requester
    } = this.state;

    const {
      COLABORADOR_REVENDA,
      COLABORADOR_CM,
      REVENDA,
      REVENDA_CM,
    } = USER_TYPE.MESSAGE;

    let sortedMensagens = mensagens.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(a.data) - new Date(b.data);
    });

    const getTypeMessage = (message) => {
      let isMsgCM = null; let typeMsg = null;
      if (USER_TYPE.REVENDA === message?.pessoa?.tipo) {
        if (this.user.userCanReplyAsCM) {
          typeMsg = REVENDA_CM;
          isMsgCM = message?.pessoa?.id === this.user.revenda.id;
        } else {
          typeMsg = REVENDA;
          isMsgCM = message?.pessoa?.id !== this.user.revenda.id;
        }
      } else {
        if (this.user.userCanReplyAsCM) {
          typeMsg = COLABORADOR_CM;
          isMsgCM = message?.pessoa?.revendaId === this.user.revenda.id;
        } else {
          typeMsg = COLABORADOR_REVENDA;
          isMsgCM = message?.pessoa?.revendaId !== this.user.revenda.id;
        }
      }
      return [isMsgCM, typeMsg];
    };

    sortedMensagens = sortedMensagens.map((message) => {
      const [isMsgCM, msgTag] = getTypeMessage(message);

      const messageColor = (isMsgCM) => {
        if (isMsgCM) {
          message.color = SOLICITATION_MESSAGE_COLORS[0];
        } else {
          message.color = SOLICITATION_MESSAGE_COLORS[1];
        }
      };

      switch (msgTag) {
        case COLABORADOR_REVENDA:
          messageColor(isMsgCM);
          break;
        case REVENDA:
          messageColor(isMsgCM);
          break;
        case COLABORADOR_CM:
          messageColor(isMsgCM);
          break;
        case REVENDA_CM:
          messageColor(isMsgCM);
          break;
        default:
          message.color = SOLICITATION_MESSAGE_COLORS[2];
          break;
      }

      return message;
    });

    const currentStatusPanelStyle = () => {
      const customStyle = this.handleValidateMessage()
        ? this.handleCheckedFinalStatusMessage(statusAtual)
        : 'antigo';

      return `mensagemStatus ${customStyle}`;
    };

    const isCreateClient = !!extra?.paraCriar?.cliente;
    const lastMessageIndex = mensagensStatus.length - 1;
    const lastMessage = mensagensStatus[lastMessageIndex];

    const { PENDENTE_RETORNO_REVENDA } = STATUS_SOLICITATION_ENUM;
    const stt1 = normalizeText(lastMessage?.status);
    const stt2 = normalizeText(PENDENTE_RETORNO_REVENDA.value);

    const canEditButtonClient = (stt1 === stt2);

    return (
      <div className="defaultFormContainer">
        <SidebarPageHeader
          mainMenu="Comercial"
          pageName="Detalhes da Solicitação"
          breadcrumbs={[{
            label: 'Solicitação',
            link: commercialPaths.commercialSolicitation,
          }]}
          button1={{
            label: 'Enviar',
            onClick: () => this.handleSubmit,
            main: true,
          }}
          openButtonSelect
          buttonBackHandler={this.history.goBack}
        />
        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <div className="infoCard">
            <span id="title">
              Identificação do solicitante
            </span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>CPF/CNPJ</span>
                <input
                  type="text"
                  name="requesterCpfCnpj"
                  value={formatCpfCnpj(requester?.cpfCnpj || '')}
                  readOnly
                  disabled
                />
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Nome</span>
                <input
                  type="text"
                  name="requesterName"
                  value={requester?.nomeSocial || requester?.nomeFantasia || requester?.razaoSocial || ''}
                  readOnly
                  disabled
                />
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>Telefone</span>
                <input
                  type="text"
                  name="requesterPhone"
                  value={formatPhone(requester?.contato?.telefone1 || requester?.contato?.telefone2 || '')}
                  readOnly
                  disabled
                />
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>E-mail</span>
                <input
                  type="text"
                  name="requesterEmail"
                  value={requester?.contato?.email || ''}
                  readOnly
                  disabled
                />
              </div>
            </div>
          </div>
          {this.user.userCanReplyAsCM && (
            <div className="infoCard">
              <span id="title">Identificação da revenda</span>
              <div className="rowContainer">
                <div className="inputHolder">
                  <span>CPF/CNPJ</span>
                  <input
                    type="text"
                    name="revendaCpfCnpj"
                    value={inputRevendaCpfCnpj}
                    readOnly
                    disabled
                  />
                </div>
                <div className="inputHolder defaultFlex">
                  <span>Razão Social</span>
                  <input
                    type="text"
                    name="revendaRazaoSocial"
                    value={inputRevendaRazaoSocial}
                    readOnly
                    disabled
                  />
                </div>
                <div className="inputHolder defaultFlex noMarginRight">
                  <span>Nome Fantasia</span>
                  <input
                    type="text"
                    name="revendaNomeFantasia"
                    value={inputRevendaNomeFantasia}
                    readOnly
                    disabled
                  />
                </div>
              </div>
              <div className="rowContainer">
                <div className="inputHolder defaultFlex noMarginRight">
                  <span>Responsável Executivo</span>
                  <input
                    type="text"
                    name="revendaResponsavelExecutivo"
                    value={inputRevendaResponsavelExecutivo}
                    readOnly
                    disabled
                  />
                </div>
              </div>
            </div>
          )}
          <div className="infoCard">
            <span id="title">
              Identificação do cliente {isCreateClient && 'atual'}
            </span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>CPF/CNPJ</span>
                <input
                  type="text"
                  name="cpfCnpj"
                  value={inputCpfCnpj}
                  readOnly
                  disabled
                />
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Razão Social</span>
                <input
                  type="text"
                  name="razaoSocial"
                  value={inputRazaoSocial}
                  readOnly
                  disabled
                />
              </div>
            </div>
          </div>
          {isCreateClient && (
            <div className="infoCard">
              <S.ContainerHeaderClient>
                <span id="title">
                  Identificação do cliente novo
                </span>
                {canEditButtonClient && (
                  <div
                    className='buttonBaseOnly'
                    onClick={this.onBeforeEditClient}
                  >
                    EDITAR
                  </div>
                )}
              </S.ContainerHeaderClient>
              <ShowDataBackgound addMarginTop>
                <ShowDataClient dataClient={extra?.paraCriar?.cliente} />
              </ShowDataBackgound>
            </div>
          )}
          <div className="infoCard">
            <span id="title">Descrição</span>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex">
                <span>Assunto</span>
                <input type="text" value={inputOriginalAssunto} disabled />
              </div>
              <div className="inputHolder">
                <span>Tipo da Solicitação</span>
                <select
                  name="tipo"
                  value={(selectTipoSolicitacao || '')}
                  disabled
                >
                  <option value={''}>Selecione</option>
                  {reasonSolicitations.map(reason => (
                    <option key={reason.id} value={reason.id}>
                      {reason.descricao}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inputHolder noMarginRight">
                <span>Data e hora da solicitação</span>
                <input type="text" value={inputDataHora} disabled />
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Mensagem</span>
                <S.InputMessageDisabled
                  value={textareaOriginalTexto}
                  rows={5}
                  readOnly
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Anexos</span>
            <div className="rowContainer">
              <div className="attachsContainer">
                {solicitacaoAnexos.map(({ anexo }) => (
                  <AttachmentDownload
                    key={anexo.id}
                    attachmentName={anexo.nome}
                    handleDownloadFile={e =>
                      this.handleDownloadFile(
                        e, anexo.id, anexo.nome
                      )
                    }
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Trâmites</span>
            {sortedMensagens.map(
              ({ pessoa, texto, data, mensagemAnexos, color }, index) => {
                const date = moment(data).format('DD/MM/YYYY, HH:mm:ss');
                const messageData = mensagensStatus[index + 1];

                const status = (messageData?.status || '');

                const messageSideContainer = () => {
                  const messageSide = !this.user.userCanReplyAsCM
                    ? this.handleMessageSide(color)
                      ? 'messageLeft'
                      : 'messageRight'
                    : this.handleMessageSide(color)
                      ? 'messageRight'
                      : 'messageLeft';

                  return this.handleValidateMessage() ? messageSide : '';
                };

                const messageContainer = () => {
                  return !this.handleValidateMessage() && 'messageContainerOld';
                };

                const mensagemStatusCircle = () => {
                  const finalStatus = this.handleCheckedFinalStatusMessage(status);
                  return `mensagemStatusCircle ${finalStatus}`;
                };

                return (
                  <div key={String(index)} className={messageSideContainer()}>
                    <div
                      className={`messageContainer ${messageContainer()}`}
                      style={{ backgroundColor: color }}
                    >
                      <div id="messageHeader">
                        <span id="messageNumber" style={{ display: 'flex' }}>
                          {`Trâmite #${index + 1}`}
                          {this.handleValidateMessage() && (
                            <label className='mensagemStatusLabel'>
                              <div className={mensagemStatusCircle()} />
                              {status}
                            </label>
                          )}
                        </span>
                        <span id="messageOwner">
                          <span id="userName">
                            {
                              pessoa ? (
                                pessoa.nomeSocial ||
                                pessoa.nomeFantasia ||
                                pessoa.razaoSocial
                              ) : ''
                            }
                          </span>{' '}
                          em {date}
                        </span>
                      </div>
                      <div id='input-message-id' className="rowContainer">
                        <div className="inputHolder defaultFlex noMarginRight">
                          <span>Mensagem *</span>
                          <textarea
                            value={texto}
                            rows={3}
                            style={{ resize: 'vertical', overflow: 'auto' }}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="rowContainer">
                        <div className="inputHolder defaultFlex noMarginRight">
                          <span>Anexos</span>
                          <div className="anexosHolder">
                            {mensagemAnexos.map(({ anexo }) => (
                              <AttachmentDownload
                                key={anexo.id}
                                attachmentName={anexo.nome}
                                handleDownloadFile={e =>
                                  this.handleDownloadFile(
                                    e, anexo.id, anexo.nome
                                  )
                                }
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
            <div className={currentStatusPanelStyle()}>
              {statusAtual}
            </div>
          </div>
          {this.handleCheckedFinalStatus(statusAtual) && (
            <div className="infoCard">
              <span id="title">Novo Trâmite</span>
              <div className="rowContainer">
                <div className={`inputHolder defaultFlex ${!this.user.userCanChangeStatus && 'noMarginRight'}`}>
                  <span>Mensagem</span>
                  <textarea
                    name="texto"
                    value={this.state.textareaTexto}
                    rows={2}
                    style={{ resize: 'vertical', overflow: 'auto' }}
                    onChange={this.handleTextareaTextoChange}
                  />
                </div>
                {this.user.userCanChangeStatus && (
                  <div className="inputHolder noMarginRight">
                    <span>Status da Solicitação *</span>
                    <select
                      name="solicitationType"
                      value={inputRadioStatus}
                      onChange={this.handleInputRadioStatusChange}
                    >
                      {Object.keys(STATUS_SOLICITATION_ENUM).map((key) => (
                        <option
                          key={key}
                          value={STATUS_SOLICITATION_ENUM[key].value}
                          disabled={!STATUS_SOLICITATION_ENUM[key].showInManagement}
                        >
                          {STATUS_SOLICITATION_ENUM[key].label_small}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
              <div className="rowContainer alignFooter">
                <div className="inputHolder defaultFlex noMarginRight">
                  <span>Anexos</span>
                  <div className="imagePicker justifyContentStart">
                    <div className="buttonHolder">
                      <span>ESCOLHER ANEXO(S)</span>
                      <input
                        name="anexos"
                        type="file"
                        multiple
                        onChange={this.handleInputAnexosChange}
                        accept={SUPORT_FILES.ACCEPT}
                      />
                    </div>
                    <label>
                      {!this.state.anexos.length
                        ? 'Selecione o(s) arquivo(s)'
                        : this.state.anexos.length === 1
                          ? '1 arquivo'
                          : this.state.anexos.length + ' arquivos'}
                    </label>
                  </div>
                </div>
              </div>
              <S.ContainerAttachments>
                {this.state.anexos && (
                  <div id="attachmentsId" className="rowContainer">
                    <Attachments
                      files={this.state.anexos}
                      handleSetAnexos={this.setAnexos}
                    />
                  </div>
                )}
              </S.ContainerAttachments>
            </div>
          )}
        </form>
        <Modal
          show={this.state.loading || !!this.state.showModalEditClient}
          loading={this.state.loading}
          customWidth={!this.state.loading ? '90%' : 'none'}
        >
          {this.state.showModalEditClient ? (
            <FieldsClientModal
              newClient={extra?.paraCriar?.cliente}
              resaleDefault={this.state.extraRevendaDefault}
              existentClient={extra?.paraCriar?.clienteExistente}
              lineBusinesses={[
                ...(this.state?.showModalEditClient?.lineBusinesses || []),
              ]}
              sameClient={inputCpfCnpj}
              setLoading={this.setLoading}
              noVerifyClientInBase
              showPermissionStatusClient={false}
              handleCancel={() => this.setShowModalEditClient(false)}
              onValidateForm={this.handleValidateFormExtra}
              onHandleSubmitObject={this.handleSubmitExtra}
            />
          ) : undefined}
        </Modal>
      </div>
    );
  }
}

function stateToProps(state) {
  const user = state.user.data;

  if (user) {
    const userPermissions = user.permissions;

    const userCanChangeStatus = userPermissions.includes(
      permissions.status_solicitacao
    );

    const userCanReplyAsCM = userPermissions.includes(
      permissions['message_resposta-cm']
    );

    user.userCanChangeStatus = userCanChangeStatus;
    user.userCanReplyAsCM = userCanReplyAsCM;
  }

  return { user };
}

export default connect(stateToProps)(EditCommercialSolicitation);
