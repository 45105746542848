import api, { endpoints } from '~/services/api';

class LicenseService {
  static getLicenses = async (formDataParam, page, rowsPerPage) => {
    const { data: response } = await api.post(
      endpoints.license.getLicenses,
      {
        ...formDataParam,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      });

    return response.data;
  };
}

export { LicenseService };
