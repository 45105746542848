import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

function OrderItemModule(itemModuleProps) {
  const {
    itemObj,
    countItems,
    handleAddModule,
    handleRemoveModule,
  } = itemModuleProps;

  return (
    <S.ModuleHolder>
      <div className="header">
        <S.ModuleTitle title={itemObj.nome}>
          <S.ModuleNameContent isDefault={itemObj.isAutomatic}>
            <span id="moduleName">{itemObj.nome}</span>
          </S.ModuleNameContent>
          <S.ModuleDefault>
            {itemObj.isAutomatic ? ' (padrão)' : ''}
          </S.ModuleDefault>
        </S.ModuleTitle>
        <div id="quantityHolder">
          <span id="minus" onClick={handleRemoveModule}>
            -
          </span>
          <span id="quantity">{countItems}</span>
          <span id="plus" onClick={handleAddModule}>
            +
          </span>
        </div>
      </div>
      <S.ModulePriceHolderContainer>
        <div className="priceHolder">
          <span>Adesão: </span>
          <S.InputCurrencyFormat
            value={itemObj.precoAdesao ? itemObj.precoAdesao : 0}
          />
        </div>
        <S.ModuleDivider>{'|'}</S.ModuleDivider>
        <div>
          <span>Mensalidade: </span>
          <S.InputCurrencyFormat
            value={itemObj.precoMensal ? itemObj.precoMensal : 0}
          />
        </div>
      </S.ModulePriceHolderContainer>
    </S.ModuleHolder>
  );
}

OrderItemModule.propTypes = {
  itemObj: PropTypes.object.isRequired,
  countItems: PropTypes.number.isRequired,
  handleAddModule: PropTypes.func.isRequired,
  handleRemoveModule: PropTypes.func.isRequired,
};

export { OrderItemModule };
