import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Modal from '~/components/Modal';
import { toast } from 'react-toastify';
import { licensePaths } from '~/routes/paths';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { ReasonCancellationService } from '~/services/license/reasonCancellation';
import { showMessageError } from '~/util/errorutils';
import * as S from '../styles';

export default function AddReasonCancellation({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [inputReason, setInputReason] = useState('');
  const [errorReason, setErrorReason] = useState(false);
  const [subReasons, setSubReasons] = useState([]);
  const [dataBackup, setDataBackup] = useState({
    motivo: '', subMotivos: []
  });
  const [subReasonsToRemove, setSubReasonsToRemove] = useState([]);

  useEffect(() => {
    if (!location.state) history.push(licensePaths.reasonCancellation);
    else {
      const asyncEffect = async () => {
        setLoading(true);
        try {
          const { resultado } = await ReasonCancellationService
            .getReasonCancellation({
              id: location.state.id,
              text: location.state.motivo,
            });

          if (resultado[0]) {
            const subReasons = resultado[0]
              .submotivoCancelamentos
              .filter((subReason) => subReason.ativo)
              .map((reason, index) => {
                return {
                  id: reason.id,
                  name: `subreason ${index}`,
                  value: reason.descricao,
                  error: false,
                };
              });

            setInputReason(resultado[0].descricao);
            setSubReasons(subReasons);

            setDataBackup(JSON.stringify({
              motivo: resultado[0].descricao,
              subMotivos: subReasons,
            }));
          } else {
            toast.error('Erro ao busca os Motivo e Submotivos');
            setTimeout(() => {
              history.push(licensePaths.reasonCancellation);
            }, 100);
          }
        } catch (error) {
          showMessageError(error);
        } finally {
          setLoading(false);
        }
      };

      asyncEffect();
    }
  }, [history, location.state]);

  function handleInputReasonChange(event) {
    const {
      target: { value },
    } = event;

    setInputReason(value);
    setErrorReason(false);
  }

  function handleSubReasonChange(event, subIndex) {
    const {
      target: { value },
    } = event;

    const subReasonItem = Array.from(subReasons);
    const [changeValue] = subReasonItem
      .filter((_, index) => index === subIndex);

    if (changeValue) {
      changeValue.value = value;
      changeValue.error = false;
      subReasonItem[subIndex] = changeValue;
      setSubReasons(subReasonItem);
    }
  }

  function validadeForm(success) {
    const data = {};
    let error_temp = false;

    if (!inputReason || !inputReason.trim().length) {
      setErrorReason(true);
      error_temp = true;
    }

    let error_subReason = false;
    const subReasonsWithError = subReasons.map(reason => {
      if (!reason.value || !reason.value.trim().length) {
        error_subReason = true;

        return {
          ...reason,
          error: true,
        };
      }

      return reason;
    });

    if (error_subReason) {
      error_temp = true;
      setSubReasons(subReasonsWithError);
    }

    if (error_temp || subReasonsWithError.some((_) => _.error)) {
      return;
    }

    data.id = location.state.id;
    data.motivo = inputReason.trim();

    const paraDeletar = [...(subReasonsToRemove || []).map((sub) => ({
      id: sub.id, descricao: sub.value.trim(),
    }))];

    const paraCriar = [];
    const paraAtualizar = [];
    subReasons.forEach(sub => {
      if (sub?.id) {
        paraAtualizar.push({
          id: sub.id,
          descricao: sub.value.trim(),
        });
      } else {
        paraCriar.push(sub.value.trim());
      }
    });

    data.subMotivos = {
      paraCriar,
      paraDeletar,
      paraAtualizar,
    };

    const dataTemp = {
      motivo: inputReason.trim(),
      subMotivos: subReasons.map((sub) => ({
        ...sub, value: sub.value.trim()
      }))
    };

    if (!_.isEqual(JSON.parse(dataBackup), dataTemp)) {
      success(data);
    } else {
      toast.success('Sem alterações no motivo ou submotivos de cancelamento');
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    validadeForm(async (formData) => {
      setLoading(true);
      try {
        const result = await ReasonCancellationService
          .updateReasonCancellation({ ...formData });

        toast(result.message, { type: toast.TYPE.SUCCESS });
        setTimeout(() => {
          history.push(licensePaths.reasonCancellation);
        }, 100);
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });
  }

  function handleDeleteSubMotivo(subIndex, subObj) {
    if (subObj?.id) {
      setSubReasonsToRemove((old) => [...old, subObj]);
    }

    setSubReasons(oldState => [
      ...oldState.filter((_, index) => index !== subIndex)
    ]);
  }

  function handleShowButtonDelete() {
    return !(subReasons.length <= 1);
  }

  function handleAddSubMotivo() {
    setSubReasons(oldState => [...oldState, {
      name: `subreason ${oldState.length}`,
      value: '',
      error: false,
    }]);
  }

  return (
    <div className="defaultFormContainer">
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Licença"
        pageName="Editar Motivo"
        breadcrumbs={[{
          label: 'Motivo de Cancelamento',
          link: licensePaths.reasonCancellation,
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => handleSubmit,
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={() => history.goBack()}
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <span id="title">Dados do Motivo</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Nome do Motivo *</span>
              <input
                type="text"
                name="nome"
                value={inputReason}
                onChange={handleInputReasonChange}
              />
              {errorReason && (
                <S.ContainerError>
                  Campo obrigatório
                </S.ContainerError>
              )}
            </div>
          </div>
          <span id="title">Dados do Submotivo</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              {subReasons.map((sub, index) => (
                <S.ContainerSubMotivo key={`sub-motivo-${index}`}>
                  <span>Nome do Submotivo {index + 1} *</span>
                  <S.ContainerInputDeleteRow>
                    <input
                      type="text"
                      name={sub.name}
                      value={sub.value}
                      onChange={(event) => handleSubReasonChange(event, index)}
                    />
                    {handleShowButtonDelete() && (
                      <S.DeleteIcon
                        disabled={true}
                        onClick={() => handleDeleteSubMotivo(index, sub)}
                        title="Deletar"
                      />
                    )}
                  </S.ContainerInputDeleteRow>
                  {sub.error && (
                    <S.ContainerError>
                      Campo obrigatório
                    </S.ContainerError>
                  )}
                </S.ContainerSubMotivo>
              ))}
            </div>
          </div>
          <S.ContainerButton onClick={handleAddSubMotivo}>
            <S.AddIcon />
            <span>SUBMOTIVO</span>
          </S.ContainerButton>
        </div>
      </form>
    </div>
  );
}

AddReasonCancellation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
      motivo: PropTypes.string,
    }).isRequired,
  }).isRequired,
};
