import { Fragment } from 'react';
import styled from 'styled-components';

export const MassActionsContainer = Fragment;
export const StepOneContainer = Fragment;
export const StepTwoContainer = Fragment;
export const StepThreeContainer = Fragment;

export const Container = styled.div``;

export const ContainerRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 6px;
    margin-top: -6px;
  }
`;

export const ButtonTextDecoration = styled.button`
  border: none;
  background-color: #FCFCFC;
  cursor: ${(props) => props.disabledButton ? 'default' : 'pointer'};

  span {
    text-decoration: underline;
    color: ${(props) => props.disabledButton ? '#C4C4C4' : '#2c2f8d'};
  }
`;
