import React from 'react';
import PropTypes from 'prop-types';
import { formatPhone, formatCpfCnpj } from '~/util/stringutils';

function UserInfoClient(props) {
  const {
    nomeSocial = '',
    razaoSocial = '',
    nomeFantasia = '',
    cpfCnpj = '',
    cidade = '',
    estado = '',
    telefone = '',
    email = '',
  } = props;

  return (
    <>
      <label>Razão Social: <b>{nomeSocial || razaoSocial}</b></label>
      <label>Fantasia: <b>{nomeFantasia}</b></label>
      <label>CNPJ: <b>{formatCpfCnpj(cpfCnpj)}</b></label>
      <label>
        Cidade/UF: <b>{
          (cidade || cidade) && `${cidade ? cidade : ''}/${estado ? estado : ''}`
        }</b>
      </label>
      <label>Pessoa de contato: <b>{formatPhone(telefone)}</b></label>
      <label>E-mail: <b>{email}</b></label>
    </>
  );
}

UserInfoClient.propTypes = {
  nomeSocial: PropTypes.string,
  razaoSocial: PropTypes.string,
  nomeFantasia: PropTypes.string,
  cpfCnpj: PropTypes.string,
  cidade: PropTypes.string,
  estado: PropTypes.string,
  telefone: PropTypes.string,
  email: PropTypes.string,
};

export { UserInfoClient };
