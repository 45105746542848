import TableCell from '@material-ui/core/TableCell';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import Modal from '~/components/Modal';
import PrintableComponent from '~/components/PrintableComponent';
import LicenseLogsService from '~/services/license-logs-service';
import { showMessageError } from '~/util/errorutils';
import { formatCpfCnpj } from '~/util/stringutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';
import SearchComponent from './SearchComponent';

function Log() {
  const [loading, setLoading] = useState(false);
  const [inputRevenda, setInputRevenda] = useState('');
  const [inputUsuario, setInputUsuario] = useState('');
  const [inputPerfil, setInputPerfil] = useState('');
  const [inputEnderecoIp, setInputEnderecoIp] = useState('');
  const [inputAcao, setInputAcao] = useState('');
  const [inputDataInicio, setInputDataInicio] = useState('');
  const [inputDataFim, setInputDataFim] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    revenda: '',
    usuario: '',
    perfil: '',
    enderecoIp: '',
    acao: '',
    dataInicio: '',
    dataFim: '',
  });

  let PrintableElement = React.useRef();

  useEffect(() => {
    findAllByFilter(pageParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInputRevendaChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputRevenda(value);
  }

  function handleInputUsuarioChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputUsuario(value);
  }

  function handleInputPerfilChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputPerfil(value);
  }

  function handleInputEnderecoIpChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputEnderecoIp(value);
  }

  function handleInputAcaoChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputAcao(value);
  }

  function handleInputDataInicioChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputDataInicio(value);
  }

  function handleInputDataFimChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setInputDataFim(value);
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};
    if (inputRevenda) {
      data.revenda = inputRevenda.trim();
      count += 1;
    }
    if (inputUsuario) {
      data.usuario = inputUsuario.trim();
      count += 1;
    }
    if (inputPerfil) {
      data.perfil = inputPerfil.trim();
      count += 1;
    }
    if (inputEnderecoIp) {
      data.enderecoIp = inputEnderecoIp.trim();
      count += 1;
    }
    if (inputAcao) {
      data.acao = inputAcao.trim();
      count += 1;
    }
    if (inputDataInicio) {
      data.dataInicio = inputDataInicio.trim();
      count += 1;
    }
    if (inputDataFim) {
      data.dataFim = inputDataFim.trim();
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    await success(data);
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  async function findAllByFilterReport(formDataParam) {
    setLoading(true);
    try {
      const response = await LicenseLogsService.getLogsSystem({
        ...(formData || formDataParam),
      });
      if (response.resultado.length) {
        handleSetSearchResponseReport(response.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos Logs para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams, formDataParams) {
    setPageParams(pageParams);
    setFormData(formDataParams);
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const response = await LicenseLogsService.getLogsSystem({
        ...(formDataParams || formData),
        limit: page * rowsPerPage + rowsPerPage,
        offset: page * rowsPerPage,
      });

      if (response.resultado.length) {
        handleSetSearchResponse(response.resultado, response.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos Logs para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  const renderRowComponent = (row) => {
    const HELPER_ACTIONS = {
      'logtype_create': 'Criar',
      'logtype_find': 'Buscar',
      'logtype_delete': 'Deletar',
      'logtype_update': 'Atualizar',
      'logtype_cancel': 'Cancelar',
    };

    return [
      <TableCell key='row_active_0'>
        {row.log.loggedAt
          ? moment(row.log.loggedAt).format('DD/MM/YYYY, HH:mm:ss')
          : '---'}
      </TableCell>,
      <TableCell key='row_active_1'>
        {row.log.ip ? row.log.ip : '---'}
      </TableCell>,
      <TableCell key='row_active_2'>
        {row.usuario.nomeSocial
          || row.usuario.nomeFantasia
          || row.usuario.razaoSocial
          || '---'}
        <div><small>
          {row.usuario.cpfCnpj ? formatCpfCnpj(row.usuario.cpfCnpj) : '---'}
        </small></div>
      </TableCell>,
      <TableCell key='row_active_3'>
        {row.usuario.tipo ? row.usuario.tipo : '---'}
      </TableCell>,
      <TableCell key='row_active_4'>
        {row.revenda.nomeSocial
          || row.revenda.nomeFantasia
          || row.revenda.razaoSocial
          || '---'}
        <div><small>
          {row.revenda.cpfCnpj ? formatCpfCnpj(row.revenda.cpfCnpj) : '---'}
        </small></div>
      </TableCell>,
      <TableCell key='row_active_5'>
        {row.log.logAcaoTipo ? HELPER_ACTIONS[row.log.logAcaoTipo.tag] : '---'}
      </TableCell>,
      <TableCell key='row_active_6'>
        {row.log.acao ? row.log.acao : '---'}
      </TableCell>,
    ];
  };

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Logs"
        handlePageUpdate={() => findAllByFilterReport(pageParameters)}
        filters={[
          { name: 'Revenda', value: inputRevenda },
          { name: 'Usuario', value: inputUsuario },
          { name: 'Perfil', value: inputPerfil },
          { name: 'EnderecoIp', value: inputEnderecoIp },
          { name: 'Ação', value: inputAcao },
          { name: 'De', value: inputDataInicio },
          { name: 'Até', value: inputDataFim },
        ]}
        headerLabels={[
          { text: 'Data', width: '10%' },
          { text: 'Endereço IP', width: '15%' },
          { text: 'Usuário', width: '17%' },
          { text: 'Perfil', width: '10%' },
          { text: 'Revenda', width: '17%' },
          { text: 'Ação', width: '10%' },
          { text: 'Descrição', width: 'auto' },
        ]}
        dataObjects={searchResponseReport}
        renderRowComponent={renderRowComponent}
      />
      <>
        <Modal loading show={loading} />
        <SidebarPageHeader
          mainMenu="Administrador"
          pageName="Logs"
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await validateForm(async data => {
              await findAllByFilterReport(pageParameters, data);
            });
          }}
          openButtonSelect
        />
        <SearchComponent
          countFilter={countFilter}
          handleSubmit={handleSubmit}
          revendaValue={inputRevenda}
          usuarioValue={inputUsuario}
          perfilValue={inputPerfil}
          acaoValue={inputAcao}
          enderecoIpValue={inputEnderecoIp}
          dataInicioValue={inputDataInicio}
          dataFimValue={inputDataFim}
          handleRevendaChange={handleInputRevendaChange}
          handleUsuarioChange={handleInputUsuarioChange}
          handlePerfilChange={handleInputPerfilChange}
          handleAcaoChange={handleInputAcaoChange}
          handleEnderecoIpChange={handleInputEnderecoIpChange}
          handleDataInicioChange={handleInputDataInicioChange}
          handleDataFimChange={handleInputDataFimChange}
        />
        <TableComponent
          headerLabels={[
            { text: 'Data', width: '10%' },
            { text: 'Endereço IP', width: '15%' },
            { text: 'Usuário', width: '17%' },
            { text: 'Perfil', width: '10%' },
            { text: 'Revenda', width: '17%' },
            { text: 'Ação', width: '10%' },
            { text: 'Descrição', width: 'auto' },
          ]}
          dataObjects={searchResponse}
          renderRowComponent={renderRowComponent}
          useCustomActions
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
        />
      </>
    </>
  );
}

Log.propTypes = {};

export default Log;
