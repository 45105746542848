import styled from 'styled-components';

export const DataInfo = styled.div`
  padding: 0 20px 20px 20px;
`;

export const DataInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${({ noMarginBottom }) => noMarginBottom ? '0' : '20px'};
  gap: 20px;

  @media screen and (max-width: 1080px) {
    flex-direction: column;
  }
`;

export const DataInfoSimple = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  flex-wrap: wrap;
`;

export const DataInfoContent = styled.div`
  padding: 0 !important;
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  flex-direction: row;

  .infoHolder {
    flex: 1;
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 15px;
    max-width: 100%;

    .header {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      &.noMarginBottom {
        margin-bottom: 0;
      }

      span {
        flex-grow: 1;
        font-weight: bold;
      }
    }

    .rowInfo {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }

    .info {
      display: flex;
      flex-direction: column;

      &.col2 {
        width: calc(50% - 10px);

        @media screen and (max-width: 1080px) {
          width: 100%;
        }
      }

      span {
        strong {
          margin-right: 6px;
        }
      }

      .description {
        width: 100%;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
