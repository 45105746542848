import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

function PersonSearch({
  serie,
  modalidade,
  software,
  numeroCaixas,
  numeroUsuarios,
  motivosCancelamento,
  subMotivosCancelamento,
  handleSelectMotivoCancelamento,
  handleSelectSubMotivoCancelamento,
  handleNumeroCaixasChange,
  handleNumeroUsuariosChange,
  motivoError,
  subMotivoError
}) {
  return (
    <>
      <label id="title">Dados da Licença</label>
      <div className="rowContainer">
        <div className="inputHolder">
          <span>Série *</span>
          <input disabled id="serie" type="text" name="serie" value={serie} />
        </div>
        <div className="inputHolder">
          <span>Software *</span>
          <input disabled id="software" type="text" value={software} />
        </div>
        <div className="inputHolder defaultFlex">
          <span>Modalidade *</span>
          <input
            disabled
            id="modalidade"
            type="text"
            name="modalidade"
            value={modalidade}
          />
        </div>
      </div>
      <div className="rowContainer">
        <div className="inputHolder inputWithNumbers">
          <span>Nº de Caixas</span>
          <input
            id="numeroCaixas"
            type="text"
            name="numeroCaixas"
            value={numeroCaixas}
            onChange={handleNumeroCaixasChange}
          />
        </div>
        <div className="inputHolder inputWithNumbers">
          <span>Nº de Usuários</span>
          <input
            id="numeroUsuarios"
            type="text"
            name="numeroUsuarios"
            value={numeroUsuarios}
            onChange={handleNumeroUsuariosChange}
          />
        </div>
        <div className="inputHolder defaultFlex">
          <span>Motivo *</span>
          <select
            name="motivoCancelamento"
            onChange={handleSelectMotivoCancelamento}
          >
            <option value="">Selecione</option>
            {motivosCancelamento
              .filter(motivo => motivo.ativo)
              .map(motivo => (
                <option key={motivo.id} value={motivo.id}>
                  {motivo.descricao}
                </option>
              ))}
          </select>
          {motivoError && (
            <small style={{ color: 'red' }}>
              Informe um motivo para cancelamento
            </small>
          )}
        </div>
        <div className="inputHolder defaultFlex">
          <span>Sub-motivo *</span>
          <select
            name="subMotivoCancelamento"
            onChange={handleSelectSubMotivoCancelamento}
          >
            <option value="">Selecione</option>
            {subMotivosCancelamento
              .filter(submotivo => submotivo.ativo)
              .map(submotivo => (
                <option key={submotivo.id} value={submotivo.id}>
                  {submotivo.descricao}
                </option>
              ))}
          </select>
          {subMotivoError && (
            <small style={{ color: 'red' }}>
              Informe um sub-motivo para cancelamento
            </small>
          )}
        </div>
      </div>
    </>
  );
}

PersonSearch.propTypes = {
  serie: PropTypes.string.isRequired,
  modalidade: PropTypes.string.isRequired,
  software: PropTypes.string.isRequired,
  numeroCaixas: PropTypes.string || PropTypes.oneOf([null]),
  numeroUsuarios: PropTypes.string || PropTypes.oneOf([null]),
  motivosCancelamento: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  subMotivosCancelamento: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleSelectMotivoCancelamento: PropTypes.func,
  handleSelectSubMotivoCancelamento: PropTypes.func,
  handleNumeroCaixasChange: PropTypes.func,
  handleNumeroUsuariosChange: PropTypes.func,
  motivoError: PropTypes.bool,
  subMotivoError: PropTypes.bool,
};

export default PersonSearch;
