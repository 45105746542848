import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
`;

export const Divider = styled.div`
  border-bottom: solid;
  border-bottom-width: 1px;
  border-color: #C6C6DC;
`;

export const ModalContent = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ModalButtons = styled.div`
  display: flex;
  padding: 0 16px 16px 16px;
  justify-content: space-between;
`;

export const ModalPropoverContainer = styled.div`
  padding: 2px;
  width: 176px;
  display: flex;
  flex-direction: column;

  button {
    height: 37px;
    background-color: #FFFFFF;
    border: none;

    text-align: left;
    font-weight: bold;
    color: #444444;
    padding-left: 12px;
  }

  div {
    height: 1px;
    width: 100%;
    background-color: #DADAE3;
  }
`;
