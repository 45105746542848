import styled from 'styled-components';

export const ContainerContent = styled.div`
  border: 1px solid var(--cm-primary);
  border-radius: 8px;
  padding: 16px 24px;
  background: rgba(252, 252, 252, 0.9);
  margin-bottom: 8px;
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
`;

export const ImageContent = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 8px;
  }

  span {
    font-size: 12px;
  }
`;
