import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const FilterContext = createContext();

const FilterProvider = ({ children }) => {
  const [filters, setFilters] = useState({});

  return (
    <FilterContext.Provider value={{ filters, setFilters }}>
      {children}
    </FilterContext.Provider>
  );
};

FilterProvider.propTypes = {
  children: PropTypes.any,
};

export { FilterProvider, FilterContext };
