import React from 'react';
import * as S from '../styles';

const VpnKeySolidBloqued = ({ ...rest }) => {
  return (
    <S.IconSVG {...rest}>
      <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1001 9.08789C11.1001 9.08789 10.7666 9 9.1001 9.08789C4.69289 9.32032 1.1001 12.7135 1.1001 17.1748C1.1001 21.6362 4.68676 25.2618 9.1001 25.2618C12.5801 25.2618 15.5401 23.0109 16.6334 19.8705H19.1001L11.1001 9.08789ZM9.1001 19.8705C7.63343 19.8705 6.43343 18.6575 6.43343 17.1748C6.43343 15.6922 7.63343 14.4792 9.1001 14.4792C10.5668 14.4792 11.7668 15.6922 11.7668 17.1748C11.7668 18.6575 10.5668 19.8705 9.1001 19.8705Z" fill="#002850" />
        <line y1="-1.25" x2="35.4436" y2="-1.25" transform="matrix(0.594823 0.803857 -0.797614 0.603169 3 2.25391)" stroke="#002850" strokeWidth="2.5" />
        <path d="M22.3335 19.8698L18.3335 14.4785H32.3335V19.8698H29.6668V25.2611L26.3335 25.2611L24.3335 22.5655L24.3335 19.8698L22.3335 19.8698Z" fill="#002850" />
      </svg>
    </S.IconSVG>
  );
};

export { VpnKeySolidBloqued };
