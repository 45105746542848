import api, { endpoints } from '~/services/api';
import { SETTINGS_ENUM } from '~/util/domainutils';

class AdminSettingsService {
  static getSettingsService = async () => {
    const [responseLink, responseSetting] = await Promise.all([
      api.get(endpoints.admin.getLinkMarketing),
      api.post(endpoints.admin.getSetting),
    ]);

    const resultLink = responseLink.data.data;
    const resultSetting = responseSetting.data.data;

    return [resultLink, resultSetting];
  };

  static getTestLicense = async () => {
    const { data: resultSetting } = await api.post(
      endpoints.admin.getSetting,
    );

    return resultSetting.data;
  }

  static getGoalsColors = async () => {
    const { data: resultSetting } = await api.post(
      endpoints.admin.getSetting,
      { code: SETTINGS_ENUM.GET_CORES_METAS }
    );

    return resultSetting.data;
  }

  static updateSettingsService = async (parmaslink, paramsSettings) => {
    const [responseLink, responseSetting] = await Promise.all([
      api.put(endpoints.admin.updateLinkMarketing, { ...parmaslink }),
      api.put(endpoints.admin.updateSetting, { ...paramsSettings }),
    ]);

    const resultLink = responseLink.data.data;
    const resultSetting = responseSetting.data.data;

    return [resultLink, resultSetting];
  }
}

export { AdminSettingsService };
