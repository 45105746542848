import PropTypes from 'prop-types';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { FieldCpfCnpj } from '~/components/FieldCpfCnpj';
import { PeriodInDate } from '~/components/PeriodInDate';
import { CollapsibleFilter } from '~/components/CollapsibleFilter';

function MonitorKeySearch(monitorKeySearchParams) {
  const {
    countFilter,
    handleSubmit,
    softwares,
    cpfCnpjValue,
    razaoSocialValue,
    serieValue,
    softwareValue,
    versaoValue,
    responsavelValue,
    dataInicioValue,
    dataFimValue,
    handleCpfCnpjChange,
    handleRazaoSocialChange,
    handleSerieChange,
    handleSoftwareChange,
    handleVersaoChange,
    handleResponsavelChange,
    handleDataInicioChange,
    handleDataFimChange,
  } = monitorKeySearchParams;

  return (
    <CollapsibleFilter countFilters={countFilter}>
      <form className="formContainer" onSubmit={handleSubmit}>
        <label id="searchText">Pesquisar</label>
        <div className="rowContainer">
          <div className="inputHolder">
            <span>CPF / CNPJ</span>
            <FieldCpfCnpj
              type="text"
              name="cpfCnpj"
              cpfCnpjValue={cpfCnpjValue}
              handleCpfCnpjChange={handleCpfCnpjChange}
            />
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Nome / Razão Social</span>
            <input
              type="text"
              name="razao"
              value={razaoSocialValue}
              onChange={handleRazaoSocialChange}
            />
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Software</span>
            <select
              name="software"
              value={softwareValue}
              onChange={handleSoftwareChange}
            >
              <option value={0}>Selecione</option>
              {softwares.map(item => (
                <option key={item.id} value={item.id}>
                  {item.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="inputHolder defaultFlex">
            <span>Série</span>
            <input
              type="text"
              name="serie"
              value={serieValue}
              onChange={handleSerieChange}
            />
          </div>
          <div className="inputHolder defaultFlex">
            <span>Versão</span>
            <input
              type="text"
              name="version"
              value={versaoValue}
              onChange={handleVersaoChange}
            />
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Responsável</span>
            <input
              type="text"
              name="responsavel"
              value={responsavelValue}
              onChange={handleResponsavelChange}
            />
          </div>
        </div>
        <div className="rowContainer rowJustifyContent">
          <div className="inputHolder">
            <PeriodInDate
              dataInicio={dataInicioValue}
              handleDataInicioChange={handleDataInicioChange}
              dataFim={dataFimValue}
              handleDataFimChange={handleDataFimChange}
            />
          </div>
          <button className="buttonBaseOnly" id="baseButtonIcon" onClick={handleSubmit}>
            <FaSearch id="baseNameIcon" />
            <span>BUSCAR</span>
          </button>
        </div>
      </form>
    </CollapsibleFilter>
  );
}

MonitorKeySearch.propTypes = {
  countFilter: PropTypes.number,
  handleSubmit: PropTypes.func,
  softwares: PropTypes.array,
  cpfCnpjValue: PropTypes.string,
  razaoSocialValue: PropTypes.string,
  serieValue: PropTypes.string,
  softwareValue: PropTypes.string,
  versaoValue: PropTypes.string,
  controleValue: PropTypes.string,
  responsavelValue: PropTypes.string,
  dataInicioValue: PropTypes.string,
  dataFimValue: PropTypes.string,
  handleCpfCnpjChange: PropTypes.func,
  handleRazaoSocialChange: PropTypes.func,
  handleSerieChange: PropTypes.func,
  handleSoftwareChange: PropTypes.func,
  handleVersaoChange: PropTypes.func,
  handleControleChange: PropTypes.func,
  handleResponsavelChange: PropTypes.func,
  handleDataInicioChange: PropTypes.func,
  handleDataFimChange: PropTypes.func,
};

export default MonitorKeySearch;
