import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import { PERSON_STATUS_ENUM } from '~/util/domainutils';
import { InputPerson } from '~/components/InputPerson';
import '../styles.scss';

function SimpleSearch({
  inputTitle1,
  handleSubmit,
  codeValue,
  handleCodeChange,
  inputPessoa1Value,
  handlePessoa1Change,
  statusValue,
  handleStatusChange,
  statusChaveValue,
  handleStatusChaveChange,
  noFieldStatus = true,
  noTitleSearch = true,
  noFieldStatusChave = true,
  userType,
}) {
  return (
    <>
      {noTitleSearch && (
        <label id="searchText">Pesquisar</label>
      )}
      <div className="rowContainer">
        <div id="code" className="inputHolder">
          <span>Código</span>
          <input
            type="text"
            name="code"
            value={codeValue}
            onChange={handleCodeChange}
          />
        </div>
        <InputPerson
          title1={inputTitle1}
          inputPerson1={inputPessoa1Value}
          handlePerson1Change={handlePessoa1Change}
          userType={userType}
        />
        {!noFieldStatus && (
          <div className="inputHolder">
            <span>Status do Cliente</span>
            <select
              name="status"
              value={statusValue}
              onChange={handleStatusChange}
            >
              <option value={''}>Selecione</option>
              <option value={PERSON_STATUS_ENUM.BLOCKED.value}>
                {PERSON_STATUS_ENUM.BLOCKED.label}
              </option>
              <option value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}>
                {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
              </option>
            </select>
          </div>
        )}
        {!noFieldStatusChave && (
          <div className="inputHolder">
            <span>Status Geração de Chave</span>
            <select
              name="statusChave"
              value={statusChaveValue}
              onChange={handleStatusChaveChange}
            >
              <option value={''}>Selecione</option>
              <option value={PERSON_STATUS_ENUM.BLOCKED.value}>
                {PERSON_STATUS_ENUM.BLOCKED.label}
              </option>
              <option value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}>
                {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
              </option>
            </select>
          </div>
        )}
        <button
          className="buttonBaseOnly"
          id="baseButtonIcon"
          onClick={handleSubmit}
        >
          <FaSearch id="baseNameIcon" />
          <span>BUSCAR</span>
        </button>
      </div>
    </>
  );
}

SimpleSearch.propTypes = {
  inputTitle1: PropTypes.string,
  userType: PropTypes.string,
  handleSubmit: PropTypes.func,
  codeValue: PropTypes.string,
  handleCodeChange: PropTypes.func,
  noFieldStatus: PropTypes.bool,
  statusValue: PropTypes.string,
  handleStatusChange: PropTypes.func,
  noTitleSearch: PropTypes.bool,
  noFieldStatusChave: PropTypes.bool,
  statusChaveValue: PropTypes.string,
  handleStatusChaveChange: PropTypes.func,
  inputPessoa1Value: PropTypes.string,
  handlePessoa1Change: PropTypes.func,
};

export default SimpleSearch;
