import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function ButtonBase({ label, outlined, onClick, disabled }) {
  const onClassName = () => {
    let classResult = '';

    if (outlined) {
      classResult += ' outline';
    }

    if (disabled) {
      classResult += ' disable';
    }

    return classResult;
  };

  return (
    <button
      type="button"
      disabled={disabled}
      className={onClassName()}
      onClick={onClick}
    >
      {label}
    </button>
  );
}

ButtonBase.propTypes = {
  label: PropTypes.string.isRequired,
  outlined: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export { ButtonBase };
