import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import FileBase64 from 'react-file-base64';
import { toast } from 'react-toastify';
import { FORM_ITEM_IMAGE_TYPE } from '~/util/domainutils';
import { SUPORT_FILES } from '~/util/fileutils';
import {
  Image,
  ImageContainer,
  MoreIcon,
  PopoverContainer,
  PopoverButton,
  AlignCenterIcon,
  AlignLeftIcon,
  AlignRightIcon,
  ImageIcon,
  PopoverInputFile,
} from './styles';
import { ContainerRowTop } from '../styles';
import '../styles.scss';

function FormImage({ form, setForm, formItemIndex, image, setImage }) {
  const [alignItemType, setAlignItemType] = useState(
    FORM_ITEM_IMAGE_TYPE.ALIGN_CENTER.tag
  );
  const [preview, setPreview] = useState(image);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleDefaultItem = useCallback(() => {
    if (form[formItemIndex].itemData.itemContent.src) {
      setImage(form[formItemIndex].itemData.itemContent.src);
    }

    if (form[formItemIndex].itemData.itemContent.object) {
      setPreview(form[formItemIndex].itemData.itemContent.src);
    }

    if (form[formItemIndex].itemData.itemContent.align) {
      setAlignItemType(form[formItemIndex].itemData.itemContent.align);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleDefaultItem();
  }, [handleDefaultItem]);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleClosePopover(type) {
    setAlignItemType(type);
    form[formItemIndex].itemData.itemContent.align = type;
    setAnchorEl(null);
    setForm(form);
  }

  function handleImageBase64(objectImage) {
    if (objectImage.type && SUPORT_FILES.IMAGES.includes(objectImage.type)) {
      const selectedImage = objectImage.base64 ? objectImage.base64 : '';

      form[formItemIndex].itemData.itemContent.src = selectedImage;
      form[formItemIndex].itemData.itemContent.object = objectImage.file;

      setImage(selectedImage);
      setPreview(selectedImage);
      setAnchorEl(null);
      setForm(form);
    } else {
      toast.error('Tipo de arquivo não é suportado');
    }
  }

  return (
    <Image alignItems={alignItemType}>
      <ContainerRowTop>
        <MoreIcon onClick={handleClick} />
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <PopoverContainer>
            <PopoverButton
              type='button'
              onClick={() => handleClosePopover(
                FORM_ITEM_IMAGE_TYPE.ALIGN_LEFT.tag
              )}
            >
              <AlignLeftIcon />
              <label>{FORM_ITEM_IMAGE_TYPE.ALIGN_LEFT.value}</label>
            </PopoverButton>
            <PopoverButton
              type='button'
              onClick={() => handleClosePopover(
                FORM_ITEM_IMAGE_TYPE.ALIGN_CENTER.tag
              )}
            >
              <AlignCenterIcon />
              <label>{FORM_ITEM_IMAGE_TYPE.ALIGN_CENTER.value}</label>
            </PopoverButton>
            <PopoverButton
              type='button'
              onClick={() => handleClosePopover(
                FORM_ITEM_IMAGE_TYPE.ALIGN_RIGHT.tag
              )}
            >
              <AlignRightIcon />
              <label>{FORM_ITEM_IMAGE_TYPE.ALIGN_RIGHT.value}</label>
            </PopoverButton>
            <PopoverInputFile>
              <div id='input-image-base64-id'>
                <FileBase64
                  multiple={false}
                  onDone={handleImageBase64}
                />
              </div>
              <label htmlFor='input-image-base64-id'>
                <ImageIcon /> {FORM_ITEM_IMAGE_TYPE.ALTER_IMAGE.value}
              </label>
            </PopoverInputFile>
          </PopoverContainer>
        </Popover>
        <ImageContainer src={preview} alt="Imagem preview" />
      </ContainerRowTop>
    </Image>
  );
}

FormImage.propTypes = {
  form: PropTypes.array,
  setForm: PropTypes.func,
  formItemIndex: PropTypes.number,
  image: PropTypes.any,
  setImage: PropTypes.func,
};

export { FormImage };
