import PropTypes from 'prop-types';
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  MdKeyboardArrowRight,
  MdKeyboardArrowDown,
  MdFiberManualRecord,
} from 'react-icons/md';
import adminRoutes from '~/routes/admin';
import personRoutes from '~/routes/person';
import productRoutes from '~/routes/product';
import commercialRoutes from '~/routes/commercial';
import licenseRoutes from '~/routes/license';
import formRoutes from '~/routes/form';
// import goalRoutes from '~/routes/goal';
// import opportunityRoutes from '~/routes/opportunity';
import permissions from '~/util/permissions';
import { toast } from 'react-toastify';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import { icons } from './sideMenuIcons';
import { useSidebar } from '~/contexts/sidebar';
import { initialPaths } from '~/routes/paths';
import { RUNTIME_API } from '~/services/apiConfig';
import { visibleChatMovidesk } from '~/styles/movidesk';
import { useFitler } from '~/hook/useFilter';
import * as S from './styles';

function SidebarContent(sidebarContentProps) {
  const { setFilters } = useFitler();

  const {
    location,
    onSetCloseProfile,
    collapseSidebarProfile,
    onHandleMouseOver,
    onHandleMouseLeave,
  } = sidebarContentProps;

  const {
    active,
    setActive,
    subActive,
    setSubActive,
  } = useSidebar();

  const [linkMarketing, setLinkMarketing] = useState('');

  const user = useSelector(state => state.user.data);
  const { permissions: userPermissions } = user;

  const hasPermission = useCallback(
    permission => !permission || (
      permission && userPermissions.includes(permission)
    ),
    [userPermissions]
  );

  const canViewMarketing = useMemo(
    () => userPermissions.includes(permissions.view_marketing),
    [userPermissions]
  );

  const getLinkMarketing = useCallback(async () => {
    try {
      const {
        data: { data }
      } = await api.get(endpoints.admin.getLinkMarketing);
      if (data) {
        setLinkMarketing(data.serviceUrl);
      } else {
        toast.error('Erro ao consultar link do marketing.');
      }
    } catch (error) {
      showMessageError(error);
    }
  }, []);

  useEffect(() => {
    if (!linkMarketing && user.tipo) {
      const asyncEffect = async () => {
        await getLinkMarketing();
      };

      asyncEffect();
    }
  }, [getLinkMarketing, linkMarketing, user.tipo]);

  const handleGetMenuActive = useCallback((location, setActive, setSubActive) => {
    let splitPath = location.pathname;

    const baseWebDefault = RUNTIME_API.BASE_URL_PATH.slice(1);
    if (baseWebDefault) {
      splitPath = location.pathname.replace(baseWebDefault, '');
    }

    splitPath.split('/');

    switch (splitPath[1]) {
      case 'home':
        setActive(initialPaths.pageInitial);
        break;
      case 'person':
        setActive('Pessoa');
        switch (splitPath[2]) {
          case 'client':
            setSubActive('/person/client');
            break;
          case 'resale':
            setSubActive('/person/resale');
            break;
          case 'employee':
            setSubActive('/person/employee');
            break;
          case 'linebusiness':
            setSubActive('/person/linebusiness');
            break;
          case 'channelrating':
            setSubActive('/person/channelrating');
            break;
          default:
            setActive('Pessoa');
        }
        break;
      case 'product':
        setActive('Produto');
        switch (splitPath[2]) {
          case 'software':
            setSubActive('/product/software');
            break;
          case 'modality':
            setSubActive('/product/modality');
            break;
          case 'module':
            setSubActive('/product/module');
            break;
          default:
            setActive('Produto');
        }
        break;
      case 'commercial':
        setActive('Comercial');
        switch (splitPath[2]) {
          case 'order':
            setSubActive('/commercial/order');
            break;
          case 'solicitation':
            setSubActive('/commercial/solicitation');
            break;
          case 'reasonsolicitation':
            setSubActive('/commercial/reasonsolicitation');
            break;
          case 'payments':
            setSubActive('/commercial/payments');
            break;
          default:
            setActive('Comercial');
        }
        break;
      // case 'goal':
      //   setActive('Metas');
      //   switch (splitPath[2]) {
      //     case 'maintenance':
      //       setSubActive('/goal/maintenance');
      //       break;
      //     case 'monitoring':
      //       setSubActive('/goal/monitoring');
      //       break;
      //     default:
      //       setActive('Metas');
      //   }
      //   break;
      // case 'opportunity':
      //   setActive('Oportunidade');
      //   switch (splitPath[2]) {
      //     case 'monitoring':
      //       setSubActive('/opportunity/monitoring');
      //       break;
      //     case 'maintenance':
      //       setSubActive('/opportunity/maintenance');
      //       break;
      //     case 'reasonloss':
      //       setSubActive('/opportunity/reasonloss');
      //       break;
      //     case 'leadorigin':
      //       setSubActive('/opportunity/leadorigin');
      //       break;
      //     default:
      //       setActive('Oportunidade');
      //   }
      //   break;
      case 'form':
        setActive('Fomulário');
        switch (splitPath[2]) {
          case 'register':
            setSubActive('/form/register');
            break;
          case 'maintenance':
            setSubActive('/form/maintenance');
            break;
          default:
            setActive('Fomulário');
        }
        break;
      case 'license':
        setActive('Licença');
        switch (splitPath[2]) {
          case 'active':
            setSubActive('/license/active');
            break;
          case 'monitorcancellation':
            setSubActive('/license/monitorcancellation');
            break;
          case 'generatekey':
            setSubActive('/license/generatekey');
            break;
          case 'monitorkey':
            setSubActive('/license/monitorkey');
            break;
          case 'reasoncancellation':
            setSubActive('/license/reasoncancellation');
            break;
          default:
            setActive('Licença');
        }
        break;
      case 'marketing':
        setActive('Marketing');
        break;
      case 'admin':
        setActive('Administrador');
        switch (splitPath[2]) {
          case 'accessgroup':
            setSubActive('/admin/accessgroup');
            break;
          case 'settings':
            setSubActive('/admin/settings');
            break;
          case 'logs':
            setSubActive('/admin/logs');
            break;
          default:
            setActive('Administrador');
        }
        break;
      default:
        setActive(initialPaths.pageInitial);
    }
  }, []);

  if (!active) {
    handleGetMenuActive(location, setActive, setSubActive);
  }

  async function handleMenuClick(name, e, ignoreEvent) {
    if (!ignoreEvent) e.preventDefault();
    setActive(name);
    onSetCloseProfile();
    setFilters({});
  }

  async function handleSubmenuClick(name) {
    setSubActive(name);
    onSetCloseProfile();
    setFilters({});
  }

  const showRoutes = useCallback((routes) => {
    let show = false;
    for (let index = 0; index < routes.length; index += 1) {
      if (hasPermission(routes[index].permission)) {
        show = true;
        break;
      }
    }

    return show;
  }, [hasPermission]);

  const getIsCollapseSicebar = () => {
    return collapseSidebarProfile
      ? 'activeCollapseSidebar'
      : 'inactiveCollapseSidebar';
  };

  const getMenuLabel = (name, text, icon, hideArrow) => {
    if (active === name)
      return (
        <div className="activeMenu listHolder">
          <img
            src={icons[icon].active}
            alt={icons[icon].title}
            loading="lazy"
          />
          <div className={getIsCollapseSicebar()}>
            <label>{text}</label>
            {!hideArrow ? (
              <div className="arrowIcon">
                <MdKeyboardArrowDown />
              </div>
            ) : null}
          </div>
        </div>
      );

    return (
      <div className="inactiveMenu listHolder">
        <img
          src={icons[icon].inactive}
          alt={icons[icon].title}
          loading="lazy"
        />
        <div className={getIsCollapseSicebar()}>
          <label>{text}</label>
          {!hideArrow ? (
            <div className="arrowIcon">
              <MdKeyboardArrowRight />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderSubmenu = routeList => {
    return routeList
      .filter(route => !route.hideSidebar)
      .map(route => {
        return (
          hasPermission(route.permission) && (
            <li key={route.path}>
              <MdFiberManualRecord
                className={
                  subActive === route.path ? 'visibleIcon' : 'hideIcon'
                }
              />
              <Link
                className={subActive === route.path ? 'active' : 'inactive'}
                to={route.path}
                onClick={() => handleSubmenuClick(route.path)}
              >
                {route.name}
              </Link>
            </li>
          )
        );
      });
  };

  visibleChatMovidesk();

  return (
    <S.SidebarContainer
      onMouseOver={onHandleMouseOver}
      onMouseLeave={onHandleMouseLeave}
      isCollapse={collapseSidebarProfile}
    >
      <S.SidebarMenu isCollapse={collapseSidebarProfile}>
        <ul>
          <li>
            <NavLink
              to={initialPaths.pageInitial}
              onClick={event => {
                handleMenuClick(initialPaths.pageInitial, event, true);
              }}
            >
              {getMenuLabel(
                initialPaths.pageInitial,
                'Página Inicial',
                'home',
                true
              )}
            </NavLink>
          </li>
          {showRoutes(personRoutes) && (
            <li>
              <NavLink
                to="#"
                onClick={event => handleMenuClick('Pessoa', event)}
              >
                {getMenuLabel('Pessoa', 'Pessoa', 'people')}
              </NavLink>

              <nav
                className="submenu"
                style={{ display: !(active === 'Pessoa') ? 'none' : '' }}
              >
                <ul>{renderSubmenu(personRoutes)}</ul>
              </nav>
            </li>
          )}
          {showRoutes(productRoutes) && (
            <li>
              <NavLink
                to="#"
                onClick={event => handleMenuClick('Produto', event)}
              >
                {getMenuLabel('Produto', 'Produto', 'product')}
              </NavLink>

              <nav
                className="submenu"
                style={{ display: !(active === 'Produto') ? 'none' : '' }}
              >
                <ul>{renderSubmenu(productRoutes)}</ul>
              </nav>
            </li>
          )}
          {showRoutes(commercialRoutes) && (
            <li>
              <NavLink
                to="#"
                onClick={event => handleMenuClick('Comercial', event)}
              >
                {getMenuLabel('Comercial', 'Comercial', 'commercial')}
              </NavLink>

              <nav
                className="submenu"
                style={{ display: !(active === 'Comercial') ? 'none' : '' }}
              >
                <ul>{renderSubmenu(commercialRoutes)}</ul>
              </nav>
            </li>
          )}
          {/* {showRoutes(goalRoutes) && (
            <li>
              <NavLink
                to="#"
                onClick={event => handleMenuClick('Metas', event)}
              >
                {getMenuLabel('Metas', 'Metas', 'goal')}
              </NavLink>

              <nav
                className="submenu"
                style={{ display: !(active === 'Metas') ? 'none' : '' }}
              >
                <ul>{renderSubmenu(goalRoutes)}</ul>
              </nav>
            </li>
          )}
          {showRoutes(opportunityRoutes) && (
            <li>
              <NavLink
                to="#"
                onClick={event => handleMenuClick('Oportunidade', event)}
              >
                {getMenuLabel('Oportunidade', 'Oportunidade', 'opportunity')}
              </NavLink>

              <nav
                className="submenu"
                style={{ display: !(active === 'Oportunidade') ? 'none' : '' }}
              >
                <ul>{renderSubmenu(opportunityRoutes)}</ul>
              </nav>
            </li>
          )} */}
          {showRoutes(formRoutes) && (
            <li>
              <NavLink
                to="#"
                onClick={event => handleMenuClick('Formulário', event)}
              >
                {getMenuLabel('Formulário', 'Formulário', 'form')}
              </NavLink>

              <nav
                className="submenu"
                style={{ display: !(active === 'Formulário') ? 'none' : '' }}
              >
                <ul>{renderSubmenu(formRoutes)}</ul>
              </nav>
            </li>
          )}
          {showRoutes(licenseRoutes) && (
            <li>
              <NavLink
                to="#"
                onClick={event => handleMenuClick('Licença', event)}
              >
                {getMenuLabel('Licença', 'Licença', 'license')}
              </NavLink>
              <nav
                className="submenu"
                style={{ display: !(active === 'Licença') ? 'none' : '' }}
              >
                <ul>{renderSubmenu(licenseRoutes)}</ul>
              </nav>
            </li>
          )}
          {canViewMarketing && (
            <li>
              <a
                href={linkMarketing}
                target='_blank'
                rel="noopener noreferrer"
                onClick={event => handleMenuClick(active, event, true)}
              >
                {getMenuLabel('/marketing', 'Marketing', 'marketing', true)}
              </a>
            </li>
          )}
          {showRoutes(adminRoutes) && (
            <li>
              <NavLink
                to="#"
                onClick={event => handleMenuClick('Administrador', event)}
              >
                {getMenuLabel('Administrador', 'Administrador', 'admin')}
              </NavLink>

              <nav
                className="submenu"
                style={{ display: !(active === 'Administrador') ? 'none' : '' }}
              >
                <ul>{renderSubmenu(adminRoutes)}</ul>
              </nav>
            </li>
          )}
        </ul>
      </S.SidebarMenu>
    </S.SidebarContainer>
  );
}

SidebarContent.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  onSetCloseProfile: PropTypes.func.isRequired,
  collapseSidebarProfile: PropTypes.bool.isRequired,
  onHandleMouseOver: PropTypes.func.isRequired,
  onHandleMouseLeave: PropTypes.func.isRequired,
};

export default withRouter(SidebarContent);
