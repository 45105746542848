import styled from 'styled-components';

export const PrintableBodyWrapper = styled.div`
  @media print {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100vw;
    margin: 6%;
    color: var(--cm-primary);
  }

  h4 {
    font-weight: normal;
    align-self: flex-start;
    margin: 10px 0;
  }

  table {
    page-break-after: auto !important;
  }

  tr {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
  }

  td {
    page-break-inside: avoid !important;
    page-break-after: auto !important;
  }

  thead {
    display: table-header-group !important;
  }

  tfoot {
    display: table-footer-group !important;
  }

  display: none;
`;

export const PageHeaderReport = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-weight: normal;
  }

  .left {
    display: flex;
    flex-direction: column;
  }

  img {
    width: 100px;
  }
`;

export const PageTitleReport = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-weight: normal;
  }
`;
