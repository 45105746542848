import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from '@hookform/error-message';
import { useController } from 'react-hook-form';
import * as S from './styles';

// eslint-disable-next-line react/display-name
export const InputPassword = React.forwardRef((props, ref) => {
  const [typeButton, setTypeButton] = useState('password');
  const {
    name,
    addMarginBottom = false,
    addMarginTop = false,
    ...rest
  } = props;

  function isTypePassword() { return typeButton === 'password'; }

  function capLock(e) {
    var kc = e.keyCode ? e.keyCode : e.which;
    var sk = e.shiftKey ? e.shiftKey : ((kc === 16) ? true : false);
    if (((kc >= 65 && kc <= 90) && !sk) || ((kc >= 97 && kc <= 122) && sk)) {
      document.getElementById('capslock-'+name).style.display = 'block';
    } else {
      document.getElementById('capslock-'+name).style.display = 'none';
    }
  }

  function changeType() {
    const inputPassword = document.getElementById(name);
    if (inputPassword.getAttribute('type') === 'password') {
      inputPassword.setAttribute('type', 'text');
      setTypeButton('text');
    } else {
      inputPassword.setAttribute('type', 'password');
      setTypeButton('password');
    }
  }

  return (
    <>
      <S.ContainerInput addMarginBottom={addMarginBottom}>
        <S.Input
          id={name}
          ref={ref}
          htmlFor={name}
          onKeyPress={capLock}
          {...rest}
        />
        {isTypePassword() ? (
          <S.IconEye onClick={changeType} />
        ) : (
          <S.IconEyeSlash onClick={changeType} />
        )}
      </S.ContainerInput>
      <S.CapsLock addMarginTop={addMarginTop} id={'capslock-' + name}>
        Caps Lock ativado
      </S.CapsLock>
    </>
  );
});

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  addMarginBottom: PropTypes.bool,
  addMarginTop: PropTypes.bool,
};

function FieldPassword(props) {
  const {
    control,
    name,
    label,
    errors,
    ...rest
  } = props;

  const { field } = useController({ name, control });

  return (
    <>
      {label && (
        <S.Label htmlFor={name}>{label}</S.Label>
      )}
      <InputPassword
        addMarginBottom={true}
        addMarginTop={true}
        name={name}
        {...rest}
        {...field}
      />
      {errors && (
        <ErrorMessage as={S.Error} errors={errors} name={name} />
      )}
    </>
  );
}

FieldPassword.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
};

export default FieldPassword;
