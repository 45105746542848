import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FaTimes, FaDownload } from 'react-icons/fa';
import { showMessageError } from '~/util/errorutils';
import { toast } from 'react-toastify';
import * as S from './styles';
import './styles.scss';

function Attachments(props) {
  const [allAttachments, setAllAttachments] = useState([]);

  useEffect(() => {
    handleAllAttachments(props.files);
  }, [props.files]);

  function handleAllAttachments(attachment) {
    const allAttachmentsTemp = [];

    for (const key in attachment) {
      if (key !== 'length' && key !== 'item') {
        allAttachmentsTemp.push(attachment[key]);
      }
    }

    setAllAttachments(allAttachmentsTemp);
  }

  function handleRemoveAttachment(index) {
    const attachmentsUpdated = allAttachments
      .filter((attachment, attachmentIndex) => {
        return attachmentIndex !== index && attachment;
      });

    setAllAttachments(attachmentsUpdated);
    props.handleSetAnexos(attachmentsUpdated);
  }

  function handleNameAttachment(attchment) {
    return (attchment?.arquivo?.path
      || attchment?.name
      || attchment?.nome) || '';
  }

  async function handleDownloadFile(event, request, setLoading, fileName) {
    event.preventDefault();

    if (!request) {
      toast.error('Não existe request!');
      return;
    }

    if (!setLoading) {
      toast.error('Não existe loading!');
      return;
    }

    setLoading(true);
    try {
      const response = await request;
      const blob = new Blob([(response.data || response)]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.setAttribute('download', fileName);
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toast.success('Download de Arquivo realizado com sucesso!');
        setLoading(false);
      }, 0);
    } catch (error) {
      showMessageError(error, 'Arquivo não encontrado');
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {allAttachments.map((attchment, index) => {
        const fileName = handleNameAttachment(attchment);
        const canDownload = props.requestDownload && attchment?.id;

        return (
          <S.Tooltip className="tooltip"
            key={`${fileName}-${index}`}
          >
            <span className="tooltiptext">
              {fileName}
            </span>
            <S.AttachmentsContainer
              isDownload={canDownload}
              isMarginRight={props.dividerTwoMarginRight}
              customBgColor={props.customColor}
            >
              <span>{fileName}</span>
              <S.Actions>
                {canDownload && fileName && (
                  <FaDownload
                    title='Baixar'
                    onClick={(event) => handleDownloadFile(
                      event,
                      props.request(fileName),
                      props.setLoading,
                      fileName,
                    )}
                  />
                )}
                {props.canDelete && (
                  <FaTimes
                    title='Excluir'
                    onClick={() => handleRemoveAttachment(index)}
                  />
                )}
              </S.Actions>
            </S.AttachmentsContainer>
          </S.Tooltip>
        );
      })}
    </>
  );
}

Attachments.defaultProps = {
  files: [],
  handleSetAnexos: () => { },
  requestDownload: false,
  canDelete: true,
};

Attachments.propTypes = {
  files: PropTypes.any,
  handleSetAnexos: PropTypes.func,
  setLoading: PropTypes.func,
  request: PropTypes.func,
  requestDownload: PropTypes.bool,
  canDelete: PropTypes.bool,
  dividerTwoMarginRight: PropTypes.bool,
  customColor: PropTypes.string,
};

export default Attachments;
