/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { Container, Header, Content, Icon } from './styles';
import './styles.scss';

export default function CustomCollapsible({
  renderHeaderLeftComponent,
  renderHeaderRightComponent,
  renderContent,
  disabled,
  isItems = false,
}) {
  const [open, setOpen] = useState(true);

  return (
    <Container className='collapsibleHolder'>
      <Header className='collapsibleHeader' onClick={() => !disabled && setOpen(!open)}>
        {renderHeaderLeftComponent && renderHeaderLeftComponent()}
        <div className="leftContainer">
          {renderHeaderRightComponent && renderHeaderRightComponent()}
          {!disabled && <Icon open={open} />}
        </div>
      </Header>
      {open && (
        <Content
          className={`collapsibleContent ${isItems ? 'with-items' : 'no-items'}`}
        >
          {renderContent && renderContent()}
        </Content>
      )}
    </Container>
  );
}
