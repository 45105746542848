import { FORM_ITEM_ASNWER_TYPE } from '~/util/domainutils';
import ShortTextIcon from '~/assets/form/ShortText.svg';
import ParagraphIcon from '~/assets/form/Subject.svg';
import MultipleChoiceIcon from '~/assets/form/CheckBox.svg';
import SelectionBoxIcon from '~/assets/form/RadioButtonChecked.svg';

const ITENS_ANSWER = [
  {
    ...FORM_ITEM_ASNWER_TYPE.SHORT_ANSWER,
    icon: ShortTextIcon
  },
  {
    ...FORM_ITEM_ASNWER_TYPE.PARAGRAPH,
    icon: ParagraphIcon
  },
  {
    ...FORM_ITEM_ASNWER_TYPE.MULTIPLE_CHOICE,
    icon: SelectionBoxIcon
  },
  {
    ...FORM_ITEM_ASNWER_TYPE.SELECTION_BOX,
    icon: MultipleChoiceIcon
  }
];

export { ITENS_ANSWER };
