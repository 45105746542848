import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import { InputCheckbox } from '~/components/InputCheckbox';
import './styles.scss';

function TableComponent(props) {
  const {
    headerLabels,
    dataObjects,
    fieldsArray,
    editPermission,
    handleEdit,
    deletePermission,
    handleDelete,
    totalItems,
    handlePageUpdate,
    renderRowComponent,
    useCustomActions,
    useCustomSelectsAdd = false,
    useCustomSelectsRemove = false,
    renderCustomHeader,
    style,
    handleRemoveAllPerson,
    initialRowsPerPage10,
    initialRowsPerPage = 50,
    selectedObjectsPersonData,
    handleRadioSelectsAllPersonChange,
    noUseRowsPerPage = false,
    noPaddingVertical = false,
  } = props;

  const [pageParams, setPageParams] = useState({
    page: 0, rowsPerPage: parseInt(initialRowsPerPage),
  });

  const handleChangePage = async (event, newPage) => {
    pageParams.page = newPage;
    setPageParams(pageParams);
    handlePageUpdate(pageParams);
  };

  const handleChangeRowsPerPage = async event => {
    pageParams.page = 0;
    pageParams.rowsPerPage = parseInt(event.target.value, 10);
    setPageParams(pageParams);
    handlePageUpdate(pageParams);
  };

  const renderRowInfo = row => {
    if (renderRowComponent) return renderRowComponent(row);

    return (fieldsArray || []).map((field, index) => (
      <TableCell key={`render_row_info_${(index + 1) * fieldsArray.length}`}>
        {row[field] || '---'}
      </TableCell>)
    );
  };

  const renderHeader = () => {
    if (renderCustomHeader) return renderCustomHeader();

    const handleCheckedAllInputs = () => {
      const objectsTotal = (selectedObjectsPersonData || []).length;
      const dataObjectsTotal = dataObjects.length;
      const totalRows = parseInt(initialRowsPerPage);

      if (objectsTotal < totalRows && dataObjectsTotal > 0 && !noUseRowsPerPage) {
        return objectsTotal === dataObjectsTotal;
      }

      if (!noUseRowsPerPage) {
        return objectsTotal === totalRows;
      }

      return objectsTotal === dataObjectsTotal;
    };

    return (
      <TableRow>
        {useCustomSelectsAdd && (
          <TableCell className="actionCell" align='center'>
            <div className='tableHeaderLabelAll'>
              <InputCheckbox
                type="checkbox"
                name="selectPerson"
                checked={handleCheckedAllInputs()}
                onChange={handleRadioSelectsAllPersonChange}
              />
              <span>Todos</span>
            </div>
          </TableCell>
        )}
        {useCustomSelectsRemove && (
          <TableCell className="actionCell" align='center'>
            <div className='tableHeaderLabelAll'>
              <FaRegTrashAlt
                className='actionButtonsHolderButton'
                onClick={handleRemoveAllPerson}
                title="Deletar"
              />
              <span className='labelAll'>Todos</span>
            </div>
          </TableCell>
        )}
        {headerLabels.map((label, index) => (
          <TableCell
            key={`renderHeader-${label.text}-${(index + 1) * headerLabels.length}`}
            style={{
              width: label.width || 'auto',
              whiteSpace: label.nowrap ? 'nowrap' : 'normal',
            }}
            align={label.align || 'left'}
          >
            {label.text}
          </TableCell>
        ))}
        {!useCustomActions && (
          <TableCell className="actionCell">Ação</TableCell>
        )}
      </TableRow>
    );
  };

  function renderClassNameStyle(index) {
    const zebra = index % 2 !== 0 ? 'zebraStyle ' : '';
    const paddingVertical = noPaddingVertical ? 'noPaddingVertical ' : '';

    return `${zebra}${paddingVertical}tableRow`;
  }

  return (
    <div style={{ width: '100%' }}>
      <div className="tableHolder" style={style}>
        <Table className="resultTable">
          <TableHead className="tableHeader">
            {renderHeader()}
          </TableHead>
          <TableBody>
            {dataObjects.map((row, index) => (
              <TableRow
                key={`dataObjects-${(index + 1) * dataObjects.length}`}
                className={renderClassNameStyle(index)}
              >
                {renderRowInfo(row)}
                {!useCustomActions && (
                  <TableCell className="actionCell">
                    <div className="actionButtonsHolder">
                      <FaRegEdit
                        className="icon"
                        disabled={editPermission}
                        onClick={() => handleEdit(row)}
                        title="Editar"
                      />
                      <hr />
                      <FaRegTrashAlt
                        className="icon"
                        disabled={deletePermission}
                        onClick={() => handleDelete(row)}
                        title="Deletar"
                      />
                    </div>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {!!totalItems && (
        <div className="tablePaginationHolder">
          <Table className="tablePagination">
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={
                    initialRowsPerPage10 ? [10, 25, 50, 100] : [25, 50, 100]
                  }
                  count={totalItems}
                  labelRowsPerPage="Itens por página"
                  labelDisplayedRows={({ from, to, count }) =>
                    `${from} - ${to === -1 ? count : `${to} de ${count}`}`
                  }
                  rowsPerPage={pageParams.rowsPerPage}
                  page={pageParams.page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      )}
    </div>
  );
}

TableComponent.propTypes = {
  headerLabels: PropTypes.arrayOf(PropTypes.shape({})),
  dataObjects: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fieldsArray: PropTypes.arrayOf(PropTypes.string),
  editPermission: PropTypes.bool,
  handleEdit: PropTypes.func,
  deletePermission: PropTypes.bool,
  handleDelete: PropTypes.func,
  totalItems: PropTypes.number,
  handlePageUpdate: PropTypes.func,
  renderRowComponent: PropTypes.func,
  useCustomActions: PropTypes.bool,
  useCustomSelectsAdd: PropTypes.bool,
  useCustomSelectsRemove: PropTypes.bool,
  renderCustomHeader: PropTypes.func,
  initialRowsPerPage10: PropTypes.bool,
  initialRowsPerPage: PropTypes.number,
  selectedObjectsPersonData: PropTypes.array,
  handleRadioSelectsAllPersonChange: PropTypes.func,
  handleRemoveAllPerson: PropTypes.func,
  noUseRowsPerPage: PropTypes.bool,
  noPaddingVertical: PropTypes.bool,
  style: PropTypes.any,
};

export default TableComponent;
