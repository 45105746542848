import styled from 'styled-components';
import { IoMdArrowDropup } from 'react-icons/io';

export const Container = styled.div`
  align-self: stretch;
  margin: 10px 20px;
`;

export const Header = styled.div`
  background: #fff;
  padding: 5px 10px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  box-shadow: 0 1px 3px rgb(0, 0, 0, 0.16);

  div.leftContainer {
    display: flex;
    align-items: center;
  }
`;

export const Content = styled.div`
  background: #ffffff80;
  padding: 10px;
  display: flex;
  flex: 1;
`;

export const Icon = styled(IoMdArrowDropup)`
  margin: 0px 20px;
  color: #707070;
  font-size: 25px;
  align-self: center;
  transform: ${props => (props.open ? 'rotate(0deg)' : 'rotate(180deg)')};
`;
