import {
  FORM_ITEM_ASNWER_TYPE as TAGS,
  HELPER_ITEMS as HELPERS,
} from '~/util/domainutils';

import FormItemDataNew from './form-item-data-new';

export default class FormItemNew {
  constructor({ id, tag, isTypeAnswer = true }) {
    this.id = id;
    this.itemTag = HELPERS.ANSWERS.TAG[tag];
    this.itemId = HELPERS.ANSWERS.IDS[tag];

    this.itemData = new FormItemDataNew({
      tag: isTypeAnswer ? HELPERS.ANSWERS.TAG[tag] : HELPERS.DEFAULT.TAG[tag]
    });

    if (tag === TAGS.TITLE.tag) {
      this.isTitle = true;
      this.showRequired = true;
      this.unshowActions = false;
    }

    if (HELPERS.ANSWERS.TAG[tag] === TAGS.ANSWER.tag) {
      this.isTitle = false;
      this.showRequired = true;
      this.unshowActions = false;
    }

    if (tag === TAGS.IMAGE.tag) {
      this.isTitle = false;
      this.showRequired = false;
      this.unshowActions = false;
    }

    if (tag === TAGS.CLIENT_RESALE.tag) {
      this.isTitle = false;
      this.showRequired = false;
      this.unshowActions = true;
    }
  }
}
