export const actionTypes = {
  AUTH_CHANGE_LOADING: '@auth/AUTH_CHANGE_LOADING',
  AUTH_SIGN_IN_REQUEST_MFA: '@auth/AUTH_SIGN_IN_REQUEST_MFA',
  AUTH_SIGN_IN_REQUEST: '@auth/SIGN_IN_REQUEST',
  AUTH_SIGN_IN_REQUEST_SSO: '@auth/SIGN_IN_REQUEST_SSO',
  AUTH_SIGN_IN_SUCCESS: '@auth/SIGN_IN_SUCCESS',
  AUTH_SIGN_IN_FAILURE: '@auth/SIGN_IN_FAILURE',
  AUTH_SIGN_OUT: '@auth/SIGN_OUT',
};

export function changeLoading(isLoading) {
  return {
    type: actionTypes.AUTH_CHANGE_LOADING,
    payload: { isLoading }
  };
}

export function signInRequestMfa(tokenMfa, token, userInfo) {
  return {
    type: actionTypes.AUTH_SIGN_IN_REQUEST_MFA,
    payload: { tokenMfa, token, userInfo },
  };
}

export function signInRequest(login, password, reCaptchaToken) {
  return {
    type: actionTypes.AUTH_SIGN_IN_REQUEST,
    payload: { login, password, reCaptchaToken },
  };
}

export function signInRequestsSSO(email) {
  return {
    type: actionTypes.AUTH_SIGN_IN_REQUEST_SSO,
    payload: { email },
  };
}

export function signInSuccess(token, userInfo) {
  return {
    type: actionTypes.AUTH_SIGN_IN_SUCCESS,
    payload: { token, userInfo },
  };
}

export function signInFailure() {
  return {
    type: actionTypes.AUTH_SIGN_IN_FAILURE,
  };
}

export function signOut() {
  return {
    type: actionTypes.AUTH_SIGN_OUT,
  };
}
