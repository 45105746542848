import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { MdVpnKey } from 'react-icons/md';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import api, { endpoints } from '~/services/api';
import { LicenseService } from '~/services/license';
import { useSelector } from 'react-redux';
import { AdminSettingsService } from '~/services/admin-settings-service';
import ProductSoftwareService from '~/services/product-software-service';
import PersonClientService from '~/services/person-client-service';
import PersonResaleService from '~/services/person-resale-service';
import { showMessageError } from '~/util/errorutils';
import {
  formatCpfCnpj,
  validaCnpj,
  identifyCpfCnpj,
  unFormatCpfCnpj,
  onlyNumbers,
  inputNumber,
  validaCpf,
} from '~/util/stringutils';
import { CHECK_USER_BLOCK } from '~/util/functionsutils';
import PersonEmployeeService from '~/services/person-employee-service';
import { PERSON_STATUS_ENUM, USER_TYPE, SETTINGS_ENUM } from '~/util/domainutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';
import permissions from '~/util/permissions';
import SearchComponent from '../Active/SearchComponent';
import { GridGenerating, GridGenerated } from './Grids';
import { schemaChave, schemaGerarChave, schemaConfiguracoes } from './schema';
import {
  Container,
  ModalContainer,
  ModalContentHeader,
  ModalContentButtons,
} from './styles';

function GenerateKey({ history }) {
  const [loading, setLoading] = useState(false);
  const [inputCpfCnpj, setInputCpfCnpj] = useState('');
  const [inputSerie, setInputSerie] = useState('');
  const [inputModalidade, setInputModalidade] = useState('');
  const [inputSoftware, setInputSoftware] = useState('');
  const [inputRazaoSocial, setInputRazaoSocial] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [codExtSoftwares, setCodExtSoftwares] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    cpfCnpj: null,
    razaoSocial: '',
    serie: '',
    software: '',
  });

  const [step, setStep] = useState(1);
  const [allResales, setAllResales] = useState([]);
  const [generating, setGenerating] = useState(false);
  const [isFirstKey, setIsFirstKey] = useState(false);
  const [prazoChaveInConfig, setPrazoChaveInConfig] = useState('');
  const [prazoChaveToValidate, setPrazoChaveToValidate] = useState('');
  const [prazoReferente, setPrazoReferente] = useState('');
  const [inputPrazoChave, setInputPrazoChave] = useState('');
  const [inputControle, setInputControle] = useState('');
  const [disabeldControle, setDisabledControle] = useState(false);
  const [inputCpfCnpjRevenda, setInputCpfCnpjRevenda] = useState(null);

  const [chaveResponse, setChaveResponse] = useState(schemaChave);
  const [formGenerateKey, setFormGenerateKey] = useState(schemaGerarChave);
  const [settings, setSettings] = useState(schemaConfiguracoes);

  const currentUser = useSelector(state => state.user.data);
  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanViewInputRevenda = useMemo(
    () => userPermissions.includes(
      permissions['view_coluna-revenda']
    ),
    [userPermissions]
  );

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      try {
        const [userRevenda, userColaborador] = await Promise.all([
          PersonResaleService.getPersonResale(currentUser.revenda.id),
          currentUser.tipo === USER_TYPE.COLABORADOR
            ? PersonEmployeeService.getPersonEmployee(currentUser.id)
            : null,
        ]);

        await CHECK_USER_BLOCK(userRevenda, history);
        await CHECK_USER_BLOCK(userColaborador, history);
      } catch (error) {
        showMessageError(error, 'Erro ao verificar usuário');
      }
    };

    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      try {
        const requestEffect = [
          ProductSoftwareService.getProductSoftwares({}),
          AdminSettingsService.getTestLicense(),
        ];

        if (userCanViewInputRevenda) {
          requestEffect.push(
            PersonResaleService.getPersonResales({
              revendaId: currentUser.revenda.id,
            }),
            findAllByFilter(pageParameters, {
              cpfCnpjRevenda: unFormatCpfCnpj(currentUser.revenda.cpfCnpj),
            }),
          );

          setCountFilter(1);
        }

        const [
          resultSoftwares,
          resultSetting,
          resultResales,
        ] = await Promise.all(requestEffect);

        if (resultResales?.resultado?.length) {
          const allResalesTemp = resultResales.resultado.map(resale => {
            const nome = resale.nomeFantasia || resale.razaoSocial || '';
            return {
              value: unFormatCpfCnpj(resale.cpfCnpj),
              label: `${formatCpfCnpj(resale.cpfCnpj)} - ${nome}`,
            };
          });

          const cpfCnpjDefault = allResalesTemp
            .find(_ => _.value === unFormatCpfCnpj(currentUser.revenda.cpfCnpj));

          setAllResales(allResalesTemp);
          setInputCpfCnpjRevenda(cpfCnpjDefault);
        }

        if (resultSoftwares?.resultado?.length) {
          const allCodeSoftware = resultSoftwares.resultado.map(software => {
            return {
              nome: software.nome,
              codeERP: software.codigoSoftwareERP
                ? software.codigoSoftwareERP : '',
            };
          });

          setCodExtSoftwares(allCodeSoftware);

          if (resultSetting?.length) {
            handleInitialSettings(resultSetting);
          } else {
            toast('Nenhuma configuração encontrada', {
              type: toast.TYPE.ERROR,
            });
          }
        } else {
          toast('Nenhum software encontrado', {
            type: toast.TYPE.ERROR,
          });
        }
        setLoading(false);
      } catch (error) {
        toast.error('Erro ao consultar cod externo dos softwares, tente realizar a busca novamente, em caso de erro contate o administrador!');
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.revenda.cpfCnpj, currentUser.revenda.id, userCanViewInputRevenda]);

  function handleInitialSettings(arrSettings) {
    const settingData = { params: {}, values: {} };

    const messageCurrent = {
      code: '',
      messages: [],
    };

    arrSettings.forEach(setting => {
      if (SETTINGS_ENUM.GET_GERAR_COM === setting.code) {
        settingData.params.gerarCom = setting.value;
      }
      if (SETTINGS_ENUM.GET_ATIVAR_CHAVE === setting.code) {
        settingData.params.ativarChave = setting.value;
      }
      if (SETTINGS_ENUM.GET_PRAZO_MAXIMO_GERAR_CHAVE === setting.code) {
        settingData.values.prazo = setting.value;
        setPrazoChaveInConfig(setting.value);
      }
      if (SETTINGS_ENUM.GET_TEST_CPF_CNPJ === setting.code) {
        settingData.values.cnpj = setting.value;
      }
      if (SETTINGS_ENUM.GET_TEST_CODE_SOFTWARE === setting.code) {
        settingData.values.codigoSoftware = setting.value;
      }
      if (SETTINGS_ENUM.GET_TEST_SERIE === setting.code) {
        settingData.values.serie = setting.value;
      }
      if (SETTINGS_ENUM.GET_TEST_CONTROLE === setting.code) {
        settingData.values.controle = setting.value;
      }
      if (SETTINGS_ENUM.GET_TEST_VERSAO === setting.code) {
        settingData.values.versao = setting.value;
      }
      if (SETTINGS_ENUM.GET_MESSAGE_ATUAL === setting.code) {
        messageCurrent.code = setting.value;
      }
      if (SETTINGS_ENUM.GET_MESSAGE_CHAVE_TEST === setting.code) {
        messageCurrent.messages.push({
          code: SETTINGS_ENUM.GET_MESSAGE_CHAVE_TEST,
          value: setting.value
        });
      }
      if (SETTINGS_ENUM.GET_MESSAGE_CHAVE_ATIVADA === setting.code) {
        messageCurrent.messages.push({
          code: SETTINGS_ENUM.GET_MESSAGE_CHAVE_ATIVADA,
          value: setting.value
        });
      }
      if (SETTINGS_ENUM.GET_MESSAGE_CHAVE_DESATIVADA === setting.code) {
        messageCurrent.messages.push({
          code: SETTINGS_ENUM.GET_MESSAGE_CHAVE_DESATIVADA,
          value: setting.value
        });
      }
    });

    const [messageCurrentData] = messageCurrent.messages.filter(
      message => message.code === messageCurrent.code
    );

    settingData.params.mensagemAtual = messageCurrentData
      ? messageCurrentData.value : '';

    setSettings({ ...settingData });
  }

  function handleInputSerieChange(event) {
    const {
      target: { value },
    } = event;

    setInputSerie(inputNumber(value));
  }

  function handleInputModalidadeChange(event) {
    const {
      target: { value },
    } = event;

    setInputModalidade(value);
  }

  function handleInputSoftwareChange(event) {
    const {
      target: { value },
    } = event;

    setInputSoftware(value);
  }

  function handleInputCpfCnpjChange(event) {
    const {
      target: { value },
    } = event;

    setInputCpfCnpj(inputNumber(value));
  }

  function handleInputRazaoSocialChange(event) {
    const {
      target: { value },
    } = event;

    setInputRazaoSocial(value);
  }

  function handleInputChaveChange(event) {
    const {
      target: { value },
    } = event;

    setInputPrazoChave(inputNumber(value));
  }

  function handleInputControleChange(event) {
    const {
      target: { value },
    } = event;

    setInputControle(inputNumber(value));
  }

  function validateUserStatus(user) {
    let MSG = '';
    let VALIDATE_VALUE = true;

    const { NOT_BLOCKED } = PERSON_STATUS_ENUM;
    const isNotBlocked = user.status === NOT_BLOCKED.value;
    const isNotBlockedKey = user.statusChave === NOT_BLOCKED.value;

    const validateRevenda = () => {
      MSG = 'Revenda bloqueada para geração de chaves!';

      if (!isNotBlocked || !isNotBlockedKey) {
        VALIDATE_VALUE = false;
        toast(MSG, { type: toast.TYPE.ERROR });
      }

      const { prazoRenovacaoChave } = user;
      return [VALIDATE_VALUE, prazoRenovacaoChave];
    };

    const validateCliente = () => {
      const MSG_NAME = `${user.razaoSocial || user.nomeFantasia}`;
      const MSG_CPF_CNPJ = identifyCpfCnpj(user.cpfCnpj);
      const MSG_CPF_CNPJ_FORMATED = `${formatCpfCnpj(user.cpfCnpj)}`;

      let resultado = null;

      if (isNotBlocked) {
        const {
          razaoSocial,
          nomeFantasia,
          cpfCnpj,
          contato,
          endereco: enderecoData,
          prazoRenovacaoChave,
        } = user;

        const { endereco } = enderecoData;
        const { email } = contato;

        resultado = {
          razaoSocial,
          nomeFantasia,
          cpfCnpj,
          endereco,
          email,
          prazoRenovacaoChave,
        };

        if (!isNotBlockedKey) {
          const MSG_END = 'bloqueado para geração de chave!';
          MSG = `${MSG_NAME} com ${MSG_CPF_CNPJ} ${MSG_CPF_CNPJ_FORMATED} ${MSG_END}`;

          toast(MSG, { type: toast.TYPE.ERROR });
        }
      } else {
        VALIDATE_VALUE = false;

        const MSG_END = 'bloqueado no sistema!';
        MSG = `${MSG_NAME} com ${MSG_CPF_CNPJ} ${MSG_CPF_CNPJ_FORMATED} ${MSG_END}`;
        toast(MSG, { type: toast.TYPE.ERROR });
      }

      return [VALIDATE_VALUE, resultado];
    };

    switch (user.tipo) {
      case USER_TYPE.REVENDA:
        return validateRevenda();
      case USER_TYPE.CLIENTE:
        return validateCliente();
      default:
        return [false, null];
    }
  }

  async function validateFormGenerateKey(success) {
    const formKey = {};

    if (!formGenerateKey.software.titulo || !formGenerateKey.software.titulo.length) {
      toast('Software não encontrato!', { type: toast.TYPE.ERROR });
      return;
    }

    const titleSoftware = formGenerateKey.software.titulo.trim().toUpperCase();

    const [software] = codExtSoftwares.filter(software => {
      return software.nome === titleSoftware;
    });

    if (!software) {
      toast('Código do software não encontrato!', { type: toast.TYPE.ERROR });
      return;
    }

    if (!formGenerateKey.software.serie || !formGenerateKey.software.serie.length) {
      toast('Série não encontrata!', { type: toast.TYPE.ERROR });
      return;
    }

    const isCpfCnpj = identifyCpfCnpj(
      unFormatCpfCnpj(formGenerateKey.cliente.cpfCnpj)
    );

    if (isCpfCnpj === 'CPF') {
      if (!validaCpf(unFormatCpfCnpj(formGenerateKey.cliente.cpfCnpj))) {
        toast('CPF inválido!', { type: toast.TYPE.ERROR });
        return;
      }

      if (!formGenerateKey.cliente.cpfCnpj) {
        toast('CPF não encontrata!', { type: toast.TYPE.ERROR });
        return;
      }
    }

    if (isCpfCnpj === 'CNPJ') {
      if (!validaCnpj(unFormatCpfCnpj(formGenerateKey.cliente.cpfCnpj))) {
        toast('CNPJ inválido!', { type: toast.TYPE.ERROR });
        return;
      }

      if (!formGenerateKey.cliente.cpfCnpj) {
        toast('CNPJ não encontrata!', { type: toast.TYPE.ERROR });
        return;
      }
    }

    if (!inputControle || !inputControle.length) {
      toast('Campo Controle é obrigatório!', { type: toast.TYPE.ERROR });
      return;
    }

    if (!onlyNumbers(inputControle)) {
      toast(
        'Campo Controle aceita apenas números (0...9)!',
        { type: toast.TYPE.ERROR },
      );
      return;
    }

    if (!inputPrazoChave || !inputPrazoChave.length) {
      toast('Campo Prazo da Chave é obrigatório!', { type: toast.TYPE.ERROR });
      return;
    }

    if (!onlyNumbers(inputPrazoChave)) {
      toast(
        'Campo Prazo de Chave aceita apenas números (0...9)!',
        { type: toast.TYPE.ERROR }
      );
      return;
    }

    if (parseInt(inputPrazoChave) > prazoChaveToValidate) {
      toast(
        `Prazo máximo permitido ${prazoReferente} é de ${prazoChaveToValidate} dias!`,
        { type: toast.TYPE.ERROR }
      );
      return;
    }

    formKey.softwareNome = titleSoftware;
    formKey.clienteNome = formGenerateKey.cliente.razaoSocial;
    formKey.codigoSoftware = software.codeERP;
    formKey.serie = formGenerateKey.software.serie;
    formKey.cnpj = unFormatCpfCnpj(formGenerateKey.cliente.cpfCnpj);
    formKey.versao = formGenerateKey.software.versao;
    formKey.controle = inputControle;
    formKey.prazo = inputPrazoChave;
    formKey.ePrimeiraChave = isFirstKey;

    await success(formKey);
  }

  function getAndValidatePrazoRenovacaoChave(prazos) {
    const { cliente, revenda, sistema } = prazos;

    if (!cliente && !revenda) {
      setPrazoReferente('do sistema');
      return sistema;
    }

    if (!cliente && revenda) {
      setPrazoReferente('da revenda');
      return revenda;
    }

    if (cliente && !revenda) {
      if (cliente <= sistema) {
        setPrazoReferente('do cliente');
        return cliente;
      } else {
        setPrazoReferente('do sistema');
        return sistema;
      }
    }

    if (cliente && revenda) {
      if (cliente <= revenda) {
        setPrazoReferente('do cliente');
        return cliente;
      } else {
        setPrazoReferente('da revenda');
        return revenda;
      }
    }

    setPrazoReferente('do sistema');
    return sistema;
  }

  function getViewCliente(client) {
    return {
      razaoSocial: client.razaoSocial,
      cpfCnpj: formatCpfCnpj(client.cpfCnpj),
      nomeFantasia: client.nomeFantasia,
      email: client.email,
      endereco: client.endereco,
    };
  }

  const handleRowActionGenerateKey = async (row) => {
    setLoading(true);
    try {
      const [
        { resultado: findRevenda },
        { resultado: findCliente },
      ] = await Promise.all([
        PersonResaleService.getPersonKeyResale(
          unFormatCpfCnpj(row.cpfCnpjRevenda),
        ),
        PersonClientService.getPersonKeyClient(
          unFormatCpfCnpj(row.cpfCnpjCliente),
        ),
      ]);
      if (findRevenda) {
        const [
          revendaNotBlocked,
          prazoRevenda,
        ] = validateUserStatus(findRevenda);
        if (revendaNotBlocked) {
          if (findCliente) {
            const [
              clientNotBlocked,
              dataCliente,
            ] = validateUserStatus(findCliente);
            if (clientNotBlocked) {
              const prazos = {
                cliente: parseInt(dataCliente.prazoRenovacaoChave),
                revenda: parseInt(prazoRevenda),
                sistema: parseInt(prazoChaveInConfig),
              };
              const prazoAvaliado = getAndValidatePrazoRenovacaoChave(prazos);
              setPrazoChaveToValidate(prazoAvaliado);
              setInputPrazoChave(String(prazoAvaliado));
              setFormGenerateKey({
                cliente: getViewCliente(dataCliente),
                software: {
                  titulo: row.software,
                  serie: row.serie,
                  versao: row.versao,
                  controle: row.controle || '',
                },
              });

              if (row.controle) {
                setInputControle(row.controle);
                setDisabledControle(true);
                setIsFirstKey(false);
              } else {
                setIsFirstKey(true);
              }

              setGenerating(true);
            }
          } else {
            toast('Cliente não cadastrado na base de dados do portal!', {
              type: toast.TYPE.ERROR,
            });
          }
        }
      } else {
        toast('Revenda não cadastrado na base de dados do portal!', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleSetChaveResponse(result) {
    if (result) {
      const { chave, data, geradaPor } = result;

      setChaveResponse({
        chave,
        prazo: inputPrazoChave,
        geradaEm: data,
        geradaPor,
        versao: formGenerateKey.software.versao,
        controle: inputControle,
      });
    } else {
      setChaveResponse({
        chave: 'XXXXXX.XXXXXX.XXXXXX.XXXXXX.XXXXXX.XXXXXX.XXXXXX',
        prazo: '',
        geradaEm: '',
        geradaPor: '',
        versao: '',
        controle: '',
      });
    }
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};
    if (userCanViewInputRevenda && inputCpfCnpjRevenda) {
      data.cpfCnpjRevenda = unFormatCpfCnpj(inputCpfCnpjRevenda.value);
      count += 1;
    }
    if (inputCpfCnpj) {
      data.cpfCnpj = unFormatCpfCnpj(inputCpfCnpj);
      count += 1;
    }
    if (inputRazaoSocial.length) {
      data.razaoSocial = inputRazaoSocial.trim();
      count += 1;
    }
    if (inputSerie.length) {
      data.serie = inputSerie;
      count += 1;
    }
    if (inputSoftware.length) {
      const titleSoftware = inputSoftware.trim().toUpperCase();

      const [software] = codExtSoftwares.filter(software => {
        return software.nome === titleSoftware;
      });

      if (software && software.codeERP) {
        data.codExtSoftware = software.codeERP;
        count += 1;
      } else {
        toast('Código software ERP não encontrado', {
          type: toast.TYPE.ERROR,
        });
        setTimeout(() => {
          handleSetSearchResponse();
        }, 100);
        return;
      }
    }

    setCountFilter(count);
    setFormData(data);
    await success(data);
  }

  async function findAllByFilter(pageParams, formDataParams) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const resultLicense = await LicenseService.getLicenses(
        { ...(formDataParams || formData) }, page, rowsPerPage,
      );

      const result = resultLicense.resultado;
      if (result.length) {
        handleSetSearchResponse(result, resultLicense.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos licenças ativas para esta pesquisa!', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  const renderRowComponent = (row) => {
    return [
      <TableCell key='row_key_0'>{row.serie}</TableCell>,
      <TableCell key='row_key_1'>{row.software}</TableCell>,
      <TableCell key='row_key_2'>
        {formatCpfCnpj(row.cpfCnpjCliente)}
      </TableCell>,
      <TableCell key='row_key_3'>{row.razaoSocialCliente}</TableCell>,
      <TableCell key='row_key_4' className="actionCell">
        <div className="actionButtonsHolder buttonCenterHolder">
          <MdVpnKey
            className="icon"
            disabled={true}
            onClick={() => handleRowActionGenerateKey(row)}
            title="Geração de Chave"
          />
        </div>
      </TableCell>,
    ];
  };

  function handleCloseGenerateKey() {
    setGenerating(false);
    setStep(1);
    setInputPrazoChave('');
    setInputControle('');
  }

  async function handleGenerateKey(formKey) {
    setLoading(true);
    setGenerating(false);
    try {
      let formKeyData = formKey;

      if (settings.params.gerarCom === 'test_license') {
        formKeyData = {
          softwareNome: formKeyData.softwareNome,
          clienteNome: formKeyData.clienteNome,
          ePrimeraChave: isFirstKey,
          ...settings.values,
        };
      }
      const { data: response } = await api.post(endpoints.license.getChave, {
        ...formKeyData,
      });
      if (response.data.resultado) {
        handleSetChaveResponse(response.data.resultado);
        setStep(2);
      } else {
        toast('Não foi possível fazer a geração da chave!', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
      setGenerating(true);
    }
  }

  function handleSubmitKey() {
    validateFormGenerateKey(async data => {
      await handleGenerateKey(data);
    });
  }

  function handleEnterClick(event) {
    if (event.keyCode === 13) return handleSubmitKey();
  }

  return (
    <>
      <Container>
        <Modal show={loading} loading />
        <SidebarPageHeader mainMenu="Licença" pageName="Chave" />
        <Modal show={generating} noPadding >
          <ModalContainer onKeyDown={(event) => handleEnterClick(event)}>
            <ModalContentHeader>
              <h3>
                {isFirstKey
                  ? 'Geração da Primeira Chave'
                  : 'Geração de Chave'}
              </h3>
            </ModalContentHeader>
            {step === 1 && (
              <GridGenerating
                formGenerateKey={formGenerateKey}
                inputPrazoChaveValue={inputPrazoChave}
                handleInputPrazoChaveChange={handleInputChaveChange}
                inputControleValue={inputControle}
                isDisabeldControle={disabeldControle}
                isFirstKey={isFirstKey}
                handleInputControleChange={handleInputControleChange}
              />
            )}
            {step === 2 && (
              <GridGenerated
                formGeneratingKey={formGenerateKey}
                formGeneratedKeyResponse={chaveResponse}
              />
            )}
            <ModalContentButtons>
              {step === 1 ? (
                <>
                  <button
                    className='buttonBaseOnly addMargin'
                    onClick={handleCloseGenerateKey}
                  >
                    Cancelar
                  </button>
                  <button
                    className='buttonBaseOnly baseMain'
                    onClick={handleSubmitKey}
                  >
                    Gerar Chave
                  </button>
                </>
              ) : (
                <button
                  className='buttonBaseOnly'
                  onClick={handleCloseGenerateKey}
                >
                  Fechar
                </button>
              )}
            </ModalContentButtons>
          </ModalContainer>
        </Modal>
        <SearchComponent
          countFilter={countFilter}
          page={settings.params.mensagemAtual && 'KEY'}
          messageHelp={settings.params.mensagemAtual}
          handleSubmit={handleSubmit}
          cpfCnpjValue={inputCpfCnpj}
          handleCpfCnpjChange={handleInputCpfCnpjChange}
          razaoSocialValue={inputRazaoSocial}
          handleRazaoSocialChange={handleInputRazaoSocialChange}
          serieValue={inputSerie}
          handleSerieChange={handleInputSerieChange}
          softwareValue={inputSoftware}
          handleSoftwareChange={handleInputSoftwareChange}
          modalidadeValue={inputModalidade}
          handleModalidadeChange={handleInputModalidadeChange}
          allResales={allResales}
          inputCpfCnpjRevenda={inputCpfCnpjRevenda}
          setInputCpfCnpjRevenda={setInputCpfCnpjRevenda}
          userCanViewInputRevenda={userCanViewInputRevenda}
        />
        <TableComponent
          headerLabels={[
            { text: 'N. de Série', width: '15%' },
            { text: 'Software', width: '17%' },
            { text: 'CPF/CNPJ', width: '17%' },
            { text: 'Razão Social', width: 'auto' },
            { text: 'Ação', align: 'center', width: '9%' },
          ]}
          dataObjects={searchResponse}
          renderRowComponent={renderRowComponent}
          useCustomActions
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
        />
      </Container>
    </>
  );
}

GenerateKey.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default GenerateKey;
