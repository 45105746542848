import PropTypes from 'prop-types';
import React from 'react';
import { InputCpfCnpj } from './styles';

function FieldCpfCnpj(props) {
  const { cpfCnpjValue, handleCpfCnpjChange, ...rest } = props;

  return (
    <InputCpfCnpj
      type="text"
      value={cpfCnpjValue}
      onChange={handleCpfCnpjChange}
      {...rest}
    />
  );
}

FieldCpfCnpj.propTypes = {
  cpfCnpjValue: PropTypes.string,
  handleCpfCnpjChange: PropTypes.func,
};

export { FieldCpfCnpj };
