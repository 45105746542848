/* eslint-disable react/prop-types */
import React from 'react';
import { UNIDADES_FEDERATIVAS, PERSON_STATUS_ENUM } from '~/util/domainutils';
import {
  formatCpfCnpj,
  formatPhone,
  normalizeText,
  onlyNumbers,
  identifyCpfCnpj,
} from '~/util/stringutils';
import * as S from './styles';

function ShowDataClient({ dataClient }) {
  const getClientName = (cliObj) => {
    return cliObj?.nomeFantasia || cliObj?.razaoSocial || '';
  };

  const getClientAddress = (cliObj) => {
    if (!cliObj) return '';

    const {
      endereco,
      bairro,
      cidade,
      estado,
    } = cliObj;

    let nomeEstado = estado;
    if (estado.length === 2) {
      nomeEstado = UNIDADES_FEDERATIVAS.find(_ => _.sigla === estado).nome;
    }

    return `
      ${(endereco || '--')},
      ${(bairro || '--')},
      ${(cidade || '--')} -
      ${(nomeEstado || '--')}`;
  };

  const getClientContacts = (cliObj) => {
    if (!cliObj) return '';

    const { telefone1, telefone2 } = cliObj;

    const tel1 = !!onlyNumbers(telefone1);
    const tel2 = !!onlyNumbers(telefone2);

    switch (true) {
      case (tel1 && tel2):
        return `${formatPhone(telefone1)} / ${formatPhone(telefone2)}`;

      case (tel1 && !tel2):
        return formatPhone(telefone1);

      case (!tel1 && tel2):
        return formatPhone(telefone2);

      default:
        return '--';
    }
  };

  const getStatusChave = (keyStatus) => {
    const stt1 = normalizeText(keyStatus);
    const stt2 = normalizeText(PERSON_STATUS_ENUM.NOT_BLOCKED.value);

    switch (true) {
      case (stt1 === stt2):
        return 'Desbloqueado';
      case (stt1 !== stt2):
        return 'Bloqueado';
      default:
        return '--';
    }
  };

  const getPrazoRenovacaoChave = (term) => {
    return term ? `${term} dias` : '--';
  };

  const typeUser = identifyCpfCnpj(dataClient.cpfCnpj);

  return (
    <S.DataInfoContainer>
      <div className="info col2">
        <div>
          <strong>Nome: </strong> {getClientName(dataClient)}
        </div>
        <div>
          <strong>{typeUser}: </strong> {formatCpfCnpj(dataClient.cpfCnpj)}
        </div>
        <div>
          <strong>Endereço: </strong> {getClientAddress(
            ((typeof dataClient.endereco === 'object')
              ? dataClient.endereco
              : dataClient)
          )}
        </div>
        <div>
          <strong>Telefone(s): </strong> {getClientContacts(
            ((typeof dataClient.contato === 'object')
              ? dataClient.contato
              : dataClient)
          )}
        </div>
        <div>
          <strong>E-mail: </strong> {
            (dataClient.contato?.email || dataClient.email)
          }
        </div>
      </div>
      <div className="info col2">
        <div>
          <strong>
            {typeUser === 'CPF' ? 'RG' : 'Inscrição Estadual'}:{' '}
          </strong> {(dataClient.inscricaoEstadual || '--')}
        </div>
        <div>
          <strong>Microempresa: </strong> {
            (dataClient.microempresa ? 'Sim' : 'Não')
          }
        </div>
        <div>
          <strong>Retém ICMS: </strong> {
            (dataClient.retemICMS ? 'Sim' : 'Não')
          }
        </div>
        <div>
          <strong>Geração de chave: </strong> {
            getStatusChave(dataClient.statusChave)
          }
        </div>
        <div>
          <strong>Prazo de Renovação de Chave: </strong> {
            getPrazoRenovacaoChave(dataClient.prazoRenovacaoChave)
          }
        </div>
      </div>
    </S.DataInfoContainer>
  );
}

function ShowDataBackgound({ children, addMarginTop }) {
  return (
    <S.InfoHolder addMarginTop={addMarginTop}>
      {children}
    </S.InfoHolder>
  );
}

export { ShowDataClient, ShowDataBackgound };
