import styled from 'styled-components';

export const ButtonCopy = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 4px;
  text-decoration: none;
  border-radius: 4px;
  border: 1px solid var(--cm-primary);
  font-size: 10pt;
  height: 22px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  color: var(--cm-primary);

  svg {
    margin-right: 2px;
  }
`;

export const Copied = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #05874A;
  background-color: #E4FFF3;
  border: 1px solid #05874A;
  border-radius: 6px;
  padding: 10px 13px;
  margin-bottom: 16px;

  svg {
    margin-right: 10px;
    font-size: 20px;
  }
`;
