import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '~/components/Modal';
import { toast } from 'react-toastify';
import { commercialPaths } from '~/routes/paths';
import { showMessageError } from '~/util/errorutils';
import { PaymentsService } from '~/services/commercial/payment';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import * as S from '../styles';

function AddPayments({ history }) {
  const [loading, setLoading] = useState(false);
  const [inputPaymentForm, setInputPaymentForm] = useState('');
  const [errorPaymentForm, setErrorPaymentForm] = useState(false);
  const [paymentPlans, setPaymentPlans] = useState([{
    name: 'payment-plan 0',
    value: '',
    error: false,
  }]);

  function handleInputPaymentFormChange(event) {
    const {
      target: { value },
    } = event;

    setInputPaymentForm(value);
    setErrorPaymentForm(false);
  }

  function handlePlansChange(event, subIndex) {
    const {
      target: { value },
    } = event;

    const paymentPlansItem = Array.from(paymentPlans);

    const [changeValue] = paymentPlansItem.filter(
      (_, index) => index === subIndex,
    );

    if (changeValue) {
      changeValue.value = value;
      changeValue.error = false;

      paymentPlansItem[subIndex] = changeValue;

      setPaymentPlans(paymentPlansItem);
    }
  }

  function validadeForm(success) {
    const data = {};
    let error_temp = false;

    if (!inputPaymentForm || !inputPaymentForm.length) {
      setErrorPaymentForm(true);
      error_temp = true;
    }

    let error_paymentPlan = false;
    const paymentPlansWithError = paymentPlans.map((plan, index) => {
      if (index === 0) {
        if (!plan.value || !plan.value.length) {
          error_paymentPlan = true;

          return {
            ...plan,
            error: true,
          };
        }
      }

      return plan;
    });

    if (error_paymentPlan) {
      error_temp = true;
      setPaymentPlans(paymentPlansWithError);
    }

    if (error_temp) {
      return;
    }

    data.formaPagamento = inputPaymentForm.trim();
    data.planosPagamento = paymentPlans
      .filter(plan => plan.value)
      .map(plan => plan.value.trim());

    success(data);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    validadeForm(async (formData) => {
      setLoading(true);
      try {
        const result = await PaymentsService.createPayments(formData);

        toast(result.message, { type: toast.TYPE.SUCCESS });

        setTimeout(() => {
          history.push(commercialPaths.payments);
        }, 100);
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });
  }

  function handleDeleteSubMotivo(subIndex) {
    setPaymentPlans(oldState => [
      ...oldState.filter((_, index) => index !== subIndex)
    ]);
  }

  function handleShowButtonDelete() {
    return !(paymentPlans.length <= 1);
  }

  function handleAddPaymentPlans() {
    setPaymentPlans(oldState => [...oldState, {
      name: `payment-plan ${oldState.length}`,
      value: '',
      error: false,
    }]);
  }

  return (
    <div className="defaultFormContainer">
      <Modal loading show={loading} />
      <SidebarPageHeader
        mainMenu="Comercial"
        pageName="Incluir Pagamento"
        breadcrumbs={[{
          label: 'Pagamento',
          link: commercialPaths.payments,
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => handleSubmit,
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={() => history.goBack()}
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <span id="title">Dados do Pagamento</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Forma do Pagamento *</span>
              <input
                type="text"
                name="payment-form"
                value={inputPaymentForm}
                onChange={handleInputPaymentFormChange}
              />
              {errorPaymentForm && (
                <S.ContainerError>
                  Campo obrigatório
                </S.ContainerError>
              )}
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Dados do(s) Plano(s) de Pagamento</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <div className="rowContainer" style={{ marginTop: 6 }}>
                <div className="inputHolder defaultFlex noMarginRight">
                  {paymentPlans.map((plan, index) => (
                    <S.ContainerPayments key={`plan-${index}`}>
                      <span>
                        {`Plano de Pagamento ${index === 0 ? '*' : ''}`}
                      </span>
                      <S.ContainerInputDeleteRow>
                        <input
                          type="text"
                          name={plan.name}
                          value={plan.value}
                          onChange={(event) => handlePlansChange(event, index)}
                        />
                        {handleShowButtonDelete() && (
                          <S.DeleteIcon
                            disabled={true}
                            onClick={() => handleDeleteSubMotivo(index)}
                            title="Deletar"
                          />
                        )}
                      </S.ContainerInputDeleteRow>
                      {plan.error && (
                        <S.ContainerError>
                          Campo obrigatório
                        </S.ContainerError>
                      )}
                    </S.ContainerPayments>
                  ))}
                </div>
              </div>
              <S.ContainerButton onClick={handleAddPaymentPlans}>
                <S.AddIcon />
                <span>PLANO</span>
              </S.ContainerButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

AddPayments.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};

export default AddPayments;
