export default {
  create_cliente: 'create_cliente',
  edit_cliente: 'edit_cliente',
  delete_cliente: 'delete_cliente',
  view_cliente: 'view_cliente',
  edit_revenda: 'edit_revenda',
  delete_revenda: 'delete_revenda',
  view_revenda: 'view_revenda',
  create_revenda: 'create_revenda',
  edit_colaborador: 'edit_colaborador',
  delete_colaborador: 'delete_colaborador',
  view_colaborador: 'view_colaborador',
  create_colaborador: 'create_colaborador',
  edit_software: 'edit_software',
  delete_software: 'delete_software',
  view_software: 'view_software',
  create_software: 'create_software',
  edit_modalidade: 'edit_modalidade',
  delete_modalidade: 'delete_modalidade',
  view_modalidade: 'view_modalidade',
  create_modalidade: 'create_modalidade',
  edit_modulo: 'edit_modulo',
  delete_modulo: 'delete_modulo',
  view_modulo: 'view_modulo',
  create_modulo: 'create_modulo',
  edit_pedido: 'edit_pedido',
  delete_pedido: 'delete_pedido',
  view_pedido: 'view_pedido',
  create_pedido: 'create_pedido',
  status_pedido: 'status_pedido',
  edit_solicitacao: 'edit_solicitacao',
  delete_solicitacao: 'delete_solicitacao',
  view_solicitacao: 'view_solicitacao',
  create_solicitacao: 'create_solicitacao',
  status_solicitacao: 'status_solicitacao',
  view_licenca: 'view_licenca',
  delete_licenca: 'delete_licenca',
  status_licenca: 'status_licenca',
  manage_licencas: 'manage_licencas',
  manage_key: 'manage_key',
  view_marketing: 'view_marketing',
  view_settings: 'view_settings',
  view_logs: 'view_logs',
  block_user: 'block_user',
  'edit_mass-actions': 'edit_mass-actions',
  'edit_link-marketing': 'edit_link-marketing',
  'view_generate-key': 'view_generate-key',
  'edit_ramo-atividade': 'edit_ramo-atividade',
  'delete_ramo-atividade': 'delete_ramo-atividade',
  'view_ramo-atividade': 'view_ramo-atividade',
  'create_ramo-atividade': 'create_ramo-atividade',
  'edit_grupo-acesso': 'edit_grupo-acesso',
  'delete_grupo-acesso': 'delete_grupo-acesso',
  'view_grupo-acesso': 'view_grupo-acesso',
  'create_grupo-acesso': 'create_grupo-acesso',
  'edit_classificacao-canal': 'edit_classificacao-canal',
  'delete_classificacao-canal': 'delete_classificacao-canal',
  'view_classificacao-canal': 'view_classificacao-canal',
  'create_classificacao-canal': 'create_classificacao-canal',
  'edit_motivo-cancelamento': 'edit_motivo-cancelamento',
  'delete_motivo-cancelamento': 'delete_motivo-cancelamento',
  'view_motivo-cancelamento': 'view_motivo-cancelamento',
  'create_motivo-cancelamento': 'create_motivo-cancelamento',
  'view_convite-pessoa': 'view_convite-pessoa',
  'create_convite-pessoa': 'create_convite-pessoa',
  'edit_convite-pessoa': 'edit_convite-pessoa',
  'delete_convite-pessoa': 'delete_convite-pessoa',
  'view_form-register': 'view_form-register',
  'create_form-register': 'create_form-register',
  'edit_form-register': 'edit_form-register',
  'delete_form-register': 'delete_form-register',
  'disable_form-register': 'disable_form-register',
  'view_form-maintenance': 'view_form-maintenance',
  'create_form-maintenance': 'create_form-maintenance',
  'edit_form-maintenance': 'edit_form-maintenance',
  'finished_form-maintenance': 'finished_form-maintenance',
  'edit_responsavel-executivo': 'edit_responsavel-executivo',
  'edit_mudanca-atendimento-revenda': 'edit_mudanca-atendimento-revenda',
  'view_mudanca-atendimento-revenda': 'view_mudanca-atendimento-revenda',
  'edit_mudanca-colaborador-revenda': 'edit_mudanca-colaborador-revenda',
  'view_motivo-solicitacao': 'view_motivo-solicitacao',
  'create_motivo-solicitacao': 'create_motivo-solicitacao',
  'edit_motivo-solicitacao': 'edit_motivo-solicitacao',
  'delete_motivo-solicitacao': 'delete_motivo-solicitacao',
  'view_pagamento': 'view_pagamento',
  'create_pagamento': 'create_pagamento',
  'edit_pagamento': 'edit_pagamento',
  'delete_pagamento': 'delete_pagamento',
  'view_coluna-revenda': 'view_coluna-revenda',
  'message_resposta-cm': 'message_resposta-cm',
  'view_metas': 'view_metas',
  'create_metas': 'create_metas',
  'edit_metas': 'edit_metas',
  'delete_metas': 'delete_metas',
  'view_motivo-perda': 'view_motivo-perda',
  'create_motivo-perda': 'create_motivo-perda',
  'edit_motivo-perda': 'edit_motivo-perda',
  'delete_motivo-perda': 'delete_motivo-perda',
  'view_manutencao-oportunidade': 'view_manutencao-oportunidade',
  'create_manutencao-oportunidade': 'create_manutencao-oportunidade',
  'edit_manutencao-oportunidade': 'edit_manutencao-oportunidade',
  'delete_manutencao-oportunidade': 'delete_manutencao-oportunidade',
  'view_origem-lead': 'view_origem-lead',
  'create_origem-lead': 'create_origem-lead',
  'edit_origem-lead': 'edit_origem-lead',
  'delete_origem-lead': 'delete_origem-lead',
  'view_monitoramento-oportunidade': 'view_monitoramento-oportunidade',
  'create_monitoramento-oportunidade': 'create_monitoramento-oportunidade',
  'edit_monitoramento-oportunidade': 'edit_monitoramento-oportunidade',
  'delete_monitoramento-oportunidade': 'delete_monitoramento-oportunidade',
  'view_salvar-rascunho': 'view_salvar-rascunho',
  'view_botao-editar': 'view_botao-editar',
  'login-sso': 'login-sso',
  'revenda_deletar_rascunho': 'revenda_deletar_rascunho',
};
