import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import { CollapsibleFilter } from '~/components/CollapsibleFilter';
import './styles.scss';

function PersonSearch({
  countFilter,
  codeValue,
  nameValue,
  handleCodeChange,
  handleNameChange,
  handleSubmit,
  selectedSoftware,
  handleSoftwareChange,
  softwares,
  selectedModality,
  handleModalityChange,
  modalities,
}) {
  return (
    <CollapsibleFilter countFilters={countFilter}>
      <form className="formContainer" onSubmit={handleSubmit}>
        <label id="searchText">Pesquisar</label>
        <div className="rowContainer">
          <div id='code' className="inputHolder">
            <span>Código</span>
            <input
              type="text"
              name="code"
              value={codeValue}
              onChange={handleCodeChange}
            />
          </div>
          <div className="inputHolder defaultFlex">
            <span>Nome</span>
            <input
              type="text"
              name="name"
              value={nameValue}
              onChange={handleNameChange}
            />
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Software</span>
            <select
              name="software"
              value={selectedSoftware}
              onChange={handleSoftwareChange}
            >
              <option value="">Selecione</option>
              {softwares.map(item => (
                <option key={item.id} value={item.id}>
                  {item.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="inputHolder defaultFlex">
            <span>Modalidade</span>
            <select
              name="modality"
              value={selectedModality}
              onChange={handleModalityChange}
            >
              <option value="">Selecione</option>
              {modalities.map(item => (
                <option key={item.id} value={item.id}>
                  {item.nome}
                </option>
              ))}
            </select>
          </div>
          <button className="buttonBaseOnly" id="baseButtonIcon" onClick={handleSubmit}>
            <FaSearch id="baseNameIcon" />
            <span>BUSCAR</span>
          </button>
        </div>
      </form>
    </CollapsibleFilter>
  );
}

PersonSearch.propTypes = {
  countFilter: PropTypes.number,
  codeValue: PropTypes.string.isRequired,
  nameValue: PropTypes.string.isRequired,
  handleCodeChange: PropTypes.func.isRequired,
  handleNameChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedSoftware: PropTypes.string.isRequired,
  handleSoftwareChange: PropTypes.func.isRequired,
  softwares: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedModality: PropTypes.string.isRequired,
  handleModalityChange: PropTypes.func.isRequired,
  modalities: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PersonSearch;
