import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import { Label, Error } from './styles';

function Select(props) {
  const { control, name, label, errors, children, ...rest } = props;

  const { field } = useController({
    name,
    control,
  });

  return (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}
      <select id={name} {...field} {...rest}>
        {children}
      </select>
      {errors && <ErrorMessage as={Error} errors={errors} name={name} />}
    </>
  );
}

Select.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  children: PropTypes.any,
};

export default Select;
