import { Fragment } from 'react';
import styled from 'styled-components';

export const UserInfo = Fragment;
export const UserInfoContent = Fragment;

export const FormClientResaleContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border: ${(props) => props.withBorder ? '1px solid #2C2F8D' : 'none'};
  border-radius: 10px;

  label {
    color: var(--cm-primary);
  }

  label.title {
    color: var(--cm-primary);
    font-weight: bold;
  }

  label.footer {
    color: #FD1616;
  }
`;

export const ClientResaleContent = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${(props) => props.noMarginBottom ? 'none' : '10px'};;

  @media (max-width: 560px) {
    @media print {
      flex-direction: row;
      font-size: 11pt;

      div + div {
        margin-top: 0 !important;
      }

      .divider {
        display: block !important;
        width: 1px;
      }
    }

    flex-direction: column;

    div {
      width: 100%;
    }

    div + div {
      margin-top: 10px;
    }

    .divider {
      display: none;
    }
  }
`;

export const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 49%;
`;

export const DataContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const Divider = styled.div`
  width: 1px;
  background-color: #2C2F8D4D;
  margin-right: 10px;
`;
