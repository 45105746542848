/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { FORM_ITEM_ASNWER_TYPE as TAGS } from '~/util/domainutils';
import { FormClientResale } from '~/pages/Form/Items/FormClientResale';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
  PrintableBodyWrapper, ViewerContainerItem, ViewerSoftwareModule, ViewerImage,
  ViewerAnswer, ViewerTitle1, ViewerTitle2, ViewerDescription, ViewerOther,
  ViewerOptionsContainer, ViewerOption, ViewerOptionOther, ViewerAnswerContent,
  ViewerAnswerWithDescription, ViewerOptionsContainerContent, ViewerInput, TextareaHelper
} from './styles';

const PrintableForm = React.forwardRef(({
  location,
  formViewer,
  answeredFilled,
  contentItemsFormAnswer,
}, ref) => {
  const { register, reset, getValues } = useForm({ defaultValues: {} });
  const currentData = moment(new Date());
  const schedule = moment(currentData).format('DD/MM/YYYY');

  useEffect(() => {
    if (location) {
      if (location.state && location.state.extraInfo) {
        const extraInfo = JSON.parse(location.state.extraInfo);

        for (const input in extraInfo.defaultValues) {
          extraInfo.defaultValues[input] = extraInfo.defaultValues[input]
            ? extraInfo.defaultValues[input] : '---';
        }

        reset(extraInfo.defaultValues);
      }
    } else {
      if (answeredFilled.extraInfo) {
        const defaultValuesTemp = answeredFilled.extraInfo.defaultValues;

        for (const inputName in defaultValuesTemp) {
          defaultValuesTemp[inputName] = defaultValuesTemp[inputName]
            ? defaultValuesTemp[inputName] : '---';
        }

        reset(defaultValuesTemp);
      }
    }
  }, [location, reset, answeredFilled]);

  function handleViewerClientResale() {
    return (
      <>
        <ViewerSoftwareModule>
          <span><b>Software:</b> {formViewer.softwareName}</span>
          <span><b>Módulo:</b> {formViewer.moduleName}</span>
          <span><b>Solicitado em:</b> {answeredFilled.requestedIn}</span>
        </ViewerSoftwareModule>
        <FormClientResale
          clientId={answeredFilled.idClient}
          resaleId={answeredFilled.idResale}
          showData={true}
          showDefaultData={false}
          withBorder={true}
        />
      </>
    );
  }

  function handleViewerImage(item, index) {
    const { src, align } = item.itemData.itemContent;

    return (
      <ViewerImage justifyContent={align}>
        <img src={src} alt={`Posição ${index}`} />
      </ViewerImage>
    );
  }

  function handleViewerTitle(item) {
    const { statement, description, required } = item.itemData;

    let formatDescription = [];

    if (description) {
      formatDescription = description.split('\n');
    }

    return (
      <>
        {required ? (
          <ViewerTitle1>
            {statement && (
              <h2>{statement.toUpperCase()}</h2>
            )}
          </ViewerTitle1>
        ) : (
          <ViewerTitle2>
            {statement && (
              <b><u>{statement.toUpperCase()}</u></b>
            )}
          </ViewerTitle2>
        )}
        {formatDescription.map((description, index) => (
          <ViewerDescription key={index}>
            {description}
          </ViewerDescription>
        ))}
      </>
    );
  }

  function handleViewerAnswer(item) {
    const {
      statement,
      description,
      itemTypeId,
      itemContent,
    } = item.itemData;

    let titleGroup = contentItemsFormAnswer
      .filter(input => input.itemFormId === item.id)
      .map(input => input.answerContent.attrName);

    [titleGroup] = titleGroup.filter(attrName => !!attrName);

    const optionOther = [];
    const optionWithoutOther = [];

    if (itemContent) {
      itemContent.forEach((option) => {
        if (!option.other) {
          optionWithoutOther.push(option);
        } else {
          optionOther.push(option);
        }
      });
    }

    const radio = { 3: 'radio' };
    const checkbox = { 4: 'checkbox' };
    const HELPERS_TYPE = { ...radio, ...checkbox };

    const IS_LONG_STATEMENT = statement.length > 50;

    return (
      <>
        {!description ? (
          <label htmlFor={titleGroup}>
            <ViewerAnswerContent isLong={IS_LONG_STATEMENT}>
              <ViewerAnswer isLong={IS_LONG_STATEMENT}>
                {statement}:
              </ViewerAnswer>
              {itemTypeId === TAGS.SHORT_ANSWER.id && (
                <input
                  style={{ margin: 0 }}
                  ref={register()}
                  name={titleGroup}
                  {...register(titleGroup)}
                />
              )}
            </ViewerAnswerContent>
          </label>
        ) : (
          <ViewerAnswerWithDescription>
            <label htmlFor={titleGroup}>
              <ViewerAnswer isLong={IS_LONG_STATEMENT}>
                {statement}:
              </ViewerAnswer>
            </label>
            <ViewerDescription className='answer'>
              {description}
            </ViewerDescription>
            {itemTypeId === TAGS.SHORT_ANSWER.id && (
              <input
                ref={register()}
                name={titleGroup}
                {...register(titleGroup)}
              />
            )}
          </ViewerAnswerWithDescription>
        )}
        {itemTypeId === TAGS.PARAGRAPH.id && (
          <>
            <textarea
              ref={register()}
              name={titleGroup}
              {...register(titleGroup)}
            />

            <TextareaHelper>{getValues(titleGroup)}</TextareaHelper>
          </>
        )}
        {(itemTypeId === TAGS.MULTIPLE_CHOICE.id
          || itemTypeId === TAGS.SELECTION_BOX.id)
          && (
            <ViewerOptionsContainerContent>
              {optionWithoutOther && (
                <ViewerOptionsContainer>
                  {optionWithoutOther.map((option) => (
                    <ViewerOption key={option.id}>
                      {!option.other && (
                        <ViewerInput>
                          <input
                            ref={register()}
                            name={titleGroup}
                            className={'viewer-option'}
                            type={HELPERS_TYPE[itemTypeId]}
                            value={typeof option.itemData.value === 'string'
                              ? option.itemData.value.trim()
                              : option.itemData.value
                            }
                            {...register(titleGroup)}
                          />
                          <label htmlFor={titleGroup}>
                            {option.itemData.value}
                          </label>
                        </ViewerInput>
                      )}
                    </ViewerOption>
                  ))}
                </ViewerOptionsContainer>
              )}
              {optionOther[0] && (
                <ViewerOther>
                  <ViewerOptionOther>
                    <input
                      ref={register()}
                      name={titleGroup}
                      className={'viewer-option'}
                      value={optionOther[0].itemData.label}
                      type={HELPERS_TYPE[itemTypeId]}
                      {...register(titleGroup)}
                    />
                    <label htmlFor={optionOther[0].itemData.label}>
                      {'Outro:'}
                    </label>
                  </ViewerOptionOther>
                  <input
                    ref={register()}
                    name={`${titleGroup}_option_other`}
                    {...register(`${titleGroup}_option_other`)}
                  />
                </ViewerOther>
              )}
            </ViewerOptionsContainerContent>
          )}
      </>
    );
  }

  function handleRenderForm(formViewer) {
    return (
      <div>
        {formViewer.contentItemsForm.map((item, index) => (
          <ViewerContainerItem className='breakItemPage' key={item.id}>
            {item.itemTag === TAGS.CLIENT_RESALE.tag && (
              handleViewerClientResale()
            )}
            {item.itemTag === TAGS.IMAGE.tag && (
              handleViewerImage(item, index)
            )}
            {item.itemTag === TAGS.TITLE.tag && (
              handleViewerTitle(item)
            )}
            {item.itemTag === TAGS.ANSWER.tag && (
              handleViewerAnswer(item, index)
            )}
          </ViewerContainerItem>
        ))}
      </div>
    );
  }

  return (
    <PrintableBodyWrapper ref={ref}>
      <div className="right">
        <h4>Gerado em <strong>{schedule}</strong></h4>
      </div>
      {formViewer && handleRenderForm(formViewer)}
    </PrintableBodyWrapper>
  );
});

export default PrintableForm;
