import store from '~/store';
import history from '~/routes/history';
import { signOut } from '~/store/modules/auth/actions';
import { toast } from 'react-toastify';
import { authenticationPaths } from '~/routes/paths';

const isResponseError = (error) => {
  return error.response && error.response.data;
};

const isResponseInternalError = (error) => {
  return Object.keys(error.response.data.internalError).length === 0;
};

const isInternalError = (error) => {
  return error.internalError && Object.keys(error.internalError).length > 0;
};

/**
 * It returns a string with the error message
 * @param error - The error object that was thrown.
 * @returns A function that returns an error message.
 */
export function catchMessageError(error) {
  let underMaintenance = false;
  let message = 'Error 0 :: não identificado, contate o adminstrador!!';
  if (isResponseError(error)) {
    if (error.response.data.internalError) {
      if (isResponseInternalError(error)) {
        message = error.response.data.internalError;
      } else if (error.response.data.message) {
        message = error.response.data.message;
      }
    } else if (error.response.data.message) {
      message = error.response.data.message;
    } else {
      message = 'Error 1 :: interno não identificado, contate o adminstrador!!';
    }
  } else if (isInternalError(error)) {
    if (typeof error.internalError === 'string') {
      message = error.internalError;
    } else if (error.message) {
      message = error.message;
      if (error.internalError?.tipo && error.internalError.tipo === 'manutencao') {
        underMaintenance = true;
      }
    } else {
      message = 'Error 2 :: interno não identificado, contate o adminstrador!!';
    }
  } else if (error.message) {
    if (typeof error.message === 'object') {
      if (error.message.title) {
        message = error.message.title;
        if (error.message.description) {
          message += error.message.description;
        }
        if (error.message.tipo === 'manutencao') {
          underMaintenance = true;
        }
      } else {
        message = 'Error 3 :: error como objeto, contate o adminstrador!!';
      }
    } else {
      message = error.message;
    }
  } else {
    if (!!error.size && !!error.type) {
      message = 'Arquivo não existe!!';
    } else {
      message = 'Error 4 :: não identificado, contate o adminstrador!!';
    }
  }

  return [message, underMaintenance];
}

/**
 * It takes an error object and a custom message, and if the error object has a
 * message, it shows that message, otherwise it shows the custom message
 * @param error - The error object that was returned from the API.
 * @param customMessage - This is the message that will be displayed if the error
 * message is not found.
 */
export function showMessageError(error, customMessage = 'Mensagem não definida') {
  const [msg, underMaintenance] = catchMessageError(
    (error?.response || {}).data || error,
  );

  if (!underMaintenance) {
    if (typeof msg === 'string') {
      toast(msg, { type: toast.TYPE.ERROR });
    } else {
      if (customMessage) {
        toast(customMessage, { type: toast.TYPE.ERROR });
      }
    }
  } else {
    if (!toast.isActive('underMaintenanceId')) {
      const AUTO_CLOSE_MS = 4000; // 4 segundos

      toast(msg, {
        toastId: 'underMaintenanceId',
        type: toast.TYPE.SUCCESS,
        autoClose: AUTO_CLOSE_MS,
      });

      setTimeout(() => {
        history.push(authenticationPaths.login);
        store.dispatch(signOut());
      }, AUTO_CLOSE_MS);
    }
  }
}
