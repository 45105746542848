import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { groupBy } from 'lodash';
import { orderResponse } from '~/util/tableutils';
import TableCell from '@material-ui/core/TableCell';
import { admin } from '~/routes/paths';
import Modal from '~/components/Modal';
import { AdminGroupAccessService } from '~/services/admin-group-access-service';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';
import { showMessageError } from '~/util/errorutils';

export default function EditAccessGroup({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [accessGroupName, setAccessGroupName] = useState('');
  const [accessGroupDescription, setAccessGroupDescription] = useState('');
  const [accessGroupPermissions, setAccessGroupPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [defaultPermissions, setDefaultPermissions] = useState([]);
  const [customPermissions, setCustomPermissions] = useState([]);
  const [nameError, setNameError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  useEffect(() => {
    const getPermissions = async () => {
      try {
        setLoading(true);
        const responsePermissions = await AdminGroupAccessService
          .getAccessGroupPermissions();

        const groupedPermissions = groupBy(
          responsePermissions.resultado, 'nome',
        );

        const defaultPermissionsArray = [];
        let customPermissionsArray = [];
        Object.keys(groupedPermissions).forEach((key) => {
          if (groupedPermissions[key].length === 1) {
            customPermissionsArray = customPermissionsArray
              .concat(groupedPermissions[key]);
          } else {
            defaultPermissionsArray.push({
              key,
              items: groupedPermissions[key],
            });
          }
        });

        const ordenedDefaultPermissions = orderResponse({
          collection: defaultPermissionsArray,
          by: ['key'],
          noMessage: true,
        });

        const ordenedCustomPermissions = orderResponse({
          collection: customPermissionsArray,
          by: ['nome'],
          noMessage: true,
        });

        setDefaultPermissions(ordenedDefaultPermissions);
        setCustomPermissions(ordenedCustomPermissions);
        setAllPermissions(responsePermissions.resultado);
        setLoading(false);
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    };

    getPermissions();
  }, []);

  useEffect(() => {
    if (!location.state) {
      return history.push(admin.accessGroup);
    } else {
      const { name, description, permissions } = location.state;

      setAccessGroupName(name);
      setAccessGroupDescription(description);

      setAccessGroupPermissions(
        permissions
          .filter(permission => permission.permissao && permission)
      );
    }
  }, [history, location.state]);

  function handleNameChange(event) {
    const {
      target: { value },
    } = event;

    setAccessGroupName(value);
    setNameError(false);
  }

  function handleDescriptionChange(event) {
    const {
      target: { value },
    } = event;

    setAccessGroupDescription(value);
    setDescriptionError(false);
  }

  function handleActivePermissionChange(isActive, permissionId) {
    const permissionSelected = allPermissions.find(
      permission => permission.id === permissionId
    );

    const newPermissions = isActive
      ? [...accessGroupPermissions, { permissao: permissionSelected }]
      : accessGroupPermissions.filter(per => per.permissao.id !== permissionId);

    setAccessGroupPermissions(newPermissions);
  }

  function handleBatchPermissionChange(active, permissions) {
    const permissionsToAdd = [];
    const permissionsToRemove = [];

    permissions.forEach(permission => {
      const isActive = !!accessGroupPermissions.find(
        perm => perm.permissao.id === permission
      );

      if (isActive && !active) {
        permissionsToRemove.push(permission);
      }

      if (!isActive && active) {
        permissionsToAdd.push({
          permissao: allPermissions.find(perm => perm.id === permission),
        });
      }
    });

    return setAccessGroupPermissions(
      accessGroupPermissions
        .concat(permissionsToAdd)
        .filter(
          permission =>
            !permissionsToRemove.find(perm => perm === permission.permissao.id)
        )
    );
  }

  async function validateData(success) {
    const data = {};
    let errors_temp = false;

    if (!accessGroupName || !accessGroupName.length) {
      setNameError(true);
      errors_temp = true;
    }

    if (!accessGroupDescription || !accessGroupDescription.length) {
      setDescriptionError(true);
      errors_temp = true;
    }

    if (errors_temp) {
      return;
    }

    const permissions = accessGroupPermissions.map(
      permission => permission.permissao.id
    );

    data.id = location.state.id;
    data.nome = accessGroupName.trim();
    data.descricao = accessGroupDescription.trim();
    data.permissoes = permissions;

    return await success(data);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    await validateData(async (resultData) => {
      setLoading(true);
      try {
        const responseGroupAccess = await AdminGroupAccessService
          .updateAccessGroup(resultData);

        setTimeout(() => {
          toast(
            'É necessário fazer o reload no sistema para carregar'
            + ' as novas informações de acesso!'
            , {
              type: toast.TYPE.SUCCESS,
              autoClose: 7000, // 7 segundos
            },
          );
        }, 100); // 0.1 segundos

        toast.success(responseGroupAccess.message);
        setLoading(false);
        setTimeout(() => {
          history.push(admin.accessGroup);
        }, 200); // 0.2 segundos
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });
  }

  const renderRowComponentDefault = row => {
    let optionsActives = 0;
    const renderCheckbox = item => {
      if (!item) return <TableCell key={`item_empty_${Math.random()}`} />;

      const { id } = item;
      const isActive = !!accessGroupPermissions.find(
        permission => permission.permissao.id === id
      );

      if (isActive) optionsActives++;

      return (
        <TableCell key={item.codigo}>
          <div className="radioHolder">
            <div className="inputBoxHolder">
              <input
                type="checkbox"
                checked={isActive}
                onChange={() => handleActivePermissionChange(!isActive, id)}
              />
              <span className="checkmark">
                {isActive && (
                  <div className="markHolder">
                    <div className="checkmark_stem" />
                    <div className="checkmark_kick" />
                  </div>
                )}
              </span>
            </div>
          </div>
        </TableCell>
      );
    };

    const chooseItemHelper = action => {
      return row.items.filter(item => item.codigo.split('_')[0] === action)[0];
    };

    return [
      <TableCell key="row_key_0">{row.key}</TableCell>,
      renderCheckbox(chooseItemHelper('view')),
      renderCheckbox(chooseItemHelper('create')),
      renderCheckbox(chooseItemHelper('edit')),
      renderCheckbox(chooseItemHelper('delete')),
      <TableCell key="row_key_1">
        <div className="radioHolder">
          <div className="inputBoxHolder">
            <input
              type="checkbox"
              checked={optionsActives === row.items.length}
              onChange={() => {
                handleBatchPermissionChange(
                  !(optionsActives === row.items.length),
                  row.items.map(item => item.id)
                );
              }}
            />
            <span className="checkmark">
              {optionsActives === row.items.length && (
                <div className="markHolder">
                  <div className="checkmark_stem" />
                  <div className="checkmark_kick" />
                </div>
              )}
            </span>
          </div>
        </div>
      </TableCell>,
    ];
  };

  const renderRowComponentCustom = row => {
    const { id, nome, descricao } = row;
    const isActive = !!accessGroupPermissions.find(
      permission => permission.permissao.id === id
    );

    return [
      <TableCell key="row_custom_0">{nome}</TableCell>,
      <TableCell key="row_custom_1">{descricao}</TableCell>,
      <TableCell key="row_custom_2">
        <div key={id} className="radioHolder">
          <div className="inputBoxHolder">
            <input
              type="checkbox"
              checked={isActive}
              onChange={() => handleActivePermissionChange(!isActive, id)}
            />
            <span className="checkmark">
              {isActive && (
                <div className="markHolder">
                  <div className="checkmark_stem" />
                  <div className="checkmark_kick" />
                </div>
              )}
            </span>
          </div>
        </div>
      </TableCell>,
    ];
  };

  return (
    <div className="defaultFormContainer">
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Administrador"
        pageName="Editar Grupo"
        breadcrumbs={[{
          label: 'Grupo de Acesso',
          link: admin.accessGroup,
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => handleSubmit,
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={() => history.goBack()}
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <span id="title">Dados do Grupo</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex">
              <span>Nome do Grupo *</span>
              <input
                type="text"
                name="name"
                value={accessGroupName}
                onChange={handleNameChange}
              />
              {nameError && (
                <small style={{ color: 'red' }}>
                  Campo obrigatório
                </small>
              )}
            </div>
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Descrição *</span>
              <input
                type="text"
                name="description"
                value={accessGroupDescription}
                onChange={handleDescriptionChange}
              />
              {descriptionError && (
                <small style={{ color: 'red' }}>
                  Campo obrigatório
                </small>
              )}
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Selecione as permissões do novo Grupo</span>
          <div className="rowContainer">
            <TableComponent
              headerLabels={[
                { text: 'Permissão', width: 'auto' },
                { text: 'Ver', width: '10%', align: 'center' },
                { text: 'Criar', width: '10%', align: 'center' },
                { text: 'Editar', width: '10%', align: 'center' },
                { text: 'Deletar', width: '10%', align: 'center' },
                { text: 'Todas', width: '10%', align: 'center' },
              ]}
              dataObjects={defaultPermissions}
              renderRowComponent={renderRowComponentDefault}
              useCustomActions
            />
          </div>
          <div className="rowContainer">
            <TableComponent
              headerLabels={[
                { text: 'Permissão', width: '30%' },
                { text: 'Descrição', width: 'auto' },
                { text: 'Ativas', width: '10%', align: 'center' },
              ]}
              dataObjects={customPermissions}
              renderRowComponent={renderRowComponentCustom}
              useCustomActions
            />
          </div>
        </div>
      </form>
    </div>
  );
}

EditAccessGroup.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      permissions: PropTypes.array,
      grupoAcessos: PropTypes.array,
    }).isRequired,
  }).isRequired,
};
