import PropTypes from 'prop-types';
import React from 'react';

function ComponentClient({
  cpfCnpjValue,
  nomeFantasiaValue,
  razaoSocialValue,
}) {
  return (
    <>
      <label id="title">Dados do Cliente</label>
      <div className="rowContainer">
        <div className="inputHolder">
          <span>CPF / CNPJ</span>
          <input
            disabled
            id="cpfCnpj"
            type="text"
            name="cpfCnpj"
            value={cpfCnpjValue}
          />
        </div>
        <div className="inputHolder defaultFlex">
          <span>Cliente</span>
          <input
            disabled
            id="razao"
            type="text"
            name="razao"
            value={razaoSocialValue}
          />
        </div>
        <div className="inputHolder defaultFlex noMarginRight">
          <span>Nome Fantasia</span>
          <input
            disabled
            id="name"
            type="text"
            name="name"
            value={nomeFantasiaValue}
          />
        </div>
      </div>
    </>
  );
}

ComponentClient.propTypes = {
  cpfCnpjValue: PropTypes.string.isRequired,
  nomeFantasiaValue: PropTypes.string.isRequired,
  razaoSocialValue: PropTypes.string.isRequired,
};

export default ComponentClient;
