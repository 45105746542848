import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { commercialPaths } from '~/routes/paths';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import Modal from '~/components/Modal';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import { FORM_ITEM_ASNWER_TYPE as TAGS } from '~/util/domainutils';
import { FormClientResale } from '../../Items/FormClientResale';
import { useForm } from 'react-hook-form';
import ReactToPrint from 'react-to-print';
import PrintableForm from '~/components/PrintableForm';
import * as S from './styles';

function ViewerAnswerFilled({ history, location }) {
  const [answeredFilled, setAnsweredFilled] = useState({});
  const [loading, setLoading] = useState(false);
  const [formViewer, setFormViewer] = useState(null);
  const [contentItemsFormAnswer, setContentItemsFormAnswer] = useState([]);
  const { register, watch, reset, formState: { errors } } = useForm({
    defaultValues: {}
  });

  const componentRef = useRef(null);

  useEffect(() => {
    if (!location.state) {
      setFormViewer(null);
      history.push(location.state.pathReferenceToBack);
    }

    if (!location.state) {
      setFormViewer(null);
      history.push(location.state.pathReferenceToBack);
    }

    if (location.state.extraInfo) {
      const extraInfo = JSON.parse(location.state.extraInfo);

      reset(extraInfo.defaultValues);
      setAnsweredFilled(location.state);
    }
  }, [history, location.state, reset]);

  useEffect(() => {
    let cleaningAsyncEffect = false;

    const asyncEffect = async () => {
      if (cleaningAsyncEffect) return;
      setLoading(true);
      try {
        const formViewer = await api.get(
          endpoints.form.register.getForm(location.state.idForm)
        );

        const [formResponse] = formViewer.data.data.resultado;

        let dataFormViewer = {
          formName: formResponse.name,
          softwareName: formResponse.softwareName,
          moduleName: formResponse.moduleName,
          createdAt: formResponse.createdAt
        };

        let contentItemsFormAnswerTemp = [];
        const itemForm = formResponse.itemForms.map((f, index) => {
          if (TAGS.TITLE.tag === f.itemType.tag) {
            const titleType = {
              id: f.id,
              itemId: TAGS.TITLE.id,
              itemTag: TAGS.TITLE.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: f.itemContent,
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: true,
              showRequired: true,
              unshowActions: false,
            };

            return titleType;
          }

          if (TAGS.SHORT_ANSWER.tag === f.itemType.tag
            || TAGS.PARAGRAPH.tag === f.itemType.tag
            || TAGS.MULTIPLE_CHOICE.tag === f.itemType.tag
            || TAGS.SELECTION_BOX.tag === f.itemType.tag
          ) {
            let titleGroup = '';

            titleGroup += formResponse.itemForms[index].statement;
            titleGroup = titleGroup
              .toLowerCase()
              .trim()
              .replace(/\s/g, '_');
            titleGroup = `${index}_${titleGroup}`;
            titleGroup = titleGroup
              .normalize('NFD')
              .replace(/([\u0300-\u036f]|[^_0-9a-zA-Z])/g, '');

            contentItemsFormAnswerTemp.push({
              itemFormId: f.id,
              answerContent: {
                attrName: titleGroup.trim(),
              },
            });

            let itemContent = null;

            if (f.itemContent) {
              itemContent = JSON.parse(f.itemContent);
            }

            const answerType = {
              id: f.id,
              itemId: TAGS.ANSWER.id,
              itemTag: TAGS.ANSWER.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: itemContent ? itemContent : f.itemContent,
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: false,
              showRequired: true,
              unshowActions: false,
            };

            return answerType;
          }

          if (TAGS.IMAGE.tag === f.itemType.tag) {
            const itemContent = JSON.parse(f.itemContent);
            const imageObject = JSON.parse(itemContent.object);

            const imageType = {
              id: f.id,
              itemId: f.itemType.id,
              itemTag: f.itemType.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: {
                  align: itemContent.align,
                  src: itemContent.src,
                  object: imageObject
                },
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: false,
              showRequired: false,
              unshowActions: false,
            };

            return imageType;
          }

          if (TAGS.CLIENT_RESALE.tag === f.itemType.tag) {
            const itemContent = JSON.parse(f.itemContent);

            const clientResaleType = {
              id: f.id,
              itemId: f.itemType.id,
              itemTag: f.itemType.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: {
                  clientId: itemContent.clientId,
                  resaleId: itemContent.resaleId,
                  showData: itemContent.showData,
                  showDefaultData: itemContent.showDefaultData,
                },
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: false,
              showRequired: false,
              unshowActions: true,
            };

            return clientResaleType;
          }
        });

        let contentItemsForm = itemForm.filter(item => {
          if (item) return item;
        });

        dataFormViewer = {
          ...dataFormViewer,
          contentItemsForm,
        };

        setContentItemsFormAnswer(contentItemsFormAnswerTemp);
        setFormViewer(dataFormViewer);
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();

    return () => {
      cleaningAsyncEffect = true;
    };
  }, [location.state]);

  function handleViewerClientResale() {
    return (
      <S.ViewerClientResale>
        <S.ViewerSoftwareModule>
          <span><b>Software:</b> {formViewer.softwareName}</span>
          <span><b>Módulo:</b> {formViewer.moduleName}</span>
          <span><b>Solicitado em:</b> {answeredFilled.requestedIn}</span>
        </S.ViewerSoftwareModule>
        <FormClientResale
          clientId={location.state.idClient}
          resaleId={location.state.userCreateOrderId}
          showData={true}
          showDefaultData={false}
          withBorder={true}
        />
      </S.ViewerClientResale>
    );
  }

  function handleViewerImage(item, index) {
    const { src, align } = item.itemData.itemContent;

    return (
      <S.ViewerImage justifyContent={align}>
        <img src={src} alt={`Posição ${index}`} />
      </S.ViewerImage>
    );
  }

  function handleViewerTitle(item) {
    const { statement, description, required } = item.itemData;

    let formatDescription = [];

    if (description) {
      formatDescription = description.split('\n');
    }

    return (
      <S.ViewerTitle>
        {required ? (
          <S.ViewerTitle1>
            {statement && (
              <h1>{statement.toUpperCase()}</h1>
            )}
          </S.ViewerTitle1>
        ) : (
          <S.ViewerTitle2>
            {statement && (
              <b><u>{statement.toUpperCase()}</u></b>
            )}
          </S.ViewerTitle2>
        )}
        {formatDescription.map((description, index) => (
          <S.ViewerDescription key={index}>
            {description}
          </S.ViewerDescription>
        ))}
      </S.ViewerTitle>
    );
  }

  function handleShowOptionOther(other) {
    if (watch(other)) {
      if (typeof watch(other) === 'object') {
        return !watch(other).includes('Outro');
      }

      if (typeof watch(other) === 'string') {
        return watch(other) !== 'Outro';
      }
    }

    return true;
  }

  function handleViewerAnswer(item) {
    const {
      statement,
      description,
      required,
      itemTypeId,
      itemContent,
    } = item.itemData;

    let titleGroup = contentItemsFormAnswer.map(input => {
      if (input.itemFormId === item.id) return input.answerContent.attrName;
    });

    [titleGroup] = titleGroup.filter(attrName => { if (attrName) return attrName; });

    const optionOther = [];
    const optionWithoutOther = [];

    if (itemContent) {
      itemContent.map((option) => {
        if (!option.other) {
          optionWithoutOther.push(option);
        } else {
          optionOther.push(option);
        }
      });
    }

    const radio = { 3: 'radio' };
    const checkbox = { 4: 'checkbox' };
    const HELPERS_TYPE = { ...radio, ...checkbox };

    const getOptionValue = (option) => {
      return typeof option.itemData.value === 'string'
        ? option.itemData.value.trim()
        : option.itemData.value;
    };

    return (
      <S.ViewerAnswerContainer>
        <label htmlFor={titleGroup}>
          <S.ViewerAnswer>
            {statement}{'  '}{required && '*'}
          </S.ViewerAnswer>
        </label>
        {description && (
          <S.ViewerDescriptionContainer>
            <S.ViewerDescription>
              {description}
            </S.ViewerDescription>
          </S.ViewerDescriptionContainer>
        )}
        {itemTypeId === TAGS.SHORT_ANSWER.id && (
          <input
            ref={register()}
            name={titleGroup}
            disabled={answeredFilled.canDisabledAllInputs}
            {...register(titleGroup, { required })}
          />
        )}
        {itemTypeId === TAGS.PARAGRAPH.id && (
          <textarea
            ref={register()}
            name={titleGroup}
            disabled={answeredFilled.canDisabledAllInputs}
            {...register(titleGroup, { required })}
          />
        )}
        {(itemTypeId === TAGS.MULTIPLE_CHOICE.id
          || itemTypeId === TAGS.SELECTION_BOX.id)
          && (
            <S.ViewerOptions>
              {optionWithoutOther && (
                <S.ViewerOptionsContainer>
                  {optionWithoutOther.map((option) => (
                    <S.ViewerOption key={option.id}>
                      {!option.other && (
                        <S.ViewerOptionContainer>
                          <input
                            ref={register()}
                            name={titleGroup}
                            className={'viewer-option'}
                            type={HELPERS_TYPE[itemTypeId]}
                            value={getOptionValue(option)}
                            disabled={answeredFilled.canDisabledAllInputs}
                            {...register(titleGroup, { required })}
                          />
                          <label htmlFor={titleGroup}>
                            {option.itemData.value}
                          </label>
                        </S.ViewerOptionContainer>
                      )}
                    </S.ViewerOption>
                  ))}
                </S.ViewerOptionsContainer>
              )}
              {optionOther[0] && (
                <S.ViewerOther>
                  <S.ViewerOptionOther>
                    <input
                      ref={register()}
                      name={titleGroup}
                      className={'viewer-option'}
                      disabled={answeredFilled.canDisabledAllInputs}
                      value={optionOther[0].itemData.label}
                      type={HELPERS_TYPE[itemTypeId]}
                      {...register(titleGroup, { required })}
                    />
                    <label htmlFor={optionOther[0].itemData.label}>
                      {'Outro:'}
                    </label>
                  </S.ViewerOptionOther>
                  <input
                    ref={register()}
                    disabled={answeredFilled.canDisabledAllInputs}
                    name={`${titleGroup}_option_other`}
                    {...register(
                      `${titleGroup}_option_other`,
                      { required: !handleShowOptionOther(titleGroup) },
                    )}
                  />
                </S.ViewerOther>
              )}
            </S.ViewerOptions>
          )}
        {(errors[titleGroup] || (
          !handleShowOptionOther(titleGroup) && errors[`${titleGroup}_option_other`]
        ))
          && (
            <S.ErrorField>{'Campo obrigatório'}</S.ErrorField>
          )}
      </S.ViewerAnswerContainer>
    );
  }

  const handleBackOrderManagement = () => {
    return history.push(
      commercialPaths.managementCommercialOrder,
      { id: location.state.idOrder }
    );
  };

  function handleBreadcrumbs() {
    const __back_ref = location.state.pathReferenceToBack;
    if (__back_ref === commercialPaths.managementCommercialOrder) {
      return [{
        label: 'Pedido',
        link: commercialPaths.commercialOrder,
      }, {
        label: 'Monitorar Pedido',
        onClick: () => handleBackOrderManagement,
      }];
    }

    return [];
  }

  function handleMainMenu() {
    const __back_ref = location.state.pathReferenceToBack;
    if (__back_ref === commercialPaths.managementCommercialOrder) {
      return 'Comercial';
    }

    return 'Formulário';
  }

  function handlePageName() {
    const __back_ref = location.state.pathReferenceToBack;
    if (__back_ref === commercialPaths.managementCommercialOrder) {
      return 'Formulário';
    }

    return 'Manutenção';
  }

  return (
    <S.ViewerAnswerForm>
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu={handleMainMenu()}
        pageName={handlePageName()}
        breadcrumbs={handleBreadcrumbs()}
        customButtonsBeforeReport={[
          (<button key={'to_viewer_answer_back_reference'}
            type="button"
            className="buttonBase baseWebMobile addMargin"
            onClick={() => {
              setFormViewer(null);
              return history.push(
                location.state.pathReferenceToBack,
                { id: location.state.idOrder }
              );
            }}
          >
            VOLTAR
          </button>),
          (<ReactToPrint
            key={'to_viewer_answer_print'}
            trigger={() => (
              <div className="buttonBase baseWebMobile addMargin baseMain">
                IMPRIMIR
              </div>
            )}
            content={() => componentRef.current}
          />),
        ]}
        openButtonSelect
      />
      <PrintableForm
        ref={componentRef}
        location={location}
        formViewer={formViewer}
        answeredFilled={answeredFilled}
        contentItemsFormAnswer={contentItemsFormAnswer}
      />
      <S.Container>
        <S.ViewerContainer>
          <span className='title-required'>
            {'Campos com (*) são obrigatórios'}
          </span>
          {formViewer && (
            <form>
              {formViewer.contentItemsForm.map((item, index) => (
                <S.ViewerContainerItem key={item.id}>
                  {item.itemTag === TAGS.CLIENT_RESALE.tag && (
                    handleViewerClientResale()
                  )}
                  {item.itemTag === TAGS.IMAGE.tag && (
                    handleViewerImage(item, index)
                  )}
                  {item.itemTag === TAGS.TITLE.tag && (
                    handleViewerTitle(item)
                  )}
                  {item.itemTag === TAGS.ANSWER.tag && (
                    handleViewerAnswer(item, index)
                  )}
                </S.ViewerContainerItem>
              ))}
            </form>
          )}
        </S.ViewerContainer>
      </S.Container>
    </S.ViewerAnswerForm>
  );
}

ViewerAnswerFilled.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      extraInfo: PropTypes.string.isRequired,
      actionLogs: PropTypes.array.isRequired,
      responsibleName: PropTypes.string,
      finishedAt: PropTypes.string,
      formStatus: PropTypes.object.isRequired,
      idForm: PropTypes.number.isRequired,
      idClient: PropTypes.number.isRequired,
      userCreateOrderId: PropTypes.number.isRequired,
      idOrder: PropTypes.number.isRequired,
      forms: PropTypes.array.isRequired,
      pathReferenceToBack: PropTypes.string.isRequired,
      canDisabledAllInputs: PropTypes.bool,
    }),
  }),
};

export default ViewerAnswerFilled;
