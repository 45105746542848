import api, { endpoints } from '~/services/api';

class InvitationService {
  static validateTokenInvitation = async (token) => {
    const { data: response } = await api.post(
      endpoints.invitation.validateTokenInvitation,
      { token }
    );

    return response.data;
  }

  static createPersonByInvitation = async (formData) => {
    const { data: response } = await api.post(
      endpoints.invitation.createPersonByInvitation,
      formData,
    );

    return response;
  }

  static createPersonByRegister = async (formData) => {
    const { data: response } = await api.post(
      endpoints.invitation.createPersonByRegister,
      formData,
    );

    return response;
  }
}

export { InvitationService };
