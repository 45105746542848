import yup from '~/config/yup';
import { PERSON_STATUS_ENUM } from '~/util/domainutils';

const schema = yup.object().shape({
  tipoPessoa: yup.string(),
  cpfCnpj: yup.string().when('tipoPessoa', {
    is: 'FÍSICA',
    then: yup
      .string()
      .isValidCpf()
      .required(),
    otherwise: yup
      .string()
      .isValidCnpj()
      .required(),
  }),
  razaoSocial: yup.string().required(),
  nomeFantasia: yup.string().required(),
  responsavel: yup.string().required().nullable(),
  inscricaoEstadual: yup.string().required(),
  optanteSimples: yup.boolean(),
  ramoAtividadeId: yup.string().required(),
  estado: yup.string().required(),
  cep: yup.string().required(),
  cidade: yup.string().required(),
  bairro: yup.string().required(),
  endereco: yup.string().required(),
  complemento: yup.string(),
  pontoReferencia: yup.string(),
  telefone1: yup.string().required(),
  telefone2: yup.string().nullable(true),
  email: yup.string().email('Email inválido').required(),
  cnae: yup.string(),
  dataCadastro: yup.string(),
  microempresa: yup.boolean().nullable(),
  retemICMS: yup.boolean().nullable(),
  segmentos: yup.boolean(),
  status: yup.string().required(),
  prazoRenovacaoChave: yup.string(),
  statusChave: yup.string().nullable(true).required(),
  revendaId: yup.string(),
});

export const defaultValues = {
  tipoPessoa: 'FÍSICA',
  cpfCnpj: '',
  razaoSocial: '',
  nomeFantasia: '',
  responsavel: '',
  inscricaoEstadual: '',
  optanteSimples: false,
  ramoAtividadeId: '',
  estado: '',
  cep: '',
  cidade: '',
  bairro: '',
  endereco: '',
  complemento: '',
  pontoReferencia: '',
  telefone1: '',
  telefone2: '',
  email: '',
  cnae: '',
  dataCadastro: '',
  microempresa: false,
  retemICMS: false,
  segmentos: false,
  status: PERSON_STATUS_ENUM.NOT_BLOCKED.value,
  statusChave: PERSON_STATUS_ENUM.NOT_BLOCKED.value,
  prazoRenovacaoChave: '30',
  revendaId: '',
};

export default schema;
