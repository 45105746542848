import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { signOut } from '~/store/modules/auth/actions';
import { capitalize, capitalizeAll } from '~/util/stringutils';
import { onKeyDown } from '~/util/accessibilityutils';
import {
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdAccountCircle,
} from 'react-icons/md';
import {
  FaSignOutAlt,
  FaRegUserCircle,
  // FaBell,
} from 'react-icons/fa';
import marcaPreferencialImg from
  '~/assets/marca/preferencial/SVG/CM-marca_sobre-verde-RGB.svg';
import marcaReduzidaImg from
  '~/assets/marca/reduzida/SVG/CM-marca_reduzida-sobre-verde-RGB.svg';
import { useSidebar } from '~/contexts/sidebar';
import { initialPaths, authenticationPaths } from '~/routes/paths';
import * as S from './styles';

function SidebarHeader(sidebarHeaderProps) {
  const {
    history,
    sidebarOpen,
    openProfile,
    onSetOpenProfile,
    onSetCloseProfile,
    collapseSidebarProfile,
    onHandleMouseOver,
    onHandleMouseLeave,
  } = sidebarHeaderProps;

  const { onLogoutSidebar, isGlobalModalActive } = useSidebar();

  const dispatch = useDispatch();
  const { instance } = useMsal();

  let {
    nome: username,
    tipo: type,
    imagem: profilePicture,
  } = useSelector(state => state.user.data);

  if (!type) type = ' -- ';

  async function handleToggleProfile(e) {
    e.preventDefault();
    history.push(initialPaths.pageProfile);
  }

  async function handleLogout(e) {
    e.preventDefault();
    try {
      onSetCloseProfile();
      onLogoutSidebar();

      dispatch(signOut());

      if (instance.getActiveAccount()) {
        await instance.logout();
      }

      history.push(authenticationPaths.login);
    } catch (err) {
      toast.error('Erro ao sair da sessão.');
    }
  }

  const onHandleProfileOptionKeyDown = (e) => {
    if (collapseSidebarProfile) {
      onKeyDown({ event: e, onClick: onSetOpenProfile });
    }
  };

  const onHandleProfileOptionOnClick = () => {
    if (collapseSidebarProfile) { onSetOpenProfile(); }
  };

  const onHandleProfileKeyDown = (e) => {
    onKeyDown({ event: e, onClick: () => handleToggleProfile(e) });
  };

  const onHandleLogoutKeyDown = (e) => {
    onKeyDown({
      event: e,
      onClick: () => handleLogout(e),
      onPressTab: onSetCloseProfile,
    });
  };

  const getIsModalActive = () => {
    return !collapseSidebarProfile && isGlobalModalActive;
  };

  return (
    <>
      {sidebarOpen && (
        <S.HeaderContainer
          isModalActive={getIsModalActive()}
          isCollapse={collapseSidebarProfile}
        >
          <S.HeaderImgMarca
            onMouseOver={onHandleMouseOver}
            onMouseLeave={onHandleMouseLeave}
            isCollapse={collapseSidebarProfile}
          >
            {collapseSidebarProfile ? (
              <img
                loading="lazy"
                src={marcaPreferencialImg}
                alt="Marca Preferencial Positiva RGB Casa Maganhães"
                aria-label="Marca Preferencial Positiva RGB Casa Maganhães"
              />
            ) : (
              <img
                loading="lazy"
                src={marcaReduzidaImg}
                alt="Marca Reduzida Positiva RGB Casa Maganhães"
                aria-label="Marca Reduzida Positiva RGB Casa Maganhães"
              />
            )}
          </S.HeaderImgMarca>
          <S.ProfileContainer
            isCollapse={collapseSidebarProfile}
            onMouseOver={onHandleMouseOver}
            onMouseLeave={onHandleMouseLeave}
          >
            <S.ProfileNav
              onKeyDown={onHandleProfileOptionKeyDown}
              onClick={onHandleProfileOptionOnClick}
            >
              <S.ProfileContent>
                <div className="picture">
                  {profilePicture ? (
                    <img alt="perfil" src={profilePicture} />
                  ) : (
                    <FaRegUserCircle size={28} />
                  )}
                </div>
                <S.ProfileContentOptions isCollapse={collapseSidebarProfile}>
                  <div className="nameContainer">
                    <span id="name" title={capitalizeAll(username)}>
                      {capitalizeAll(username)}
                    </span>
                    <span id="type">{capitalize(type)}</span>
                  </div>
                  {!openProfile ? (
                    <MdKeyboardArrowDown size={20} />
                  ) : (
                    <MdKeyboardArrowUp size={20} />
                  )}
                </S.ProfileContentOptions>
              </S.ProfileContent>
            </S.ProfileNav>
          </S.ProfileContainer>
          <S.ProfileOptionsMenuContainer
            onMouseOver={onHandleMouseOver}
            onMouseLeave={onHandleMouseLeave}
            isCollapse={openProfile && collapseSidebarProfile}
          >
            <S.OptionMenu
              tabIndex={0}
              onClick={handleToggleProfile}
              onKeyDown={onHandleProfileKeyDown}
            >
              <div className='option-icon'>
                <MdAccountCircle size={22} />
              </div>
              <span>PERFIL</span>
            </S.OptionMenu>
            <S.OptionMenu
              tabIndex={0}
              onClick={handleLogout}
              onKeyDown={onHandleLogoutKeyDown}
            >
              <div className='option-icon'>
                <FaSignOutAlt size={18} />
              </div>
              <span>SAIR</span>
            </S.OptionMenu>
          </S.ProfileOptionsMenuContainer>
          {/* <S.NotificationContainer tabIndex={0}>
            <div className='option-icon'>
              <FaBell size={28} />
            </div>
            <span>Notificações</span>
          </S.NotificationContainer> */}
        </S.HeaderContainer>
      )}
    </>
  );
}

SidebarHeader.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  sidebarOpen: PropTypes.bool.isRequired,
  openProfile: PropTypes.bool.isRequired,
  onSetOpenProfile: PropTypes.func.isRequired,
  onSetCloseProfile: PropTypes.func.isRequired,
  collapseSidebarProfile: PropTypes.bool.isRequired,
  onHandleMouseOver: PropTypes.func.isRequired,
  onHandleMouseLeave: PropTypes.func.isRequired,
};

export default withRouter(SidebarHeader);
