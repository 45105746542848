import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import ReactLoading from 'react-loading';
import { useSidebar } from '~/contexts/sidebar';
import { Container, Content } from './styles';

const Modal = (props) => {
  const { setGlobalModalActive, collapseSidebarProfile } = useSidebar();
  const { children, show, loading, noPadding, customWidth } = props;

  useEffect(() => {
    if (collapseSidebarProfile) {
      setGlobalModalActive(false);
    }
  }, [collapseSidebarProfile, setGlobalModalActive]);

  setGlobalModalActive(show);

  return (
    <Container show={show}>
      <Content
        noPadding={noPadding}
        customWidth={customWidth}
      >
        {loading ? (
          <ReactLoading
            type="bubbles"
            color="var(--cm-primary)"
            height={60}
            width={60}
          />
        ) : (
          children
        )}
      </Content>
    </Container>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  show: PropTypes.bool,
  loading: PropTypes.bool,
  noPadding: PropTypes.bool,
  customWidth: PropTypes.oneOfType([
    PropTypes.string, PropTypes.number,
  ]),
};

Modal.defaultProps = {
  children: undefined,
  show: false,
  loading: false,
  noPadding: false,
  customWidth: 'auto',
};

export default Modal;
export const CustomModal = Fragment;
