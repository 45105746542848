import React from 'react';
import { SUPORT_FILES } from '~/util/fileutils';
import Attachments from '~/components/Attachments';
import { FieldsClient } from '~/components/FieldsClient';
import { SelectClient } from '~/components/SelectClient';
import { useSolicitacao } from '~/contexts/solicitation';
import * as S from './styles';

function AttachmentsSolicitation() {
  const {
    setLoading,

    menuActive,
    setMenuActive,
    handleValidateCpfCnpj,

    anexos,
    setAnexos,
    handleInputAnexosChange,
    getSelectedSolicitation,
    lineBusinesses,
    handleSaveCreateClientNew,
    handleGetDataCreateNewClient,

    client,

    newClient,
    newInputClienteValue,
    setNewClient,
    setNewClienteId,
    setNewInputClienteValue,

    register,
    control,
    watch,
    errors,
    clearErrors,
    setValue,
  } = useSolicitacao();

  const {
    existeCliente,
    descricao,
  } = getSelectedSolicitation();

  return (
    <form className="defaultForm">
      <div className="infoCard noPaddingTop">
        <span id="title">Anexos</span>
        <div className="rowContainer">
          <div className="imagePicker">
            <div className="buttonHolder">
              <span>ESCOLHER ANEXO(S)</span>
              <input
                name="anexos"
                type="file"
                multiple
                onChange={handleInputAnexosChange}
                accept={SUPORT_FILES.ACCEPT}
              />
            </div>
            <label>
              {!anexos.length
                ? 'Selecione o(s) arquivo(s)'
                : anexos.length === 1
                  ? '1 arquivo'
                  : anexos.length + ' arquivos'}
            </label>
          </div>
        </div>
        {anexos && (
          <div id="attachmentsId" className="rowContainer">
            <Attachments
              files={anexos}
              handleSetAnexos={setAnexos}
            />
          </div>
        )}
      </div>
      {existeCliente && (
        <div className="infoCard">
          <span id="title">{descricao}</span>
          <div className="rowContainer">
            <span id="title">Adicionar novo cliente *</span>
          </div>
          <S.MenuHeaderContainer active={menuActive}>
            <S.MenuContent
              active={menuActive === 0}
              onClick={() => {
                handleSaveCreateClientNew();
                setMenuActive(0);
              }}
            >
              SELECIONAR EXISTENTE
            </S.MenuContent>
            <S.MenuContent
              active={menuActive === 1}
              onClick={async () => {
                await handleGetDataCreateNewClient();
                setMenuActive(1);
              }}
            >
              CRIAR NOVO
            </S.MenuContent>
          </S.MenuHeaderContainer>
          <S.ClientContainer>
            {menuActive === 0 && (
              <SelectClient
                sameClient={client}
                client={newClient}
                setClient={setNewClient}
                setClienteId={setNewClienteId}
                inputClienteValue={newInputClienteValue}
                setInputValue={setNewInputClienteValue}
                messageUserBlock={'bloqueado para criação de solicitações!'}
                handleCheckSameCpfCnpj={handleValidateCpfCnpj}
                noDataClient
                noLabelTitle
                noPaddingInfoCard
              />
            )}
            {menuActive === 1 && (
              <FieldsClient
                setLoading={setLoading}
                useFormObjects={{
                  register,
                  control,
                  watch,
                  errors,
                  clearErrors,
                  setValue,
                }}
                sameClient={client}
                lineBusinesses={lineBusinesses}
                showPermissionStatusClient={false}
                noVerifyClientInBase={true}
                handleCheckSameCpfCnpj={handleValidateCpfCnpj}
              />
            )}
          </S.ClientContainer>
        </div>
      )}
    </form>
  );
}

export { AttachmentsSolicitation };
