import moment from 'moment';
import { CALENDARIO_MESES } from './domainutils';

/**
 * It returns the current date in the format YYYY-MM-DD.
 */
export const newDatePerson = () => moment().format('YYYY-MM-DD');

/**
 * It takes a date in the format DD/MM/YYYY and returns it in the format YYYY-MM-DD
 * @param date - The date you want to format.
 * @returns A date in the format YYYY-MM-DD
 */
export const getDatePerson = (date) => {
  const dateFormated = `${date.replaceAll('/', '-')}`;
  return moment(dateFormated, 'DD-MM-YYYY').format('YYYY-MM-DD');
};

/**
 * It returns the same date as the first parameter if the second parameter is not a
 * valid date, otherwise it returns the second parameter
 * @param dateA - The date you want to compare.
 * @param dateB - The date that will be compared to dateA.
 */
export const getSameDate = (dateA, dateB) => {
  if (!dateB) return moment(dateA).format('YYYY-MM-DD');
  const dateFormated = `${dateB.replaceAll('/', '-')}`;
  const __DataB = moment(dateFormated, 'DD-MM-YYYY');
  return moment(dateA).isSame(__DataB)
    ? moment(__DataB).format('YYYY-MM-DD')
    : moment(dateA).format('YYYY-MM-DD');
};

/**
 * It receives a date and returns a string with the month of the date in Portuguese
 * @param date - The date you want to format.
 * @param [hours] - { active: true, format: 'HH:mm' }
 * @returns A function that returns a string.
 */
export const getDateWithMonthInPortuguese = (date, hours = { active: true, format: 'HH:mm' }) => {
  if (!date) return '';
  let formatDefault = (month) => `DD [de] [${month}] [de] YYYY, ${hours.format}`;
  if (!hours.active) {
    formatDefault = (month) => `DD [de] [${month}] [de] YYYY`;
  }
  const messageDate = moment(date);
  const month = CALENDARIO_MESES[messageDate.month()].label;
  return moment(messageDate).format(formatDefault(month));
};

/**
 * It takes a date and returns an object with the date formatted in different ways
 * @param time - the time you want to format
 * @returns An object with three properties: time, timeFull, and timeSmall.
 */
export const getFormatTimeOpportunity = (time) => {
  const __time = moment(time).hour(0).minute(0).second(0).millisecond(0);
  return {
    time: __time,
    timeFull: __time.format(),
    timeSmall: __time.format('DD/MM/yyyy'),
  };
};
