import React from 'react';
import PropTypes from 'prop-types';
import { SelectClients } from './SelectClients';
import { SelectProducts } from './SelectProducts';
import { FillForms } from './FillForms';
import { ChoosePaymentForm } from './ChoosePaymentForm';
import { Conclude } from './Conclude';

function Steps(props) {
  const {
    index,
    dataObject,
    userCanEdit,
    setLoading,
    tableShoppingCart: {
      calculateTotalAdesao,
      calculateTotalMensalidade,
      softwares,
    },
  } = props;

  const selectClients = dataObject.selectClients;
  const selectProducts = dataObject.selectProducts;
  const fillForms = dataObject.fillForms;
  const choosePaymentForm = dataObject.choosePaymentForm;
  const conclude = dataObject.conclude;

  const steps = [
    <SelectClients key='select-clients' {...selectClients} />,
    <SelectProducts key='select-products'
      activeStep={index}
      setLoading={setLoading}
      userCanEdit={userCanEdit}
      {...props.tableShoppingCart}
      {...selectProducts}
    />,
    <FillForms key='fill-forms' {...fillForms} setLoading={setLoading} />,
    <ChoosePaymentForm key='choose-playment-form'
      activeStep={index}
      setLoading={setLoading}
      userCanEdit={userCanEdit}
      {...props.tableShoppingCart}
      {...choosePaymentForm}
    />,
    <Conclude key='conclude'
      {...conclude}
      activeStep={index}
      softwares={softwares}
      calculateTotalAdesao={calculateTotalAdesao}
      calculateTotalMensalidade={calculateTotalMensalidade}
    />,
  ];

  return (
    <>
      {steps.map((elem, ind) => (
        <div key={ind}>
          {index === ind && (elem)}
        </div>
      ))}
    </>
  );
}

Steps.propTypes = {
  index: PropTypes.number.isRequired,
  dataObject: PropTypes.object.isRequired,
  userCanEdit: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  tableShoppingCart: PropTypes.shape({
    softwares: PropTypes.array.isRequired,
    handleRemoveModule: PropTypes.func.isRequired,
    handleAddModule: PropTypes.func.isRequired,
    calculateTotalAdesao: PropTypes.func.isRequired,
    calculateTotalMensalidade: PropTypes.func.isRequired,
  }).isRequired,
};

export { Steps };
