/* eslint-disable react/prop-types */
import React from 'react';

import { Container } from '../styles';

export default function Resale({ resale, responsavelExecutivo }) {
  return (
    <Container>
      {resale ? (
        <>
          <div>
            <span>Razão Social:</span>
            <span>{resale.razaoSocial}</span>
          </div>
          <div>
            <span>CNPJ:</span>
            <span>{resale.cpfCnpj}</span>
          </div>
          <div>
            <span>Nome Fantasia:</span>
            <span>{resale.nomeFantasia}</span>
          </div>
          <div>
            <span>Responsável Executivo:</span>
            <span>{responsavelExecutivo}</span>
          </div>
        </>
      ) : (
        <Container>
          <span>Revenda não encontrada na base de dados</span>
        </Container>
      )}
    </Container>
  );
}
