import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ReasonSolicitationService } from '~/services/commercial/reasonsolicitation';
import { commercialPaths } from '~/routes/paths';
import Modal from '~/components/Modal';
import { accessDenied } from '~/util/accessDenied';
import TableCell from '@material-ui/core/TableCell';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { StatusType } from '~/components/StatusType';
import SimpleTextSearch from '~/components/SimpleTextSearch';
import TableComponent from '~/components/TableComponent';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import { useFitler } from '~/hook/useFilter';
import './styles.scss';

function ReasonSolicitation({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(filters.inputValue ? filters.inputValue : '');
  const [deleteItem, setDeleteItem] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [searchResponse, setSearchResponse] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions['create_motivo-solicitacao']),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions['edit_motivo-solicitacao']),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions['delete_motivo-solicitacao']),
    [userPermissions]
  );

  useEffect(() => {
    findAllByFilter(pageParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(event) {
    const {
      target: { value },
    } = event;

    setInputValue(value);
  }

  async function findAllByFilter(pageParams) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      if (inputValue.length) { setCountFilter(1); } else { setCountFilter(0); }
      const { page, rowsPerPage } = pageParams;
      const result = await ReasonSolicitationService
        .getReasonSolicitation({
          text: inputValue.trim(), page, rowsPerPage,
        });

      if (result.resultado && result.resultado.length) {
        setTotalItems(result.total);
        setSearchResponse(result.resultado);
      } else {
        setTotalItems(0);
        setSearchResponse([]);
        toast('Não encontramos nenhum Motivo!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setFilters({ inputValue });

    await findAllByFilter(pageParameters);
  }

  async function handleDelete() {
    try {
      setLoading(true);
      const result = await ReasonSolicitationService
        .deleteReasonSolicitation(deleteItem.id);

      if (!result.ok) {
        toast('Erro ao deletar o Motivo!', {
          type: toast.TYPE.ERROR,
        });
      } else {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteItem.id
        );

        setSearchResponse(newSearchResponse);
        toast(result.message, { type: toast.TYPE.SUCCESS });
        setTotalItems(totalItems - 1);
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o motivo!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  const renderRowComponent = row => {
    return [
      <TableCell key="row_reason-solicitacao_0">
        {row.id}
      </TableCell>,
      <TableCell key="row_reason-solicitacao_1">
        {row.descricao}
      </TableCell>,
      <TableCell key="row_reason-solicitacao_2">
        <StatusType ativo={row.ativo} />
      </TableCell>,
    ];
  };

  const handleEditItem = row => {
    return history.push(
      commercialPaths.editReasonSolicitation,
      {
        id: row.id,
        motivo: row.descricao,
        tag: row.tag,
        ativo: row.ativo,
        existeCliente: row.existeCliente,
      }
    );
  };

  return (
    <div className='adminPageContainer'>
      <SidebarPageHeader
        mainMenu="Comercial"
        pageName="Motivo de Solicitação"
        button1={{
          label: 'Novo Motivo',
          link: commercialPaths.addReasonSolicitation,
          permission: userCanCreate,
          main: true,
        }}
        openButtonSelect
      />
      <SimpleTextSearch
        countFilter={countFilter}
        fieldLabel="Motivo"
        inputValue={inputValue}
        handleInputChange={handleChange}
        handleSubmit={handleSubmit}
      />
      <Modal loading={loading} show={loading || !!deleteItem}>
        <div className="modalContainer">
          <h2>Confirmar exclusão</h2>
          <span>
            Tem certeza que deseja deletar o item
            <span id="elementName">
              {` ${!!deleteItem && deleteItem.descricao}`}
            </span>
            ?
          </span>
          <div>
            <button
              className="buttonBaseOnly addMargin"
              onClick={() => setDeleteItem(null)}
            >
              Cancelar
            </button>
            <button
              className="buttonBaseOnly baseMain"
              onClick={handleDelete}
            >
              Deletar
            </button>
          </div>
        </div>
      </Modal>
      <TableComponent
        headerLabels={[
          { text: 'ID', width: '5%' },
          { text: 'Motivo', width: 'auto' },
          { text: 'Status', width: '120px', align: 'center' },
        ]}
        dataObjects={searchResponse}
        editPermission={userCanEdit}
        handleEdit={userCanEdit
          ? (row) => handleEditItem(row)
          : () => accessDenied(history)
        }
        deletePermission={userCanDelete}
        handleDelete={userCanDelete
          ? (row) => setDeleteItem(row)
          : () => accessDenied(history)
        }
        totalItems={totalItems}
        handlePageUpdate={findAllByFilter}
        renderRowComponent={renderRowComponent}
      />
    </div>
  );
}

ReasonSolicitation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ReasonSolicitation;
