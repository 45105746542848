import React, { useEffect } from 'react';
import { FieldsClient } from '~/components/FieldsClient';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import schema, { defaultValues } from '~/schemas/client-schema';
import { getDatePerson } from '~/util/dateutils';
import { toast } from 'react-toastify';
import * as S from './styles';

function FieldsClientModal(clientModalProps) {
  const {
    newClient,
    resaleDefault,
    existentClient,
    lineBusinesses,
    sameClient,
    setLoading,
    showPermissionStatusClient,
    noVerifyClientInBase,
    handleCheckSameCpfCnpj,
    handleCancel,
    onHandleSubmitObject,
    onValidateForm,
  } = clientModalProps;

  const {
    register,
    control,
    watch,
    reset,
    handleSubmit,
    setError,
    errors,
    clearErrors,
    setValue,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });

  useEffect(() => {
    reset({ ...newClient, dataCadastro: getDatePerson(newClient.dataCadastro) });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInvalid = (errors) => {
    if (errors) {
      toast.error(
        'Houve um erro inesperado ao salvar edição de cadastro,'
        + ' contate o adminstrador');
    }
  };

  async function handleSave() {
    await handleSubmit((data) => {
      onValidateForm({
        setError,
        inputExtra: data,
        showPermissionStatusClient,
        clienteExistente: existentClient,
      }, (formDataExtra) => {
        onHandleSubmitObject(formDataExtra);
        handleCancel();
      });
    }, onInvalid)();
  }

  return (
    <div>
      <S.ModalContentHeader>
        Edição de cadastro
      </S.ModalContentHeader>
      <S.ModalContentScroll>
        <FieldsClient
          lineBusinesses={lineBusinesses}
          useFormObjects={{
            register,
            control,
            watch,
            errors,
            clearErrors,
            setValue,
          }}
          sameClient={sameClient}
          setLoading={setLoading}
          resaleDefault={resaleDefault}
          showPermissionStatusClient={showPermissionStatusClient}
          noVerifyClientInBase={noVerifyClientInBase}
          handleCheckSameCpfCnpj={handleCheckSameCpfCnpj}
        />
      </S.ModalContentScroll>
      <S.ModalContentActions>
        <button
          type='button'
          className='buttonBase'
          onClick={handleCancel}
        >
          CANCELAR
        </button>
        <button
          type='submit'
          className='buttonBase handleSave'
          onClick={handleSave}
        >
          SALVAR
        </button>
      </S.ModalContentActions>
    </div>
  );
}

export default FieldsClientModal;
