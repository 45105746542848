import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function ButtonLink(buttonLinkProps) {
  let {
    label,
    linkTo,
    permission,
    onClick,
    main,
    className,
  } = buttonLinkProps;

  label = (label || '').toUpperCase();

  function handleIncludePermission() {
    return [true, false].includes(permission);
  }

  const handleCursor = () => {
    if (handleIncludePermission()) {
      return !permission ? 'not-allowed' : 'pointer';
    }

    return 'pointer';
  };

  const handleTitle = () => {
    if (handleIncludePermission()) {
      return !permission ? 'Acesso Negado!' : label;
    }

    return label;
  };

  const handleLinkTo = () => {
    if (handleIncludePermission()) {
      return !permission ? '#' : linkTo;
    }

    return linkTo;
  };

  const handleClassname = () => {
    if (className) return className;
    return `buttonBase baseWebMobile addMargin ${main && 'baseMain'}`;
  };

  return (
    <Link
      to={handleLinkTo()}
      className={handleClassname()}
      onClick={onClick?.()}
      style={{ cursor: handleCursor() }}
      title={handleTitle()}
    >
      <div className='linkLabel'>
        {label}
      </div>
    </Link>
  );
}

ButtonLink.propTypes = {
  label: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  permission: PropTypes.bool,
  onClick: PropTypes.func,
  main: PropTypes.bool,
  noRadius: PropTypes.bool,
  className: PropTypes.string,
};

export { ButtonLink };
