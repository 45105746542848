import styled from 'styled-components';
import { FaBars } from 'react-icons/fa';
import { MdMoreVert } from 'react-icons/md';

const getPositionIsCollapse = ({ isCollapse, isSidebarOpen }) => {
  if (!isCollapse) { return (isCollapse && !isSidebarOpen) ? 'none' : 0; }
  return isSidebarOpen ? 'none' : 0;
};

export const PageHeaderContaner = styled.div`
  position: fixed;

  top: 0;
  right: 0;
  left: 75px;
  width: calc(100vw - 75px);

  @media screen and (max-width: 799px) {
    left: ${getPositionIsCollapse};
    right: ${getPositionIsCollapse};
    width: 100vw;
  }

  height: 75px;
  background-color: var(--cm-tertiary);
  color: var(--cm-tertiary-contrast);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 10px 20px;
  border-bottom: 2px solid #d4d4e4;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);

  @media screen and (max-width: 450px) {
    padding: 8px 0 10px 10px;
  }

  .sidebarHolder {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .textHolder {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 799px) {
      flex: 1;
    }

    @media screen and (max-width: 450px) {
      width: 70%;
      flex: 1;
    }

    .mainLvl {
      font-size: 12pt;

      @media screen and (max-width: 450px) {
        font-size: 10pt;
      }
    }

    .pageName {
      font-size: 18pt;
      font-weight: bold;
      white-space: nowrap;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;

      @media screen and (max-width: 600px) {
        max-width: 90%;
      }

      @media screen and (max-width: 500px) {
        max-width: 80%;
      }

      @media screen and (max-width: 450px) {
        font-size: 14pt;
      }
    }
  }

  .buttonWrapper {
    display: flex;

    @media screen and (max-width: 799px) {
      display: none;
    }
  }
`;

export const ButtonSelectContaner = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column-reverse;
  background-color: white;
  max-width: 185px;
  overflow: hidden;
  border-radius: 8px;

  .linkLabel {
    text-align: center;
    white-space: nowrap;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  margin-right: 10px;

  &:hover {
    background-color: var(--cm-tertiary-shade);
  }

  &.left {
    position: absolute;
    top: 12px;
    right: 0;
  }

  &.left:active {
    background-color: var(--cm-tertiary-shade);
  }
`;

export const BarsIcon = styled(FaBars).attrs({
  color: 'var(--cm-tertiary-contrast)',
  size: 28,
})``;

export const MoreVertIcon = styled(MdMoreVert).attrs({
  color: 'var(--cm-tertiary-contrast)',
  size: 28,
})``;

export const BreadcrumbContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  background-color: var(--cm-tertiary);
  color: var(--cm-tertiary-contrast);

  svg {
    margin: 0 5px;

    @media screen and (max-width: 450px) {
      font-size: 14px;
    }
  }

  a {
    color: var(--cm-tertiary-contrast);
    @media screen and (max-width: 450px) {
      margin-bottom: 1px;
    }
  }

  .mainLvlBreadcum {
    font-size: 12pt;
  }

  @media screen and (max-width: 450px) {
    .mainLvlBreadcum {
      font-size: 10pt;
    }
  }

  .breadcrum {
    display: flex;
    align-items: center;

    button {
      box-shadow: none;
    }
  }

  .more-horz { display: none; }
  @media screen and (max-width: 600px) {
    .more-horz { display: block; margin: 0; }
    .mainLvlHidden, .breadcumHidden {
      display: none;
    }
  }

  .buttonBreadcum {
    border: none;
    background-color: var(--cm-tertiary);
    color: var(--cm-tertiary-contrast);
    text-decoration: underline;

    &.noUnderline {
      text-decoration: none;
    }
  }
`;
