import React from 'react';
import { useSolicitacao } from '~/contexts/solicitation';
import Attachments from '~/components/Attachments';
import { FieldConfirmTerm } from '~/components/FieldConfirmTerm';
import { formatCpfCnpj } from '~/util/stringutils';
import { UNIDADES_FEDERATIVAS } from '~/util/domainutils';
import { ShowDataClient } from '~/components/ShowDataClient';
import * as S from './styles';

function Resume() {
  const {
    client,
    inputAssunto,
    textareaTexto,
    getSelectedSolicitation,
    getDataNewClient,
    anexos,
    confirm,
    handleInputConfirmChange,
  } = useSolicitacao();

  const reasonSelected = getSelectedSolicitation();

  let dataNewClient = null;
  if (reasonSelected?.existeCliente) {
    dataNewClient = getDataNewClient();
  }

  const getClientName = (cliObj) => {
    return cliObj?.nomeFantasia || cliObj?.razaoSocial || '';
  };

  const getClientAddress = (cliObj) => {
    if (!cliObj) return '';

    const {
      endereco,
      bairro,
      cidade,
      estado,
    } = cliObj;

    let nomeEstado = estado;
    if (estado.length === 2) {
      nomeEstado = UNIDADES_FEDERATIVAS.find(_ => _.sigla === estado).nome;
    }

    return `
      ${(endereco || '--')},
      ${(bairro || '--')},
      ${(cidade || '--')} -
      ${(nomeEstado || '--')}`;
  };

  return (
    <S.DataInfo>
      <S.DataInfoContainer>
        <S.DataInfoContent>
          <div className="infoHolder">
            <div className="header">
              <span>Cliente atual:</span>
            </div>
            <div>
              {client ? (
                <div className="info">
                  <div>
                    <strong>Nome: </strong> {getClientName(client)}
                  </div>
                  <div>
                    <strong>CPF/CNPJ: </strong> {formatCpfCnpj(client.cpfCnpj)}
                  </div>
                  <div>
                    <strong>Endereço: </strong> {getClientAddress(client.endereco)}
                  </div>
                </div>
              ) : (
                <div className="info">
                  <div><strong>Nome:</strong></div>
                  <div><strong>CPF/CNPJ:</strong></div>
                  <div><strong>Endereço:</strong></div>
                </div>
              )}
            </div>
          </div>
        </S.DataInfoContent>
        <S.DataInfoContent>
          <div className="infoHolder">
            <div className="header">
              <span>Solicitação:</span>
            </div>
            <div>
              {reasonSelected && inputAssunto && textareaTexto ? (
                <div className="info">
                  <div>
                    <strong>Tipo: </strong> {(reasonSelected?.descricao || '')}
                  </div>
                  <div>
                    <strong>Assunto: </strong> {(inputAssunto || '')}
                  </div>
                  <div className='description'>
                    <strong>Descrição: </strong> {(textareaTexto || '')}
                  </div>
                </div>
              ) : (
                <div className="info">
                  <div><strong>Tipo:</strong></div>
                  <div><strong>Assunto:</strong></div>
                  <div><strong>Descrição:</strong></div>
                </div>
              )}
            </div>
          </div>
        </S.DataInfoContent>
      </S.DataInfoContainer>
      {reasonSelected?.existeCliente && dataNewClient && (
        <S.DataInfoContent>
          <div className="infoHolder">
            <div className="header">
              <span>Cliente novo:</span>
            </div>
            <ShowDataClient dataClient={dataNewClient} />
          </div>
        </S.DataInfoContent>
      )}
      <S.DataInfoSimple>
        <S.DataInfoContent>
          <div className="infoHolder">
            <div className="header noMarginBottom">
              <span>Anexos:</span>
            </div>
            <div className="rowInfo">
              <Attachments
                files={(anexos || [])}
                canDelete={false}
                customColor={'white'}
              />
            </div>
          </div>
        </S.DataInfoContent>
      </S.DataInfoSimple>
      <S.DataInfoSimple>
        <FieldConfirmTerm
          value={confirm}
          checked={confirm === true}
          onChange={handleInputConfirmChange}
          description={'Confirmo que revisei a solicitação'
            + ' e todos os dados estão corretos.'}
        />
      </S.DataInfoSimple>
    </S.DataInfo>
  );
}

export { Resume };
