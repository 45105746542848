import snakeCase from 'lodash/snakeCase';

export function identifyCpfCnpj(cpfCnpj) {
  let value = unFormatCpfCnpj(cpfCnpj);
  if (!value) return '';
  if (value.length === 11) {
    return 'CPF';
  } else if (value.length === 14) {
    return 'CNPJ';
  }
}

export function unFormatCpfCnpj(cpfCnpj) {
  if (!cpfCnpj) return '';
  return cpfCnpj.replace(/[^\d]+/g, '');
}

export function unFormatPunctuation(number) {
  if (!number) return '';
  return number.replace(/[^\d]+/g, '');
}

export function onlyNumbers(value) {
  if (!value) return '';
  return value.replace(/[\D.]/g, '');
}

export function formatFaturamento(value) {
  if (!value) return '';
  return value.replace(/[^,0-9]/g, '');
}

export function onlyText(value) {
  if (!value) return '';
  return value.replace(/[0-9.,/-]/g, '');
}

export function inputVersion(value) {
  if (!value) return '';
  // eslint-disable-next-line no-useless-escape
  return value.replace(/[^\.0-9]/g, '');
}

export function inputNumber(value) {
  if (!value) return '';
  return value.replace(/\D/g, '');
}

export function inputNumberAndPunctuation(value) {
  if (!value) return '';
  return value.replace(/[^_.,-/\0-9]/g, '');
}

export function inputToLowerCase(value) {
  if (!value) return '';
  return value.toLowerCase();
}

export function formatCpfCnpj(valor) {
  if (!valor) return '';
  if (valor.length === 11) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  } else if (valor.length === 14) {
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    );
  }
  return valor;
}

export function formatCpfCnpjMask(valor) {
  if (!valor) return '';
  if (valor.length === 11) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '###.###.$3-##');
  } else if (valor.length === 14) {
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '##.###.$3/####-$5'
    );
  }
  return valor;
}

export function formatCpfCnpjByCaracter(valor) {
  let s = unFormatCpfCnpj(valor);
  const breakPointsCpfCnpj = {
    4: s.replace(/(\d{3})(\d{1})/g, '$1.$2'),
    5: s.replace(/(\d{3})(\d{2})/g, '$1.$2'),
    6: s.replace(/(\d{3})(\d{3})/g, '$1.$2'),
    7: s.replace(/(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3'),
    8: s.replace(/(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3'),
    9: s.replace(/(\d{3})(\d{3})(\d{3})/g, '$1.$2.$3'),
    10: s.replace(/(\d{3})(\d{3})(\d{3})(\d{1})/g, '$1.$2.$3-$4'),
    11: s.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4'),
    12: s.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/g, '$1.$2.$3/$4'),
    13: s.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{1})/g, '$1.$2.$3/$4-$5'),
    14: s.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5'),
  };
  return breakPointsCpfCnpj[s.length] || s;
}

export function formatCpf(valor) {
  if (!valor) return '';
  if (valor.length === 11) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
  }
  return valor;
}

export function validaCpf(cpf) {
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  )
    return false;
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  // eslint-disable-next-line
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export function validaCnpj(cnpj) {
  if (
    !cnpj ||
    cnpj.length !== 14 ||
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return false;
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  // eslint-disable-next-line
  if (resultado != digitos.charAt(0)) return false;
  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  // eslint-disable-next-line
  if (resultado != digitos.charAt(1)) return false;
  return true;
}

export function validateEmail(email) {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validePhone(phone) {
  if (!phone) return true;
  return phone.match(
    /(\(\d{2}\)\s\d{5}-\d{4})|\(\d{2}\)\s(\d{5}-\d{3}_)|\(\d{2}\)(\d{5}-\d{4})|\(\d{2}\)(\d{5}-\d{3}_)|(\(\d{2}\)\s\d{4}-\d{4})|(\d{11})|(\d{10})|(\d{9})/g
  );
}

export function formatPhone(value) {
  if (!value) return '';
  const phone = value.replace(/[^\d]+/g, '');
  if (phone.length === 10) return phone.replace(
    /(\d{2})(\d{4})(\d{4})/g,
    '($1) $2-$3'
  );
  if (phone.length === 11) return phone.replace(
    /(\d{2})(\d{5})(\d{4})/g,
    '($1) $2-$3'
  );
  return value;
}

export function capitalize(string) {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function capitalizeAll(string) {
  if (!string) return '';
  return snakeCase(string)
    .split('_')
    .map(s => s.length > 2 ? capitalize(s) : s)
    .join(' ');
}

export function normalizeText(string) {
  if (!string) return '';

  let str = string
    .toLowerCase()
    .normalize('NFD')
    .replace(/([\u0300-\u036f]|[^_0-9a-zA-Z])/g, '')
    .replace(/\s/g, '_')
    .trim();

  return str;
}
