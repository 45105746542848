import api, { endpoints } from '~/services/api';

class PaymentsService {
  static getPayments = async () => {
    const { data: response } = await api.post(
      endpoints.commercial.payment.getPayments, null,
    );

    return response.data;
  }

  static getPayment = async (id) => {
    const { data: response } = await api.get(
      endpoints.commercial.payment.getPayment(id),
    );

    return response.data;
  }

  static deletePayment = async (id) => {
    const { data: response } = await api.delete(
      endpoints.commercial.payment.deletePayment(id)
    );

    return response;
  }

  static createPayments = async (formData) => {
    const { data: response } = await api.post(
      endpoints.commercial.payment.createPayments,
      { ...formData }
    );

    return response;
  }

  static updatePayments = async (formData) => {
    const { data: response } = await api.put(
      endpoints.commercial.payment.updatePayments,
      { ...formData }
    );

    return response.data;
  }

  static getGracePeriodPayment = async () => {
    const { data: response } = await api.get(
      endpoints.commercial.payment.getGracePeriodPayment,
    );

    return response.data;
  }

  static updateGracePeriodPayment = async (ativar, carencia) => {
    const { data: response } = await api.put(
      endpoints.commercial.payment.updateGracePeriodPayment,
      { ativar, carencia }
    );

    return response;
  }
}

export { PaymentsService };
