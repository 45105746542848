import PropTypes from 'prop-types';
import React from 'react';
import { includes } from 'lodash';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import permissions from '~/util/permissions';
import InputMask from 'react-input-mask';
import PersonResaleNew from '~/model/person-resale-new';
import { ChannelRatingService } from '~/services/person/channelrating';
import { AdminGroupAccessService } from '~/services/admin-group-access-service';
import PersonEmployeeService from '~/services/person-employee-service';
import { InvitationService } from '~/services/person/invitation';
import { showMessageError } from '~/util/errorutils';
import { AutoCompleteOn } from '~/components/AutoCompleteOn';
import PageHeaderWithButtons from '~/components/PageHeaderWithButtons';
import { PERSON_STATUS_ENUM } from '~/util/domainutils';
import {
  validaCnpj,
  validateEmail,
  inputNumber,
  unFormatCpfCnpj,
  formatCpfCnpj,
  onlyText,
  onlyNumbers,
} from '~/util/stringutils';
import * as S from './styles';

class AddInvitationResale extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    user: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    const { history } = props;

    this.history = history;
    this.accessGroups = [];
    this.channelRatings = [];
    this.user = props.user;

    this.userCanEditResponsibleExecutive = props.permissions
      .includes(permissions['edit_responsavel-executivo']);

    this.state = {
      loading: true,
      isRequested: true,
      channelRatingSelected: { descontos: [] },
      tiposDescontoRows: [],
      totalDescontos: 0.0,
      inputEmployeeValue: '',
      employee: {},
      employees: [],
      timeout: null,
      showSuggestions: false,

      inputCpfCnpj: '',
      inputNome: '',
      inputEmail: '',
      selectGrupo: 0,

      inputRazao: '',
      inputExecutivo: null,
      statusChave: 'Não Bloqueado',
      prazoRenovacaoChave: '',
      comissionada: false,
      classificacaoId: null,
      descontosRevenda: [],

      // errors
      error_grupoId: false,
      error_cpfCnpj: false,
      error_inputNome: false,
      error_email: false,
      error_revendaId: false,

      // errors message
      error_cpfCnpj_message: 'Campo obrigatório',
      error_email_message: 'Campo obrigatório',
      error_revendaId_message: 'Campo obrigatório',
    };
  }

  /* LifeCicle:: Inicializa quando monta a DOM */
  componentDidMount() {
    Promise.all([
      AdminGroupAccessService.getAccessGroup(''),
      ChannelRatingService.getChannelRating(''),
    ])
      .then(values => {
        if (values.length) {
          if (values[0].resultado.length) {
            this.accessGroups.push(...values[0].resultado);
          }

          if (values[1].resultado.length) {
            this.channelRatings.push(...values[1].resultado);
          }
        }
      })
      .finally(() => this.setLoading(false));
  }

  setInputValue = b => this.setState({ inputEmployeeValue: b });

  setLoading = b => this.setState({ loading: b });

  setInputCpfCnpj = b => this.setState({ inputCpfCnpj: b });

  setInputNome = b => this.setState({ inputNome: b });

  setInputRazao = b => this.setState({ inputRazao: b });

  setSelectGrupo = b => this.setState({ selectGrupo: b });

  setInputEmail = b => this.setState({ inputEmail: b });

  setResponsavelExecutivoId = b => this.setState({ inputExecutivo: b });

  setStatusChave = b => this.setState({ statusChave: b });

  setPrazoRenovacaoChave = b => this.setState({ prazoRenovacaoChave: b });

  handleSelectGrupoChange = event => {
    const {
      target: { value },
    } = event;
    this.setSelectGrupo(value);
    this.handleErrorsGrupoId(false);
  };

  handleInputCpfCnpjChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCpfCnpj(inputNumber(value));
    this.handleErrorsCpfCnpj(false, 'Campo obrigatório');
  };

  handleInputNomeChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputNome(value);
    this.handleErrorsInputNome(false);
  };

  handleInputRazaoSocialChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputRazao(value);
  };

  handleInputEmailChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputEmail(value);
    this.handleErrorsEmail(false, 'Campo obrigatório');
  };

  handleInputStatusChaveChange = event => {
    const {
      target: { value },
    } = event;

    this.setState({ statusChave: value });
  };

  handleInputPrazoChange = event => {
    const {
      target: { value },
    } = event;

    this.setState({ prazoRenovacaoChave: value });
  }

  handleCheckboxComissionadaChange = event => {
    const {
      target: { value },
    } = event;

    this.setState({ comissionada: value });
  };

  handleRadioClassificacaoChange = event => {
    const {
      target: { value, checked },
    } = event;

    if (checked) {
      this.setAddRadioClassificacao(value);
    } else {
      this.setRemoveRadioClassificacao();
    }
  };

  handleCheckboxDescontosRevendaChange = event => {
    const {
      target: { value, checked },
    } = event;
    if (checked) {
      this.addCheckboxDescontosRevenda(value);
    } else {
      this.removeCheckboxDescontosRevenda(value);
    }
  };

  setAddRadioClassificacao = b => {
    let classificacaoId = parseInt(b);
    let descontosRevenda = [];

    const selected = this.channelRatings.find(
      value => value.id === classificacaoId
    );

    const mod4Rows = Math.ceil(selected.descontos.length / 4);
    let countDescontos = 0;

    const rows = [...Array(mod4Rows).keys()].map(key => {
      let countToEnd = countDescontos < 4 ? 4 : selected.descontos.length;

      if (countToEnd < 4) {
        countToEnd = countDescontos + 4;
      }

      const row = selected.descontos.slice(countDescontos, countToEnd);
      countDescontos += 4;

      return {
        key,
        classificacaoId,
        descontos: row,
      };
    });

    this.setState({
      classificacaoId,
      channelRatingSelected: selected,
      tiposDescontoRows: rows,
      totalDescontos: 0.0,
      descontosRevenda,
    });
  };

  setRemoveRadioClassificacao = () => {
    this.setState({
      classificacaoId: null,
      descontosRevenda: [],
      channelRatingSelected: { descontos: [] },
      tiposDescontoRows: [],
      totalDescontos: 0.0,
    });
  };

  addCheckboxDescontosRevenda = b => {
    const { classificacaoId, descontosRevenda } = this.state;
    const selectedChannelRating = this.channelRatings.find(
      value => value.id === classificacaoId
    );
    descontosRevenda.push(parseInt(b));
    let total = 0.0;
    descontosRevenda.map(id => {
      const desconto = selectedChannelRating.descontos.find(
        value => value.id === id
      );
      total += desconto.valor;
      return id;
    });
    this.setState({
      descontosRevenda,
      totalDescontos: total,
    });
  };

  removeCheckboxDescontosRevenda = b => {
    const { classificacaoId, descontosRevenda } = this.state;
    const selectedChannelRating = this.channelRatings.find(
      value => value.id === classificacaoId
    );
    descontosRevenda.splice(
      descontosRevenda.indexOf(parseInt(b)),
      1
    );
    let total = 0.0;
    descontosRevenda.map(id => {
      const desconto = selectedChannelRating.descontos.find(
        value => value.id === id
      );
      total += desconto.valor;
      return id;
    });
    this.setState({
      descontosRevenda,
      totalDescontos: total,
    });
  };

  handleErrorsGrupoId = error_grupoId => {
    this.setState({ error_grupoId });
  };

  handleErrorsCpfCnpj = (error_cpfCnpj, error_cpfCnpj_message) => {
    this.setState({ error_cpfCnpj, error_cpfCnpj_message });
  };

  handleErrorsInputNome = error_inputNome => {
    this.setState({ error_inputNome });
  };

  handleErrorsEmail = (error_email, error_email_message) => {
    this.setState({ error_email, error_email_message });
  };

  validateForm(success) {
    let errors_temp = false;
    const formData = {};

    if (!this.state.inputCpfCnpj || !this.state.inputCpfCnpj.length) {
      this.handleErrorsCpfCnpj(true, 'Campo obrigatório');
      errors_temp = true;
    } else {
      formData.cpfCnpj = unFormatCpfCnpj(this.state.inputCpfCnpj);
      if (formData.cpfCnpj.length === 14 && !validaCnpj(formData.cpfCnpj)) {
        this.handleErrorsCpfCnpj(true, 'Digite um CNPJ válido');
        errors_temp = true;
      } else if (formData.cpfCnpj.length < 14) {
        this.handleErrorsCpfCnpj(true, 'Digite um CNPJ válido');
        errors_temp = true;
      }
    }

    if (!this.state.inputNome || !this.state.inputNome.length) {
      this.handleErrorsInputNome(true);
      errors_temp = true;
    } else {
      formData.nome = this.state.inputNome.trim();
    }

    if (!this.state.selectGrupo) {
      this.handleErrorsGrupoId(true);
      errors_temp = true;
    } else {
      formData.grupoId = this.state.selectGrupo;
    }

    if (!this.state.inputEmail || !this.state.inputEmail.length) {
      this.handleErrorsEmail(true, 'Campo obrigatório');
      errors_temp = true;
    } else {
      formData.email = this.state.inputEmail.trim();
      if (!validateEmail(formData.email)) {
        this.handleErrorsEmail(true, 'Digite um email válido');
        errors_temp = true;
      }
    }

    if (errors_temp) {
      return;
    }

    const invite = { ...formData };
    const newFormData = new PersonResaleNew({
      ...formData,
      nomeFantasia: formData.nome,
      razaoSocial: this.state.inputRazao,
      responsavelExecutivoId: this.state.inputExecutivo,
      statusChave: this.state.statusChave,
      prazoRenovacaoChave: this.state.prazoRenovacaoChave,
      comissionada: this.state.comissionada,
      classificacaoId: this.state.classificacaoId,
      descontosRevenda: this.state.descontosRevenda,
    });

    success(invite, newFormData);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.validateForm(async (convite, usuarioParaCriar) => {
      this.setLoading(true);
      try {
        const result = await InvitationService
          .createInvitationResale(convite, usuarioParaCriar);
        toast(result.message, { type: toast.TYPE.SUCCESS });
        setTimeout(() => this.history.goBack(), 100);
      } catch (error) {
        showMessageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  };

  getEmployees = async (value) => {
    try {
      const data = await PersonEmployeeService.getPersonEmployeeAutoComplete({
        cpfCnpj: unFormatCpfCnpj(value.trim().toLowerCase()) || null,
        nome: onlyText(value.trim().toLowerCase()) || null,
      });

      return data.resultado;
    } catch (error) {
      showMessageError(error);

      return [];
    }
  }

  getEmployeesChange = async (event) => {
    const {
      target: { value: valueToFind }
    } = event;

    if (valueToFind === '') {
      this.setState({
        employees: [],
        showSuggestions: false,
      });
    } else {
      clearTimeout(this.state.timeout);

      const timer = setTimeout(() => {
        const asyncSetTimeOut = async () => {
          const employees = await this.getEmployees(valueToFind);
          if (employees.length) {
            this.setState({
              employees,
              showSuggestions: true,
            });
          } else {
            this.setState({
              employees: [],
              showSuggestions: true,
            });
          }
        };

        asyncSetTimeOut();
      }, 500); // 0.5 segundos

      this.setState({ timeout: timer });
    }

    this.setInputValue(valueToFind);
  };

  getSuggestionLabel = suggestion => {
    if (suggestion) {
      return this.renderSuggestion(suggestion);
    } else {
      return '';
    }
  }

  getSuggestionValue = suggestion => {
    if (suggestion) {
      return suggestion;
    } else {
      return '';
    }
  }

  getSuggestionKey = suggestion => {
    if (suggestion) {
      return suggestion.id;
    } else {
      return '';
    }
  }

  renderSuggestion = suggestion => {
    return `${formatCpfCnpj(suggestion.cpfCnpj)} - ${suggestion.nomeSocial}`;
  }

  handleOnClick = suggestion => {
    const [employeeSelected] = this.state.employees.filter(
      suggItem => suggItem.id === suggestion.id
    );

    this.setInputValue(this.renderSuggestion(employeeSelected));
    this.setResponsavelExecutivoId(employeeSelected.id);
    this.setState({
      showSuggestions: false,
      employee: employeeSelected,
    });
  }

  handleSelectedStatusOutline(field, value) {
    return this.state[field] === PERSON_STATUS_ENUM[value].value
      ? 'checkradio_outline' : '';
  }

  render() {
    const {
      inputEmployeeValue,
      employees,
      showSuggestions,
      loading,
      inputRazao,
      inputCpfCnpj,
      inputNome,
      inputEmail,
      selectGrupo,

      statusChave,
      prazoRenovacaoChave,
    } = this.state;

    const {
      error_grupoId,
      error_cpfCnpj,
      error_inputNome,
      error_email,
      error_cpfCnpj_message,
      error_email_message,
    } = this.state;

    return (
      <div className="defaultFormContainer">
        <Modal show={loading} loading />
        <PageHeaderWithButtons
          mainMenu={'Convite'}
          pageName={'Novo Convite'}
          button1Label={'ENVIAR CONVITE'}
          button1Handle={this.handleSubmit}
          button2Label="CANCELAR"
          button2Handle={this.history.goBack}
        />
        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <div className="infoCard">
            <span id="title">Dados para convite</span>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex">
                <span>Nome Fantasia *</span>
                <input
                  type="text"
                  name="nome"
                  value={inputNome}
                  onChange={this.handleInputNomeChange}
                />
                {error_inputNome && (
                  <small style={{ color: 'red' }}>
                    Campo obrigatório
                  </small>
                )}
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Razão Social</span>
                <input
                  type="text"
                  name="razao"
                  value={inputRazao}
                  onChange={this.handleInputRazaoSocialChange}
                />
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>CNPJ *</span>
                <InputMask
                  type="text"
                  mask="99.999.999/9999-99"
                  name="cpfCnpj"
                  max={18}
                  value={inputCpfCnpj}
                  onChange={this.handleInputCpfCnpjChange}
                />
                {error_cpfCnpj && (
                  <small style={{ color: 'red' }}>
                    {error_cpfCnpj_message}
                  </small>
                )}
              </div>
              <div className="inputHolder defaultFlex">
                <span>E-mail *</span>
                <input
                  type="text"
                  name="nome"
                  value={inputEmail}
                  onChange={this.handleInputEmailChange}
                />
                {error_email && (
                  <small style={{ color: 'red' }}>
                    {error_email_message}
                  </small>
                )}
              </div>
              <div className="inputHolder noMarginRight">
                <span>Grupo de Acesso *</span>
                <select
                  name="grupoId"
                  value={selectGrupo}
                  onChange={this.handleSelectGrupoChange}
                >
                  <option value={''}>Selecione</option>
                  {this.accessGroups.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </select>
                {error_grupoId && (
                  <small style={{ color: 'red' }}>
                    Campo obrigatório
                  </small>
                )}
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Responsável Executivo CM</span>
                <span id="helpText">
                  Ao digitar, o campo irá auto completar. A busca do colaborador
                  poderá ser feita pelo CNPJ ou Nome Completo.
                </span>
                <AutoCompleteOn
                  inputDataValue={inputEmployeeValue}
                  suggestions={employees}
                  isShowSuggestions={showSuggestions}
                  handleDataChange={this.getEmployeesChange}
                  handleGetSuggestionKey={this.getSuggestionKey}
                  handleGetSuggestionLabel={this.getSuggestionLabel}
                  handleOnClick={this.handleOnClick}
                  isDisabled={!this.userCanEditResponsibleExecutive}
                />
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Geração de Chave</span>
            <div className="rowContainer">
              <div className="inputHolder marginRightX4">
                <span>Status Geração de Chave *</span>
                <div className="rowContainer">
                  <div className="radioHolder">
                    <div className="inputBoxHolder">
                      <input
                        type="radio"
                        name="statusChave"
                        value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}
                        onChange={this.handleInputStatusChaveChange}
                      />
                      <span className={
                        `checkradio ${this.handleSelectedStatusOutline(
                          'statusChave', PERSON_STATUS_ENUM.NOT_BLOCKED.key
                        )}`
                      }>
                        {statusChave === PERSON_STATUS_ENUM.NOT_BLOCKED.value && (
                          <div className="markRadioHolder">
                            <div className="checkradio_stem" />
                          </div>
                        )}
                      </span>
                    </div>
                    <span id="radioLabel">
                      {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
                    </span>
                  </div>
                  <div className="radioHolder">
                    <div className="inputBoxHolder">
                      <input
                        type="radio"
                        name="statusChave"
                        value={PERSON_STATUS_ENUM.BLOCKED.value}
                        onChange={this.handleInputStatusChaveChange}
                      />
                      <span className={
                        `checkradio ${this.handleSelectedStatusOutline(
                          'statusChave', PERSON_STATUS_ENUM.BLOCKED.key
                        )}`
                      }>
                        {statusChave === PERSON_STATUS_ENUM.BLOCKED.value && (
                          <div className="markRadioHolder">
                            <div className="checkradio_stem" />
                          </div>
                        )}
                      </span>
                    </div>
                    <span id="radioLabel">
                      {PERSON_STATUS_ENUM.BLOCKED.label}
                    </span>
                  </div>
                </div>
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Prazo de Renovação de Chave</span>
                <S.ContainerRow>
                  <input
                    className='inputSmall'
                    type="text"
                    name="prazo"
                    value={onlyNumbers(prazoRenovacaoChave)}
                    onChange={this.handleInputPrazoChange}
                  />
                  <span>dias</span>
                </S.ContainerRow>
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Outros</span>
            <div className="rowContainer">
              <div className="inputHolder noMarginRight">
                <span>Comissionada</span>
                <select
                  name="comissionada"
                  value={this.state.comissionada}
                  onChange={this.handleCheckboxComissionadaChange}
                >
                  <option value={false}>Não</option>
                  <option value>Sim</option>
                </select>
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Categoria</span>
            <div className="rowContainer">
              {this.channelRatings.map(item => (
                <div key={item.id} className="radioHolder">
                  <div className="inputBoxHolder">
                    <input
                      type="checkbox"
                      name="channelRating"
                      value={item.id}
                      checked={this.state.classificacaoId === item.id}
                      onChange={this.handleRadioClassificacaoChange}
                    />
                    <span className="checkmark">
                      {this.state.classificacaoId === item.id && (
                        <div className="markHolder">
                          <div className="checkmark_stem" />
                          <div className="checkmark_kick" />
                        </div>
                      )}
                    </span>
                  </div>
                  <span id="radioLabel">{item.nome}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Tipo de Desconto</span>
            <div className="rowContainer">
              {this.state.tiposDescontoRows.map(row => {
                return row.descontos.map(item => (
                  <div key={item.id} className="radioHolder">
                    <div className="inputBoxHolder">
                      <input
                        type="checkbox"
                        value={item.id}
                        onChange={this.handleCheckboxDescontosRevendaChange}
                      />
                      <span className="checkmark">
                        {includes(this.state.descontosRevenda, item.id) && (
                          <div className="markHolder">
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </div>
                        )}
                      </span>
                    </div>
                    <span id="radioLabel">{item.nome}</span>
                  </div>
                ));
              })}
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Total de Desconto(s)</span>
            <div className="dicountHolder">
              <span>
                <span id="value">{this.state.totalDescontos}%</span> de
                desconto(s)
              </span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const getUser = state => state.user.data;
const getPermissions = state => state.user.data.permissions;

export default connect(state => ({
  user: getUser(state),
  permissions: getPermissions(state),
}))(AddInvitationResale);
