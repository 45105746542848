import styled from 'styled-components';

export const Container = styled.div``;

export const ModalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    color: #707070;
    padding-bottom: 10px;
  }

  span {
    font-size: 18px !important;
  }

  div {
    display: flex;
    flex: 1;
    width: 100%;
    padding-top: 25px;
    justify-content: center;
  }
`;

export const ModalContainerHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  color: var(--cm-primary);

  svg {
    cursor: pointer;
  }
`;
