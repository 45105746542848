import { authenticationPaths } from '~/routes/paths';

const NOT_ACTIVE_PATHS = [
  ...Object.values(authenticationPaths),
];

const CHAT_APP_WIDGET = 'md-app-widget';

export function visibleChatMovidesk() {
  const { pathname } = document.location;

  const element = document.getElementById(CHAT_APP_WIDGET);
  if (!element) return;

  if (element && !NOT_ACTIVE_PATHS.includes(pathname)) {
    element.style.visibility = 'visible';
  } else {
    element.style.visibility = 'hidden';
  }
}

export function hiddenChatMovidesk() {
  const { pathname } = document.location;

  const element = document.getElementById(CHAT_APP_WIDGET);
  if (!element) return;

  if (element && NOT_ACTIVE_PATHS.includes(pathname)) {
    element.style.visibility = 'hidden';
  }
}
