import styled from 'styled-components';

export const ContainerAttachments = styled.div`
  width: 100%;
`;

export const ContainerHeaderClient = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  #title {
    margin-right: 20px;
  }
`;

export const InputMessageDisabled = styled.textarea`
  resize: vertical;
  overflow: auto;
  background-color: rgba(197, 197, 212, 0.15) !important;
`;
