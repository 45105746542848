import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import PrintableComponent from '~/components/PrintableComponent';
import { productPaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import ProductSoftwareService from '~/services/product-software-service';
import { showMessageError } from '~/util/errorutils';
import { inputNumber } from '~/util/stringutils';
import permissions from '~/util/permissions';
import { StatusType } from '~/components/StatusType';
import { accessDenied } from '~/util/accessDenied';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';
import SearchComponent from './SearchComponent';
import { useFitler } from '~/hook/useFilter';
import {
  CancelButton,
  Container,
  DeleteButton,
  ModalContainer,
} from './styles';

function ProductSoftware({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(false);
  const [inputCodigo, setInputCodigo] = useState('');
  const [inputName, setInputName] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    id: null, nome: '',
  });

  let PrintableElement = React.useRef();

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions.create_software),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions.edit_software),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions.delete_software),
    [userPermissions]
  );

  useEffect(() => {
    findAllByFilter(pageParameters, handleFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilters() {
    let count = 0;

    if (filters.codigoSoftwareERP) {
      setInputCodigo(filters.codigoSoftwareERP);
      count += 1;
    }
    if (filters.text) {
      setInputName(filters.text);
      count += 1;
    }

    setCountFilter(count);

    return filters;
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  async function findAllByFilterReport(pageParams, formDataParam) {
    setLoading(true);
    try {
      const data = await ProductSoftwareService.getProductSoftwares({
        ...formDataParam,
      });
      if (data.resultado.length) {
        handleSetSearchResponseReport(data.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos produtos de softwares para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleInputCodigoChange(event) {
    const {
      target: { value },
    } = event;

    setInputCodigo(inputNumber(value));
  }

  function handleInputNameChange(event) {
    const {
      target: { value },
    } = event;

    setInputName(value);
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};
    if (inputCodigo) {
      data.codigoSoftwareERP = inputCodigo.trim();
      count += 1;
    }

    if (inputName.length) {
      data.text = inputName.trim();
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    setFilters(data);
    await success(data);
  }

  async function findAllByFilter(pageParams, dataParams) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const data = await ProductSoftwareService.getProductSoftwares({
        ...(dataParams || formData),
        limit: page * rowsPerPage + rowsPerPage,
        offset: page * rowsPerPage,
      });
      if (data.resultado.length) {
        handleSetSearchResponse(data.resultado, data.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos produtos de softwares para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  async function handleDelete(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const { data: response } = await api.delete(
        endpoints.product.software.deleteProductSoftware(deleteItem.id)
      );
      if (!response.data.ok) {
        toast('Erro ao deletar o produto de software!', {
          type: toast.TYPE.ERROR,
        });
      } else {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteItem.id
        );

        handleSetSearchResponse(newSearchResponse, totalItems - 1);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o produto de software!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  function renderRowComponent(row) {
    let isAtivo = [true, false].includes(row.ativo) ? row.ativo : false;

    return [
      <TableCell key='row_software_0'>{row.codigoSoftwareERP || '---'}</TableCell>,
      <TableCell key='row_software_1'>{row.nome || '---'}</TableCell>,
      <TableCell key='row_software_5' align="center">
        <StatusType ativo={isAtivo} />
      </TableCell>,
    ];
  }

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Software"
        handlePageUpdate={findAllByFilterReport}
        filters={[
          { name: 'Código ERP', value: inputCodigo },
          { name: 'Software', value: inputName },
        ]}
        headerLabels={[
          { text: 'Código ERP', width: '10%' },
          { text: 'Nome', width: '60%' },
          { text: 'Status', width: '120px', align: 'center' },
        ]}
        renderRowComponent={row => renderRowComponent(row, true)}
        dataObjects={searchResponseReport}
        fieldsArray={['codigoSoftwareERP', 'nome', 'ativo']}
      />
      <Container>
        <Modal loading={loading} show={loading || !!deleteItem}>
          <ModalContainer>
            <h2>Confirmar exclusão</h2>
            <span>
              Tem certeza que deseja deletar o produto de software:
              {` ${!!deleteItem &&
                ((!!deleteItem.nome && deleteItem.nome) || deleteItem.id)}?`}
            </span>
            <div>
              <CancelButton onClick={() => setDeleteItem(null)}>
                Fechar
              </CancelButton>
              <DeleteButton onClick={handleDelete}>Deletar</DeleteButton>
            </div>
          </ModalContainer>
        </Modal>
        <SidebarPageHeader
          mainMenu="Produto"
          pageName="Software"
          button1={{
            label: 'Novo Software',
            link: productPaths.addProductSoftware,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await validateForm(async data => {
              await findAllByFilterReport(pageParameters, data);
            });
          }}
          openButtonSelect
        />
        <SearchComponent
          countFilter={countFilter}
          codeValue={inputCodigo}
          nameValue={inputName}
          handleCodeChange={handleInputCodigoChange}
          handleNameChange={handleInputNameChange}
          handleSubmit={handleSubmit}
        />
        <TableComponent
          headerLabels={[
            { text: 'Código ERP', width: '15%' },
            { text: 'Nome', width: '65%' },
            { text: 'Ativo', width: '20%', align: 'center' },
          ]}
          dataObjects={searchResponse}
          fieldsArray={['codigoSoftwareERP', 'nome', 'ativo']}
          renderRowComponent={renderRowComponent}
          editPermission={userCanEdit}
          handleEdit={row =>
            history.push(productPaths.editProductSoftware, {
              id: row.id,
            })
          }
          deletePermission={userCanDelete}
          handleDelete={userCanDelete
            ? row => setDeleteItem(row)
            : () => accessDenied(history)
          }
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
        />
      </Container>
    </>
  );
}

ProductSoftware.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ProductSoftware;
