import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import {
  FaRegEdit,
  FaRegTrashAlt,
  FaUserLock,
  FaTimesCircle,
} from 'react-icons/fa';
import { FiUnlock } from 'react-icons/fi';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal, { CustomModal } from '~/components/Modal';
import { USER_TYPE } from '~/util/domainutils';
import PrintableComponent from '~/components/PrintableComponent';
import { person } from '~/routes/paths';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import { accessDenied } from '~/util/accessDenied';
import permissions from '~/util/permissions';
import { InvitationService } from '~/services/person/invitation';
import PersonResaleService from '~/services/person-resale-service';
import { PERSON_STATUS_ENUM } from '~/util/domainutils';
import { formatCpfCnpj, inputNumber } from '~/util/stringutils';
import TableComponent from '~/components/TableComponent';
import PersonSearchComponent from '../SearchComponent';
import { useFitler } from '~/hook/useFilter';
import {
  CancelButton,
  Container,
  DeleteButton,
  ModalContainer,
} from './styles';

function PersonResale({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(false);
  const [inputCode, setInputCode] = useState('');
  const [inputRevenda, setInputRevenda] = useState('');
  const [inputStatus, setInputStatus] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [notBlockedItem, setNotBlockedItem] = useState(null);
  const [blockedItem, setBlockedItem] = useState(null);
  const [deleteInviteItem, setDeleteInviteItem] = useState(null);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    id: null,
    textPessoa1: '',
    status: '',
  });

  let PrintableElement = React.useRef();

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions.create_revenda),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions.edit_revenda),
    [userPermissions]
  );

  const userCanEditMassActions = useMemo(
    () => userPermissions.includes(permissions['edit_mass-actions']),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions.delete_revenda),
    [userPermissions]
  );

  const userCanBlock = useMemo(
    () => userPermissions.includes(permissions.block_user),
    [userPermissions]
  );

  const userCanDeleteInvite = useMemo(
    () => userPermissions.includes(permissions['delete_convite-pessoa']),
    [userPermissions]
  );

  useEffect(() => {
    findAllByFilter(pageParameters, handleFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilters() {
    let count = 0;

    if (filters.id) {
      setInputCode(filters.id);
      count += 1;
    }
    if (filters.textPessoa1) {
      setInputRevenda(filters.textPessoa1);
      count += 1;
    }
    if (filters.status) {
      setInputStatus(filters.status);
      count += 1;
    }

    setCountFilter(count);

    return filters;
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleInputCodeChange(event) {
    const {
      target: { value },
    } = event;

    setInputCode(inputNumber(value));
  }

  function handleInputRevendaChange(event) {
    const {
      target: { value },
    } = event;

    setInputRevenda(value);
  }

  function handleStatusChange(event) {
    const {
      target: { value },
    } = event;

    setInputStatus(value);
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};
    if (inputCode.length) {
      data.id = inputCode.trim();
      count += 1;
    }
    if (inputRevenda.length) {
      data.textPessoa1 = inputRevenda.trim();
      count += 1;
    }
    if (inputStatus.length) {
      data.status = inputStatus;
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    setFilters(data);
    await success(data);
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  async function findAllByFilterReport(pageParams, formData) {
    setLoading(true);
    try {
      const { data: response } = await api.post(
        endpoints.person.resale.getPersonResales,
        { ...formData }
      );
      const { data } = response;
      if (data.resultado.length) {
        handleSetSearchResponseReport(data.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos revendas para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams, data) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      const { page, rowsPerPage: limit } = pageParams;
      const { data: response } = await api.post(
        endpoints.person.resale.getPersonResales,
        {
          ...(data || formData),
          limit,
          offset: page * limit,
        }
      );
      if (response.data.resultado.length) {
        handleSetSearchResponse(response.data.resultado, response.data.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos revendas para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  async function handleDelete(event) {
    event.preventDefault();
    try {
      setLoading(true);

      const { data: response } = await api.delete(
        endpoints.person.resale.deletePersonResale(deleteItem.id)
      );

      if (!response.data.ok)
        toast('Erro ao deletar o Revenda!', {
          type: toast.TYPE.ERROR,
        });
      else {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteItem.id
        );

        handleSetSearchResponse(newSearchResponse, totalItems - 1);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o Revenda!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  const renderRowComponent = (row) => {
    const IS_NOT_BLOCKED = () => {
      return row.status === PERSON_STATUS_ENUM.NOT_BLOCKED.value;
    };

    let renderActions = [(
      <TableCell
        key='row_person_resale_5'
        className="actionCell"
        style={{ width: '150px' }}
      >
        <div className="actionButtonsHolder">
          {userCanBlock && (
            <>
              {IS_NOT_BLOCKED() ? (
                <FiUnlock
                  className="icon"
                  disabled={!userCanBlock}
                  onClick={() => setNotBlockedItem(row)}
                  title="Bloquear"
                />
              ) : (
                <FaUserLock
                  className="icon"
                  disabled={!userCanBlock}
                  onClick={() => setBlockedItem(row)}
                  title="Desbloquear"
                />
              )}
              <hr />
            </>
          )}
          <FaRegEdit
            className="icon"
            disabled={!userCanEdit}
            onClick={() => {
              history.push(person.editPersonResale, {
                id: row.id,
                responsavelExecutivoId: row.responsavelExecutivoId,
              });
            }}
            title="Editar"
          />
          <hr />
          <FaRegTrashAlt
            className="icon"
            disabled={!userCanDelete}
            onClick={() => setDeleteItem(row)}
            title="Deletar"
          />
        </div>
      </TableCell>
    )];

    if (row.tipo === USER_TYPE.CONVITE.REVENDA) {
      renderActions = [(
        <TableCell
          key='row_actions_employee_0'
          className="actionCell"
          style={{ width: '150px' }}
        >
          <div className="actionButtonsHolder buttonCenterHolder">
            <FaTimesCircle
              className="icon"
              onClick={() => {
                return userCanDeleteInvite
                  ? setDeleteInviteItem(row)
                  : accessDenied(history);
              }}
              title="Cancelar Convite"
            />
          </div>
        </TableCell>
      )];
    }

    return [
      <TableCell key='row_person_resale_0'>
        {row.id}
      </TableCell>,
      <TableCell key='row_person_resale_1'>
        {row.nomeFantasia}
      </TableCell>,
      <TableCell key='row_person_resale_2'>
        {row.formatedCpfCnpj}
      </TableCell>,
      ...renderActions,
    ];
  };

  async function handleStatusUser(event) {
    event.preventDefault();
    try {
      setLoading(true);
      let params = { id: null, status: null };
      if (notBlockedItem) {
        params = {
          id: notBlockedItem.id,
          status: PERSON_STATUS_ENUM.BLOCKED.value,
        };
      }
      if (blockedItem) {
        params = {
          id: blockedItem.id,
          status: PERSON_STATUS_ENUM.NOT_BLOCKED.value,
        };
      }
      const response = await PersonResaleService.updatePersonResaleStatus(
        params.id, params.status,
      );
      if (!response.data.ok) {
        toast('Erro ao alterar status da Revenda!', { type: toast.TYPE.ERROR });
      } else {
        const newSearchResponse = searchResponse.map(resp => {
          if (resp.id === params.id) {
            return {
              ...resp,
              status: params.status,
            };
          }

          return resp;
        });
        handleSetSearchResponse(newSearchResponse, totalItems);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError('Erro ao alterar status da Revenda!');
    } finally {
      if (notBlockedItem) setNotBlockedItem(null);
      if (blockedItem) setBlockedItem(null);
      setLoading(false);
    }
  }

  async function handleDeleteInvite(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const result = await InvitationService
        .deletePersonInvitation(deleteInviteItem.cpfCnpj);

      if (result.ok) {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteInviteItem.id
        );

        handleSetSearchResponse(newSearchResponse, totalItems - 1);
        toast(result.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
      setDeleteInviteItem(null);
    }
  }

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Revenda"
        handlePageUpdate={findAllByFilterReport}
        filters={[
          { name: 'Código', value: inputCode },
          { name: 'Revenda', value: inputRevenda },
          { name: 'Status', value: inputStatus },
        ]}
        headerLabels={[
          { text: 'Código', width: 'auto' },
          { text: 'Nome / Fantasia', width: '40%' },
          { text: 'CPF / CNPJ', width: '18%' },
        ]}
        dataObjects={searchResponseReport.map(item => {
          item.formatedCpfCnpj = formatCpfCnpj(item.cpfCnpj);
          item.telefone1 = item.contato.telefone1;
          return item;
        })}
        fieldsArray={['id', 'nomeFantasia', 'formatedCpfCnpj']}
      />
      <Container>
        <SidebarPageHeader
          mainMenu="Pessoa"
          pageName="Revenda"
          button1={{
            label: 'Nova Revenda',
            link: person.addPersonResale,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await validateForm(async data => {
              await findAllByFilterReport(pageParameters, data);
            });
          }}
          openButtonSelect
          customButtonsAfterReport={[
            userCanEditMassActions && (
              <button
                key='mass-action-button'
                className="buttonBase baseWebMobile addMargin"
                onClick={() => history
                  .push(person.personMassActions, {
                    goBack: person.personResale,
                    pageMain: 'Revenda'
                  })}
              >
                AÇÕES EM MASSA
              </button>
            ),
          ]}
        />
        <PersonSearchComponent
          countFilter={countFilter}
          userType={USER_TYPE.REVENDA}
          userCanViewColumnRevenda={false}
          inputPessoa1Value={inputRevenda}
          handlePessoa1Change={handleInputRevendaChange}
          handleSubmit={handleSubmit}
          codeValue={inputCode}
          handleCodeChange={handleInputCodeChange}
          noFieldStatus={false}
          statusValue={inputStatus}
          handleStatusChange={handleStatusChange}
          noFieldStatusChave
        />
        <TableComponent
          headerLabels={[
            { text: 'Código', width: '10%' },
            { text: 'Nome / Fantasia', width: 'auto' },
            { text: 'CPF / CNPJ', width: 'auto' },
            { text: 'Ação', width: '15%', align: 'center' },
          ]}
          dataObjects={searchResponse.map(item => {
            item.formatedCpfCnpj = formatCpfCnpj(item.cpfCnpj);
            item.telefone1 = item.contato.telefone1;
            return item;
          })}
          renderRowComponent={renderRowComponent}
          fieldsArray={['id', 'nomeFantasia', 'formatedCpfCnpj']}
          useCustomActions
          editPermission={userCanEdit}
          handleEdit={row =>
            history.push(person.editPersonResale, {
              id: row.id,
              name: row.nome,
            })
          }
          deletePermission={userCanDelete}
          handleDelete={row => setDeleteItem(row)}
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
        />
        <Modal loading={loading}
          show={loading
            || !!deleteItem
            || !!notBlockedItem
            || !!blockedItem
            || !!deleteInviteItem
          }
        >
          <CustomModal>
            {!!deleteItem && (
              <ModalContainer>
                <h2>Confirmar exclusão</h2>
                <span>
                  Tem certeza que deseja deletar a revenda:
                  {` ${!!deleteItem && deleteItem.nomeFantasia}?`}
                </span>
                <div>
                  <CancelButton onClick={() => setDeleteItem(null)}>
                    Cancelar
                  </CancelButton>
                  <DeleteButton onClick={handleDelete}>Deletar</DeleteButton>
                </div>
              </ModalContainer>
            )}
            {(!!notBlockedItem || !!blockedItem) && (
              <ModalContainer>
                <h2>Confirmar atualização de status</h2>
                {!!notBlockedItem && (
                  <>
                    <span>
                      Tem certeza que deseja bloquear a revenda:
                      {` ${!!notBlockedItem &&
                        ((!!notBlockedItem.nomeFantasia && notBlockedItem.nomeFantasia) ||
                          notBlockedItem.id)}?`}
                    </span>
                    <div>
                      <CancelButton onClick={() => setNotBlockedItem(null)}>
                        Cancelar
                      </CancelButton>
                      <DeleteButton onClick={handleStatusUser}>
                        Bloquear
                      </DeleteButton>
                    </div>
                  </>
                )}
                {!!blockedItem && (
                  <>
                    <span>
                      Tem certeza que deseja desbloquear a revenda:
                      {` ${!!blockedItem &&
                        ((!!blockedItem.nomeFantasia && blockedItem.nomeFantasia) ||
                          blockedItem.id)}?`}
                    </span>
                    <div>
                      <CancelButton onClick={() => setBlockedItem(null)}>
                        Cancelar
                      </CancelButton>
                      <DeleteButton onClick={handleStatusUser}>
                        Desbloquear
                      </DeleteButton>
                    </div>
                  </>
                )}
              </ModalContainer>
            )}
            {(!!deleteInviteItem) && (
              <ModalContainer>
                <h2>Confirmar cancelamento</h2>
                <span>
                  Tem certeza que deseja cancelar o convite de:
                  {` ${!!deleteInviteItem &&
                    ((!!deleteInviteItem.nomeFantasia && deleteInviteItem.nomeFantasia)
                      || deleteInviteItem.id)}?`}
                </span>
                <div>
                  <CancelButton onClick={() => setDeleteInviteItem(null)}>
                    NÃO
                  </CancelButton>
                  <DeleteButton onClick={handleDeleteInvite}>
                    SIM
                  </DeleteButton>
                </div>
              </ModalContainer>
            )}
          </CustomModal>
        </Modal>
      </Container>
    </>
  );
}

PersonResale.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default PersonResale;
