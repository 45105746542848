import React from 'react';
import PropTypes from 'prop-types';
import { SelectClientSolicitation } from './SelectClients';
import { SelectSolicitation } from './SelectSolicitation';
import { AttachmentsSolicitation } from './Attachments';
import { Resume } from './Resume';

function Steps({ index }) {
  const steps = [
    <SelectClientSolicitation key='select-clients' />,
    <SelectSolicitation key='select-solicitation' />,
    <AttachmentsSolicitation key='attachments' />,
    <Resume key='resume' />,
  ];

  return (
    <>
      {steps.map((elem, ind) => (
        <div key={ind}>
          {index === ind && (elem)}
        </div>
      ))}
    </>
  );
}

Steps.propTypes = {
  index: PropTypes.number.isRequired,
};

export { Steps };
