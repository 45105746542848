import PropTypes from 'prop-types';
import React from 'react';

function MultipleChoices(props) {
  const {
    type,
    title,
    dataObjects,
    checkedChoices,
    handleChoicesChange,
    customLabel,
  } = props;

  return (
    <>
      <span>{title}</span>
      <div id="statusHolder">
        {dataObjects.map(((input, index) => (
          <div
            key={`multiple-choice-${title}-${type}-${index}`}
            className="radioHolder"
          >
            <div className="inputBoxHolder">
              <input
                type={type}
                name={title.toLowerCase()}
                value={input.value}
                checked={checkedChoices.some(elem => elem === input.value)}
                onChange={handleChoicesChange}
              />
              <span className="checkmark">
                {checkedChoices.some(elem => elem === input.value)
                  && (
                    <div className="markHolder">
                      <div className="checkmark_stem" />
                      <div className="checkmark_kick" />
                    </div>
                  )}
              </span>
            </div>
            {customLabel ? customLabel(input) : (
              <span id="radioLabel">{input.label}</span>
            )}
          </div>
        )))}
      </div>
    </>
  );
}

MultipleChoices.propTypes = {
  dataObjects: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  checkedChoices: PropTypes.array.isRequired,
  handleChoicesChange: PropTypes.func.isRequired,
  customLabel: PropTypes.func,
};

export { MultipleChoices };
