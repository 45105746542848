import styled from 'styled-components';

export const OrderChoosePaymentFormContainer = styled.form`
  border: 0;
  margin: 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
`;

export const ChoosePaymentFormContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 20px 10px 20px;
`;

export const PaymentMethodSelects = styled.div`
  max-width: 360px;
  margin-right: 20px;

  span {
    color: var(--cm-primary);
    font-weight: bold;
  }

  select {
    height: 33px;
    border-radius: 6px;
    border: 1px solid #dadae3;
    padding: 0px 15px;
    margin-right: 20px;
  }

  &:nth-last-child(1) {
    margin-right: 0;

    select {
      margin-right: 0;
    }
  }

  select:focus {
    border: 1px solid var(--cm-primary);
  }
`;

export const Divider = styled.div`
  height: 10px;
  background-color: #E5E5E5;
`;
