import styled from 'styled-components';

export const Label = styled.label`
  color: var(--cm-primary);
  font-size: 12pt;
`;

export const Error = styled.small`
  color: red;
`;
