import styled from 'styled-components';

export const PageBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #fcfcfc;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
`;
