import React from 'react';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import { USER_TYPE } from '~/util/domainutils';
import {
  formatCpfCnpjByCaracter,
  onlyNumbers, onlyText,
} from '~/util/stringutils';
import * as S from './styles';

const TEXT_TITLE_REVENDA_CLIENTE = 'Pesquise por parte: Razão Social, Nome Fantasia ou CPF/CNPJ';
const TEXT_TITLE_COLABORADOR = 'Pesquise por parte: Nome Social ou CPF/CNPJ';

function InputPerson({
  title1,
  inputPerson1,
  handlePerson1Change,
  title2,
  inputPerson2,
  handlePerson2Change,
  userCanViewPerson2,
  noMarginRight = false,
  userType,
}) {
  function handleInput(inputValue) {
    if ((inputValue|| '').length >= 1
      && [
        '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
      ].includes(inputValue.charAt(0))
      && (onlyText(inputValue).length === 0)
    ) {
      return formatCpfCnpjByCaracter(inputValue);
    }

    return inputValue;
  }

  function isCpfCnpj(e) {
    const count = onlyText(e.target.value).length;
    if (count === 0 && onlyNumbers(e.target.value).length <= 14) {
      return true;
    } else if (count > 0) {
      return true;
    } else {
      return false;
    }
  }

  const getNoMarginRight = (canView) => {
    return canView ? 'noMarginRight' : '';
  };

  const getInputStyle = (canView) => {
    return `inputHolder defaultFlex ${getNoMarginRight(canView)}`;
  };

  const getStyle1 = () => {
    return userCanViewPerson2
      ? getInputStyle(!userCanViewPerson2)
      : getInputStyle(userCanViewPerson2);
  };

  const getStyle2 = () => {
    return getInputStyle(!userCanViewPerson2)
      + getNoMarginRight(noMarginRight);
  };

  const TEXT_TITLE = userType !== USER_TYPE.COLABORADOR
    ? TEXT_TITLE_REVENDA_CLIENTE
    : TEXT_TITLE_COLABORADOR;

  return (
    <>
      <div className={getStyle1()}>
        <S.LabelIcon>
          <span>{title1}</span>
          <FaInfoCircle
            size={14}
            title={TEXT_TITLE}
          />
        </S.LabelIcon>
        <input
          type="text"
          name="person-1"
          value={handleInput(inputPerson1)}
          onChange={(e) => { if (isCpfCnpj(e)) handlePerson1Change(e); }}
        />
      </div>
      {userCanViewPerson2 && (
        <div className={getStyle2()}>
          <S.LabelIcon>
            <span>{title2}</span>
            <FaInfoCircle
              size={14}
              title={TEXT_TITLE}
            />
          </S.LabelIcon>
          <input
            type="text"
            name="person-2"
            value={handleInput(inputPerson2)}
            onChange={(e) => { if (isCpfCnpj(e)) handlePerson2Change(e); }}
          />
        </div>
      )}
    </>
  );
}

InputPerson.propTypes = {
  title1: PropTypes.string,
  inputPerson1: PropTypes.string,
  handlePerson1Change: PropTypes.func,
  title2: PropTypes.string,
  userType: PropTypes.string,
  inputPerson2: PropTypes.string,
  handlePerson2Change: PropTypes.func,
  userCanViewPerson2: PropTypes.bool,
  noMarginRight: PropTypes.bool,
};

export { InputPerson };
