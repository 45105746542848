import moment from 'moment';
import React, { useEffect, useState } from 'react';
import FileBase64 from 'react-file-base64';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Photo from '~/assets/200x200.jpg';
import Modal from '~/components/Modal';
import { InputPassword } from '~/components/FieldPassword';
import api, { endpoints } from '~/services/api';
import { userUpdateData } from '~/store/modules/user/actions';
import { showMessageError } from '~/util/errorutils';
import { PASSWORD_ATTEMPTS, passwordCheck } from '~/util/validationsutils';
import { SUPORT_FILES } from '~/util/fileutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';
import { PasswordInformation } from '~/components/PasswordInformation';
import { Form } from './styles';
import './styles.scss';

function Profile() {
  const user = useSelector(state => state.user.data);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const [inputFullName, setInputFullName] = useState(user?.nome);
  const [inputEmail, setInputEmail] = useState((user?.contato?.email || ''));
  const [file, setFile] = useState(user?.imagem ? user?.imagem : Photo);

  const [inputPassword, setInputPassword] = useState('');
  const [inputConfirmPassword, setInputConfirmPassword] = useState('');

  const [accessHistoryResponse, setAccessHistoryResponse] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });

  const [countdownBlockedAfterAttempts, setCountdownBlockedAfterAttempts] = useState(`(${PASSWORD_ATTEMPTS.TIME / 1000}s)`);
  const [buttonBlockedAfterAttempts, setButtonBlockedAfterAttempts] = useState(false);

  const passwordAttempts = {
    key: PASSWORD_ATTEMPTS.KEY,
    maximum: PASSWORD_ATTEMPTS.MAXIMUM,
    time: PASSWORD_ATTEMPTS.TIME,
  };

  async function getAccessHistory(pageParams) {
    setPageParams(pageParams);
    try {
      const { page, rowsPerPage } = pageParams;
      const { data: response } = await api.post(
        endpoints.profile.getAccessHistory,
        { limit: page * rowsPerPage + rowsPerPage, offset: page * rowsPerPage }
      );
      if (response.data.resultado.length) {
        setAccessHistoryResponse(response.data.resultado);
        setTotalItems(response.data.total);
      } else {
        toast('Não encontramos o hitórico de acesso para o perfil!', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getAccessHistory(pageParameters);
  }, [pageParameters]);

  function handleInputFullNameChange(event) {
    const {
      target: { value },
    } = event;

    setInputFullName(value);
  }

  function handleInputEmailChange(event) {
    const {
      target: { value },
    } = event;

    setInputEmail(value);
  }

  function handleInputPasswordChange(event) {
    const {
      target: { value },
    } = event;

    setInputPassword(value);
  }

  function handleInputConfirmPasswordChange(event) {
    const {
      target: { value },
    } = event;

    setInputConfirmPassword(value);
  }

  function getFile(value) {
    if (value.type && SUPORT_FILES.IMAGES.includes(value.type)) {
      setFile(value.base64);
    } else {
      toast.error('Tipo de arquivo não é suportado');
    }
  }



  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    try {
      const data = {};
      if (inputFullName.length) { data.nome = inputFullName; }
      if (inputEmail.length) { data.email = inputEmail; }
      if (file.length && file !== Photo) { data.imagem = file; }

      if (inputPassword.length) {
        if (!inputConfirmPassword.length) {
          toast('Favor, confirme a senha!', {
            type: toast.TYPE.ERROR,
          });
          return;
        }

        if (inputPassword !== inputConfirmPassword) {
          toast('Confirmação de senha inválida.', {
            type: toast.TYPE.ERROR,
          });
          return;
        }

        const passwordChecked = passwordCheck(inputPassword);

        if (!passwordChecked.success) {
          toast(passwordChecked.message, { type: toast.TYPE.ERROR });

          const attemptsKey = passwordAttempts.key;
          const attempts = parseInt(localStorage.getItem(attemptsKey) || 0) + 1;

          localStorage.setItem(attemptsKey, attempts);

          if (attempts >= passwordAttempts.maximum) {
            setButtonBlockedAfterAttempts(true);

            let limit = passwordAttempts.time / 1000;
            const interval = setInterval(() => {
              if (limit === 1) {
                setButtonBlockedAfterAttempts(false);
                localStorage.setItem(attemptsKey, 0);
                clearInterval(interval);
                return;
              }

              limit = limit - 1;

              setCountdownBlockedAfterAttempts(`(${limit}s)`);
            }, 1000);
          }

          return;
        }

        setButtonBlockedAfterAttempts(false);
        localStorage.setItem(passwordAttempts.key, 0);

        data.novaSenha = inputPassword;
        setInputPassword('');
        setInputConfirmPassword('');
      }

      const { data: response } = await api.put(
        endpoints.profile.updateUserProfile, data
      );

      if (response.data) {
        dispatch(
          userUpdateData({
            ...user,
            nome: inputFullName,
            imagem: file,
            contato: { ...user.contato, email: inputEmail },
          })
        );

        toast(response.message, { type: toast.TYPE.SUCCESS });
      } else {
        toast('Não encontramos o hitórico de acesso para o perfil!', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="defaultFormContainer">
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Olá"
        pageName={user?.nome}
        button1={{
          label: `ATUALIZAR ${buttonBlockedAfterAttempts ? countdownBlockedAfterAttempts : ''}`,
          onClick: () => handleSubmit,
          main: true,
          disabled: buttonBlockedAfterAttempts
        }}
      />
      <Form className="defaultForm profileDefaultForm" onSubmit={handleSubmit}>
        <div className="profileImageContainer">
          <img alt="upload" className="Icon" src={file} />
          {file.length > 0 ? null : (
            <span>Nenhuma imagem foi selecionada.</span>
          )}
          <label>Selecione um arquivo de imagem</label>
          <div className="imagePicker">
            <div className="buttonHolder">
              <span>ESCOLHER IMAGEM</span>
              <FileBase64 multiple={false} onDone={value => getFile(value)} />
            </div>
          </div>
        </div>
        <div className="infoCard profileFormContainer">
          <span id="title">Dados Gerais</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Nome</span>
              <input
                type="text"
                name="fullname"
                value={inputFullName}
                onChange={handleInputFullNameChange}
                disabled
              />
            </div>
          </div>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>E-mail</span>
              <input
                type="email"
                name="email"
                value={inputEmail}
                onChange={handleInputEmailChange}
                disabled
              />
            </div>
          </div>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex" style={{ maxWidth: 200 }}>
              <span>Senha</span>
              <InputPassword
                autoComplete="new-password"
                type="password"
                name="password"
                value={inputPassword}
                onChange={handleInputPasswordChange}
              />
            </div>
            <div className="inputHolder defaultFlex" style={{ maxWidth: 200 }}>
              <span>Confirmar Senha</span>
              <InputPassword
                autoComplete="new-password"
                type="password"
                name="confirmPassword"
                value={inputConfirmPassword}
                onChange={handleInputConfirmPasswordChange}
              />
            </div>
          </div>

          <PasswordInformation password={inputPassword} />
        </div>
      </Form>

      <div className="defaultForm">
        <div className="infoCard">
          <span id="title">Últimos Acessos</span>
          <TableComponent
            headerLabels={[
              { text: 'Data' },
              { text: 'Sistema Operacional' },
              { text: 'Navegador' },
              { text: 'Endereço IP' },
            ]}
            dataObjects={accessHistoryResponse.map(response => {
              response.date = moment(response.data).format(
                'DD/MM/YYYY, HH:mm:ss'
              );
              return response;
            })}
            fieldsArray={['date', 'so', 'browser', 'ip']}
            totalItems={totalItems}
            handlePageUpdate={getAccessHistory}
            useCustomActions
          />
        </div>
      </div>
    </div>
  );
}

export default Profile;
