import React from 'react';
import PropTypes from 'prop-types';
import { MdOutlineWarning } from 'react-icons/md';
import * as S from './styles';

function WarningText({ description = '', noMarginBottom = false }) {
  return (
    <>
      {description !== '' && (
        <S.WarningTextContainer
          noMarginBottom={noMarginBottom}
        >
          <MdOutlineWarning />
          <small>{description}</small>
        </S.WarningTextContainer>
      )}
    </>
  );
}

WarningText.propTypes = {
  noMarginBottom: PropTypes.bool,
  description: PropTypes.string,
};

export { WarningText };
