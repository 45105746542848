/**
 * "After a specified amount of time, scroll to the element with the specified id."
 *
 * The function takes two parameters:
 *
 * nameElement: the id of the element to scroll to
 * ms: the amount of time to wait before scrolling
 * The function uses setTimeout to wait for the specified amount of time before
 * scrolling to the element
 * @param nameElement - The name of the element you want to move to.
 * @param [ms=200] - The time in milliseconds to wait before scrolling to the
 * element.
 */
export function handleMoveElement(nameElement, ms = 200) {
  setTimeout(() => {
    const element = document.getElementById(nameElement);

    if (element) element.scrollIntoView({ behavior: 'smooth' });
  }, ms);
}

/**
 * It takes an element id as an argument, finds the element, and scrolls to the
 * bottom of it
 * @param nameElementId - The name of the element you want to scroll to the bottom of.
 * @returns the scrollTop property of the element with the id of nameElementId.
 */
export function scrollToBottom(nameElementId) {
  const messagesScroll = document.getElementById(nameElementId);

  if (!messagesScroll) return;

  messagesScroll.scrollTop = messagesScroll.scrollHeight;
}
