import PropTypes from 'prop-types';
import React from 'react';
import ReactToPrint from 'react-to-print';
import { FaEye, FaDownload } from 'react-icons/fa';
import { FormsContainer, Tooltip } from './styles';
import './styles.scss';

function FormViewer({ formName, showDownload, handleDownload, handleViewer, withWidth }) {
  return (
    <Tooltip key={formName} className="tooltip">
      <span className="tooltiptext">{formName}</span>
      <FormsContainer width={withWidth ? withWidth : 168}>
        <span>{formName}</span>
        <div className="formActions">
          {showDownload && (
            <ReactToPrint
              key={'to_viewer_answer_print'}
              trigger={() => <FaDownload className="formButton" />}
              content={() => handleDownload}
            />
          )}
          <FaEye
            className="formButton"
            onClick={handleViewer}
          />
        </div>
      </FormsContainer>
    </Tooltip>
  );
}

FormViewer.propTypes = {
  formName: PropTypes.string,
  showDownload: PropTypes.bool,
  handleDownload: PropTypes.object,
  handleViewer: PropTypes.func,
  withWidth: PropTypes.number,
  contentRef: PropTypes.any,
};

export default FormViewer;
