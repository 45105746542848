import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import PersonEmployeeNew from '~/model/person-employee-new';
import PersonEmployeeEdit from '~/model/person-employee-edit';
import PersonResaleNew from '~/model/person-resale-new';
import PersonResaleEdit from '~/model/person-resale-edit';
import { InvitationService } from '~/services/invitation';
import { USER_TYPE } from '~/util/domainutils';
import { RECONNECT_USER } from '~/util/functionsutils';
import { showMessageError } from '~/util/errorutils';
import { FormEmployee } from './FormEmployee';
import { FormResale } from './FormResale';
import { FormRegister } from './FormRegister';
import { hiddenChatMovidesk } from '~/styles/movidesk';
import { onlyNumbers, validePhone } from '~/util/stringutils';
import { PASSWORD_ATTEMPTS, passwordCheck } from '~/util/validationsutils';
import MarcaReduzidaNegativa from
  '~/assets/marca/reduzida/SVG/CM-marca_reduzida-negativa-RGB.svg';
import marcaPreferencialNegativa from
  '~/assets/marca/preferencial/SVG/CM-marca_preferencial-negativa-RGB.svg';
import * as S from './styles';

class Invitation extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { history, location } = props;
    this.history = history;
    this.location = location;
    this.token = '';
    this.tipo = 'cadastro';
    this.usuarioTipo = '';
    this.state = {
      loading: true,
      validToken: false,
      inputPassword: '',
      inputConfirmPassword: '',
      resaleEmployee: null,
      imageName: '',
      personId: null,
      person: {
        ...new PersonEmployeeNew({}),
        ...new PersonResaleNew({}),
      },
      error: {
        errorNomeSocial: false,
        errorNomeFantasia: false,
        errorRazaoSocial: false,
        errorOptanteSimples: false,
        errorTelefone1: {
          state: false,
          message: 'Campo obrigatório',
        },
        errorTelefone2: false,
        errorEstado: false,
        errorCidade: false,
        errorCEP: false,
        errorBairro: false,
        errorEndereco: false,
        errorMicroempresa: false,
        errorRetemICMS: false,
        errorSegmentos: false,
        errorSenha: false,
        errorConfirmarSenha: false,
      },
      passwordAttempts: {
        key: PASSWORD_ATTEMPTS.KEY,
        maximum: PASSWORD_ATTEMPTS.MAXIMUM,
        time: PASSWORD_ATTEMPTS.TIME,
      },
      buttonBlockedAfterAttempts: false,
      countdownBlockedAfterAttempts: `(${PASSWORD_ATTEMPTS.TIME / 1000}s)`
    };
  }

  /* LifeCicle:: Inicializa quando monta a DOM */
  componentDidMount() {
    hiddenChatMovidesk();
    const query = queryString.parse(this.props.location.search);
    if (!query.token) {
      this.setLoading(false);
      toast('O convite não está mais ativo!', { type: toast.TYPE.ERROR });
      RECONNECT_USER(this.history);
    } else {
      this.setValidToken(true);
      InvitationService
        .validateTokenInvitation(query.token)
        .then(convite => {
          if (convite) {
            this.token = query.token;
            this.tipo = convite.tipo;
            this.usuarioTipo = convite.data.usuarioTipo;
            return [convite.data.usuarioTipo, convite.data.pessoa];
          }
        })
        .then(async ([usuarioTipo, pessoa]) => {
          if (usuarioTipo === USER_TYPE.CONVITE.COLABORADOR) {
            this.setPersonId(pessoa.id);
            const employee = new PersonEmployeeEdit(pessoa);
            const { nomeFantasia, cpfCnpj, email } = pessoa.revenda;
            this.setState({
              person: new PersonEmployeeNew(employee),
              resaleEmployee: {
                nomeFantasia: nomeFantasia || '',
                cpfCnpj: cpfCnpj || '',
                email: email || '',
              },
            });
          }
          if (usuarioTipo === USER_TYPE.CONVITE.REVENDA) {
            this.setPersonId(pessoa.id);
            const resale = new PersonResaleEdit(pessoa);
            this.setState({
              person: new PersonResaleNew(resale)
            });
          }
        })
        .catch(() => {
          toast.error('O convite não está mais ativo!');
          RECONNECT_USER(this.history);
        })
        .finally(() => { this.setLoading(false); });
    }
  }

  isTipoConvite() { return this.tipo === 'convite'; }

  isLoading() { return this.state.loading === true; }

  setPersonId = b => this.setState({ personId: b });

  setLoading = b => this.setState({ loading: b });

  setValidToken = b => this.setState({ validToken: b });

  setInputPassword = b => this.setState({ inputPassword: b });

  setInputConfirmPassword = b => this.setState({ inputConfirmPassword: b });

  setInputNomeSocial = b => {
    const { person } = this.state;
    person.nomeSocial = b;
    this.setState({ person });
  };

  setInputNomeFantasia = b => {
    const { person } = this.state;
    person.nomeFantasia = b;
    this.setState({ person });
  };

  setInputRazaoSocial = b => {
    const { person } = this.state;
    person.razaoSocial = b;
    this.setState({ person });
  };

  setSelectEstado = b => {
    const { person } = this.state;
    person.estado = b;
    this.setState({ person });
  };

  setInputCidade = b => {
    const { person } = this.state;
    person.cidade = b;
    this.setState({ person });
  };

  setInputCep = b => {
    const { person } = this.state;
    person.cep = b;
    this.setState({ person });
  };

  setInputBairro = b => {
    const { person } = this.state;
    person.bairro = b;
    this.setState({ person });
  };

  setInputEndereco = b => {
    const { person } = this.state;
    person.endereco = b;
    this.setState({ person });
  };

  setInputComplemento = b => {
    const { person } = this.state;
    person.complemento = b;
    this.setState({ person });
  };

  setInputPontoReferencia = b => {
    const { person } = this.state;
    person.pontoReferencia = b;
    this.setState({ person });
  };

  setInputTelefone1 = b => {
    const { person } = this.state;
    person.telefone1 = b;
    this.setState({ person });
  };

  setInputTelefone2 = b => {
    const { person } = this.state;
    person.telefone2 = b;
    this.setState({ person });
  };

  setInputImagem = b => {
    const { person } = this.state;
    person.imagem = b.base64;
    this.setState({ person, imageName: b.name });
  };

  setInputInscricaoEstadual = b => {
    const { person } = this.state;
    person.inscricaoEstadual = b;
    this.setState({ person });
  };

  setInputOptanteSimples = b => {
    const { person } = this.state;
    person.optanteSimples = b;
    this.setState({ person });
  };

  setInputDataFundacao = b => {
    const { person } = this.state;
    person.dataFundacao = b;
    this.setState({ person });
  };

  setInputMicroempresa = b => {
    const { person } = this.state;
    person.microempresa = b;
    this.setState({ person });
  };

  setInputRetemICMS = b => {
    const { person } = this.state;
    person.retemICMS = b;
    this.setState({ person });
  };

  setInputSegmentos = b => {
    const { person } = this.state;
    person.segmentos = b;
    this.setState({ person });
  };

  setButtonBlockedAfterAttempts = b => this.setState({ buttonBlockedAfterAttempts: b });

  setCountdownBlockedAfterAttempts = b => this.setState({ countdownBlockedAfterAttempts: b });

  handleInputPasswordChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputPassword(value);
  };

  handleInputConfirmPasswordChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputConfirmPassword(value);
  };

  handleInputNomeSocialChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputNomeSocial(value);
    this.handleErrorsNomeSocial(false);
  };

  handleInputNomeFantasiaChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputNomeFantasia(value);
    this.handleErrorsNomeFantasia(false);
  };

  handleInputRazaoSocialChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputRazaoSocial(value);
    this.handleErrorsRazaoSocial(false);
  };

  handleSelectEstadoChange = event => {
    const {
      target: { value },
    } = event;
    this.setSelectEstado(value);
    this.handleErrorsEstado(false);
  };

  handleInputCidadeChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCidade(value);
    this.handleErrorsCidade(false);
  };

  handleInputCepChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCep(value);
    this.handleErrorsCEP(false);
  };

  handleInputBairroChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputBairro(value);
    this.handleErrorsBairro(false);
  };

  handleInputEnderecoChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputEndereco(value);
    this.handleErrorsEndereco(false);
  };

  handleInputComplementoChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputComplemento(value);
  };

  handleInputPontoReferenciaChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputPontoReferencia(value);
  };

  handleInputTelefone1Change = event => {
    const {
      target: { value },
    } = event;
    this.setInputTelefone1(value);
    this.handleErrorsTelefone1(false, 'Campo obrigatório');
  };

  handleInputTelefone2Change = event => {
    const {
      target: { value },
    } = event;
    this.setInputTelefone2(value);
    this.handleErrorsTelefone2(false, '');
  };

  handleInputInscricaoEstadualChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputInscricaoEstadual(value);
  };

  handleInputOptanteSimplesChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputOptanteSimples(value);
    this.handleErrorsOptanteSimples(false);
  };

  handleInputDataFundacaoChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputDataFundacao(value);
  };

  handleInputMicroempresaChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputMicroempresa(value);
    this.handleErrorsMicroempresa(false);
  };

  handleInputRetemICMSChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputRetemICMS(value);
    this.handleErrorsRetemICMS(false);
  };

  handleInputSegmentosChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputSegmentos(value);
    this.handleErrorsSegmentos(false);
  };

  handleInputPasswordChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputPassword(value);
    this.handleErrorsSenha(false);
  };

  handleInputConfirmPasswordChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputConfirmPassword(value);
    this.handleErrorsConfirmarSenha(false);
  };

  handleErrorsNomeSocial = errorNomeSocial => {
    const error = this.state.error;
    error.errorNomeSocial = errorNomeSocial;
    this.setState({ error });
  }

  handleErrorsNomeFantasia = errorNomeFantasia => {
    const error = this.state.error;
    error.errorNomeFantasia = errorNomeFantasia;
    this.setState({ error });
  }

  handleErrorsRazaoSocial = errorRazaoSocial => {
    const error = this.state.error;
    error.errorRazaoSocial = errorRazaoSocial;
    this.setState({ error });
  }

  handleErrorsOptanteSimples = errorOptanteSimples => {
    const error = this.state.error;
    error.errorOptanteSimples = errorOptanteSimples;
    this.setState({ error });
  }

  handleErrorsTelefone1 = (state, message) => {
    const error = this.state.error;
    error.errorTelefone1 = { state, message };
    this.setState({ error });
  }

  handleErrorsTelefone2 = (errorTelefone2) => {
    const error = this.state.error;
    error.errorTelefone2 = errorTelefone2;
    this.setState({ error });
  }

  handleErrorsEstado = errorEstado => {
    const error = this.state.error;
    error.errorEstado = errorEstado;
    this.setState({ error });
  }

  handleErrorsCidade = errorCidade => {
    const error = this.state.error;
    error.errorCidade = errorCidade;
    this.setState({ error });
  }

  handleErrorsCEP = errorCEP => {
    const error = this.state.error;
    error.errorCEP = errorCEP;
    this.setState({ error });
  }

  handleErrorsBairro = errorBairro => {
    const error = this.state.error;
    error.errorBairro = errorBairro;
    this.setState({ error });
  }

  handleErrorsEndereco = errorEndereco => {
    const error = this.state.error;
    error.errorEndereco = errorEndereco;
    this.setState({ error });
  }

  handleErrorsMicroempresa = errorMicroempresa => {
    const error = this.state.error;
    error.errorMicroempresa = errorMicroempresa;
    this.setState({ error });
  }

  handleErrorsRetemICMS = errorRetemICMS => {
    const error = this.state.error;
    error.errorRetemICMS = errorRetemICMS;
    this.setState({ error });
  }

  handleErrorsSegmentos = errorSegmentos => {
    const error = this.state.error;
    error.errorSegmentos = errorSegmentos;
    this.setState({ error });
  }

  handleErrorsSenha = errorSenha => {
    const error = this.state.error;
    error.errorSenha = errorSenha;
    this.setState({ error });
  }

  handleErrorsConfirmarSenha = errorConfirmarSenha => {
    const error = this.state.error;
    error.errorConfirmarSenha = errorConfirmarSenha;
    this.setState({ error });
  }

  validateForm(success) {
    let errors_temp = false;

    let formData = {
      token: null,
      senha: null,
    };

    if (this.isTipoConvite()) {
      formData = {
        ...formData,
        usuarioParaRegistrar: null,
      };

      if (this.usuarioTipo === USER_TYPE.CONVITE.COLABORADOR) {
        const { nomeSocial } = this.state.person;

        if (!nomeSocial || !nomeSocial.length) {
          this.handleErrorsNomeSocial(true);
          errors_temp = true;
        }

        formData.usuarioParaRegistrar = {
          ...new PersonEmployeeNew(this.state.person),
        };
      }

      if (this.usuarioTipo === USER_TYPE.CONVITE.REVENDA) {
        const dataValidate = this.state.person;

        if (!dataValidate.razaoSocial || !dataValidate.razaoSocial.length) {
          this.handleErrorsRazaoSocial(true);
          errors_temp = true;
        }

        if (!dataValidate.nomeFantasia || !dataValidate.nomeFantasia.length) {
          this.handleErrorsNomeFantasia(true);
          errors_temp = true;
        }

        if (!dataValidate.estado || !dataValidate.estado.length) {
          this.handleErrorsEstado(true);
          errors_temp = true;
        }

        if (!dataValidate.cidade || !dataValidate.cidade.length) {
          this.handleErrorsCidade(true);
          errors_temp = true;
        }

        if (!dataValidate.cep || !dataValidate.cep.length) {
          this.handleErrorsCEP(true);
          errors_temp = true;
        }

        if (!dataValidate.endereco || !dataValidate.endereco.length) {
          this.handleErrorsEndereco(true);
          errors_temp = true;
        }

        if (!dataValidate.bairro || !dataValidate.bairro.length) {
          this.handleErrorsBairro(true);
          errors_temp = true;
        }

        let telefone1_temp = false;
        if (!dataValidate.telefone1) {
          this.handleErrorsTelefone1(true, 'Campo obrigatório');
          errors_temp = true;
          telefone1_temp = true;
        }

        if (!telefone1_temp) {
          if (dataValidate.telefone1 && !validePhone(dataValidate.telefone1)) {
            this.handleErrorsTelefone1(true, 'Digite um número válido');
            errors_temp = true;
          }
        }

        if (dataValidate.telefone2 && !validePhone(dataValidate.telefone2)) {
          this.handleErrorsTelefone2(true);
          errors_temp = true;
        }

        if (![true, false].includes(dataValidate.optanteSimples)) {
          this.handleErrorsOptanteSimples(true);
          errors_temp = true;
        }

        if (![true, false].includes(dataValidate.microempresa)) {
          this.handleErrorsMicroempresa(true);
          errors_temp = true;
        }

        if (![true, false].includes(dataValidate.retemICMS)) {
          this.handleErrorsRetemICMS(true);
          errors_temp = true;
        }

        if (![true, false].includes(dataValidate.segmentos)) {
          this.handleErrorsSegmentos(true);
          errors_temp = true;
        }

        formData.usuarioParaRegistrar = {
          ...new PersonResaleNew(this.state.person),
        };
      }

      formData.usuarioParaRegistrar = {
        ...formData.usuarioParaRegistrar,
        id: this.state.personId,
        tipo: this.usuarioTipo,
        tipoPessoa: this.usuarioTipo === USER_TYPE.CONVITE.REVENDA
          ? 'JURÍDICA' : null,
        cep: onlyNumbers(formData.usuarioParaRegistrar.cep || ''),
        telefone1: onlyNumbers(formData.usuarioParaRegistrar.telefone1 || ''),
        telefone2: onlyNumbers(formData.usuarioParaRegistrar.telefone2 || ''),
      };
    }

    const { inputPassword, inputConfirmPassword } = this.state;

    if (!inputPassword.length && !inputConfirmPassword.length) {
      this.handleErrorsSenha(true);
      this.handleErrorsConfirmarSenha(true);
      errors_temp = true;
    }

    if (!inputPassword.length && inputConfirmPassword.length) {
      this.handleErrorsSenha(true);
      errors_temp = true;
    }

    if (errors_temp) {
      return;
    }

    if (!inputConfirmPassword.length) {
      toast('Por favor, confirme a senha!', { type: toast.TYPE.ERROR });
      return;
    }

    if (inputPassword !== inputConfirmPassword) {
      toast('Confirmação de senha inválida.', { type: toast.TYPE.ERROR });
      return;
    }

    const passwordChecked = passwordCheck(inputPassword);

    if (!passwordChecked.success) {
      toast(passwordChecked.message, { type: toast.TYPE.ERROR });

      const attemptsKey = this.state.passwordAttempts.key;
      const attempts = parseInt(localStorage.getItem(attemptsKey) || 0) + 1;

      localStorage.setItem(attemptsKey, attempts);

      if (attempts >= this.state.passwordAttempts.maximum) {
        this.setButtonBlockedAfterAttempts(true);

        let limit = this.state.passwordAttempts.time / 1000;
        const interval = setInterval(() => {
          if (limit === 1) {
            this.setButtonBlockedAfterAttempts(false);
            localStorage.setItem(attemptsKey, 0);
            clearInterval(interval);
            return;
          }

          limit = limit - 1;

          this.setCountdownBlockedAfterAttempts(`(${limit}s)`);
        }, 1000);
      }

      return;
    }
    this.setButtonBlockedAfterAttempts(false);
    localStorage.setItem(this.state.passwordAttempts.key, 0);

    formData.token = this.token;
    formData.senha = inputPassword;

    success(formData);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.validateForm(async (formData) => {
      this.setLoading(true);
      try {
        let result = null;
        if (this.isTipoConvite()) {
          result = await InvitationService
            .createPersonByInvitation(formData);
        } else {
          result = await InvitationService
            .createPersonByRegister(formData);
        }
        toast(result.message, { type: toast.TYPE.SUCCESS });
        RECONNECT_USER(this.history);
      } catch (error) {
        showMessageError(error, 'Erro ao efetuar essa operação.');
      } finally {
        this.setLoading(false);
      }
    });
  };

  render() {
    const { usuarioTipo } = this;

    let titleType = 'Convite';

    if (usuarioTipo === USER_TYPE.CONVITE.COLABORADOR) {
      titleType = 'Convite Colaborador';
    }

    if (usuarioTipo === USER_TYPE.CONVITE.REVENDA) {
      titleType = 'Convite Revenda';
    }

    return (
      <S.ContainerBackground>
        <Modal show={this.state.loading} loading />
        <S.Header>
          <img
            src={MarcaReduzidaNegativa}
            alt="Marca Reduzida Casa Magalhães"
            loading='lazy'
          />
          <span>PORTAL DE RELACIONAMENTO</span>
          <div />
        </S.Header>
        {!this.isLoading() && (
          <S.Container>
            {!this.isTipoConvite() && (
              <S.MarcaContentImg
                src={marcaPreferencialNegativa}
                alt="Marca Preferencial Negativa Casa Maganhães"
                loading='lazy'
              />
            )}
            <S.TitleContent>
              {this.isTipoConvite() ? titleType : 'Cadastro'} - Confirmação
            </S.TitleContent>
            {usuarioTipo === USER_TYPE.CONVITE.COLABORADOR && (
              <FormEmployee
                handleSubmit={this.handleSubmit}
                person={this.state.person}
                errors={this.state.error}
                imageName={this.state.imageName}
                resale={this.state.resaleEmployee}
                password={this.state.inputPassword}
                confirmPassword={this.state.inputConfirmPassword}
                handleInputNomeSocialChange={this.handleInputNomeSocialChange}
                handleInputTelefone1Change={this.handleInputTelefone1Change}
                handleInputTelefone2Change={this.handleInputTelefone2Change}
                handleSelectEstadoChange={this.handleSelectEstadoChange}
                handleInputCidadeChange={this.handleInputCidadeChange}
                handleInputCepChange={this.handleInputCepChange}
                handleInputEnderecoChange={this.handleInputEnderecoChange}
                handleInputBairroChange={this.handleInputBairroChange}
                handleInputPontoReferenciaChange={this.handleInputPontoReferenciaChange}
                handleInputComplementoChange={this.handleInputComplementoChange}
                setInputImagem={this.setInputImagem.bind(this)}
                handleInputPasswordChange={this.handleInputPasswordChange}
                handleInputConfirmPasswordChange={this.handleInputConfirmPasswordChange}
              />
            )}
            {usuarioTipo === USER_TYPE.CONVITE.REVENDA && (
              <FormResale
                handleSubmit={this.handleSubmit}
                person={this.state.person}
                errors={this.state.error}
                imageName={this.state.imageName}
                resale={this.state.resaleEmployee}
                password={this.state.inputPassword}
                confirmPassword={this.state.inputConfirmPassword}
                handleInputTelefone1Change={this.handleInputTelefone1Change}
                handleInputTelefone2Change={this.handleInputTelefone2Change}
                handleSelectEstadoChange={this.handleSelectEstadoChange}
                handleInputCidadeChange={this.handleInputCidadeChange}
                handleInputCepChange={this.handleInputCepChange}
                handleInputEnderecoChange={this.handleInputEnderecoChange}
                handleInputBairroChange={this.handleInputBairroChange}
                handleInputPontoReferenciaChange={this.handleInputPontoReferenciaChange}
                handleInputComplementoChange={this.handleInputComplementoChange}
                handleInputNomeFantasiaChange={this.handleInputNomeFantasiaChange}
                handleInputRazaoSocialChange={this.handleInputRazaoSocialChange}
                handleInputInscricaoEstadualChange={this.handleInputInscricaoEstadualChange}
                handleInputOptanteSimplesChange={this.handleInputOptanteSimplesChange}
                handleInputDataFundacaoChange={this.handleInputDataFundacaoChange}
                handleInputMicroempresaChange={this.handleInputMicroempresaChange}
                handleInputRetemICMSChange={this.handleInputRetemICMSChange}
                handleInputSegmentosChange={this.handleInputSegmentosChange}
                setInputImagem={this.setInputImagem.bind(this)}
                handleInputPasswordChange={this.handleInputPasswordChange}
                handleInputConfirmPasswordChange={this.handleInputConfirmPasswordChange}
              />
            )}
            {![
              USER_TYPE.CONVITE.COLABORADOR,
              USER_TYPE.CONVITE.REVENDA,
            ].includes(usuarioTipo)
              && (
                <FormRegister
                  handleSubmit={this.handleSubmit}
                  errors={this.state.error}
                  password={this.state.inputPassword}
                  confirmPassword={this.state.inputConfirmPassword}
                  handleInputPasswordChange={this.handleInputPasswordChange}
                  handleInputConfirmPasswordChange={
                    this.handleInputConfirmPasswordChange
                  }
                />)
            }
            <S.ButtonContainer>
              <button
                type="button"
                onClick={this.handleSubmit}
                disabled={this.state.buttonBlockedAfterAttempts}
                style={{
                  opacity: this.state.buttonBlockedAfterAttempts ? 0.5 : 1,
                  cursor: this.state.buttonBlockedAfterAttempts ? 'not-allowed' : 'pointer'
                }}
              >
                <span>CONFIRMAR CONVITE {this.state.buttonBlockedAfterAttempts && this.state.countdownBlockedAfterAttempts}</span>
              </button>
            </S.ButtonContainer>
          </S.Container>
        )}
      </S.ContainerBackground>
    );
  }
}

export default Invitation;
