import api, { endpoints } from '~/services/api';

class LicenseLogsService {
  static getLogsChave = async (params) => {
    const { data: response } = await api.post(
      endpoints.license.getLogsChave,
      params
    );
    return response.data;
  };

  static getLogsSystem = async (params) => {
    const { data: response } = await api.post(
      endpoints.license.getLogsSystem,
      params
    );
    return response.data;
  };
}
export default LicenseLogsService;
