import { Fragment } from 'react';
import styled from 'styled-components';

export const ViewerInput = Fragment;

export const TextareaHelper = styled.div`
  background-color: transparent;
  height: min-content !important;
  border: none;
  padding-left: 0;
  padding-right: 0;
  font-size: 12pt !important;
  color: var(--cm-primary);
  resize: none;
  margin-top: 10px;
`;

export const PrintableBodyWrapper = styled.div`
  @media print {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100vw;
    margin: 6%;
    color: var(--cm-primary);

    input {
      background-color: transparent;
      border: none;
      padding-left: 0;
      padding-right: 0;
      font-size: 12pt !important;
      color: var(--cm-primary);
    }

    input[type='radio'], input[type='checkbox'] {
      accent-color: var(--cm-primary);
    }

    textarea {
      display: none;
    }
  }

  display: none;

  form {
    background-color: none;
  }
`;

export const FormToPrint = styled.form``;

export const ViewerContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin-top: 20px;

  &.breakItemPage {
    break-inside: avoid !important;
    break-after: auto !important;
    break-before: always !important;
  }
`;

export const ViewerSoftwareModule = styled.div`
  display: flex;
  margin-bottom: 10px;
  color: var(--cm-primary);

  span + span {
    margin-left: 40px;
  }

  @media print {
    flex-direction: column;
    justify-content: flex-start;

    span {
      margin-left: 0px !important;
    }
  }
`;

export const ViewerImage = styled.div`
  display: flex;
  width: 100%;
  padding: 0 40px;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};

  img {
    height: 76px;
    resize: contain;
  }
`;

export const ViewerTitle1 = styled.div`
  display: flex;
  justify-content: center;
  color: var(--cm-primary);
  padding: 0 40px;

  h2 {
    text-align: center;
  }
`;

export const ViewerTitle2 = styled.div`
  color: var(--cm-primary);
`;

export const ViewerDescription = styled.p`
  color: #444444;
  margin-top: 10px;

  @media print {
    &.answer {
      margin: 0;
      height: auto;
    }
  }

  &.answer {
    margin: 10px 0;
    height: auto;
  }
`;

export const ViewerAnswerContent = styled.div`
  display: flex;
  flex-direction: ${({ isLong }) => isLong ? 'column' : 'row'};
  align-items: center;
  justify-content: flex-start;
`;

export const ViewerAnswerWithDescription = styled.div`
  display: flex;
  flex-direction: column;

  @media print {
    margin-bottom: 0 !important;

    input {
      margin-top: -10px !important;
    }
  }
`;

export const ViewerAnswer = styled.b`
  color: #444444;

  @media print {
    width: ${({ isLong }) => isLong ? '100%' : '50%'};
  }
`;

export const ViewerOptionsContainerContent = styled.div`
  margin-top: 10px;

  input[type='radio'],
  input[type='checkbox'] {
    width: 11px !important;
  }
`;

export const ViewerOptionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
`;

export const ViewerOption = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 25%;

  @media (max-width: 1050px) {
    width: 50%;
  }

  @media (max-width: 800px) {
    width: 50%;
  }

  @media (max-width: 600px) {
    width: 100%;
  }

  @media (max-width: 540px) {
    @media print {
      width: 33.3%;
    }
  }

  label {
    width: 100%;
    color: #444444;
  }
`;

export const ViewerOptionOther = styled.div`
  display: flex;
  align-items: center;
`;

export const ViewerOther = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  label {
    color: #444444;
    margin-right: 20px;
  }
`;
