import PropTypes from 'prop-types';
import React, { useState, useEffect, useMemo } from 'react';
import Modal from '~/components/Modal';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { Steps } from './Steps';
import { Stepper } from '../Items/Stepper';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { commercialPaths } from '~/routes/paths';
import ProductModalityService from '~/services/product-modality-service';
import ProductSoftwareService from '~/services/product-software-service';
import ProductModuleService from '~/services/product-module-service';
import PersonClientService from '~/services/person-client-service';
import { CommercialOrderService } from '~/services/commercial-order-service';
import { FormRegisterService } from '~/services/form-register-service';
import { PaymentsService } from '~/services/commercial/payment';
import { CommercialOrderDraft } from '~/model/commercial-order-draft';
import { FormGenerate } from '~/model/form-generate';
import {
  USER_TYPE,
  FORM_FILLED_ENUM,
  CALENDARIO_MESES,
  PERSON_STATUS_ENUM,
} from '~/util/domainutils';
import { handleMoveElement } from '~/util/scrollutils';
import { showMessageError } from '~/util/errorutils';
import { formatCpfCnpj } from '~/util/stringutils';
import permissions from '~/util/permissions';
import * as S from './styles';

function EditOrder({ history, location }) {
  const { PREENCHIDO, NAO_PREENCHIDO } = FORM_FILLED_ENUM;
  const OTHER_INPUT = { KEY: '_option_other', VALUE: 'Outro' };
  const ANSWERED_FORMS_STATUS = 'ANSWERED_FORMS';

  const user = useSelector(state => state.user.data);
  const userRevenda = user.revenda;
  const userCanEdit = user.revenda.comissionada;
  const userPermissions = user.permissions;
  const userCreateOrderId = user.id;

  const getUserRevendaId = () => {
    return (user.tipo === USER_TYPE.REVENDA) ? user.id : user.revenda.id;
  };

  const canShowAgainDialogModality = useSelector((state) => {
    return state.lifecycle.onShowAgainDialogModality;
  });

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [inputClienteValue, setInputValue] = useState('');
  const [orderId, setOrderId] = useState(null);
  const [clienteId, setClienteId] = useState(null);
  const [client, setClient] = useState({});
  const [selectedSoftware, setSelectedSoftware] = useState(null);
  const [selectedModality, setSelectedModality] = useState(null);
  const [softwares, setSoftwares] = useState([]);
  const [modules, setModules] = useState([]);
  const [modulesTotal, setModulesTotal] = useState([]);
  const [modalities, setModalities] = useState([]);
  const [items, setItems] = useState([]);

  const [activeSteps, setActiveSteps] = useState([
    'SELECT_CLIENT',
    'SELECT_PRODUCTS',
    'PAYMENT_METHODS',
    'COMPLETE_ORDER',
    // ANSWERED_FORMS_STATUS,
  ]);

  const [statusAtual, setStatusAtual] = useState(null);
  const [messagemOcorrencia, setMessagemOcorrencia] = useState('');
  const [mesInicio, setMesInicio] = useState(moment().add(1, 'month').month());
  const [formasPagamento, setFormasPagamento] = useState([]);
  const [planosPagamento, setPlanosPagamento] = useState([]);
  const [formaPagamentoId, setFormaPagamentoId] = useState(0);
  const [planoPagamentoId, setPlanoPagamentoId] = useState(0);
  const [carenciaAtivada, setCarenciaAtivada] = useState(false);
  const [periodoCarencia, setPeriodoCarencia] = useState(3);
  const [orderConfirmation, setOrderConfirmation] = useState(false);
  const [observation, setObservation] = useState('');

  const [forms, setForms] = useState([]);
  const [formularios, setFormularios] = useState([]);
  const [pessoasFormulario, setPessoasFormularios] = useState({});
  const [formCurrentIndex, setFormCurrentIndex] = useState(0);
  const [formCurrentId, setFormCurrentId] = useState(0);
  const [answerForms, setAnswerForms] = useState([]);
  const [answerFormsIds, setAnswerFormsIds] = useState([]);
  const [allFormsToAnswer, setAllFormsToAnswer] = useState([]);
  const [formCheckboxTemp, setFormCheckboxTemp] = useState([]);
  const [formsToAnswer, setFormsToAnswer] = useState([]);

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors },
  } = useForm({ defaultValues: {} });

  const [pedidoAntigo, setPedidoAntigo] = useState(null);

  const userCanViewSaveDraft = useMemo(
    () => userPermissions.includes(permissions['view_salvar-rascunho']),
    [userPermissions]
  );

  useEffect(() => {
    const { draftOrder, isDraft, idClient, idOrder } = location.state;

    if (!idClient || !idOrder) return history.goBack();

    setOrderId(idOrder);

    setLoading(true);
    Promise.all([
      ProductModalityService.getProductModalities({}),
      ProductSoftwareService.getProductSoftwaresComLogo(),
      PaymentsService.getGracePeriodPayment(),
      CommercialOrderService.getOrder(idOrder),
    ])
      .then((values) => {
        if (values.length) {
          const [
            temp_modalidades,
            temp_softwares,
            temp_carencia,
            temp_order,
          ] = values;

          let errorNotExist = false;
          if (!temp_modalidades.resultado.length) {
            errorNotExist = true;
            toast('Não existem modalidades cadastradas.', {
              type: toast.TYPE.ERROR,
            });
          }

          if (!temp_softwares.resultado.length) {
            errorNotExist = true;
            toast('Não existem softwares cadastradas.', {
              type: toast.TYPE.ERROR,
            });
          }

          if (!temp_carencia.length) {
            errorNotExist = true;
            toast('Não existe carência cadastradas.', {
              type: toast.TYPE.ERROR,
            });
          }

          if (errorNotExist) {
            handleStopBuildOrder();
            return;
          }

          setSoftwares(orderBy([...temp_softwares.resultado], 'ordem'));
          setModalities([...temp_modalidades.resultado]);
          setCarenciaAtivada(temp_carencia[0].value === 'true');
          setPeriodoCarencia(Number(temp_carencia[1].value));

          return [
            temp_order,
            [...temp_softwares.resultado],
            [...temp_modalidades.resultado],
          ];
        } else {
          handleStopBuildOrder();
        }
      })
      .then((values) => {
        if (!values.length) return handleStopBuildOrder();

        const [orderT, softwaresT, modalidadesT] = values;

        if (isDraft) {
          const draftJson = JSON.parse(draftOrder);
          const draftTemp = new CommercialOrderDraft(draftJson);

          handleBuildOrderDraft(draftTemp, modalidadesT, softwaresT);
        } else {
          if (!(orderT && !isDraft)) return handleStopBuildOrder();

          handleBuildOrderToEdit(orderT, modalidadesT, softwaresT);
        }
      })
      .catch(() => {
        toast('Erro ao consultar dados para montagem do pedido.', {
          type: toast.TYPE.ERROR,
        });

        setTimeout(() => handleStopBuildOrder(), 100); // 0.010 segundos
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   *    CONTRUIR PEDIDO PARA EDIÇÃO
  */

  const NOT_IS_DRAFT = () => !location.state.isDraft;
  const IS_DRAFT = () => location.state.isDraft;

  const renderClientValue = (orderData) => {
    const cpfCnpj = formatCpfCnpj(orderData.cpfCnpj);
    const nomeFantasia = formatCpfCnpj(orderData.nomeFantasia);
    return `${cpfCnpj} - ${nomeFantasia}`;
  };

  function handleStopBuildOrder() {
    if (IS_DRAFT()) {
      return history.push(commercialPaths.commercialOrder);
    }

    return history.push(
      commercialPaths.managementCommercialOrder,
      { id: orderId }
    );
  }

  function setClientOrder(cliente, clienteId) {
    setClient(cliente);
    setClienteId(clienteId);
    setInputValue(renderClientValue(cliente));
  }

  async function handleBuildOrderDraft(draftT, modalidadesT, softwaresT) {
    try {
      const temp_orderDraft = draftT;
      const temp_softwares = softwaresT;
      const temp_modalidades = modalidadesT;
      const clienteId = temp_orderDraft.clienteId;

      const personCliente = await PersonClientService.getPersonClient(clienteId);
      setClientOrder(personCliente, clienteId);

      const temp_modalidadeId = temp_orderDraft.modalityId;
      const isModalityId = temp_modalidadeId !== 0;
      if (isModalityId) {
        setSelectedModality(find(temp_modalidades, {
          id: temp_modalidadeId,
        }));

        const { resultado: modulesTotal } = await ProductModuleService
          .getProductModules({ modalidadeId: temp_modalidadeId });

        setModulesTotal(orderBy(modulesTotal, 'posicaoEmSoftware'));
      }

      let isSoftwareId = null;
      let softwareIdTemp = null;
      if (temp_orderDraft.softwareId.length > 0) {
        if (typeof temp_orderDraft.softwareId === 'object') {
          isSoftwareId = temp_orderDraft.softwareId[0] !== 0;
          softwareIdTemp = temp_orderDraft.softwareId[0];
        } else {
          isSoftwareId = temp_orderDraft.softwareId !== 0;
          softwareIdTemp = temp_orderDraft.softwareId;
        }

        if (isSoftwareId) {
          setSelectedSoftware(find(temp_softwares, {
            id: softwareIdTemp,
          }));
        }
      }

      if (isModalityId && isSoftwareId) {
        const softwaresId = [];
        const requestModules = [];
        temp_orderDraft.softwareId.forEach((softwareId) => {
          if (!softwaresId.includes(softwareId)) {
            softwaresId.push(softwareId);
            requestModules.push(
              ProductModuleService.getProductModules({
                softwareId, modalidadeId: temp_modalidadeId,
              })
            );
          }
        });

        const allModules = await Promise.all(requestModules);
        const { resultado: modulesFilted } = allModules[0];

        setModules(orderBy(modulesFilted, 'posicaoEmSoftware'));
      }

      if (temp_orderDraft.items.length > 0) {
        setItems(temp_orderDraft.items);
      }

      const isFormsToAnswer = () => {
        const { allFormsToAnswer } = temp_orderDraft.formularios;
        return (allFormsToAnswer || []).some((_) => _.exist === true);
      };

      if (isFormsToAnswer()) {
        const {
          allFormsToAnswer,
          answerForms,
          answerFormsIds,
          formCurrentId,
          formCurrentIndex,
          forms,
          formsToAnswer,
        } = temp_orderDraft.formularios;

        const reqGetAllForms = forms.map((item) => {
          return FormRegisterService.getForm(item.idForm);
        });

        const formatAttrName = (uuid, attrName) => `${uuid}---${attrName}`;

        const checkboxTemp = [];
        const formulariosTemp = [];

        const resGetAllForms = await Promise.all(reqGetAllForms);
        if (resGetAllForms.length) {
          const getFormDataByIndex = (uuid, index) => {
            const { defaultValues } = JSON.parse(answerForms[index].extraInfo);

            const formDataTemp = {};
            for (const keyItem in defaultValues) {
              const key = formatAttrName(uuid, keyItem);
              formDataTemp[key] = defaultValues[keyItem];
            }

            return formDataTemp;
          };

          (resGetAllForms || []).forEach((item, index) => {
            const [formResponse] = item.resultado;

            const formGenerate = new FormGenerate(
              formResponse, clienteId, getUserRevendaId(),
            );

            const [
              formViewer, contentItemsFormAnswer,
            ] = formGenerate.getFormViewerAnswer();

            const dadosFormulario = {
              index,
              formViewer,
              contentItemsFormAnswer,
              formId: formResponse.id,
              formData: getFormDataByIndex(formViewer.uuid, index),
            };

            const valuesDataTemp = [];
            for (const keyItem in dadosFormulario.formData) {
              if (typeof dadosFormulario.formData[keyItem] === 'object') {
                valuesDataTemp.push({
                  inputName: keyItem,
                  hasNoValue: false,
                });
              }
            }

            formulariosTemp.push(dadosFormulario);
            checkboxTemp.push({
              formId: formResponse.id,
              valuesData: valuesDataTemp,
            });
          });
        }

        const resPersonForms = await FormRegisterService.getPeopleFormsById(
          clienteId, personCliente.revendaId,
        );

        setForms(forms);
        setFormularios(formulariosTemp);
        setPessoasFormularios(resPersonForms);
        setFormCurrentIndex(formCurrentIndex);
        setFormCurrentId(formCurrentId);
        setAnswerForms(answerForms);
        setAnswerFormsIds(answerFormsIds);
        setAllFormsToAnswer(allFormsToAnswer);
        setFormCheckboxTemp(checkboxTemp);
        setFormsToAnswer(formsToAnswer);
        setActiveSteps((oldSteps) => [...oldSteps, ANSWERED_FORMS_STATUS]);
      }

      if (temp_orderDraft.mesInicio < 0 && temp_orderDraft.mesInicio > 11) {
        setMesInicio(temp_orderDraft.mesInicio);
      }

      const resPaymentTypes = await CommercialOrderService.getPaymentTypes();

      const temp_data = {
        formaPagamentoId: temp_orderDraft.formaPagamentoId ?? 0,
        planoPagamentoId: temp_orderDraft.planoPagamentoId ?? 0,
        formasPagamento: resPaymentTypes,
        planosPagamento: [],
        forms: forms.length ? forms : [],
      };

      const [formaPagamento] = resPaymentTypes.filter(
        (forma) => forma.id === temp_orderDraft.formaPagamentoId,
      );

      if (formaPagamento) {
        const temp_data_planosPagamento = formaPagamento.formaPlanoPagamentos
          ? formaPagamento.formaPlanoPagamentos
          : [];

        temp_data.planosPagamento = temp_data_planosPagamento;
      }

      setFormasPagamento(temp_data.formasPagamento);
      setPlanosPagamento(temp_data.planosPagamento);
      setFormaPagamentoId(temp_data.formaPagamentoId);
      setPlanoPagamentoId(temp_data.planoPagamentoId);

      setObservation(temp_orderDraft.obs ?? '');

      setActiveStep(temp_orderDraft.activeStep);
    } catch (error) {
      new Error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleBuildOrderToEdit(orderT, modalidadesT, softwaresT) {
    try {
      const temp_order = orderT;
      const temp_softwares = softwaresT;
      const temp_modalidades = modalidadesT;

      setClientOrder(temp_order.cliente, temp_order.clienteId);

      const itemPedido = temp_order.itemPedidos[0];
      const temp_modalidadeId = itemPedido.modulo.modalidadeId;

      const softwaresId = [];
      const requestModules = [];
      temp_order.itemPedidos.forEach((item) => {
        if (!softwaresId.includes(item.softwareId)) {
          softwaresId.push(item.softwareId);
          requestModules.push(
            ProductModuleService.getProductModules({
              softwareId: item.softwareId,
              modalidadeId: temp_modalidadeId,
            })
          );
        }
      });

      const modules = await Promise.all([
        ProductModuleService.getProductModules({
          modalidadeId: temp_modalidadeId,
        }),
        ...requestModules
      ]);

      const { resultado: modulesTotal } = modules[0];
      const allModules = modules.slice(1, modules.length);
      const { resultado: modulesFilted } = allModules[0];
      const temp_softwareId = modulesFilted[0].softwareId;

      setSelectedModality(find(temp_modalidades, { id: temp_modalidadeId }));
      setSelectedSoftware(find(temp_softwares, { id: temp_softwareId }));

      setModules(orderBy(modulesFilted, 'posicaoEmSoftware'));
      setModulesTotal(orderBy(modulesTotal, 'posicaoEmSoftware'));

      const itemTemp = [];
      temp_order.itemPedidos.forEach((item) => {
        itemTemp.push({
          ...find(modulesTotal, { id: item.moduloId }),
          precoAdesao: item.valorAdesaoUnidade,
          precoMensal: item.valorMensalUnidade,
          count: item.quantidade,
        });
      });

      setItems(itemTemp);

      if (temp_order.forms.length) {
        const itemModulesId = temp_order.itemPedidos.map((_) => _.moduloId);

        const formsTemp = [];
        temp_order.forms.forEach((_) => {
          if (itemModulesId.includes(_.form.moduleId)) {
            formsTemp.push({
              formId: _.formId,
              moduleId: _.form.moduleId,
            });
          }
        });

        const formulariosResponder = [];
        itemModulesId.forEach((id) => {
          const formIdsTemp = formsTemp.filter((_) => _.moduleId === id);

          if (!formIdsTemp.length) {
            formulariosResponder.push({
              moduleId: id,
              formsId: [],
              exist: false,
            });
          } else {
            formulariosResponder.push({
              moduleId: id,
              formsId: formIdsTemp.map((_) => _.formId),
              exist: true,
            });
          }
        });

        setAllFormsToAnswer(formulariosResponder);

        const IS_EXIST_FORMS = formulariosResponder.some((_) => _.exist === true);

        if (IS_EXIST_FORMS) {
          const requestsToGetForms = [];
          formulariosResponder.forEach((item) => {
            if (item.formsId.length) {
              item.formsId.forEach((id) => {
                requestsToGetForms.push(FormRegisterService.getFormsById(id));
              });
            }
          });

          const responseForms = await Promise.all(requestsToGetForms);

          const getFormsTemp = [];
          responseForms.forEach((item) => {
            const [form] = (item.resultado || []);

            if (form) {
              getFormsTemp.push({
                idForm: form.id,    // ID do formulário
                idAnswerForm: null, // ID da resposta do formulário
                formName: form.name,
                moduleName: form.moduleName,
                status: PREENCHIDO,
              });
            }
          });

          setForms(getFormsTemp);
          const formDataObject = temp_order.forms[0];

          const resPersonForms = await FormRegisterService.getPeopleFormsById(
            formDataObject.clientId, formDataObject.resellerId,
          );

          setPessoasFormularios(resPersonForms);

          const reqGetAllForms = getFormsTemp.map((item) => {
            return FormRegisterService.getForm(item.idForm);
          });

          const resGetAllForms = await Promise.all(reqGetAllForms);

          const checkboxTemp = [];
          const formulariosTemp = [];

          const formatAttrName = (uuid, attrName) => `${uuid}---${attrName}`;

          if (resGetAllForms.length) {
            const getFormDataByIndex = (uuid, formId) => {
              const formObj = temp_order.forms.find(_ => _.formId === formId);

              if (formObj) {
                const { defaultValues } = JSON.parse(formObj.extraInfo);

                const formDataTemp = {};
                for (const keyItem in defaultValues) {
                  const key = formatAttrName(uuid, keyItem);
                  formDataTemp[key] = defaultValues[keyItem];
                }

                return formDataTemp;
              }

              return {};
            };

            (resGetAllForms || []).forEach((item, index) => {
              const [formResponse] = item.resultado;

              const formGenerate = new FormGenerate(
                formResponse, clienteId, getUserRevendaId(),
              );

              const [
                formViewer, contentItemsFormAnswer,
              ] = formGenerate.getFormViewerAnswer();

              const dadosFormulario = {
                index,
                formViewer,
                contentItemsFormAnswer,
                formId: formResponse.id,
                formData: getFormDataByIndex(formViewer.uuid, formResponse.id),
              };

              const valuesDataTemp = [];
              for (const keyItem in dadosFormulario.formData) {
                if (typeof dadosFormulario.formData[keyItem] === 'object') {
                  valuesDataTemp.push({
                    inputName: keyItem,
                    hasNoValue: false,
                  });
                }
              }

              formulariosTemp.push(dadosFormulario);
              checkboxTemp.push({
                formId: formResponse.id,
                valuesData: valuesDataTemp,
              });
            });

            if (formulariosTemp.length) {
              const answersTemp = [];
              formulariosTemp.forEach((item) => {
                const formDataValues = {};

                for (const keyItem in item.formData) {
                  if (keyItem.includes(item.formViewer.uuid)) {
                    const keyValue = keyItem.replace(
                      `${item.formViewer.uuid}---`, '',
                    );

                    if (typeof item.formData[keyItem] === 'string') {
                      formDataValues[keyValue] = item.formData[keyItem].trim();
                    }

                    if (typeof item.formData[keyItem] === 'object') {
                      formDataValues[keyValue] = item.formData[keyItem];
                    }
                  }
                }

                const dataAnswerContent = {
                  idForm: item.formId,
                  formName: item.formViewer.formName,
                  extraInfo: JSON.stringify({
                    defaultValues: formDataValues,
                    attrsName: item.contentItemsFormAnswer.map(
                      (input) => input.answerContent.attrName,
                    )
                  }),
                  dataAnswer: item.contentItemsFormAnswer.map(
                    (input) => ({
                      itemFormId: input.itemFormId,
                      answerContent: JSON.stringify(
                        item.formData[formatAttrName(
                          item.formViewer.uuid,
                          input.answerContent.attrName,
                        )]
                      ),
                    })
                  ),
                };

                answersTemp.push(dataAnswerContent);
              });

              setAnswerForms(answersTemp);
            }

            setFormularios(formulariosTemp);
            setFormCheckboxTemp(checkboxTemp);
            reset(formulariosTemp[0].formData);
            setAnswerFormsIds(formulariosTemp.map((_) => _.formId));
          }

          setActiveSteps((oldSteps) => [...oldSteps, ANSWERED_FORMS_STATUS]);
        }
      }

      const resPaymentTypes = await CommercialOrderService.getPaymentTypes();

      const temp_data = {
        formaPagamentoId: temp_order.formaPagamentoId ?? 0,
        planoPagamentoId: temp_order.planoPagamentoId ?? 0,
        formasPagamento: resPaymentTypes,
        planosPagamento: [],
        forms: forms.length ? forms : [],
      };

      const [formaPagamento] = resPaymentTypes.filter(
        (forma) => forma.id === temp_order.formaPagamentoId,
      );

      if (formaPagamento) {
        const temp_data_planosPagamento = formaPagamento.formaPlanoPagamentos
          ? formaPagamento.formaPlanoPagamentos
          : [];

        temp_data.planosPagamento = temp_data_planosPagamento;
      }

      setMesInicio(temp_order.mesInicio);
      setFormasPagamento(temp_data.formasPagamento);
      setPlanosPagamento(temp_data.planosPagamento);
      setFormaPagamentoId(temp_data.formaPagamentoId);
      setPlanoPagamentoId(temp_data.planoPagamentoId);
      setObservation(temp_order.obs);
      setStatusAtual(temp_order.statusAtual.status);

      setPedidoAntigo({ ...temp_order });
    } catch (error) {
      new Error(error);
    } finally {
      setLoading(false);
    }
  }

  /**
   *    SELECIONE O CLIENTE
   */

  function handleSelectClientSubmit() {
    const { BLOCKED } = PERSON_STATUS_ENUM;

    if (clienteId === null) {
      toast('Escolha um cliente é obrigatório!', { type: toast.TYPE.ERROR });
      return;
    }

    if (client.status === BLOCKED.value) {
      toast('Cliente bloqueado!', { type: toast.TYPE.ERROR });
      return;
    }

    setActiveStep(1);
  }

  /**
   *    SELECIONE OS PRODUTOS
   */

  function HANDLE_ACTIVE_STEP_FORM() {
    return activeSteps.includes(ANSWERED_FORMS_STATUS);
  }

  async function handleCanMoveToFillForms() {
    const formsId = [];

    allFormsToAnswer.map((item) => formsId.push(...item.formsId));

    if (!formsId.length) return;

    try {
      const IS_CHECKED_PEOPLE_FORM = () => {
        const clientChanged = pessoasFormulario?.cliente?.id !== clienteId;
        const hasPeople = ![...Object.keys(pessoasFormulario)].length;

        return hasPeople || clientChanged;
      };

      if (IS_CHECKED_PEOPLE_FORM()) {
        const resPersonForms = await FormRegisterService.getPeopleFormsById(
          clienteId, getUserRevendaId()
        );

        setPessoasFormularios(resPersonForms);
      }

      const formulariosTemp = [...formularios];
      const formulariosExistentes = formulariosTemp.map((item) => item.formId);

      const reqGetAllForms = [];
      formsId.forEach((id) => {
        if (!formulariosExistentes.includes(id)) {
          reqGetAllForms.push(FormRegisterService.getForm(id));
        }
      });

      if (reqGetAllForms.length) {
        const resGetAllForms = await Promise.all(reqGetAllForms);

        const checkboxTemp = [...formCheckboxTemp];
        const idsTemp = formulariosTemp.map((_) => _.formId);
        const indexTemp = formulariosTemp.map((_, index) => index);

        (resGetAllForms || []).forEach((item, index) => {
          const [formResponse] = item.resultado;

          const formGenerate = new FormGenerate(
            formResponse, clienteId, getUserRevendaId(),
          );

          const [
            formViewer, contentItemsFormAnswer,
          ] = formGenerate.getFormViewerAnswer();

          if (!idsTemp.includes(formResponse.id)) {
            const dadosFormulario = {
              formViewer,
              index: indexTemp.length + index,
              formId: formResponse.id,
              contentItemsFormAnswer,
              formData: {},
            };

            formulariosTemp.push(dadosFormulario);
            checkboxTemp.push({
              formId: formResponse.id,
              valuesData: [],
            });
          }
        });

        setFormularios(formulariosTemp);
        setFormCheckboxTemp(checkboxTemp);
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setActiveStep(2);
      setFormCurrentIndex(0);
      setTimeout(() => setLoading(false), 200); // 0.2 segundos
    }
  }

  async function handleCanMoveToPaymentMethods() {
    const resPaymentTypes = await CommercialOrderService.getPaymentTypes();

    if (resPaymentTypes.length) {
      const temp_data = {
        formaPagamentoId: formaPagamentoId ?? 0,
        planoPagamentoId: planoPagamentoId ?? 0,
        formasPagamento: resPaymentTypes,
        planosPagamento: [],
        forms: forms.length ? forms : [],
      };

      setFormasPagamento(temp_data.formasPagamento);
      setPlanosPagamento(temp_data.planosPagamento);
      setFormaPagamentoId(temp_data.formaPagamentoId);
      setPlanoPagamentoId(temp_data.planoPagamentoId);
      setActiveStep(3);
      setTimeout(() => setLoading(false), 200); // 0.2 segundos
    } else {
      setFormasPagamento([]);
      toast.error('Não encontramos formas de pagamento!');
      setTimeout(() => setLoading(false), 200); // 0.2 segundos
      handleStopBuildOrder();
      return;
    }
  }

  function validateItensPedidoForm(success) {
    if (!items || !items.length) {
      toast('Adicione pelo menos um módulo no sistema para o carrinho.', {
        type: toast.TYPE.ERROR,
      });

      return;
    }

    success();
  }

  async function handleItensPedidoSubmit() {
    validateItensPedidoForm(async () => {
      setLoading(true);
      try {
        if (HANDLE_ACTIVE_STEP_FORM()) {
          const requestsToGetForms = [];

          allFormsToAnswer.forEach((item) => {
            if (item.formsId.length) {
              item.formsId.forEach((id) => {
                requestsToGetForms.push(FormRegisterService.getFormsById(id));
              });
            }
          });

          const responseForms = await Promise.all(requestsToGetForms);

          const getFormsTemp = [];
          responseForms.forEach((item) => {
            const form = (item.resultado ?? []);

            if (form.length) {
              const isAnswered = !!find(answerForms, { idForm: form[0].id });

              getFormsTemp.push({
                idForm: form[0].id, // ID do formulário
                idAnswerForm: null, // ID da resposta do formulário
                formName: form[0].name,
                moduleName: form[0].moduleName,
                status: isAnswered ? PREENCHIDO : NAO_PREENCHIDO
              });
            }
          });

          setForms(getFormsTemp);
          handleCanMoveToFillForms();
        } else {
          handleCanMoveToPaymentMethods();
        }
      } catch (error) {
        showMessageError(error);
      }
    });
  }

  /**
   *    PREENCHA OS FORMULÁRIOS
   */

  function handleValuesFormulariosChange(formData, index) {
    const formulariosTemp = [...formularios];
    formulariosTemp[index].formData = formData;
    reset(formData);
    setFormularios(formulariosTemp);
  }

  function validateRequiredFields(updatedFormData, success) {
    const [oldItem] = formularios.filter((_) => _.formId === formCurrentId);
    let formOK = false;

    if (oldItem) {
      for (const keyItem in updatedFormData) {
        if (typeof oldItem.formData[keyItem] === 'string') {
          oldItem.formData[keyItem] = (updatedFormData[keyItem] ?? '').trim();
        } else {
          oldItem.formData[keyItem] = updatedFormData[keyItem];
        }
      }

      const formatAttrName = (item, form) => {
        return `${item.formViewer.uuid}---${form.answerContent.attrName}`;
      };

      const itemsAnswerRequired = [];
      oldItem.contentItemsFormAnswer.forEach((form) => {
        if (form.required) {
          itemsAnswerRequired.push(formatAttrName(oldItem, form));
        }
      });

      const unfilledFields = [];
      for (const key in oldItem.formData) {
        const keyWithoutOther = key.replace(OTHER_INPUT.KEY, '');

        if (itemsAnswerRequired.includes(keyWithoutOther)) {
          let valueData = oldItem.formData[key];

          if (valueData && valueData.length > 0) {
            unfilledFields.push({ key, filled: true });
          } else {
            const IS_INCLUDES_OTHER = () => {
              let keyOther = key;

              if (key.includes(OTHER_INPUT.KEY)) {
                keyOther = key.replace(OTHER_INPUT.KEY, '');
              } else {
                return false;
              }

              const valueDataOther = oldItem.formData[keyOther];

              if (!(valueDataOther && valueDataOther.length > 0)) return false;

              if (typeof valueDataOther === 'object') {
                const IS_OTHER_CHECK = () => {
                  if (!valueDataOther.includes(OTHER_INPUT.VALUE)
                    && (valueData && (
                      valueData.length === 0 || valueData === ''
                    ))
                  ) {
                    return true;
                  }

                  return valueDataOther.includes(OTHER_INPUT.VALUE)
                    && (valueData && valueData.length > 0);
                };

                if (IS_OTHER_CHECK()) return true;

                return !valueDataOther.includes(OTHER_INPUT.VALUE);
              }

              return valueDataOther !== OTHER_INPUT.VALUE;
            };

            if (IS_INCLUDES_OTHER()) {
              unfilledFields.push({ key, filled: true });
            } else {
              unfilledFields.push({ key, filled: false });
            }
          }
        }
      }

      const IS_FIND_ANSWER = !answerForms.find((_) => _.idForm === formCurrentId);

      const IS_FILLED_FIELDS = () => {
        return unfilledFields.every((_) => _.filled === true) && IS_FIND_ANSWER;
      };

      if (IS_FILLED_FIELDS()) {
        formOK = true;
        setAnswerFormsIds((oldAnswer) => [
          ...new Set([...oldAnswer, formCurrentId])
        ]);
      } else {
        if (IS_FIND_ANSWER) {
          formOK = false;
          setAnswerFormsIds((oldAnswer) => [
            ...oldAnswer.filter((id) => id !== formCurrentId),
          ]);
        }
      }

      success(updatedFormData, oldItem.index, formOK);
    }

    success(updatedFormData, null, formOK);
  }

  function validateAnswerForm(formIndexOK, formOK, success) {
    const [formTemp] = formularios.filter((_, ind) => ind === formIndexOK);
    const filledIds = [...answerFormsIds];

    if (formTemp && !filledIds.includes(formTemp.formId) && formOK) {
      filledIds.push(formTemp.formId);
    }

    const answers = [];
    formularios.forEach((form) => {
      if (filledIds.includes(form.formId)) {
        for (const keyItem in form.formData) {
          if (typeof form[keyItem] === 'string') {
            form[keyItem] = form[keyItem].trim();
          }
        }

        const formatAttrName = (uuid, attrName) => {
          return `${uuid}---${attrName}`;
        };

        const formDataValues = {};
        for (const keyItem in form.formData) {
          if (keyItem.includes(form.formViewer.uuid)) {
            const keyValue = keyItem.replace(
              `${form.formViewer.uuid}---`, '',
            );

            if (typeof form.formData[keyItem] === 'string') {
              formDataValues[keyValue] = form.formData[keyItem].trim();
            }

            if (typeof form.formData[keyItem] === 'object') {
              formDataValues[keyValue] = form.formData[keyItem];
            }
          }
        }

        const dataAnswerContent = {
          idForm: form.formId,
          formName: form.formViewer.formName,
          extraInfo: JSON.stringify({
            defaultValues: formDataValues,
            attrsName: form.contentItemsFormAnswer.map(
              (input) => input.answerContent.attrName,
            )
          }),
          dataAnswer: form.contentItemsFormAnswer.map(
            (input) => ({
              itemFormId: input.itemFormId,
              answerContent: JSON.stringify(
                form.formData[formatAttrName(
                  form.formViewer.uuid,
                  input.answerContent.attrName,
                )]
              ),
            })
          ),
        };

        answers.push(dataAnswerContent);
      }
    });

    setAnswerForms(answers);

    let formsUpdated = [...forms];
    formsUpdated = formsUpdated.map((form) => {
      return filledIds.includes(form.idForm)
        ? { ...form, status: PREENCHIDO }
        : { ...form };
    });

    setForms(formsUpdated);
    success(formsUpdated);
  }

  function validateFillForms(formsFilleds, success) {
    const indexErrors = [];
    let error_forms = false;

    formsFilleds.forEach((form, index) => {
      if (form.status === NAO_PREENCHIDO) {
        indexErrors.push(index + 1);
        error_forms = true;
      }
    });

    if (error_forms) {
      switch (true) {
        case (indexErrors.length === formsFilleds.length) && indexErrors.length > 1:
          toast(
            'Preencha todos os formulários corretamente',
            { type: toast.TYPE.ERROR }
          );
          break;
        case indexErrors.length === 1:
          toast(
            `O formulário ${indexErrors[0]} precisa ser preenchido corretamente`,
            { type: toast.TYPE.ERROR }
          );
          break;
        case indexErrors.length === 2:
          toast(
            `Os formulários ${indexErrors.join(' e ')} precisam ser`
            + ' preenchidos corretamente',
            { type: toast.TYPE.ERROR }
          );
          break;
        default:
          toast(
            `Os formulários ${indexErrors.join(', ')} precisam ser`
            + ' preenchidos corretamente',
            { type: toast.TYPE.ERROR }
          );
          break;
      }
      return;
    }

    success();
  }

  const handleFillFormsSubmit = (formDataCurrent) => {
    validateRequiredFields(
      formDataCurrent, (formDataChecked, formDataIndex, formDataFilled) => {
        if (![null, undefined].includes(formDataIndex)) {
          handleValuesFormulariosChange(formDataChecked, formDataIndex);
          validateAnswerForm(formDataIndex, formDataFilled, (formsFilleds) => {
            validateFillForms(formsFilleds, async () => {
              setLoading(true);
              try {
                await handleCanMoveToPaymentMethods();
              } catch (error) {
                showMessageError(error);
              } finally {
                setLoading(false);
              }
            });
          });
        }
      }
    );
  };

  const onGetFormData = (formDataCurrent, formDataIndex) => {
    validateRequiredFields(formDataCurrent, (formDataChecked) => {
      handleValuesFormulariosChange(formDataChecked, formDataIndex);
    });
  };

  /**
   *    ESCOLHA A FORMA DE PAGAMENTO
   */

  function validateFormaPagamentoForm(success) {
    if (formaPagamentoId === 0 && planoPagamentoId === 0) {
      toast(
        'Forma e Plano de Pagamento são obrigatórios.',
        { type: toast.TYPE.ERROR }
      );
      return;
    }

    if (formaPagamentoId === 0) {
      toast('Forma de Pagamento é obrigatório.', { type: toast.TYPE.ERROR });
      return;
    }

    if (planoPagamentoId === 0) {
      toast('Plano de Pagamento é obrigatório.', { type: toast.TYPE.ERROR });
      return;
    }

    success();
  }

  function occurrenceCustomization(success) {
    let messageOccurrence = '';

    if (NOT_IS_DRAFT()) {
      const customMessage = (arr, arrFind, type, tag) => {
        let isRemoved = arr.length > 1;
        let pluralSoft = isRemoved ? 's ' : ' ';
        let pluralAmount = isRemoved ? 'foram ' : 'foi ';

        const itemNames = arr.map((id) => {
          const itemData = find(arrFind, { id });

          return itemData.nome.toUpperCase();
        });

        let joinOption = ', ';
        if (itemNames.length === 2) {
          joinOption = ' e ';
        }

        return `- O${pluralSoft}${tag}${pluralSoft}`
          + `${itemNames.join(joinOption)} `
          + `${pluralAmount}${type}${pluralSoft}`;
      };

      /**
       * SOFTWARES
       */

      const tempSoftwaresAntigos = [
        ...new Set(pedidoAntigo.itemPedidos.map((_) => _.softwareId)),
      ];

      const tempSoftwaresAtualizados = [
        ...new Set(items.map((_) => _.softwareId)),
      ];

      const softwaresRemovidos = [];
      tempSoftwaresAntigos.forEach((id) => {
        if (!tempSoftwaresAtualizados.includes(id)) {
          softwaresRemovidos.push(id);
        }
      });

      const softwaresAdicionados = [];
      tempSoftwaresAtualizados.forEach((id) => {
        if (!tempSoftwaresAntigos.includes(id)) {
          softwaresAdicionados.push(id);
        }
      });

      const isChangeSoftware = softwaresRemovidos.length !== 0
        || softwaresAdicionados.length !== 0;

      if (isChangeSoftware) {
        if (softwaresRemovidos.length) {
          const messageSoftwareRemovidos = customMessage(
            softwaresRemovidos, softwares, 'removido', 'software',
          );

          messageOccurrence += `${messageSoftwareRemovidos}\n`;
        }

        if (softwaresAdicionados.length) {
          const messageSoftwareAdicionados = customMessage(
            softwaresAdicionados, softwares, 'adicionado', 'software',
          );

          messageOccurrence += `${messageSoftwareAdicionados}\n`;
        }
      }

      /**
       * MÓDULOS
       */

      const tempModulosAntigos = [
        ...new Set(pedidoAntigo.itemPedidos.map((_) => _.moduloId)),
      ];

      const tempModulosAtualizados = [
        ...new Set(items.map((_) => _.id)),
      ];

      const modulosRemovidos = [];
      const modulosParaVerificarQtd = [];
      tempModulosAntigos.forEach((id) => {
        if (!tempModulosAtualizados.includes(id)) {
          modulosRemovidos.push(id);
        } else {
          modulosParaVerificarQtd.push(id);
        }
      });

      const modulosAdicionados = [];
      tempModulosAtualizados.forEach((id) => {
        if (!tempModulosAntigos.includes(id)) {
          modulosAdicionados.push(id);
        }
      });

      const isChangeModules = modulosRemovidos.length !== 0
        || modulosAdicionados.length !== 0;

      if (isChangeModules) {
        if (modulosRemovidos.length) {
          const messageModulesRemovidos = customMessage(
            modulosRemovidos, modulesTotal, 'removido', 'módulo',
          );

          messageOccurrence += `${messageModulesRemovidos}\n`;
        }

        if (modulosAdicionados.length) {
          const messageModulesAdicionados = customMessage(
            modulosAdicionados, modulesTotal, 'adicionado', 'módulo',
          );

          messageOccurrence += `${messageModulesAdicionados}\n`;
        }
      }

      if (modulosParaVerificarQtd.length) {
        const modulosComQtdAlterada = [];
        const modulosComMensalidadeAlterada = [];
        items.forEach((item) => {
          if (modulosParaVerificarQtd.includes(item.id)) {
            const itemData = pedidoAntigo.itemPedidos.find(
              (_) => _.moduloId === item.id,
            );

            if (item.count !== itemData.quantidade) {
              const moduleData = modulesTotal.find((_) => _.id === item.id);
              const softwareData = find(softwares, { id: moduleData.softwareId });

              modulosComQtdAlterada.push(`- O módulo ${moduleData.nome}`
                + ` do software ${((softwareData?.nome) || '---')}`
                + ` alterou a quantidade de ${itemData.quantidade} para`
                + ` ${item.count}\n`);
            }

            if (item.precoMensal !== itemData.valorMensalUnidade) {
              const moduleData = modulesTotal.find((_) => _.id === item.id);
              const softwareData = find(softwares, { id: moduleData.softwareId });

              modulosComMensalidadeAlterada.push(`- O módulo ${moduleData.nome}`
                + ` do software ${(softwareData?.nome || '---')}`
                + ` alterou a mensaliade de R$ ${itemData.valorMensalUnidade} para`
                + ` R$ ${item.precoMensal}\n`);
            }
          }
        });

        if (modulosComQtdAlterada.length > 0) {
          modulosComQtdAlterada.forEach((message) => {
            messageOccurrence += `${message}`;
          });
        }

        if (modulosComMensalidadeAlterada.length > 0) {
          modulosComMensalidadeAlterada.forEach((message) => {
            messageOccurrence += `${message}`;
          });
        }
      }

      /**
       * FORMULÁRIOS
       */

      const allFormsToFind = [];

      const tempFormulariosAntigos = [
        ...new Set(pedidoAntigo.forms.map((_) => _.formId)),
      ];

      pedidoAntigo.forms.forEach((_) => {
        allFormsToFind.push({
          id: _.formId,
          nome: _.form.name.toUpperCase(),
        });
      });

      const tempFormulariosAtualizados = [
        ...new Set(forms.map((_) => _.idForm)),
      ];

      forms.forEach((_) => {
        allFormsToFind.push({
          id: _.idForm,
          nome: _.formName.toUpperCase(),
        });
      });

      const formularioRemovidos = [];
      tempFormulariosAntigos.forEach((id) => {
        if (!tempFormulariosAtualizados.includes(id)) {
          formularioRemovidos.push(id);
        }
      });

      const formularioAdicionados = [];
      tempFormulariosAtualizados.forEach((id) => {
        if (!tempFormulariosAntigos.includes(id)) {
          formularioAdicionados.push(id);
        }
      });

      const isChangeForms = formularioRemovidos.length !== 0
        || formularioAdicionados.length !== 0;

      if (isChangeForms) {
        if (formularioRemovidos.length) {
          const messageFormularioRemovidos = customMessage(
            formularioRemovidos,
            allFormsToFind,
            'removido',
            'formulário',
          );

          messageOccurrence += `${messageFormularioRemovidos}\n`;
        }

        if (formularioAdicionados.length) {
          const messageFormularioAdicionados = customMessage(
            formularioAdicionados,
            allFormsToFind,
            'adicionado',
            'formulário',
          );

          messageOccurrence += `${messageFormularioAdicionados}\n`;
        }
      }

      if (mesInicio !== pedidoAntigo.mesInicio) {
        messageOccurrence += '- O início da mensalidade foi alterado para o mês de'
          + ` ${CALENDARIO_MESES[mesInicio].label.toLowerCase()}\n`;
      }

      const newformaPagamento = formasPagamento.find(
        (_) => _.id === formaPagamentoId,
      );

      const oldformaPagamento = formasPagamento.find(
        (_) => _.id === pedidoAntigo.formaPagamentoId,
      );

      if (newformaPagamento.nome !== oldformaPagamento.nome) {
        messageOccurrence += '- A forma de pagamento foi alterado para'
          + ` ${newformaPagamento.nome}\n`;
      }

      const newPlanos = planosPagamento.find(
        (_) => _.id === planoPagamentoId,
      );

      const oldPlanos = oldformaPagamento.formaPlanoPagamentos.find(
        (_) => _.id === pedidoAntigo.planoPagamentoId,
      );

      if (newPlanos.planoPagamento.nome !== oldPlanos.planoPagamento.nome) {
        messageOccurrence += '- A plano de pagamento foi alterado para'
          + ` ${newPlanos.planoPagamento.nome}\n`;
      }
    }

    success(messageOccurrence);
  }

  async function handleFormaPagamentoSubmit() {
    validateFormaPagamentoForm(() => {
      occurrenceCustomization((message) => {
        setMessagemOcorrencia(message);
        setActiveStep(4);
      });
    });
  }

  /**
   *    CONCLUIR
   */

  function getDataDraftToSave() {
    const softwares = [];
    softwares.concat([...new Set([...items.map((_) => _.softwareId)])]);

    if (selectedSoftware && !softwares.includes(selectedSoftware.id)) {
      softwares.push(selectedSoftware.id);
    }

    const answers = [];
    if (activeStep >= 2) {
      validateRequiredFields(
        getValues(), (_, formDataIndex, formDataFilled) => {
          if (![null, undefined].includes(formDataIndex)) {
            validateAnswerForm(formDataIndex, formDataFilled, () => {
              const formatAttrName = (uuid, attrName) => {
                return `${uuid}---${attrName}`;
              };

              formularios.forEach((form) => {
                for (const keyItem in form.formData) {
                  if (typeof form[keyItem] === 'string') {
                    form[keyItem] = form[keyItem].trim();
                  }
                }

                const formDataValues = {};
                for (const keyItem in form.formData) {
                  if (keyItem.includes(form.formViewer.uuid)) {
                    const keyValue = keyItem.replace(
                      `${form.formViewer.uuid}---`, '',
                    );

                    if (typeof form.formData[keyItem] === 'string') {
                      formDataValues[keyValue] = form.formData[keyItem].trim();
                    }

                    if (typeof form.formData[keyItem] === 'object') {
                      formDataValues[keyValue] = form.formData[keyItem];
                    }
                  }
                }

                const dataAnswerContent = {
                  idForm: form.formId,
                  formName: form.formViewer.formName,
                  extraInfo: JSON.stringify({
                    defaultValues: formDataValues,
                    attrsName: form.contentItemsFormAnswer.map(
                      (input) => input.answerContent.attrName,
                    )
                  }),
                  dataAnswer: form.contentItemsFormAnswer.map(
                    (input) => ({
                      itemFormId: input.itemFormId,
                      answerContent: JSON.stringify(
                        form.formData[formatAttrName(
                          form.formViewer.uuid,
                          input.answerContent.attrName,
                        )]
                      ),
                    })
                  ),
                };

                answers.push(dataAnswerContent);
              });
            });
          }
        }
      );
    }

    const draftData = {
      activeStep,
      clienteId,
      mesInicio,
      userCreateOrderId,
      softwareId: softwares,
      modalityId: selectedModality ? selectedModality.id : null,
      items,
      formaPagamentoId,
      planoPagamentoId,
      obs: observation,
      formularios: {
        forms,
        formCurrentIndex,
        formCurrentId,
        answerForms: answers,
        answerFormsIds: [...new Set([...answerFormsIds])],
        allFormsToAnswer,
        formCheckboxTemp,
        formsToAnswer,
      }
    };

    return draftData;
  }

  function validateDraftOrderConfirmation(success) {
    handleSelectClientSubmit();

    success();
  }

  function validateSalvarRascunho(finalizarRascunho, success) {
    const formData = {};

    formData.id = orderId;
    formData.clienteId = client.id;
    formData.mesInicio = isNaN(mesInicio) ? parseInt(mesInicio) : mesInicio;

    if (![undefined, null, 0].includes(formaPagamentoId)) {
      formData.formaPagamentoId = !isNaN(formaPagamentoId)
        ? parseInt(formaPagamentoId)
        : formaPagamentoId;
    } else {
      formData.formaPagamentoId = 1;
    }

    if (![undefined, null, 0].includes(planoPagamentoId)) {
      formData.planoPagamentoId = !isNaN(planoPagamentoId)
        ? parseInt(planoPagamentoId)
        : planoPagamentoId;
    } else {
      formData.planoPagamentoId = 1;
    }

    formData.itemPedidos = [];
    items.forEach((item) => {
      const itemPedido = {};
      itemPedido.softwareId = parseInt(item.softwareId);
      itemPedido.moduloId = parseInt(item.id);
      itemPedido.quantidade = parseInt(item.count);
      itemPedido.valorMensalUnidade = item.precoMensal;
      itemPedido.valorAdesaoUnidade = item.precoAdesao;
      formData.itemPedidos.push(itemPedido);
    });

    formData.obs = observation.trim();

    const formAnswerData = {
      clientId: client.id,
      userCreateOrderId,
      contentItemsFormAnswer: answerForms.map((item) => ({
        formId: item.idForm,
        dataAnswer: item.dataAnswer,
        extraInfo: item.extraInfo,
      }))
    };

    formData.rascunho = JSON.stringify(getDataDraftToSave());

    if ([null, undefined].includes(finalizarRascunho)) {
      toast('Erro ao finalizar rascunho!', {
        type: toast.TYPE.ERROR,
      });

      return;
    }

    formData.finalizarRascunho = finalizarRascunho;

    success(formData, formAnswerData);
  }

  function handleSalvarRascunhoSubmit(finalizarRascunho) {
    validateDraftOrderConfirmation(() => {
      validateSalvarRascunho(finalizarRascunho, async (formData, formAnswerData) => {
        setLoading(true);
        try {
          const draftOrderCreated = await CommercialOrderService.createDraftOrder(
            formData, formAnswerData,
          );

          if (draftOrderCreated.message) {
            toast(draftOrderCreated.message, { type: toast.TYPE.SUCCESS });
          }

          if (draftOrderCreated.data.backgroundMessage) {
            setTimeout(() => {
              const { error, success } = draftOrderCreated.data.backgroundMessage;
              if (!error.length && success.length) {
                toast(success[0], { type: toast.TYPE.SUCCESS });
              } else {
                if (error.length) {
                  toast(error[0], { type: toast.TYPE.ERROR });
                }
              }
            }, 75); // 0.075 segundos
          }

          setTimeout(() => {
            history.push(commercialPaths.commercialOrder);
          }, 150); // 0.15 segundos
        } catch (error) {
          showMessageError(error);
        } finally {
          setLoading(false);
        }
      });
    });
  }

  function validateOrderConfirmation(success) {
    if (!orderConfirmation) {
      toast('Confirmação de revisão de pedido é obrigatório!', {
        type: toast.TYPE.ERROR,
      });

      handleMoveElement('endTermo');
      return;
    }

    success();
  }

  function validateFinalizarPedidoForm(success) {
    const formData = {};

    formData.id = orderId;
    formData.clienteId = client.id;
    formData.revendaId = pedidoAntigo.revendaId;

    formData.mesInicio = isNaN(mesInicio) ? parseInt(mesInicio) : mesInicio;

    formData.formaPagamentoId = !isNaN(formaPagamentoId)
      ? parseInt(formaPagamentoId)
      : formaPagamentoId;

    formData.planoPagamentoId = !isNaN(planoPagamentoId)
      ? parseInt(planoPagamentoId)
      : planoPagamentoId;

    formData.itemPedidos = [];

    items.forEach((item) => {
      const itemPedido = {};
      itemPedido.softwareId = parseInt(item.softwareId);
      itemPedido.moduloId = parseInt(item.id);
      itemPedido.quantidade = parseInt(item.count);
      itemPedido.valorMensalUnidade = item.precoMensal;
      itemPedido.valorAdesaoUnidade = item.precoAdesao;
      formData.itemPedidos.push(itemPedido);
    });

    formData.obs = observation.trim();

    let ocorrenciasData = messagemOcorrencia;
    if (observation.trim() !== pedidoAntigo.obs) {
      ocorrenciasData += '\n- Existe alteração na observação do pedido\n\n';
    }

    const formAnswerData = {
      clientId: client.id,
      userCreateOrderId,
      contentItemsFormAnswer: answerForms.map(
        (answer) => ({
          formId: answer.idForm,
          dataAnswer: answer.dataAnswer,
          extraInfo: answer.extraInfo,
        })
      ),
    };

    formData.rascunho = null;

    success(formData, formAnswerData, ocorrenciasData);
  }

  function handleFinalizarPedidoSubmit() {
    validateOrderConfirmation(() => {
      validateFinalizarPedidoForm(
        async (formData, formAnswerData, ocorrenciasData) => {
          setLoading(true);
          try {
            const currentStatusOrder = await CommercialOrderService
              .isVerifyStatusOrder(orderId);

            if (!currentStatusOrder) {
              toast.error('Erro ao verificar status atual do pedido');
              setLoading(false);
              return;
            }

            if (!currentStatusOrder.ok) {
              toast(currentStatusOrder.message, { type: toast.TYPE.ERROR });
              setLoading(false);
              return;
            } else {
              const requestUpdateOrder = [
                CommercialOrderService.updateOrder(formData, formAnswerData)
              ];

              if (NOT_IS_DRAFT()) {
                requestUpdateOrder.push(
                  CommercialOrderService.updateStatusOrder(
                    orderId, statusAtual, ocorrenciasData,
                  )
                );
              }

              const [updateOrder] = await Promise.all(requestUpdateOrder);
              toast.success(updateOrder.message);

              setTimeout(() => {
                history.push(
                  commercialPaths.managementCommercialOrder,
                  { id: orderId },
                );
              }, 500); // 0.5 meio segundos
            }
          } catch (error) {
            showMessageError(error);
          } finally {
            setLoading(false);
          }
        }
      );
    });
  }

  function onHandleFinalizarPedidoSubmit() {
    if (IS_DRAFT()) {
      return handleSalvarRascunhoSubmit(true);
    }

    return handleFinalizarPedidoSubmit();
  }

  /**
   *    GLOBAL
   */

  function handleLinkToFormCurrent(formId) {
    setLoading(true);
    handleMoveElement('orderTop');

    const [oldItem] = formularios.filter((_) => _.formId === formId);

    setFormCurrentId(oldItem.formId);
    setFormCurrentIndex(oldItem.index);
    reset(oldItem.formData);

    setActiveStep(2);
    setLoading(false);
  }

  async function handleAddModule(addModule) {
    try {
      setLoading(true);

      const itemsTemp = [...items];
      const hasItem = itemsTemp.filter((item) => item.id === addModule.id);

      if (hasItem && hasItem.length) {
        hasItem[0].count++;
      } else {
        addModule = cloneDeep(addModule);

        let totalDesconto = 0.0;
        userRevenda.descontos.forEach(descontoRevenda => {
          totalDesconto += descontoRevenda.desconto.valor;
        });

        if (addModule.descontoAtivo && totalDesconto > 0) {
          const pctTotal = totalDesconto / 100;
          addModule.precoAdesao = addModule.precoAdesao
            ? addModule.precoAdesao - addModule.precoAdesao * pctTotal
            : 0;
          addModule.precoMensal = addModule.precoMensal
            ? addModule.precoMensal - addModule.precoMensal * pctTotal
            : 0;
        }

        addModule.count = 1;
        itemsTemp.push(addModule);

        let formulariosResponder = [...allFormsToAnswer];
        const moduleWithForm = formulariosResponder.filter(
          (_) => _.moduleId === addModule.id,
        );

        let moduleCheckFormTemp = null;
        if (!moduleWithForm.length) {
          moduleCheckFormTemp = await FormRegisterService.getFormsByModuleId(
            addModule.id,
          );
        }

        const IS_CHECKED = () => {
          return moduleCheckFormTemp && moduleCheckFormTemp.resultado.length;
        };

        let activeStepsTemp = [...activeSteps];

        if (IS_CHECKED()) {
          const formularios = moduleCheckFormTemp.resultado;
          const formulariosExistentes = formularios.filter(
            (_) => _.moduleId === addModule.id,
          );

          if (formulariosExistentes.length) {
            const dataFormulario = {
              moduleId: addModule.id,
              formsId: formularios.map((_) => _.id),
              exist: true,
            };

            formulariosResponder.push(dataFormulario);

            if (!activeStepsTemp.includes(ANSWERED_FORMS_STATUS)) {
              activeStepsTemp.push(ANSWERED_FORMS_STATUS);
            }
          }
        } else {
          const dataFormulario = {
            moduleId: addModule.id,
            formsId: [],
            exist: false,
          };

          const modulesWithoutForms = formulariosResponder.filter(
            (_) => _.moduleId === addModule.id
          );

          if (!modulesWithoutForms.length) {
            formulariosResponder.push(dataFormulario);
          }
        }

        setActiveSteps(activeStepsTemp);
        setAllFormsToAnswer(formulariosResponder);
      }

      setItems(itemsTemp);
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleRemoveModule(remModule) {
    try {
      setLoading(true);

      const itemsCurrent = [...items];
      const allFormsToAnswerCurrent = [...allFormsToAnswer];
      const activeStepsCurrent = [...activeSteps];

      const hasItem = itemsCurrent.filter((item) => item.id === remModule.id);

      let allFormsToAnswerTemp = allFormsToAnswerCurrent;
      let activeStepsTemp = activeStepsCurrent;
      if (hasItem && hasItem.length) {
        hasItem[0].count--;

        if (hasItem[0].count <= 0) {
          hasItem[0].count = 0;
          const index = itemsCurrent.findIndex((item) => item.id === hasItem[0].id);
          if (index < 0) {
            throw new Error('Ocorreu um erro ao excluir o módulo do Pedido!');
          }
          itemsCurrent.splice(index, 1);
        }

        if (hasItem[0].count === 0) {
          if (allFormsToAnswerCurrent.length) {
            const [hasForm] = allFormsToAnswerCurrent.filter(
              item => item.moduleId === remModule.id,
            );

            if (hasForm) {
              const [formsToRemove] = allFormsToAnswerCurrent.filter(
                item => item.moduleId === remModule.id,
              );

              allFormsToAnswerTemp = allFormsToAnswerCurrent.filter(
                item => item.moduleId !== remModule.id,
              );

              const hasModuleWithForm = allFormsToAnswerTemp.filter((module) => {
                return module.formsId.length && module;
              });

              if (!hasModuleWithForm.length) {
                activeStepsTemp = activeStepsCurrent.filter(
                  step => step !== ANSWERED_FORMS_STATUS
                );
              }

              if (formsToRemove.formsId.length) {
                formsToRemove.formsId.forEach((formId) => {
                  setForms((oldForms) => [
                    ...oldForms.filter((_) => _.idForm !== formId),
                  ]);

                  setFormularios((oldForms) => {
                    const newForms = [
                      ...oldForms.filter((_) => _.formId !== formId),
                    ];

                    const arrUpdatedInd = newForms.map((formData, index) => {
                      return { ...formData, index };
                    });

                    return [...arrUpdatedInd];
                  });

                  setFormCheckboxTemp((oldForms) => [
                    ...oldForms.filter((_) => _.formId !== formId),
                  ]);

                  setAnswerForms((oldForms) => [
                    ...oldForms.filter((_) => _.idForm !== formId),
                  ]);

                  setAnswerFormsIds((oldForms) => [
                    ...oldForms.filter((id) => id !== formId),
                  ]);
                });
              }
            }
          }
        }

        setItems(itemsCurrent);
        setActiveSteps(activeStepsTemp);
        setAllFormsToAnswer(allFormsToAnswerTemp);
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function calculateTotalAdesao(items) {
    let sum = 0.0;
    items.forEach((item) => {
      const { precoAdesao } = item;
      if (precoAdesao) sum += precoAdesao * item.count;
    });
    return sum;
  }

  function calculateTotalMensalidade(items) {
    let sum = 0.0;
    items.forEach((item) => {
      const { precoMensal } = item;
      if (precoMensal) sum += precoMensal * item.count;
    });
    return sum;
  }

  const stepsDataObjects = [
    {
      title: 'Cliente',
      number: 1,
      handleEnabledStep: activeStep > 0,
      handleIsStep: activeStep === 0,
      handleClick: () => setActiveStep(0),
      active: true,
      showDivider: true,
    },
    {
      title: 'Produtos',
      number: 2,
      handleEnabledStep: activeStep > 1,
      handleIsStep: activeStep === 1,
      handleClick: handleSelectClientSubmit,
      active: true,
      showDivider: true,
    },
    {
      title: 'Formulários',
      number: 3,
      handleEnabledStep: activeStep > 2,
      handleIsStep: activeStep === 2,
      handleClick: () => setActiveStep(2),
      active: HANDLE_ACTIVE_STEP_FORM(),
      showDivider: true,
    },
    {
      title: 'Forma de Pagamento',
      number: HANDLE_ACTIVE_STEP_FORM() ? 4 : 3,
      handleEnabledStep: activeStep > 3,
      handleIsStep: activeStep === 3,
      handleClick: () => setActiveStep(3),
      active: true,
      showDivider: true,
    },
    {
      title: 'Concluir',
      number: HANDLE_ACTIVE_STEP_FORM() ? 5 : 4,
      handleEnabledStep: activeStep > 4,
      handleIsStep: activeStep === 4,
      handleClick: () => setActiveStep(4),
      active: true,
      showDivider: false,
    },
  ];

  const HANDLE_ON_CLICK_SUBMIT = {
    0: handleSelectClientSubmit,
    1: handleItensPedidoSubmit,
    2: handleSubmit(handleFillFormsSubmit),
    3: handleFormaPagamentoSubmit,
    4: onHandleFinalizarPedidoSubmit,
  };

  const tableShoppingCart = {
    softwares,
    handleRemoveModule,
    handleAddModule,
    calculateTotalAdesao,
    calculateTotalMensalidade,
  };

  const selectClients = {
    client,
    setClient,
    setClienteId,
    inputClienteValue,
    setInputValue,
  };

  const selectProducts = {
    selectedSoftware,
    selectedModality,
    modules,
    modulesTotal,
    modalities,
    items,
    forms,
    allFormsToAnswer,
    answerForms,
    formsToAnswer,
    activeSteps,
    setSelectedSoftware,
    setSelectedModality,
    setModules,
    setModulesTotal,
    setItems,
    setForms,
    setAllFormsToAnswer,
    setAnswerForms,
    setFormsToAnswer,
    setActiveSteps,
    handleSubmit: handleItensPedidoSubmit,
    canShowAgainDialogModality,
    isDraft: IS_DRAFT(),
  };

  const fillForms = {
    forms,
    answerFormsIds,
    allFormsToAnswer,
    formsToAnswer,
    formularios,
    pessoasFormulario,
    register,
    watch,
    getValues,
    setValue,
    reset,
    errors,
    onSubmit: () => handleSubmit(handleFillFormsSubmit),
    formCurrentIndex,
    setFormCurrentIndex,
    setFormCurrentId,
    onGetFormData,
    formCheckboxTemp,
    setFormCheckboxTemp,
  };

  const choosePaymentForm = {
    items,
    setItems,
    mesInicio,
    setMesInicio,
    carenciaAtivada,
    periodoCarencia,
    formasPagamento,
    planosPagamento,
    formaPagamentoId,
    planoPagamentoId,
    setFormasPagamento,
    setPlanosPagamento,
    setFormaPagamentoId,
    setPlanoPagamentoId,
    handleSubmit: handleFormaPagamentoSubmit,
  };

  const conclude = {
    user,
    items,
    mesInicio,
    observation,
    cliente: client,
    formularios,
    register,
    watch,
    setValue,
    errors,
    reset,
    pessoasFormulario,
    formasPagamento,
    formaPagamentoId,
    planoPagamentoId,
    selectedModality,
    orderConfirmation,
    answerForms,
    setObservation,
    setOrderConfirmation,
    handleLinkToFormCurrent,
  };

  const handleButtonSave = () => {
    const existDraft = !!location.state.draftOrder;

    return activeStep === 4 && (existDraft ? NOT_IS_DRAFT() : false);
  };

  const renderPageName = () => {
    return IS_DRAFT() ? 'Editar Rascunho' : 'Editar Pedido';
  };

  const renderButtonStepName = () => {
    return activeStep === 4
      ? IS_DRAFT()
        ? 'FINALIZAR PEDIDO'
        : 'SALVAR'
      : 'AVANÇAR';
  };

  const renderBreadcrumbs = () => {
    const ORDERS = {
      label: 'Pedido',
      link: commercialPaths.commercialOrder,
    };

    return IS_DRAFT() ? [ORDERS] : [ORDERS, {
      label: 'Monitorar Pedido',
      onClick: () => handleBackOrderManagement,
    }];
  };

  const handleBackOrderManagement = () => {
    return history.push(
      commercialPaths.managementCommercialOrder,
      { id: location.state.idOrder }
    );
  };

  return (
    <S.EditOrderContainer>
      <Modal show={loading} loading />
      <div id='orderTop' />
      <SidebarPageHeader
        mainMenu="Comercial"
        pageName={renderPageName()}
        breadcrumbs={renderBreadcrumbs()}
        customButtonsBeforeReport={[
          <button key='button_order_cancel'
            type="button"
            className="buttonBase baseWebMobile addMargin"
            onClick={handleStopBuildOrder}
          >
            CANCELAR
          </button>,
          (userCanViewSaveDraft && IS_DRAFT()) && (
            <button key='button_order_save_draft'
              type="button"
              className="buttonBase baseWebMobile addMargin"
              onClick={() => handleSalvarRascunhoSubmit(false)}
            >
              SALVAR RASCUNHO
            </button>
          ),
          <button key='button_order_complete'
            type="button"
            title={handleButtonSave() ? 'Desabilitado' : 'Próxima Etapa'}
            style={{ opacity: handleButtonSave() ? '0.5' : '1' }}
            disabled={handleButtonSave()}
            className="buttonBase baseWebMobile addMargin baseMain"
            onClick={HANDLE_ON_CLICK_SUBMIT[activeStep]}
          >
            {renderButtonStepName()}
          </button>,
        ]}
        openButtonSelect
      />
      <Stepper
        customHeader={(<span>Etapas do pedido:</span>)}
        stepsDataObjects={stepsDataObjects}
      />
      <Steps
        index={activeStep}
        userCanEdit={userCanEdit}
        setLoading={setLoading}
        tableShoppingCart={tableShoppingCart}
        dataObject={{
          selectClients,
          selectProducts,
          fillForms,
          choosePaymentForm,
          conclude,
        }}
      />
    </S.EditOrderContainer>
  );
}

EditOrder.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      draftOrder: PropTypes.any,
      isDraft: PropTypes.bool,
      idClient: PropTypes.number,
      idOrder: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default EditOrder;
