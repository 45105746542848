import styled from 'styled-components';

export const WarningTextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  text-align: start;

  color: var(--cm-primary);
  border: 1px solid var(--cm-warning);
  background-color: var(--cm-warning-20);
  padding: 8px;
  margin: 20px 20px 12px 20px;
  border-radius: 6px;

  margin-bottom: ${({ noMarginBottom }) => noMarginBottom ? '0' : '20px'};

  svg {
    margin: 0 8px 0 4px;
    color: var(--cm-warning);
  }
`;
