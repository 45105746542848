import PropTypes from 'prop-types';
import { FaRegEdit } from 'react-icons/fa';
import { MdOutlineFactCheck, MdScheduleSend } from 'react-icons/md';
import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import { find } from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import api, { endpoints } from '~/services/api';
import { accessDenied } from '~/util/accessDenied';
import ProductSoftwareService from '~/services/product-software-service';
import ProductModuleService from '~/services/product-module-service';
import permissions from '~/util/permissions';
import PrintableComponent from '~/components/PrintableComponent';
import TableComponent from '~/components/TableComponent';
import SearchComponent from './SearchComponent';
import { HELPER_STATUS_FORM } from '~/util/domainutils';
import { inputNumber } from '~/util/stringutils';
import { showMessageError } from '~/util/errorutils';
import { formPaths } from '~/routes/paths';
import { useFitler } from '~/hook/useFilter';
import {
  Container,
  ModalContainer,
  TableCellContent,
  TableCellValue,
  DeleteButton,
  CancelButton,
} from '../styles';

function FormMaintenance({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(true);
  const [allSoftwares, setAllSoftwares] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [modules, setModules] = useState([]);
  const [inputFormName, setInputFormName] = useState('');
  const [inputSelectSoftware, setInputSelectSoftware] = useState('');
  const [inputSelectModule, setInputSelectModule] = useState('');
  const [inputClientName, setInputClientName] = useState('');
  const [inputOrderNumber, setInputOrderNumber] = useState(null);
  const [inputInitialDate, setInputInitialDate] = useState('');
  const [inputEndDate, setInputEndDate] = useState('');
  const [inputCheckboxStatus, setInputCheckboxStatus] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [searchResponse, setSearchResponse] = useState([]);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [updateStatusItem, setUpdateStatusItem] = useState(null);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    name: null,
    orderId: null,
    clienteName: null,
    formStatusId: null,
    initialDate: null,
    endDate: null,
    moduleId: null,
    softwareId: null,
  });

  let PrintableElement = React.useRef();

  const { permissions: userPermissions } = useSelector(state => state.user.data);

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions['edit_form-maintenance']),
    [userPermissions]
  );

  const userCanFinished = useMemo(
    () => userPermissions.includes(permissions['finished_form-maintenance']),
    [userPermissions]
  );

  useEffect(() => {
    let cleaningAsyncEffect = false;

    const asyncEffect = async () => {
      if (cleaningAsyncEffect) return;
      setLoading(true);
      try {
        const [softwares, modules] = await Promise.all([
          ProductSoftwareService.getProductSoftwares({}),
          ProductModuleService.getProductModules({}),
          findAllByFilter(pageParameters, handleFilters()),
        ]);

        const modulesActives = modules.resultado.filter(module => module.ativo);

        setAllSoftwares(softwares.resultado);
        setModules(modulesActives);
        setAllModules(modulesActives);
      } catch (error) {
        setAllSoftwares([]);
        setAllModules([]);
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();

    return () => {
      cleaningAsyncEffect = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilters() {
    let count = 0;

    if (filters.name) {
      setInputFormName(filters.name);
      count += 1;
    }
    if (filters.softwareId) {
      setInputSelectSoftware(filters.softwareId);
      count += 1;
    }
    if (filters.moduleId) {
      setInputSelectModule(filters.moduleId);
      count += 1;
    }
    if (filters.orderId) {
      setInputOrderNumber(filters.orderId);
      count += 1;
    }
    if (filters.textCliente) {
      setInputClientName(filters.textCliente);
      count += 1;
    }
    if (filters.initialDate) {
      setInputInitialDate(filters.initialDate);
      count += 1;
    }
    if (filters.endDate) {
      setInputEndDate(filters.endDate);
      count += 1;
    }
    if (filters.formStatusId) {
      setInputCheckboxStatus(filters?.formStatusId?.map(
        id => HELPER_STATUS_FORM?.ID_TO_TAG?.[id]?.toUpperCase()
      ));
      count += 1;
    }

    setCountFilter(count);

    return filters;
  }

  function handleInputFormNameChange(event) {
    const {
      target: { value },
    } = event;

    setInputFormName(value);
  }

  function handleSelectSoftwareChange(event) {
    const {
      target: { value },
    } = event;

    const modulesFilted = modules.filter((module) => {
      return parseInt(module.softwareId) === parseInt(value);
    });

    setInputSelectSoftware(String(value));

    if (modulesFilted.length) {
      setAllModules(modulesFilted);
    } else {
      setAllModules(modules);
      setInputSelectModule(String(0));
    }
  }

  function handleSelectModuleChange(event) {
    const {
      target: { value },
    } = event;

    const [module] = modules.filter((module) => {
      return parseInt(module.id) === parseInt(value);
    });

    setInputSelectModule(String(value));

    if (module) {
      setInputSelectSoftware(String(module.softwareId));
    } else {
      setInputSelectSoftware(String(0));
    }
  }

  function handleClientNameChange(event) {
    const {
      target: { value },
    } = event;

    setInputClientName(value);
  }

  function handleOrderNumberChange(event) {
    const {
      target: { value },
    } = event;

    setInputOrderNumber(inputNumber(value));
  }

  function handleInputInitialDateChange(event) {
    const {
      target: { value },
    } = event;

    setInputInitialDate(value);
  }

  function handleInputEndDateChange(event) {
    const {
      target: { value },
    } = event;

    setInputEndDate(value);
  }

  function handleInputCheckboxStatusChange(event) {
    const {
      target: { value },
    } = event;

    if (inputCheckboxStatus.some(elem => elem === value)) {
      setInputCheckboxStatus(old => old.filter(elem => elem !== value));
    } else {
      setInputCheckboxStatus(old => [...old, value]);
    }
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  async function findAllByFilterReport(pageParams, formDataParam) {
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const { data: response } = await api.post(
        endpoints.form.maintenance.getFormsAnswer,
        {
          ...(formDataParam || formData),
          limit: page * rowsPerPage + rowsPerPage,
          offset: page * rowsPerPage,
        }
      );
      if (response.data.resultado.length) {
        handleSetSearchResponseReport(response.data.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos pedidos para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  async function findAllByFilter(pageParams, formDataParam) {
    setLoading(true);
    setPageParams(pageParams);
    try {
      const { page, rowsPerPage } = pageParams;
      const { data: response } = await api.post(
        endpoints.form.maintenance.getFormsAnswer,
        {
          ...(formDataParam || formData),
          limit: page * rowsPerPage + rowsPerPage,
          offset: page * rowsPerPage,
        }
      );
      if (response.data.resultado.length) {
        handleSetSearchResponse(
          response.data.resultado,
          response.data.total,
        );
      } else {
        handleSetSearchResponse();
        toast(
          'Não encontramos nenhum formulário de resposta para essa pesquisa',
          { type: toast.TYPE.SUCCESS },
        );
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};

    if (inputFormName && inputFormName.length) {
      data.name = inputFormName.trim();
      count += 1;
    }

    if (inputSelectSoftware && inputSelectSoftware.length) {
      data.softwareId = parseInt(inputSelectSoftware);
      count += 1;
    }

    if (inputSelectModule && inputSelectModule.length) {
      data.moduleId = parseInt(inputSelectModule);
      count += 1;
    }

    if (inputOrderNumber && inputOrderNumber.length) {
      data.orderId = parseInt(inputOrderNumber);
      count += 1;
    }

    if (inputClientName && inputClientName.length) {
      data.textCliente = inputClientName.trim();
      count += 1;
    }

    if (inputInitialDate && inputInitialDate.length) {
      data.initialDate = inputInitialDate.trim();
      count += 1;
    }

    if (inputEndDate && inputEndDate.length) {
      data.endDate = inputEndDate.trim();
      count += 1;
    }

    if (inputCheckboxStatus && inputCheckboxStatus.length) {
      data.formStatusId = inputCheckboxStatus.map(s => HELPER_STATUS_FORM.IDS[s]);
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    setFilters(data);
    await success(data);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    await validateForm(formData => {
      findAllByFilter(pageParameters, formData);
    });
  }

  function renderRowComponent(row) {
    return [
      <TableCell key='row_form_print_maintenance_0'>
        {row.form.name}
      </TableCell>,
      <TableCell key='row_form_print_maintenance_1'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label>{row.form.moduleName}</label>
          <small>{row.form.softwareName}</small>
        </div>
      </TableCell>,
      <TableCell key='row_form_print_maintenance_2'>
        {row.client.razaoSocial ? (
          <TableCellContent>
            <TableCellValue>
              <span title={row.client.razaoSocial}>
                {row.client.razaoSocial}
              </span>
            </TableCellValue>
          </TableCellContent>
        ) : '---'}
      </TableCell>,
      <TableCell key='row_form_print_maintenance_3'>
        {row.orderId}
      </TableCell>,
      <TableCell key='row_form_print_maintenance_4'>
        {moment(row.createdAt).format('DD/MM/YYYY, HH:mm:ss')}
      </TableCell>,
      <TableCell key='row_form_print_maintenance_5'>
        {row.formStatus.value}
      </TableCell>,
    ];
  }

  const renderPrintStatus = () => {
    const statuses = inputCheckboxStatus.map(s => HELPER_STATUS_FORM.VALUES[s]);
    return statuses.length ? statuses.join(', ') : '';
  };

  const renderPrintOrderNumber = () => {
    return inputOrderNumber ? String(inputOrderNumber) : '';
  };

  const renderPrintModule = () => {
    const module = find(allModules, { id: parseInt(inputSelectModule) });
    return module ? module.nome : '';
  };

  const renderPrintSoftware = () => {
    const software = find(allSoftwares, { id: parseInt(inputSelectSoftware) });
    return software ? software.nome : '';
  };

  function handleLinkToEdit(row) {
    history.push(formPaths.editFormMaintenance, {
      idAnswer: row.id, // Id da resposta do formulário
      idForm: row.formId,
      idOrder: row.orderId,
      idClient: row.clientId,
      idResale: row.resellerId,
      userCreateOrderId: row.userCreateOrderId,
      status: row.formStatus,
      extraInfo: row.extraInfo,
      itemAnswers: row.itemAnswers,
      actionLogs: row.actionLogs,
      responsibleName: row.responsibleName,
      finishedAt: row.finishedAt,
      requestedIn: moment(row.createdAt).format('DD/MM/YYYY'),
      pathReferenceToBack: formPaths.formMaintenance,
    });
  }

  function handleOnClickButtonEdit(row) {
    return userCanEdit
      ? () => handleLinkToEdit(row)
      : () => accessDenied(history);
  }

  async function handleUpdateStatus(item, statusId) {
    setLoading(true);
    try {
      const { data: response } = await api.put(
        endpoints.form.maintenance.updateStatusFormAnswer,
        {
          id: item.id,
          formId: item.formId,
          orderId: item.orderId,
          clientId: item.clientId,
          resellerId: item.resellerId,
          statusId,
        }
      );

      if (response.data) {
        const { json: { data, message } } = response.data;

        if (data && data.ok) {
          const newSearchResponse = searchResponse.map(resp => {

            if (resp.id === item.id) {
              const newStatus = statusId === HELPER_STATUS_FORM.IDS.ATENDIDO
                ? HELPER_STATUS_FORM.VALUES.ATENDIDO
                : HELPER_STATUS_FORM.VALUES.PENDENTE;

              return {
                ...resp,
                formStatus: {
                  ...resp.formStatus,
                  value: newStatus
                },
              };
            }

            return resp;
          });
          handleSetSearchResponse(newSearchResponse, totalItems);
          toast(message, { type: toast.TYPE.SUCCESS });
        }
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleUpdateStatusItem() {
    const { VALUES: { ATENDIDO, PENDENTE } } = HELPER_STATUS_FORM;
    try {
      if (updateStatusItem.formStatus.value === ATENDIDO) {
        await handleUpdateStatus(updateStatusItem, 1);
      }
      if (updateStatusItem.formStatus.value === PENDENTE) {
        await handleUpdateStatus(updateStatusItem, 2);
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
      setUpdateStatusItem(null);
    }
  }

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Formulário"
        handlePageUpdate={findAllByFilterReport}
        filters={[
          { name: 'Formulário', value: inputFormName },
          { name: 'Software', value: renderPrintSoftware() },
          { name: 'Módulo', value: renderPrintModule() },
          { name: 'Cliente', value: inputClientName },
          { name: 'Nº Pedido', value: renderPrintOrderNumber() },
          { name: 'Status', value: renderPrintStatus() },
          { name: 'De', value: inputInitialDate },
          { name: 'Até', value: inputEndDate },
        ]}
        headerLabels={[
          { text: 'Nome', width: 'auto' },
          { text: 'Módulo', width: '20%' },
          { text: 'Cliente', width: '20%' },
          { text: 'Nr.', width: 'auto' },
          { text: 'Solicitado em', width: '150px' },
          { text: 'Status', width: '8%' },
        ]}
        dataObjects={searchResponseReport}
        renderRowComponent={(row) => renderRowComponent(row)}
      />
      <Container>
        <SidebarPageHeader
          mainMenu="Formulário"
          pageName="Manutenção"
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async (data) => {
            await findAllByFilterReport(pageParameters, data);
          }}
          openButtonSelect
        />
        <SearchComponent
          countFilter={countFilter}
          handleSubmit={handleSubmit}
          checkboxStatus={inputCheckboxStatus}
          handleCheckboxStatusChange={handleInputCheckboxStatusChange}
          formName={inputFormName}
          handleFormNameChange={handleInputFormNameChange}
          clientValue={inputClientName}
          handleClientChange={handleClientNameChange}
          orderValue={inputOrderNumber}
          handleOrderChange={handleOrderNumberChange}
          selectedSoftware={String(inputSelectSoftware)}
          handleSoftwareChange={handleSelectSoftwareChange}
          softwares={allSoftwares}
          selectedModule={String(inputSelectModule)}
          handleModuleChange={handleSelectModuleChange}
          modules={allModules}
          dataInicio={inputInitialDate}
          handleDataInicioChange={handleInputInitialDateChange}
          dataFim={inputEndDate}
          handleDataFimChange={handleInputEndDateChange}
        />
        <TableComponent
          headerLabels={[
            { text: 'Nome', width: 'auto' },
            { text: 'Módulo', width: '20%' },
            { text: 'Cliente', width: '20%' },
            { text: 'Nr.', width: 'auto' },
            { text: 'Solicitado em', width: '150px' },
            { text: 'Status', width: '8%' },
            { text: 'Ação', width: '100px', align: 'center' },
          ]}
          dataObjects={searchResponse}
          renderRowComponent={(row) => [
            ...renderRowComponent(row),
            <TableCell key='row_form_maintenance_6' className="actionCell widthAuto">
              <div className="actionButtonsHolder buttonCenterHolder">
                {userCanFinished && (
                  <>
                    {row.formStatus.value.toLowerCase() ===
                      HELPER_STATUS_FORM.VALUES.ATENDIDO.toLowerCase()
                      ? (
                        <MdScheduleSend
                          className='icon'
                          onClick={() => setUpdateStatusItem(row)}
                          title="Deixar Pendente"
                        />
                      ) : (
                        <MdOutlineFactCheck
                          className='icon'
                          onClick={() => setUpdateStatusItem(row)}
                          title="Atender"
                        />
                      )}
                    <hr style={{ margin: '0 5px' }} />
                  </>
                )}
                <FaRegEdit
                  className={`${userCanEdit ? 'icon' : 'icon-disabled'}`}
                  onClick={handleOnClickButtonEdit(row)}
                  title="Editar"
                />
              </div>
            </TableCell>,
          ]}
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
          useCustomActions
        />
      </Container>
      <Modal loading={loading} show={loading || !!updateStatusItem}>
        <ModalContainer>
          <h2>Confirmar alteração de status</h2>
          <span>
            Tem certeza que deseja alterar o status da resposta do formulário do pedido?
            <br />
            <b>{updateStatusItem && updateStatusItem.orderId}</b>
          </span>
          <div>
            <CancelButton onClick={() => setUpdateStatusItem(null)}>
              Fechar
            </CancelButton>
            <DeleteButton onClick={handleUpdateStatusItem}>
              Sim
            </DeleteButton>
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
}

FormMaintenance.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default FormMaintenance;
