import api, { endpoints } from '~/services/api';

class ReasonCancellationService {
  static getReasonCancellation = async (params) => {
    const { data: response } = await api.post(
      endpoints.license.getReasonCancellation,
      { ...params }
    );
    return response.data;
  };

  static createReasonCancellation = async (params) => {
    const { data: response } = await api.post(
      endpoints.license.createReasonCancellation,
      { ...params }
    );
    return response;
  };

  static updateReasonCancellation = async (params) => {
    const { data: response } = await api.put(
      endpoints.license.updateReasonCancellation,
      { ...params }
    );
    return response;
  };

  static deleteReasonCancellation = async (id) => {
    const { data: response } = await api.delete(
      endpoints.license.deleteReasonCancellation(id),
    );
    return response;
  };
}

export { ReasonCancellationService };
