import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { initialPaths } from '~/routes/paths';
import { useWindowDimensions } from '~/hook/useWindowDimensions';
import { MQL_MATCH_MEDIA } from '~/styles';

const IS_SMALL = 'is_small';
const IS_LARGE = 'is_large';

const mql = window.matchMedia(`(min-width: ${MQL_MATCH_MEDIA}px)`);
const SidebarContext = createContext(null);

function SidebarProvider({ children }) {
  const { width } = useWindowDimensions();

  const getWebDimension = useCallback(() => {
    return width <= MQL_MATCH_MEDIA ? IS_SMALL : IS_LARGE;
  }, [width]);

  const [active, setActive] = useState('');
  const [subActive, setSubActive] = useState('');
  const [underMaintenance, setUnderMaintenance] = useState(null);
  const [sidebarDocked, setSidebarDocked] = useState(mql.matches);
  const [sidebarOpen, setSidebarOpen] = useState(mql.matches);
  const [sidebarClose, setSidebarClose] = useState(true);
  const [collapseSidebarProfile, setCollapseSidebarProfile] = useState(false);
  const [isGlobalModalActive, setGlobalModalActive] = useState(false);

  useEffect(() => {
    onSingInSidebar();
    mql.addListener(mediaQueryChanged);
    return () => mql.removeListener(mediaQueryChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function mediaQueryChanged() {
    setSidebarDocked(mql.matches);
    setSidebarOpen(mql.matches);
  }

  const onSetSidebarOpen = useCallback(() => {
    if (sidebarClose) {
      setCollapseSidebarProfile(false);
    } else {
      setSidebarOpen(!sidebarOpen);
    }
  }, [sidebarClose, sidebarOpen]);

  const onSetSidebarClose = useCallback(() => {
    setSidebarOpen(true);
    setCollapseSidebarProfile(true);
  }, []);

  const onSetCollapseSidebarOpen = useCallback(() => {
    setCollapseSidebarProfile(false);
  }, []);

  const onHandleMouseOver = useCallback(() => {
    setCollapseSidebarProfile(true);
  }, []);

  const onHandleMouseLeave = useCallback(() => {
    switch (getWebDimension()) {
      case IS_SMALL: {
        setCollapseSidebarProfile(true);
        break;
      }

      case IS_LARGE: {
        setCollapseSidebarProfile(false);
        break;
      }

      default:
        break;
    }
  }, [getWebDimension]);

  const onSingInSidebar = useCallback(() => {
    switch (getWebDimension()) {
      case IS_SMALL: {
        setSidebarOpen(false);
        setSidebarClose(false);
        setCollapseSidebarProfile(false);
        break;
      }

      case IS_LARGE: {
        setSidebarOpen(true);
        setSidebarClose(true);
        setCollapseSidebarProfile(false);
        break;
      }

      default:
        break;
    }
  }, [getWebDimension]);

  const onLogoutSidebar = useCallback(() => {
    switch (getWebDimension()) {
      case IS_SMALL: {
        setSidebarOpen(true);
        setSidebarClose(true);
        setCollapseSidebarProfile(true);
        break;
      }

      case IS_LARGE: {
        setSidebarOpen(true);
        setSidebarClose(false);
        setCollapseSidebarProfile(false);
        break;
      }

      default:
        break;
    }

    setActive(initialPaths.pageInitial);
    setSubActive('');
  }, [getWebDimension]);

  const getSidebarContext = useCallback(
    () => ({
      width,
      sidebarDocked,
      sidebarOpen,
      setSidebarOpen,
      onSetSidebarOpen,
      collapseSidebarProfile,
      onSetCollapseSidebarOpen,
      onSetSidebarClose,
      sidebarClose,
      setSidebarClose,
      onSingInSidebar,
      onLogoutSidebar,
      onHandleMouseOver,
      onHandleMouseLeave,
      active,
      setActive,
      subActive,
      setSubActive,
      underMaintenance,
      setUnderMaintenance,
      isGlobalModalActive,
      setGlobalModalActive,
    }),
    [
      width,
      sidebarDocked,
      sidebarOpen,
      onSetSidebarOpen,
      collapseSidebarProfile,
      onSetCollapseSidebarOpen,
      sidebarClose,
      setSidebarClose,
      onSetSidebarClose,
      onSingInSidebar,
      onLogoutSidebar,
      onHandleMouseOver,
      onHandleMouseLeave,
      active,
      setActive,
      subActive,
      setSubActive,
      underMaintenance,
      setUnderMaintenance,
      isGlobalModalActive,
      setGlobalModalActive,
    ],
  );

  return (
    <SidebarContext.Provider
      value={getSidebarContext()}
    >
      {children}
    </SidebarContext.Provider>
  );
}

function useSidebar() {
  const context = useContext(SidebarContext);
  return context;
}

SidebarProvider.propTypes = {
  children: PropTypes.any,
};

export { SidebarProvider, useSidebar };
