import React, { useEffect, useState } from 'react';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import Modal from '~/components/Modal';
import { toast } from 'react-toastify';
import { commercialPaths } from '~/routes/paths';
import { showMessageError } from '~/util/errorutils';
import { PaymentsService } from '~/services/commercial/payment';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import * as S from '../styles';

function EditPayments({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [idPaymentForm, setIDPaymentForm] = useState('');
  const [inputPaymentForm, setInputPaymentForm] = useState('');
  const [errorPaymentForm, setErrorPaymentForm] = useState(false);
  const [paymentPlansToRemove, setPaymentPlansToRemove] = useState([]);
  const [paymentPlans, setPaymentPlans] = useState([]);

  useEffect(() => {
    if (!location.state) history.push(commercialPaths.payments);
    else {
      setLoading(true);
      const asyncEffect = async () => {
        try {
          const {
            resultado: [pagamento]
          } = await PaymentsService.getPayment(location.state.id);

          if (pagamento) {
            setInputPaymentForm(pagamento.nome);
            setIDPaymentForm(pagamento.id);

            const plans = pagamento.formaPlanoPagamentos.map((plan, index) => {
              return {
                id: plan.planoPagamento.id,
                name: `plan ${index}`,
                value: plan.planoPagamento.nome,
                error: false,
              };
            });

            setPaymentPlans(plans);
          } else {
            toast('Erro ao busca Pagamentos', { type: toast.TYPE.ERROR });

            setTimeout(() => {
              history.push(commercialPaths.payments);
            }, 100);
          }
        } catch (error) {
          showMessageError(error);
        } finally {
          setLoading(false);
        }
      };

      asyncEffect();
    }
  }, [location.state, history]);

  function handleInputPaymentFormChange(event) {
    const {
      target: { value },
    } = event;

    setInputPaymentForm(value);
    setErrorPaymentForm(false);
  }

  function handlePlansChange(event, subIndex) {
    const {
      target: { value },
    } = event;

    const paymentPlansItem = Array.from(paymentPlans);

    const [changeValue] = paymentPlansItem.filter(
      (_, index) => index === subIndex,
    );

    if (changeValue) {
      changeValue.value = value;
      changeValue.error = false;

      paymentPlansItem[subIndex] = changeValue;

      setPaymentPlans(paymentPlansItem);
    }
  }

  function validadeForm(success) {
    const data = {};
    let error_temp = false;

    if (!inputPaymentForm || !inputPaymentForm.length) {
      setErrorPaymentForm(true);
      error_temp = true;
    }

    let error_paymentPlan = false;
    const paymentPlansWithError = paymentPlans.map((plan, index) => {
      if (index === 0) {
        if (!plan.value || !plan.value.length) {
          error_paymentPlan = true;

          return {
            ...plan,
            error: true,
          };
        }
      }

      return plan;
    });

    if (error_paymentPlan) {
      error_temp = true;
      setPaymentPlans(paymentPlansWithError);
    }

    if (error_temp) {
      return;
    }

    data.id = idPaymentForm;
    data.formaPagamento = inputPaymentForm.trim();

    data.planoParaRemover = paymentPlansToRemove.map(plan => ({
      id: plan.id,
      nome: plan.value,
    }));

    data.planoParaAdicionar = [];
    data.planoParaAtualizar = [];
    paymentPlans.forEach((plan) => {
      if (plan.id && plan.value) {
        return data.planoParaAtualizar.push({
          id: plan.id,
          nome: plan.value,
        });
      }
      if (!plan.id && plan.value) {
        return data.planoParaAdicionar.push({
          nome: plan.value,
        });
      }
    });

    success(data);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    validadeForm(async (formData) => {
      setLoading(true);
      try {
        const result = await PaymentsService.updatePayments(formData);

        const messages = orderBy(result.errors, 'type', 'desc');

        let timer = 200;
        messages.forEach((error, index) => {
          setTimeout(() => {
            toast(error.message, {
              type: toast.TYPE[error.type],
              autoClose: error.type === 'SUCCESS'
                ? 3000 // 3 segundos
                : 6000 // 6 segundos
            });
          }, timer * (index + 1));
        });

        setTimeout(() => {
          history.push(commercialPaths.payments);
        }, timer + 100);
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });
  }

  function handleDeletePaymentPlan(subIndex) {
    const planToRemove = paymentPlans.find((_, index) => index === subIndex);

    if (planToRemove.id) {
      setPaymentPlansToRemove(oldState => [...oldState, planToRemove]);
    }

    setPaymentPlans(oldState => [
      ...oldState.filter((_, index) => index !== subIndex)
    ]);
  }

  function handleShowButtonDelete() {
    return !(paymentPlans.length <= 1);
  }

  function handleAddPaymentPlans() {
    setPaymentPlans(oldState => [...oldState, {
      name: `payment-plan ${oldState.length}`,
      value: '',
      error: false,
    }]);
  }

  return (
    <div className="defaultFormContainer">
      <Modal loading show={loading} />
      <SidebarPageHeader
        mainMenu="Comercial"
        pageName="Editar Pagamento"
        breadcrumbs={[{
          label: 'Pagamento',
          link: commercialPaths.payments,
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => handleSubmit,
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={() => history.goBack()}
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <span id="title">Dados do Pagamento</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Forma do Pagamento *</span>
              <input
                type="text"
                name="payment-form"
                value={inputPaymentForm}
                onChange={handleInputPaymentFormChange}
              />
              {errorPaymentForm && (
                <S.ContainerError>
                  Campo obrigatório
                </S.ContainerError>
              )}
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Dados do(s) Plano(s) de Pagamento</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <div className="rowContainer" style={{ marginTop: 6 }}>
                <div className="inputHolder defaultFlex noMarginRight">
                  {paymentPlans.map((plan, index) => (
                    <S.ContainerPayments key={`plan-${index}`}>
                      <span>
                        {`Plano de Pagamento ${index === 0 ? '*' : ''}`}
                      </span>
                      <S.ContainerInputDeleteRow>
                        <input
                          type="text"
                          name={plan.name}
                          value={plan.value}
                          onChange={(event) => handlePlansChange(event, index)}
                        />
                        {handleShowButtonDelete() && (
                          <S.DeleteIcon
                            disabled={true}
                            onClick={() => handleDeletePaymentPlan(index)}
                            title="Deletar"
                          />
                        )}
                      </S.ContainerInputDeleteRow>
                      {plan.error && (
                        <S.ContainerError>
                          Campo obrigatório
                        </S.ContainerError>
                      )}
                    </S.ContainerPayments>
                  ))}
                </div>
              </div>
              <S.ContainerButton onClick={handleAddPaymentPlans}>
                <S.AddIcon />
                <span>PLANO</span>
              </S.ContainerButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

EditPayments.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
};

export default EditPayments;
