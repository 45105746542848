/* eslint-disable react/prop-types */
import React from 'react';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { Container } from '../styles';

export default function Order({ order }) {
  return (
    <Container>
      <div>
        <span>Número do Pedido:</span>
        <span>{order.id}</span>
      </div>
      <div>
        <span>Data do Pedido:</span>
        <span>
          {format(
            parseISO(order.data || order.createdAt),
            'dd \'de\' MMM \'de\' yyyy - HH:mm:ss',
            { locale: pt }
          )}
        </span>
      </div>
      <div id="fullTextContainer">
        <span>Observação:</span>
        <span id="textContent" className='textItalic'>
          {order.obs}
        </span>
      </div>
    </Container>
  );
}
