import styled, { css } from 'styled-components';

export const DroppableContainer = styled.div`
  padding-bottom: 10px;
  padding-top: 1px;
  margin-top: 10px;
`;

export const DraggableItem = styled.div`
  width: 100%;
`;

export const DraggableHandle = styled.div``;

export const DraggableRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;

  border-radius: 10px;
  user-select: none;
  background: #fff;

  div + div {
    padding: 10px 10px 0 10px;
    width: 100%;
  }

  ${props => props.isDragging && css`
    border: 1px solid #00000055;
    -webkit-box-shadow: 0px 3px 4px 0px rgba(46, 50, 50, 0.48);
    -moz-box-shadow: 0px 3px 4px 0px rgba(46, 50, 50, 0.48);
    box-shadow: 0px 3px 4px 0px rgba(46, 50, 50, 0.48);
  `}
`;

export const DraggableContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
`;

