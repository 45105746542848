import React from 'react';
import * as S from '../styles';

const VpnKeyOutline = ({ ...rest }) => {
  return (
    <S.IconSVG {...rest}>
      <svg width="33" height="19" viewBox="0 0 33 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.3937 6.75C16.2662 3.54625 13.2137 1.25 9.625 1.25C5.07375 1.25 1.375 4.94875 1.375 9.5C1.375 14.0512 5.07375 17.75 9.625 17.75C13.2137 17.75 16.2662 15.4537 17.3937 12.25H23.375V17.75H28.875V12.25H31.625V6.75H17.3937ZM9.625 12.25C8.1125 12.25 6.875 11.0125 6.875 9.5C6.875 7.9875 8.1125 6.75 9.625 6.75C11.1375 6.75 12.375 7.9875 12.375 9.5C12.375 11.0125 11.1375 12.25 9.625 12.25Z" stroke="#002850" strokeWidth="2" />
      </svg>
    </S.IconSVG>
  );
};

export { VpnKeyOutline };
