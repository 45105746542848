import styled, { css } from 'styled-components';
import {
  MdDragHandle,
  MdContentCopy,
  MdDeleteOutline,
  MdClose,
} from 'react-icons/md';
import { GrDrag } from 'react-icons/gr';

export const DroppableContainer = styled.div`
  padding-bottom: 10px;
  padding-top: 1px;
  margin-top: 10px;
`;

export const DraggableItem = styled.div`
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #C5C5D4;

  margin: 10px 0px 0 0px;
  user-select: none;
  background: #fff;

  ${props => props.isDragging && css`
    border: 1px solid #00000055;
    -webkit-box-shadow: 0px 3px 4px 0px rgba(46, 50, 50, 0.48);
    -moz-box-shadow: 0px 3px 4px 0px rgba(46, 50, 50, 0.48);
    box-shadow: 0px 3px 4px 0px rgba(46, 50, 50, 0.48);
  `}
`;

export const TitleAndDescription = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const DragIcon = styled(MdDragHandle)`
  width: 30px;
  height: 30px;
  color: #0000008A;
  align-self: center;
`;

export const DragIndicatorIcon = styled(GrDrag)`
  width: 20px;
  height: 20px;
  color: #0000008A;
  align-self: center;
`;

export const CopyIcon = styled(MdContentCopy)`
  width: 20px;
  height: 20px;
  color: #0000008A;
  margin-right: 10px;
  cursor: pointer;
`;

export const DeleteIcon = styled(MdDeleteOutline)`
  width: 20px;
  height: 20px;
  color: #0000008A;
  margin-right: 10px;
  cursor: pointer;
`;

export const Delete2Icon = styled(MdClose)`
  width: 30px;
  height: 30px;
  color: #0000008A;
  margin-right: 10px;
  margin-bottom: 12px;
  align-self: flex-end;
  cursor: pointer;
`;

export const ContainerRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ContainerRowTop = styled(ContainerRow)`
  align-items: flex-start;
`;

