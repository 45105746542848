import PropTypes from 'prop-types';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { AttachmentContainer } from './styles';
import './styles.scss';

function AttachmentDownload({ attachmentName, handleDownloadFile }) {
  return (
    <div className='tooltip'>
      <span className="tooltiptext">{attachmentName}</span>
      <AttachmentContainer>
        <span>{attachmentName}</span>
        <FaDownload onClick={handleDownloadFile} />
      </AttachmentContainer>
    </div>
  );
}

AttachmentDownload.propTypes = {
  attachmentName: PropTypes.string.isRequired,
  handleDownloadFile: PropTypes.func.isRequired,
};

export { AttachmentDownload };
