import PropTypes from 'prop-types';
import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  TableHeadBreaks,
  TableHeadBreaksColumn,
  TableBodyContainer,
  TableReport,
} from './styles';
import './styles.scss';

function TableReportComponent({
  headerLabels,
  dataObjects,
  fieldsArray,
  renderRowComponent,
  renderCustomHeader,
  filters,
  newLayout = false,
}) {
  const dataBreaks = () => {
    const breaks = {
      revendas: [],
      softwares: [],
    };

    dataObjects.forEach((licenca) => {
      if (!breaks.revendas.includes(licenca.nomeFantasiaRevenda))
        breaks.revendas.push(licenca.nomeFantasiaRevenda);
      if (!breaks.softwares.includes(licenca.software))
        breaks.softwares.push(licenca.software);
    });

    return breaks;
  };

  const renderRowInfo = (row) => {
    if (renderRowComponent) return renderRowComponent(row);

    return fieldsArray.map((field, index) => (
      <TableCell key={`row_${row[field]}_${index}`}>
        {row[field] || ''}
      </TableCell>
    ));
  };

  const renderHeader = () => {
    if (renderCustomHeader) return renderCustomHeader();

    return (
      <TableRow>
        {headerLabels.map((label, index) => (
          <TableCell
            key={`header_table_cell_${label.text}_${index}`}
            style={{ width: label.width || 'auto' }}
            align={label.align || 'left'}
          >
            {label.text}
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const renderHeadBreak = (revenda, software) => {
    const filted = [];
    filters.forEach(field => {
      if ((field.value === revenda) || (field.value === software))
        filted.push(field.value);
    });

    return (
      <>
        {filted.length !== 0 ? (
          <>
            {filted.length !== 2 && (
              <TableHeadBreaks>
                {filted.map((breakTable) => (
                  <>
                    {!(breakTable === software) && (
                      <TableHeadBreaksColumn>
                        <b>SOFTWARE:</b> {software || '---'}
                      </TableHeadBreaksColumn>
                    )}
                    {!(breakTable === revenda) && (
                      <TableHeadBreaksColumn>
                        <b>REVENDA:</b> {revenda || '---'}
                      </TableHeadBreaksColumn>
                    )}
                  </>
                ))}
              </TableHeadBreaks>
            )}
          </>
        ) : (
          <TableHeadBreaks>
            <TableHeadBreaksColumn>
              <b>SOFTWARE:</b> {software || '---'}
            </TableHeadBreaksColumn>
            <TableHeadBreaksColumn>
              <b>REVENDA:</b> {revenda || '---'}
            </TableHeadBreaksColumn>
          </TableHeadBreaks>
        )}
      </>
    );
  };

  const renderBreakFilter = (row, revenda, software) => {
    const isSameRevenda = row.nomeFantasiaRevenda === revenda;
    const isSameSoftware = row.software === software;

    return isSameRevenda && isSameSoftware;
  };

  function zebraStyle(index) {
    return index % 2 !== 0 ? 'zebraStyle' : '';
  }

  return (
    <div>
      {newLayout ? (
        <div className={'tableHolderReportNewLayout'}>
          {dataBreaks().revendas.map((revenda) => (
            dataBreaks().softwares.map((software) => (
              <TableReport key={`table_report_${revenda}_${software}`}>
                {renderHeadBreak(revenda, software)}
                <Table className={'resultTableNewLayout'}>
                  <TableHead className={'tableHeaderNewLayout'}>
                    {renderHeader()}
                  </TableHead>
                  <TableBody>
                    {dataObjects.map((row) => (
                      <TableBodyContainer key={`table_body_container_${row.id}`}>
                        {renderBreakFilter(row, revenda, software) && (
                          <TableRow className={'tableRowNewLayout'}>
                            {renderRowInfo(row)}
                          </TableRow>
                        )}
                      </TableBodyContainer>
                    ))}
                  </TableBody>
                </Table>
              </TableReport>
            ))
          ))}
        </div>
      ) : (
        <div className={'tableHolderReport'}>
          <Table className={'resultTable'}>
            <TableHead className={'tableHeader'}>
              {renderHeader()}
            </TableHead>
            <TableBody>
              {dataObjects.map((row, index) => (
                <TableRow key={row.id}
                  className={`tableRow ${zebraStyle(index)}`}
                >
                  {renderRowInfo(row)}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
}

TableReportComponent.propTypes = {
  headerLabels: PropTypes.arrayOf(PropTypes.shape({})),
  dataObjects: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    || PropTypes.shape({}).isRequired,
  fieldsArray: PropTypes.arrayOf(PropTypes.string),
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  renderRowComponent: PropTypes.func,
  renderCustomHeader: PropTypes.func,
  newLayout: PropTypes.bool,
};

export default TableReportComponent;
