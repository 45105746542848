import React from 'react';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import TableComponent from '~/components/TableComponent';
import { formatCpfCnpj, identifyCpfCnpj } from '~/util/stringutils';
import * as S from './styles';

export function TableModuleActives({ dataObject }) {
  const {
    cpfCnpjCliente,
    modalidade,
    nomeFantasiaCliente,
    razaoSocialCliente,
    serie,
    software,
    modulos,
  } = dataObject;

  const formatedModules = modulos.map((modObj) => ({
    ...modObj, nomeModulo: (modObj?.nomeModulo || '').toUpperCase()
  }));
  const orderedModules = orderBy(formatedModules, 'nomeModulo');
  const modulesPart1 = (orderedModules || []).filter((_, i) => i % 2 === 0);
  const modulesPart2 = (orderedModules || []).filter((_, i) => i % 2 !== 0);

  const renderRowComponent = (row) => {
    return [
      <TableCell key='row_active_1' align='center'>
        {row.quantidade || '-'}
      </TableCell>,
      <TableCell key='row_active_2'>
        {row.nomeModulo || '-'}
      </TableCell>,
    ];
  };

  return (
    <S.TableModuleActives>
      <S.ContainerData>
        <S.ConrtainerDataRow>
          <div className='title'>
            <strong>DADOS DO CLIENTE</strong>
          </div>
          <div>
            <span>
              Razão Social:{' '}
              <strong>{razaoSocialCliente || '---'}</strong>
            </span>
          </div>
          <div>
            <span>
              Nome Fantasia:{' '}
              <strong>{nomeFantasiaCliente || '---'}</strong>
            </span>
          </div>
          <div>
            <span>
              {identifyCpfCnpj(cpfCnpjCliente)}:{' '}
              <strong>{formatCpfCnpj(cpfCnpjCliente) || '---'}</strong>
            </span>
          </div>
        </S.ConrtainerDataRow>
        <hr />
        <S.ConrtainerDataRow>
          <div className='title'>
            <strong>DADOS DA LICENÇA</strong>
          </div>
          <div>
            <span>
              Série:{' '}
              <strong>{(serie || '---').trim()}</strong>
            </span>
          </div>
          <div>
            <span>
              Software:{' '}
              <strong>{software || '---'}</strong>
            </span>
          </div>
          <div>
            <span>
              Modalidade:{' '}
              <strong>{modalidade || '---'}</strong>
            </span>
          </div>
        </S.ConrtainerDataRow>
      </S.ContainerData>
      <S.TablesModules id='modules-view-web'>
        <TableComponent
          headerLabels={[
            { text: 'Qtd.', width: '40px' },
            { text: 'Módulo', width: 'calc(50% - 40px)' },
          ]}
          dataObjects={modulesPart1}
          fieldsArray={['quantidade', 'nomeModulo']}
          renderRowComponent={renderRowComponent}
          style={{ margin: 0 }}
          useCustomActions
        />
        {(modulesPart2.length > 0) && (
          <TableComponent
            headerLabels={[
              { text: 'Qtd.', width: '40px' },
              { text: 'Módulo', width: 'calc(50% - 40px)' },
            ]}
            dataObjects={modulesPart2}
            fieldsArray={['quantidade', 'nomeModulo']}
            renderRowComponent={renderRowComponent}
            style={{ margin: 0 }}
            useCustomActions
          />
        )}
      </S.TablesModules>
      <S.TablesModules id='modules-view-mobile'>
        <TableComponent
          headerLabels={[
            { text: 'Qtd.', width: '40px' },
            { text: 'Módulo', width: 'calc(50% - 40px)' },
          ]}
          dataObjects={modulos}
          fieldsArray={['quantidade', 'nomeModulo']}
          renderRowComponent={renderRowComponent}
          style={{ margin: 0 }}
          useCustomActions
        />
      </S.TablesModules>
    </S.TableModuleActives>
  );
}

TableModuleActives.propTypes = {
  dataObject: PropTypes.shape({
    cpfCnpjCliente: PropTypes.string,
    modalidade: PropTypes.string,
    modulos: PropTypes.array,
    nomeFantasiaCliente: PropTypes.string,
    razaoSocialCliente: PropTypes.string,
    serie: PropTypes.string,
    software: PropTypes.string,
  }),
};
