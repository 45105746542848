import React from 'react';
import PropTypes from 'prop-types';
import { FORM_ITEM_ASNWER_TYPE as TAGS } from '~/util/domainutils';
import { OTHER_INPUT_UNUM as OTHER_INPUT } from '~/util/formularioutils';
import { FormClientResale } from '~/pages/Form/Items/FormClientResale';
import * as S from './styles';

function RenderForm(props) {
  const {
    formId,
    formViewer,
    formPersons,
    register,
    onSubmit,
    setValue,
    watch,
    errors,
    setFormCheckboxTemp,
    contentItemsFormAnswer,
    showLabelFieldRequired = true,
    canDisabledAllInputs = false,
    noBorder = false,
  } = props;

  function handleViewerClientResale() {
    return (
      <S.ViewerClientResale>
        <S.ViewerSoftwareModule>
          {formViewer && (
            <>
              <span><b>Software:</b> {formViewer.softwareName}</span>
              <span><b>Módulo:</b> {formViewer.moduleName}</span>
            </>
          )}
        </S.ViewerSoftwareModule>
        {formPersons && (
          <FormClientResale
            formPersons={formPersons}
            showData={true}
            withBorder={true}
            showDefaultData={false}
          />
        )}
      </S.ViewerClientResale>
    );
  }

  function handleViewerImage(item, index) {
    const { src, align } = item.itemData.itemContent;

    return (
      <S.ViewerImage justifyContent={align}>
        <img src={src} alt={`position ${index}`} />
      </S.ViewerImage>
    );
  }

  function handleViewerTitle(item) {
    const { statement, description, required } = item.itemData;

    let formatDescription = [];

    if (description) {
      formatDescription = description.split('\n');
    }

    return (
      <S.ViewerTitle>
        {required ? (
          <S.ViewerTitle1>
            {statement && (
              <h1>{statement.toUpperCase()}</h1>
            )}
          </S.ViewerTitle1>
        ) : (
          <S.ViewerTitle2>
            {statement && (
              <b><u>{statement.toUpperCase()}</u></b>
            )}
          </S.ViewerTitle2>
        )}
        {formatDescription.map((description, index) => (
          <S.ViewerDescription key={index}>
            {description}
          </S.ViewerDescription>
        ))}
      </S.ViewerTitle>
    );
  }

  function handleViewerAnswer(item) {
    const {
      statement,
      description,
      required,
      itemTypeId,
      itemContent,
    } = item.itemData;

    let titleGroup = [];
    contentItemsFormAnswer.forEach((input) => {
      if (input.itemFormId === item.id) {
        titleGroup.push(input.answerContent.attrName);
      }
    });

    [titleGroup] = titleGroup.filter((attrName) => attrName && attrName);

    titleGroup = `${formViewer.uuid}---${titleGroup}`;

    const optionOther = [];
    const optionWithoutOther = [];

    if (itemContent) {
      itemContent.forEach((option) => {
        if (!option.other) {
          optionWithoutOther.push(option);
        } else {
          optionOther.push(option);
        }
      });
    }

    const radio = { 3: 'radio' };
    const checkbox = { 4: 'checkbox' };
    const HELPERS_TYPE = { ...radio, ...checkbox };

    const getValueData = (option) => {
      return typeof option.itemData.value === 'string'
        ? option.itemData.value.trim()
        : option.itemData.value;
    };

    return (
      <S.ViewerAnswerContainer>
        <label htmlFor={titleGroup}>
          <S.ViewerAnswer>
            {statement}{'  '}{required && '*'}
          </S.ViewerAnswer>
        </label>
        {description && (
          <S.ViewerDescriptionContainer>
            <S.ViewerDescription>
              {description}
            </S.ViewerDescription>
          </S.ViewerDescriptionContainer>
        )}
        {itemTypeId === TAGS.SHORT_ANSWER.id && (
          <input
            disabled={canDisabledAllInputs}
            ref={register()}
            name={titleGroup}
            {...register(titleGroup, { required })}
          />
        )}
        {itemTypeId === TAGS.PARAGRAPH.id && (
          <textarea
            disabled={canDisabledAllInputs}
            ref={register()}
            name={titleGroup}
            {...register(titleGroup, { required })}
          />
        )}
        {(itemTypeId === TAGS.MULTIPLE_CHOICE.id
          || itemTypeId === TAGS.SELECTION_BOX.id)
          && (
            <S.ViewerOptions>
              {optionWithoutOther && (
                <S.ViewerOptionsContainer>
                  {optionWithoutOther.map((option) => (
                    <S.ViewerOption key={option.id}>
                      {!option.other && (
                        <S.ViewerOptionContainer>
                          <input
                            disabled={canDisabledAllInputs}
                            ref={register()}
                            name={titleGroup}
                            className={'viewer-option'}
                            type={HELPERS_TYPE[itemTypeId]}
                            value={getValueData(option)}
                            {...register(titleGroup, { required })}
                            onClick={() => {
                              if (itemTypeId === TAGS.SELECTION_BOX.id) {
                                handleHelpCheckbox(titleGroup);
                              }
                            }}
                            onChange={() => {
                              handleOptionOtherChange(titleGroup, itemTypeId);
                            }}
                          />
                          <label htmlFor={titleGroup}>
                            {option.itemData.value}
                          </label>
                        </S.ViewerOptionContainer>
                      )}
                    </S.ViewerOption>
                  ))}
                </S.ViewerOptionsContainer>
              )}
              {optionOther[0] && (
                <S.ViewerOther>
                  <S.ViewerOptionOther>
                    <input
                      disabled={canDisabledAllInputs}
                      ref={register()}
                      name={titleGroup}
                      className={'viewer-option'}
                      value={optionOther[0].itemData.label}
                      type={HELPERS_TYPE[itemTypeId]}
                      {...register(titleGroup, { required })}
                      onClick={() => {
                        if (itemTypeId === TAGS.SELECTION_BOX.id) {
                          handleHelpCheckbox(titleGroup);
                        }
                      }}
                      onChange={() => {
                        handleOptionOtherChange(titleGroup, itemTypeId);
                      }}
                    />
                    <label htmlFor={titleGroup}>{'Outro:'}</label>
                  </S.ViewerOptionOther>
                  <input
                    ref={register()}
                    name={`${titleGroup}${OTHER_INPUT.KEY}`}
                    disabled={canDisabledAllInputs || handleShowOptionOther(titleGroup)}
                    {...register(
                      `${titleGroup}${OTHER_INPUT.KEY}`,
                      { required: !handleShowOptionOther(titleGroup) },
                    )}
                  />
                </S.ViewerOther>
              )}
            </S.ViewerOptions>
          )}
        {(errors[titleGroup] || (
          !handleShowOptionOther(titleGroup)
          && errors[`${titleGroup}${OTHER_INPUT.KEY}`]
        ))
          && (
            <S.ErrorField>
              Campo obrigatório
            </S.ErrorField>
          )}
      </S.ViewerAnswerContainer>
    );
  }

  function handleOptionOtherChange(other, typeId) {
    const optionOther = `${other}${OTHER_INPUT.KEY}`;

    if (typeId === 4 && (!watch(other).includes(OTHER_INPUT.VALUE))) {
      setValue(optionOther, '');
    }

    if (typeId === 3 && !(watch(other) === OTHER_INPUT.VALUE)) {
      setValue(optionOther, '');
    }
  }

  function handleHelpCheckbox(titleGroup) {
    setFormCheckboxTemp((oldCheckbox) => {
      const checkboxUpdate = [...oldCheckbox].map(
        (item) => {
          if (item.formId === formId) {
            const valuesTemp = item.valuesData.map(
              (_) => _.inputName,
            );

            let itemValuesTemp = [...item.valuesData];
            if (!valuesTemp.includes(titleGroup)) {
              itemValuesTemp.push({
                inputName: titleGroup,
                hasNoValue: watch(titleGroup).length <= 0,
              });
            } else {
              const itemIndex = itemValuesTemp.findIndex(
                (_) => _.inputName === titleGroup,
              );

              if (itemValuesTemp[itemIndex]) {
                itemValuesTemp[itemIndex].hasNoValue = watch(
                  titleGroup,
                ).length <= 0;
              }
            }

            return { ...item, valuesData: itemValuesTemp };
          }

          return item;
        }
      );

      return checkboxUpdate;
    });
  }

  function handleShowOptionOther(other) {
    if (watch(other)) {
      if (typeof watch(other) === 'object') {
        return !watch(other).includes(OTHER_INPUT.VALUE);
      }

      if (typeof watch(other) === 'string') {
        return watch(other) !== OTHER_INPUT.VALUE;
      }
    }

    return true;
  }

  const HANDLE_VIEWERS = ({ tag }, item, index) => {
    const TYPES = {
      [TAGS.CLIENT_RESALE.tag]: handleViewerClientResale,
      [TAGS.IMAGE.tag]: () => handleViewerImage(item, index),
      [TAGS.TITLE.tag]: () => handleViewerTitle(item),
      [TAGS.ANSWER.tag]: () => handleViewerAnswer(item, index),
    };

    return TYPES[tag]();
  };

  return (
    <S.ViewerContainer noBorder={noBorder}>
      {showLabelFieldRequired && (
        <span className='title-required'>
          {'Campos com (*) são obrigatórios'}
        </span>
      )}
      {formViewer && (
        <form onSubmit={onSubmit}>
          {formViewer.contentItemsForm.map((item, index) => (
            <S.ViewerContainerItem key={item.id}>
              {HANDLE_VIEWERS({ tag: item.itemTag }, item, index)}
            </S.ViewerContainerItem>
          ))}
        </form>
      )}
    </S.ViewerContainer>
  );
}

RenderForm.propTypes = {
  noBorder: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setIsValidadeForms: PropTypes.func,
  formId: PropTypes.any,
  formViewer: PropTypes.any,
  formPersons: PropTypes.any,
  contentItemsFormAnswer: PropTypes.any,
  answeredFilled: PropTypes.any,
  showLabelFieldRequired: PropTypes.bool,
  canDisabledAllInputs: PropTypes.bool,
  register: PropTypes.func,
  onSubmit: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
  formCurrentIndex: PropTypes.number,
  setFormCheckboxTemp: PropTypes.func,
};

export { RenderForm };
