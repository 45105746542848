import styled from 'styled-components';

export const TermHolder = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 15px;

  #title {
    font-weight: bold;
    color: black;
  }

  #radioInputHolder {
    display: block;
    flex-direction: row;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 5px;
    margin-right: 10px;
    margin-top: 10px;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      width: 17px !important;
      height: 17px;
      margin: 0;
      left: 4px;
      top: 7px;
      z-index: 2;
    }

    #radioLabel {
      padding-left: 25px;
      color: black;
    }
  }

  .checkmark {
    position: absolute;
    top: 4px;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: white;
    border: 1px solid #d4d4df;
    border-radius: 4px;
  }

  .checkmark_stem {
    position: absolute;
    width: 2px;
    height: 15px;
    background-color: var(--cm-primary);
    left: 13px;
    top: 3px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  }

  .checkmark_kick {
    position: absolute;
    width: 10px;
    height: 2px;
    background-color: var(--cm-primary);
    left: 1px;
    top: 11px;
    -ms-transform: rotate(45deg); /* IE 9 */
    -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
    transform: rotate(45deg);
  }

  #radioInputHolder:hover input ~ .checkmark {
    border: 1px solid #4c4e9e;
    background-color: #eee;
  }

  #radioInputHolder input:checked ~ .checkmark {
    border: 1px solid var(--cm-primary);
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  #radioInputHolder input:checked ~ .checkmark:after {
    display: block;
  }
`;
