import { licensePaths } from '~/routes/paths';
import permissions from '~/util/permissions';

import ActiveLicense from '~/pages/License/Active';
import TotalCancellation from '~/pages/License/Active/TotalCancellation';
import PartialCancellation from '~/pages/License/Active/PartialCancellation';

import MonitorRequests from '~/pages/License/MonitorRequests';
import EditRequests from '~/pages/License/MonitorRequests/EditRequests';

import GenerateKey from '~/pages/License/GenerateKey';

import MonitorKey from '~/pages/License/MonitorKey';

import ReasonCancellation from '~/pages/License/ReasonCancellation';
import AddReasonCancellation from
  '~/pages/License/ReasonCancellation/AddReasonCancellation';
import EditReasonCancellation from
  '~/pages/License/ReasonCancellation/EditReasonCancellation';

const licenseRoutes = [
  {
    key: 1,
    exact: true,
    hideSidebar: false,
    name: 'Ativas',
    path: licensePaths.activeLicenses,
    component: ActiveLicense,
    permission: permissions.view_licenca,
  },
  {
    key: 2,
    exact: true,
    hideSidebar: false,
    name: 'Monitorar Cancelamentos',
    path: licensePaths.monitorRequests,
    component: MonitorRequests,
    permission: permissions.manage_licencas,
  },
  {
    key: 3,
    exact: true,
    hideSidebar: true,
    name: 'Cancelamento Total',
    path: licensePaths.totalCancellation,
    component: TotalCancellation,
    permission: permissions.delete_licenca,
  },
  {
    key: 4,
    exact: true,
    hideSidebar: true,
    name: 'Cancelamento Parcial',
    path: licensePaths.partialCancellation,
    component: PartialCancellation,
    permission: permissions.delete_licenca,
  },
  {
    key: 5,
    exact: true,
    hideSidebar: true,
    name: 'Monitorar Solicitações',
    path: licensePaths.monitorRequests,
    component: MonitorRequests,
    permission: permissions.manage_licencas,
  },
  {
    key: 6,
    exact: true,
    hideSidebar: true,
    name: 'Editar Solicitações',
    path: licensePaths.editRequests,
    component: EditRequests,
    permission: permissions.manage_licencas,
  },
  {
    key: 7,
    exact: true,
    hideSidebar: false,
    name: 'Chave',
    path: licensePaths.generateKey,
    component: GenerateKey,
    permission: permissions['view_generate-key'],
  },
  {
    key: 8,
    exact: true,
    hideSidebar: false,
    name: 'Monitorar Chaves',
    path: licensePaths.monitorKey,
    component: MonitorKey,
    permission: permissions.manage_key,
  },
  {
    key: 9,
    exact: true,
    hideSidebar: false,
    name: 'Motivo Cancelamento',
    path: licensePaths.reasonCancellation,
    component: ReasonCancellation,
    permission: permissions['view_motivo-cancelamento'],
  },
  {
    key: 10,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Motivo Cancelamento',
    path: licensePaths.addReasonCancellation,
    component: AddReasonCancellation,
    permission: permissions['create_motivo-cancelamento'],
  },
  {
    key: 11,
    exact: false,
    hideSidebar: true,
    name: 'Editar Motivo Cancelamento',
    path: licensePaths.editReasonCancellation,
    component: EditReasonCancellation,
    permission: permissions['edit_motivo-cancelamento'],
  },
];

export default licenseRoutes;
