import iconAdminActive from '../../assets/icons/ic_admin_active.svg';
import iconCommercialActive from '../../assets/icons/ic_commercial_active.svg';
import iconHomeActive from '../../assets/icons/ic_home_active.svg';
import iconLicenseActive from '../../assets/icons/ic_license_active.svg';
import iconPeopleActive from '../../assets/icons/ic_people_active.svg';
import iconMarketingActive from '../../assets/icons/ic_marketing_active.svg';
import iconProductActive from '../../assets/icons/ic_product_active.svg';
import iconSupportActive from '../../assets/icons/ic_support_active.svg';
import iconFormActive from '../../assets/icons/ic_form_active.svg';
import iconGoalActive from '../../assets/icons/ic_goal_active.svg';
import iconOpportunityActive from '../../assets/icons/ic_opportunity_active.svg';

import iconAdminInactive from '../../assets/icons/ic_admin_inactive.svg';
import iconCommercialInactive from '../../assets/icons/ic_commercial_inactive.svg';
import iconHomeInactive from '../../assets/icons/ic_home_inactive.svg';
import iconLicenseInactive from '../../assets/icons/ic_license_inactive.svg';
import iconPeopleInactive from '../../assets/icons/ic_people_inactive.svg';
import iconMarketingInactive from '../../assets/icons/ic_marketing_inactive.svg';
import iconProductInactive from '../../assets/icons/ic_product_inactive.svg';
import iconSupportInactive from '../../assets/icons/ic_support_inactive.svg';
import iconFormInactive from '../../assets/icons/ic_form_inactive.svg';
import iconGoalInactive from '../../assets/icons/ic_goal_inactive.svg';
import iconOpportunityInactive from '../../assets/icons/ic_opportunity_inactive.svg';

export const icons = {
  admin: {
    title: 'Administrador',
    active: iconAdminActive,
    inactive: iconAdminInactive,
  },
  commercial: {
    title: 'Comercial',
    active: iconCommercialActive,
    inactive: iconCommercialInactive,
  },
  home: {
    title: 'Página Inicial',
    active: iconHomeActive,
    inactive: iconHomeInactive,
  },
  license: {
    title: 'Licença',
    active: iconLicenseActive,
    inactive: iconLicenseInactive,
  },
  people: {
    title: 'Pessoa',
    active: iconPeopleActive,
    inactive: iconPeopleInactive,
  },
  marketing: {
    title: 'Marketing',
    active: iconMarketingActive,
    inactive: iconMarketingInactive,
  },
  form: {
    title: 'Formulário',
    active: iconFormActive,
    inactive: iconFormInactive,
  },
  product: {
    title: 'Produto',
    active: iconProductActive,
    inactive: iconProductInactive,
  },
  support: {
    title: 'Suporte',
    active: iconSupportActive,
    inactive: iconSupportInactive,
  },
  goal: {
    title: 'Metas',
    active: iconGoalActive,
    inactive: iconGoalInactive,
  },
  opportunity: {
    title: 'Oportunidade',
    active: iconOpportunityActive,
    inactive: iconOpportunityInactive,
  },
};
