import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;

  @media print {
    .no-print {
      @media (max-width: 900px) {
        flex-direction: column;
      }
    }

    .print {
      flex-direction: row;
    }
  }

  div {
    display: flex;
    width: 50%;
    padding: 5px 10px;

    @media print {
      .no-print {
        @media (max-width: 900px) {
          width: 100%;
        }
      }

      .print {
        @media (max-width: 900px) {
          width: 49%;
        }
      }
    }

    span {
      font-size: 17px;
      color: #707070;

      :first-child {
        margin-right: 10px;
      }

      @media (max-width: 700px) {
        font-size: 14px;

        :first-child {
          margin-right: 5px;
        }
      }
    }
  }

  #fullTextContainer {
    width: 100%;

    #textContent {
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
    }

    #textContent.textItalic {
      font-style: italic;
    }
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;
