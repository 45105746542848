import React from 'react';
import PropTypes from 'prop-types';
import { USER_TYPE } from '~/util/domainutils';
import { CollapsibleFilter } from '~/components/CollapsibleFilter';
import AdvancedSearch from './AdvancedSearch';
import SimpleSearch from './SimpleSearch';
import './styles.scss';

function PersonSearchComponent({
  userType,
  countFilter,
  handleSubmit,
  codeValue,
  handleCodeChange,
  statusValue,
  handleStatusChange,
  statusChaveValue,
  handleStatusChaveChange,
  inputPessoa1Value,
  handlePessoa1Change,
  inputPessoa2Value,
  handlePessoa2Change,
  isMassActions,
  noFieldStatus = true,
  noTitleSearch = true,
  noFieldStatusChave = true,
  userCanViewColumnRevenda,
}) {
  const handleAdvancedSearchContent = (inputTitle1, inputTitle2) => (
    <AdvancedSearch
      title1={'Pesquisar'}
      inputTitle1={inputTitle1}
      inputPessoa1Value={inputPessoa1Value}
      handlePessoa1Change={handlePessoa1Change}
      inputTitle2={inputTitle2}
      inputPessoa2Value={inputPessoa2Value}
      handlePessoa2Change={handlePessoa2Change}
      handleSubmit={handleSubmit}
      codeValue={codeValue}
      handleCodeChange={handleCodeChange}
      noFieldStatus={noFieldStatus}
      statusValue={statusValue}
      handleStatusChange={handleStatusChange}
      noFieldStatusChave={noFieldStatusChave}
      statusChaveValue={statusChaveValue}
      handleStatusChaveChange={handleStatusChaveChange}
      userCanViewColumnRevenda={userCanViewColumnRevenda}
      userType={userType}
    />
  );

  const handleSimpleSearchContent = (inputTitle1) => (
    <SimpleSearch
      handleSubmit={handleSubmit}
      codeValue={codeValue}
      handleCodeChange={handleCodeChange}
      inputTitle1={inputTitle1}
      inputPessoa1Value={inputPessoa1Value}
      handlePessoa1Change={handlePessoa1Change}
      statusValue={statusValue}
      handleStatusChange={handleStatusChange}
      noFieldStatus={true}
      noTitleSearch={noTitleSearch}
      noFieldStatusChave={noFieldStatusChave}
      statusChaveValue={statusChaveValue}
      handleStatusChaveChange={handleStatusChaveChange}
      userType={userType}
    />
  );

  const RENDER_PERSON = {
    [USER_TYPE.CLIENTE]: (
      <>
        {userCanViewColumnRevenda ? (
          handleAdvancedSearchContent('Cliente', 'Revenda')
        ) : (
          handleSimpleSearchContent('Cliente')
        )}
      </>
    ),
    [USER_TYPE.REVENDA]: (
      handleSimpleSearchContent('Revenda')
    ),
    [USER_TYPE.COLABORADOR]: (
      <>
        {userCanViewColumnRevenda ? (
          handleAdvancedSearchContent('Colaborador', 'Revenda')
        ) : (
          handleSimpleSearchContent('Cliente')
        )}
      </>
    ),
  };

  return (
    <>
      {isMassActions ? (
        <form className="formContainer" onSubmit={handleSubmit}>
          {RENDER_PERSON[userType]}
        </form>
      ) : (
        <CollapsibleFilter countFilters={countFilter}>
          <form className="formContainer" onSubmit={handleSubmit}>
            {RENDER_PERSON[userType]}
          </form>
        </CollapsibleFilter>
      )}
    </>
  );
}

PersonSearchComponent.propTypes = {
  countFilter: PropTypes.number,
  userType: PropTypes.string,
  handleSubmit: PropTypes.func,
  codeValue: PropTypes.string,
  handleCodeChange: PropTypes.func,
  statusValue: PropTypes.string,
  handleStatusChange: PropTypes.func,
  isMassActions: PropTypes.bool,
  noTitleSearch: PropTypes.bool,
  noFieldStatus: PropTypes.bool,
  noFieldStatusChave: PropTypes.bool,
  userCanViewColumnRevenda: PropTypes.bool,
  statusChaveValue: PropTypes.string,
  handleStatusChaveChange: PropTypes.func,
  inputPessoa1Value: PropTypes.string,
  handlePessoa1Change: PropTypes.func,
  inputPessoa2Value: PropTypes.string,
  handlePessoa2Change: PropTypes.func,
};

PersonSearchComponent.defaultProps = {
  countFilter: 0,
  userCanViewColumnRevenda: true,
  noTitleSearch: true,
  name2Value: '',
  handleName2Change: () => { },
  cnpj2Value: '',
  handleCnpj2Change: () => { },
  statusValue: '',
  handleStatusChange: () => { },
  statusChaveValue: '',
  handleStatusChaveChange: () => { },
};

export default PersonSearchComponent;
