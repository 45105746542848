import { Fragment } from 'react';
import styled from 'styled-components';

export const CustomContainer = Fragment;

export const ContainerDataClient = styled.form``;

export const Divider = styled.div`
  height: 10px;
  background-color: #fcfcfc;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  form {
    span {
      font-size: 16px;
      color: #707070;
    }
  }
`;

export const ChooseClientContainer = styled.div`
  margin: 0;
  padding: 0;
  border: none;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;

  #clientText {
    color: var(--cm-primary);
    font-size: 12pt;
    font-weight: bold;
  }

  #helpText {
    font-size: 12pt;
    color: var(--cm-primary);
  }

  input {
    height: 33px;
    border-radius: 6px;
    border: 1px solid #dadae3;
    padding: 8px 15px;
    margin-right: 20px;
  }

  input:focus {
    border: 1px solid var(--cm-primary);
  }

  nav {
    border: 1px solid #4c4e9e;
    border-radius: 8px;
    padding: 8px;

    ul {
      min-height: 140px;
      width: auto;
      height: auto;
      min-width: 350px;

      .suggestions-container {
        border: 1px solid #999;
        border-radius: 8px;
        padding: 8px;
      }

      .suggestion-active {
        border: 1px solid #4c4e9e;
        border-radius: 8px;
        z-index: 3;
      }

      li {
        padding: 10px;
      }
    }
  }
`;

export const FormTitle = styled.span`
  color: #2c2f82 !important;
  font-weight: bold;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    padding: 12px 30px;
    border-radius: 4px;
    border-color: #b1aeae;
    border-width: 1px;
    font-size: 16px;
    box-shadow: 0 1px 3px rgb(0, 0, 0, 0.16);
    background: #eaeaea;

    @media (max-width: 450px) {
      font-size: 12px;
      padding: 6px 15px;
    }
  }
`;

export const ImageContent = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
  }
`;

export const InfoCard = styled.div`
  padding: 15px 20px 10px 20px;
  background-color: #fcfcfc;
`;
