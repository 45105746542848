import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function PageHeader({
  mainMenu,
  pageName,
  button1Label,
  button1Handle,
  button2Label,
  button2Handle,
  customButton,
  cancelButtonHandler
}) {
  return (
    <div className="pageHeader">
      <div className="textHolder">
        <span className="mainLvl">{mainMenu}</span>
        <span className="pageName">{pageName}</span>
      </div>
      <div className='buttonsHolder'>
        {(cancelButtonHandler) ? (
          <button
            className="buttonBase marginRight"
            type="button"
            onClick={cancelButtonHandler}
          >
            CANCELAR
          </button>
        ) : null}
        {(customButton) ? customButton : null}
        {(button2Label) ? (
          <button
            className="buttonBase marginRight"
            type="button"
            onClick={button2Handle}
          >
            {button2Label.toUpperCase()}
          </button>
        ) : null}
        {(button1Label) ? (
          <button
            className="buttonBase marginRight addButton"
            type="button"
            onClick={button1Handle}
          >
            {button1Label.toUpperCase()}
          </button>
        ) : null}
      </div>
    </div>
  );
}

PageHeader.propTypes = {
  mainMenu: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
  button1Label: PropTypes.string,
  button1Handle: PropTypes.func,
  button2Label: PropTypes.string,
  button2Handle: PropTypes.func,
  customButton: PropTypes.shape({}),
  cancelButtonHandler: PropTypes.func,
};

export default PageHeader;
