import React, { useEffect, useState } from 'react';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import { FaRegTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { person } from '~/routes/paths';
import { FIELDS_ENUM } from '~/util/domainutils';
import PersonClientService from '~/services/person-client-service';
import PersonResaleService from '~/services/person-resale-service';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import { formatCpfCnpj, inputNumber, normalizeText } from '~/util/stringutils';
import TableComponent from '~/components/TableComponent';
import PersonSearchComponent from '../SearchComponent';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { MultipleFields } from './MultipleFields';
import { handleMoveElement } from '~/util/scrollutils';
import {
  ButtonTextDecoration,
  MassActionsContainer,
  StepOneContainer,
  StepTwoContainer,
  StepThreeContainer,
} from './styles';
import './styles.scss';

function MassActions({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [inputCode, setInputCode] = useState('');
  const [inputPessoa, setInputPessoa] = useState('');
  const [inputStatus, setInputStatus] = useState('');
  const [selectedObjectsPerson, setSelectedObjectsPerson] = useState([]);
  const [selectedPerson, setSelectedPerson] = useState([]);
  const [searchResponse, setSearchResponse] = useState([]);
  const [activeStep, setActiveStep] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [inputMassAction, setInputMassAction] = useState('');
  const [selectedFields, setSelectedFields] = useState([]);
  const [error_massAction, setErrorMassAction] = useState(false);
  const [inputsFieldData, setInputsFieldData] = useState([]);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 10,
  });
  const [formData, setFormData] = useState({
    id: null,
    nome: '',
    cpfCnpj: '',
    status: '',
  });

  useEffect(() => {
    if (location.state.pageMain === 'Cliente') {
      setInputsFieldData([
        {
          id: 0,
          field: '',
          value: '',
          show: true,
          error: true,
        },
        {
          id: 1,
          field: '',
          value: '',
          show: false,
          error: true,
        },
      ]);
    }

    if (location.state.pageMain === 'Revenda') {
      setInputsFieldData([
        {
          id: 0,
          field: '',
          value: '',
          show: true,
          error: true,
        },
      ]);
    }
  }, [location.state.pageMain]);

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleInputCodeChange(event) {
    const {
      target: { value },
    } = event;

    setInputCode(inputNumber(value));
  }

  function handleInputPessoaChange(event) {
    const {
      target: { value },
    } = event;

    setInputPessoa(value);
  }

  function handleStatusChange(event) {
    const {
      target: { value },
    } = event;

    setInputStatus(value);
  }

  function handleInputMassActionChange(event) {
    const {
      target: { value },
    } = event;

    setInputMassAction(value);
    setErrorMassAction(false);

    handleScroll('actionsFocusId');
  }

  async function validateForm(success) {
    const data = {};
    if (inputCode.length) {
      data.id = inputCode.trim();
    }
    if (inputPessoa.length) {
      data.textPessoa1 = inputPessoa.trim();
    }
    if (inputStatus.length) {
      data.status = inputStatus;
    }

    setFormData(data);
    await success(data);
  }

  async function findAllByFilter(pageParams, dataParams) {
    setPageParams(pageParams);
    setLoading(true);

    try {
      const { page, rowsPerPage: limit } = pageParams;

      let data = null;

      if (location.state.pageMain === 'Cliente') {
        data = await PersonClientService.getPersonClients({
          ...(dataParams || formData),
          limit,
          offset: page * limit,
        });
      }

      if (location.state.pageMain === 'Revenda') {
        data = await PersonResaleService.getPersonResales({
          ...(dataParams || formData),
          limit,
          offset: page * limit,
        });
      }

      if (data.resultado.length) {
        handleSetSearchResponse(data.resultado, data.total);
        setSelectedObjectsPerson([]);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos clientes para esta pesquisa!', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  function handleCheckedInputRadio(id) {
    const findRow = find(selectedPerson, { id: parseInt(id) });

    return !!findRow;
  }

  function handleAddPersonToSelects(event) {
    const {
      target: { value },
    } = event;

    if (selectedPerson.some(elem => parseInt(elem.id) === parseInt(value))) {
      setSelectedPerson(old => old.filter(
        elem => parseInt(elem.id) !== parseInt(value)
      ));
      setSelectedObjectsPerson(old => old.filter(
        elem => parseInt(elem.id) !== parseInt(value)
      ));
    } else {
      const findPerson = find(searchResponse, { id: parseInt(value) });

      setSelectedPerson(old => [...old, findPerson]);
      setSelectedObjectsPerson(old => [...old, findPerson]);
    }
  }

  function handleRadioSelectsAllPersonChange() {
    const allPersons = [...searchResponse];
    const allPersonWithChecked = selectedPerson.map(elem => parseInt(elem.id));

    if (selectedObjectsPerson.length === pageParameters.rowsPerPage) {
      const allPersonToRemoved = allPersons.map(elem => parseInt(elem.id));

      setSelectedPerson(old => [...old.filter(elem => {
        return !allPersonToRemoved.includes(parseInt(elem.id));
      })]);
      setSelectedObjectsPerson(old => [...old.filter(elem => {
        return !allPersonToRemoved.includes(parseInt(elem.id));
      })]);
    } else {
      const findAllPersonWithoutChecked = allPersons.filter(elem =>
        !allPersonWithChecked.includes(parseInt(elem.id))
      );

      setSelectedPerson(old => [...old, ...findAllPersonWithoutChecked]);
      setSelectedObjectsPerson(old => [...old, ...findAllPersonWithoutChecked]);
    }
  }

  function handleRemovePerson(id) {
    setSelectedPerson(old => old.filter(
      elem => parseInt(elem.id) !== parseInt(id)
    ));
    setSelectedObjectsPerson(old => old.filter(
      elem => parseInt(elem.id) !== parseInt(id)
    ));
  }

  function handleRemoveAllPerson() {
    setSelectedPerson([]);
    setSelectedObjectsPerson([]);
  }

  function handleScroll(elementId) {
    setTimeout(() => {
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  }

  function handleNextStep() {
    if (activeStep === 1) {
      if (!selectedPerson.length) {
        toast.error('É preciso selecionar pelo menos um cliente');
        return;
      }

      setActiveStep(2);
    }

    if (activeStep === 2) {
      let errors_temp = false;

      if (!inputMassAction || !inputMassAction.length) {
        setErrorMassAction(true);
        errors_temp = true;
      }

      if (errors_temp) {
        handleScroll('focusInput');
        return;
      }

      const selectedFieldsData = inputsFieldData.filter(input => input.show);

      if (selectedFieldsData.length === 2) {
        if (selectedFieldsData[0].field === selectedFieldsData[1].field) {
          toast.error('Os campos estão idênticos');
          handleScroll('actionsFocusId');
          return;
        }
      }

      const errorsInputs = [];
      selectedFieldsData.forEach(input => {
        if (!input.field
          || !input.value
          || !input.field.length
          || !input.value.length
        ) {
          errorsInputs.push(true);
        }
      });

      if (errorsInputs.length) {
        toast.error('Preencha os campos obrigatórios');
        handleScroll('actionsFocusId');
        return;
      }

      setSelectedFields(selectedFieldsData);
      setActiveStep(3);
    }

    handleMoveElement('massActionsId', 0);
  }

  function handlePreviousStep() {
    if (activeStep === 4) {
      setActiveStep(3);
    }

    if (activeStep === 3) {
      setActiveStep(2);
    }

    if (activeStep === 2) {
      setActiveStep(1);
    }
  }

  const renderRowComponent = (row, isRemoved) => {
    return [
      activeStep <= 2 && (
        <TableCell key='row_order_0' align='center'>
          <>{!isRemoved ? (
            <div className="radioHolder">
              <div className="inputBoxHolder">
                <input
                  type="checkbox"
                  name="selectPerson"
                  value={row.id}
                  checked={handleCheckedInputRadio(row.id)}
                  onChange={handleAddPersonToSelects}
                />
                <span className="checkmark">
                  {handleCheckedInputRadio(row.id)
                    && (
                      <div className="markHolder">
                        <div className="checkmark_stem" />
                        <div className="checkmark_kick" />
                      </div>
                    )}
                </span>
              </div>
            </div>
          ) : (
            <div className='containerDeleteIcon'>
              <FaRegTrashAlt
                className='deleteIcon'
                onClick={() => handleRemovePerson(row.id)}
                title="Deletar"
              />
            </div>
          )}</>
        </TableCell>
      ),
      <TableCell key='row_order_1'>{row.id}</TableCell>,
      <TableCell key='row_order_2'>{row.nomeFantasia}</TableCell>,
      <TableCell key='row_order_3'>{formatCpfCnpj(row.cpfCnpj)}</TableCell>,
      <TableCell key='row_order_4'>{row.contato.telefone1}</TableCell>,
      <TableCell key='row_order_5'>{row.status}</TableCell>,
    ];
  };

  function handleAddFields(event) {
    event.preventDefault();

    const selectedFieldsData = inputsFieldData.filter(input => !input.show);

    if (selectedFieldsData.length) {
      const fields = [...inputsFieldData];

      const [tempField] = selectedFieldsData.map(field => {
        return { ...field, show: true };
      });

      for (let index = 0; index < fields.length; index += 1) {
        if (fields[index].id === tempField.id) {
          fields[index] = tempField;
        }
      }

      setInputsFieldData(fields);
      handleScroll('actionsFocusId');
    }
  }

  function handleRemoveField(inputId) {
    const selectedFieldsData = inputsFieldData.filter(input => input.id === inputId);

    if (selectedFieldsData.length) {
      const fields = [...inputsFieldData];

      const [tempField] = selectedFieldsData.map(field => {
        return { ...field, show: false, field: '' };
      });

      for (let index = 0; index < fields.length; index += 1) {
        if (fields[index].id === tempField.id) {
          fields[index] = tempField;
        }
      }

      setInputsFieldData(fields);
    }
  }

  function handleDisabledAddField() {
    const selectedFieldsData = inputsFieldData.map(input => input.show);

    return !selectedFieldsData.includes(false);
  }

  function validateFormMassActions(success) {
    const data = {};

    if (activeStep === 3) {
      data.ids = selectedPerson.map(pessoa => pessoa.id);

      selectedFields.forEach(input => {
        if (input.field === 'FIELED_PERSON_STATUS') {
          data.status = input.value;
        }

        if (input.field === 'FIELED_KEY_RENEWAL_TERM') {
          data.prazoRenovacaoChave = input.value;
        }
      });

      success(data);
    }
  }

  async function handleSubmitMassActions() {
    validateFormMassActions(async (formData) => {
      setLoading(true);
      try {
        let result = null;

        if (location.state.pageMain === 'Cliente') {
          const { data } = await api.put(
            endpoints.person.client.updateManyPersonClient,
            { ...formData },
          );

          result = data;
        }

        if (location.state.pageMain === 'Revenda') {
          const { data } = await api.put(
            endpoints.person.resale.updateManyPersonResale,
            { ...formData },
          );

          result = data;
        }

        if (result.data && result.data.ok) {
          let timer = 100;
          if (typeof result.message === 'string') {
            toast(result.message, { type: toast.TYPE.SUCCESS });
          } else {
            toast('Alguns clientes apresentaram erros ao realizar as ações',
              { type: toast.TYPE.ERROR });

            result.message.forEach((message, index) => {
              setTimeout(() => {
                toast(message, { type: toast.TYPE.ERROR, autoClose: 6000 });
              }, (index + 1) * 300);

              timer += 300;
            });
          }

          setTimeout(() => {
            if (location.state.pageMain === 'Cliente') {
              history.push(person.personClient);
            }

            if (location.state.pageMain === 'Revenda') {
              history.push(person.personResale);
            }
          }, timer);
        }
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });
  }

  function handlePrazoRenovacaoChave() {
    if (location.state.pageMain === 'Cliente') {
      return true;
    }

    if (location.state.pageMain === 'Revenda') {
      return false;
    }
  }

  return (
    <MassActionsContainer>
      <Modal loading show={loading} />
      <SidebarPageHeader
        mainMenu="Pessoa"
        pageName="Ações em Massa"
        breadcrumbs={[{
          label: location.state.pageMain,
          link: location.state.goBack,
        }]}
        customButtonsBeforeReport={[
          (<button
            key='cancel-button-0'
            className="buttonBase baseWebMobile addMargin"
            onClick={() => history.push(location.state.goBack)}
          >
            CANCELAR
          </button>),
          activeStep >= 2 && (
            <button
              key='back-button-1'
              className="buttonBase baseWebMobile addMargin"
              onClick={handlePreviousStep}
            >
              VOLTAR
            </button>
          ),
          activeStep !== 3 && (
            <button
              type='submit'
              key='next-button-2'
              className="buttonBase baseWebMobile addMargin baseMain"
              onClick={handleNextStep}
            >
              AVANÇAR
            </button>
          ),
          activeStep === 3 && (
            <button
              type='submit'
              key='save-button-3'
              className="buttonBase baseWebMobile addMargin baseMain"
              onClick={handleSubmitMassActions}
            >
              SALVAR
            </button>
          ),
        ]}
        openButtonSelect
      />
      <div id='massActionsId' />
      <div className="defaultFormContainer">
        <form className="defaultForm">
          <div className="infoCard noPadding">
            {activeStep === 1 && (
              <StepOneContainer>
                <div id='title-table-search'>
                  {location.state.pageMain === 'Cliente' && (
                    <span>
                      <b>Seleção de cliente</b>{' '}
                      (os cliente selecionados aparecerão na segunda tabela abaixo)
                    </span>
                  )}
                  {location.state.pageMain === 'Revenda' && (
                    <span>
                      <b>Seleção de revenda</b>{' '}
                      (as revendas selecionadas aparecerão na segunda tabela abaixo)
                    </span>
                  )}
                </div>
                <PersonSearchComponent
                  isMassActions
                  userType={normalizeText(location.state.pageMain)}
                  userCanViewColumnRevenda={false}
                  inputPessoa1Value={inputPessoa}
                  handlePessoa1Change={handleInputPessoaChange}
                  handleSubmit={handleSubmit}
                  codeValue={inputCode}
                  handleCodeChange={handleInputCodeChange}
                  noFieldStatus={false}
                  statusValue={inputStatus}
                  handleStatusChange={handleStatusChange}
                  noTitleSearch={false}
                />
                <TableComponent
                  headerLabels={[
                    { text: 'Código', width: '10%' },
                    { text: 'Nome / Fantasia', width: '25%' },
                    { text: 'CPF / CNPJ', width: '20%' },
                    { text: 'Telefone', width: '15%' },
                    { text: 'Status', width: '15%' },
                  ]}
                  dataObjects={searchResponse}
                  renderRowComponent={renderRowComponent}
                  totalItems={totalItems}
                  handlePageUpdate={findAllByFilter}
                  useCustomActions
                  useCustomSelectsAdd
                  selectedObjectsPersonData={selectedObjectsPerson}
                  handleRadioSelectsAllPersonChange={
                    handleRadioSelectsAllPersonChange
                  }
                  initialRowsPerPage10
                  initialRowsPerPage={10}
                />
              </StepOneContainer>
            )}
            {activeStep === 3 && (
              <StepThreeContainer>
                <div id='title-table-selected'>
                  <span><b>Resumo</b></span>
                </div>
                <TableComponent
                  headerLabels={[
                    { text: 'Campo', width: '50%' },
                    { text: 'Novo Valor', width: '50%' },
                  ]}
                  dataObjects={selectedFields}
                  renderRowComponent={(row) => [
                    <TableCell key='row_order_1'>
                      {FIELDS_ENUM[row.field].label}
                    </TableCell>,
                    <TableCell key='row_order_2'>{row.value}</TableCell>,
                  ]}
                  useCustomActions
                />
              </StepThreeContainer>
            )}
            <div id='title-table-selected'>
              {location.state.pageMain === 'Cliente' && (
                <>
                  {activeStep <= 2 ? (
                    <span><b>Clientes selecionados</b></span>
                  ) : (
                    <span><b>Clientes</b></span>
                  )}
                </>
              )}
              {location.state.pageMain === 'Revenda' && (
                <>
                  {activeStep <= 2 ? (
                    <span><b>Revendas selecionadas</b></span>
                  ) : (
                    <span><b>Revendas</b></span>
                  )}
                </>
              )}
            </div>
            <TableComponent
              headerLabels={[
                { text: 'Código', width: '10%' },
                { text: 'Nome / Fantasia', width: '25%' },
                { text: 'CPF / CNPJ', width: '20%' },
                { text: 'Telefone', width: '15%' },
                { text: 'Status', width: '15%' },
              ]}
              dataObjects={selectedPerson}
              renderRowComponent={(row) => renderRowComponent(row, true)}
              handlePageUpdate={findAllByFilter}
              useCustomActions
              handleRemoveAllPerson={handleRemoveAllPerson}
              useCustomSelectsRemove={activeStep <= 2}
            />
          </div>
          {activeStep === 2 && (
            <StepTwoContainer>
              <div className="infoCard">
                <span id="title">Ação que deseja realisar</span>
                <div className="rowContainer">
                  <div className="inputHolder">
                    {error_massAction && (
                      <div id='focusInput' />
                    )}
                    <span>Ação *</span>
                    <select
                      name="mass-action"
                      value={inputMassAction}
                      onChange={handleInputMassActionChange}
                    >
                      <option value={''}>Selecione</option>
                      <option value={'ACTION_EDIT'}>Editar</option>
                    </select>
                    {error_massAction && (
                      <small style={{ color: 'red' }}>Campo obrigatório</small>
                    )}
                  </div>
                </div>
              </div>
              {inputMassAction && (
                <div className="infoCard">
                  <span id="title">
                    {`Campo de ${location.state.pageMain} que pode mudar em massa`}
                  </span>
                  {inputsFieldData.map((input, index) => (
                    <MultipleFields
                      key={input.id}
                      fieldId={input.id}
                      fieldIndex={index}
                      fieldValue={input.value}
                      fieldLabel={input.field}
                      initialShow={input.show}
                      inputsFieldData={inputsFieldData}
                      setInputsFieldData={setInputsFieldData}
                      handleRemoveField={() => handleRemoveField(input.id)}
                      handleButtonRemove={
                        location.state.pageMain === 'Cliente'
                          ? handleDisabledAddField()
                          : false
                      }
                      noPrazoRenovacaoChave={handlePrazoRenovacaoChave()}
                    />
                  ))}
                  <div style={{ marginTop: '10px' }}>
                    <ButtonTextDecoration
                      disabledButton={handleDisabledAddField()}
                      onClick={handleAddFields}
                    >
                      <span>Adicionar Campo</span>
                    </ButtonTextDecoration>
                  </div>
                  <div id='actionsFocusId' />
                </div>
              )}
            </StepTwoContainer>
          )}
        </form>
      </div>
    </MassActionsContainer>
  );
}

MassActions.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      goBack: PropTypes.string.isRequired,
      pageMain: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default MassActions;
