import React from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import { AttachmentDownload } from '~/components/AttachmentDownload';
import './styles.scss';

Attachments.propTypes = {
  attachments: PropTypes.array.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default function Attachments({ attachments, setLoading }) {

  async function handleDownloadFile(event, itemId, fileName) {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await api.get(
        endpoints.commercial.order.getAnexoFile(itemId),
        {
          responseType: 'blob'
        }
      );
      let blob = new Blob([response.data]);
      let url = window.URL.createObjectURL(blob);

      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toast('Download de Arquivo realizado com sucesso!', { type: toast.TYPE.SUCCESS });
        setLoading(false);
      }, 0);
    } catch (error) {
      setLoading(false);
      showMessageError(error);
    }
  }

  return (
    <div className='orderAttachments'>
      {(attachments || []).map(({ anexo }) => {
        return (
          <AttachmentDownload
            key={anexo.id}
            attachmentName={anexo.nome}
            handleDownloadFile={
              e => handleDownloadFile(e, anexo.id, anexo.nome)
            }
          />
        );
      })}
    </div>
  );
}
