import { all, call, put, takeLatest, fork, delay } from 'redux-saga/effects';
import { TOKEN_KEY, USERINFO_KEY } from '~/util/auth';
import { setAttemptSignIn, getAttemptSignIn } from '~/util/localstorageutils';
import api, { endpoints } from '~/services/api';
import history from '~/routes/history';
import { showMessageError } from '~/util/errorutils';
import { actionTypes, signInSuccess, signInFailure, changeLoading } from './actions';
import { getInitialState } from '../lifecycle/actions';
import { visibleChatMovidesk } from '~/styles/movidesk';
import { authenticationPaths } from '~/routes/paths';
import { RUNTIME_API } from '~/services/apiConfig';
import { toast } from 'react-toastify';

function loadUserPermissions(user) {
  return user.grupo.grupoPermissaos.map(
    permission => permission.permissao.codigo
  );
}

export function* loadToken() {
  const token = localStorage.getItem(TOKEN_KEY);
  const userInfo = localStorage.getItem(USERINFO_KEY);

  const userInfoJson = JSON.parse(userInfo);

  if (!token || !userInfo) {
    yield put(signInFailure());
  } else {
    api.defaults.headers.token = token;

    if (!Object.keys(userInfoJson).includes('mfa')) {
      yield put(signInFailure());
      localStorage.clear();
    }

    yield put(signInSuccess(token, userInfoJson));
  }
}

export function* syncUserAuthenticate(token, userInfo) {
  if (token && userInfo) {
    const userPermissions = loadUserPermissions(userInfo);
    userInfo.permissions = [...userPermissions];

    localStorage.setItem(TOKEN_KEY, token);
    localStorage.setItem(USERINFO_KEY, JSON.stringify(userInfo));

    yield put(getInitialState());
    yield put(signInSuccess(token, userInfo));

    history.push(RUNTIME_API.BASE_URL_WEB);
    visibleChatMovidesk();

    setAttemptSignIn({ clear: true });
  } else {
    yield put(signInFailure());
  }
}

export function* signInSSO({ payload }) {
  const { email } = payload;

  try {
    const { data: response } = yield call(
      api.post,
      endpoints.authentication.sso,
      { email }
    );

    if (!response.data) {
      yield put(changeLoading(false));

      history.push(authenticationPaths.login);

      toast.error(response.message);
    } else {
      const { token = null, userInfo, mfaAtivo } = response.data;

      userInfo.mfa = mfaAtivo;

      if (token) {
        api.defaults.headers.token = token;
        yield delay(500);
      }

      if (mfaAtivo) {
        yield put(changeLoading(false));

        history.push(authenticationPaths.validateMfa, {
          token,
          userInfo,
          login: '',
          senha: '',
          isSingleSingOn: true
        });
      } else {
        yield syncUserAuthenticate(token, userInfo);
      }
    }
  } catch (error) {
    yield put(changeLoading(false));

    history.push(authenticationPaths.login);

    showMessageError(error, 'Erro ao efetuar o login com SSO!');
  }
}

export function* signIn({ payload }) {
  const { login, password, reCaptchaToken } = payload;
  const [attempt, block] = getAttemptSignIn();

  try {
    const { data: response } = yield call(
      api.post,
      endpoints.authentication.signin,
      { login, senha: password, tentativa: attempt, reCaptchaToken }
    );

    const { token = null, userInfo, mfaAtivo } = response.data;

    userInfo.mfa = mfaAtivo;

    if (token) {
      api.defaults.headers.token = token;
      yield delay(500);
    }

    if (mfaAtivo) {
      yield put(changeLoading(false));
      history.push(authenticationPaths.validateMfa, {
        token,
        userInfo,
        login,
        senha: password,
        isSingleSingOn: false
      });
    } else {
      yield syncUserAuthenticate(token, userInfo);
    }
  } catch (error) {
    setAttemptSignIn({ clear: false });

    if (block === 'true') {
      toast.error('Acesso bloqueado. Entre em contato com a equipe de canais para solicitar o desbloqueio');
    } else {
      showMessageError(error, 'Erro ao efetuar o login!');
    }

    yield put(signInFailure());
  }
}

export function* signInMfa({ payload }) {
  const { tokenMfa, token, userInfo } = payload;

  try {
    const { data: response } = yield call(
      api.post,
      endpoints.authentication.validateMfa,
      { token: tokenMfa, tokenUserId: userInfo.id }
    );

    if (response?.data?.ok) {
      yield syncUserAuthenticate(token, userInfo);
    } else {
      yield put(signInFailure());
    }
  } catch (error) {
    showMessageError(error, 'Erro ao efetuar o login com mfa!');
    yield put(signInFailure());
  }
}

function signOut() {
  // authenticationUserDisconnect();
  localStorage.clear();
}

export default all([
  fork(loadToken),
  takeLatest(actionTypes.AUTH_SIGN_IN_REQUEST_MFA, signInMfa),
  takeLatest(actionTypes.AUTH_SIGN_IN_REQUEST, signIn),
  takeLatest(actionTypes.AUTH_SIGN_IN_REQUEST_SSO, signInSSO),
  takeLatest(actionTypes.AUTH_SIGN_OUT, signOut),
]);
