import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import './styles.scss';

function AutoCompleteOn(autoCompleteOnProps) {
  const {
    name,
    inputDataValue,
    suggestions,
    isShowSuggestions,
    handleDataChange,
    handleGetSuggestionKey,
    handleOnClick,
    handleGetSuggestionLabel,
    customMessage,
    isDisabled = false,
  } = autoCompleteOnProps;

  const [activeSuggestion, setActiveSuggestion] = useState(0);

  const onKeyDown = e => {
    if (e.keyCode === 13) {
      const [suggestion] = suggestions.filter((_, index) => index === activeSuggestion);

      if (suggestion) {
        e.preventDefault();

        handleOnClick(suggestion);

        setActiveSuggestion(0);
      }
    }

    if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      setActiveSuggestion(active => active - 1);
    }

    if (e.keyCode === 40) {
      if (activeSuggestion + 1 === suggestions.length) {
        return;
      }

      setActiveSuggestion(active => active + 1);
    }
  };

  return (
    <>
      <input
        type='text'
        name={name}
        onKeyDown={onKeyDown}
        value={inputDataValue}
        disabled={isDisabled}
        onChange={handleDataChange}
      />
      {isShowSuggestions && inputDataValue && (
        <Fragment>
          {suggestions.length ? (
            <nav className='suggestions-container'>
              <ul
                id={'suggestion-list'}
                className="suggestions suggestions-full"
              >
                {suggestions.map((suggestion, index) => (
                  <li
                    className={index === activeSuggestion ? 'suggestion-active' : ''}
                    key={handleGetSuggestionKey(suggestion)}
                    value={handleGetSuggestionKey(suggestion)}
                    onClick={() => handleOnClick(suggestion)}
                  >
                    {handleGetSuggestionLabel(suggestion)}
                  </li>
                ))}
              </ul>
            </nav>
          ) : (
            <div className="no-suggestions">
              {customMessage
                ? customMessage
                : <em>Não foram encontradas opções.</em>
              }
            </div>
          )}
        </Fragment>
      )}
    </>
  );
}

AutoCompleteOn.propTypes = {
  inputDataValue: PropTypes.string.isRequired,
  isShowSuggestions: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  suggestions: PropTypes.array.isRequired,
  handleDataChange: PropTypes.func.isRequired,
  handleGetSuggestionKey: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleGetSuggestionLabel: PropTypes.func.isRequired,
  customMessage: PropTypes.string,
  name: PropTypes.string,
  indexActive: PropTypes.number,
};

export { AutoCompleteOn };
