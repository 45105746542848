import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { signInRequestsSSO, signOut } from '~/store/modules/auth/actions';
import { initialPaths, authenticationPaths } from '~/routes/paths';
import Modal from '~/components/Modal';
import { BackgroundLogin } from '~/components/BackgroundLogin';
import { TOKEN_KEY } from '~/util/auth';

function SingleSignOn({ history, location }) {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const { signed } = useSelector(state => state.auth);
  const authenticated = localStorage.getItem(TOKEN_KEY) || signed;
  const query = new URLSearchParams(location.hash.substring(1));
  const isParametersSSO = query.get('code') && query.get('client_info') && query.get('state') && query.get('session_state');

  useEffect(() => {
    instance.handleRedirectPromise()
      .then(async (data) => {
        try {

          if (!isParametersSSO) {
            if (authenticated) {
              history.push(initialPaths.pageInitial);
            } else {
              history.push(authenticationPaths.login);
            }

            return null;
          }

          const email = data?.idTokenClaims?.email?.toLowerCase() || '';

          dispatch(signInRequestsSSO(email));
        } catch (error) {
          dispatch(signOut());
          history.push(authenticationPaths.login);
          toast.error('Erro ao efetuar o login com SSO!');
        }
      })
      .catch(() => {
        dispatch(signOut());
        history.push(authenticationPaths.login);
        toast.error('Erro ao efetuar o login com SSO!');
      });
  }, [instance]);

  return (
    <BackgroundLogin>
      <Modal loading show={true} />
    </BackgroundLogin>
  );
}

SingleSignOn.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

export default SingleSignOn;
