import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Copyright } from '~/components/Copyright';
import { showMessageError } from '~/util/errorutils';
import api, { endpoints } from '~/services/api';
import Modal from '~/components/Modal';
import { unFormatCpfCnpj } from '~/util/stringutils';
import { BackgroundLogin } from '~/components/BackgroundLogin';
import { authenticationPaths } from '~/routes/paths';
import { hiddenChatMovidesk } from '~/styles/movidesk';
import MarcaReduzidaPositiva from
  '~/assets/marca/reduzida/SVG/CM-marca_reduzida-positiva-RGB.svg';
import marcaPreferencialNegativa from
  '~/assets/marca/preferencial/SVG/CM-marca_preferencial-negativa-RGB.svg';
import './styles.scss';

function RequestResetPassword({ history }) {
  const [loading, setLoading] = useState(false);
  const [cpfCnpj, setCpfCnpj] = useState('');

  useEffect(() => {
    hiddenChatMovidesk();
  }, []);

  async function handleSRequestResetPassword(e) {
    e.preventDefault();
    if (!cpfCnpj) {
      toast('Preencha o CPF/CNPJ!', { type: toast.TYPE.ERROR });
    } else {
      try {
        setLoading(true);
        const { data: response } = await api.post(
          endpoints.authentication.requestPasswordReset,
          { cpfCnpj: unFormatCpfCnpj(cpfCnpj.cpfCnpj) },
        );
        toast('O link de recuperação de senha foi enviado para o seguinte e-mail: ' + response.data.email, {
          type: toast.TYPE.SUCCESS,
          autoClose: 6000,
        });
        history.push(authenticationPaths.login);
      } catch (error) {
        showMessageError(error, 'Erro ao solicitar recuperação de senha!');
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <BackgroundLogin>
      <div className="recoverContainer">
        <Modal show={loading} loading />
        <div>
          <img
            src={marcaPreferencialNegativa}
            alt="Marca Preferencial Negativa Casa Maganhães"
            loading='lazy'
          />
          <div className="content">
            <div className="header">
              <img
                src={MarcaReduzidaPositiva}
                alt="Marca Reduzida Casa Magalhães"
                loading='lazy'
              />
              <div>Solicite a Recuperação de Senha</div>
            </div>
            <form className="form" onSubmit={handleSRequestResetPassword}>
              <span className="title">Preencha o CPF/CNPJ</span>
              <input
                type="text"
                placeholder={'CPF/CNPJ'}
                onChange={e => setCpfCnpj({ cpfCnpj: e.target.value })}
              />
              <div>
                <Link to={authenticationPaths.login}>
                  <button type="button" className="resetPassword">CANCELAR</button>
                </Link>
                <button className="sigin" type="submit">SOLICITAR</button>
              </div>
            </form>
          </div>
          <Copyright />
        </div>
      </div>
    </BackgroundLogin>
  );
}

RequestResetPassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};


export default withRouter(RequestResetPassword);
