import api, { endpoints } from '~/services/api';

class ReasonSolicitationService {
  static getReasonsSolicitation = async (params) => {
    const { data: result } = await api.post(
      endpoints.commercial.reasonSolicitation.getReasonSolicitation,
      { ...params }
    );

    return result.data;
  }

  static getReasonSolicitation = async ({ text, page, rowsPerPage }) => {
    const { data: response } = await api.post(
      endpoints.commercial.reasonSolicitation.getReasonSolicitation,
      {
        text,
        limit: page * rowsPerPage + rowsPerPage,
        offset: page * rowsPerPage,
      }
    );

    return response.data;
  }

  static createReasonSolicitation = async (formData) => {
    const { data: response } = await api.post(
      endpoints.commercial.reasonSolicitation.createReasonSolicitation,
      { ...formData }
    );

    return response;
  }

  static updateReasonSolicitation = async (formData) => {
    const { data: response } = await api.put(
      endpoints.commercial.reasonSolicitation.updateReasonSolicitation,
      { ...formData }
    );

    return response;
  }

  static deleteReasonSolicitation = async (id) => {
    const { data: response } = await api.delete(
      endpoints.commercial.reasonSolicitation.deleteReasonSolicitation(id)
    );

    return response.data;
  }
}

export { ReasonSolicitationService };
