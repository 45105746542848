import styled from 'styled-components';

export const FillFormsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FillFormsHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--cm-primary);
  padding: 10px 20px;

  button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    color: var(--cm-primary);

    span {
      margin: 0 11px;
    }
  }

  button.desativer {
    color: #00000042;
    cursor: default;
  }
`;

export const FormsContentNav = styled.nav`
  display: flex;
  cursor: pointer;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24px;
    width: 24px;
    border: 1px solid #3B873E;
    border-radius: 12px;
    color: #FFFFFF;
    background-color: var(--cm-primary);
    font-weight: bold;
    text-align: center;
    padding-bottom: 2px;
    font-size: 12px;
  }

  button.formComplete {
    border: 1px solid var(--cm-primary);
    background-color: #3B873E;
  }

  button.formDesativado {
    border: 1px solid var(--cm-primary);
    background-color: #FFFFFF;
    color: var(--cm-primary);
  }

  button + button {
    margin-left: 16px;
  }
`;

export const ContainerRenderForm = styled.div`
  width: calc(100% - 40px);
  margin: 0 0 20px 20px;
`;
