import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { STATUS_ORDER_ENUM } from '~/util/domainutils';
import { CollapsibleFilter } from '~/components/CollapsibleFilter';
import { InputPerson } from '~/components/InputPerson';
import './styles.scss';

function SearchComponent(props) {
  const {
    countFilter,
    handleSubmit,
    inputClienteValue,
    handleClienteChange,
    inputRevendaValue,
    handleRevendaChange,
    idValue,
    handleIdChange,
    handleDataFimChange,
    handleDataInicioChange,
    dataFim,
    dataInicio,
    radioStatus,
    handleRadioStatusChange,
    userCanViewColumnRevenda,
  } = props;

  return (
    <CollapsibleFilter countFilters={countFilter}>
      <form className="formContainer" onSubmit={handleSubmit}>
        <div className="rowContainer">
          <div id="idHolder" className="inputHolder">
            <span>Nr.</span>
            <input
              type="text"
              name="name"
              value={idValue}
              onChange={handleIdChange}
            />
          </div>
          <InputPerson
            title1='Cliente'
            inputPerson1={inputClienteValue}
            handlePerson1Change={handleClienteChange}
            title2='Revenda'
            inputPerson2={inputRevendaValue}
            handlePerson2Change={handleRevendaChange}
            userCanViewPerson2={userCanViewColumnRevenda}
          />
          <div className="inputHolder noMarginRight">
            <span>Período</span>
            <div id="dates">
              <InputMask
                mask="99/99/9999"
                name="initialDate"
                value={dataInicio}
                onChange={handleDataInicioChange}
              />
              <span id="dateSeparator">à</span>
              <InputMask
                mask="99/99/9999"
                style={{ paddingLeft: '10px' }}
                name="finalDate"
                value={dataFim}
                onChange={handleDataFimChange}
              />
            </div>
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Status</span>
            <div id="statusHolder">
              {Object.keys(STATUS_ORDER_ENUM).map((key, index) => (
                STATUS_ORDER_ENUM[key].showInSearch && (
                  <div key={`${STATUS_ORDER_ENUM[key]}-${index}`}
                    className="radioHolder"
                  >
                    <div className="inputBoxHolder">
                      <input
                        type="checkbox"
                        name="status"
                        value={STATUS_ORDER_ENUM[key].value}
                        checked={radioStatus.some(
                          elem => elem === STATUS_ORDER_ENUM[key].value
                        )}
                        onChange={handleRadioStatusChange}
                      />
                      <span className="checkmark">
                        {radioStatus.some(
                          elem => elem === STATUS_ORDER_ENUM[key].value
                        )
                          && (
                            <div className="markHolder">
                              <div className="checkmark_stem" />
                              <div className="checkmark_kick" />
                            </div>
                          )}
                      </span>
                    </div>
                    <span id="radioLabel">
                      {STATUS_ORDER_ENUM[key].label}
                    </span>
                  </div>
                )
              ))}
            </div>
          </div>
          <button
            id="baseButtonIcon"
            className="buttonBaseOnly"
            onClick={handleSubmit}
          >
            <FaSearch id="baseNameIcon" />
            <span>BUSCAR</span>
          </button>
        </div>
      </form>
    </CollapsibleFilter>
  );
}

SearchComponent.propTypes = {
  countFilter: PropTypes.number,
  handleSubmit: PropTypes.func,
  inputClienteValue: PropTypes.string,
  handleClienteChange: PropTypes.func,
  inputRevendaValue: PropTypes.string,
  handleRevendaChange: PropTypes.func,
  userCanViewColumnRevenda: PropTypes.bool,
  idValue: PropTypes.string,
  handleIdChange: PropTypes.func,
  dataInicio: PropTypes.string,
  handleDataInicioChange: PropTypes.func,
  dataFim: PropTypes.string,
  handleDataFimChange: PropTypes.func,
  radioStatus: PropTypes.array,
  handleRadioStatusChange: PropTypes.func,
};

export default SearchComponent;
