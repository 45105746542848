import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { updateShowAgainModality } from '~/store/modules/lifecycle/actions';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import groupBy from 'lodash/groupBy';
import includes from 'lodash/includes';
import ProductModuleService from '~/services/product-module-service';
import { showMessageError } from '~/util/errorutils';
import ImageDefault200x200 from '~/assets/200x200.jpg';
import { handleMoveElement } from '~/util/scrollutils';
import { TableShoppingCart } from '../../../Items/TableShoppingCart';
import { InputCheckbox } from '~/components/InputCheckbox';
import { OrderItemModule } from '~/components/OrderItemModule';
import Modal from '~/components/Modal';
import * as S from './styles';

function SelectProducts(props) {
  const {
    activeStep,
    selectedSoftware,
    selectedModality,
    softwares,
    modules,
    modulesTotal,
    modalities,
    items,
    activeSteps,
    setSelectedSoftware,
    setSelectedModality,
    setModules,
    setModulesTotal,
    setItems,
    setForms,
    setAllFormsToAnswer,
    setAnswerForms,
    setFormsToAnswer,
    setActiveSteps,
    handleAddModule,
    handleRemoveModule,
    userCanEdit,
    handleSubmit,
    calculateTotalAdesao,
    calculateTotalMensalidade,
    setLoading,
    canShowAgainDialogModality,
  } = props;

  const dispatch = useDispatch();
  const [openMenuModality, setOpenMenuModality] = useState(false);
  const [confirmedRemoval, setConfirmedRemoval] = useState(false);
  const [showChangeModality, setShowChangeModality] = useState(false);

  function countItems(itemCheck) {
    const hasItem = items.filter(item => item.id === itemCheck.id);
    if (hasItem && hasItem.length) {
      return hasItem[0].count;
    }
    return 0;
  }

  function countCostTotal() {
    let total = 0;
    items.forEach((item) => {
      total += item.count;
    });
    return total;
  }

  async function handleSelectedSoftwareChange(selectedSoftwareValue) {
    try {
      setLoading(true);
      const [modulesFilted, modulesTotal] = await Promise.all([
        ProductModuleService.getProductModules({
          softwareId: selectedSoftwareValue.id,
          modalidadeId: (selectedModality || {}).id,
        }),
        ProductModuleService.getProductModules({
          modalidadeId: (selectedModality || {}).id,
        })
      ]);

      if (modulesFilted.resultado.length && modulesTotal.resultado.length) {
        setSelectedSoftware(selectedSoftwareValue);
        setModules(orderBy(modulesFilted.resultado, 'posicaoEmSoftware'));
        setModulesTotal(orderBy(modulesTotal.resultado, 'posicaoEmSoftware'));
      } else {
        setModules([]);
        setModulesTotal([]);
        setSelectedSoftware(selectedSoftwareValue);
        toast('Não encontramos produtos de softwares para esta pesquisa!', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      setModules([]);
      showMessageError(error);
      setSelectedSoftware(selectedSoftwareValue);
    } finally {
      setLoading(false);
    }
  }

  async function HandleSelectedModalityChange(selectedModalityValue) {
    try {
      setLoading(true);

      const response = await ProductModuleService.getProductModules({
        modalidadeId: selectedModalityValue.id,
      });

      setItems([]);
      if (response.resultado.length) {
        const grouped = groupBy(response.resultado, 'softwareId');

        const groupIds = (Object.keys(grouped) || []).filter((groupId) => {
          const handleSoftwareCheck = find(softwares, { id: Number(groupId) });
          return handleSoftwareCheck ? handleSoftwareCheck.ativo : false;
        });

        let softwareTemp = null;
        let modulesTemp = response.resultado;
        let modulesTotalTemp = response.resultado;

        if (groupIds.length === 1) {
          const [softwareIdToCheck] = groupIds;

          softwareTemp = find(softwares, { id: Number(softwareIdToCheck) });
          modulesTemp = grouped[softwareIdToCheck];
          modulesTotalTemp = grouped[softwareIdToCheck];
        }

        const activeStepsTemp = activeSteps.filter(
          step => step !== 'ANSWERED_FORMS'
        );

        setSelectedModality(selectedModalityValue);
        setSelectedSoftware(softwareTemp);
        setForms([]);
        setAnswerForms([]);
        setFormsToAnswer([]);
        setAllFormsToAnswer([]);
        setActiveSteps(activeStepsTemp);
        setModules(orderBy(modulesTemp, 'posicaoEmSoftware'));
        setModulesTotal(orderBy(modulesTotalTemp, 'posicaoEmSoftware'));
      } else {
        setSelectedModality(selectedModalityValue);
        setSelectedSoftware(null);
        setModules([]);

        toast('Não encontramos produtos de softwares para esta pesquisa!', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      showMessageError(error);
      setSelectedModality(selectedModalityValue);
      setSelectedSoftware(null);
      setModules([]);
    } finally {
      setLoading(false);
      setConfirmedRemoval(false);
      setOpenMenuModality(false);
    }
  }

  const HANDLE_ACTIVE_SELETED_SOFTWARE = () => {
    return selectedSoftware
      && selectedSoftware.id
      && (selectedModality && selectedModality.id);
  };

  function handleSetDoNotShowAgainChange(event) {
    const {
      target: {
        value
      }
    } = event;

    dispatch(updateShowAgainModality(value !== 'true'));
  }

  function handleOnMenuOpen() {
    const canOpenMenu = items.length > 0
      && !canShowAgainDialogModality
      && !confirmedRemoval;

    if (canOpenMenu) {
      setShowChangeModality(true);
    } else {
      setOpenMenuModality(!openMenuModality);
    }
  }

  function handleConfirmRemovalChange(event) {
    event.preventDefault();
    setShowChangeModality(false);
    setConfirmedRemoval(true);
    setOpenMenuModality(true);
  }

  return (
    <S.CustomContainer>
      <S.OrderSoftwareFormContainer onSubmit={handleSubmit}>
        {HANDLE_ACTIVE_SELETED_SOFTWARE() && (
          <S.ShoppingCartContent onClick={() => handleMoveElement('shoppingCartId')}>
            <S.ShoppingCartContainer>
              {countCostTotal() > 0 && (
                <S.ShoppingCartBadge>
                  {countCostTotal()}
                </S.ShoppingCartBadge>
              )}
              <S.ShoppingCartIcon size={28} color='white' />
            </S.ShoppingCartContainer>
            <div id='priceHolder'>
              <span>
                Adesão
                <S.InputCurrencyFormat
                  className='price'
                  value={calculateTotalAdesao(items)}
                />
              </span>
              <span>
                Mensalidade
                <S.InputCurrencyFormat
                  className='price'
                  value={calculateTotalMensalidade(items)}
                />
              </span>
            </div>
          </S.ShoppingCartContent>
        )}
        <S.ContainerContent>
          <span id="title">
            Escolha a modalidade/sistema e adicione módulos *
          </span>
          <S.SoftwareSelectedContainer>
            <S.InputSelect
              menuIsOpen={openMenuModality}
              placeholder="Selecione uma Modalidade..."
              className="selectSoftware"
              onMenuOpen={handleOnMenuOpen}
              onMenuClose={() => setOpenMenuModality(false)}
              isSearchable={false}
              onChange={(item) => HandleSelectedModalityChange(modalities[item.value])}
              options={modalities.map((item, index) => {
                return {
                  value: index,
                  label: (
                    <div className="softwareItem">
                      <span id="softwareName">
                        {item.nome}
                      </span>
                    </div>
                  ),
                };
              })}
              value={
                selectedModality
                  ? {
                    value: selectedModality.id,
                    label: (
                      <div className="softwareItem">
                        <span id="softwareName">
                          {selectedModality.nome}
                        </span>
                      </div>
                    ),
                  }
                  : null
              }
            />
            {selectedModality && (
              <S.InputSelect
                placeholder="Selecione um Software..."
                className="selectSoftware"
                isSearchable={false}
                onMenuOpen={() => setOpenMenuModality(false)}
                value={
                  selectedSoftware
                    ? {
                      value: selectedSoftware.id,
                      label: (
                        <div className="softwareItem">
                          <img
                            src={
                              selectedSoftware.logo &&
                                selectedSoftware.logo.length &&
                                selectedSoftware.logo !== 'Base64 image'
                                ? selectedSoftware.logo
                                : ImageDefault200x200
                            }
                            alt={selectedSoftware.nome}
                            title={selectedSoftware.nome}
                          />
                        </div>
                      ),
                    }
                    : null
                }
                onChange={item => {
                  handleSelectedSoftwareChange(
                    find(softwares, { id: item.value }),
                  );
                }}
                options={softwares
                  .filter(item => {
                    if (!modulesTotal.length) return false;
                    if (!item.ativo) return false;
                    const grouped = groupBy(modulesTotal, 'softwareId');
                    let groupIds = [];
                    (Object.values(grouped) || []).forEach((group) => {
                      const modulesActive = group.map((goup) => {
                        return {
                          ativo: goup.ativo,
                          softwareId: goup.softwareId,
                        };
                      });
                      (modulesActive || []).forEach((module) => {
                        if (module.ativo === true) {
                          if (!groupIds.includes(module.softwareId)) {
                            groupIds.push(module.softwareId);
                          }
                        }
                      });
                    });
                    return includes(groupIds, item.id);
                  })
                  .map(item => {
                    return {
                      value: item.id,
                      label: (
                        <div className="softwareItem">
                          <img
                            src={
                              item.logo &&
                                item.logo.length &&
                                item.logo !== 'Base64 image'
                                ? item.logo
                                : ImageDefault200x200
                            }
                            alt={item.nome}
                            title={item.nome}
                          />
                        </div>
                      ),
                    };
                  })
                }
              />
            )}
          </S.SoftwareSelectedContainer>
        </S.ContainerContent>
        {HANDLE_ACTIVE_SELETED_SOFTWARE() && <S.Divider />}
        <S.ContainerContent>
          <S.ModulesContainer>
            {HANDLE_ACTIVE_SELETED_SOFTWARE()
              ? (
                <S.ModulesContent>
                  <S.ModuleHeaderTitle>
                    <span id="title">Escolha os módulos *</span>
                  </S.ModuleHeaderTitle>
                  <S.ModuleContainer>
                    {modules.map((item) => (
                      item.ativo && (
                        <OrderItemModule
                          key={item.id}
                          itemObj={item}
                          countItems={countItems(item)}
                          handleAddModule={() => handleAddModule(item)}
                          handleRemoveModule={() => handleRemoveModule(item)}
                        />
                      )
                    ))}
                  </S.ModuleContainer>
                </S.ModulesContent>
              ) : null
            }
          </S.ModulesContainer>
        </S.ContainerContent>
        {HANDLE_ACTIVE_SELETED_SOFTWARE()
          && (
            <>
              <S.Divider />
              <TableShoppingCart
                activeStep={activeStep}
                softwares={softwares}
                items={items}
                setItems={setItems}
                handleAddModule={handleAddModule}
                handleRemoveModule={handleRemoveModule}
                userCanEdit={userCanEdit}
                calculateTotalAdesao={calculateTotalAdesao}
                calculateTotalMensalidade={calculateTotalMensalidade}
              />
            </>
          )
        }
      </S.OrderSoftwareFormContainer>
      <Modal
        show={showChangeModality}
        loading={false}
      >
        <S.ContanierInfoModalModality>
          <h2>Alteração de modalidade</h2>
          <span>
            Caso altere a modalidade do pedido, todos os itens do seu
            carrinho de compras serão removidos.
          </span>
          <S.ModalModalityActions>
            <S.ContainerInputChackbox>
              <InputCheckbox
                checked={canShowAgainDialogModality === true}
                value={canShowAgainDialogModality}
                onChange={handleSetDoNotShowAgainChange}
              />
              <label>Não mostrar novamente<br />nessa sessão</label>
            </S.ContainerInputChackbox>
            <button onClick={handleConfirmRemovalChange}>
              <span>OK</span>
            </button>
          </S.ModalModalityActions>
        </S.ContanierInfoModalModality>
      </Modal>
    </S.CustomContainer>
  );
}

SelectProducts.propTypes = {
  activeStep: PropTypes.number.isRequired,
  selectedSoftware: PropTypes.object,
  selectedModality: PropTypes.object,
  softwares: PropTypes.array.isRequired,
  modules: PropTypes.array.isRequired,
  modulesTotal: PropTypes.array.isRequired,
  modalities: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  allFormsToAnswer: PropTypes.array.isRequired,
  answerForms: PropTypes.array.isRequired,
  formsToAnswer: PropTypes.array.isRequired,
  activeSteps: PropTypes.array.isRequired,
  setSelectedSoftware: PropTypes.func.isRequired,
  setSelectedModality: PropTypes.func.isRequired,
  setModules: PropTypes.func.isRequired,
  setModulesTotal: PropTypes.func.isRequired,
  setItems: PropTypes.func.isRequired,
  setForms: PropTypes.func.isRequired,
  setAllFormsToAnswer: PropTypes.func.isRequired,
  setAnswerForms: PropTypes.func.isRequired,
  setFormsToAnswer: PropTypes.func.isRequired,
  setActiveSteps: PropTypes.func.isRequired,
  handleAddModule: PropTypes.func.isRequired,
  handleRemoveModule: PropTypes.func.isRequired,
  userCanEdit: PropTypes.bool.isRequired,
  canShowAgainDialogModality: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  calculateTotalAdesao: PropTypes.func.isRequired,
  calculateTotalMensalidade: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export { SelectProducts };
