import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import FileBase64 from 'react-file-base64';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { Radio } from '~/components/Radio';
import { productPaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import ProductModuleService from '~/services/product-module-service';
import { showMessageError } from '~/util/errorutils';
import { inputNumber } from '~/util/stringutils';
import { SUPORT_FILES } from '~/util/fileutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { ImagePreviewContainer } from '../styles';
import { DraggableItem, DroppableContainer, ImageContent } from './styles';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
class EditProductSoftware extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        id: PropTypes.number,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { history, location } = props;
    this.history = history;
    this.location = location;
    this.id = null;

    this.state = {
      loading: true,
      inputAtivo: false,
      inputNome: '',
      inputOrdem: 0,
      inputLogo: '',
      modules: [],
      inputCodigoSoftwareERP: '',

      // errors
      error_inputNome: false,
      error_inputLogo: false,
      error_inputCodigoSoftwareERP: false,
      error_inputAtivo: false,
    };
  }

  /* LifeCicle:: Inicializa quando monta a DOM */
  componentDidMount() {
    if (!this.location.state || !this.location.state.id) {
      this.setLoading(false);
      toast('Erro ao consultar produto de software.', {
        type: toast.TYPE.ERROR,
      });
      this.history.push(productPaths.productSoftware);
    } else {
      this.id = this.location.state.id;
      Promise.all([
        api
          .get(
            endpoints.product.software.getProductSoftware(
              this.location.state.id
            )
          )
          .then(value => {
            if (value.data && value.data.data) {
              this.setState({
                inputNome: value.data.data.nome,
                inputLogo: value.data.data.logo,
                inputOrdem: value.data.data.ordem,
                inputCodigoSoftwareERP: (value.data.data.codigoSoftwareERP || ''),
                inputAtivo: value.data.data.ativo,
              });
            } else {
              toast('Erro ao consultar produto de software.', {
                type: toast.TYPE.ERROR,
              });
              this.history.push(productPaths.productSoftware);
            }
          })
          .catch(() => {
            toast('Erro ao consultar produto de software.', {
              type: toast.TYPE.ERROR,
            });
            this.history.push(productPaths.productSoftware);
          }),
        ProductModuleService.getProductModules({
          softwareId: this.location.state.id,
        })
          .then(({ resultado }) =>
            this.setState({ modules: orderBy(resultado, 'posicaoEmSoftware') })
          )
          .catch(() => {
            toast('Erro ao consultar os módulos do produto de software.', {
              type: toast.TYPE.ERROR,
            });
            this.history.push(productPaths.productSoftware);
          }),
      ]).finally(() => {
        this.setLoading(false);
      });
    }
  }

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const modules = reorder(
      this.state.modules,
      result.source.index,
      result.destination.index
    );

    this.setState({ modules });
  };

  setLoading = b => this.setState({ loading: b });

  setInputNome = b => this.setState({ inputNome: b });

  setInputOrdem = b => this.setState({ inputOrdem: b });

  setInputAtivo = b => this.setState({ inputAtivo: !!b });

  setInputCodigoSoftwareERP = b => {
    this.setState({ inputCodigoSoftwareERP: b });
  };

  setInputLogo = b => {
    if (b.type && SUPORT_FILES.IMAGES.includes(b.type)) {
      this.setState({ inputLogo: b.base64 ? b.base64 : '', imageName: b.name });
      this.handleErrorsInputLogo(false);
    } else {
      toast.error('Tipo de arquivo não é suportado');
    }
  };

  handleInputNomeChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputNome(value);
    this.handleErrorsInputNome(false);
  };

  handleInputOrdemChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputOrdem(value);
  };

  handleInputCodigoSoftwareERPChange = event => {
    const {
      target: { value },
    } = event;

    this.setInputCodigoSoftwareERP(inputNumber(value));
    this.handleErrorsInputCodigoSoftwareERP(false);
  }

  handleInputAtivoChange = event => {
    const {
      target: { value },
    } = event;

    this.setInputAtivo(value === 'true');
    this.handleErrorsInputAtivo(false);
  }

  handleErrorsInputNome = (error_inputNome) => {
    this.setState({ error_inputNome });
  }

  handleErrorsInputLogo = (error_inputLogo) => {
    this.setState({ error_inputLogo });
  }

  handleErrorsInputCodigoSoftwareERP = (error_inputCodigoSoftwareERP) => {
    this.setState({ error_inputCodigoSoftwareERP });
  }

  handleErrorsInputAtivo = (error_inputAtivo) => {
    this.setState({ error_inputAtivo });
  }

  validateForm(success) {
    let errors_temp = false;
    const formData = {};

    if (!this.id) {
      toast('Produto de software inválido!', { type: toast.TYPE.ERROR });
      return;
    }

    formData.id = this.id;

    if (!this.state.inputNome || !this.state.inputNome.length) {
      this.handleErrorsInputNome(true);
      errors_temp = true;
    }

    formData.nome = this.state.inputNome.trim();

    if (!this.state.inputLogo || !this.state.inputLogo.length) {
      this.handleErrorsInputLogo(true);
      errors_temp = true;
    }

    if (!this.state.inputCodigoSoftwareERP || !this.state.inputCodigoSoftwareERP.length) {
      this.handleErrorsInputCodigoSoftwareERP(true);
      errors_temp = true;
    }

    if ([undefined, null].includes(this.state.inputAtivo)) {
      this.handleErrorsInputAtivo(true);
      errors_temp = true;
    }

    if (errors_temp) {
      return;
    }

    formData.logo = this.state.inputLogo;
    const orderedModules = this.state.modules.map((item, index) => ({
      ...item,
      posicaoEmSoftware: index,
    }));
    formData.modules = orderedModules;
    formData.ordem = this.state.inputOrdem;
    formData.codigoSoftwareERP = this.state.inputCodigoSoftwareERP.trim();
    formData.ativo = Boolean(this.state.inputAtivo);

    success(formData);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.validateForm(async formData => {
      this.setLoading(true);
      try {
        const { data } = await api.put(
          endpoints.product.software.updateProductSoftware,
          formData
        );
        toast(data.message, { type: toast.TYPE.SUCCESS });
        this.setLoading(false);
        this.history.push(productPaths.productSoftware);
      } catch (error) {
        this.setLoading(false);
        showMessageError(error);
      }
    });
  };

  render() {
    return (
      <div className="defaultFormContainer">
        <Modal show={this.state.loading} loading />
        <SidebarPageHeader
          mainMenu="Produto"
          pageName="Editar Software"
          breadcrumbs={[{
            label: 'Software',
            link: productPaths.productSoftware,
          }]}
          button1={{
            label: 'Salvar',
            onClick: () => this.handleSubmit,
            main: true,
          }}
          openButtonSelect
          buttonCancelHandler={() => this.history.goBack()}
        />
        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <div className="infoCard">
            <span id="title">Dados do Software</span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>Código Software ERP *</span>
                <input
                  type="text"
                  name="nome"
                  value={this.state.inputCodigoSoftwareERP}
                  onChange={this.handleInputCodigoSoftwareERPChange}
                />
                {this.state.error_inputCodigoSoftwareERP && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
              <div className="inputHolder defaultFlex">
                <span>Nome do Software *</span>
                <input
                  type="text"
                  name="nome"
                  value={this.state.inputNome}
                  onChange={this.handleInputNomeChange}
                />
                {this.state.error_inputNome && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
              <div className="inputHolder noMarginRight">
                <span>Ordem</span>
                <input
                  type="number"
                  name="ordem"
                  value={this.state.inputOrdem}
                  onChange={this.handleInputOrdemChange}
                />
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Disponibilidade</span>
            <div className="rowContainer">
              <div className="inputHolder addMarginBottom">
                <span>Status para pedido? *</span>
                <div className="rowContainer">
                  <Radio
                    name="statusPedido"
                    labelHorizontal={'Disponível'}
                    value={true}
                    onChange={this.handleInputAtivoChange}
                    outlineSelect={this.state.inputAtivo === true}
                  />
                  <Radio
                    name="statusPedido"
                    labelHorizontal={'Indisponível'}
                    value={false}
                    onChange={this.handleInputAtivoChange}
                    outlineSelect={this.state.inputAtivo === false}
                  />
                </div>
                {this.state.error_inputAtivo && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Logotipo do Software *</span>
            <div className="rowContainer">
              <ImageContent>
                <div className="imagePicker">
                  <div className="buttonHolder">
                    <span>ESCOLHER IMAGEM</span>
                    <FileBase64
                      multiple={false}
                      onDone={this.setInputLogo.bind(this)}
                    />
                  </div>
                  <label>
                    {' '}
                    {this.state.imageName ||
                      'Selecione um arquivo de imagem'}{' '}
                  </label>
                </div>
              </ImageContent>
            </div>
            {this.state.error_inputLogo && (
              <small style={{ color: 'red' }}>Campo obrigatório</small>
            )}
          </div>
          {this.state.inputLogo && (
            <div className="infoCard">
              <span id="title">Pré-Visualização</span>
              <div>
                <ImagePreviewContainer>
                  <img
                    src={this.state.inputLogo}
                    height={200}
                    alt='preview software'
                  />
                </ImagePreviewContainer>
              </div>
            </div>
          )}
          <div className="infoCard">
            <span id="title">Ordenação de Módulos</span>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                  <DroppableContainer
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    {...provided.droppableProps}
                  >
                    {this.state.modules.map(({ nome, id }, index) => (
                      <Draggable
                        key={`${id}`}
                        draggableId={`${id}`}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <DraggableItem
                            isDragging={snapshot.isDragging}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={{
                              ...provided.draggableProps.style,
                            }}
                          >
                            {`${index + 1} - ${nome}`}
                          </DraggableItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </DroppableContainer>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </form>
      </div>
    );
  }
}

export default EditProductSoftware;
