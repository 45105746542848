import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import ProductSoftwareService from '~/services/product-software-service';
import ProductModuleService from '~/services/product-module-service';
import FormItemNew from '~/model/form-item-new';
import { formPaths } from '~/routes/paths';
import { v4 as uuid_v4 } from 'uuid';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { FormDragDropContext } from '../../Items/FormDragDropContext';
import { FormActions } from '../../Items/FormActions';
import { FORM_ITEM_ASNWER_TYPE as TAGS } from '~/util/domainutils';
import { Container, ContainerForm } from '../../styles';
import '../../styles.scss';

function EditRegister({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [formName, setFormName] = useState('');
  const [softwares, setSoftwares] = useState([]);
  const [allSoftwares, setAllSoftwares] = useState([]);
  const [softwareSelectedId, setSoftwareSelectedId] = useState(0);
  const [modules, setModules] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [moduleSelectedId, setModuleSelectedId] = useState(0);
  const [moduleDisabled, setModuleDisabled] = useState(false);
  const [form, setForm] = useState([]);
  const [itemsFormRemoved, setItemsFormRemoved] = useState([]);
  const [formData, setFormData] = useState({
    id: null,
    name: '',
    moduleId: null,
    moduleName: '',
    softwareId: null,
    softwareName: '',
    contentItemsForm: [],
  });

  const [errorName, setErrorName] = useState(false);
  const [errorModuleId, setErrorModuleId] = useState(false);
  const [errorSoftwareId, setErrorSoftwareId] = useState(false);
  const [errorFormItems, setErrorFormItems] = useState([]);

  useEffect(() => {
    let cleaningAsyncEffect = false;

    if (location.state.isFirstRefreshToForm) {
      if (!location.state.id) {
        toast.error('Erro ao carregar as informações do formulário');
        history.push(formPaths.formRegister);
      }

      const asyncEffect = async () => {
        if (cleaningAsyncEffect) return;
        setLoading(true);
        try {
          const [formDataResponse, softwares, modules] = await Promise.all([
            api.get(endpoints.form.register.getForm(location.state.id)), // id do formulário
            ProductSoftwareService.getProductSoftwares({}),
            ProductModuleService.getProductModules({}),
          ]);

          const [formResponse] = formDataResponse.data.data.resultado;

          const modulesActives = modules.resultado;

          const [module] = modulesActives
            .filter(module => module.id === formResponse.moduleId);

          if (!module?.ativo) {
            setModuleDisabled(true);
            toast.success('O módulo deste formulário foi desativado');
          }

          const [software] = softwares.resultado
            .filter(software => software.id === module.softwareId);

          const itemForm = [];
          formResponse.itemForms.forEach(f => {
            if (TAGS.TITLE.tag === f.itemType.tag) {
              const titleType = {
                id: f.id,
                itemId: TAGS.TITLE.id,
                itemTag: TAGS.TITLE.tag,
                itemData: {
                  statement: f.statement,
                  description: f.description,
                  itemContent: f.itemContent,
                  itemTypeId: f.itemType.id,
                  orderNumber: f.orderNumber,
                  required: f.required,
                },
                isTitle: true,
                showRequired: true,
                unshowActions: false,
              };

              itemForm.push(titleType);
            }

            if (TAGS.SHORT_ANSWER.tag === f.itemType.tag
              || TAGS.PARAGRAPH.tag === f.itemType.tag
              || TAGS.MULTIPLE_CHOICE.tag === f.itemType.tag
              || TAGS.SELECTION_BOX.tag === f.itemType.tag
            ) {
              let itemContent = null;

              if (f.itemContent) {
                itemContent = JSON.parse(f.itemContent);
              }

              const answerType = {
                id: f.id,
                itemId: TAGS.ANSWER.id,
                itemTag: TAGS.ANSWER.tag,
                itemData: {
                  statement: f.statement,
                  description: f.description,
                  itemContent: itemContent ? itemContent : f.itemContent,
                  itemTypeId: f.itemType.id,
                  orderNumber: f.orderNumber,
                  required: f.required,
                },
                isTitle: false,
                showRequired: true,
                unshowActions: false,
              };

              itemForm.push(answerType);
            }

            if (TAGS.IMAGE.tag === f.itemType.tag) {
              const itemContent = JSON.parse(f.itemContent);
              const imageObject = JSON.parse(itemContent.object);

              const imageType = {
                id: f.id,
                itemId: f.itemType.id,
                itemTag: f.itemType.tag,
                itemData: {
                  statement: f.statement,
                  description: f.description,
                  itemContent: {
                    align: itemContent.align,
                    src: itemContent.src,
                    object: imageObject
                  },
                  itemTypeId: f.itemType.id,
                  orderNumber: f.orderNumber,
                  required: f.required,
                },
                isTitle: false,
                showRequired: false,
                unshowActions: false,
              };

              itemForm.push(imageType);
            }

            if (TAGS.CLIENT_RESALE.tag === f.itemType.tag) {
              const itemContent = JSON.parse(f.itemContent);

              const clientResaleType = {
                id: f.id,
                itemId: f.itemType.id,
                itemTag: f.itemType.tag,
                itemData: {
                  statement: f.statement,
                  description: f.description,
                  itemContent: {
                    clientId: itemContent.clientId,
                    resaleId: itemContent.resaleId,
                    showData: itemContent.showData,
                    showDefaultData: itemContent.showDefaultData,
                  },
                  itemTypeId: f.itemType.id,
                  orderNumber: f.orderNumber,
                  required: f.required,
                },
                isTitle: false,
                showRequired: false,
                unshowActions: true,
              };

              itemForm.push(clientResaleType);
            }
          });

          let contentItemsForm = itemForm.filter(item => !!item);

          const modulesFilted = modulesActives.filter(module => {
            return module.softwareId === software.id;
          });

          setAllSoftwares(softwares.resultado);
          setSoftwares(softwares.resultado);
          setAllModules(modulesActives);
          setModules(modulesFilted);
          setFormData({
            ...formData,
            id: formResponse.id,
            name: formResponse.name,
            moduleId: module.id,
            moduleName: module.nome,
            softwareId: software.id,
            softwareName: software.nome,
            contentItemsForm
          });
          setForm(contentItemsForm);
          setFormName(formResponse.name);
          setModuleSelectedId(module.id);
          setSoftwareSelectedId(software.id);
        } catch (error) {
          setAllSoftwares([]);
          setSoftwares([]);
          setAllModules([]);
          setModules([]);

          showMessageError(error);
        } finally {
          setLoading(false);
          location.state.isFirstRefreshToForm = false;
        }
      };

      asyncEffect();
    }

    return () => { cleaningAsyncEffect = true; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let cleaningAsyncEffect = false;

    if (!location.state.isFirstRefreshToForm) {
      const asyncEffect = async () => {
        if (cleaningAsyncEffect) return;
        setLoading(true);
        try {
          const [softwares, modules] = await Promise.all([
            ProductSoftwareService.getProductSoftwares({}),
            ProductModuleService.getProductModules({}),
          ]);

          const modulesActives = modules.resultado;

          const [module] = modulesActives
            .filter(moduObj => moduObj.id === Number(moduleSelectedId));

          if (!module?.ativo) {
            setModuleDisabled(true);
          }

          setAllSoftwares(softwares.resultado);
          setSoftwares(softwares.resultado);
          setAllModules(modulesActives);
          setModules(modulesActives);
        } catch (error) {
          setAllSoftwares([]);
          setSoftwares([]);
          setAllModules([]);
          setModules([]);

          showMessageError(error);
        } finally {
          setLoading(false);
        }
      };

      asyncEffect();
    }

    return () => { cleaningAsyncEffect = true; };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let cleaningAsyncEffect = false;
    if (cleaningAsyncEffect) return;

    if (location.state.formViewer) {
      const {
        id,
        name,
        softwareId,
        softwareName,
        moduleId,
        moduleName,
        contentItemsForm,
      } = location.state.formViewer;

      setFormName(name);
      setSoftwareSelectedId(Number(softwareId));
      setModuleSelectedId(Number(moduleId));

      setFormData({
        ...formData,
        id, name, softwareId, softwareName, moduleId, moduleName,
      });

      setForm(contentItemsForm);

      location.state.formViewer = null;
    }

    return () => {
      location.state.formViewer = null;
      cleaningAsyncEffect = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAddNewTitle() {
    const titleCreated = new FormItemNew({
      id: uuid_v4(),
      tag: TAGS.TITLE.tag,
    });

    setForm([...form, titleCreated]);

    handleScroll('formEnd');
  }

  function handleAddNewAnswer() {
    const answerCreated = new FormItemNew({
      id: uuid_v4(),
      tag: TAGS.ANSWER.tag
    });

    setForm([...form, answerCreated]);

    handleScroll('formEnd');
  }

  function handleAddNewImage() {
    const imageCreated = new FormItemNew({
      id: uuid_v4(),
      tag: TAGS.IMAGE.tag
    });

    setForm([...form, imageCreated]);

    handleScroll('formEnd');
  }

  function handleFormName(event) {
    const {
      target: {
        value
      }
    } = event;

    setFormName(value);
    setFormData({ ...formData, name: value });
    setErrorName(false);
  }

  function handleSelectedSoftwareId(event) {
    const {
      target: {
        value: softwareId
      }
    } = event;

    let dataTemp = {};

    if (Number(softwareId) !== 0) {
      const modulesFilted = allModules.filter(module => {
        return module.softwareId === Number(softwareId);
      });

      const [software] = allSoftwares.filter(software => {
        return software.id === Number(softwareId);
      });

      setModules(modulesFilted);

      dataTemp = {
        ...dataTemp,
        softwareId: Number(softwareId),
        softwareName: software.nome,
      };
    } else {
      setModules(allModules);
      setModuleSelectedId(0);

      dataTemp = {
        ...dataTemp,
        softwareId: null,
        softwareName: '',
      };
    }

    setSoftwareSelectedId(Number(softwareId));
    setFormData({ ...formData, ...dataTemp });
    setErrorSoftwareId(false);
  }

  function handleSelectedModuleId(event) {
    const {
      target: {
        value: moduleId
      }
    } = event;

    let dataTemp = {};

    if (Number(moduleId) !== 0) {
      const [module] = allModules.filter(module => {
        return module.id === Number(moduleId);
      });

      const [software] = allSoftwares.filter(software => {
        return software.id === module.softwareId;
      });

      setSoftwareSelectedId(module.softwareId);

      dataTemp = {
        ...dataTemp,
        moduleId: Number(moduleId),
        moduleName: module.nome,
        softwareId: module.softwareId,
        softwareName: software.nome,
      };

      if (errorSoftwareId) {
        setErrorSoftwareId(false);
      }
    } else {
      setSoftwares(allSoftwares);

      dataTemp = {
        ...dataTemp,
        moduleId: Number(moduleId),
        moduleName: '',
        softwareId: formData.softwareId,
        softwareName: '',
      };

      setErrorSoftwareId(false);
    }

    setModuleSelectedId(Number(moduleId));
    setFormData({ ...formData, ...dataTemp });
    setErrorModuleId(false);
  }

  function handleRemoveItemForm(formId) {
    const [itemToRemoved] = form.filter(form => form.id === formId);

    if (typeof itemToRemoved.id === 'number') {
      setItemsFormRemoved([...itemsFormRemoved, itemToRemoved.id]);
    }

    setForm([...form.filter(form => form.id !== formId)]);
  }

  function handleDuplicateItemForm(formId, itemIndex) {
    let previousItems = [];
    let successorItems = [];

    const [item] = form.filter((item, index) => {
      if (index <= itemIndex)
        previousItems.push(item);
      else
        successorItems.push(item);

      return item.id === formId;
    });

    let duplicateItem = null;

    if (item.itemTag === TAGS.IMAGE.tag) {
      duplicateItem = {
        id: uuid_v4(),
        itemId: TAGS.IMAGE.id,
        itemTag: TAGS.IMAGE.tag,
        itemData: {
          statement: null,
          description: null,
          itemContent: {
            align: item.itemData.itemContent.align,
            src: item.itemData.itemContent.src,
            object: item.itemData.itemContent.object
          },
          itemTypeId: item.itemData.itemTypeId,
          orderNumber: null,
          required: item.itemData.required
        },
        isTitle: false,
        showRequired: false,
        unshowActions: false,
      };
    }

    if (item.itemTag === TAGS.ANSWER.tag) {
      duplicateItem = {
        id: uuid_v4(),
        itemId: TAGS.ANSWER.id,
        itemTag: TAGS.ANSWER.tag,
        itemData: {
          statement: item.itemData.statement,
          description: item.itemData.description,
          itemContent: null,
          itemTypeId: item.itemData.itemTypeId,
          orderNumber: null,
          required: item.itemData.required
        },
        isTitle: false,
        showRequired: true,
        unshowActions: false,
      };
    }

    if (item.itemTag === TAGS.TITLE.tag) {
      duplicateItem = {
        id: uuid_v4(),
        itemId: TAGS.TITLE.id,
        itemTag: TAGS.TITLE.tag,
        itemData: {
          statement: item.itemData.statement,
          description: item.itemData.description,
          itemContent: null,
          itemTypeId: item.itemData.itemTypeId,
          orderNumber: null,
          required: item.itemData.required
        },
        isTitle: true,
        showRequired: true,
        unshowActions: false,
      };
    }

    setForm([...previousItems, duplicateItem, ...successorItems]);
  }

  function handleScroll(elementId) {
    setTimeout(() => {
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  }

  function handleClear() {
    location.state.formViewer = null;

    setFormName('');
    setSoftwares([]);
    setAllSoftwares([]);
    setSoftwareSelectedId(0);
    setModules([]);
    setAllModules([]);
    setModuleSelectedId(0);
    setForm([]);

    setFormData({
      id: null,
      name: '',
      moduleId: null,
      moduleName: '',
      softwareId: null,
      softwareName: '',
      contentItemsForm: [],
    });

    setErrorName(false);
    setErrorModuleId(false);
    setErrorSoftwareId(false);
    setErrorFormItems([]);
  }

  const formatFormViewer = useCallback(async (success) => {
    const payloadViewer = {
      ...formData,
      contentItemsForm: form.map((f, index) => {
        f.itemData.orderNumber = index;
        return f;
      }),
    };

    await success(payloadViewer);
  }, [form, formData]);

  const handleViewer = useCallback(async () => {
    await formatFormViewer(formViewer => {
      setAllSoftwares([]);
      setSoftwares([]);
      setAllModules([]);
      setModules([]);
      history.push(formPaths.formViewer, {
        formViewer, pathReferenceToBack: formPaths.editFormRegister,
      });
    });
  }, [formatFormViewer, history]);

  const validateForm = useCallback(async (success) => {
    const formDataSubmit = {};
    let errors_temp = false;
    let error_at_least_one_asnwer = false;

    if (form.length) {
      const items = form.map(item => item.itemTag);

      if (!items.includes(TAGS.ANSWER.tag)) {
        error_at_least_one_asnwer = true;
        errors_temp = true;
      }

      const errorItemsTemp = [];
      form.forEach((item, itemIndex) => {
        if (item.itemTag === TAGS.CLIENT_RESALE.tag
          || item.itemTag === TAGS.IMAGE.tag
        ) {
          errorItemsTemp.push({
            itemIndex,
            itemId: item.id,
            errorSubItem: [],
            errorItem: false,
          });
        }

        let errors_title = false;

        if (item.itemTag === TAGS.TITLE.tag) {
          if (!item.itemData.statement || !item.itemData.statement.length) {
            errorItemsTemp.push({
              itemIndex,
              itemId: item.id,
              errorSubItem: [],
              errorItem: true,
            });

            errors_title = true;
          } else {
            errorItemsTemp.push({
              itemIndex,
              itemId: item.id,
              errorSubItem: [],
              errorItem: false,
            });
          }
        }

        let errors_answer = false;

        if (item.itemTag === TAGS.ANSWER.tag) {
          let errorTemp = {
            itemIndex,
            itemId: item.id,
            errorSubItem: [],
            errorItem: false,
          };

          if (!item.itemData.statement || !item.itemData.statement.length) {
            errorTemp = {
              itemIndex,
              itemId: item.id,
              errorSubItem: [],
              errorItem: true,
            };

            errors_answer = true;
          } else {
            errorTemp = {
              itemIndex,
              itemId: item.id,
              errorSubItem: [],
              errorItem: false,
            };
          }

          if ((item.itemData.itemTypeId === 3 || item.itemData.itemTypeId === 4)
            && item.itemData.itemContent && item.itemData.itemContent.length
          ) {

            const errorSubItemTemp = [];
            item.itemData.itemContent.forEach((subItem, subItemIndex) => {
              let errorSubItemValuesTemp = {};

              if (!subItem.itemData.value || !subItem.itemData.value.length) {
                if (subItem.other) {
                  errorSubItemValuesTemp = {
                    subItemIndex,
                    subItemId: subItem.id,
                    errorSubItem: false,
                  };
                } else {
                  errorSubItemValuesTemp = {
                    subItemIndex,
                    subItemId: subItem.id,
                    errorSubItem: true,
                  };

                  errors_answer = true;
                }
              } else {
                errorSubItemValuesTemp = {
                  subItemIndex,
                  subItemId: subItem.id,
                  errorSubItem: false,
                };
              }

              errorSubItemTemp.push(errorSubItemValuesTemp);
            });

            const isOptionError = errorSubItemTemp.map(subItem => {
              return subItem.errorSubItem;
            });

            if (errors_answer && isOptionError.includes(true)) {
              errorTemp = {
                itemIndex,
                itemId: item.id,
                errorSubItem: errorSubItemTemp,
                errorItem: true,
              };

              errors_answer = true;
            } else {
              errorTemp = {
                itemIndex,
                itemId: item.id,
                errorSubItem: errorSubItemTemp,
                errorItem: false,
              };
            }
          }

          errorItemsTemp.push(errorTemp);
        }

        const isErrorItem = errorItemsTemp.map(item => {
          return item.errorItem;
        });

        if (isErrorItem.includes(true)) {
          errors_temp = true;
        }

        if (errors_title || errors_answer) {
          errors_temp = true;
        }
      });

      setErrorFormItems(errorItemsTemp);
    }

    if (!formData.name || !formData.name.length) {
      setErrorName(true);
      errors_temp = true;
    }

    if (!formData.moduleId || formData.moduleId === 0) {
      setErrorModuleId(true);
      errors_temp = true;
    }

    if (!formData.softwareId || formData.softwareId === 0) {
      setErrorSoftwareId(true);
      errors_temp = true;
    }

    if (errors_temp) {
      if (error_at_least_one_asnwer) {
        toast.error('É preciso ter pelo menos uma pergunta');
      }

      setTimeout(() => {
        const element = document.getElementById('fieldsRequiredId');

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);

      return;
    }

    let newItemsForm = [];
    formDataSubmit.id = formData.id;

    if (formData.name) {
      formDataSubmit.name = formData.name.trim();
    }

    formDataSubmit.moduleId = formData.moduleId;

    const itemsFormTemp = [];
    form.forEach((f, index) => {
      let itemContentToJson = null;

      if (f.itemData.itemContent) {
        let objectFileToJson = null;

        if (f.itemData.itemContent.object) {
          const { object: fileObject } = f.itemData.itemContent;

          objectFileToJson = {
            lastModified: fileObject.lastModified,
            lastModifiedDate: fileObject.lastModifiedDate,
            name: fileObject.name,
            size: fileObject.size,
            type: fileObject.type,
            webkitRelativePath: fileObject.webkitRelativePath,
          };

          objectFileToJson = JSON.stringify(objectFileToJson);
        }

        f.itemData.itemContent.object = objectFileToJson;
        itemContentToJson = JSON.stringify(f.itemData.itemContent);
      }

      let statement = null;
      if (f.itemData.statement) {
        statement = f.itemData.statement.trim();
      }

      let description = null;
      if (f.itemData.description) {
        description = f.itemData.description.trim();
      }

      const formItemTemp = {
        formId: formData.id,
        statement,
        description,
        itemContent: itemContentToJson,
        itemTypeId: f.itemData.itemTypeId,
        orderNumber: index,
        required: f.itemData.required,
      };

      let formItem = {};
      if (typeof f.id === 'number') {
        formItem = {
          id: f.id,
          ...formItemTemp,
        };

        itemsFormTemp.push(formItem);
      } else {
        newItemsForm.push(formItemTemp);
      }
    });

    formDataSubmit.itemsForm = itemsFormTemp.filter(item => !!item);
    formDataSubmit.newItemsForm = newItemsForm;
    formDataSubmit.removeItemsForm = itemsFormRemoved;

    await success(formDataSubmit);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, formData, errorFormItems]);

  const handleSubmit = useCallback(async () => {
    await validateForm(async (form) => {
      setLoading(true);
      try {
        const { data } = await api.put(endpoints.form.register.updateForm, form);

        if (data.data.ok) {
          toast.success(data.message);
          handleClear();
          setTimeout(() => {
            history.push(formPaths.formRegister);
          }, 100); // 1 milesegundo
        }
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateForm, history]);

  function handleBackRegister() {
    handleClear(); history.push(formPaths.formRegister);
  }

  return (
    <>
      <div id='formTop' />
      <Modal loading show={loading} />
      <Container>
        <SidebarPageHeader
          mainMenu="Formulário"
          pageName="Editar Formulário"
          breadcrumbs={[{
            label: 'Cadastro',
            onClick: () => handleBackRegister,
          }]}
          button1={{
            label: 'Salvar',
            onClick: () => handleSubmit,
            main: true,
          }}
          button2={{
            label: 'Visualizar',
            onClick: () => handleViewer,
            main: false,
          }}
          openButtonSelect
          buttonCancelHandler={handleBackRegister}
        />
        <div className="defaultFormContainer">
          <form className="defaultForm">
            <div className="infoCard">
              <div style={{ marginBottom: 20 }}>
                <span style={{ color: 'red' }}>
                  {'Campos com (*) são obrigatórios'}
                </span>
              </div>
              <span id="title">Dados do formulário</span>
              <div className="rowContainer">
                <div className="inputHolder defaultFlex">
                  <span>Nome *</span>
                  {errorName && <div id='fieldsRequiredId' />}
                  <input
                    type="text"
                    name="formName"
                    value={formName}
                    disabled={moduleDisabled}
                    onChange={handleFormName}
                  />
                  {errorName && (
                    <small style={{ color: 'red' }}>Campo obrigatório</small>
                  )}
                </div>
              </div>
              <div className="rowContainer">
                <div className="inputHolder widthSelectDefault">
                  <span>Software *</span>
                  {errorSoftwareId && <div id='fieldsRequiredId' />}
                  <select
                    name="software"
                    value={softwareSelectedId}
                    disabled={moduleDisabled}
                    onChange={handleSelectedSoftwareId}
                  >
                    <option value={0}>Selecione</option>
                    {softwares.map(soft => (
                      <option key={soft.id} value={soft.id}>
                        {soft.nome}
                      </option>
                    ))}
                  </select>
                  {errorSoftwareId && (
                    <small style={{ color: 'red' }}>Campo obrigatório</small>
                  )}
                </div>
                <div className="inputHolder widthSelectDefault">
                  <span>Módulo *</span>
                  {errorModuleId && <div id='fieldsRequiredId' />}
                  <select
                    name="modulo"
                    value={moduleSelectedId}
                    disabled={moduleDisabled}
                    onChange={handleSelectedModuleId}
                  >
                    <option value={0}>Selecione</option>
                    {modules.map(modu => (
                      <option key={modu.id} value={modu.id}>
                        {modu.nome}
                      </option>
                    ))}
                  </select>
                  {errorModuleId && (
                    <small style={{ color: 'red' }}>Campo obrigatório</small>
                  )}
                </div>
              </div>
            </div>
            <div id='itemsFormContainer' className="infoCard infoCardBase">
              <span id="title">Itens do formulário</span>
              <ContainerForm className='rowContainer'>
                <FormDragDropContext
                  form={form}
                  setForm={setForm}
                  errorForm={errorFormItems}
                  setErrorFormItems={setErrorFormItems}
                  handleRemoveItemForm={handleRemoveItemForm}
                  handleDuplicateItemForm={handleDuplicateItemForm}
                />
                <FormActions
                  handleAddNewAnswer={handleAddNewAnswer}
                  handleAddNewTitle={handleAddNewTitle}
                  handleAddNewImage={handleAddNewImage}
                  handleScrollTop={() => handleScroll('formTop')}
                  handleScrollEnd={() => handleScroll('formEnd')}
                />
              </ContainerForm>
            </div>
          </form>
        </div>
      </Container>
      <div id='formEnd' />
    </>
  );
}

EditRegister.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
      isFirstRefreshToForm: PropTypes.bool,
      formViewer: PropTypes.shape({}),
    }),
  }),
};

export default EditRegister;
