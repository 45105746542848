import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';
import { PERSON_STATUS_ENUM } from '~/util/domainutils';
import PersonClientService from '~/services/person-client-service';
import { showMessageError } from '~/util/errorutils';
import { AutoCompleteOn } from '~/components/AutoCompleteOn';
import {
  onlyText,
  unFormatCpfCnpj,
  formatCpfCnpj,
  identifyCpfCnpj,
} from '~/util/stringutils';
import * as S from './styles';

function SelectClient(props) {
  const {
    client,
    setClient,
    setClienteId,
    inputClienteValue,
    setInputValue,
    messageUserBlock,
    noDataClient,
    noLabelTitle,
    noPaddingInfoCard,
    sameClient,
    handleCheckSameCpfCnpj,
  } = props;

  const [userBlocked, setUserBlocked] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [autoTimeout, setAutoTimeout] = useState(null);
  const [clients, setClients] = useState([]);

  function renderMessageUserBlocked(client) {
    const MSG = messageUserBlock;
    const MSG_NAME = `${client.razaoSocial || client.nomeFantasia}`;
    const MSG_CPF_CNPJ = identifyCpfCnpj(client.cpfCnpj);
    const MSG_CPF_CNPJ_FORMATED = `${formatCpfCnpj(client.cpfCnpj)}`;

    return `${MSG_NAME} com ${MSG_CPF_CNPJ} ${MSG_CPF_CNPJ_FORMATED} ${MSG}`;
  }

  function handlePreventDefault(event) {
    return event.preventDefault();
  }

  function handleVerifyClient(client) {
    if (client.status === PERSON_STATUS_ENUM.BLOCKED.value) {
      toast(renderMessageUserBlocked(client), { type: toast.TYPE.ERROR });
      setUserBlocked(true);
    } else {
      setUserBlocked(false);
    }

    if (handleCheckSameCpfCnpj && sameClient) {
      handleCheckSameCpfCnpj(sameClient, {
        cpfCnpj: (unFormatCpfCnpj(client.cpfCnpj) || ''),
      });
    }

    setClient(client);
    setClienteId(client.id);
    setInputValue(renderSuggestion(client));
  }

  async function getClients(value) {
    try {
      const data = await PersonClientService.getPersonClients({
        cpfCnpj: unFormatCpfCnpj(value.trim().toLowerCase()) || null,
        nome: onlyText(value.trim().toLowerCase()) || null,
        offset: 0,
        limit: 10,
      });

      return data.resultado;
    } catch (error) {
      showMessageError(error);
      return [];
    }
  }

  async function getClientsChange(event) {
    const {
      target: { value: valueToFind }
    } = event;

    if (valueToFind === '') {
      setClients([]);
      setInputValue('');
      setClient({});
      setClienteId(null);
      setShowSuggestions(false);
    } else {
      clearTimeout(autoTimeout);

      const timer = setTimeout(() => {
        const asyncSetTimeOut = async () => {
          const clients = await getClients(valueToFind);
          if (clients.length) {
            setClients(clients);
          } else {
            setClients([]);
          }
          setShowSuggestions(true);
        };

        asyncSetTimeOut();
      }, 500); // 0.5 segundos

      setAutoTimeout(timer);
    }

    setInputValue(valueToFind);
  }

  function getSuggestionLabel(suggestion) {
    if (suggestion) {
      return renderSuggestion(suggestion);
    } else {
      return '';
    }
  }

  function getSuggestionKey(suggestion) {
    if (suggestion) {
      return suggestion.id;
    } else {
      return '';
    }
  }

  function renderSuggestion(suggestion) {
    return `${formatCpfCnpj(suggestion.cpfCnpj)} - ${suggestion.nomeFantasia}`;
  }

  function handleOnClick(suggestion) {
    const [clientSelected] = clients.filter(
      suggItem => suggItem.id === suggestion.id
    );

    handleVerifyClient(clientSelected);
    if (userBlocked) {
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  }

  function handleShowDataClient() {
    return !noDataClient
      && inputClienteValue
      && client
      && client.contato
      && client.endereco;
  }

  return (
    <S.CustomContainer>
      <S.Container>
        <div className="defaultFormContainer">
          <div className={`infoCard ${noPaddingInfoCard && 'noPadding'}`}>
            <S.ChooseClientContainer onSubmit={handlePreventDefault}>
              <S.InfoCard>
                {!noLabelTitle && (
                  <>
                    <label id="clientText">Cliente *</label>
                    <br />
                  </>
                )}
                <span id="helpText">
                  Ao digitar, o campo irá auto completar. A busca do cliente
                  poderá ser feita pelo CNPJ ou Nome Fantasia.
                </span>
                <AutoCompleteOn
                  inputDataValue={inputClienteValue}
                  suggestions={clients}
                  isShowSuggestions={showSuggestions}
                  handleDataChange={getClientsChange}
                  handleGetSuggestionKey={getSuggestionKey}
                  handleGetSuggestionLabel={getSuggestionLabel}
                  handleOnClick={handleOnClick}
                />
              </S.InfoCard>
            </S.ChooseClientContainer>
          </div>
          {handleShowDataClient() && (
            <S.ContainerDataClient className='defaultForm'>
              <S.Divider />
              <div className="infoCard">
                <S.FormTitle>Contato</S.FormTitle>
                <div className="rowContainer">
                  <div className="inputHolder defaultFlex">
                    <span>Email</span>
                    <input
                      type="email"
                      defaultValue={client?.contato?.email || ''}
                      name="email"
                      disabled
                    />
                  </div>
                  <div className="inputHolder">
                    <span>Telefone (1)</span>
                    <InputMask
                      value={client?.contato?.telefone1 || ''}
                      name="telefone1"
                      disabled
                      mask="(99) 99999-9999"
                    />
                  </div>
                  <div className="inputHolder">
                    <span>Telefone (2)</span>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={client?.contato?.telefone2 || ''}
                      name="telefone2"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="infoCard">
                <S.FormTitle>Endereço</S.FormTitle>
                <div className="rowContainer">
                  <div className="inputHolder defaultFlex">
                    <span>Endereço Completo</span>
                    <input
                      type="endereco"
                      defaultValue={client?.endereco?.endereco || ''}
                      name="endereco"
                      disabled
                    />
                  </div>
                  <div className="inputHolder defaultFlex">
                    <span>Complemento</span>
                    <input
                      defaultValue={client?.endereco?.complemento || ''}
                      name="complemento"
                      disabled
                    />
                  </div>
                  <div className="inputHolder">
                    <span>Bairro</span>
                    <input
                      defaultValue={client?.endereco?.bairro || ''}
                      name="bairro"
                      disabled
                    />
                  </div>
                  <div className="rowContainer">
                    <div className="inputHolder">
                      <span>CEP</span>
                      <input
                        defaultValue={client?.endereco?.cep || ''}
                        name="cep"
                        disabled
                      />
                    </div>
                    <div className="inputHolder">
                      <span>Cidade</span>
                      <input
                        defaultValue={client?.endereco?.cidade || ''}
                        name="cidade"
                        disabled
                      />
                    </div>
                    <div className="inputHolder">
                      <span>Estado</span>
                      <input
                        defaultValue={client?.endereco?.estado || ''}
                        name="estado"
                        disabled
                      />
                    </div>
                    <div className="inputHolder defaultFlex">
                      <span>Ponto de Referência</span>
                      <input
                        defaultValue={client?.endereco?.pontoReferencia || ''}
                        name="pontoReferencia"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </S.ContainerDataClient>
          )}
        </div>
      </S.Container>
    </S.CustomContainer>
  );
}

SelectClient.propTypes = {
  inputClienteValue: PropTypes.string.isRequired,
  setInputValue: PropTypes.func.isRequired,
  client: PropTypes.shape({
    id: PropTypes.number,
    razaoSocial: PropTypes.string,
    nomeFantasia: PropTypes.string,
    cpfCnpj: PropTypes.string,
    status: PropTypes.string,
    contato: PropTypes.shape({
      email: PropTypes.string,
      telefone1: PropTypes.string,
      telefone2: PropTypes.string,
    }),
    endereco: PropTypes.shape({
      endereco: PropTypes.string,
      complemento: PropTypes.string,
      bairro: PropTypes.string,
      cep: PropTypes.string,
      cidade: PropTypes.string,
      estado: PropTypes.string,
      pontoReferencia: PropTypes.string,
    }),
  }),
  setClient: PropTypes.func.isRequired,
  setClienteId: PropTypes.func.isRequired,
  messageUserBlock: PropTypes.string.isRequired,
  noDataClient: PropTypes.bool,
  noLabelTitle: PropTypes.bool,
  noPaddingInfoCard: PropTypes.bool,
  sameClient: PropTypes.object,
  handleCheckSameCpfCnpj: PropTypes.func,
};

export { SelectClient };
