import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { useDispatch, useSelector } from 'react-redux';
import { signOut } from '~/store/modules/auth/actions';
import { initialPaths, authenticationPaths } from '~/routes/paths';
import Modal from '~/components/Modal';
import { BackgroundLogin } from '~/components/BackgroundLogin';
import { TOKEN_KEY } from '~/util/auth';

function Logout({ history, location }) {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const { signed } = useSelector(state => state.auth);
  const authenticated = localStorage.getItem(TOKEN_KEY) || signed;
  const sessionExpired = (location?.state?.sessionExpired || null);

  async function handleLogout() {
    if (!sessionExpired) {
      if (authenticated) {
        history.push(initialPaths.pageInitial);
      } else {
        history.push(authenticationPaths.login);
      }
      return null;
    }

    try {
      dispatch(signOut());

      if (instance.getActiveAccount()) {
        await instance.logout();
      }

      history.push(authenticationPaths.login);
    } catch (err) {
      dispatch(signOut());
      history.push(authenticationPaths.login);
      toast.error('Erro ao sair da sessão.');
    }
  }

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <BackgroundLogin>
      <Modal loading show={true} />
    </BackgroundLogin>
  );
}

Logout.propTypes = {
  location: PropTypes.any,
  history: PropTypes.any,
};

export default Logout;
