import React from 'react';
import PropTypes from 'prop-types';
import { FaRegTrashAlt } from 'react-icons/fa';
import { PERSON_STATUS_ENUM, FIELDS_ENUM } from '~/util/domainutils';
import { onlyNumbers } from '~/util/stringutils';
import { ContainerRow } from '../styles';
import '../styles.scss';

function MultipleFields({
  handleRemoveField,
  handleButtonRemove,
  initialShow,
  fieldId,
  fieldIndex,
  fieldValue,
  fieldLabel,
  inputsFieldData,
  setInputsFieldData,
  noPrazoRenovacaoChave,
}) {
  function handleInputFieldLabelSelected(event) {
    const { target: { value } } = event;

    const fields = [...inputsFieldData];

    const [tempField] = fields.filter(field => field.id === fieldId);

    tempField.field = value;
    tempField.error = false;

    if (value === '') {
      tempField.error = true;
    }

    fields[fieldIndex] = tempField;

    setInputsFieldData(fields);
  }

  function handleInputFieldValueSelected(event) {
    const { target: { value } } = event;

    const fields = [...inputsFieldData];

    const [tempField] = fields.filter(field => field.id === fieldId);

    tempField.value = value;
    tempField.error = false;

    if (value === '') {
      tempField.error = true;
    }

    fields[fieldIndex] = tempField;

    setInputsFieldData(fields);
  }

  function handleInputOptionSelect() {
    const field_value_empty = '';
    const enum_renewal_value = FIELDS_ENUM.FIELED_KEY_RENEWAL_TERM.value;
    const enum_generate_key_value = FIELDS_ENUM.FIELED_PERSON_STATUS.value;

    const renderFields = (field_value) => {
      return (
        <>
          {field_value === enum_renewal_value && (
            <option value={FIELDS_ENUM.FIELED_PERSON_STATUS.value}>
              {FIELDS_ENUM.FIELED_PERSON_STATUS.label}
            </option>
          )}
          {field_value === enum_generate_key_value && (
            <option value={FIELDS_ENUM.FIELED_KEY_RENEWAL_TERM.value}>
              {FIELDS_ENUM.FIELED_KEY_RENEWAL_TERM.label}
            </option>
          )}
          {field_value === field_value_empty && (
            <>
              <option value={FIELDS_ENUM.FIELED_PERSON_STATUS.value}>
                {FIELDS_ENUM.FIELED_PERSON_STATUS.label}
              </option>
              {noPrazoRenovacaoChave && (
                <option value={FIELDS_ENUM.FIELED_KEY_RENEWAL_TERM.value}>
                  {FIELDS_ENUM.FIELED_KEY_RENEWAL_TERM.label}
                </option>
              )}
            </>
          )}
        </>
      );
    };

    if (fieldIndex === 0) {
      if (inputsFieldData[fieldIndex + 1]) {
        const field_value = inputsFieldData[fieldIndex + 1].field;

        return renderFields(field_value);
      }

      return (
        <>
          <option value={FIELDS_ENUM.FIELED_PERSON_STATUS.value}>
            {FIELDS_ENUM.FIELED_PERSON_STATUS.label}
          </option>
          {noPrazoRenovacaoChave && (
            <option value={FIELDS_ENUM.FIELED_KEY_RENEWAL_TERM.value}>
              {FIELDS_ENUM.FIELED_KEY_RENEWAL_TERM.label}
            </option>
          )}
        </>
      );
    }

    if (fieldIndex >= 1) {
      const field_value = inputsFieldData[fieldIndex - 1].field;

      return renderFields(field_value);
    }
  }

  return (
    <>
      {initialShow && (
        <div className="rowContainer">
          {handleButtonRemove && (
            <div className='containerDeleteIcon addMarginRight'>
              <FaRegTrashAlt
                className='deleteIcon'
                onClick={handleRemoveField}
                title="Deletar"
              />
            </div>
          )}
          <div className="inputHolder">
            <span>Campo *</span>
            <select
              name="mass-action-field"
              value={fieldLabel}
              onChange={handleInputFieldLabelSelected}
            >
              <option value={''}>Selecione</option>
              {handleInputOptionSelect()}
            </select>
          </div>
          {fieldLabel === FIELDS_ENUM.FIELED_PERSON_STATUS.value && (
            <div className="inputHolder">
              <span>Status *</span>
              <select
                name="mass-action-value"
                value={fieldValue}
                onChange={handleInputFieldValueSelected}
              >
                <option value={''}>Selecione</option>
                <option value={PERSON_STATUS_ENUM.BLOCKED.value}>
                  {PERSON_STATUS_ENUM.BLOCKED.label}
                </option>
                <option value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}>
                  {PERSON_STATUS_ENUM.NOT_BLOCKED.label}
                </option>
              </select>
            </div>
          )}
          {fieldLabel === FIELDS_ENUM.FIELED_KEY_RENEWAL_TERM.value && (
            <div className="inputHolder">
              <span>Prazo *</span>
              <ContainerRow>
                <input
                  className='inputPrazo'
                  type="text"
                  name="prazo"
                  value={onlyNumbers(fieldValue)}
                  onChange={handleInputFieldValueSelected}
                />
                <span>dias</span>
              </ContainerRow>
            </div>
          )}
        </div>
      )}
    </>
  );
}

MultipleFields.propTypes = {
  handleRemoveField: PropTypes.func,
  handleButtonRemove: PropTypes.bool,
  initialShow: PropTypes.bool,
  fieldId: PropTypes.number,
  fieldIndex: PropTypes.number,
  fieldValue: PropTypes.string,
  fieldLabel: PropTypes.string,
  inputsFieldData: PropTypes.array,
  setInputsFieldData: PropTypes.func,
  noPrazoRenovacaoChave: PropTypes.bool,
};

export { MultipleFields };
