import styled from 'styled-components';
import Select from 'react-select';

export const SelectDefault = styled(Select).attrs({
  styles: {
    container: (provided) => ({
      ...provided,
      display: 'inline-block',
      width: 'auto',
      minHeight: '1px',
      textAlign: 'left',
      border: 'none',
    }),
    control: (provided) => ({
      ...provided,
      border: '1px solid #dadae3',
      borderRadius: '6px',
      minHeight: '1px',
      height: '33px',
      cursor: 'pointer',
    }),
    input: (provided) => ({
      ...provided,
      minHeight: '1px',
      marginTop: '-10px',
      fontSize: '14px',
      color: '#707070',
      paddingBottom: '0',
      paddingLeft: '6px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      minHeight: '1px',
      paddingTop: '0',
      paddingBottom: '0',
      color: '#707070',
    }),
    option: (provided) => ({
      ...provided,
      cursor: 'pointer',
      color: 'black',
      ':hover': { backgroundColor: '#DEEBFF' },
      ':active': { backgroundColor: '#B2D4FF' },
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      marginTop: '5px',
      minHeight: '1px',
      height: '20px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      minHeight: '1px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      minHeight: '1px',
      height: '33px',
      overflow: 'none',
      paddingTop: '0',
      paddingBottom: '0',
    }),
    singleValue: (provided) => ({
      ...provided,
      minHeight: '1px',
      fontSize: '14px',
      color: '#707070',
      marginTop: '-2px',
    }),
  }
})`
  margin-top: 5px;
`;
