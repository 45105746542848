import api, { endpoints } from '~/services/api';

class InvitationService {
  static createInvitationEmployee = async (convite, usuarioParaCriar) => {
    const { data: response } = await api.post(
      endpoints.person.invitation.createInvitationEmployee,
      { convite, usuarioParaCriar }
    );

    return response;
  }

  static createInvitationResale = async (convite, usuarioParaCriar) => {
    const { data: response } = await api.post(
      endpoints.person.invitation.createInvitationResale,
      { convite, usuarioParaCriar }
    );

    return response;
  }

  static getPersonInvitation = async (cpfCnpj) => {
    const { data: response } = await api.post(
      endpoints.person.invitation.findPersonInvitation,
      { cpfCnpj, limit: 1 }
    );

    return response.data;
  }

  static deletePersonInvitation = async (cpfCnpj) => {
    const { data: response } = await api.delete(
      endpoints.person.invitation.deletePersonInvitation(cpfCnpj),
    );

    return response.data;
  }
}

export { InvitationService };
