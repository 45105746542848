import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import find from 'lodash/find';
import orderBy from 'lodash/orderBy';
import TableComponent from '~/components/TableComponent';
import * as S from './styles';
import './styles.scss';

function TableShoppingCart(props) {
  const {
    activeStep,
    softwares,
    items,
    setItems,
    handleRemoveModule,
    handleAddModule,
    userCanEdit,
    calculateTotalAdesao,
    calculateTotalMensalidade,
  } = props;

  function countItems(itemCheck) {
    const hasItem = items.filter((item) => item.id === itemCheck.id);
    if (hasItem && hasItem.length) {
      return hasItem[0].count;
    }
    return 0;
  }

  function formatPriceNumber(price) {
    if (!price) return 0;
    let priceToConvert = String(price || '').replace('R$ ', '');
    let [valueInt, valueDec] = priceToConvert.split(',');
    valueInt = valueInt.split('.').join('');
    const parseValue = parseFloat(`${valueInt}.${valueDec}`);
    return isNaN(parseValue) ? 0 : parseValue;
  }

  function handleInputPrecoMensalItemChange(event, itemId) {
    const {
      target: { value },
    } = event;

    let useValue;

    try {
      useValue = formatPriceNumber(value);
    } catch (e) {
      useValue = 0;
    }

    const updateItems = [...items];

    updateItems.map(item => {
      if (item.id === itemId) {
        item.precoMensal = useValue;
      }

      return item;
    });

    setItems(updateItems);
  }

  return (
    <div>
      <S.HeaderTableComponent>
        <span id="title">Carrinho de Compras</span>
        {userCanEdit && (
          <span id="subtitle">
            (Você pode editar os valores de mensalidade)
          </span>
        )}
      </S.HeaderTableComponent>
      <TableComponent
        headerLabels={[
          { text: 'QTD', width: '30px', align: 'center' },
          { text: 'Módulo', width: '40%' },
          { text: 'Software', width: '20%' },
          { text: 'Adesão', width: '100px', align: 'center' },
          { text: 'Mensalidade', width: '100px', align: 'center' },
        ]}
        dataObjects={[...orderBy(items, 'softwareId'), { renderTotal: true }]}
        fieldsArray={['nome', 'descricao']}
        useCustomActions
        renderRowComponent={(row) => {
          if (row.renderTotal) {
            return [
              <S.TableCellTotal key={0} colSpan={3} align="right">
                <span>Total:</span>
              </S.TableCellTotal>,
              <S.TableCellTotal key={1} align="right">
                <span>
                  <S.CurrencyFormatPrice
                    value={calculateTotalAdesao(items)}
                  />
                </span>
              </S.TableCellTotal>,
              <S.TableCellTotal key={2} align="right">
                <span>
                  <S.CurrencyFormatPrice
                    value={calculateTotalMensalidade(items)}
                  />
                </span>
              </S.TableCellTotal>,
            ];
          }

          const precoAdesao = row.precoAdesao ? row.precoAdesao : '0';
          const precoMensal = row.precoMensal ? row.precoMensal : '0';

          const software = find(softwares, { id: row.softwareId });

          const canEditPriceTotal = () => {
            return [1, 3].includes(activeStep);
          };

          const onDisplayType = () => {
            return userCanEdit && canEditPriceTotal() ? 'input' : 'text';
          };

          return [
            <TableCell key={0} align="center">
              {canEditPriceTotal() ? (
                <S.CountItemsContainer>
                  <div id="quantityHolder">
                    <span id="minus"
                      onClick={() => handleRemoveModule(row)}
                    >
                      -
                    </span>
                    <span id="quantity">{countItems(row)}</span>
                    <span id="plus"
                      onClick={() => handleAddModule(row)}
                    >
                      +
                    </span>
                  </div>
                </S.CountItemsContainer>
              ) : (
                <span>{countItems(row)}</span>
              )}
            </TableCell>,
            <TableCell key={1}>{row.nome}</TableCell>,
            <TableCell key={2}>{software.nome}</TableCell>,
            <TableCell key={3} align="right">
              <S.CurrencyFormatPrice value={precoAdesao} />
            </TableCell>,
            <S.TableCellPriceTotal key={4} align="right">
              <div className='priceTotalContainer'>
                <S.CurrencyFormatPriceTotal
                  value={precoMensal}
                  displayType={onDisplayType()}
                  onChange={(e) => handleInputPrecoMensalItemChange(e, row.id)}
                  className={canEditPriceTotal() ? 'right' : ''}
                />
              </div>
            </S.TableCellPriceTotal>,
          ];
        }}
      />
      <div id='shoppingCartId' />
    </div>
  );
}

TableShoppingCart.defaultProps = {
  softwares: [],
  items: [],
  setItems: () => null,
  handleRemoveModule: () => null,
  handleAddModule: () => null,
  userCanEdit: false,
};

TableShoppingCart.propTypes = {
  activeStep: PropTypes.number.isRequired,
  softwares: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  handleRemoveModule: PropTypes.func.isRequired,
  handleAddModule: PropTypes.func.isRequired,
  userCanEdit: PropTypes.bool.isRequired,
  calculateTotalAdesao: PropTypes.func.isRequired,
  calculateTotalMensalidade: PropTypes.func.isRequired,
};

export { TableShoppingCart };
