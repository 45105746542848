import { actionTypes } from './actions';

export const INITIAL_STATE = {
  onShowAgainDialogModality: false,
};

export default function (state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.LIFECICLE_UPDATE_SHOW_AGAIN_MODALITY:
      return {
        ...state,
        onShowAgainDialogModality: payload.state,
      };
    case actionTypes.LIFECICLE_GET_INITIAL_STATE:
      return {
        ...state,
        onShowAgainDialogModality: false,
      };
    default:
      return state;
  }
}
