import PropTypes from 'prop-types';
import React from 'react';
import * as S from './styles';

function FieldConfirmTerm(confirmTermProps) {
  const {
    value,
    checked,
    onChange,
    title = 'Termo *',
    description,
  } = confirmTermProps;

  return (
    <S.TermHolder>
      <span id="title">{title}</span>
      <div id="radioInputHolder">
        <input
          type="checkbox"
          value={value}
          checked={checked}
          onChange={onChange}
        />
        <span id="radioLabel">
          {description}
        </span>
        <span className="checkmark">
          {checked && (
            <div>
              <div className="checkmark_stem" />
              <div className="checkmark_kick" />
            </div>
          )}
        </span>
      </div>
      <div id='endTerm' />
    </S.TermHolder>
  );
}

FieldConfirmTerm.propTypes = {
  value: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
};

export { FieldConfirmTerm };
