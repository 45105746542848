/**
 * It takes an object and returns true if it's empty, false if it's not
 * @param obj - The object to check if it's empty.
 * @returns the length of the array of unique keys in the object.
 */
export function isObjectEmpty(obj) {
  return ![...new Set([...Object.keys((obj || {}))])].length;
}

/**
 * The function counts the number of unique keys in an object.
 * @param obj - The object that is being passed as an argument.
 * @returns The number of unique keys in the input object `obj`
 */
export function countObject(obj) {
  return [...new Set([...Object.keys((obj || {}))])].length;
}
