import PropTypes from 'prop-types';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { productPaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import ProductModalityService from '~/services/product-modality-service';
import ProductSoftwareService from '~/services/product-software-service';
import { showMessageError } from '~/util/errorutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';

class EditProductModule extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        id: PropTypes.number,
      }).isRequired,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { history, location } = props;
    this.history = history;
    this.location = location;
    this.id = null;

    this.productSoftwares = [];
    this.productModalities = [];

    this.state = {
      loading: true,
      inputNome: '',
      selectSoftware: '',
      selectModality: '',
      inputCodExternoAdesao: 0,
      inputPrecoAdesao: 0,
      inputCodExternoMensal: 0,
      inputPrecoMensal: 0,
      checkDescontoAtivo: true,
      checkAtivo: true,
      checkIsAutomatic: false,

      // errors
      error_inputName: false,
      error_selectSoftware: false,
      error_selectModality: false,
    };
  }

  /* LifeCicle:: Inicializa quando monta a DOM */
  componentDidMount() {
    if (!this.location.state || !this.location.state.id) {
      this.setLoading(false);
      toast('Erro ao consultar modelo de produto.', {
        type: toast.TYPE.ERROR,
      });
      this.history.push(productPaths.productModule);
    } else {
      this.id = this.location.state.id;
      Promise.all([
        ProductSoftwareService.getProductSoftwares({}),
        ProductModalityService.getProductModalities({}),
        api.get(
          endpoints.product.module.getProductModule(this.location.state.id)
        ),
      ])
        .then(values => {
          if (values.length) {
            if (values[0].resultado.length) {
              this.productSoftwares.push(...values[0].resultado);
            }
            if (values[1].resultado.length) {
              this.productModalities.push(...values[1].resultado);
            }
            if (values[2].data && values[2].data.data) {
              const moduleEdit = values[2].data.data;

              this.setState({
                inputNome: moduleEdit.nome,
                selectSoftware: `${moduleEdit.softwareId}`,
                selectModality: `${moduleEdit.modalidadeId}`,
                inputCodExternoAdesao: moduleEdit.codExternoAdesao || 0,
                inputPrecoAdesao: this.formatPrice(moduleEdit.precoAdesao),
                inputCodExternoMensal: moduleEdit.codExternoMensal || 0,
                inputPrecoMensal: this.formatPrice(moduleEdit.precoMensal),
                checkDescontoAtivo: !!moduleEdit.descontoAtivo,
                checkAtivo: !!moduleEdit.ativo,
                checkIsAutomatic: !!moduleEdit.isAutomatic,
              });
            } else {
              toast('Erro ao consultar produto de software.', {
                type: toast.TYPE.ERROR,
              });
              this.history.push(productPaths.productModule);
            }
          }
        })
        .catch(() => {
          toast('Erro ao consultar softwares e/ou modalidades para consulta.', {
            type: toast.TYPE.ERROR,
          });
          this.history.push(productPaths.productModule);
        })
        .finally(() => {
          this.setLoading(false);
        });
    }
  }

  formatPrice(price) {
    if (!price) return '0';
    return String(price || '').replace('.', ',');
  }

  formatPriceNumber(price) {
    if (!price) return 0;
    let priceToConvert = String(price || '').replace('R$ ', '');
    let [valueInt, valueDec] = priceToConvert.split(',');
    valueInt = valueInt.split('.').join('');
    const parseValue = parseFloat(`${valueInt}.${valueDec}`);
    return isNaN(parseValue) ? 0 : parseValue;
  }

  setLoading = b => this.setState({ loading: b });

  setInputNome = b => this.setState({ inputNome: b });

  setSelectSoftware = b => this.setState({ selectSoftware: b });

  setSelectModality = b => this.setState({ selectModality: b });

  setInputCodExternoAdesao = b => this.setState({ inputCodExternoAdesao: b });

  setInputPrecoAdesao = b => this.setState({ inputPrecoAdesao: b });

  setInputCodExternoMensal = b => this.setState({ inputCodExternoMensal: b });

  setInputPrecoMensal = b => this.setState({ inputPrecoMensal: b });

  setCheckDescontoAtivo = b => this.setState({ checkDescontoAtivo: !!b });

  setCheckAtivo = b => this.setState({ checkAtivo: !!b });

  setCheckIsAutomatic = b => this.setState({ checkIsAutomatic: !!b });

  handleInputNomeChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputNome(value);
    this.handleErrorsInputName(false);
  };

  handleSelectSoftwareChange = event => {
    const {
      target: { value },
    } = event;
    this.setSelectSoftware(value);
    this.handleErrorsSelectSoftware(false);
  };

  handleSelectModalityChange = event => {
    const {
      target: { value },
    } = event;
    this.setSelectModality(value);
    this.handleErrorsSelectModality(false);
  };

  handleInputCodExternoAdesaoChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCodExternoAdesao(value);
  };

  handleInputPrecoAdesaoChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputPrecoAdesao(value);
  };

  handleInputCodExternoMensalChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCodExternoMensal(value);
  };

  handleInputPrecoMensalChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputPrecoMensal(value);
  };

  handleCheckDescontoAtivoChange = event => {
    const {
      target: { value },
    } = event;
    this.setCheckDescontoAtivo(value !== 'true');
  };

  handleCheckAtivoChange = event => {
    const {
      target: { value },
    } = event;
    this.setCheckAtivo(value !== 'true');
  };

  handleCheckIsAutomaticChange = event => {
    const {
      target: { value },
    } = event;
    this.setCheckIsAutomatic(value !== 'true');
  };

  handleErrorsInputName = (error_inputName) => {
    this.setState({ error_inputName });
  }

  handleErrorsSelectSoftware = (error_selectSoftware) => {
    this.setState({ error_selectSoftware });
  }

  handleErrorsSelectModality = (error_selectModality) => {
    this.setState({ error_selectModality });
  }

  validateForm(success) {
    let errors_temp = false;
    const formData = {};

    if (!this.id) {
      toast('Produto de software inválido!', { type: toast.TYPE.ERROR });
      return;
    }
    formData.id = this.id;

    if (!this.state.inputNome || !this.state.inputNome.length) {
      this.handleErrorsInputName(true);
      errors_temp = true;
    }
    if (!this.state.selectSoftware || !this.state.selectSoftware.length) {
      this.handleErrorsSelectSoftware(true);
      errors_temp = true;
    }
    if (!this.state.selectModality || !this.state.selectModality.length) {
      this.handleErrorsSelectModality(true);
      errors_temp = true;
    }

    if (errors_temp) {
      return;
    }

    if (this.state.inputNome) {
      formData.nome = this.state.inputNome.trim();
    }

    formData.softwareId = this.state.selectSoftware;
    formData.modalidadeId = this.state.selectModality;

    if (
      this.state.inputCodExternoAdesao &&
      this.state.inputCodExternoAdesao.length
    ) {
      formData.codExternoAdesao = this.state.inputCodExternoAdesao;
    }

    if (this.state.inputPrecoAdesao && this.state.inputPrecoAdesao.length) {
      formData.precoAdesao = this.formatPriceNumber(this.state.inputPrecoAdesao);
    }

    if (
      this.state.inputCodExternoMensal &&
      this.state.inputCodExternoMensal.length
    ) {
      formData.codExternoMensal = this.state.inputCodExternoMensal;
    }

    if (this.state.inputPrecoMensal && this.state.inputPrecoMensal.length) {
      formData.precoMensal = this.formatPriceNumber(this.state.inputPrecoMensal);
    }

    formData.descontoAtivo = Boolean(this.state.checkDescontoAtivo);
    formData.ativo = Boolean(this.state.checkAtivo);
    formData.isAutomatic = Boolean(this.state.checkIsAutomatic);

    success(formData);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.validateForm(async formData => {
      this.setLoading(true);
      try {
        const { data } = await api.put(
          endpoints.product.module.updateProductModule,
          formData
        );
        toast(data.message, { type: toast.TYPE.SUCCESS });
        this.setLoading(false);
        this.history.push(productPaths.productModule);
      } catch (error) {
        this.setLoading(false);
        showMessageError(error);
      }
    });
  };

  render() {
    return (
      <div className="defaultFormContainer">
        <Modal show={this.state.loading} loading />
        <SidebarPageHeader
          mainMenu="Produto"
          pageName="Editar Módulo"
          breadcrumbs={[{
            label: 'Módulo',
            link: productPaths.productModule,
          }]}
          button1={{
            label: 'Salvar',
            onClick: () => this.handleSubmit,
            main: true,
          }}
          openButtonSelect
          buttonCancelHandler={() => this.history.goBack()}
        />
        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <div className="infoCard">
            <span id="title">Dados do Módulo</span>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Nome do Módulo *</span>
                <input
                  type="text"
                  name="nome"
                  value={this.state.inputNome}
                  onChange={this.handleInputNomeChange}
                />
                {this.state.error_inputName && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex">
                <span>Software *</span>
                <select
                  name="software"
                  value={this.state.selectSoftware}
                  onChange={this.handleSelectSoftwareChange}
                >
                  <option value="">Selecione</option>
                  {this.productSoftwares.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </select>
                {this.state.error_selectSoftware && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Modalidade *</span>
                <select
                  name="modalidade"
                  value={this.state.selectModality}
                  onChange={this.handleSelectModalityChange}
                >
                  <option value="">Selecione</option>
                  {this.productModalities.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </select>
                {this.state.error_selectModality && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
            </div>
            <div className="rowContainer">
              <div className="modulePriceHolder defaultFlex noMarginLeft">
                <div className="inputHolder defaultFlex">
                  <span>Código no ERP</span>
                  <input
                    type="number"
                    name="codExternoAdesao"
                    value={this.state.inputCodExternoAdesao}
                    onChange={this.handleInputCodExternoAdesaoChange}
                  />
                </div>
                <div className="inputHolder defaultFlex">
                  <span>Preço de Adesão</span>
                  <CurrencyFormat
                    value={this.state.inputPrecoAdesao || ''}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                    className="right"
                    onChange={this.handleInputPrecoAdesaoChange}
                  />
                </div>
              </div>
              <div className="modulePriceHolder defaultFlex noMarginRight">
                <div className="inputHolder defaultFlex">
                  <span>Código no ERP</span>
                  <input
                    type="number"
                    name="codExternoAdesao"
                    value={this.state.inputCodExternoMensal}
                    onChange={this.handleInputCodExternoMensalChange}
                  />
                </div>
                <div className="inputHolder defaultFlex noMarginRight">
                  <span>Preço de Manutenção</span>
                  <CurrencyFormat
                    value={this.state.inputPrecoMensal || ''}
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$ "
                    className="right"
                    onChange={this.handleInputPrecoMensalChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Política de Descontos</span>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex noMarginRight">
                <div className="radioHolder justifyFlexStart">
                  <div className="inputBoxHolder">
                    <input
                      type="checkbox"
                      value={this.state.checkDescontoAtivo}
                      checked={this.state.checkDescontoAtivo}
                      onChange={this.handleCheckDescontoAtivoChange}
                    />
                    <span className="checkmark">
                      {this.state.checkDescontoAtivo && (
                        <div className="markHolder">
                          <div className="checkmark_stem" />
                          <div className="checkmark_kick" />
                        </div>
                      )}
                    </span>
                  </div>
                  <span id="radioLabel">Permite desconto</span>
                </div>
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Disponibilidade</span>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex noMarginRight">
                <div className="radioHolder justifyFlexStart">
                  <div className="inputBoxHolder">
                    <input
                      type="checkbox"
                      value={this.state.checkAtivo}
                      checked={this.state.checkAtivo}
                      onChange={this.handleCheckAtivoChange}
                    />
                    <span className="checkmark">
                      {this.state.checkAtivo && (
                        <div className="markHolder">
                          <div className="checkmark_stem" />
                          <div className="checkmark_kick" />
                        </div>
                      )}
                    </span>
                  </div>
                  <span id="radioLabel">Disponível</span>
                </div>
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Inserção Automática</span>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex noMarginRight">
                <div className="radioHolder justifyFlexStart">
                  <div className="inputBoxHolder">
                    <input
                      type="checkbox"
                      value={this.state.checkIsAutomatic}
                      checked={this.state.checkIsAutomatic}
                      onChange={this.handleCheckIsAutomaticChange}
                    />
                    <span className="checkmark">
                      {this.state.checkIsAutomatic && (
                        <div className="markHolder">
                          <div className="checkmark_stem" />
                          <div className="checkmark_kick" />
                        </div>
                      )}
                    </span>
                  </div>
                  <span id="radioLabel">Inserção automática no pedido</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default EditProductModule;
