/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import orderBy from 'lodash/orderBy';
import TableComponent from '~/components/TableComponent';
import * as S from './styles';

export default function Order({ orderItems }) {
  const [totalAdesaoValue, totalMensalValue] = useMemo(() => {
    let totalAdesao = 0;
    let totalMensal = 0;

    for (let index = 0; index < orderItems.length; index += 1) {
      totalAdesao += orderItems[index].valorAdesaoTotal;
      totalMensal += orderItems[index].valorMensalTotal;
    }

    return [totalAdesao, totalMensal];
  }, [orderItems]);

  const headerLabelsData = [
    { text: 'Código', width: '70px' },
    { text: 'Módulo', width: '40%' },
    { text: 'Software', width: '20%' },
    {
      text: 'QTD',
      width: '70px',
      align: 'center',
    },
    {
      text: 'Valor Negociado',
      width: 'auto',
      align: 'center',
      nowrap: true,
    },
    {
      text: 'Valor Total',
      width: 'auto',
      align: 'center',
      nowrap: true,
    },
  ];

  const renderRowComponentAdesao = row => {
    if (row.renderTotal) {
      return [
        <S.TableCellContent key='row_adesao_total_0' colSpan={5} align='right'>
          <span className='title'>Total Geral:</span>
        </S.TableCellContent>,
        <S.TableCellContent key='row_adesao_total_1' align='right'>
          <span>
            <S.CurrencyFormatValue value={row.renderTotal || ''} />
          </span>
        </S.TableCellContent>,
      ];
    }

    if (row.modulo) {
      return [
        <S.TableCellContent key='row_adesao_modulo_0' align="center">
          {row.modulo.codExternoAdesao || '-'}
        </S.TableCellContent>,
        <S.TableCellContent key='row_adesao_modulo_1'>
          {row.modulo && row.modulo.nome}
        </S.TableCellContent>,
        <S.TableCellContent key='row_adesao_modulo_2'>
          {row.software && row.software.nome}
        </S.TableCellContent>,
        <S.TableCellContent key='row_adesao_modulo_3' align="center">
          {row.quantidade}
        </S.TableCellContent>,
        <S.TableCellContent key='row_adesao_modulo_4' align="right">
          <S.CurrencyFormatValue value={row.valorAdesaoUnidade || ''} />
        </S.TableCellContent>,
        <S.TableCellContent key='row_adesao_modulo_5' align="right">
          <S.CurrencyFormatValue value={row.valorAdesaoTotal || ''} />
        </S.TableCellContent>,
      ];
    }

    return null;
  };

  const renderRowComponentMensal = row => {
    if (row.renderTotal) {
      return [
        <S.TableCellContent key='row_mensal_total_0' colSpan={5} align="right">
          <span className='title'>Total Geral:</span>
        </S.TableCellContent>,
        <S.TableCellContent key='row_mensal_total_1' align="right">
          <span>
            <S.CurrencyFormatValue value={row.renderTotal || ''} />
          </span>
        </S.TableCellContent>,
      ];
    }

    if (row.modulo) {
      return [
        <S.TableCellContent key='row_mensal_modulo_0' align="center">
          {row.modulo.codExternoMensal || '-'}
        </S.TableCellContent>,
        <S.TableCellContent key='row_mensal_modulo_1'>
          {row.modulo && row.modulo.nome}
        </S.TableCellContent>,
        <S.TableCellContent key='row_mensal_modulo_2'>
          {row.software && row.software.nome}
        </S.TableCellContent>,
        <S.TableCellContent key='row_mensal_modulo_3' align="center">
          {row.quantidade}
        </S.TableCellContent>,
        <S.TableCellContent key='row_mensal_modulo_4' align="right">
          <S.CurrencyFormatValue value={row.valorMensalUnidade || ''} />
        </S.TableCellContent>,
        <S.TableCellContent key='row_mensal_modulo_5' align="right">
          <S.CurrencyFormatValue value={row.valorMensalTotal || ''} />
        </S.TableCellContent>,
      ];
    }

    return null;
  };

  const dataObjectAdesao = () => {
    const orderItemsTemp = [
      ...orderItems.map((item) => {
        item.valorUnidade = item.valorAdesaoUnidade;
        item.valorTotal = item.valorAdesaoTotal;
        return item;
      }),
      { renderTotal: totalAdesaoValue },
    ];

    return orderBy(orderItemsTemp, 'softwareId');
  };

  const dataObjectMensal = () => {
    const orderItemsTemp = [
      ...orderItems.map((item) => {
        item.valorUnidade = item.valorMensalUnidade;
        item.valorTotal = item.valorMensalTotal;
        return item;
      }),
      { renderTotal: totalMensalValue },
    ];

    return orderBy(orderItemsTemp, 'softwareId');
  };

  return (
    <S.Container>
      <span className="orderItemsTableHeader">
        Valores de venda da licença
      </span>
      <TableComponent
        headerLabels={headerLabelsData}
        dataObjects={dataObjectAdesao()}
        useCustomActions
        renderRowComponent={renderRowComponentAdesao}
      />
      <span className="orderItemsTableHeader">
        Valores de manutenção da licença
      </span>
      <TableComponent
        headerLabels={headerLabelsData}
        dataObjects={dataObjectMensal()}
        useCustomActions
        renderRowComponent={renderRowComponentMensal}
      />
    </S.Container>
  );
}
