import { all, put, takeLatest } from 'redux-saga/effects';
import { USERINFO_KEY } from '~/util/auth';
import { signInFailure } from '../auth/actions';
import { actionTypes } from './actions';

export function* setUserUpdateData({ payload }) {
  const { data } = payload;

  if (!data) {
    yield put(signInFailure());
  } else {
    localStorage.setItem(USERINFO_KEY, JSON.stringify(data));
  }
}

export default all([
  takeLatest(actionTypes.USER_UPDATE_DATA, setUserUpdateData),
]);
