import { ATTEMPT_KEY, USERBLOCK_KEY } from './auth';

/**
 * The function sets the initial values for the attempt and user block keys in the
 * local storage.
 */
export function setAttemptInit() {
  localStorage.setItem(ATTEMPT_KEY, 0);
  localStorage.setItem(USERBLOCK_KEY, 'false');
}

/**
 * The function `setAttemptSignIn` is used to track the number of sign-in attempts
 * and determine if the user should be blocked after 5 unsuccessful attempts.
 * @returns Nothing is being returned from this function.
 */
export function setAttemptSignIn({ clear }) {
  if (clear) {
    localStorage.removeItem(ATTEMPT_KEY);
    localStorage.removeItem(USERBLOCK_KEY);
    return;
  }

  const attempt = localStorage.getItem(ATTEMPT_KEY);

  if (Number(attempt) < 5) {
    const sum = Number(attempt) + 1;
    localStorage.setItem(ATTEMPT_KEY, String(sum));
    localStorage.setItem(USERBLOCK_KEY, String(sum >= 5));
  } else {
    localStorage.setItem(ATTEMPT_KEY, String(5));
    localStorage.setItem(USERBLOCK_KEY, String(true));
  }
}

/**
 * The function retrieves the number of sign-in attempts and the user block status
 * from local storage.
 * @returns an array with two values. The first value is the number of login
 * attempts stored in the localStorage under the key ATTEMPT_KEY. The second value
 * is a boolean indicating whether the user is currently blocked, stored in the
 * localStorage under the key USERBLOCK_KEY. If either of these values is not found
 * in the localStorage, the function returns [0, false] as the default values
 */
export function getAttemptSignIn() {
  const attempt = localStorage.getItem(ATTEMPT_KEY);
  const block = localStorage.getItem(USERBLOCK_KEY);

  if (attempt && block) return [Number(attempt), block];

  return [0, false];
}
