export class CommercialOrderDraft {
  constructor(props) {
    const {
      activeStep,
      formaPagamentoId,
      planoPagamentoId,
      obs,
      idClient,
      clienteId,
      softwareId,
      modalityId,
      selectedSoftware,
      selectedModality,
      userCreateOrderId,
      items,
      mesInicio,
      allFormsToAnswer,
      answerForms,
      forms,
      formsToAnswer,
      formularios,
    } = props;

    const getSoftwaresId = (id, selected) => {
      if (typeof id === 'object') return id;
      else if (![undefined, null, 0].includes(id)) return id;
      else if (![undefined, null].includes(selected)) return selected.id;
      else return 0;
    };

    const getId = (id, selected) => {
      if (![undefined, null, 0].includes(id)) return id;
      else if (![undefined, null].includes(selected)) return selected.id;
      else return 0;
    };

    const getFormularios = (formularios, {
      allFormsToAnswer,
      answerForms,
      forms,
      formsToAnswer,
    }) => {
      if (![undefined, null].includes(formularios)) return formularios;
      else return {
        forms,
        answerForms,
        allFormsToAnswer,
        formsToAnswer,
        formCurrentId: 0,
        formCurrentIndex: 0,
        formCheckboxTemp: [],
        answerFormsIds: [],
      };
    };

    this.obs = obs;
    this.activeStep = activeStep;
    this.clienteId = idClient || clienteId;
    this.mesInicio = mesInicio;
    this.softwareId = getSoftwaresId(softwareId, selectedSoftware);
    this.modalityId = getId(modalityId, selectedModality);
    this.items = items;
    this.formaPagamentoId = formaPagamentoId;
    this.planoPagamentoId = planoPagamentoId;
    this.userCreateOrderId = userCreateOrderId;
    this.formularios = getFormularios(
      formularios,
      {
        allFormsToAnswer,
        answerForms,
        forms,
        formsToAnswer,
      }
    );
  }
}
