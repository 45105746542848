import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { FaCircle } from 'react-icons/fa';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Field from '~/components/Field';
import FieldCEP from '~/components/FieldCEP';
import FieldCNPJ from '~/components/FieldCNPJ';
import FieldCPF from '~/components/FieldCPF';
import FieldPhone from '~/components/FieldPhone';
import Modal from '~/components/Modal';
import Select from '~/components/Select';
import moment from 'moment';
import { AutoCompleteOn } from '~/components/AutoCompleteOn';
import { useSelector } from 'react-redux';
import PersonClientEdit from '~/model/person-client-edit';
import { person } from '~/routes/paths';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import api, { endpoints } from '~/services/api';
import { LineBusinessService } from '~/services/person/linebusiness';
import PersonResaleService from '~/services/person-resale-service';
import PersonClientService from '~/services/person-client-service';
import { PERSON_STATUS_ENUM, UNIDADES_FEDERATIVAS } from '~/util/domainutils';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import {
  inputNumber,
  inputToLowerCase,
  unFormatCpfCnpj,
  validePhone,
  onlyText,
  formatCpfCnpj,
} from '~/util/stringutils';
import schema, { defaultValues } from './schema';
import { ContainerLog, ContentLog } from './styles';

function EditPersonClient(props) {
  const { history, location } = props;
  const [loading, setLoading] = useState(false);
  const [lineBusinesses, setLineBusinesses] = useState([]);
  const [attendances, setAttendances] = useState([]);
  const {
    control,
    handleSubmit,
    watch,
    errors,
    reset,
    setValue,
    setError,
    register,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [resales, setResales] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [handleTimeout, setHandleTimeout] = useState(null);
  const [inputResaleValue, setInputValue] = useState('');

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanBlockUser = useMemo(
    () => userPermissions.includes(permissions.block_user),
    [userPermissions]
  );

  const userCanChangeAttendance = useMemo(
    () => userPermissions.includes(
      permissions['edit_mudanca-atendimento-revenda']
    ),
    [userPermissions]
  );

  const userCanViewLogChangeAttendance = useMemo(
    () => userPermissions.includes(
      permissions['view_mudanca-atendimento-revenda']
    ),
    [userPermissions]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([
      LineBusinessService.getLineBusiness(''),
      PersonClientService.getPersonClient(location.state.id),
      PersonClientService.getAttendanceChangeClient(location.state.id),
    ])
      .then(([{ resultado: lineBusinesses }, cliente, attendances]) => {
        setLineBusinesses(lineBusinesses);
        reset(new PersonClientEdit(cliente));
        setInputValue(renderSuggestion(cliente.revenda));
        setAttendances(attendances);
      })
      .catch(() => {
        toast.error('Erro ao carregar Dados!');
        setTimeout(() => {
          history.push(person.personClient);
        }, 100); // 0.10 s
      })
      .finally(() => {
        setLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function validateForm(formData, success) {
    const data = {};

    data.bairro = formData['bairro'].trim();
    data.cep = formData['cep'];
    data.cidade = formData['cidade'].trim();
    data.cnae = '';
    data.complemento = formData['complemento'].trim();
    data.cpfCnpj = unFormatCpfCnpj(formData['cpfCnpj']);
    data.dataCadastro = moment(formData['dataCadastro']).format('DD/MM/YYYY');
    data.email = formData['email'].toLowerCase().trim();
    data.endereco = formData['endereco'].trim();
    data.estado = formData['estado'].trim();
    data.inscricaoEstadual = formData['inscricaoEstadual'].trim();
    data.microempresa = formData['microempresa'];
    data.nomeFantasia = formData['nomeFantasia'].trim();
    data.pontoReferencia = formData['pontoReferencia'].trim();
    data.prazoRenovacaoChave = formData['prazoRenovacaoChave'].trim();
    data.ramoAtividadeId = formData['ramoAtividadeId'];
    data.razaoSocial = formData['razaoSocial'].trim();
    data.responsavel = formData['responsavel'].trim();
    data.retemICMS = formData['retemICMS'];
    data.optanteSimples = formData['optanteSimples'];
    data.status = formData['status'];
    data.tipoPessoa = formData['tipoPessoa'];
    data.statusChave = formData['statusChave'];
    data.revendaId = formData['revendaId'];

    if (!validePhone(formData['telefone1'])) {
      return setError('telefone1', {
        type: 'valueAsNumber',
        message: 'Digite um número válido',
        shouldFocus: true,
      });
    }

    if (formData['telefone2'] && !validePhone(formData['telefone2'])) {
      return setError('telefone2', {
        type: 'valueAsNumber',
        message: 'Digite um número válido',
        shouldFocus: true,
      });
    }

    data.telefone1 = formData['telefone1'];
    data.telefone2 = formData['telefone2'];

    success(data);
  }

  function handleOptanteSimples() {
    if (watch('tipoPessoa') === 'JURÍDICA') {
      setValue('optanteSimples', false);
    }

    return watch('tipoPessoa') === 'JURÍDICA';
  }

  function handleSelectedStatusOutline(field, value) {
    return watch(field) === PERSON_STATUS_ENUM[value].value
      ? 'checkradio_outline' : '';
  }

  const onSubmit = useCallback(async form => {
    setLoading(true);
    validateForm(form, async formData => {
      try {
        const { id } = location.state; // Id do cliente
        const { data } = await api.put(
          endpoints.person.client.updatePersonClient,
          { id, ...formData }
        );
        toast.success(data.message);
        setTimeout(() => {
          history.push(person.personClient);
        }, 100); // 0.10 s
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const errorsStatus = errors => errors['status'];
  const errorsStatusChave = errors => errors['statusChave'];

  const handleSetStatusCliente = () => {
    return setValue('statusChave', PERSON_STATUS_ENUM.BLOCKED.value);
  };

  const handleStatusIsBlocked = () => {
    return watch('status') === PERSON_STATUS_ENUM.BLOCKED.value;
  };

  function handleVerifyRevenda(resale) {
    setInputValue(renderSuggestion(resale));
    setValue('revendaId', resale.id);
  }

  async function getResales(value) {
    try {
      const data = await PersonResaleService.getPersonResaleAutoComplete({
        cpfCnpj: unFormatCpfCnpj(value.trim().toLowerCase()) || null,
        nome: onlyText(value.trim().toLowerCase()) || null,
        offset: 0,
        limit: 0,
      });

      return data.resultado;
    } catch (error) {
      showMessageError(error);

      return [];
    }
  }

  async function getResalesChange(event) {
    const {
      target: { value: valueToFind }
    } = event;

    if (valueToFind === '') {
      setResales([]);
      setShowSuggestions(false);
      setValue('revendaId', '');
    } else {
      clearTimeout(handleTimeout);

      const timer = setTimeout(() => {
        const asyncSetTimeOut = async () => {
          const resales = await getResales(valueToFind);
          if (resales.length) {
            setResales(resales);
            setShowSuggestions(true);
          } else {
            setResales([]);
            setShowSuggestions(true);
          }
        };

        asyncSetTimeOut();
      }, 500); // 0.5 segundos

      setHandleTimeout(timer);
    }

    setInputValue(valueToFind);
  }

  function getSuggestionLabel(suggestion) {
    if (suggestion) {
      return renderSuggestion(suggestion);
    } else {
      return '';
    }
  }

  function getSuggestionKey(suggestion) {
    if (suggestion) {
      return suggestion.id;
    } else {
      return '';
    }
  }

  function renderSuggestion(suggestion) {
    return `${formatCpfCnpj(suggestion.cpfCnpj)} - ${suggestion.nomeFantasia}`;
  }

  function handleOnClick(suggestion) {
    const [resaleSelected] = resales.filter(
      suggItem => suggItem.id === suggestion.id
    );

    handleVerifyRevenda(resaleSelected);
    setShowSuggestions(false);
  }

  const showInfoCard = (permission) => {
    return permission ? 'block' : 'none';
  };

  return (
    <div className="defaultFormContainer">
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Pessoa"
        pageName="Editar Cliente"
        breadcrumbs={[{
          label: 'Cliente',
          link: person.personClient,
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => handleSubmit(onSubmit),
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={() => history.goBack()}
      />
      <form className="defaultForm">
        <div className="infoCard" style={{ display: showInfoCard(userCanBlockUser) }}>
          <span id="title">Permissões</span>
          <div className="rowContainer">
            <div className="inputHolder addMarginTop">
              <span id="subtitle">Status do Cliente *</span>
              <div className="rowContainer">
                <div className='radioHolder'>
                  <div className='inputBoxHolder'>
                    <Field
                      type="radio"
                      name="status"
                      value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}
                      control={control}
                    />
                    <span className={
                      `checkradio ${handleSelectedStatusOutline(
                        'status', PERSON_STATUS_ENUM.NOT_BLOCKED.key
                      )}`
                    }>
                      {watch('status') === PERSON_STATUS_ENUM.NOT_BLOCKED.value
                        && (
                          <div className="markRadioHolder">
                            <div className="checkradio_stem" />
                          </div>
                        )}
                    </span>
                  </div>
                  <span id='radioLabel'>
                    {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
                  </span>
                </div>
                <div className='radioHolder'>
                  <div className='inputBoxHolder'>
                    <Field
                      type="radio"
                      name="status"
                      value={PERSON_STATUS_ENUM.BLOCKED.value}
                      control={control}
                      onClick={handleSetStatusCliente}
                    />
                    <span className={
                      `checkradio ${handleSelectedStatusOutline(
                        'status', PERSON_STATUS_ENUM.BLOCKED.key
                      )}`
                    }>
                      {watch('status') === PERSON_STATUS_ENUM.BLOCKED.value
                        && (
                          <div className="markRadioHolder">
                            <div className="checkradio_stem" />
                          </div>
                        )}
                    </span>
                  </div>
                  <span id='radioLabel'>
                    {PERSON_STATUS_ENUM.BLOCKED.label}
                  </span>
                </div>
              </div>
              {errorsStatus(errors) && (
                <small style={{ color: 'red' }}>Campo obrigatório</small>
              )}
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Geração de Chave</span>
          <div className="rowContainer">
            <div className="inputHolder addMarginTop marginRightX4">
              <span id="subtitle">Status Geração de Chave *</span>
              <div className="rowContainer">
                <div className='radioHolder'>
                  <div className='inputBoxHolder'>
                    <Field
                      type="radio"
                      name="statusChave"
                      value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}
                      control={control}
                      disabled={handleStatusIsBlocked()}
                      onClick={() => {
                        if (!handleStatusIsBlocked()) {
                          setValue(
                            'statusChave', PERSON_STATUS_ENUM.NOT_BLOCKED.value,
                          );
                        }
                      }}
                    />
                    <span className={
                      `checkradio ${handleSelectedStatusOutline(
                        'statusChave', PERSON_STATUS_ENUM.NOT_BLOCKED.key
                      )}`
                    }>
                      {watch('statusChave') === PERSON_STATUS_ENUM.NOT_BLOCKED.value
                        && (
                          <div className="markRadioHolder">
                            <div className="checkradio_stem" />
                          </div>
                        )}
                    </span>
                  </div>
                  <span id='radioLabel'>
                    {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
                  </span>
                </div>
                <div className='radioHolder'>
                  <div className='inputBoxHolder'>
                    <Field
                      type="radio"
                      name="statusChave"
                      value={PERSON_STATUS_ENUM.BLOCKED.value}
                      control={control}
                      disabled={handleStatusIsBlocked()}
                      onClick={() => {
                        if (!handleStatusIsBlocked()) {
                          setValue(
                            'statusChave', PERSON_STATUS_ENUM.BLOCKED.value,
                          );
                        }
                      }}
                    />
                    <span className={
                      `checkradio ${handleSelectedStatusOutline(
                        'statusChave', PERSON_STATUS_ENUM.BLOCKED.key
                      )}`
                    }>
                      {watch('statusChave') === PERSON_STATUS_ENUM.BLOCKED.value
                        && (
                          <div className="markRadioHolder">
                            <div className="checkradio_stem" />
                          </div>
                        )}
                    </span>
                  </div>
                  <span id='radioLabel'>
                    {PERSON_STATUS_ENUM.BLOCKED.label}
                  </span>
                </div>
              </div>
              {errorsStatusChave(errors) && (
                <small style={{ color: 'red' }}>Campo obrigatório</small>
              )}
            </div>
            <div className="inputHolder addMarginTop">
              <span id="subtitle">Prazo de Renovação de Chave</span>
              <div className="rowContainer rowContainerCenter">
                <div className='inputHolder noMarginRight inputHolderSmall'>
                  <Field
                    type="text"
                    name="prazoRenovacaoChave"
                    labelHorizontal="dias"
                    className="widthPrazoRenovacaoChave"
                    control={control}
                    errors={errors}
                    onChange={(e) =>
                      setValue(
                        'prazoRenovacaoChave',
                        inputNumber(e.target.value)
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Dados Gerais</span>
          <div className="rowContainer">
            <div className="inputHolder">
              <Select
                name="tipoPessoa"
                label="Tipo de Pessoa *"
                control={control}
                disabled
              >
                <option value="FÍSICA">FÍSICA</option>
                <option value="JURÍDICA">JURÍDICA</option>
              </Select>
            </div>
            {watch('tipoPessoa') === 'FÍSICA' ? (
              <div className="inputHolder defaultFlex">
                <FieldCPF
                  control={control}
                  name="cpfCnpj"
                  label="CPF *"
                  errors={errors}
                  disabled
                />
              </div>
            ) : (
              <div className="inputHolder defaultFlex">
                <FieldCNPJ
                  control={control}
                  name="cpfCnpj"
                  label="CNPJ *"
                  errors={errors}
                  disabled
                />
              </div>
            )}
            <div className="inputHolder defaultFlex noMarginRight">
              <Field
                type="text"
                name="razaoSocial"
                placeholder="Razão Social"
                label="Razão Social *"
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex">
              <Field
                type="text"
                name="nomeFantasia"
                placeholder="Nome Fantasia"
                label="Nome Fantasia *"
                control={control}
                errors={errors}
              />
            </div>
            <div
              className={`
              inputHolder
              defaultFlex
              ${watch('tipoPessoa') === 'FÍSICA' && 'noMarginRight'}`}
            >
              <Field
                type="text"
                name="inscricaoEstadual"
                placeholder={watch('tipoPessoa') === 'FÍSICA'
                  ? 'RG'
                  : 'Inscrição Estadual'
                }
                label={watch('tipoPessoa') === 'FÍSICA'
                  ? 'RG *'
                  : 'Inscrição Estadual *'
                }
                control={control}
                errors={errors}
              />
            </div>
            {handleOptanteSimples() ? (
              <div className="inputHolder noMarginRight">
                <Select
                  name="optanteSimples"
                  label="Optante do SIMPLES"
                  control={control}
                  errors={errors}
                >
                  <option value={false}>Não</option>
                  <option value={true}>Sim</option>
                </Select>
              </div>
            ) : null}
          </div>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex">
              <Select
                name="ramoAtividadeId"
                label="Ramo de Atividade *"
                control={control}
                errors={errors}
              >
                <option value="" disabled>
                  Selecione
                </option>
                {(lineBusinesses || []).map(ramo => (
                  <option key={ramo.id} value={ramo.id}>
                    {ramo.nome}
                  </option>
                ))}
              </Select>
            </div>
            <div className="inputHolder defaultFlex noMarginRight">
              <Field
                type="text"
                name="responsavel"
                placeholder="Responsável"
                label="Responsável *"
                control={control}
                errors={errors}
              />
            </div>
            <div />
          </div>
        </div>

        <div className="infoCard" style={{ display: showInfoCard(userCanChangeAttendance) }}>
          <span id="title">Revenda</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>De qual revenda?</span>
              <span id="helpText">
                Ao digitar, o campo irá auto completar. A busca do cliente
                poderá ser feita pelo CNPJ ou Nome Fantasia.
              </span>
              <AutoCompleteOn
                {...register('revendaId')}
                inputDataValue={inputResaleValue}
                suggestions={resales}
                isShowSuggestions={showSuggestions}
                handleDataChange={getResalesChange}
                handleGetSuggestionKey={getSuggestionKey}
                handleGetSuggestionLabel={getSuggestionLabel}
                handleOnClick={handleOnClick}
                isDisabled={!userCanChangeAttendance}
              />
            </div>
          </div>
        </div>

        <div className="infoCard">
          <span id="title">Endereço</span>
          <div className="rowContainer">
            <div className="inputHolder">
              <Select
                name="estado"
                label="Estado *"
                control={control}
                errors={errors}
              >
                <option value="" disabled>
                  Selecione
                </option>
                {UNIDADES_FEDERATIVAS.map(uf => (
                  <option key={uf.sigla} value={uf.nome}>
                    {uf.nome}
                  </option>
                ))}
              </Select>
            </div>
            <div className="inputHolder">
              <Field
                type="text"
                name="cidade"
                placeholder="Cidade"
                label="Cidade *"
                control={control}
                errors={errors}
              />
            </div>
            <div className="inputHolder">
              <FieldCEP
                control={control}
                name="cep"
                label="CEP *"
                errors={errors}
              />
            </div>
            <div className="inputHolder defaultFlex noMarginRight">
              <Field
                type="text"
                name="bairro"
                placeholder="Bairro"
                label="Bairro *"
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex">
              <Field
                type="text"
                name="endereco"
                label="Endereço Completo *"
                control={control}
                errors={errors}
              />
            </div>
            <div className="inputHolder defaultFlex noMarginRight">
              <Field
                type="text"
                name="complemento"
                label="Complemento"
                control={control}
                errors={errors}
              />
            </div>
          </div>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <Field
                type="text"
                name="pontoReferencia"
                label="Ponto de Referência"
                control={control}
                errors={errors}
              />
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Contato</span>
          <div className="rowContainer">
            <div className="inputHolder">
              <FieldPhone
                control={control}
                name="telefone1"
                label="Telefone(1) *"
                errors={errors}
              />
            </div>
            <div className="inputHolder">
              <FieldPhone
                control={control}
                name="telefone2"
                label="Telefone(2)"
                errors={errors}
              />
            </div>
            <div className="inputHolder defaultFlex noMarginRight">
              <Field
                type="email"
                name="email"
                label="E-mail *"
                placeholder="email@email.com"
                control={control}
                errors={errors}
                onChange={e =>
                  setValue('email', inputToLowerCase(e.target.value), {
                    shouldValidate: true,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Outros</span>
          <div className="rowContainer">
            {/* <div className="inputHolder defaultFlex">
              <Field
                type="text"
                name="cnae"
                label="CNAE *"
                control={control}
                errors={errors}
              />
            </div> */}
            <div className="inputHolder defaultFlex noMarginRight">
              <Field
                readOnly
                disabled
                type="date"
                name="dataCadastro"
                label="Data de Cadastro"
                control={control}
                errors={errors}
              />
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Opções</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex">
              <Select
                name="microempresa"
                label="Microempresa"
                control={control}
                errors={errors}
              >
                <option value={false}>Não</option>
                <option value>Sim</option>
              </Select>
            </div>
            <div className="inputHolder defaultFlex noMarginRight">
              <Select
                name="retemICMS"
                label="Retém ICMS"
                control={control}
                errors={errors}
              >
                <option value={false}>Não</option>
                <option value>Sim</option>
              </Select>
            </div>
          </div>
        </div>
        {userCanViewLogChangeAttendance && (
          <div className="infoCard">
            <ContentLog>
              <span id='title-log'>Log:</span>
              <div className="rowContainer">
                {attendances.length ? (
                  attendances.map((attendance) => {
                    const revendaAtual = attendance.revendaAtual
                      ? (
                        attendance.revendaAtual.nomeFantasia
                        || attendance.revendaAtual.razaoSocial
                        || attendance.revendaAtual.nomeSocial
                      )
                      : '';

                    const revendaAntiga = attendance.revendaAntiga
                      ? (
                        attendance.revendaAntiga.nomeFantasia
                        || attendance.revendaAntiga.razaoSocial
                        || attendance.revendaAntiga.nomeSocial
                      )
                      : '';

                    const atualizadoPor = attendance.atualizadoPor
                      ? (
                        attendance.atualizadoPor.nomeFantasia
                        || attendance.atualizadoPor.razaoSocial
                        || attendance.atualizadoPor.nomeSocial
                      )
                      : '';

                    return (
                      <ContainerLog key={attendance.id}>
                        <FaCircle />
                        <p>{`Mudança de atendimento de ${revendaAntiga}`
                          + ` para revenda ${revendaAtual}`
                          + ` atualizado por ${atualizadoPor},`
                          + ` em ${moment(attendance.createdAt)
                            .format('DD/MM/YYYY HH:mm:ss')}.`}</p>
                      </ContainerLog>
                    );
                  })
                ) : (
                  <ContainerLog>
                    <p>Sem registro de mudança de atendimento</p>
                  </ContainerLog>
                )}
              </div>
            </ContentLog>
          </div>
        )}
      </form>
    </div>
  );
}

EditPersonClient.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default EditPersonClient;
