import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import { FaRegEdit, FaRegTrashAlt, FaUserLock } from 'react-icons/fa';
import { FiUnlock } from 'react-icons/fi';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import PrintableComponent from '~/components/PrintableComponent';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { person } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import PersonClientService from '~/services/person-client-service';
import { showMessageError } from '~/util/errorutils';
import { USER_TYPE } from '~/util/domainutils';
import { PERSON_STATUS_ENUM } from '~/util/domainutils';
import permissions from '~/util/permissions';
import { accessDenied } from '~/util/accessDenied';
import { formatCpfCnpj, inputNumber } from '~/util/stringutils';
import TableComponent from '~/components/TableComponent';
import PersonSearchComponent from '../SearchComponent';
import { useFitler } from '~/hook/useFilter';
import * as S from './styles';

function PersonClient({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(false);
  const [inputCode, setInputCode] = useState('');
  const [inputCliente, setInputCliente] = useState('');
  const [inputRevenda, setInputRevenda] = useState('');
  const [inputStatus, setInputStatus] = useState('');
  const [inputStatusChave, setInputStatusChave] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [notBlockedItem, setNotBlockedItem] = useState(null);
  const [blockedItem, setBlockedItem] = useState(null);
  const [keyNotBlockedItem, setKeyNotBlockedItem] = useState(null);
  const [keyBlockedItem, setKeyBlockedItem] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    id: null,
    textPessoa1: '',
    textPessoa2: '',
    status: '',
    statusChave: '',
  });

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanViewColumnRevenda = useMemo(
    () => userPermissions.includes(permissions['view_coluna-revenda']),
    [userPermissions]
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions.create_cliente),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions.edit_cliente),
    [userPermissions]
  );

  const userCanEditMassActions = useMemo(
    () => userPermissions.includes(permissions['edit_mass-actions']),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions.delete_cliente),
    [userPermissions]
  );

  const userCanBlock = useMemo(
    () => userPermissions.includes(permissions.block_user),
    [userPermissions]
  );

  let PrintableElement = React.useRef();

  useEffect(() => {
    findAllByFilter(pageParameters, handleFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilters() {
    let count = 0;

    if (filters.id) {
      setInputCode(filters.id);
      count += 1;
    }
    if (filters.textPessoa1) {
      setInputCliente(filters.textPessoa1);
      count += 1;
    }
    if (filters.textPessoa2) {
      setInputRevenda(filters.textPessoa2);
      count += 1;
    }
    if (filters.status) {
      setInputStatus(filters.status);
      count += 1;
    }
    if (filters.statusChave) {
      setInputStatusChave(filters.statusChave);
      count += 1;
    }

    setCountFilter(count);

    return filters;
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  function handleInputCodeChange(event) {
    const {
      target: { value },
    } = event;

    setInputCode(inputNumber(value));
  }

  function handleInputClienteChange(event) {
    const {
      target: { value },
    } = event;

    setInputCliente(value);
  }

  function handleInputRevendaChange(event) {
    const {
      target: { value },
    } = event;

    setInputRevenda(value);
  }

  function handleStatusChange(event) {
    const {
      target: { value },
    } = event;

    setInputStatus(value);
  }

  function handleStatusChaveChange(event) {
    const {
      target: { value },
    } = event;

    setInputStatusChave(value);
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};

    if (inputCode.length) {
      data.id = inputCode.trim();
      count += 1;
    }

    if (inputCliente.length) {
      data.textPessoa1 = inputCliente.trim();
      count += 1;
    }

    if (inputRevenda.length) {
      data.textPessoa2 = inputRevenda.trim();
      count += 1;
    }

    if (inputStatus.length) {
      data.status = inputStatus;
      count += 1;
    }

    if (inputStatusChave.length) {
      data.statusChave = inputStatusChave;
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    setFilters(data);
    await success(data);
  }

  async function findAllByFilter(pageParams, dataParams) {
    setPageParams(pageParams);
    setLoading(true);

    try {
      const { page, rowsPerPage: limit } = pageParams;
      const data = await PersonClientService.getPersonClients({
        ...(dataParams || formData),
        limit,
        offset: page * limit,
      });

      if (data.resultado.length) {
        handleSetSearchResponse(data.resultado, data.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos clientes para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilterReport(pageParams, formDataParam) {
    setLoading(true);
    try {
      const data = await PersonClientService
        .getPersonClients({ ...formDataParam });

      if (data.resultado.length) {
        handleSetSearchResponseReport(data.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos clientes para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  async function handleDelete(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const { data: response } = await api.delete(
        endpoints.person.client.deletePersonClient(deleteItem.id)
      );
      if (!response.data.ok) {
        toast('Erro ao deletar o Cliente!', { type: toast.TYPE.ERROR });
      } else {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteItem.id
        );
        handleSetSearchResponse(newSearchResponse, totalItems - 1);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o Cliente!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  const renderRowComponent = (row, isPrint = true) => {
    const IS_NOT_BLOCKED = () => {
      return row.status === PERSON_STATUS_ENUM.NOT_BLOCKED.value;
    };

    const IS_KEY_NOT_BLOCKED = () => {
      return row.statusChave === PERSON_STATUS_ENUM.NOT_BLOCKED.value;
    };

    const nomeCliente = row.nomeFantasia
      || row.razaoSocial
      || '---';

    let renderColumns = [
      <TableCell key='row_person_client_0'>
        {isPrint ? (
          <S.TableCellContent>
            <S.TableCellValue title={nomeCliente}>
              {nomeCliente}
            </S.TableCellValue>
          </S.TableCellContent>
        ) : (
          <span>{nomeCliente}</span>
        )}
      </TableCell>,
      <TableCell key='row_person_client_1'>
        {row.formatedCpfCnpj || '---'}
      </TableCell>,
    ];

    const nomeRevenda = row.revenda.nomeFantasia
      || row.revenda.razaoSocial
      || '---';

    if (userCanViewColumnRevenda) {
      renderColumns = [
        ...renderColumns,
        <TableCell key='row_person_client_4'>
          {isPrint ? (
            <S.TableCellContent>
              <S.TableCellValue title={nomeRevenda}>
                {nomeRevenda}
              </S.TableCellValue>
            </S.TableCellContent>
          ) : (
            <span>{nomeRevenda}</span>
          )}
        </TableCell>,
        <TableCell key='row_person_client_5'>
          {formatCpfCnpj(row.revenda.cpfCnpj) || '---'}
        </TableCell>,
      ];
    }

    if (isPrint) {
      renderColumns = [
        ...renderColumns,
        <TableCell
          key='row_person_client_7'
          className="actionCell"
          style={{ width: '150px' }}
        >
          <div className="actionButtonsHolder">
            {userCanBlock && (
              <>
                {IS_NOT_BLOCKED() ? (
                  <FiUnlock
                    className="icon"
                    onClick={() => setNotBlockedItem(row)}
                    title="Bloquear"
                  />
                ) : (
                  <FaUserLock
                    className="icon"
                    onClick={() => setBlockedItem(row)}
                    title="Desbloquear"
                  />
                )}
                <hr />
              </>
            )}
            {IS_KEY_NOT_BLOCKED() ? (
              <S.KeyNotBloquedIcon
                className="icon"
                title='Bloquear Chave'
                alt='Botão bloquear chave'
                onClick={() => setKeyNotBlockedItem(row)}
              />
            ) : (
              <S.KeyBloquedIcon
                className="icon"
                title='Desbloquear Chave'
                alt='Botão desbloquear chave'
                onClick={() => setKeyBlockedItem(row)}
              />
            )}
            <hr />
            <FaRegEdit
              className="icon"
              onClick={() => {
                return userCanEdit
                  ? history.push(person.editPersonClient, { id: row.id })
                  : accessDenied(history);
              }}
              title="Editar"
            />
            <hr />
            <FaRegTrashAlt
              className="icon"
              onClick={() => {
                return userCanDelete
                  ? setDeleteItem(row)
                  : accessDenied(history);
              }}
              title="Deletar"
            />
          </div>
        </TableCell>,
      ];
    }

    return [
      <TableCell key='row_person_client_6'>
        {row.id}
      </TableCell>,
      ...renderColumns,
    ];
  };

  async function handleStatusUser(event) {
    event.preventDefault();
    try {
      setLoading(true);
      let params = {
        id: null,
        status: null
      };
      if (notBlockedItem) {
        params = {
          id: notBlockedItem.id,
          status: PERSON_STATUS_ENUM.BLOCKED.value,
        };
      }
      if (blockedItem) {
        params = {
          id: blockedItem.id,
          status: PERSON_STATUS_ENUM.NOT_BLOCKED.value,
        };
      }
      const response = await PersonClientService.updatePersonClientStatus(
        params.id, params.status,
      );
      if (!response.data.ok) {
        toast('Erro ao alterar status do Cliente!', { type: toast.TYPE.ERROR });
      } else {
        const newSearchResponse = searchResponse.map(resp => {
          if (resp.id === params.id) {
            return {
              ...resp,
              status: params.status,
              statusChave: notBlockedItem ? params.status : params.statusChave,
            };
          }

          return resp;
        });
        handleSetSearchResponse(newSearchResponse, totalItems);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao alterar status do Cliente!');
    } finally {
      if (notBlockedItem) setNotBlockedItem(null);
      if (blockedItem) setBlockedItem(null);
      setLoading(false);
    }
  }

  async function handleStatusChaveUser(event) {
    event.preventDefault();
    try {
      setLoading(true);

      let params = {
        id: null,
        statusChave: null
      };

      if (keyNotBlockedItem) {
        if (keyNotBlockedItem.status === PERSON_STATUS_ENUM.BLOCKED.value) {
          toast('O cliente está bloqueado!', { type: toast.TYPE.ERROR });
          return;
        }

        params = {
          id: keyNotBlockedItem.id,
          statusChave: PERSON_STATUS_ENUM.BLOCKED.value,
        };
      }

      if (keyBlockedItem) {
        if (keyBlockedItem.status === PERSON_STATUS_ENUM.BLOCKED.value) {
          toast('O cliente está bloqueado!', { type: toast.TYPE.ERROR });
          return;
        }

        params = {
          id: keyBlockedItem.id,
          statusChave: PERSON_STATUS_ENUM.NOT_BLOCKED.value,
        };
      }

      const response = await PersonClientService
        .updatePersonClientStatusKey(params.id, params.statusChave);

      if (!response.data.ok) {
        toast('Erro ao alterar status da Chave do Cliente!',
          { type: toast.TYPE.ERROR });
      } else {
        const newSearchResponse = searchResponse.map(resp => {
          if (resp.id === params.id) {
            return {
              ...resp,
              statusChave: params.statusChave,
            };
          }

          return resp;
        });
        handleSetSearchResponse(newSearchResponse, totalItems);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao alterar status do Cliente!');
    } finally {
      if (keyNotBlockedItem) setKeyNotBlockedItem(null);
      if (keyBlockedItem) setKeyBlockedItem(null);
      setLoading(false);
    }
  }

  function handleLabels() {
    if (userCanViewColumnRevenda) {
      return [
        { text: 'Código', width: '5%' },
        { text: 'Cliente', width: 'auto' },
        { text: 'CPF / CNPJ', width: '18%' },
        { text: 'Revenda', width: 'auto' },
        { text: 'CPF / CNPJ', width: '18%' },
      ];
    }

    return [
      { text: 'Código', width: '5%' },
      { text: 'Nome / Fantasia', width: 'auto' },
      { text: 'CPF / CNPJ', width: 'auto' },
    ];
  }

  const renderFilters = () => {
    if (userCanViewColumnRevenda) {
      return [
        { name: 'Código', value: inputCode },
        { name: 'Cliente', value: inputCliente },
        { name: 'Revenda', value: inputRevenda },
        { name: 'Status', value: inputStatus },
      ];
    }

    return [
      { name: 'Código', value: inputCode },
      { name: 'Cliente', value: inputCliente },
      { name: 'Status', value: inputStatus },
    ];
  };

  return (
    <>
      <PrintableComponent
        title="Cliente"
        filters={renderFilters()}
        ref={ref => (PrintableElement = ref)}
        handlePageUpdate={findAllByFilterReport}
        fieldsArray={['id', 'nomeFantasia', 'formatedCpfCnpj']}
        headerLabels={handleLabels()}
        renderRowComponent={(row) => renderRowComponent(row, false)}
        dataObjects={searchResponseReport.map(item => {
          item.formatedCpfCnpj = formatCpfCnpj(item.cpfCnpj);
          item.telefone1 = item.contato.telefone1;
          return item;
        })}
      />
      <S.Container>
        <Modal
          loading={loading}
          show={loading
            || !!deleteItem
            || !!notBlockedItem
            || !!blockedItem
            || !!keyNotBlockedItem
            || !!keyBlockedItem
          }
        >
          <>
            {!!deleteItem && (
              <S.ModalContainer>
                <h2>Confirmar exclusão</h2>
                <span>
                  Tem certeza que deseja deletar o cliente:
                  {` ${!!deleteItem &&
                    ((!!deleteItem.nomeFantasia && deleteItem.nomeFantasia) ||
                      deleteItem.id)}?`}
                </span>
                <div>
                  <S.CancelButton onClick={() => setDeleteItem(null)}>
                    Cancelar
                  </S.CancelButton>
                  <S.DeleteButton onClick={handleDelete}>Deletar</S.DeleteButton>
                </div>
              </S.ModalContainer>
            )}
            {(!!notBlockedItem || !!blockedItem) && (
              <S.ModalContainer>
                <h2>Confirmar atualização de status</h2>
                {!!notBlockedItem && (
                  <>
                    <span>
                      Tem certeza que deseja bloquear o cliente:
                      {` ${!!notBlockedItem &&
                        ((!!notBlockedItem.nomeFantasia && notBlockedItem.nomeFantasia) ||
                          notBlockedItem.id)}?`}
                    </span>
                    <div>
                      <S.CancelButton onClick={() => setNotBlockedItem(null)}>
                        Cancelar
                      </S.CancelButton>
                      <S.DeleteButton onClick={handleStatusUser}>
                        Bloquear
                      </S.DeleteButton>
                    </div>
                  </>
                )}
                {!!blockedItem && (
                  <>
                    <span>
                      Tem certeza que deseja desbloquear o cliente:
                      {` ${!!blockedItem &&
                        ((!!blockedItem.nomeFantasia && blockedItem.nomeFantasia) ||
                          blockedItem.id)}?`}
                    </span>
                    <div>
                      <S.CancelButton onClick={() => setBlockedItem(null)}>
                        Cancelar
                      </S.CancelButton>
                      <S.DeleteButton onClick={handleStatusUser}>
                        Desbloquear
                      </S.DeleteButton>
                    </div>
                  </>
                )}
              </S.ModalContainer>
            )}
            {(!!keyNotBlockedItem || !!keyBlockedItem) && (
              <S.ModalContainer>
                <h2>Confirmar atualização de status da chave</h2>
                {!!keyNotBlockedItem && (
                  <>
                    <span>
                      Tem certeza que deseja bloquear a chave do cliente:
                      {` ${!!keyNotBlockedItem &&
                        ((!!keyNotBlockedItem.nomeFantasia && keyNotBlockedItem.nomeFantasia) ||
                          keyNotBlockedItem.id)}?`}
                    </span>
                    <div>
                      <S.CancelButton onClick={() => setKeyNotBlockedItem(null)}>
                        Cancelar
                      </S.CancelButton>
                      <S.DeleteButton onClick={handleStatusChaveUser}>
                        Bloquear
                      </S.DeleteButton>
                    </div>
                  </>
                )}
                {!!keyBlockedItem && (
                  <>
                    <span>
                      Tem certeza que deseja desbloquear o cliente:
                      {` ${!!keyBlockedItem &&
                        ((!!keyBlockedItem.nomeFantasia && keyBlockedItem.nomeFantasia) ||
                          keyBlockedItem.id)}?`}
                    </span>
                    <div>
                      <S.CancelButton onClick={() => setKeyBlockedItem(null)}>
                        Cancelar
                      </S.CancelButton>
                      <S.DeleteButton onClick={handleStatusChaveUser}>
                        Desbloquear
                      </S.DeleteButton>
                    </div>
                  </>
                )}
              </S.ModalContainer>
            )}
          </>
        </Modal>
        <SidebarPageHeader
          mainMenu="Pessoa"
          pageName="Cliente"
          button1={{
            label: 'Novo Cliente',
            link: person.addPersonClient,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await validateForm(async data => {
              await findAllByFilterReport(pageParameters, data);
            });
          }}
          openButtonSelect
          customButtonsAfterReport={[
            userCanEditMassActions && (
              <button
                key='mass-action-button'
                className="buttonBase baseWebMobile addMargin"
                onClick={() => history
                  .push(person.personMassActions, {
                    goBack: person.personClient,
                    pageMain: 'Cliente'
                  })}
              >
                AÇÕES EM MASSA
              </button>
            ),
          ]}
        />
        <PersonSearchComponent
          countFilter={countFilter}
          userType={USER_TYPE.CLIENTE}
          inputPessoa1Value={inputCliente}
          handlePessoa1Change={handleInputClienteChange}
          inputPessoa2Value={inputRevenda}
          handlePessoa2Change={handleInputRevendaChange}
          handleSubmit={handleSubmit}
          codeValue={inputCode}
          handleCodeChange={handleInputCodeChange}
          statusValue={inputStatus}
          handleStatusChange={handleStatusChange}
          statusChaveValue={inputStatusChave}
          userCanViewColumnRevenda={userCanViewColumnRevenda}
          noFieldStatus={false}
          noFieldStatusChave={false}
          handleStatusChaveChange={handleStatusChaveChange}
        />
        <TableComponent
          headerLabels={[
            ...handleLabels(),
            {
              text: 'Ação',
              align: 'center',
              width: userCanBlock ? '170px' : '150px',
            },
          ]}
          renderRowComponent={renderRowComponent}
          dataObjects={searchResponse.map(item => {
            item.formatedCpfCnpj = formatCpfCnpj(item.cpfCnpj);
            item.telefone1 = item.contato.telefone1;
            return item;
          })}
          fieldsArray={['id', 'nomeFantasia', 'formatedCpfCnpj']}
          handlePageUpdate={findAllByFilter}
          totalItems={totalItems}
          useCustomActions
        />
      </S.Container>
    </>
  );
}

PersonClient.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default PersonClient;
