import styled from 'styled-components';

export const TableModuleActives = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;

  #modules-view-web {
    display: flex;
  }

  #modules-view-mobile {
    display: none;
  }

  @media screen and (max-width: 896px) {
    height: auto;

    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 80vh;

    &::-webkit-scrollbar {
      width: 12px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: white; /* color of the tracking area */
    }

    &::-webkit-scrollbar-thumb {
      background-color: #616161e5; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
      border: 3px solid white; /* creates padding around scroll thumb */
    }

    #modules-view-web {
      display: none;
    }

    #modules-view-mobile {
      display: flex;
    }
  }
`;

export const ContainerData = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 20px;
  padding: 16px;
  border: 1px solid var(--cm-primary);
  border-radius: 8px;
  color: var(--cm-primary);

  hr {
    width: 1px;
    opacity: 0.5;
    margin-right: 20px;
  }

  @media screen and (max-width: 896px) {
    flex-direction: column;

    hr {
      height: 1px;
      width: 100%;
      margin: 20px 0;
    }
  }
`;

export const ConrtainerDataRow = styled.div`
  width: calc(50% - 0.5px);

  .title {
    margin-bottom: 4px;
  }

  @media screen and (max-width: 896px) {
    width: 100%;
  }
`;

export const TablesModules = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
`;
