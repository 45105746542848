import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { toast } from 'react-toastify';
import moment from 'moment';
import Modal from '~/components/Modal';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import PersonEmployeeService from '~/services/person-employee-service';
import { CommercialOrderService } from '~/services/commercial-order-service';
import { commercialPaths } from '~/routes/paths';
import { STATUS_ORDER_ENUM } from '~/util/domainutils';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import Collapsible from './Collapsible';
import * as G from './Grids';
import * as S from './styles';
import './styles.scss';

function OrderManagement({ history, location }) {
  const componentRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [forms, setForms] = useState([]);
  const [status, setStatus] = useState(null);
  const [observacao, setObservacao] = useState('');

  const user = useSelector(state => state.user.data);
  const userPermissions = user.permissions;
  const revenda = user.revenda;

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions.edit_pedido),
    [userPermissions]
  );

  const userCanChangeStatus = useMemo(
    () => userPermissions.includes(permissions.status_pedido),
    [userPermissions]
  );

  const userCanViewButtonEdit = useMemo(
    () => userPermissions.includes(permissions['view_botao-editar']),
    [userPermissions]
  );

  const loadOrder = async () => {
    setLoading(true);

    const { id } = location.state;

    try {
      const responseOrder = await CommercialOrderService.getOrder(id);

      let orderData = { ...responseOrder, responsavelExecutivo: '' };

      const { revendaId, responsavelExecutivoId } = responseOrder.revenda;

      if (responsavelExecutivoId) {
        const {
          resultado: [employee]
        } = await PersonEmployeeService.getPersonEmployees({
          id: responsavelExecutivoId, revendaId,
        });

        orderData = {
          ...orderData,
          responsavelExecutivo: employee ? employee.nomeSocial : '',
        };
      }

      setStatus(orderData.statusAtual.status);
      setForms(responseOrder.forms);
      setOrder(orderData);
    } catch (error) {
      showMessageError(error);
      setTimeout(() => {
        history.push(commercialPaths.commercialOrder);
      }, 100); // 0.1s
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location.state) {
      history.push(commercialPaths.commercialOrder);
    } else {
      if (!location.state.id) {
        toast('Cliente não existe na base de dados', { type: toast.TYPE.ERROR });
        setTimeout(() =>
          history.push(commercialPaths.commercialOrder), 100
        ); // 0.1s
      }

      loadOrder();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const canViewButtonEdit = () => {
    return userCanViewButtonEdit && (order && order.revendaId === revenda.id);
  };

  async function validadeOrder(success) {
    setLoading(true);
    try {
      const orderAntigo = order;
      const orderAtual = await CommercialOrderService
        .getOrder(location.state.id);

      setLoading(false);

      if (orderAtual.statusPedidos.length !== orderAntigo.statusPedidos.length) {
        await loadOrder();
        toast(
          'A revenda alterou o pedido neste momento!'
          + '\n A página será recarregada!',
          { type: toast.TYPE.ERROR }
        );
        return;
      }

      success();
    } catch (error) {
      showMessageError(error);
      setLoading(false);
      return;
    }
  }

  async function validateFields(success) {
    const isSameStatus = order.statusAtual.status === status;

    if (isSameStatus && (!observacao || !observacao.length)) {
      toast('Adicione uma observação', { type: toast.TYPE.ERROR });
      return;
    }

    await success();
  }

  async function handleSave() {
    await validadeOrder(async () => {
      await validateFields(async () => {
        setLoading(true);
        try {
          const responseUpdate = await CommercialOrderService
            .updateStatusOrder(order.id, status, observacao);

          if (responseUpdate.ok) {
            toast.success('Status atualizado com sucesso!');

            if (responseUpdate.error.messages.length) {
              responseUpdate.error.messages.forEach((error, index) => {
                setTimeout(() => {
                  toast(error.message, { type: toast.TYPE.ERROR });
                }, 500 * (index + 1)); // 0.5s e 1s
              });
            }

            setObservacao('');
            await loadOrder();
          }
        } catch (error) {
          showMessageError(error);
        } finally {
          setLoading(false);
        }
      });
    });
  }

  async function validateCanEditOrder(success) {
    if (!order.cliente) {
      toast.error('Não é possível editar este pedido, cliente não foi'
        + ' encontrado na base de dados');
      return;
    }

    try {
      setLoading(true);
      const currentStatusOrder = await CommercialOrderService
        .isVerifyStatusOrder(order.id);

      if (!currentStatusOrder) {
        toast.error('Erro ao verificar status atual do pedido');
        setLoading(false);
        return;
      }

      if (!currentStatusOrder.ok) {
        toast(currentStatusOrder.message, { type: toast.TYPE.ERROR });
        await loadOrder();
        setLoading(false);
        return;
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }

    success();
  }

  async function handleEditCommercialOrder() {
    await validateCanEditOrder(() => {
      return history.push(
        commercialPaths.editCommercialOrder,
        {
          draftOrder: order.rascunho,
          isDraft: !!order.rascunho,
          idClient: order.cliente.id,
          idOrder: order.id,
        }
      );
    });
  }

  const IS_STATUS_CAN_EDIT = () => {
    const status = [
      STATUS_ORDER_ENUM.PROPOSTA.value,
      STATUS_ORDER_ENUM.PENDENTE_REVENDA.value
    ];

    const [statusAtual] = ((order && order.statusPedidos) || [])
      .sort((statusA, statusB) => {
        return moment(statusA.data).unix() - moment(statusB.data).unix();
      }).slice(-1);

    if (!statusAtual) return false;

    return status.includes(statusAtual.status);
  };

  return (
    <S.Container>
      <SidebarPageHeader
        mainMenu="Comercial"
        pageName="Monitorar Pedido"
        breadcrumbs={[{
          label: 'Pedido',
          link: commercialPaths.commercialOrder,
        }]}
        customButtonsBeforeReport={[
          <button key='button_order_back'
            type="button"
            className="buttonBase baseWebMobile addMargin"
            onClick={() => history.push(commercialPaths.commercialOrder)}
          >
            VOLTAR
          </button>,
          <ReactToPrint
            key='button_order_print'
            trigger={() => (
              <div className={
                `buttonBase baseWebMobile addMargin ${!canViewButtonEdit() ? 'baseMain' : ''}`
              }>
                IMPRIMIR
              </div>
            )}
            content={() => componentRef.current}
          />,
          canViewButtonEdit() && (
            IS_STATUS_CAN_EDIT() && (
              <button key='button_order_complete'
                type="button"
                title={'Editar Pedido'}
                className={'buttonBase baseWebMobile addMargin baseMain'}
                onClick={handleEditCommercialOrder}
              >
                EDITAR
              </button>
            )
          ),
        ]}
        openButtonSelect
      />
      <Modal show={loading} loading />
      {order && (
        <div className="orderManagementContainer" ref={componentRef}>
          <Collapsible
            renderHeaderLeftComponent={() => (
              <span className="collapsibleTitle">Dados do Pedido</span>
            )}
            renderContent={() => <G.OrderGrid order={order} />}
          />
          <Collapsible
            renderHeaderLeftComponent={() => (
              <span className="collapsibleTitle">Dados do Solicitante</span>
            )}
            renderContent={() => <G.CreatorGrid creator={order?.criadoPor} />}
          />
          <Collapsible
            renderHeaderLeftComponent={() => <span>Dados do Cliente</span>}
            renderContent={() => <G.ClientGrid client={order.cliente} />}
          />
          <Collapsible
            renderHeaderLeftComponent={() => <span>Dados da Revenda</span>}
            renderContent={() => <G.ResaleGrid
              resale={order.revenda}
              responsavelExecutivo={order.responsavelExecutivo}
            />}
          />
          <Collapsible
            renderHeaderLeftComponent={() => (
              <span>Dados do Faturamento</span>
            )}
            renderContent={() => (
              <G.AdhesionGrid
                adhesion={{
                  formaPagamento: order.formaPagamento,
                  planoPagamento: order.planoPagamento,
                }}
                modality={order.itemPedidos}
              />
            )}
          />
          <Collapsible
            renderHeaderLeftComponent={() => <span>Dados da Manutenção</span>}
            renderContent={() => <G.MaintenanceGrid maintenance={order} />}
          />
          <Collapsible
            isItems={true}
            renderHeaderLeftComponent={() => (
              <span>Dados dos Itens do Pedido</span>
            )}
            renderContent={() => (
              <G.OrderItemsGrid orderItems={order.itemPedidos} />
            )}
          />
          <Collapsible
            isItems={true}
            renderHeaderLeftComponent={() => <span>Anexos</span>}
            renderContent={() => (
              <G.AttachmentsGrid
                attachments={order.pedidoAnexos}
                setLoading={setLoading}
              />
            )}
          />
          <Collapsible
            renderHeaderLeftComponent={() => <span>Formulários</span>}
            renderContent={() => (
              <G.FormsGrid forms={forms} history={history} />
            )}
          />
          <Collapsible
            isItems={true}
            renderHeaderLeftComponent={() => <span>Ocorrências</span>}
            renderContent={() => (
              <G.OccurrenceGrid
                statusPedidos={order.statusPedidos}
                criadoPor={order.criadoPor}
              />
            )}
          />
          {userCanChangeStatus && (
            <div className="updateStatusContainer">
              <span className="updateStatusHeader">Atualizar Status</span>
              <div className="updateStatusContent">
                <div className="updateStatusOptions">
                  <G.StatusesGrid
                    disabled={!userCanEdit}
                    onStatusChange={({ target }) => setStatus(target.value)}
                    status={status}
                  />
                </div>
                <div className="obsHolder">
                  <span>Observação</span>
                  <textarea
                    name="texto"
                    value={observacao}
                    rows={3}
                    onChange={({ target }) => setObservacao(target.value)}
                  />
                </div>
                <div className="updateHolder">
                  <button className="buttonBase" onClick={handleSave}>
                    <span>ATUALIZAR</span>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </S.Container>
  );
}

OrderManagement.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      id: PropTypes.number,
    }).isRequired,
  }).isRequired,
};

export default OrderManagement;
