/* eslint-disable react/prop-types */
import React from 'react';

import { Container } from '../styles';
import { formatCpfCnpj } from '~/util/stringutils';

export default function Resale({ resale }) {
  return (
    <Container>
      <div>
        <span>Nome Fantasia:</span>
        <span>{resale.nomeFantasia}</span>
      </div>
      <div>
        <span>CNPJ:</span>
        <span>{formatCpfCnpj(resale.cpfCnpj)}</span>
      </div>
    </Container>
  );
}
