import PropTypes from 'prop-types';
import React from 'react';

function Radio(props) {
  const {
    name,
    labelHorizontal,
    customContentHorizontal,
    value,
    onChange,
    onClick,
    outlineSelect,
    customStyles,
    ...rest
  } = props;

  return (
    <div className="radioHolder" style={customStyles}>
      <div className="inputBoxHolder">
        <input
          type="radio"
          name={name}
          value={value}
          onChange={onChange}
          onClick={onClick}
          {...rest}
        />
        <span className={`checkradio ${(outlineSelect ? 'checkradio_outline' : '')}`}>
          {outlineSelect && (
            <div className="markRadioHolder">
              <div className="checkradio_stem" />
            </div>
          )}
        </span>
      </div>
      {customContentHorizontal && customContentHorizontal}
      {labelHorizontal && <span id="radioLabel">{labelHorizontal}</span>}
    </div>
  );
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  labelHorizontal: PropTypes.string,
  customContentHorizontal: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]),
  outlineSelect: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  customStyles: PropTypes.object,
};

export { Radio };
