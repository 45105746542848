import api, { endpoints } from '~/services/api';

class SolicitationService {
  static createSolicitation = async (formData) => {
    const { data: response } = await api.post(
      endpoints.commercial.solicitation.createCommercialSolicitation,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );

    return response;
  }

  static getSolicitations = async (formDataParams) => {
    const { data: response } = await api.post(
      endpoints.commercial.solicitation.getCommercialSolicitations,
      { ...formDataParams }
    );

    return response;
  }

  static getFilterSolicitations = async ({ formDataParams, limit, offset }) => {
    const { data: response } = await api.post(
      endpoints.commercial.solicitation.getCommercialSolicitations,
      {
        ...formDataParams,
        limit,
        offset,
      }
    );
    return response;
  }

  static updateExtraSolicitation = async (id, extra, tipoMotivoId) => {
    const { data: response } = await api.put(
      endpoints.commercial.solicitation.updateExtraCommercialSolicitation,
      { id, extra, tipoMotivoId },
    );

    return response;
  }

  static updateSolicitationStatus = async (formData) => {
    const { data: response } = await api.put(
      endpoints.commercial.solicitation.updateCommercialSolicitationStatus,
      formData
    );

    return response;
  }

  static createSolicitationMenssage = async (formData) => {
    const { data: response } = await api.post(
      endpoints.commercial.solicitation.createCommercialSolicitationMenssage,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );

    return response;
  }
}

export { SolicitationService };
