/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { formPaths, commercialPaths } from '~/routes/paths';
import FormViewer from '~/components/FormViewer';
import FormDownload from '~/components/FormDownload';
import moment from 'moment';
import './styles.scss';

function Forms({ forms, history }) {
  const formRef = useRef([]);

  const handleRef = (ref, index) => {
    formRef.current[index] = ref;
    return ref;
  };

  function onHandleViewer(formObject) {
    return history.push(formPaths.formAnswerViewerFilled, {
      pathReferenceToBack: commercialPaths.managementCommercialOrder,
      forms,
      idAnswer: formObject.id,
      idForm: formObject.formId,
      idClient: formObject.clientId,
      idResale: formObject.resellerId,
      userCreateOrderId: formObject.userCreateOrderId,
      itemAnswers: formObject.itemAnswers,
      actionLogs: formObject.actionLogs,
      responsibleName: formObject.responsibleName,
      formStatus: formObject.formStatus,
      extraInfo: formObject.extraInfo,
      finishedAt: formObject.finishedAt,
      requestedIn: moment(formObject.createdAt).format('DD/MM/YYYY'),
      idOrder: formObject.orderId,
      canDisabledAllInputs: true,
    });
  }

  return (
    <>
      {forms && forms.map((form, index) => (
        <div key={`${form.id}-print`} style={{ display: 'none' }}>
          <FormDownload
            ref={(ref) => handleRef(ref, index)}
            idAnswer={form.id}
            idForm={form.formId}
            idOrder={form.orderId}
            idClient={form.clientId}
            idResale={form.userCreateOrderId}
            itemAnswers={form.itemAnswers}
            extraInfo={form.extraInfo}
            requestedIn={moment(form.createdAt).format('DD/MM/YYYY')}
          />
        </div>
      ))}
      <div id='containerForms'>
        {forms && forms.map((formObject, index) => (
          <div key={formObject.id}>
            <FormViewer
              showDownload
              formName={formObject.form.name}
              handleDownload={formRef.current[index]}
              handleViewer={() => onHandleViewer(formObject)}
              withWidth={210}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default Forms;
