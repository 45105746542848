/* eslint-disable react/prop-types */
import React from 'react';

import { Container } from '../styles';
import { formatCpfCnpj, formatPhone } from '~/util/stringutils';

export default function Client({ client }) {
  return (
    <Container>
      <div>
        <span>Nome Fantasia:</span>
        <span>{client.nomeFantasia || ''}</span>
      </div>
      <div>
        <span>CPF/CNPJ:</span>
        <span>{formatCpfCnpj(client.cpfCnpj) || ''}</span>
      </div>
      <div>
        <span>Responsável:</span>
        <span>{client.responsavelNome || ''}</span>
      </div>
      <div>
        <span>Telefone 1:</span>
        <span>{formatPhone(client.responsavelTelefone1) || ''}</span>
      </div>
      <div>
        <span>Email:</span>
        <span>{client.responsavelEmail || ''}</span>
      </div>
      <div>
        <span>Telefone 2:</span>
        <span>{formatPhone(client.responsavelTelefone2) || ''}</span>
      </div>
    </Container>
  );
}
