import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 100%;

  div {
    display: flex;

    textarea {
      resize: none;
    }

    span {
      font-size: 17px;
      color: #707070;

      :first-child {
        margin-right: 10px;
      }

      @media (max-width: 700px) {
        font-size: 14px;

        :first-child {
          margin-right: 5px;
        }
      }
    }
  }
`;

export const ObsContainer = styled.div`
  display: flex;
  flex-direction: column;

  div {
    margin: 10px 20px;
  }
`;
