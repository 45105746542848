/* eslint-disable no-undef */
import { useDispatch } from 'react-redux';
import { signInRequest } from '~/store/modules/auth/actions';
import { unFormatCpfCnpj } from '~/util/stringutils';

export function useReCaptcha() {
  const enabled = String(process.env.REACT_APP_RECAPTCHA_ENABLED) === 'true';
  const sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

  const dispatch = useDispatch();

  return {
    enabled,
    handleLoginReCaptcha: async (input) => {
      if (!enabled) {
        return;
      }

      window.grecaptcha.enterprise.ready(async () => {
        const { login, password } = input;
        const reCaptchaToken = await window.grecaptcha.enterprise.execute(sitekey, { action: 'login' });

        dispatch(signInRequest(unFormatCpfCnpj(login), password, reCaptchaToken));
      });
    },
    handleLoadReCaptcha: () => {
      if (!enabled) {
        return;
      }

      const script = document.createElement('script');

      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${sitekey}`;
      script.id = 'recaptcha-script';
      script.async = true;

      document.head.append(script);
    },
    handleRemoveReCaptcha: () => {
      if (!enabled) {
        return;
      }

      const script = document.getElementById('recaptcha-script');

      if (script) {
        script.remove();
      }

      const recaptchaelems = document.getElementsByClassName('grecaptcha-badge');

      if (recaptchaelems.length) {
        recaptchaelems[0].remove();
      }
    },
  };
}
