/* eslint-disable react/prop-types */
import React from 'react';
import { STATUS_ORDER_ENUM } from '~/util/domainutils';
import './styles.scss';

const status = [];
Object.keys(STATUS_ORDER_ENUM).forEach((key) => {
  if (STATUS_ORDER_ENUM[key].showInManagement) {
    status.push(STATUS_ORDER_ENUM[key].label);
  }
});

export default function Statuses({
  onStatusChange,
  status: statusSelected,
  disabled,
}) {
  return (
    <div className='updateStatusOptions'>
      {status.map((item) => (
        <div key={`ocurrences_status_${item}`} className='radioHolder'>
          <div className='inputBoxHolder'>
            <input
              disabled={disabled}
              type="radio"
              name="status"
              value={item}
              checked={statusSelected === item}
              onChange={onStatusChange}
            />
            <span className="checkmark">
              {(statusSelected === item) && (
                <div className='markHolder'>
                  <div className="checkmark_stem" />
                  <div className="checkmark_kick" />
                </div>
              )}
            </span>
          </div>
          <span id='radioLabel'>{item}</span>
        </div>
      ))}
    </div>
  );
}
