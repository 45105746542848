import React, { useState, useEffect } from 'react';
// import { orderBy } from 'lodash';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { AdminSettingsService } from '~/services/admin-settings-service';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { FieldCpfCnpj } from '~/components/FieldCpfCnpj';
import { SETTINGS_ENUM } from '~/util/domainutils';
// import { SketchPicker } from 'react-color';
import { showMessageError } from '~/util/errorutils';
import {
  ModalContainer,
  ModalContentButtons,
  ModalContent,
  ContainerRow,
  // AddIcon,
  // DeleteIcon,
  // ContainerButton,
  // ContainerColors,
  // ContentColors,
  // ContentColor,
  // ContainerDelete,
  // CircleColor,
  // Color,
  // Popover,
  // Cover,
} from './styles';
import './styles.scss';
import { onlyNumbers, unFormatCpfCnpj, inputVersion } from '~/util/stringutils';
import { Radio } from '~/components/Radio';

function Settings() {
  const [openModalDialogBeforeSaving, setOpenModalDialogBeforeSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previousLink, setPreviousLink] = useState('');

  const [inputLink, setInputLink] = useState('');
  const [messageCurrent, setMessageCurrent] = useState('');
  const [messageChaveTest, setMessageChaveTest] = useState('');
  const [messageChaveAtivada, setMessageChaveAtivada] = useState('');
  const [messageChaveDesativada, setMessageChaveDesativada] = useState('');
  const [inputPrazo, setInputPrazo] = useState('');
  const [inputEnabledKey, setInputEnabledKey] = useState('');
  const [inputEnabledUnderMaintenance, setInputEnabledUnderMaintenance] = useState('');
  const [inputKey, setInputKey] = useState('');
  const [inputCpfCnpj, setInputCpfCnpj] = useState('');
  const [inputCodeSoftware, setInputCodeSoftware] = useState('');
  const [inputSerie, setInputSerie] = useState('');
  const [inputControle, setInputControle] = useState('');
  const [inputVersao, setInputVersao] = useState('');
  const [inputLogYears, setInputLogYears] = useState('');
  const [inputLogMonths, setInputLogMonths] = useState('');
  const [inputLogDays, setInputLogDays] = useState('');
  // const [inputFutureGoal, setInputFutureGoal] = useState([]);
  // const [inputsColors, setInputsColors] = useState([]);

  const [formData, setFormData] = useState({
    manutencaoPortal: '',
    marketing: {
      link: '',
    },
    license: {
      prazo: '',
      ativarChave: '',
      gerarCom: '',
      cpfCnpj: '',
      codeSoftware: '',
      serie: '',
      controle: '',
      versao: '',
    },
    mensagens: {
      mensagemAtual: '',
      mensagemChaveAtivada: '',
      mensagemChaveDesativada: '',
      mensagemChaveTeste: '',
    },
    logs: {
      logRotateAno: '',
      logRotateMes: '',
      logRotateDia: '',
    },
  });

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      try {
        const [link, settings] = await AdminSettingsService.getSettingsService();

        if (link && settings.length) {
          handleInitialData(link.serviceUrl, settings);
        } else {
          handleClearInputs();
          toast('Erro ao consultar configurações.', {
            type: toast.TYPE.ERROR,
          });
        }
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInitialData(link, settings) {
    setInputLink(link);
    setPreviousLink(link);
    settings.forEach(setting => {
      switch (setting.code) {
        case SETTINGS_ENUM.GET_ATIVAR_CHAVE:
          setInputEnabledKey(setting.value);
          break;
        case SETTINGS_ENUM.GET_GERAR_COM:
          setInputKey(setting.value);
          break;
        case SETTINGS_ENUM.GET_PRAZO_MAXIMO_GERAR_CHAVE:
          setInputPrazo(setting.value);
          break;
        case SETTINGS_ENUM.GET_TEST_CPF_CNPJ:
          setInputCpfCnpj(setting.value);
          break;
        case SETTINGS_ENUM.GET_TEST_CODE_SOFTWARE:
          setInputCodeSoftware(setting.value);
          break;
        case SETTINGS_ENUM.GET_TEST_SERIE:
          setInputSerie(setting.value);
          break;
        case SETTINGS_ENUM.GET_TEST_CONTROLE:
          setInputControle(setting.value);
          break;
        case SETTINGS_ENUM.GET_TEST_VERSAO:
          setInputVersao(setting.value);
          break;
        case SETTINGS_ENUM.GET_MESSAGE_ATUAL:
          setMessageCurrent(setting.value);
          break;
        case SETTINGS_ENUM.GET_MESSAGE_CHAVE_TEST:
          setMessageChaveTest(setting.value);
          break;
        case SETTINGS_ENUM.GET_MESSAGE_CHAVE_ATIVADA:
          setMessageChaveAtivada(setting.value);
          break;
        case SETTINGS_ENUM.GET_MESSAGE_CHAVE_DESATIVADA:
          setMessageChaveDesativada(setting.value);
          break;
        case SETTINGS_ENUM.GET_LOG_ROTATE_ANO:
          setInputLogYears(setting.value);
          break;
        case SETTINGS_ENUM.GET_LOG_ROTATE_MES:
          setInputLogMonths(setting.value);
          break;
        case SETTINGS_ENUM.GET_LOG_ROTATE_DIA:
          setInputLogDays(setting.value);
          break;
        // case SETTINGS_ENUM.GET_CORES_METAS:
        //   handleInitialColors(setting.value);
        //   break;
        case SETTINGS_ENUM.GET_ATIVAR_MANUTENCAO_PORTAL:
          setInputEnabledUnderMaintenance(setting.value);
          break;

        default:
          break;
      }
    });
  }

  // function handleInitialColors(JSONValue) {
  //   const colors = JSON.parse(JSONValue);

  //   const futureGoalDataInitial = {
  //     ...colors.futureGoal[0],
  //     displayColorPicker: false,
  //   };

  //   setInputFutureGoal([futureGoalDataInitial]);

  //   const otherGoalDataInitial = colors.otherGoal.map((input) => {
  //     return {
  //       ...input,
  //       displayColorPicker: false,
  //     };
  //   });

  //   orderedOtherGoal(otherGoalDataInitial);
  // }

  // function orderedOtherGoal(otherGoalDataInitial) {
  //   const orderedOtherGoal = orderBy(
  //     otherGoalDataInitial, (goal) => Number(goal.percentage.value), ['asc'],
  //   );

  //   setInputsColors(orderedOtherGoal);
  // }

  function handleClearInputs() {
    setInputLink('');
    setPreviousLink('');
    setInputEnabledKey('');
    setInputKey('');
    setInputPrazo('');
    setInputCpfCnpj('');
    setInputCodeSoftware('');
    setInputSerie('');
    setInputControle('');
    setInputVersao('');
    setMessageCurrent('');
    setMessageChaveTest('');
    setMessageChaveAtivada('');
    setMessageChaveDesativada('');
    setInputLogYears('');
    setInputLogMonths('');
    setInputLogDays('');
  }

  function handleMenssageFeedbackAtivadaChange(event) {
    const {
      target: { value }
    } = event;

    setMessageChaveAtivada(value);
  }

  function handleMenssageFeedbackDesativadaChange(event) {
    const {
      target: { value }
    } = event;

    setMessageChaveDesativada(value);
  }

  function handleMenssageFeedbackTestChange(event) {
    const {
      target: { value }
    } = event;

    setMessageChaveTest(value);
  }

  function handleMenssageFeedbackAtualChange(key, enabedKey) {
    const chaveDesativada = key === 'no' && (
      enabedKey === 'real_license' || enabedKey === 'test_license'
    );

    if (chaveDesativada) {
      return setMessageCurrent(SETTINGS_ENUM.GET_MESSAGE_CHAVE_DESATIVADA);
    }

    const chaveAtivada = key === 'yes' && enabedKey === 'real_license';

    if (chaveAtivada) {
      return setMessageCurrent(SETTINGS_ENUM.GET_MESSAGE_CHAVE_ATIVADA);
    }

    const chaveTest = key === 'yes' && enabedKey === 'test_license';

    if (chaveTest) {
      return setMessageCurrent(SETTINGS_ENUM.GET_MESSAGE_CHAVE_TEST);
    }
  }

  function handleInputLinkChange(event) {
    const {
      target: { value },
    } = event;

    setInputLink(value);
  }

  function handleInputPrazoChange(event) {
    const {
      target: { value },
    } = event;

    setInputPrazo(onlyNumbers(value));
  }

  function handleInputEnabledKeyChange(event) {
    const {
      target: { value },
    } = event;

    setInputEnabledKey(value);
    handleMenssageFeedbackAtualChange(value, inputKey);
  }

  function handleInputEnabledUnderMaintenanceChange(event) {
    const {
      target: { value },
    } = event;

    setInputEnabledUnderMaintenance(value);
  }

  function handleInputKeyChange(event) {
    const {
      target: { value },
    } = event;

    setInputKey(value);
    handleMenssageFeedbackAtualChange(inputEnabledKey, value);
  }

  function handleInputCpfCnpjChange(event) {
    const {
      target: { value },
    } = event;

    setInputCpfCnpj(value);
  }

  function handleInputCodeSoftwareChange(event) {
    const {
      target: { value },
    } = event;

    setInputCodeSoftware(value);
  }

  function handleInputSerieChange(event) {
    const {
      target: { value },
    } = event;

    setInputSerie(value);
  }

  function handleInputControleChange(event) {
    const {
      target: { value },
    } = event;

    setInputControle(value);
  }

  function handleInputVersaoChange(event) {
    const {
      target: { value },
    } = event;

    setInputVersao(value);
  }

  function handleInputYearsChange(event) {
    const {
      target: { value }
    } = event;

    setInputLogYears(value);
  }

  function handleInputMonthsChange(event) {
    const {
      target: { value }
    } = event;

    setInputLogMonths(value);
  }

  function handleInputDaysChange(event) {
    const {
      target: { value }
    } = event;

    setInputLogDays(value);
  }

  // function handleInputFutureGoalChange(event) {
  //   const {
  //     target: { value }
  //   } = event;

  //   let changeValueColorName = inputFutureGoal[0];
  //   changeValueColorName['colorName'].value = value;

  //   setInputFutureGoal([changeValueColorName]);
  // }

  // function handleInputColorNameChange(event, index) {
  //   const {
  //     target: { value }
  //   } = event;

  //   const changeInputsValue = inputsColors;

  //   let changeValueColorName = changeInputsValue[index];
  //   changeValueColorName['colorName'].value = value;

  //   changeInputsValue[index] = changeValueColorName;

  //   setInputsColors([...changeInputsValue]);
  // }

  // function handleFutureGoalOpenColorPicker(value) {
  //   const changeInputsValue = inputFutureGoal[0];

  //   let changeValuePercentage = changeInputsValue;
  //   changeValuePercentage.displayColorPicker = value;

  //   setInputFutureGoal([changeValuePercentage]);
  // }

  // const handleFutureGoalClick = () => {
  //   handleFutureGoalOpenColorPicker(true);
  //   handleMoveEndColor();
  // };

  // const handleFutureGoalClose = () => {
  //   handleFutureGoalOpenColorPicker(false);
  // };

  // const handleFutureGoalChange = (color) => {
  //   let changeInputsValue = [...inputFutureGoal][0];
  //   changeInputsValue.color = color.rgb;

  //   setInputFutureGoal([changeInputsValue]);
  // };

  // function handleInputPercentageChange(event, index) {
  //   const {
  //     target: { value }
  //   } = event;

  //   const changeInputsValue = inputsColors;

  //   let changeValuePercentage = changeInputsValue[index];
  //   changeValuePercentage['percentage'].value = onlyNumbers(value);

  //   changeInputsValue[index] = changeValuePercentage;

  //   setInputsColors([...changeInputsValue]);
  // }

  // function handleOpenColorPicker(value, index) {
  //   const changeInputsValue = inputsColors;

  //   let changeValuePercentage = changeInputsValue[index];
  //   changeValuePercentage.displayColorPicker = value;

  //   changeInputsValue[index] = changeValuePercentage;

  //   setInputsColors([...changeInputsValue]);
  // }

  // const handleInputsClick = (index) => {
  //   handleOpenColorPicker(true, index);
  //   handleMoveEndColor();
  // };

  // const handleInputsClose = (index) => {
  //   handleOpenColorPicker(false, index);
  // };

  // const handleInputsChange = (color, index) => {
  //   let changeInputsValue = [...inputsColors];
  //   changeInputsValue[index].color = color.rgb;

  //   setInputsColors([...changeInputsValue]);
  // };

  // function removeColor(indexColor) {
  //   setInputsColors(oldColors => [
  //     ...oldColors.filter((_, index) => (index !== indexColor))
  //   ]);
  // }

  // function addColor() {
  //   const index = inputsColors.length;

  //   const newColor = {
  //     type: 'OTHER_GOAL',
  //     percentage: {
  //       name: `percentage-${index}`,
  //       value: '',
  //     },
  //     colorName: {
  //       name: `color-name-${index}`,
  //       value: '',
  //     },
  //     displayColorPicker: false,
  //     color: {
  //       r: '179',
  //       g: '179',
  //       b: '179',
  //       a: '0.83',
  //     },
  //     error: false
  //   };

  //   setInputsColors(oldColors => [...oldColors, newColor]);

  //   handleMoveEndColor();
  // }

  async function findAllByFilter(dataParams) {
    setOpenModalDialogBeforeSaving(false);
    setLoading(true);

    try {
      const settingsParams = {
        manutencaoPortal: (dataParams.manutencaoPortal || formData.manutencaoPortal),
        ...(dataParams.license || formData.license),
        ...(dataParams.mensagens || formData.mensagens),
        ...(dataParams.logs || formData.logs),
        // colors: (dataParams.colors || formData.colors),
      };

      const [link, settings] = await AdminSettingsService.updateSettingsService(
        { ...(dataParams.marketing || formData.marketing) },
        { ...settingsParams }
      );
      if (link.ok && settings.ok) {
        toast('Configurações atualizadas com sucesso.', { type: toast.TYPE.SUCCESS });

        // orderedOtherGoal(inputsColors);
      } else {
        toast('Não foi possível atualizar as configurações.', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function validateForm(success) {
    const data = {
      manutencaoPortal: '',
      marketing: {},
      license: {},
      mensagens: {},
      logs: {},
      colors: '',
    };

    if (inputLink.length) {
      data.marketing.link = inputLink.trim();
    }

    if (inputPrazo.length) {
      data.license.prazo = inputPrazo.trim();
    }

    if (inputEnabledKey.length) {
      data.license.ativarChave = inputEnabledKey.trim();
    }

    if (inputKey.length) {
      data.license.gerarCom = inputKey.trim();
    }

    if (inputCpfCnpj.length) {
      data.license.cpfCnpj = unFormatCpfCnpj(inputCpfCnpj.trim());
    }

    if (inputCodeSoftware.length) {
      data.license.codeSoftware = inputCodeSoftware.trim();
    }

    if (inputSerie.length) {
      data.license.serie = inputSerie.trim();
    }

    if (inputControle.length) {
      data.license.controle = inputControle.trim();
    }

    if (inputVersao.length) {
      data.license.versao = inputVersao.trim();
    }

    if (messageCurrent.length) {
      data.mensagens.mensagemAtual = messageCurrent.trim();
    }

    if (messageChaveAtivada.length) {
      data.mensagens.mensagemChaveAtivada = messageChaveAtivada.trim();
    }

    if (messageChaveDesativada.length) {
      data.mensagens.mensagemChaveDesativada = messageChaveDesativada.trim();
    }

    if (messageChaveTest.length) {
      data.mensagens.mensagemChaveTeste = messageChaveTest.trim();
    }

    if (inputLogYears.length) {
      data.logs.logRotateAno = inputLogYears.trim();
    }

    if (inputLogMonths.length) {
      data.logs.logRotateMes = inputLogMonths.trim();
    }

    if (inputLogDays.length) {
      data.logs.logRotateDia = inputLogDays.trim();
    }

    if (inputLogDays.length) {
      data.logs.logRotateDia = inputLogDays.trim();
    }

    if (inputEnabledUnderMaintenance.length) {
      data.manutencaoPortal = inputEnabledUnderMaintenance.trim();
    }

    // if (inputsColors.length) {
    //   const percentageError = inputsColors.map((input) => {
    //     return input.percentage.value !== '';
    //   });

    //   if (!percentageError.every((bool) => bool === true)) {
    //     toast.error('Existe cores sem porcentagem associado');
    //     handleMoveEndColor();
    //     return;
    //   }
    // }

    // const DataColor = {
    //   futureGoal: inputFutureGoal.map((input) => {
    //     return {
    //       type: input.type,
    //       colorName: input.colorName,
    //       color: input.color
    //     };
    //   }),
    //   otherGoal: inputsColors.map((input) => {
    //     return {
    //       type: input.type,
    //       percentage: input.percentage,
    //       colorName: input.colorName,
    //       color: input.color
    //     };
    //   })
    // };

    // data.colors = JSON.stringify(DataColor);

    setFormData(data);
    await success(data);
  }

  function handleSubmit(event) {
    event.preventDefault();

    validateForm(async data => {
      await findAllByFilter(data);
    });
  }

  function handleIsLinkChange() {
    return previousLink.trim() !== inputLink.trim();
  }

  function handleButtonSave() {
    return handleIsLinkChange()
      ? () => setOpenModalDialogBeforeSaving(handleIsLinkChange())
      : handleSubmit;
  }

  // function handleMoveEndColor() {
  //   setTimeout(() => {
  //     const element = document.getElementById('endColor');
  //     if (element) element.scrollIntoView({ behavior: 'smooth' });
  //   }, 200);
  // }

  return (
    <div className="defaultFormContainer">
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Administrador"
        pageName="Configurações"
        button1={{
          label: 'Salvar',
          onClick: () => handleButtonSave(),
          main: true,
        }}
        openButtonSelect
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <span id="title">Manutenção do Portal</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Colocar sistema em manutenção? *</span>
              <div className="rowContainer">
                <Radio
                  name="enabledUnderMaintenance"
                  labelHorizontal={'Sim'}
                  value={'yes'}
                  onChange={handleInputEnabledUnderMaintenanceChange}
                  outlineSelect={inputEnabledUnderMaintenance === 'yes'}
                />
                <Radio
                  name="enabledUnderMaintenance"
                  labelHorizontal={'Não'}
                  value={'no'}
                  onChange={handleInputEnabledUnderMaintenanceChange}
                  outlineSelect={inputEnabledUnderMaintenance === 'no'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Dados do Link Marketing</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Link *</span>
              <input
                type="text"
                name="link"
                value={inputLink}
                onChange={handleInputLinkChange}
              />
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">
            <b>Geração de Chave</b>
          </span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Habilitar geração de chave? *</span>
              <div className="rowContainer">
                <Radio
                  name="enabledKey"
                  labelHorizontal={'Sim'}
                  value={'yes'}
                  onChange={handleInputEnabledKeyChange}
                  outlineSelect={inputEnabledKey === 'yes'}
                />
                <Radio
                  name="enabledKey"
                  labelHorizontal={'Não'}
                  value={'no'}
                  onChange={handleInputEnabledKeyChange}
                  outlineSelect={inputEnabledKey === 'no'}
                />
              </div>
            </div>
          </div>
          {inputEnabledKey === 'yes' && (
            <>
              <div className="rowContainer">
                <div className="inputHolder defaultFlex noMarginRight">
                  <span>Prazo máximo para geração de chaves (prazo do Milênio) *</span>
                  <ContainerRow>
                    <input
                      className='inputSmall'
                      type="text"
                      name="prazo"
                      value={onlyNumbers(inputPrazo)}
                      onChange={handleInputPrazoChange}
                    />
                    <span>dias</span>
                  </ContainerRow>
                </div>
              </div>
              <div className="rowContainer">
                <div className="inputHolder defaultFlex noMarginRight">
                  <span>Gerar com *</span>
                  <div>
                    <span id='description'>
                      {'(Escolhendo "licença de teste", o sistema não utilizará os dados da licença do cliente)'}
                    </span>
                  </div>
                  <div className="rowContainer">
                    <Radio
                      name="generateKey"
                      labelHorizontal={'Dados reais da licença'}
                      value={'real_license'}
                      onChange={handleInputKeyChange}
                      outlineSelect={inputKey === 'real_license'}
                    />
                    <Radio
                      name="generateKey"
                      labelHorizontal={'Licença de teste'}
                      value={'test_license'}
                      onChange={handleInputKeyChange}
                      outlineSelect={inputKey === 'test_license'}
                    />
                  </div>
                </div>
                {inputKey === 'test_license' && (
                  <div className="rowContainer">
                    <div className="inputHolder defaultFlex">
                      <span>CPF/CNPJ *</span>
                      <FieldCpfCnpj
                        type="text"
                        value={inputCpfCnpj}
                        onChange={handleInputCpfCnpjChange}
                      />
                    </div>
                    <div className="inputHolder defaultFlex">
                      <span>Código do software *</span>
                      <input
                        type="text"
                        value={onlyNumbers(inputCodeSoftware)}
                        onChange={handleInputCodeSoftwareChange}
                      />
                    </div>
                    <div className="inputHolder defaultFlex">
                      <span>Série *</span>
                      <input
                        type="text"
                        value={onlyNumbers(inputSerie)}
                        onChange={handleInputSerieChange}
                      />
                    </div>
                    <div className="inputHolder defaultFlex">
                      <span>Controle *</span>
                      <input
                        type="text"
                        value={onlyNumbers(inputControle)}
                        onChange={handleInputControleChange}
                      />
                    </div>
                    <div className="inputHolder defaultFlex noMarginRight">
                      <span>Versão *</span>
                      <input
                        type="text"
                        value={inputVersion(inputVersao)}
                        onChange={handleInputVersaoChange}
                      />
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Mensagem de Feedback *</span>
              {messageCurrent === SETTINGS_ENUM.GET_MESSAGE_CHAVE_TEST && (
                <input
                  type="text"
                  name="feedback"
                  value={messageChaveTest}
                  onChange={handleMenssageFeedbackTestChange}
                />
              )}
              {messageCurrent === SETTINGS_ENUM.GET_MESSAGE_CHAVE_ATIVADA && (
                <input
                  type="text"
                  name="feedback"
                  value={messageChaveAtivada}
                  onChange={handleMenssageFeedbackAtivadaChange}
                />
              )}
              {messageCurrent === SETTINGS_ENUM.GET_MESSAGE_CHAVE_DESATIVADA && (
                <input
                  type="text"
                  name="feedback"
                  value={messageChaveDesativada}
                  onChange={handleMenssageFeedbackDesativadaChange}
                />
              )}
            </div>
          </div>
        </div>
        <div className="infoCard">
          <span id="title">Período de retenção de logs *</span>
          <div>
            <span id='description'>
              {'(O log que atinguir o tempo informado será deletado)'}
            </span>
          </div>
          <div className="rowContainer">
            <div className="inputHolder">
              <ContainerRow>
                <input
                  className='inputSmall'
                  type="text"
                  name="yarns"
                  value={onlyNumbers(inputLogYears)}
                  onChange={handleInputYearsChange}
                />
                {parseInt(inputLogYears) <= 1 ? (
                  <span id='description'>ano</span>
                ) : (
                  <span id='description'>anos</span>
                )}
              </ContainerRow>
            </div>
            <div className="inputHolder">
              <ContainerRow>
                <input
                  className='inputSmall'
                  type="text"
                  name="months"
                  value={onlyNumbers(inputLogMonths)}
                  onChange={handleInputMonthsChange}
                />
                {parseInt(inputLogMonths) <= 1 ? (
                  <span id='description'>mês</span>
                ) : (
                  <span id='description'>meses</span>
                )}
              </ContainerRow>
            </div>
            <div className="inputHolder noMarginRight">
              <ContainerRow>
                <input
                  className='inputSmall'
                  type="text"
                  name="days"
                  value={onlyNumbers(inputLogDays)}
                  onChange={handleInputDaysChange}
                />
                {parseInt(inputLogDays) <= 1 ? (
                  <span id='description'>dia</span>
                ) : (
                  <span id='description'>dias</span>
                )}
              </ContainerRow>
            </div>
          </div>
        </div>
        {/* <div className="infoCard">
          <span id="title">Cores para status da meta</span>
          {inputFutureGoal.map((input) => (
            <ContentColors key={input.colorName.name}>
              <ContentColor>
                <span>Cor</span>
                <CircleColor onClick={handleFutureGoalClick}>
                  <Color rgba={input.color} />
                </CircleColor>
                {input.displayColorPicker ? (
                  <Popover>
                    <Cover onClick={handleFutureGoalClose} />
                    <SketchPicker
                      color={input.color}
                      onChange={handleFutureGoalChange}
                    />
                  </Popover>
                ) : null}
              </ContentColor>
              <ContentColor>
                <span>Nome de meta futura</span>
                <input
                  name={input.colorName.name}
                  value={input.colorName.value}
                  onChange={handleInputFutureGoalChange}
                  className='colorName'
                />
              </ContentColor>
            </ContentColors>
          ))}
          <div className='rowContainer noMarginTop'>
            {inputsColors.map((input, index) => (
              <ContainerColors key={index}>
                <ContentColors>
                  <ContentColor>
                    <span>Cor</span>
                    <CircleColor onClick={() => handleInputsClick(index)}>
                      <Color rgba={input.color} />
                    </CircleColor>
                    {input.displayColorPicker ? (
                      <Popover>
                        <Cover onClick={() => handleInputsClose(index)} />
                        <SketchPicker
                          color={input.color}
                          onChange={(color) => handleInputsChange(color, index)}
                        />
                      </Popover>
                    ) : null}
                  </ContentColor>
                  <ContentColor>
                    <span>Porcentagem*</span>
                    <input
                      name={input.percentage.name}
                      value={input.percentage.value}
                      onChange={(e) => handleInputPercentageChange(e, index)}
                      className='percentage'
                    />
                  </ContentColor>
                  <ContentColor>
                    <span>Nome</span>
                    <input
                      name={input.colorName.name}
                      value={input.colorName.value}
                      onChange={(e) => handleInputColorNameChange(e, index)}
                      className='colorName'
                    />
                  </ContentColor>
                  <ContainerDelete>
                    <DeleteIcon onClick={() => removeColor(index)} />
                  </ContainerDelete>
                </ContentColors>
              </ContainerColors>
            ))}
          </div>
          <ContainerButton onClick={addColor}>
            <AddIcon />
            <span>PORCENTAGEM</span>
          </ContainerButton>
        </div> */}
      </form>
      <div id='endColor' />
      <Modal show={openModalDialogBeforeSaving}>
        <ModalContainer>
          <h3>Confirmar alteração de link</h3>
          <ModalContent>
            <b>{inputLink}</b>
            <p>
              Confira o novo link, pois isso pode inviabilizar o acesso.
              Confirmar?
            </p>
          </ModalContent>
          <ModalContentButtons>
            <button
              onClick={() => setOpenModalDialogBeforeSaving(
                !openModalDialogBeforeSaving
              )}
            >
              Não
            </button>
            <button onClick={handleSubmit}>Sim</button>
          </ModalContentButtons>
        </ModalContainer>
      </Modal>
    </div>
  );
}

export default Settings;
