import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import moment from 'moment';
import includes from 'lodash/includes';
import { CALENDARIO_MESES } from '~/util/domainutils';
import { TableShoppingCart } from '../../../Items/TableShoppingCart';
import * as S from './styles';

function ChoosePaymentForm(props) {
  const {
    activeStep,
    softwares,
    items,
    setItems,
    handleAddModule,
    handleRemoveModule,
    mesInicio,
    carenciaAtivada,
    periodoCarencia,
    formasPagamento,
    planosPagamento,
    formaPagamentoId,
    planoPagamentoId,
    setMesInicio,
    setPlanosPagamento,
    setFormaPagamentoId,
    setPlanoPagamentoId,
    userCanEdit,
    handleSubmit,
    calculateTotalAdesao,
    calculateTotalMensalidade,
  } = props;

  useEffect(() => {
    if (formaPagamentoId && planoPagamentoId) {
      const [formaPagamento] = formasPagamento.filter(
        (forma) => forma.id === formaPagamentoId,
      );

      if (formaPagamento) {
        const temp_planosPagamento = formaPagamento.formaPlanoPagamentos
          ? formaPagamento.formaPlanoPagamentos
          : [];

        if (temp_planosPagamento) {
          const [planoPagamento] = formaPagamento.formaPlanoPagamentos.filter(
            (plano) => plano.id === planoPagamentoId,
          );

          if (planoPagamento) {
            handlePlanoPagamento({
              planId: planoPagamentoId,
              planName: planoPagamento.planoPagamento.nome,
            });
          } else {
            handlePlanoPagamento({
              planId: planoPagamentoId,
              planName: '',
            });
          }
        }

        handleFormaPagamento({
          paymentId: formaPagamentoId,
          paymentName: formaPagamento.nome,
          paymentPlan: formaPagamento.formaPlanoPagamentos
            ? formaPagamento.formaPlanoPagamentos
            : []
        });
      } else {
        setFormaPagamentoId(formaPagamentoId);
        setPlanoPagamentoId(0);
        setPlanosPagamento([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFormaPagamento({ paymentId, paymentPlan }) {
    setFormaPagamentoId(paymentId);
    setPlanosPagamento(paymentPlan);
  }

  function handlePlanoPagamento({ planId }) {
    setPlanoPagamentoId(planId);
  }

  function handleSelectMesInicioChange(event) {
    const {
      target: { value },
    } = event;

    setMesInicio(parseInt(value));
  }

  function handleSelectFormaPagamentoChange(event) {
    const {
      target: { value },
    } = event;

    const paymentId = parseInt(value);

    const [formaPagamento] = formasPagamento.filter(
      (forma) => forma.id === paymentId,
    );

    if (formaPagamento) {
      handleFormaPagamento({
        paymentId,
        paymentName: formaPagamento.nome,
        paymentPlan: formaPagamento.formaPlanoPagamentos
          ? formaPagamento.formaPlanoPagamentos
          : []
      });
    } else {
      setFormaPagamentoId(paymentId);
      setPlanoPagamentoId(0);
      setPlanosPagamento([]);
    }
  }

  function handleSelectPlanoPagamentoIdChange(event) {
    const {
      target: { value },
    } = event;

    const planoPagamentoIdValue = parseInt(value);

    const [planoPagamento] = planosPagamento.filter(
      (plano) => plano.id === planoPagamentoIdValue,
    );

    if (planoPagamento) {
      handlePlanoPagamento({
        planId: planoPagamentoIdValue,
        planName: planoPagamento.planoPagamento.nome,
      });
    } else {
      handlePlanoPagamento({
        planId: planoPagamentoIdValue,
        planName: '',
      });
    }
  }

  return (
    <S.OrderChoosePaymentFormContainer onSubmit={handleSubmit}>
      <S.ChoosePaymentFormContent>
        <S.PaymentMethodSelects>
          <span>Escolha o mês de início das mensalidades: *</span>
          <select
            name="mesInicio"
            value={mesInicio}
            disabled={!carenciaAtivada}
            onChange={handleSelectMesInicioChange}
          >
            {CALENDARIO_MESES.filter(item => {
              const tempDate = moment();

              const months = [];
              for (let i = 0; i < periodoCarencia; i += 1) {
                months.push(tempDate.add(1, 'month').month());
              }

              return includes(months, item.value);
            }).map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </S.PaymentMethodSelects>
        <S.PaymentMethodSelects>
          <span>Escolha a forma de pagamento: *</span>
          <select
            name="formaPagamento"
            value={formaPagamentoId}
            onChange={handleSelectFormaPagamentoChange}
          >
            <option value={0}>Selecione</option>
            {(formasPagamento || []).map((item) => (
              <option key={item.id} value={item.id}>
                {item.nome}
              </option>
            ))}
          </select>
        </S.PaymentMethodSelects>
        <S.PaymentMethodSelects>
          <span>Escolha o plano de pagamento: *</span>
          <select
            name="planoPagamento"
            value={planoPagamentoId}
            onChange={handleSelectPlanoPagamentoIdChange}
          >
            <option value={0}>Selecione</option>
            {(planosPagamento || []).map((item) => (
              <option key={item.id} value={item.id}>
                {item.planoPagamento.nome}
              </option>
            ))}
          </select>
        </S.PaymentMethodSelects>
      </S.ChoosePaymentFormContent>
      <S.Divider />
      <TableShoppingCart
        activeStep={activeStep}
        softwares={softwares}
        items={items}
        setItems={setItems}
        handleAddModule={handleAddModule}
        handleRemoveModule={handleRemoveModule}
        userCanEdit={userCanEdit}
        calculateTotalAdesao={calculateTotalAdesao}
        calculateTotalMensalidade={calculateTotalMensalidade}
      />
    </S.OrderChoosePaymentFormContainer>
  );
}

ChoosePaymentForm.propTypes = {
  periodoCarencia: PropTypes.number.isRequired,
  carenciaAtivada: PropTypes.bool.isRequired,
  activeStep: PropTypes.number.isRequired,
  softwares: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  setItems: PropTypes.func.isRequired,
  handleAddModule: PropTypes.func.isRequired,
  handleRemoveModule: PropTypes.func.isRequired,
  mesInicio: PropTypes.number.isRequired,
  formasPagamento: PropTypes.array.isRequired,
  planosPagamento: PropTypes.array.isRequired,
  formaPagamentoId: PropTypes.number.isRequired,
  planoPagamentoId: PropTypes.number.isRequired,
  setMesInicio: PropTypes.func.isRequired,
  setFormasPagamento: PropTypes.func.isRequired,
  setPlanosPagamento: PropTypes.func.isRequired,
  setFormaPagamentoId: PropTypes.func.isRequired,
  setPlanoPagamentoId: PropTypes.func.isRequired,
  userCanEdit: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  calculateTotalAdesao: PropTypes.func.isRequired,
  calculateTotalMensalidade: PropTypes.func.isRequired,
};

export { ChoosePaymentForm };
