import styled from 'styled-components';
import {
  MdMoreVert,
  MdFormatAlignLeft,
  MdFormatAlignCenter,
  MdFormatAlignRight,
  MdImage,
} from 'react-icons/md';

export const Image = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: ${(props) => props.alignItems ? props.alignItems : 'center'};
  flex-direction: column;
  padding: 0 30px 10px 30px;
`;

export const ImageContainer = styled.img`
  margin-top: 10px;
  height: 76px;
  resize: contain;
`;

export const MoreIcon = styled(MdMoreVert)`
  width: 20px;
  height: 20px;
  background-color: #E5E5E5;
  border-radius: 10px;
  color: #0000008A;
  margin-right: 10px;
  cursor: pointer;
`;

export const AlignLeftIcon = styled(MdFormatAlignLeft)`
  width: 20px;
  height: 20px;
  color: #0000008A;
`;

export const AlignCenterIcon = styled(MdFormatAlignCenter)`
  width: 20px;
  height: 20px;
  color: #0000008A;
`;

export const AlignRightIcon = styled(MdFormatAlignRight)`
  width: 20px;
  height: 20px;
  color: #0000008A;
`;

export const ImageIcon = styled(MdImage)`
  width: 20px;
  height: 20px;
  color: #0000008A;
`;

export const PopoverContainer = styled.div`
  display: 'flex';
  flex-direction: 'column';
  align-items: 'flex-start';
  padding: 10px;

  button + button {
    margin-top: 10px;
    cursor: pointer;
  }

  button:hover {
    background-color: #E5E5E5;
    cursor: pointer;
  }
`;

export const PopoverButton = styled.button`
  padding: 5px;
  width: 100%;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  box-shadow: none;

  label {
    margin-left: 10px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    color: var(--text-black-high);
  }
`;

export const PopoverInputFile = styled.div`
  div#input-image-base64-id {
    width: 100%;
    cursor: pointer;

    input[type='file'] {
      position: absolute;
      opacity: 0;
      width: 170px;
      height: 32px;
      border-radius: 0;
      padding: 0;
      margin: 0;
      z-index: 2;
      cursor: pointer;
    }
  }

  label {
    padding: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;

    svg {
      margin-right: 10px;
    }
  }

  label:hover {
    background-color: #E5E5E5;
    cursor: pointer;
  }
`;
