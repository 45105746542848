import { admin } from './paths';
import permissions from '~/util/permissions';

import AdminAccessGroup from '~/pages/Admin/AccessGroup';
import AdminAddAccessGroup from '~/pages/Admin/AccessGroup/AddAccessGroup';
import AdminEditAccessGroup from '~/pages/Admin/AccessGroup/EditAccessGroup';

import Log from '~/pages/Admin/Log';

import Settings from '~/pages/Admin/Settings';

const adminRoutes = [
  {
    key: 1,
    exact: true,
    hideSidebar: false,
    name: 'Grupo de Acesso',
    path: admin.accessGroup,
    component: AdminAccessGroup,
    permission: permissions['view_grupo-acesso'],
  },
  {
    key: 2,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Grupo de Acesso',
    path: admin.addAccessGroup,
    component: AdminAddAccessGroup,
    permission: permissions['create_grupo-acesso'],
  },
  {
    key: 3,
    exact: false,
    hideSidebar: true,
    name: 'Editar Grupo de Acesso',
    path: admin.editAccessGroup,
    component: AdminEditAccessGroup,
    permission: permissions['edit_grupo-acesso'],
  },
  {
    key: 4,
    exact: true,
    hideSidebar: false,
    name: 'Configurações',
    path: admin.settings,
    component: Settings,
    permission: permissions.view_settings,
  },
  {
    key: 5,
    exact: true,
    hideSidebar: false,
    name: 'Logs',
    path: admin.logs,
    component: Log,
    permission: permissions['view_logs'],
  },
];

export default adminRoutes;
