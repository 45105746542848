import api, { endpoints } from '~/services/api';

class SoftwareService {
  static getProductSoftwaresSemLogo = async () => {
    const { data: response } = await api.post(
      endpoints.product.software.getProductSoftwares,
      { isLogo: false }
    );
    return response.data;
  };

  static getProductSoftwaresComLogo = async () => {
    const { data: response } = await api.post(
      endpoints.product.software.getProductSoftwares,
      { isLogo: true }
    );
    return response.data;
  };

  static getProductSoftwares = async (filter) => {
    const { data: response } = await api.post(
      endpoints.product.software.getProductSoftwares,
      filter
    );
    return response.data;
  };
}

export { SoftwareService };
