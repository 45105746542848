import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { initialPaths } from './paths';

export default function RouteWrapper({
  component: Component,
  permission,
  ...rest
}) {
  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  if (!permission || (permission && userPermissions.includes(permission))) {
    return <Route {...rest} render={props => <Component {...props} />} />;
  }

  toast('Acesso Negado!', { type: toast.TYPE.ERROR });

  return <Redirect to={initialPaths.pageInitial} />;
}

RouteWrapper.propTypes = {
  permission: PropTypes.string,
  component: PropTypes.oneOfType([
    PropTypes.object, PropTypes.func,
  ]).isRequired,
};

RouteWrapper.defaultProps = { permission: null };
