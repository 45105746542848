export const actionTypes = {
  LIFECICLE_UPDATE_SHOW_AGAIN_MODALITY: '@lifecicle/UPDATE_SHOW_AGAIN_MODALITY',
  LIFECICLE_GET_INITIAL_STATE: '@lifecicle/GET_INITIAL_STATE',
};

export function updateShowAgainModality(state) {
  return {
    type: actionTypes.LIFECICLE_UPDATE_SHOW_AGAIN_MODALITY,
    payload: {
      state,
    },
  };
}

export function getInitialState() {
  return {
    type: actionTypes.LIFECICLE_GET_INITIAL_STATE,
  };
}
