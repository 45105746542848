import PropTypes from 'prop-types';
import React from 'react';
import FileBase64 from 'react-file-base64';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { productPaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import { inputNumber } from '~/util/stringutils';
import { SUPORT_FILES } from '~/util/fileutils';
import { Radio } from '~/components/Radio';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { ImageContent } from './styles';
import { ImagePreviewContainer } from '../styles';

class AddProductSoftware extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    const { history } = props;
    this.history = history;

    this.state = {
      loading: false,
      inputAtivo: false,
      inputNome: '',
      inputOrdem: 0,
      inputLogo: '',
      inputCodigoSoftwareERP: '',

      // errors
      error_inputNome: false,
      error_inputLogo: false,
      error_inputCodigoSoftwareERP: false,
    };
  }

  setLoading = b => this.setState({ loading: b });

  setInputNome = b => this.setState({ inputNome: b });

  setInputOrdem = b => this.setState({ inputOrdem: b });

  setInputAtivo = b => this.setState({ inputAtivo: !!b });

  setInputCodigoSoftwareERP = b => {
    this.setState({ inputCodigoSoftwareERP: b });
  };

  setInputLogo = b => {
    if (b.type && SUPORT_FILES.IMAGES.includes(b.type)) {
      this.setState({ inputLogo: b.base64 ? b.base64 : '', imageName: b.name });
      this.handleErrorsInputLogo(false);
    } else {
      toast.error('Tipo de arquivo não é suportado');
    }
  };

  handleInputNomeChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputNome(value);
    this.handleErrorsInputNome(false);
  };

  handleInputOrdemChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputOrdem(value);
  };

  handleInputCodigoSoftwareERPChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCodigoSoftwareERP(inputNumber(value));
    this.handleErrorsInputCodigoSoftwareERP(false);
  }

  handleInputAtivoChange = event => {
    const {
      target: { value },
    } = event;

    this.setInputAtivo(value === 'true');
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.validateForm(async formData => {
      this.setLoading(true);
      try {
        const { data } = await api.post(
          endpoints.product.software.createProductSoftware,
          formData
        );
        toast(data.message, { type: toast.TYPE.SUCCESS });
        this.setLoading(false);
        this.history.push(productPaths.productSoftware);
      } catch (error) {
        this.setLoading(false);
        showMessageError(error);
      }
    });
  };

  handleErrorsInputNome = (error_inputNome) => {
    this.setState({ error_inputNome });
  }

  handleErrorsInputLogo = (error_inputLogo) => {
    this.setState({ error_inputLogo });
  }

  handleErrorsInputCodigoSoftwareERP = (error_inputCodigoSoftwareERP) => {
    this.setState({ error_inputCodigoSoftwareERP });
  }

  validateForm(success) {
    let errors_temp = false;
    const formData = {};

    if (!this.state.inputNome || !this.state.inputNome.length) {
      this.handleErrorsInputNome(true);
      errors_temp = true;
    }

    if (!this.state.inputLogo || !this.state.inputLogo.length) {
      this.handleErrorsInputLogo(true);
      errors_temp = true;
    }

    if (!this.state.inputCodigoSoftwareERP || !this.state.inputCodigoSoftwareERP.length) {
      this.handleErrorsInputCodigoSoftwareERP(true);
      errors_temp = true;
    }

    if (errors_temp) {
      return;
    }

    formData.nome = this.state.inputNome.trim();
    formData.codigoSoftwareERP = this.state.inputCodigoSoftwareERP.trim();
    formData.logo = this.state.inputLogo;
    formData.ordem = this.state.inputOrdem;
    formData.ativo = Boolean(this.state.inputAtivo);

    success(formData);
  }

  render() {
    return (
      <div className="defaultFormContainer">
        <Modal show={this.state.loading} loading />
        <SidebarPageHeader
          mainMenu="Produto"
          pageName="Novo Software"
          breadcrumbs={[{
            label: 'Software',
            link: productPaths.productSoftware,
          }]}
          button1={{
            label: 'Salvar',
            onClick: () => this.handleSubmit,
            main: true,
          }}
          openButtonSelect
          buttonCancelHandler={() => this.history.goBack()}
        />
        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <div className="infoCard">
            <span id="title">Dados do Software</span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>Código Software ERP *</span>
                <input
                  type="text"
                  name="nome"
                  value={this.state.inputCodigoSoftwareERP}
                  onChange={this.handleInputCodigoSoftwareERPChange}
                />
                {this.state.error_inputCodigoSoftwareERP && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
              <div className="inputHolder defaultFlex">
                <span>Nome do Software *</span>
                <input
                  type="text"
                  name="nome"
                  value={this.state.inputNome}
                  onChange={this.handleInputNomeChange}
                />
                {this.state.error_inputNome && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
              <div className="inputHolder noMarginRight">
                <span>Ordem</span>
                <input
                  type="number"
                  name="ordem"
                  value={this.state.inputOrdem}
                  onChange={this.handleInputOrdemChange}
                />
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Disponibilidade</span>
            <div className="rowContainer">
              <div className="inputHolder addMarginBottom">
                <span>Status para pedido? *</span>
                <div className="rowContainer">
                  <Radio
                    name="statusPedido"
                    labelHorizontal={'Disponível'}
                    value={true}
                    onChange={this.handleInputAtivoChange}
                    outlineSelect={this.state.inputAtivo === true}
                  />
                  <Radio
                    name="statusPedido"
                    labelHorizontal={'Indisponível'}
                    value={false}
                    onChange={this.handleInputAtivoChange}
                    outlineSelect={this.state.inputAtivo === false}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Logotipo do Software *</span>
            <div className="rowContainer">
              <ImageContent>
                <div className="imagePicker">
                  <div className="buttonHolder">
                    <span>ESCOLHER IMAGEM</span>
                    <FileBase64
                      multiple={false}
                      onDone={this.setInputLogo.bind(this)}
                    />
                  </div>
                  <label>
                    {' '}
                    {this.state.imageName ||
                      'Selecione um arquivo de imagem'}{' '}
                  </label>
                </div>
              </ImageContent>
            </div>
            {this.state.error_inputLogo && (
              <small style={{ color: 'red' }}>Campo obrigatório</small>
            )}
          </div>
          {this.state.inputLogo && (
            <div className="infoCard">
              <span id="title">Pré-Visualização</span>
              <div>
                <ImagePreviewContainer>
                  <img
                    src={this.state.inputLogo}
                    height={200}
                    alt='preview software'
                  />
                </ImagePreviewContainer>
              </div>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default AddProductSoftware;
