import styled from 'styled-components';

export const ContainerRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 6px;
    margin-top: -6px;
  }

  input {
    color: #707070;
  }

  input.inputSmall {
    width: 64px;
  }
`;
