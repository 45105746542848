/* eslint-disable indent */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { includes } from 'lodash';
import { connect } from 'react-redux';
import FileBase64 from 'react-file-base64';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import Modal, { CustomModal } from '~/components/Modal';
import { person } from '~/routes/paths';
import permissions from '~/util/permissions';
import { InputPhoneMask } from '~/components/FieldPhone';
import { AutoCompleteOn } from '~/components/AutoCompleteOn';
import PersonEmployeeService from '~/services/person-employee-service';
import PersonResaleService from '~/services/person-resale-service';
import PersonResaleEdit from '~/model/person-resale-edit';
import { AdminGroupAccessService } from '~/services/admin-group-access-service';
import { ChannelRatingService } from '~/services/person/channelrating';
import { AuthenticationService } from '~/services/authentication';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import {
  UNIDADES_FEDERATIVAS,
  PERSON_STATUS_ENUM,
} from '~/util/domainutils';
import {
  validaCnpj,
  validateEmail,
  unFormatCpfCnpj,
  validePhone,
  onlyNumbers,
  formatCpfCnpj,
  onlyText,
  unFormatPunctuation,
} from '~/util/stringutils';
import { SUPORT_FILES } from '~/util/fileutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import * as S from './styles';
import '../styles.scss';

class EditPersonResale extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    location: PropTypes.shape({
      state: PropTypes.shape({
        id: PropTypes.number,
        responsavelExecutivoId: PropTypes.number,
      }).isRequired,
    }).isRequired,
    user: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    const { history, location } = props;
    this.history = history;
    this.location = location;
    this.accessGroups = [];
    this.channelRatings = [];
    this.user = props.user;

    this.userCanEditResponsibleExecutive = props.permissions
      .includes(permissions['edit_responsavel-executivo']);

    this.refTelefone1 = React.createRef();
    this.refTelefone2 = React.createRef();
    this.refEmail = React.createRef();

    this.state = {
      loading: true,
      tiposDescontoRows: [],
      totalDescontos: 0.0,
      inputEmployeeValue: '',
      employee: {},
      employees: [],
      timeout: null,
      showSuggestions: false,
      person: new PersonResaleEdit({}),
      resaleItem: null,

      // errors
      error_grupoId: false,
      error_cpfCnpj: false,
      error_nomeFantasia: false,
      error_razaoSocial: false,
      error_telefone1: false,
      error_telefone2: false,
      error_email: false,
      error_status: false,
      error_statusChave: false,

      // errors message
      error_cpfCnpj_message: 'Campo obrigatório',
      error_telefone1_message: 'Campo obrigatório',
      error_telefone2_message: 'Campo obrigatório',
      error_email_message: 'Campo obrigatório',
    };
  }

  componentDidMount() {
    Promise.all([
      AdminGroupAccessService.getAccessGroup(''),
      ChannelRatingService.getChannelRating(''),
      PersonResaleService.getPersonResale(this.location.state.id),
    ])
      .then(values => {
        if (values.length) {
          const [groups, channelRatings, resale] = values;

          if (channelRatings.resultado.length) {
            this.channelRatings.push(...channelRatings.resultado);
          }

          if (resale) {
            const person = new PersonResaleEdit(resale);
            const total = this.calculateTotal(person);

            const state = { person, totalDescontos: total };

            this.updateDescontos(state);
            this.setState(state);

            if (groups.resultado.length) {
              this.accessGroups.push(...groups.resultado);
            }
          } else {
            toast('Erro ao consultar revenda.', { type: toast.TYPE.ERROR });

            setTimeout(() => {
              this.history.push(person.personResale);
            }, 100);
          }
        }
      })
      .then(() => {
        if (this.location.state.responsavelExecutivoId) {
          PersonEmployeeService.getPersonEmployee(
            this.location.state.responsavelExecutivoId
          )
            .then((executive) => {
              if (executive) {
                this.setInputValue(this.renderSuggestion(executive));
              }
            })
            .catch(() => {
              toast('Erro ao buscar responsável executivo.', {
                type: toast.TYPE.ERROR,
              });

              setTimeout(() => {
                this.history.push(person.personResale);
              }, 100);
            });
        }
      })
      .catch(() => {
        toast('Erro ao consultar informações.', {
          type: toast.TYPE.ERROR,
        });

        setTimeout(() => {
          this.history.push(person.personResale);
        }, 100);
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  handleFilterAccessGroups(resultado) {
    const accessGroups = this.user.grupo.grupoAcessos.map(
      group => group.grupoAcesso,
    );
    let criadorUserAccessId = [];
    let criadorSearchId = [];
    let groupsUserAccessToView = this.user.grupo.grupoAcessos.map(
      group => group.grupoAcessoId,
    );
    let accessDataGroups = resultado;

    accessGroups.forEach(group => {
      if (!criadorUserAccessId.includes(group.criadorId)) {
        criadorUserAccessId.push(group.criadorId);
      }
    });

    resultado.forEach(group => {
      if (!criadorSearchId.includes(group.criadorId)) {
        criadorSearchId.push(group.criadorId);
      }
    });

    const isVerifySameCriadorId = () => {
      return criadorSearchId[0] === criadorUserAccessId[0];
    };

    if (!isVerifySameCriadorId()) {
      accessDataGroups = accessDataGroups.concat(accessGroups);

      groupsUserAccessToView = [];
      resultado.forEach(group => {
        if (!groupsUserAccessToView.includes(group.id)) {
          groupsUserAccessToView.push(group.id);
        }
      });
    }

    const resultFilted = accessDataGroups.filter(group => {
      return groupsUserAccessToView.includes(group.id);
    });

    return [resultFilted, resultFilted.length];
  }

  validateResaleItem(success) {
    const formData = this.state.person;

    let errors_temp = false;
    let cpfCnpj_temp = false;
    let email_temp = false;
    let focus = '';

    const IS_CNPJ = !formData.cpfCnpj || !formData.cpfCnpj.length;
    const IS_RAZAO_SOCIAL = !formData.razaoSocial || !formData.razaoSocial.length;
    const IS_NOME_FANTASIA = !formData.nomeFantasia || !formData.nomeFantasia.length;
    const IS_EMAIL = !formData.email || !formData.email.length;

    if (IS_CNPJ) {
      this.handleErrorsCpfCnpj(true, 'Campo obrigatório');
      errors_temp = true;
      cpfCnpj_temp = true;
      if (focus === '') focus = 'cfpCnpj';
    }

    if (!cpfCnpj_temp) {
      formData.cpfCnpj = unFormatCpfCnpj(formData.cpfCnpj);
      if (!validaCnpj(formData.cpfCnpj)) {
        this.handleErrorsCpfCnpj(true, 'Digite um CNPJ válido');
        errors_temp = true;
        if (focus === '') focus = 'cfpCnpj';
      }
      cpfCnpj_temp = false;
    }

    if (IS_RAZAO_SOCIAL) {
      this.handleErrorsRazaoSocial(true);
      errors_temp = true;
      if (focus === '') focus = 'razaoSocial';
    }

    if (IS_NOME_FANTASIA) {
      this.handleErrorsNomeFantasia(true);
      errors_temp = true;
      if (focus === '') focus = 'nomeFantasia';
    }

    if (IS_EMAIL) {
      this.handleErrorsEmail(true, 'Campo obrigatório');
      errors_temp = true;
      email_temp = true;
      if (focus === '') focus = 'email';
    }

    if (!email_temp) {
      if (!validateEmail(formData.email)) {
        this.handleErrorsEmail(true, 'Digite um Email válido');
        errors_temp = true;
        if (focus === '') focus = 'email';
      }
      email_temp = false;
    }

    if (IS_CNPJ || IS_RAZAO_SOCIAL || IS_NOME_FANTASIA || IS_EMAIL) {
      toast.error('Preencha todos os campos obrigatórios para redefinir senha!');
    }

    if (errors_temp) {
      this.handleErrorsFocus(focus);
      return;
    }

    success();
  }

  setResaleItem = (cpfCnpj, nomeRevenda, email) => {
    this.validateResaleItem(() => {
      if (cpfCnpj && nomeRevenda && email) {
        this.setState({ resaleItem: { cpfCnpj, nomeRevenda, email } });
      } else {
        this.setState({ resaleItem: null });
      }
    });
  }

  setInputValue = b => this.setState({ inputEmployeeValue: b });

  setLoading = b => this.setState({ loading: b });

  setSelectGrupo = b => {
    const { person } = this.state;
    person.grupoId = b;
    this.setState({ person });
  };

  setSelectTipoPessoa = b => {
    const { person } = this.state;
    person.tipoPessoa = b;
    this.setState({ person });
  };

  setInputCpfCnpj = b => {
    const { person } = this.state;
    person.cpfCnpj = b;
    this.setState({ person });
  };

  setSelectEstado = b => {
    const { person } = this.state;
    person.estado = b;
    this.setState({ person });
  };

  setInputCidade = b => {
    const { person } = this.state;
    person.cidade = b;
    this.setState({ person });
  };

  setInputCep = b => {
    const { person } = this.state;
    person.cep = b;
    this.setState({ person });
  };

  setInputBairro = b => {
    const { person } = this.state;
    person.bairro = b;
    this.setState({ person });
  };

  setInputEndereco = b => {
    const { person } = this.state;
    person.endereco = b;
    this.setState({ person });
  };

  setInputComplemento = b => {
    const { person } = this.state;
    person.complemento = b;
    this.setState({ person });
  };

  setInputPontoReferencia = b => {
    const { person } = this.state;
    person.pontoReferencia = b;
    this.setState({ person });
  };

  setInputTelefone1 = b => {
    const { person } = this.state;
    person.telefone1 = b;
    this.setState({ person });
  };

  setInputTelefone2 = b => {
    const { person } = this.state;
    person.telefone2 = b;
    this.setState({ person });
  };

  setInputEmail = b => {
    const { person } = this.state;
    person.email = b.toLowerCase();
    this.setState({ person });
  };

  setInputDataFundacao = b => {
    const { person } = this.state;
    person.dataFundacao = b;
    this.setState({ person });
  };

  updateDescontos(state) {
    if (state.person.classificacaoId) {
      const selected = this.channelRatings.find(
        value => value.id === state.person.classificacaoId
      );
      const mod4Rows = Math.ceil(selected.descontos.length / 4);
      let countDescontos = 0;
      state.tiposDescontoRows = [...Array(mod4Rows).keys()].map(key => {
        let countToEnd = countDescontos < 4 ? 4 : selected.descontos.length;

        if (countToEnd < 4) {
          countToEnd = countDescontos + 4;
        }

        const row = selected.descontos.slice(countDescontos, countToEnd);
        countDescontos += 4;
        return {
          key,
          classificacaoId: state.person.classificacaoId,
          descontos: row,
        };
      });
    } else {
      state.tiposDescontoRows = [];
    }
  }

  calculateTotal(person) {
    const selectedChannelRating = this.channelRatings.find(
      value => value.id === person.classificacaoId
    );
    let total = 0.0;
    person.descontosRevenda.map(id => {
      if (
        selectedChannelRating.descontos &&
        selectedChannelRating.descontos.length
      ) {
        const desconto = selectedChannelRating.descontos.find(
          value => value.id === id
        );
        if (desconto) {
          total += desconto.valor;
        }
      }
      return id;
    });
    return total;
  }

  setAddRadioClassificacao = b => {
    const { person } = this.state;
    person.classificacaoId = parseInt(b);
    person.descontosRevenda = [];

    const state = {
      totalDescontos: 0.0,
      person,
    };

    this.updateDescontos(state);
    this.setState(state);
  };

  setRemoveRadioClassificacao = () => {
    const { person } = this.state;
    person.classificacaoId = null;
    person.descontosRevenda = [];
    this.setState({
      person,
      tiposDescontoRows: [],
      totalDescontos: 0.0,
    });
  };

  addCheckboxDescontosRevenda = b => {
    const { person } = this.state;
    person.descontosRevenda.push(parseInt(b));
    const total = this.calculateTotal(person);
    this.setState({
      person,
      totalDescontos: total,
    });
  };

  removeCheckboxDescontosRevenda = b => {
    const { person } = this.state;
    person.descontosRevenda.splice(
      person.descontosRevenda.indexOf(parseInt(b)),
      1
    );
    const total = this.calculateTotal(person);
    this.setState({
      person,
      totalDescontos: total,
    });
  };

  setInputImagem = b => {
    if (b.type && SUPORT_FILES.IMAGES.includes(b.type)) {
      const { person } = this.state;
      person.imagem = b.base64;
      this.setState({ person, imageName: b.name });
    } else {
      toast.error('Tipo de arquivo não é suportado');
    }
  };

  setStatusChave = b => {
    const { person } = this.state;
    person.statusChave = b;
    this.setState({ person });
  }

  setResponsavelExecutivoId = b => {
    const { person } = this.state;
    person.responsavelExecutivoId = b;
    this.setState({ person });
  }

  handleSelectGrupoChange = event => {
    const {
      target: { value },
    } = event;
    this.setSelectGrupo(value);
    this.handleErrorsGrupoId(false);
  };

  handleSelectTipoPessoaChange = event => {
    const {
      target: { value },
    } = event;
    this.setSelectTipoPessoa(value);
  };

  handleInputCpfCnpjChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCpfCnpj(value);
    this.handleErrorsCpfCnpj(false, 'Campo obrigatório');
  };

  handleSelectEstadoChange = event => {
    const {
      target: { value },
    } = event;
    this.setSelectEstado(value);
  };

  handleInputCidadeChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCidade(value);
  };

  handleInputCepChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCep(value);
  };

  handleInputBairroChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputBairro(value);
  };

  handleInputEnderecoChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputEndereco(value);
  };

  handleInputComplementoChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputComplemento(value);
  };

  handleInputPontoReferenciaChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputPontoReferencia(value);
  };

  handleInputTelefone1Change = event => {
    const {
      target: { value },
    } = event;
    this.setInputTelefone1(value);
    this.handleErrorsTelefone1(false, 'Campo obrigatório');
  };

  handleInputTelefone2Change = event => {
    const {
      target: { value },
    } = event;
    this.setInputTelefone2(value);
    this.handleErrorsTelefone2(false, 'Digite um número válido');
  };

  handleInputEmailChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputEmail(value.toLowerCase());
    this.handleErrorsEmail(false, 'Campo obrigatório');
  };

  handleInputDataFundacaoChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputDataFundacao(value);
  };

  handleCheckboxComissionadaChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(oldState => ({
      person: { ...oldState.person, comissionada: value },
    }));
  };

  handleRadioClassificacaoChange = event => {
    const {
      target: { value, checked },
    } = event;

    if (checked) {
      this.setAddRadioClassificacao(value);
    } else {
      this.setRemoveRadioClassificacao();
    }
  };

  handleCheckboxDescontosRevendaChange = event => {
    const {
      target: { value, checked },
    } = event;
    if (checked) {
      this.addCheckboxDescontosRevenda(value);
    } else {
      this.removeCheckboxDescontosRevenda(value);
    }
  };

  handleInputRazaoSocialChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(oldState => ({
      person: { ...oldState.person, razaoSocial: value },
    }));
    this.handleErrorsRazaoSocial(false);
  };

  handleInputNomeFantasiaChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(oldState => ({
      person: { ...oldState.person, nomeFantasia: value },
    }));
    this.handleErrorsNomeFantasia(false);
  };

  handleInputInscricaoEstadualChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(oldState => ({
      person: {
        ...oldState.person,
        inscricaoEstadual: value,
      },
    }));
  };

  handleInputOptanteSimplesChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(oldState => ({
      person: { ...oldState.person, optanteSimples: value },
    }));
  };

  handleInputMicroempresaChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(oldState => ({
      person: { ...oldState.person, microempresa: value },
    }));
  };

  handleInputRetemICMSChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(oldState => ({
      person: { ...oldState.person, retemICMS: value },
    }));
  };

  handleInputSegmentosChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(oldState => ({
      person: { ...oldState.person, segmentos: value },
    }));
  };

  handleInputStatusRevendaChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(oldState => ({
      person: { ...oldState.person, status: value },
    }));

    if (value === PERSON_STATUS_ENUM.BLOCKED.value) {
      this.setState(oldState => ({
        person: { ...oldState.person, statusChave: value },
      }));
    }

    this.handleErrorsStatusRevenda(false);
  };

  handleInputStatusChaveChange = event => {
    const {
      target: { value },
    } = event;

    if (!this.handleStatusIsBlocked()) {
      this.setState(oldState => ({
        person: { ...oldState.person, statusChave: value },
      }));
    }

    this.handleErrorsStatusChave(false);
  };

  handleInputPrazoChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(state => ({
      person: { ...state.person, prazoRenovacaoChave: value },
    }));
  }

  handleErrorsGrupoId = (error_grupoId) => {
    this.setState({ error_grupoId });
  }

  handleErrorsCpfCnpj = (error_cpfCnpj, error_cpfCnpj_message) => {
    this.setState({ error_cpfCnpj, error_cpfCnpj_message });
  }

  handleErrorsNomeFantasia = (error_nomeFantasia) => {
    this.setState({ error_nomeFantasia });
  }

  handleErrorsRazaoSocial = error_razaoSocial => {
    this.setState({ error_razaoSocial });
  };

  handleErrorsTelefone1 = (error_telefone1, error_telefone1_message) => {
    this.setState({ error_telefone1, error_telefone1_message });
  }

  handleErrorsTelefone2 = (error_telefone2, error_telefone2_message) => {
    this.setState({ error_telefone2, error_telefone2_message });
  }

  handleErrorsEmail = (error_email, error_email_message) => {
    this.setState({ error_email, error_email_message });
  }

  handleErrorsStatusRevenda = (error_status) => {
    this.setState({ error_status });
  }

  handleErrorsStatusChave = error_statusChave => {
    this.setState({ error_statusChave });
  };

  handleErrorsFocus = (focus) => {
    switch (focus) {
      case 'telefone1':
        this.refTelefone1.current.focus();
        return;
      case 'telefone2':
        this.refTelefone2.current.focus();
        return;
      case 'email':
        this.refEmail.current.focus();
        return;
      default:
        return;
    }
  }

  validateForm(success) {
    let errors_temp = false;
    let cpfCnpj_temp = false;
    let telefone1_temp = false;
    let email_temp = false;
    let focus = '';
    const formData = this.state.person;

    if (!formData.grupoId) {
      this.handleErrorsGrupoId(true);
      errors_temp = true;
      if (focus === '') focus = 'grupoId';
    }

    if (!formData.cpfCnpj || !formData.cpfCnpj.length) {
      this.handleErrorsCpfCnpj(true, 'Campo obrigatório');
      errors_temp = true;
      cpfCnpj_temp = true;
      if (focus === '') focus = 'cfpCnpj';
    }

    if (!cpfCnpj_temp) {
      formData.cpfCnpj = unFormatCpfCnpj(formData.cpfCnpj);
      if (!validaCnpj(formData.cpfCnpj)) {
        this.handleErrorsCpfCnpj(true, 'Digite um CNPJ válido');
        errors_temp = true;
        if (focus === '') focus = 'cfpCnpj';
      }
      cpfCnpj_temp = false;
    }

    if (!formData.nomeFantasia || !formData.nomeFantasia.length) {
      this.handleErrorsNomeFantasia(true);
      errors_temp = true;
      if (focus === '') focus = 'nomeFantasia';
    }

    if (!formData.razaoSocial || !formData.razaoSocial.length) {
      this.handleErrorsRazaoSocial(true);
      errors_temp = true;
      if (focus === '') focus = 'razaoSocial';
    }

    if (!formData.responsavelExecutivoId) {
      formData.responsavelExecutivoId = null;
    }

    if (!formData.telefone1 || !formData.telefone1.length) {
      this.handleErrorsTelefone1(true, 'Campo obrigatório');
      errors_temp = true;
      telefone1_temp = true;
      if (focus === '') focus = 'telefone1';
    }

    if (!telefone1_temp) {
      if (formData.telefone1.includes('.')) {
        formData.telefone1 = formData.telefone1.replace('.', '-');
      }
      if (formData.telefone1 && !validePhone(formData.telefone1)) {
        this.handleErrorsTelefone1(true, 'Digite um número válido');
        errors_temp = true;
        if (focus === '') focus = 'telefone1';
      }
      telefone1_temp = false;
    }

    if (formData.telefone2) {
      if (formData.telefone2 && formData.telefone2.includes('.')) {
        formData.telefone2 = formData.telefone2.replace('.', '-');
      }
      if (formData.telefone2 && !validePhone(formData.telefone2)) {
        this.handleErrorsTelefone2(true, 'Digite um número válido');
        errors_temp = true;
        if (focus === '') focus = 'telefone2';
      }
    }

    if (!formData.email || !formData.email.length) {
      this.handleErrorsEmail(true, 'Campo obrigatório');
      errors_temp = true;
      email_temp = true;
      if (focus === '') focus = 'email';
    }

    if (!email_temp) {
      if (!validateEmail(formData.email)) {
        this.handleErrorsEmail(true, 'Digite um Email válido');
        errors_temp = true;
        if (focus === '') focus = 'email';
      }
      email_temp = false;
    }

    if (!formData.status || !formData.status.length) {
      this.handleErrorsStatusRevenda(true);
      errors_temp = true;
      if (focus === '') focus = 'status';
    }

    if (!formData.statusChave || !formData.statusChave.length) {
      this.handleErrorsStatusChave(true);
      errors_temp = true;
      if (focus === '') focus = 'statusChave';
    }

    if (formData.dataCadastro) {
      formData.dataCadastro = moment(formData.dataCadastro).format('DD/MM/YYYY');
    }

    if (formData.dataFundacao) {
      formData.dataFundacao = moment(formData.dataFundacao).format('DD/MM/YYYY');
    }

    if (errors_temp) {
      this.handleErrorsFocus(focus);
      return;
    }

    if (formData.nomeFantasia) {
      formData.nomeFantasia = formData.nomeFantasia.trim();
    }

    if (formData.razaoSocial) {
      formData.razaoSocial = formData.razaoSocial.trim();
    }

    if (formData.telefone1) {
      formData.telefone1 = unFormatPunctuation(formData.telefone1.trim());
    }

    if (formData.telefone2) {
      formData.telefone2 = unFormatPunctuation(formData.telefone2.trim());
    }

    if (formData.cidade) formData.cidade = formData.cidade.trim();

    if (formData.cep) formData.cep = unFormatPunctuation(formData.cep.trim());

    if (formData.bairro) formData.bairro = formData.bairro.trim();

    if (formData.endereco) formData.endereco = formData.endereco.trim();

    if (formData.complemento) {
      formData.complemento = formData.complemento.trim();
    }

    if (formData.pontoReferencia) {
      formData.pontoReferencia = formData.pontoReferencia.trim();
    }

    if (formData.prazoRenovacaoChave) {
      formData.prazoRenovacaoChave = formData.prazoRenovacaoChave.trim();
    }

    success(formData);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.validateForm(async formData => {
      this.setLoading(true);
      try {
        const { data } = await api.put(
          endpoints.person.resale.updatePersonResale,
          formData
        );
        toast(data.message, { type: toast.TYPE.SUCCESS });
        setTimeout(() => {
          this.history.push(person.personResale);
        }, 100);
      } catch (error) {
        showMessageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  };

  handleSelectedStatusOutline(field, value) {
    return this.state.person[field] === PERSON_STATUS_ENUM[value].value
      ? 'checkradio_outline' : '';
  }

  handleStatusIsBlocked = () => {
    return this.state.person.status === PERSON_STATUS_ENUM.BLOCKED.value;
  };

  getEmployees = async (value) => {
    try {
      const data = await PersonEmployeeService.getPersonEmployeeAutoComplete({
        cpfCnpj: unFormatCpfCnpj(value.trim().toLowerCase()) || null,
        nome: onlyText(value.trim().toLowerCase()) || null,
      });

      return data.resultado;
    } catch (error) {
      showMessageError(error);
      return [];
    }
  }

  getEmployeesChange = async (event) => {
    const {
      target: { value: valueToFind }
    } = event;

    if (valueToFind === '') {
      this.setState({
        employees: [],
        showSuggestions: false,
      });
    } else {
      clearTimeout(this.state.timeout);

      const timer = setTimeout(() => {
        const asyncSetTimeOut = async () => {
          const employees = await this.getEmployees(valueToFind);
          if (employees.length) {
            this.setState({
              employees,
              showSuggestions: true,
            });
          } else {
            this.setState({
              employees: [],
              showSuggestions: true,
            });
          }
        };

        asyncSetTimeOut();
      }, 500); // 0.5 segundos

      this.setState({ timeout: timer });
    }

    this.setInputValue(valueToFind);
  };

  getSuggestionLabel = suggestion => {
    if (suggestion) {
      return this.renderSuggestion(suggestion);
    } else {
      return '';
    }
  }

  getSuggestionValue = suggestion => {
    if (suggestion) {
      return suggestion;
    } else {
      return '';
    }
  }

  getSuggestionKey = suggestion => {
    if (suggestion) {
      return suggestion.id;
    } else {
      return '';
    }
  }

  renderSuggestion = suggestion => {
    return `${formatCpfCnpj(suggestion.cpfCnpj)} - ${suggestion.nomeSocial}`;
  }

  handleOnClick = suggestion => {
    const [employeeSelected] = this.state.employees.filter(
      suggItem => suggItem.id === suggestion.id
    );

    this.setInputValue(this.renderSuggestion(employeeSelected));
    this.setResponsavelExecutivoId(employeeSelected.id);
    this.setState({
      showSuggestions: false,
      employee: employeeSelected,
    });
  }

  handleVerifyResaleItem = () => {
    const { email, cpfCnpj, nomeRevenda } = this.state.resaleItem;
    return cpfCnpj !== '' && nomeRevenda !== '' && email !== '';
  }

  handleOpenModalResetPassword = () => {
    const { email, cpfCnpj, nomeFantasia, razaoSocial } = this.state.person;
    const nomeRevenda = nomeFantasia || razaoSocial || '---';
    this.setResaleItem(cpfCnpj, nomeRevenda, email);
  }

  handleResetPassword = async () => {
    this.setLoading(true);
    try {
      const result = await AuthenticationService
        .requestPasswordReset(this.state.resaleItem.cpfCnpj);

      if (result && result.email) {
        toast('Link para redefinição de senha enviado.', {
          type: toast.TYPE.SUCCESS,
          autoClose: 6000,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      this.setResaleItem(null);
      this.setLoading(false);
    }
  }

  render() {
    return (
      <div className="defaultFormContainer">
        <SidebarPageHeader
          mainMenu="Pessoa"
          pageName="Editar Revenda"
          breadcrumbs={[{
            label: 'Revenda',
            link: person.personResale,
          }]}
          button1={{
            label: 'Salvar',
            onClick: () => this.handleSubmit,
            main: true,
          }}
          openButtonSelect
          buttonCancelHandler={() => this.history.goBack()}
          customButtonsBeforeReport={[(
            <button key={'button_reset_password'}
              type="button"
              className="buttonBase baseWebMobile addMargin"
              onClick={this.handleOpenModalResetPassword}
            >
              REDEFINIR SENHA
            </button>
          )]}
        />
        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <div className="infoCard">
            <span id="title">Permissões</span>
            <div className="rowContainer">
              <div className="inputHolder marginRightX4">
                <span>Grupo de Acesso *</span>
                <select
                  name="grupoId"
                  value={this.state.person.grupoId}
                  onChange={this.handleSelectGrupoChange}
                >
                  <option value="">Selecione</option>
                  {this.accessGroups.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </select>
                {this.state.error_grupoId && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
              <div className="inputHolder marginRightX4">
                <span>Status da Revenda *</span>
                <div className="rowContainer">
                  <div className="radioHolder">
                    <div className="inputBoxHolder">
                      <input
                        type="radio"
                        name="status"
                        value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}
                        onChange={this.handleInputStatusRevendaChange}
                      />
                      <span className={
                        `checkradio ${this.handleSelectedStatusOutline(
                          'status', PERSON_STATUS_ENUM.NOT_BLOCKED.key
                        )}`
                      }>
                        {this.state.person.status ===
                          PERSON_STATUS_ENUM.NOT_BLOCKED.value && (
                            <div className="markRadioHolder">
                              <div className="checkradio_stem" />
                            </div>
                          )}
                      </span>
                    </div>
                    <span id="radioLabel">
                      {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
                    </span>
                  </div>
                  <div className="radioHolder">
                    <div className="inputBoxHolder">
                      <input
                        type="radio"
                        name="status"
                        value={PERSON_STATUS_ENUM.BLOCKED.value}
                        onChange={this.handleInputStatusRevendaChange}
                      />
                      <span className={
                        `checkradio ${this.handleSelectedStatusOutline(
                          'status', PERSON_STATUS_ENUM.BLOCKED.key
                        )}`
                      }>
                        {this.state.person.status ===
                          PERSON_STATUS_ENUM.BLOCKED.value && (
                            <div className="markRadioHolder">
                              <div className="checkradio_stem" />
                            </div>
                          )}
                      </span>
                    </div>
                    <span id="radioLabel">
                      {PERSON_STATUS_ENUM.BLOCKED.label}
                    </span>
                  </div>
                </div>
                {this.state.error_status && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
            </div>
          </div>

          <div className="infoCard">
            <span id="title">Geração de Chave</span>
            <div className="rowContainer">
              <div className="inputHolder marginRightX4">
                <span>Status Geração de Chave *</span>
                <div className="rowContainer">
                  <div className="radioHolder">
                    <div className="inputBoxHolder">
                      <input
                        type="radio"
                        name="statusChave"
                        value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}
                        onChange={this.handleInputStatusChaveChange}
                        onClick={() => {
                          if (!this.handleStatusIsBlocked()) {
                            this.setStatusChave(
                              PERSON_STATUS_ENUM.NOT_BLOCKED.value,
                            );
                          }
                        }}
                      />
                      <span className={
                        `checkradio ${this.handleSelectedStatusOutline(
                          'statusChave', PERSON_STATUS_ENUM.NOT_BLOCKED.key
                        )}`
                      }>
                        {this.state.person.statusChave ===
                          PERSON_STATUS_ENUM.NOT_BLOCKED.value && (
                            <div className="markRadioHolder">
                              <div className="checkradio_stem" />
                            </div>
                          )}
                      </span>
                    </div>
                    <span id="radioLabel">
                      {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
                    </span>
                  </div>
                  <div className="radioHolder">
                    <div className="inputBoxHolder">
                      <input
                        type="radio"
                        name="statusChave"
                        value={PERSON_STATUS_ENUM.BLOCKED.value}
                        onChange={this.handleInputStatusChaveChange}
                      />
                      <span className={
                        `checkradio ${this.handleSelectedStatusOutline(
                          'statusChave', PERSON_STATUS_ENUM.BLOCKED.key
                        )}`
                      }>
                        {this.state.person.statusChave ===
                          PERSON_STATUS_ENUM.BLOCKED.value && (
                            <div className="markRadioHolder">
                              <div className="checkradio_stem" />
                            </div>
                          )}
                      </span>
                    </div>
                    <span id="radioLabel">
                      {PERSON_STATUS_ENUM.BLOCKED.label}
                    </span>
                  </div>
                </div>
                {this.state.error_statusChave && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Prazo de Renovação de Chave</span>
                <S.ContainerRow>
                  <input
                    className='inputSmall'
                    type="text"
                    name="prazo"
                    value={onlyNumbers(this.state.person.prazoRenovacaoChave)}
                    onChange={this.handleInputPrazoChange}
                  />
                  <span>dias</span>
                </S.ContainerRow>
              </div>
            </div>
          </div>

          <div className="infoCard">
            <span id="title">Dados Gerais</span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>Tipo de Pessoa *</span>
                <select
                  name="tipoPessoa"
                  value={this.state.person.tipoPessoa}
                  onChange={this.handleSelectTipoPessoaChange}
                >
                  <option value="JURÍDICA">JURÍDICA</option>
                </select>
              </div>
              <div className="inputHolder">
                <span>CNPJ *</span>
                <InputMask
                  type="text"
                  mask="99.999.999/9999-99"
                  name="cpfCnpj"
                  value={this.state.person.cpfCnpj}
                  onChange={this.handleInputCpfCnpjChange}
                />
                {this.state.error_cpfCnpj && (
                  <small style={{ color: 'red' }}>
                    {this.state.error_cpfCnpj_message}
                  </small>
                )}
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Razão Social *</span>
                <input
                  type="text"
                  name="razaoSocial"
                  value={this.state.person.razaoSocial}
                  onChange={this.handleInputRazaoSocialChange}
                />
                {this.state.error_razaoSocial && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex">
                <span>Nome Fantasia *</span>
                <input
                  type="text"
                  name="nomeFantasia"
                  value={this.state.person.nomeFantasia}
                  onChange={this.handleInputNomeFantasiaChange}
                />
                {this.state.error_nomeFantasia && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
              <div className="inputHolder">
                <span>Inscrição Estadual</span>
                <input
                  type="text"
                  name="inscricaoEstadual"
                  value={this.state.person.inscricaoEstadual}
                  onChange={this.handleInputInscricaoEstadualChange}
                />
              </div>
              {this.state.person.tipoPessoa === 'JURÍDICA' ? (
                <div className="inputHolder noMarginRight">
                  <span>Optante do SIMPLES</span>
                  <select
                    name="optanteSimples"
                    value={this.state.person.optanteSimples}
                    onChange={this.handleInputOptanteSimplesChange}
                  >
                    <option value={false}>Não</option>
                    <option value>Sim</option>
                  </select>
                </div>
              ) : null}
            </div>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Responsável Executivo CM</span>
                <span id="helpText">
                  Ao digitar, o campo irá auto completar. A busca do colaborador
                  poderá ser feita pelo CNPJ ou Nome Completo.
                </span>
                <AutoCompleteOn
                  inputDataValue={this.state.inputEmployeeValue}
                  suggestions={this.state.employees}
                  isShowSuggestions={this.state.showSuggestions}
                  handleDataChange={this.getEmployeesChange}
                  handleGetSuggestionKey={this.getSuggestionKey}
                  handleGetSuggestionLabel={this.getSuggestionLabel}
                  handleOnClick={this.handleOnClick}
                  isDisabled={!this.userCanEditResponsibleExecutive}
                />
              </div>
            </div>
          </div>

          <div className="infoCard">
            <span id="title">Endereço</span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>Estado</span>
                <select
                  name="estado"
                  value={this.state.person.estado}
                  onChange={this.handleSelectEstadoChange}
                >
                  <option value={''}>Selecione</option>
                  {UNIDADES_FEDERATIVAS.map(uf => (
                    <option key={uf.sigla} value={uf.nome}>
                      {uf.nome}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inputHolder">
                <span>Cidade</span>
                <input
                  type="text"
                  name="cidade"
                  value={this.state.person.cidade || ''}
                  onChange={this.handleInputCidadeChange}
                />
              </div>
              <div className="inputHolder">
                <span>CEP</span>
                <InputMask
                  type="text"
                  mask="99.999-999"
                  name="cep"
                  value={this.state.person.cep || ''}
                  onChange={this.handleInputCepChange}
                />
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Bairro</span>
                <input
                  type="text"
                  name="bairro"
                  value={this.state.person.bairro || ''}
                  onChange={this.handleInputBairroChange}
                />
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex">
                <span>Endereço Completo</span>
                <input
                  type="text"
                  name="endereco"
                  value={this.state.person.endereco || ''}
                  onChange={this.handleInputEnderecoChange}
                />
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Complemento</span>
                <input
                  type="text"
                  name="complemento"
                  value={this.state.person.complemento || ''}
                  onChange={this.handleInputComplementoChange}
                />
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Ponto de Referência</span>
                <input
                  type="text"
                  name="pontoReferencia"
                  value={this.state.person.pontoReferencia || ''}
                  onChange={this.handleInputPontoReferenciaChange}
                />
              </div>
            </div>
          </div>

          <div className="infoCard">
            <span id="title">Contato</span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>Telefone(1) *</span>
                <InputPhoneMask
                  type="text"
                  name="telefone1"
                  ref={this.refTelefone1}
                  value={this.state.person.telefone1 || ''}
                  onChange={this.handleInputTelefone1Change}
                />
                {this.state.error_telefone1 && (
                  <small style={{ color: 'red' }}>
                    {this.state.error_telefone1_message}
                  </small>
                )}
              </div>
              <div className="inputHolder">
                <span>Telefone(2)</span>
                <InputPhoneMask
                  type="text"
                  name="telefone2"
                  ref={this.refTelefone2}
                  value={this.state.person.telefone2 || ''}
                  onChange={this.handleInputTelefone2Change}
                />
                {this.state.error_telefone2 && (
                  <small style={{ color: 'red' }}>
                    {this.state.error_telefone2_message}
                  </small>
                )}
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>E-mail *</span>
                <input
                  ref={this.refEmail}
                  type="email"
                  name="email"
                  value={this.state.person.email || ''}
                  onChange={this.handleInputEmailChange}
                />
                {this.state.error_email && (
                  <small style={{ color: 'red' }}>
                    {this.state.error_email_message}
                  </small>
                )}
              </div>
            </div>
          </div>

          <div className="infoCard">
            <span id="title">Outros</span>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex">
                <span>Data de Cadastro</span>
                <input
                  disabled
                  readOnly
                  type="date"
                  name="dataCadastro"
                  value={this.state.person.dataCadastro || ''}
                />
              </div>
              <div className="inputHolder defaultFlex">
                <span>Data de Fundação</span>
                <input
                  type="date"
                  name="dataFundacao"
                  value={this.state.person.dataFundacao || ''}
                  onChange={this.handleInputDataFundacaoChange}
                />
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Comissionada</span>
                <select
                  name="comissionada"
                  value={this.state.person.comissionada}
                  onChange={this.handleCheckboxComissionadaChange}
                >
                  <option value={false}>Não</option>
                  <option value>Sim</option>
                </select>
              </div>
            </div>
          </div>

          <div className="infoCard">
            <span id="title">Foto da Empresa</span>
            <div className="rowContainer">
              <S.ImageContent>
                <div className="imagePicker">
                  <div className="buttonHolder">
                    <span>ESCOLHER IMAGEM</span>
                    <FileBase64
                      multiple={false}
                      onDone={this.setInputImagem.bind(this)}
                    />
                  </div>
                  <label>
                    {' '}
                    {this.state.imageName ||
                      'Selecione um arquivo de imagem'}{' '}
                  </label>
                </div>
              </S.ImageContent>
            </div>
          </div>

          <div className="infoCard">
            <span id="title">Opções</span>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex">
                <span>Microempresa</span>
                <select
                  name="optanteSimples"
                  value={this.state.person.microempresa}
                  onChange={this.handleInputMicroempresaChange}
                >
                  <option value={false}>Não</option>
                  <option value={true}>Sim</option>
                </select>
              </div>
              <div className="inputHolder defaultFlex">
                <span>Retém ICMS</span>
                <select
                  name="optanteSimples"
                  value={this.state.person.retemICMS}
                  onChange={this.handleInputRetemICMSChange}
                >
                  <option value={false}>Não</option>
                  <option value={true}>Sim</option>
                </select>
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Segmentos</span>
                <select
                  name="optanteSimples"
                  value={this.state.person.segmentos}
                  onChange={this.handleInputSegmentosChange}
                >
                  <option value={false}>Não</option>
                  <option value={true}>Sim</option>
                </select>
              </div>
            </div>
          </div>

          <div className="infoCard">
            <span id="title">Categoria</span>
            <div className="rowContainer">
              {this.channelRatings.map(item => (
                <div key={item.id} className="radioHolder">
                  <div className="inputBoxHolder">
                    <input
                      type="checkbox"
                      name="channelRating"
                      value={item.id}
                      checked={this.state.person.classificacaoId === item.id}
                      onChange={this.handleRadioClassificacaoChange}
                    />
                    <span className="checkmark">
                      {this.state.person.classificacaoId === item.id && (
                        <div className="markHolder">
                          <div className="checkmark_stem" />
                          <div className="checkmark_kick" />
                        </div>
                      )}
                    </span>
                  </div>
                  <span id="radioLabel">{item.nome}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="infoCard">
            <span id="title">Tipo de Desconto</span>
            <div className="rowContainer">
              {this.state.tiposDescontoRows.map(row => {
                return row.descontos.map(item => (
                  <div key={item.id} className="radioHolder">
                    <div className="inputBoxHolder">
                      <input
                        type="checkbox"
                        value={item.id}
                        checked={includes(
                          this.state.person.descontosRevenda,
                          item.id
                        )}
                        onChange={this.handleCheckboxDescontosRevendaChange}
                      />
                      <span className="checkmark">
                        {includes(
                          this.state.person.descontosRevenda,
                          item.id
                        )
                          && (
                            <div className="markHolder">
                              <div className="checkmark_stem" />
                              <div className="checkmark_kick" />
                            </div>
                          )}
                      </span>
                    </div>
                    <span id="radioLabel">{item.nome}</span>
                  </div>
                ));
              })}
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Total de Desconto(s)</span>
            <div className="dicountHolder">
              <span>
                <span id="value">{this.state.totalDescontos}%</span> de
                desconto(s)
              </span>
            </div>
          </div>
        </form>
        <Modal
          loading={this.state.loading}
          show={this.state.loading || !!this.state.resaleItem}
        >
          <CustomModal>
            {!!this.state.resaleItem && (
              <S.ModalContent>
                <header>
                  <h3>Enviar link para redefinir senha</h3>
                </header>
                <p>{'Tem certeza que deseja enviar link para '}
                  <b>{this.state.resaleItem.email}</b>{' '}
                  {'para redefinir senha de '}
                  <b>{formatCpfCnpj(this.state.resaleItem.cpfCnpj)}</b>{' / '}
                  <b>{this.state.resaleItem.nomeRevenda}?</b>
                </p>
                <div>
                  <button type="button"
                    className="buttonBaseOnly addMargin"
                    onClick={() => this.setResaleItem(null)}
                  >
                    NÃO
                  </button>
                  <button type="button"
                    className="buttonBaseOnly baseMain"
                    onClick={this.handleResetPassword}
                  >
                    SIM
                  </button>
                </div>
              </S.ModalContent>
            )}
          </CustomModal>
        </Modal>
      </div>
    );
  }
}

const getUser = state => state.user.data;
const getPermissions = state => state.user.data.permissions;

export default connect(state => ({
  user: getUser(state),
  permissions: getPermissions(state),
}))(EditPersonResale);
