/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import { FORM_ITEM_ASNWER_TYPE as TAGS } from '~/util/domainutils';
import { useForm } from 'react-hook-form';
import Modal from '~/components/Modal';
import PrintableForm from '~/components/PrintableForm';

const FormDownload = React.forwardRef(({
  idAnswer,
  idForm,
  idClient,
  idResale,
  itemAnswers,
  extraInfo,
  requestedIn,
}, ref) => {
  const [loading, setLoading] = useState(false);
  const { reset } = useForm({ defaultValues: {} });
  const [answeredFilled, setAnsweredFilled] = useState({});
  const [formViewer, setFormViewer] = useState(null);
  const [contentItemsFormAnswer, setContentItemsFormAnswer] = useState([]);

  useEffect(() => {
    const extraInfoFull = JSON.parse(extraInfo);

    reset(extraInfoFull.defaultValues);

    const answeredFilledFull = {
      idAnswer,
      idForm,
      idClient,
      idResale,
      itemAnswers,
      extraInfo: extraInfoFull,
      requestedIn,
    };

    setAnsweredFilled(answeredFilledFull);
  }, [
    extraInfo,
    idAnswer,
    idClient,
    idForm,
    idResale,
    itemAnswers,
    requestedIn,
    reset,
  ]);

  useEffect(() => {
    let cleaningAsyncEffect = false;

    const asyncEffect = async () => {
      if (cleaningAsyncEffect) return;
      setLoading(true);
      try {
        const formViewer = await api.get(
          endpoints.form.register.getForm(idForm)
        );

        const [formResponse] = formViewer.data.data.resultado;

        let dataFormViewer = {
          formName: formResponse.name,
          softwareName: formResponse.softwareName,
          moduleName: formResponse.moduleName,
          createdAt: formResponse.createdAt
        };

        let contentItemsFormAnswerTemp = [];
        const itemForm = [];
        formResponse.itemForms.forEach((f, index) => {
          if (TAGS.TITLE.tag === f.itemType.tag) {
            const titleType = {
              id: f.id,
              itemId: TAGS.TITLE.id,
              itemTag: TAGS.TITLE.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: f.itemContent,
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: true,
              showRequired: true,
              unshowActions: false,
            };

            itemForm.push(titleType);
          }

          if (TAGS.SHORT_ANSWER.tag === f.itemType.tag
            || TAGS.PARAGRAPH.tag === f.itemType.tag
            || TAGS.MULTIPLE_CHOICE.tag === f.itemType.tag
            || TAGS.SELECTION_BOX.tag === f.itemType.tag
          ) {
            let titleGroup = '';

            titleGroup += formResponse.itemForms[index].statement;
            titleGroup = titleGroup
              .toLowerCase()
              .trim()
              .replace(/\s/g, '_');
            titleGroup = `${index}_${titleGroup}`;
            titleGroup = titleGroup
              .normalize('NFD')
              .replace(/([\u0300-\u036f]|[^_0-9a-zA-Z])/g, '');

            contentItemsFormAnswerTemp.push({
              itemFormId: f.id,
              answerContent: {
                attrName: titleGroup,
              },
            });

            let itemContent = null;

            if (f.itemContent) {
              itemContent = JSON.parse(f.itemContent);
            }

            const answerType = {
              id: f.id,
              itemId: TAGS.ANSWER.id,
              itemTag: TAGS.ANSWER.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: itemContent ? itemContent : f.itemContent,
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: false,
              showRequired: true,
              unshowActions: false,
            };

            itemForm.push(answerType);
          }

          if (TAGS.IMAGE.tag === f.itemType.tag) {
            const itemContent = JSON.parse(f.itemContent);
            const imageObject = JSON.parse(itemContent.object);

            const imageType = {
              id: f.id,
              itemId: f.itemType.id,
              itemTag: f.itemType.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: {
                  align: itemContent.align,
                  src: itemContent.src,
                  object: imageObject
                },
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: false,
              showRequired: false,
              unshowActions: false,
            };

            itemForm.push(imageType);
          }

          if (TAGS.CLIENT_RESALE.tag === f.itemType.tag) {
            const itemContent = JSON.parse(f.itemContent);

            const clientResaleType = {
              id: f.id,
              itemId: f.itemType.id,
              itemTag: f.itemType.tag,
              itemData: {
                statement: f.statement,
                description: f.description,
                itemContent: {
                  clientId: itemContent.clientId,
                  resaleId: itemContent.resaleId,
                  showData: itemContent.showData,
                  showDefaultData: itemContent.showDefaultData,
                },
                itemTypeId: f.itemType.id,
                orderNumber: f.orderNumber,
                required: f.required,
              },
              isTitle: false,
              showRequired: false,
              unshowActions: true,
            };

            itemForm.push(clientResaleType);
          }
        });

        let contentItemsForm = itemForm.filter(item => !!item);

        dataFormViewer = {
          ...dataFormViewer,
          contentItemsForm,
        };

        setContentItemsFormAnswer(contentItemsFormAnswerTemp);
        setFormViewer(dataFormViewer);
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();

    return () => {
      cleaningAsyncEffect = true;
    };
  }, [idForm]);

  return (
    <>
      <Modal show={loading} loading />
      <PrintableForm
        ref={ref}
        formViewer={formViewer}
        answeredFilled={answeredFilled}
        contentItemsFormAnswer={contentItemsFormAnswer}
      />
    </>
  );
});

export default FormDownload;
