import PropTypes from 'prop-types';
import React from 'react';
import { InputPhoneMask } from '~/components/FieldPhone';

function ComponentObservation({
  responsavelNome,
  responsavelTelefone1,
  responsavelTelefone2,
  responsavelEmail,
  assunto,
  observacao,
  responsavelNomeError,
  responsavelTelefone1Error,
  responsavelTelefone2Error,
  responsavelEmailError,
  assuntoError,
  observacaoError,
  telefone1ErrorMessage,
  emailErrorMessage,
  handleInputResponsavelNomeChange,
  handleInputResponsavelTelefone1Change,
  handleInputResponsavelTelefone2Change,
  handleInputResponsavelEmailChange,
  handleInputAssuntoChange,
  handleInputObservacaoChange,
}) {
  return (
    <>
      <label id="title">Observação - Dados do Cliente</label>
      <div className="rowContainer">
        <div className="inputHolder defaultFlex">
          <span>Responsável *</span>
          <input
            onChange={handleInputResponsavelNomeChange}
            id="responsavelNome"
            name="responsavelNome"
            value={(responsavelNome || '')}
          />
          {responsavelNomeError && (
            <small style={{ color: 'red' }}>
              Campo obrigatório
            </small>
          )}
        </div>
        <div className="inputHolder">
          <span>Telefone 1 *</span>
          <InputPhoneMask
            type="text"
            onChange={handleInputResponsavelTelefone1Change}
            id="responsavelTelefone1"
            name="responsavelTelefone1"
            value={responsavelTelefone1}
          />
          {responsavelTelefone1Error && (
            <small style={{ color: 'red' }}>
              {telefone1ErrorMessage}
            </small>
          )}
        </div>
        <div className="inputHolder">
          <span>Telefone 2</span>
          <InputPhoneMask
            type="text"
            onChange={handleInputResponsavelTelefone2Change}
            id="responsavelTelefone2"
            name="responsavelTelefone2"
            value={responsavelTelefone2}
          />
          {responsavelTelefone2Error && (
            <small style={{ color: 'red' }}>
              Digite um número válido
            </small>
          )}
        </div>
        <div className="inputHolder defaultFlex noMarginRight">
          <span>Email *</span>
          <input
            onChange={handleInputResponsavelEmailChange}
            id="responsavelEmail"
            name="responsavelEmail"
            value={responsavelEmail}
          />
          {responsavelEmailError && (
            <small style={{ color: 'red' }}>
              {emailErrorMessage}
            </small>
          )}
        </div>
      </div>
      <div className="rowContainer">
        <div className="inputHolder defaultFlex noMarginRight noMarginRight">
          <span>Assunto *</span>
          <input
            onChange={handleInputAssuntoChange}
            id="assunto"
            name="assunto"
            value={assunto}
          />
          {assuntoError && (
            <small style={{ color: 'red' }}>
              Campo obrigatório
            </small>
          )}
        </div>
      </div>
      <div className="rowContainer">
        <div className="inputHolder defaultFlex noMarginRight">
          <span>Justificativa do Cancelamento *</span>
          <textarea
            onChange={handleInputObservacaoChange}
            id="observacao"
            name="observacao"
            value={observacao}
          />
          {observacaoError && (
            <small style={{ color: 'red' }}>
              Campo obrigatório
            </small>
          )}
        </div>
      </div>
    </>
  );
}

ComponentObservation.propTypes = {
  responsavelNome: PropTypes.string,
  responsavelTelefone1: PropTypes.string,
  responsavelTelefone2: PropTypes.string,
  responsavelEmail: PropTypes.string,
  assunto: PropTypes.string,
  observacao: PropTypes.string,
  responsavelNomeError: PropTypes.bool.isRequired,
  responsavelTelefone1Error: PropTypes.bool.isRequired,
  responsavelTelefone2Error: PropTypes.bool.isRequired,
  responsavelEmailError: PropTypes.bool.isRequired,
  assuntoError: PropTypes.bool.isRequired,
  observacaoError: PropTypes.bool.isRequired,
  telefone1ErrorMessage: PropTypes.string.isRequired,
  emailErrorMessage: PropTypes.string.isRequired,
  handleInputResponsavelNomeChange: PropTypes.func.isRequired,
  handleInputResponsavelTelefone1Change: PropTypes.func.isRequired,
  handleInputResponsavelTelefone2Change: PropTypes.func.isRequired,
  handleInputResponsavelEmailChange: PropTypes.func.isRequired,
  handleInputAssuntoChange: PropTypes.func.isRequired,
  handleInputObservacaoChange: PropTypes.func.isRequired,
};

export default ComponentObservation;
