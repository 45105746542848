import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { FORM_ITEM_ASNWER_TYPE as TAGS } from '~/util/domainutils';
import { v4 as uuid_v4 } from 'uuid';
import { ITENS_ANSWER } from './defaultValue';
import { FormMultipleOptions } from '../FormMultipleOptions';
import {
  FormItemType,
  ContainerTextInputs,
  FormActions,
  ButtonAdd,
  SelectOption,
  FormMultipleOptionsContainer,
} from './styles';
import '../styles.scss';

function FormTitle({
  itemTypeId, itemType, form, formItem, setForm, formItemIndex, errorForm,
  setErrorFormItems,
}) {
  const [selectedOption, setSelectedOption] = useState(ITENS_ANSWER[0]);
  const [addOther, setAddOther] = useState(false);
  const [arrOptions, setArrOptions] = useState([
    ({
      id: uuid_v4(),
      itemIndex: 0,
      itemData: {
        label: null,
        value: null,
      },
      other: false,
    }),
    ({
      id: uuid_v4(),
      itemIndex: 1,
      itemData: {
        label: null,
        value: null,
      },
      other: false,
    }),
  ]);

  const titleRef = useRef();
  const answerRef = useRef();
  const descriptionRef = useRef();
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');

  useEffect(() => {
    selectedDefaultFormItem(itemTypeId, formItem, formItemIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selectedDefaultFormItem(
    itemTypeIdDefault, formItemDefault, formItemIndexDefault
  ) {
    const [itemType] = ITENS_ANSWER.filter(item => item.id === itemTypeIdDefault);

    setSelectedOption(itemType);

    if (handleIsTitle()) {
      const itemId = form[formItemIndexDefault].id;
      const { statement, description } = form[formItemIndexDefault].itemData;

      if (itemId === formItemDefault.id) {
        if (statement) setTitle(statement);
        if (description) setDescription(description);
      }
    }

    if (handleIsAnswer()) {
      const itemId = form[formItemIndexDefault].id;
      const {
        statement, description, itemTypeId
      } = form[formItemIndexDefault].itemData;

      if (itemId === formItemDefault.id) {
        if (statement) answerRef.current.value = statement;
        if (description) setDescription(description);
        if (
          itemTypeId === TAGS.MULTIPLE_CHOICE.id
          || itemTypeId === TAGS.SELECTION_BOX.id
        ) {
          if (form[formItemIndex].itemData.itemContent) {
            form[formItemIndex].itemData.itemContent.forEach((item) => {
              if (item.other) setAddOther(true);
            });

            setArrOptions(form[formItemIndex].itemData.itemContent);
          }
        }
      }
    }

    setForm(form);
  }

  function selectedFormItemType(itemTypeSelected) {
    const [itemType] = ITENS_ANSWER.filter(item => item.id === itemTypeSelected.value);

    setSelectedOption(itemType);

    form[formItemIndex].itemData.itemTypeId = itemType.id;

    if (
      itemType.id === TAGS.SHORT_ANSWER.id
      || itemType.id === TAGS.PARAGRAPH.id
    ) {
      form[formItemIndex].itemData.itemContent = null;
    }

    setForm(form);
  }

  function handleIsTitle() {
    return itemType === TAGS.TITLE.tag;
  }

  function handleIsAnswer() {
    return itemType === TAGS.ANSWER.tag;
  }

  function handleRemoveOption(optionId) {
    const [optionRemevedOther] = arrOptions
      .filter(option => option.id === optionId);

    if (optionRemevedOther.other) setAddOther(false);

    const arrOptionsRemeved = arrOptions
      .filter(option => option.id !== optionId);

    setArrOptions(arrOptionsRemeved);

    form[formItemIndex].itemData.itemContent = arrOptionsRemeved;

    setForm(form);
  }

  function handleAddOption(defaultOther = false) {
    const option = {
      id: uuid_v4(),
      itemIndex: formItemIndex,
      itemData: {
        label: null,
        value: null,
      },
      other: defaultOther,
    };

    const optionOption = [];
    const resultWithoutOption = [];

    arrOptions.forEach(option => {
      if (!option.other) {
        resultWithoutOption.push(option);
      } else {
        optionOption.push({
          ...option,
          itemIndex: option.itemIndex + 1,
        });
      }
    });

    const newOption = [...resultWithoutOption, option, ...optionOption];

    setArrOptions(newOption);

    if (defaultOther) setAddOther(true);
  }

  const handleUpdateTitle = useCallback(() => {
    const value = titleRef.current.value;

    setTitle(value);

    let formUpdate = [...form];

    formUpdate[formItemIndex]['itemData']['statement'] = value;

    let errorFormUpdate = [...errorForm];

    if (errorFormUpdate[formItemIndex]) {
      errorFormUpdate[formItemIndex].errorItem = false;

      setErrorFormItems(errorFormUpdate);
    }

    setForm(formUpdate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, setForm, formItemIndex, errorForm]);

  const handleUpdateAnswer = useCallback(() => {
    const value = answerRef.current.value;

    let formUpdated = [...form];

    formUpdated[formItemIndex]['itemData']['statement'] = value;

    let errorFormUpdate = [...errorForm];

    if (errorFormUpdate[formItemIndex]) {
      errorFormUpdate[formItemIndex].errorItem = false;

      if (errorFormUpdate[formItemIndex].errorSubItem.length) {
        const optionTemp = errorFormUpdate[formItemIndex];

        const isOptionError = optionTemp.errorSubItem.map(subItem => {
          return subItem.errorSubItem;
        });

        if (!isOptionError.includes(true)) {
          errorFormUpdate[formItemIndex].errorItem = false;
        } else {
          errorFormUpdate[formItemIndex].errorItem = true;
        }
      }

      setErrorFormItems(errorFormUpdate);
    }

    setForm(formUpdated);
  }, [form, setForm, formItemIndex, errorForm, setErrorFormItems]);

  const handleUpdateAnswerDescription = useCallback(() => {
    const value = descriptionRef.current.value;

    let formUpdated = [...form];

    formUpdated[formItemIndex].itemData.description = value;

    setDescription(value);

    setForm(formUpdated);
  }, [form, setForm, formItemIndex]);

  const handleFieldInputTitleAnswer = () => {
    const inputId = `${handleIsTitle() ? 'title' : 'answer'}-${formItem.id}`;

    return (
      <>
        <label htmlFor={inputId}>
          <b id='subtitle'>{handleIsTitle() ? 'Título *' : 'Pergunta *'}</b>
        </label>
        {handleIsTitle() && (
          <input
            type={'text'}
            ref={titleRef}
            id={inputId}
            name={inputId}
            value={title.toUpperCase()}
            onChange={handleUpdateTitle}
          />
        )}
        {handleIsAnswer() && (
          <input
            type={'text'}
            ref={answerRef}
            id={inputId}
            name={inputId}
            onChange={handleUpdateAnswer}
          />
        )}
      </>
    );
  };

  return (
    <FormItemType>
      <ContainerTextInputs>
        <div className="rowContainer rowAlignItemsEnd">
          <div className={`inputHolder defaultFlex noMarginTop
            ${handleIsTitle() && 'noMarginRight'}
          `}>
            {handleFieldInputTitleAnswer()}
          </div>
          {handleIsAnswer() && (
            <div className="inputHolder noMarginRight">
              <Select
                onChange={item => selectedFormItemType(item)}
                placeholder='Selecione'
                isSearchable={false}
                value={
                  selectedOption ? {
                    value: selectedOption.id,
                    label: (
                      <SelectOption>
                        <img
                          src={selectedOption.icon}
                          alt={selectedOption.value}
                          title={selectedOption.value}
                        />
                        <label>{selectedOption.value}</label>
                      </SelectOption>
                    ),
                  } : null
                }
                options={ITENS_ANSWER
                  .map(item => {
                    return {
                      value: item.id,
                      label: (
                        <SelectOption>
                          <img
                            src={item.icon}
                            alt={item.value}
                            title={item.value}
                          />
                          <label>{item.value}</label>
                        </SelectOption>
                      ),
                    };
                  })
                }
                styles={{
                  container: (provided) => ({
                    ...provided,
                    width: 220,
                    marginBottom: 10
                  }),
                  valueContainer: (provided) => ({
                    ...provided,
                  })
                }}
              />
            </div>
          )}
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex noMarginTop noMarginRight">
            <span id='subtitle'>Descrição</span>
            <textarea
              ref={descriptionRef}
              rows={3}
              type="text"
              name="description"
              value={description}
              onChange={handleUpdateAnswerDescription}
            />
            {handleIsAnswer()
              && (selectedOption.id === 3 || selectedOption.id === 4)
              && (
                <FormMultipleOptionsContainer>
                  <FormMultipleOptions
                    form={form}
                    setForm={setForm}
                    formItemIndex={formItemIndex}
                    options={arrOptions}
                    setOptions={setArrOptions}
                    handleRemoveOption={handleRemoveOption}
                    errorForm={errorForm}
                    setErrorFormItems={setErrorFormItems}
                  />
                  <FormActions>
                    <ButtonAdd
                      type='button'
                      onClick={() => handleAddOption(false)}
                    >
                      {'Adicionar opção'}
                    </ButtonAdd>
                    <label className={addOther ? 'disabled' : ''}>
                      {' '}ou{' '}
                    </label>
                    <ButtonAdd
                      type='button'
                      className={addOther ? 'disabled' : ''}
                      onClick={
                        !addOther
                          ? () => handleAddOption(true)
                          : null
                      }
                    >
                      {'Adicionar "Outro"'}
                    </ButtonAdd>
                  </FormActions>
                </FormMultipleOptionsContainer>
              )
            }
          </div>
        </div>
      </ContainerTextInputs>
    </FormItemType>
  );
}

FormTitle.propTypes = {
  itemTypeId: PropTypes.number,
  itemType: PropTypes.string,
  form: PropTypes.array,
  errorForm: PropTypes.array,
  formItem: PropTypes.object,
  setErrorFormItems: PropTypes.func,
  setForm: PropTypes.func,
  formItemIndex: PropTypes.number,
};

export { FormTitle };
