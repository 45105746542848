/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';

import { CALENDARIO_MESES } from '~/util/domainutils';
import { Container } from '../styles';

export default function Maintenance({ maintenance }) {
  const month = useMemo(
    () =>
      CALENDARIO_MESES.find(item => item.value === maintenance.mesInicio).label,
    [maintenance.mesInicio]
  );

  return (
    <Container>
      <div>
        <span>Início da Manutenção:</span>
        <span>{month}</span>
      </div>
    </Container>
  );
}
