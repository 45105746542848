import { actionTypes } from './actions';

const INITIAL_STATE = {
  token: null,
  signed: false,
  loading: false,
};

export default function (state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.AUTH_CHANGE_LOADING:
      return {
        ...state,
        loading: payload.isLoading,
      };
    case actionTypes.AUTH_SIGN_IN_REQUEST_MFA:
      return {
        ...state,
        signed: false,
        loading: true,
      };
    case actionTypes.AUTH_SIGN_IN_REQUEST_SSO:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.AUTH_SIGN_IN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: false,
        signed: true,
        token: payload.token,
      };
    case actionTypes.AUTH_SIGN_IN_FAILURE:
      return {
        ...state,
        loading: false,
        signed: false,
        token: null,
      };
    case actionTypes.AUTH_SIGN_OUT:
      return {
        ...state,
        loading: false,
        signed: false,
        token: null,
      };
    default:
      return state;
  }
}
