export const schemaChave = {
  chave: 'XXXXXX.XXXXXX.XXXXXX.XXXXXX.XXXXXX.XXXXXX.XXXXXX',
  prazo: '',
  geradaEm: '',
  geradaPor: '',
  versao: '',
  controle: '',
};

export const schemaGerarChave = {
  cliente: {
    razaoSocial: '',
    cpfCnpj: '',
    nomeFantasia: '',
    email: '',
    endereco: '',
  },
  software: {
    titulo: '',
    serie: '',
    versao: '',
    controle: '',
  },
};

export const schemaConfiguracoes = {
  params: {
    ativarChave: '',
    gerarCom: '',
    mensagemAtual: '',
  },
  values: {
    controle: '',
    serie: '',
    codigoSoftware: '',
    versao: '',
    cnpj: '',
    prazo: '',
  },
};
