import PropTypes from 'prop-types';
import React from 'react';
import {
  FilterItemReport,
  FilterItemsReportContainer,
  FiltersReportContainer,
  FilterItemsReport,
} from './styles';

const FiltersReport = ({ filters }) => {
  return (
    <FiltersReportContainer>
      {filters
        && filters.reduce((prev, item) => {
          if (item.value) return [...prev, item];
          return [...prev];
        }, []).length > 0
        && (
          <FilterItemsReport>
            <h3>Filtros:</h3>
            <FilterItemsReportContainer>
              {filters.reduce((prev, filter, index) => {
                if (filter.value)
                  return [
                    ...prev,
                    <FilterItemReport key={`filters-report-${index}-${filter.value}`}>
                      <span>
                        <strong>{filter.name}: </strong>{filter.value}
                      </span>
                    </FilterItemReport>,
                  ];
                return [...prev];
              }, [])}
            </FilterItemsReportContainer>
          </FilterItemsReport>
        )}
    </FiltersReportContainer>
  );
};

FiltersReport.propTypes = {
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string,
    })
  ).isRequired,
};

export default FiltersReport;
