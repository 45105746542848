import PropTypes from 'prop-types';
import { FaRegEdit, FaRegTrashAlt } from 'react-icons/fa';
import TableCell from '@material-ui/core/TableCell';
import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import api, { endpoints } from '~/services/api';
import permissions from '~/util/permissions';
import { accessDenied } from '~/util/accessDenied';
import PrintableComponent from '~/components/PrintableComponent';
import SimpleTextSearch from '~/components/SimpleTextSearch';
import TableComponent from '~/components/TableComponent';
import { showMessageError } from '~/util/errorutils';
import { formPaths } from '~/routes/paths';
import { useFitler } from '~/hook/useFilter';
import {
  ContainerAddRegister,
  Container,
  ModalContainer,
  CancelButton,
  DeleteButton,
} from '../styles';

function FormRegister({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(false);
  const [inputFormName, setInputFormName] = useState(filters?.inputFormName ? filters?.inputFormName : '');
  const [deleteItem, setDeleteItem] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [searchResponse, setSearchResponse] = useState([]);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions['create_form-register']),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions['edit_form-register']),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions['delete_form-register']),
    [userPermissions]
  );

  let PrintableElement = React.useRef();

  useEffect(() => {
    findAllByFilter(pageParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleInputFormNameChange(event) {
    const {
      target: { value },
    } = event;

    setInputFormName(value);
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  async function findAllByFilterReport(pageParams) {
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const { data: { data: response } } = await api.post(
        endpoints.form.register.getForms,
        {
          name: inputFormName.trim(),
          limit: page * rowsPerPage + rowsPerPage,
          offset: page * rowsPerPage,
        }
      );
      const result = response.resultado;
      if (result.length) {
        handleSetSearchResponseReport(result);
        setTotalItems(response.total);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos Formulários para esta pesquisa', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      if (inputFormName.length) { setCountFilter(1); } else { setCountFilter(0); }
      const { page, rowsPerPage } = pageParams;
      const { data: response } = await api.post(
        endpoints.form.register.getForms,
        {
          name: inputFormName.trim(),
          limit: page * rowsPerPage + rowsPerPage,
          offset: page * rowsPerPage,
        }
      );
      if (response.data.resultado.length) {
        handleSetSearchResponse(response.data.resultado, response.data.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos nenhum formulário para essa pesquisa', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setFilters({ inputFormName });

    await findAllByFilter(pageParameters);
  }

  async function handleDelete(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const { data: response } = await api.delete(
        endpoints.form.register.deleteForm(deleteItem.id)
      );
      const newSearchResponse = searchResponse.filter(
        resp => resp.id !== deleteItem.id
      );
      handleSetSearchResponse(newSearchResponse, totalItems - 1);
      toast(response.message, { type: toast.TYPE.SUCCESS });
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o formulário');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  return (
    <ContainerAddRegister>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Formulário"
        handlePageUpdate={findAllByFilterReport}
        filters={[{ name: 'Formulário', value: inputFormName }]}
        headerLabels={[
          { text: 'Nome', width: '42%' },
          { text: 'Módulo', width: '28%' },
          { text: 'Software', width: '22%' },
        ]}
        dataObjects={searchResponseReport}
        renderRowComponent={(row) => [
          <TableCell key='row_form_register_print_0'>
            {row.name}
          </TableCell>,
          <TableCell key='row_form_register_print_1'>
            {row.moduleName}
          </TableCell>,
          <TableCell key='row_form_register_print_2'>
            {row.softwareName}
          </TableCell>,
        ]}
      />
      <Modal loading={loading} show={loading || !!deleteItem}>
        <ModalContainer>
          <h2>Confirmar exclusão</h2>
          <span>
            Tem certeza que deseja excluir o formulário?
            <br />
            <b>
              {
                `${!!deleteItem && (
                  (!!deleteItem.name && deleteItem.name) || deleteItem.id
                )}`
              }
            </b>
          </span>
          <div>
            <CancelButton onClick={() => setDeleteItem(null)}>
              Fechar
            </CancelButton>
            <DeleteButton onClick={handleDelete}>Sim</DeleteButton>
          </div>
        </ModalContainer>
      </Modal>
      <Container>
        <SidebarPageHeader
          mainMenu="Formulário"
          pageName="Cadastro"
          button1={{
            label: 'Novo Formulário',
            link: formPaths.addFormRegister,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async (data) => {
            await findAllByFilterReport(pageParameters, data);
          }}
          openButtonSelect
        />
        <SimpleTextSearch
          countFilter={countFilter}
          fieldLabel="Formulário"
          inputValue={inputFormName}
          handleInputChange={handleInputFormNameChange}
          handleSubmit={handleSubmit}
        />
        <TableComponent
          headerLabels={[
            { text: 'Nome', width: '42%' },
            { text: 'Módulo', width: '28%' },
            { text: 'Software', width: '20%' },
            { text: 'Ação', width: '10%', align: 'center' },
          ]}
          dataObjects={searchResponse}
          renderRowComponent={(row) => [
            <TableCell key='row_form_register_0'>
              {row.name}
            </TableCell>,
            <TableCell key='row_form_register_1'>
              {row.moduleName}
            </TableCell>,
            <TableCell key='row_form_register_2'>
              {row.softwareName}
            </TableCell>,
            <TableCell key='row_form_register_3' className="actionCell">
              <div className="actionButtonsHolder">
                <FaRegEdit
                  className={`${userCanEdit ? 'icon' : 'icon-disabled'}`}
                  disabled={!userCanEdit}
                  onClick={userCanEdit
                    ? () => history.push(formPaths.editFormRegister, {
                      id: row.id, isFirstRefreshToForm: true,
                    })
                    : () => accessDenied(history)
                  }
                  title="Editar"
                />
                <hr />
                <FaRegTrashAlt
                  className={`${userCanDelete ? 'icon' : 'icon-disabled'}`}
                  disabled={!userCanDelete}
                  onClick={userCanDelete
                    ? () => setDeleteItem(row)
                    : () => accessDenied(history)
                  }
                  title="Deletar"
                />
              </div>
            </TableCell>,
          ]}
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
          useCustomActions
        />
      </Container>
    </ContainerAddRegister>
  );
}

FormRegister.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default FormRegister;
