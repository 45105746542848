import { Fragment } from 'react';
import styled from 'styled-components';

export const FormAnswerContainer = Fragment;

export const FormActions = styled.div`
  color: var(--cm-primary);
  padding: 0 0 10px 10px;
`;

export const ButtonAdd = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  color: var(--cm-primary);
  text-decoration: underline;
`;
