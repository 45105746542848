import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '~/components/Modal';
import { showMessageError } from '~/util/errorutils';
import api, { endpoints } from '~/services/api';
import { UserInfoClient } from './UserInfoClient';
import { UserInfoResale } from './UserInfoResale';
import * as S from './styles';

function FormClientResale(props) {
  const {
    clientId,
    resaleId,
    formPersons,
    showData = false,
    withBorder = false,
    showDefaultData = false,
  } = props;

  const [loading, setLoading] = useState(false);

  const [dataClient, setDataClient] = useState({
    nomeSocial: 'Cliente LTDA',
    razaoSocial: 'Cliente LTDA',
    nomeFantasia: 'Cliente',
    cpfCnpj: 'XX-XXX.XXX/XXXX-XX',
    cidade: 'Nome Cidade',
    estado: 'UF',
    telefone: '(XX) X.XXXX-XXXX',
    email: 'cliente@email.com',
  });

  const [dataResale, setDataResale] = useState({
    nomeFantasia: 'Revenda',
    razaoSocial: 'Revenda',
    nomeSocial: 'Nome Responsável',
    telefone: '(XX) X.XXXX-XXXX',
    email: 'revenda@email.com',
  });

  useEffect(() => {
    const IS_EXIST_PERSON = !!formPersons;

    if (IS_EXIST_PERSON) {
      setClientResale(formPersons);
    } else if (!showDefaultData) {
      const asyncEffect = async () => {
        setLoading(true);
        try {
          const {
            data: { data: resPersonForms }
          } = await api.post(endpoints.person.personForms, {
            ids: [clientId, resaleId]
          });

          setClientResale(resPersonForms);
        } catch (error) {
          showMessageError(error);
        } finally {
          setLoading(false);
        }
      };

      asyncEffect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function setClientResale(data) {
    setDataClient({
      nomeSocial: data.cliente.nomeSocial,
      razaoSocial: data.cliente.razaoSocial,
      nomeFantasia: data.cliente.nomeFantasia,
      cpfCnpj: data.cliente.cpfCnpj,
      cidade: data.cliente.cidade,
      estado: data.cliente.estado,
      telefone: data.cliente.telefone,
      email: data.cliente.email,
    });

    setDataResale({
      razaoSocial: data.revenda.razaoSocial,
      nomeFantasia: data.revenda.nomeFantasia,
      nomeSocial: data.criadorPedido.nomeSocial
        || data.criadorPedido.razaoSocial
        || data.criadorPedido.nomeFantasia,
      telefone: data.criadorPedido.telefone,
      email: data.criadorPedido.email,
    });
  }

  return (
    <S.FormClientResaleContainer withBorder={withBorder}>
      <S.ClientResaleContent noMarginBottom={withBorder}>
        <Modal show={loading} loading />
        <S.DataContainer>
          <label className='title'>DADOS DO CLIENTE</label>
          <S.DataContent>
            {showData ? (
              <S.UserInfoContent>
                <S.UserInfo>
                  <UserInfoClient
                    nomeSocial={dataClient.nomeSocial}
                    razaoSocial={dataClient.razaoSocial}
                    nomeFantasia={dataClient.nomeFantasia}
                    cpfCnpj={dataClient.cpfCnpj}
                    cidade={dataClient.cidade}
                    estado={dataClient.estado}
                    telefone={dataClient.telefone}
                    email={dataClient.email}
                  />
                </S.UserInfo>
              </S.UserInfoContent>
            ) : (
              <S.UserInfo>
                <UserInfoClient />
              </S.UserInfo>
            )}
          </S.DataContent>
        </S.DataContainer>
        <S.Divider className='divider' />
        <S.DataContainer>
          <label className='title'>DADOS DA REVENDA</label>
          <S.DataContent>
            {showData ? (
              <S.UserInfoContent>
                <S.UserInfo>
                  <UserInfoResale
                    nomeFantasia={dataResale.nomeFantasia}
                    razaoSocial={dataResale.razaoSocial}
                    responsavel={dataResale.nomeSocial}
                    telefone={dataResale.telefone}
                    email={dataResale.email}
                  />
                </S.UserInfo>
              </S.UserInfoContent>
            ) : (
              <S.UserInfo>
                <UserInfoResale />
              </S.UserInfo>
            )}
          </S.DataContent>
        </S.DataContainer>
      </S.ClientResaleContent>
      {!showData && (
        <label className='footer'>
          Estes valores aparecerão automaticamente quando o formulário for requisitado no pedido.
        </label>
      )}
    </S.FormClientResaleContainer>
  );
}

FormClientResale.propTypes = {
  clientId: PropTypes.number,
  resaleId: PropTypes.number,
  showData: PropTypes.bool,
  showDefaultData: PropTypes.bool,
  withBorder: PropTypes.bool,
  formPersons: PropTypes.object,
};

export { FormClientResale };
