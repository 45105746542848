import { Fragment } from 'react';
import styled from 'styled-components';

export const HomeContainer = Fragment;

export const Container = styled.div`
  flex: 1;

  &.homeContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #F9F9F9;

    .homeHeader {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: var(--cm-primary);
      margin-bottom: -42px;
      border: 0;

      .welcomeText {
        font-size: 36pt;
        font-weight: bold;
      }

      .subText {
        font-size: 16pt;
        font-weight: 400;
      }

      @media screen and (max-width: 450px) {
        .welcomeText {
          font-size: 30pt;
        }

        .subText {
          font-size: 12pt;
        }
      }
    }

    .welcomeImg {
      width: auto;
      height: 400px;
      padding: 42px;
    }
  }
`;
