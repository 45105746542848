import styled from 'styled-components';

export const AttachmentsContainer = styled.div`
  display: flex;
  height: 50px;
  width: 168px;
  border-radius: 8px;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cm-primary);
  margin: 20px 20px 0 0;

  margin-right: ${({ isMarginRight }) => isMarginRight ? 5 : 20};
  background-color: ${({ customBgColor }) => customBgColor || 'none'};

  span {
    max-width: ${({ isDownload }) => `calc(100% - ${isDownload ? 48 : 20}px)`};
    display: -webkit-box;
    overflow: hidden; /* remove o estouro do elemento */
    text-overflow: ellipsis; /* adiciona “...” no final */
    -webkit-line-clamp: 2; /* quantidade de linhas */
    -webkit-box-orient: vertical; /* conteúdo será apresentado verticalmente */
    cursor: default;
  }

  svg {
    color: #0000008A;
    cursor: pointer;
  }

  svg + svg {
    margin-left: 8px;
  }
`;

export const Tooltip = styled.div``;

export const Actions = styled.div`
  width: auto;
  display: flex;
  justify-content: center;
`;
