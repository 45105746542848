import styled, { createGlobalStyle } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-width: 100%;
`;

export const RoutesContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 90%;
  height: 100%;
`;

export const PageHeader = styled.div`
  padding: 2% 0px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #c9c7c7;
  border-style: solid;

  span {
    color: var(--cm-primary);
    font-size: 22px;
    @media (max-width: 450px) {
      font-size: 14px;
    }
    @media (max-width: 900px) {
      font-size: 16px;
    }
  }
`;

export const ResultsHeader = styled.div`
  padding: 15px 0px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #c9c7c7;
  border-style: solid;

  span {
    font-size: 22px !important;
    color: #9f9f9f !important;

    @media (max-width: 900px) {
      font-size: 14px !important;
    }
  }
`;

export const RowContainer = styled.div`
  padding: 0 !important;
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding-left: 15px;

    &:first-child {
      padding-left: 0;
    }

    @media (max-width: 600px) {
      padding-left: 0;
    }
  }
`;

export const MQL_MATCH_MEDIA = 800;

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@400;700&display=swap');

  :root {
    --cm-primary: #002850;
    --cm-primary-contrast: #ffffff;
    --cm-primary-shade: #002346;
    --cm-primary-tint: #1a3e62;

    --cm-primary-5: #0028500D;
    --cm-primary-40: #00285066;

    --cm-secondary: #32f032;
    --cm-secondary-contrast: #002850;
    --cm-secondary-shade: #2cd32c;
    --cm-secondary-tint: #47f247;

    --cm-secondary-tint-20: #32F03233;

    --cm-tertiary: #ffffff;
    --cm-tertiary-contrast: #002850;
    --cm-tertiary-shade: #e0e0e0;
    --cm-tertiary-tint: #ffffff;

    --cm-success: #32F032;
    --cm-success-contrast: #002850;
    --cm-success-shade: #2cd32c;
    --cm-success-tint: #47f247;

    --cm-warning: #FF7800;
    --cm-warning-contrast: #000000;
    --cm-warning-shade: #e06a00;
    --cm-warning-tint: #ff861a;

    --cm-warning-20: #FF780033;

    --cm-danger: #C70E0E;
    --cm-danger-contrast: #ffffff;
    --cm-danger-shade: #af0c0c;
    --cm-danger-tint: #cd2626;

    --cm-info: #264FEC;
    --cm-info-contrast: #ffffff;
    --cm-info-shade: #2146d0;
    --cm-info-tint: #3c61ee;

    // toasts
    --toast-bg-success-blue: #2241d6;
    --toast-bg-success-green: #4CAF50;

    // texts
    --text-black-main: #000000;
    --text-black-high: #444444;
    --text-black-low: #707070;

    --cm-font-famaly: 'Lexend Deca', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: var(--cm-font-famaly);
    max-width: 100%
  }

  .MuiTableCell-root {
    font-family: var(--cm-font-famaly) !important;
  }

  html, body, #root {
    display: flex;
    flex: 1;
    background-color: #fff;
    min-height: 100%;
    max-width: 100%
  }

  body {
    -webkit-font-smoothing: antialiased !important;
    text-rendering: optimizeLegibility !important;
  }

  button {
    cursor: pointer;
  }

  button#baseButtonIcon {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 33px;
    min-width: 120px;
    margin-bottom: 10px;

    #baseNameIcon {
      font-size: 10pt;
      margin-right: 10px;
    }
  }

  .buttonBaseOnly {
    height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    padding: 5px 15px;
    border-radius: 4px;
    border: 1px solid var(--cm-primary-shade);
    font-size: 12pt;
    font-weight: bold;
    color: var(--cm-tertiary-contrast);
    background-color: var(--cm-tertiary);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    @media (max-width: 450px) {
      font-size: 12px;
      padding: 6px 15px;
      text-align: center;
    }

    &.baseMain {
      font-weight: normal;
      border: 1px solid var(--cm-primary-shade);
      color: var(--cm-primary-contrast);
      background-color: var(--cm-primary);
    }

    &.addMargin {
      margin-right: 30px;
    }
  }

  .buttonBase {
    height: 33px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px 15px;
    border-radius: 4px;
    border: 1px solid var(--cm-primary-shade);
    font-size: 12pt;
    font-weight: bold;
    color: var(--cm-tertiary-contrast);
    background-color: var(--cm-tertiary);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    @media (max-width: 450px) {
      font-size: 12px;
      padding: 6px 15px;
      text-align: center;
    }

    &.addMargin {
      margin-right: 30px;

      @media (max-width: 799px) {
        margin-right: 0;
      }
    }

    &.baseMain {
      font-weight: normal;
      border: 1px solid var(--cm-primary-shade);
      color: var(--cm-primary-contrast);
      background-color: var(--cm-primary);

      @media (max-width: 799px) {
        font-weight: bold;
        height: 37px;
        border: none;
        color: #444444;
        background-color: #FFFFFF;
      }
    }

    &.baseReport {
      border: 1px solid var(--cm-tertiary-contrast);
      color: var(--cm-tertiary-contrast);
      background-color: var(--cm-tertiary);
      margin-right: 30px;

      @media (max-width: 799px) {
        font-weight: bold;
        margin-right: 0;
        height: 37px;
        border: none;
        color: #444444;
        background-color: #FFFFFF;
      }
    }

    &.baseWebMobile {
      @media (max-width: 799px) {
        width: 185px;
        border-radius: 0;
        margin-right: 0;

        height: 37px;
        border: none;
        background-color: #FFFFFF;

        text-align: left;
        font-weight: bold;
        padding-left: 12px;
        margin-bottom: 1px solid #DADAE3;
        border: none;
        color: #444444;
        margin-right: 0;
      }
    }
  }

  form {
    background: #ececec;
    margin: 10px 0px;
    padding: 15px;
    border-width: 1px;
    border-color: #d2d2d2;
    border-style: solid;
  }

  input, textarea {
    background: #fff;
    color: #707070;
    width: 100%;
    margin: 10px 0px;
    padding: 13px 25px;
    font-size: 14px;
    border: none;
  }

  input[type='checkbox'] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    background: #fff;
    color: #707070;
    width: 20px !important;
    border: none;
    padding: 10px;
    margin: 5px 10px;
  }

  input[type='radio'] {
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.5); /* IE */
    -moz-transform: scale(1.5); /* FF */
    -webkit-transform: scale(1.5); /* Safari and Chrome */
    -o-transform: scale(1.5); /* Opera */
    transform: scale(1.5);
    background: #fff;
    color: #707070;
    width: 15px;
    border: none;
    padding: 0;
    margin: 5px 10px;
  }

  input[type='radio'], input[type='checkbox'] {
    accent-color: var(--cm-primary);
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    background-color: transparent;
  }

  select {
    background: #fff;
    color: #707070;
    width: 100%;
    margin: 10px 0px;
    padding: 13px 25px;
    font-size: 14px;
    border: none;
  }

  nav {
    position: sticky;
    top: 0;
  }

  .stepper-container {
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center !important;

    .stepper-item {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      align-items: center;
      max-width: 95%;

      .stepper-item-inner {
        width: 22px;
        height: 22px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: small;
      }
    }

    .stepper-title {
      margin: 1px 1px 1px 5px;
      max-width: 95%;

      @media (max-width: 1580px) {
        max-width: 80%;
      }

      @media (max-width: 1150px) {
        max-width: 75%;
      }

      font-size: 14px;
      color: #b2b5b6;
    }
    .stepper-title-active {
      font-weight: bold;
      color: black;
    }
    .stepper-item-outer {
      width: 95%;
      display: flex;
      align-items: center;
    }
  }

  .stepper-item-outer:nth-of-type(even){
    height: 0px;
    border: 0.5px solid #b2b5b6;
    max-width: 15%;

    @media (max-width: 450px) {
      max-width: 5%;
    }

    @media (max-width: 1150px) {
      width: 10%;
    }
  }

  .stepper-item-inner-future {
    background-color: #b2b5b6;
  }
  .stepper-item-inner-completed {
    background-color: #7fc8ef;
  }
  .stepper-item-inner-active {
    background-color: #007cc3;
  }

  .right { text-align: right; }
`;
