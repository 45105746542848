import { actionTypes as authTypes } from '../auth/actions';
import { actionTypes as userTypes } from './actions';

const INITIAL_STATE = {
  data: {
    permissions: [],
  },
};

export default function (state = INITIAL_STATE, action) {
  const { type, payload } = action;

  switch (type) {
    case userTypes.USER_UPDATE_DATA:
      return {
        ...state,
        data: payload.data,
      };
    case authTypes.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        data: payload.userInfo,
      };
    case authTypes.AUTH_SIGN_OUT:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
}
