import React from 'react';
import { withRouter } from 'react-router-dom';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import marcaTaglineImg from
  '~/assets/marca/tagline/SVG/CM-marca_tagline-positiva-RGB.svg';
import * as S from './styles';

function Home() {
  return (
    <S.HomeContainer>
      <SidebarPageHeader mainMenu="Início" pageName="Página Inicial" />
      <S.Container className="homeContainer">
        <div className="homeHeader">
          <span className="welcomeText">
            Boas vindas!
          </span>
          <span className="subText">
            Selecione um item do menu para começar.
          </span>
        </div>
        <img
          className="welcomeImg"
          src={marcaTaglineImg}
          alt="Marca Com Tagline Positiva RGB Casa Maganhães"
          loading='lazy'
        />
      </S.Container>
    </S.HomeContainer>
  );
}

export default withRouter(Home);
