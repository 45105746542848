import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import {
  FaRegEdit,
  FaRegTrashAlt,
  FaUserLock,
  FaTimesCircle,
} from 'react-icons/fa';
import { FiUnlock } from 'react-icons/fi';
import Modal, { CustomModal } from '~/components/Modal';
import PrintableComponent from '~/components/PrintableComponent';
import { person } from '~/routes/paths';
import { USER_TYPE, PERSON_STATUS_ENUM } from '~/util/domainutils';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import { InvitationService } from '~/services/person/invitation';
import PersonEmployeeService from '~/services/person-employee-service';
import { accessDenied } from '~/util/accessDenied';
import { formatCpfCnpj, inputNumber } from '~/util/stringutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';
import PersonSearchComponent from '../SearchComponent';
import { useFitler } from '~/hook/useFilter';
import {
  CancelButton,
  Container,
  DeleteButton,
  ModalContainer,
} from './styles';

function PersonEmployee({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(false);
  const [inputCode, setInputCode] = useState('');

  const [inputColaborador, setInputColaborador] = useState('');
  const [inputRevenda, setInputRevenda] = useState('');

  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [searchResponse, setSearchResponse] = useState([]);
  const [notBlockedItem, setNotBlockedItem] = useState(null);
  const [blockedItem, setBlockedItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [deleteInviteItem, setDeleteInviteItem] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    id: null,
    textPessoa1: '',
    textPessoa2: '',
  });

  let PrintableElement = React.useRef();

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanViewColumnRevenda = useMemo(
    () => userPermissions.includes(permissions['view_coluna-revenda']),
    [userPermissions]
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions.create_colaborador),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions.edit_colaborador),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions.delete_colaborador),
    [userPermissions]
  );

  const userCanDeleteInvite = useMemo(
    () => userPermissions.includes(permissions['delete_convite-pessoa']),
    [userPermissions]
  );

  useEffect(() => {
    findAllByFilter(pageParameters, handleFilters());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilters() {
    let count = 0;

    if (filters.id) {
      setInputCode(filters.id);
      count += 1;
    }
    if (filters.textPessoa1) {
      setInputColaborador(filters.textPessoa1);
      count += 1;
    }
    if (filters.textPessoa2) {
      setInputRevenda(filters.textPessoa2);
      count += 1;
    }

    setCountFilter(count);

    return filters;
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  function handleInputCodeChange(event) {
    const {
      target: { value },
    } = event;

    setInputCode(inputNumber(value));
  }

  function handleInputColaboradorChange(event) {
    const {
      target: { value },
    } = event;

    setInputColaborador(value);
  }

  function handleInputRevendaChange(event) {
    const {
      target: { value },
    } = event;

    setInputRevenda(value);
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};

    if (inputCode.length) {
      data.id = inputCode.trim();
      count += 1;
    }

    if (inputColaborador.length) {
      data.textPessoa1 = inputColaborador.trim();
      count += 1;
    }

    if (inputRevenda.length) {
      data.textPessoa2 = inputRevenda.trim();
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    setFilters(data);
    await success(data);
  }

  async function findAllByFilterReport(pageParams, formDataParam) {
    setLoading(true);
    try {
      const { data: response } = await api.post(
        endpoints.person.employee.getPersonEmployees,
        {
          ...formDataParam,
        }
      );
      const { data } = response;
      if (data.resultado.length) {
        handleSetSearchResponseReport(data.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos colaboradores para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams, data) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      const { page, rowsPerPage: limit } = pageParams;
      const { data: response } = await api.post(
        endpoints.person.employee.getPersonEmployees,
        {
          ...(data || formData),
          limit,
          offset: page * limit,
        }
      );
      if (response.data.resultado.length) {
        handleSetSearchResponse(response.data.resultado, response.data.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos colaboradores para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  async function handleDelete(event) {
    event.preventDefault();
    try {
      setLoading(true);

      const { data: response } = await api.delete(
        endpoints.person.employee.deletePersonEmployee(deleteItem.id)
      );

      if (!response.data.ok)
        toast('Erro ao deletar o Colaborador!', {
          type: toast.TYPE.ERROR,
        });
      else {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteItem.id
        );

        handleSetSearchResponse(newSearchResponse, totalItems - 1);
        toast(response.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o Colaborador!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  const renderRowComponent = (row, print = false) => {
    const IS_NOT_BLOCKED = () => {
      return row.status === PERSON_STATUS_ENUM.NOT_BLOCKED.value;
    };

    let renderEmployee = [
      <TableCell key='row_nome_social_employee_1'>
        {row.nomeSocial || '---'}
      </TableCell>,
      <TableCell key='row_cpf_cnpj_employee_2'>
        {row.formatedCpfCnpj || '---'}
      </TableCell>,
    ];

    if (userCanViewColumnRevenda) {
      renderEmployee = [
        <TableCell key='row_nome_social_employee_3'>
          {row.nomeSocial || '---'}
        </TableCell>,
        <TableCell key='row_cpf_cnpj_employee_4'>
          {row.formatedCpfCnpj || '---'}
        </TableCell>,
        <TableCell key='row_nome_revenda_5'>
          {row.revenda.nomeFantasia || row.revenda.razaoSocial || '---'}
        </TableCell>,
        <TableCell key='row_cpf_cnpj_revenda_6'>
          {formatCpfCnpj(row.revenda.cpfCnpj) || '---'}
        </TableCell>,
      ];
    }

    let renderActions = [(
      <TableCell
        key='row_actions_employee_0'
        className="actionCell"
        style={{ width: '150px' }}
      >
        <div className="actionButtonsHolder">
          {IS_NOT_BLOCKED() ? (
            <FiUnlock
              className="icon"
              onClick={() => setNotBlockedItem(row)}
              title="Desativar"
            />
          ) : (
            <FaUserLock
              className="icon"
              onClick={() => setBlockedItem(row)}
              title="Ativar"
            />
          )}
          <hr />
          <FaRegEdit
            className="icon"
            onClick={() => {
              return userCanEdit
                ? history.push(person.editPersonEmployee, {
                  id: row.id,
                  revendaId: row.revendaId,
                })
                : accessDenied(history);
            }}
            title="Editar"
          />
          <hr />
          <FaRegTrashAlt
            className="icon"
            onClick={() => {
              return userCanDelete
                ? setDeleteItem(row)
                : accessDenied(history);
            }}
            title="Deletar"
          />
        </div>
      </TableCell>
    )];

    if (row.tipo === USER_TYPE.CONVITE.COLABORADOR) {
      renderActions = [(
        <TableCell
          key='row_actions_employee_0'
          className="actionCell"
          style={{ width: '150px' }}
        >
          <div className="actionButtonsHolder buttonCenterHolder">
            <FaTimesCircle
              className="icon"
              onClick={() => {
                return userCanDeleteInvite
                  ? setDeleteInviteItem(row)
                  : accessDenied(history);
              }}
              title="Deletar Convite"
            />
          </div>
        </TableCell>
      )];
    }

    if (print) {
      return [
        <TableCell key='row_id_employee_0'>
          {row.id}
        </TableCell>,
        ...renderEmployee,
      ];
    }

    return [
      <TableCell key='row_id_employee_0'>
        {row.id}
      </TableCell>,
      ...renderEmployee,
      ...renderActions,
    ];
  };

  const headerLabels = () => {
    if (userCanViewColumnRevenda) {
      return [
        { text: 'Código', width: '10%' },
        { text: 'Colaborador', width: 'auto' },
        { text: 'CPF / CNPJ', width: '18%' },
        { text: 'Revenda', width: '20%' },
        { text: 'CPF / CNPJ', width: '18%' },
      ];
    }

    return [
      { text: 'Código', width: '10%' },
      { text: 'Nome / Fantasia', width: 'auto' },
      { text: 'CPF / CNPJ', width: '20%' },
    ];
  };

  const renderFilters = () => {
    if (userCanViewColumnRevenda) {
      return [
        { name: 'Código', value: inputCode },
        { name: 'Colaborador', value: inputColaborador },
        { name: 'Revenda', value: inputRevenda },
      ];
    }

    return [
      { name: 'Código', value: inputCode },
      { name: 'Revenda', value: inputRevenda },
    ];
  };

  async function handleStatusUser(event) {
    event.preventDefault();
    try {
      setLoading(true);

      let params = { id: null, status: null };

      if (notBlockedItem) {
        params = {
          id: notBlockedItem.id,
          status: PERSON_STATUS_ENUM.BLOCKED.value,
        };
      }

      if (blockedItem) {
        params = {
          id: blockedItem.id,
          status: PERSON_STATUS_ENUM.NOT_BLOCKED.value,
        };
      }

      const response = await PersonEmployeeService
        .updatePersonEmployeeStatus(params.id, params.status);

      if (!response.data.ok) {
        toast.error('Erro ao alterar status do Colaborador!');
        setLoading(false);
      } else {
        const newSearchResponse = searchResponse.map(resp => {
          if (resp.id === params.id) {
            return {
              ...resp,
              status: params.status,
            };
          }
          return resp;
        });

        handleSetSearchResponse(newSearchResponse, totalItems);
        toast(response.message, { type: toast.TYPE.SUCCESS });
        setLoading(false);
      }
    } catch (error) {
      showMessageError(error, 'Erro ao alterar status do Colaborador!');
    } finally {
      if (notBlockedItem) setNotBlockedItem(null);
      if (blockedItem) setBlockedItem(null);
      setLoading(false);
    }
  }

  async function handleDeleteInvite(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const result = await InvitationService
        .deletePersonInvitation(deleteInviteItem.cpfCnpj);

      if (result.ok) {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteInviteItem.id
        );

        handleSetSearchResponse(newSearchResponse, totalItems - 1);
        toast(result.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
      setDeleteInviteItem(null);
    }
  }

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Colaborador"
        handlePageUpdate={findAllByFilterReport}
        filters={renderFilters()}
        headerLabels={headerLabels()}
        dataObjects={searchResponseReport.map(item => {
          item.formatedCpfCnpj = formatCpfCnpj(item.cpfCnpj);
          item.telefone1 = item.contato.telefone1;
          return item;
        })}
        renderRowComponent={(row) => renderRowComponent(row, true)}
      />
      <Container>
        <SidebarPageHeader
          mainMenu="Pessoa"
          pageName="Colaborador"
          button1={{
            label: 'Novo Colaborador',
            link: person.addPersonEmployee,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await validateForm(async data => {
              await findAllByFilterReport(pageParameters, data);
            });
          }}
          openButtonSelect
        />
        <PersonSearchComponent
          handleSubmit={handleSubmit}
          countFilter={countFilter}
          userType={USER_TYPE.COLABORADOR}
          inputPessoa1Value={inputColaborador}
          handlePessoa1Change={handleInputColaboradorChange}
          inputPessoa2Value={inputRevenda}
          handlePessoa2Change={handleInputRevendaChange}
          codeValue={inputCode}
          handleCodeChange={handleInputCodeChange}
          noFieldStatus={true}
          noFieldStatusChave={true}
          userCanViewColumnRevenda={userCanViewColumnRevenda}
        />
        <TableComponent
          headerLabels={[
            ...headerLabels(),
            {
              text: 'Ação',
              width: '150px',
              align: 'center',
            }
          ]}
          dataObjects={searchResponse.map(item => {
            item.formatedCpfCnpj = formatCpfCnpj(item.cpfCnpj);
            item.telefone1 = item.contato.telefone1;
            return item;
          })}
          renderRowComponent={renderRowComponent}
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
          useCustomActions
        />
        <Modal loading={loading}
          show={loading
            || !!deleteItem
            || !!notBlockedItem
            || !!blockedItem
            || !!deleteInviteItem
          }
        >
          <CustomModal>
            {(!!deleteItem) && (
              <ModalContainer>
                <h2>Confirmar exclusão</h2>
                <span>
                  Tem certeza que deseja deletar a colaborador:
                  {` ${!!deleteItem && deleteItem.nomeSocial}?`}
                </span>
                <div>
                  <CancelButton onClick={() => setDeleteItem(null)}>
                    Cancelar
                  </CancelButton>
                  <DeleteButton onClick={handleDelete}>Deletar</DeleteButton>
                </div>
              </ModalContainer>
            )}
            {(!!notBlockedItem || !!blockedItem) && (
              <ModalContainer>
                <h2>Confirmar atualização de status</h2>
                {!!notBlockedItem && (
                  <>
                    <span>
                      Tem certeza que deseja desativar o colaborador:
                      {` ${(!!notBlockedItem && notBlockedItem.nomeSocial)
                        || notBlockedItem.id}?`}
                    </span>
                    <div>
                      <CancelButton onClick={() => setNotBlockedItem(null)}>
                        Cancelar
                      </CancelButton>
                      <DeleteButton onClick={handleStatusUser}>
                        Desativar
                      </DeleteButton>
                    </div>
                  </>
                )}
                {!!blockedItem && (
                  <>
                    <span>
                      Tem certeza que deseja ativar o colaborador:
                      {` ${(!!blockedItem && blockedItem.nomeSocial)
                        || blockedItem.id}?`}
                    </span>
                    <div>
                      <CancelButton onClick={() => setBlockedItem(null)}>
                        Cancelar
                      </CancelButton>
                      <DeleteButton onClick={handleStatusUser}>
                        Ativar
                      </DeleteButton>
                    </div>
                  </>
                )}
              </ModalContainer>
            )}
            {(!!deleteInviteItem) && (
              <ModalContainer>
                <h2>Confirmar cancelamento</h2>
                <span>
                  Tem certeza que deseja cancelar o convite de:
                  {` ${(!!deleteInviteItem && deleteInviteItem.nomeSocial)
                    || deleteInviteItem.id}?`}
                </span>
                <div>
                  <CancelButton onClick={() => setDeleteInviteItem(null)}>
                    NÃO
                  </CancelButton>
                  <DeleteButton onClick={handleDeleteInvite}>
                    SIM
                  </DeleteButton>
                </div>
              </ModalContainer>
            )}
          </CustomModal>
        </Modal>
      </Container>
    </>
  );
}

PersonEmployee.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default PersonEmployee;
