import axios from 'axios';
import { RUNTIME_API } from '~/services/apiConfig';
import history from '~/routes/history';
import { authenticationPaths } from '~/routes/paths';

const requestInterceptorResolve = (config) => {
  return config;
};

const requestInterceptorReject = (error) => {
  return Promise.reject(error);
};

const responseInterceptorResolve = (response) => {
  return response;
};

const responseInterceptorReject = (error) => {
  if (error.response && error.response.status === 401) {
    if (error.response.data && error.response.data.message && error.response.data.message.sessionExpired) {
      setTimeout(() => {
        history.push(authenticationPaths.logout, {
          sessionExpired: true
        });
      }, 10 * 1000); // 10 segundos
    }
  }

  return Promise.reject(error);
};

const api = axios.create({
  baseURL: RUNTIME_API.BASE_URL_API,
  timeout: 120000, // 2 minutos
});

api.interceptors.request.use(requestInterceptorResolve, requestInterceptorReject);
api.interceptors.response.use(responseInterceptorResolve, responseInterceptorReject);

export default api;

export const endpoints = {
  authentication: {
    sso: '/autenticacao/sso',
    signin: '/autenticacao/validarUsuario',
    requestPasswordReset: '/autenticacao/recuperarSenha',
    verifyTokenPasswordReset: '/autenticacao/recuperarSenha/token/validar',
    passwordReset: '/autenticacao/recuperarSenha/alterarSenha',
    validateMfa: '/autenticacao/mfa/token/validar',
  },
  profile: {
    updateUserProfile: '/perfil/editar',
    getAccessHistory: '/perfil/acessos',
  },
  invitation: {
    validateTokenInvitation: '/token/validar',
    createPersonByInvitation: '/cadastro/convite/aceitar',
    createPersonByRegister: '/cadastro/ativar',
  },
  person: {
    personForms: '/pessoas/formulario/buscar',
    client: {
      createPersonClient: '/pessoa/cliente/criar',
      getPersonClients: '/pessoa/cliente/buscar',
      getPersonClient: id => `/pessoa/cliente/${id}`,
      updatePersonClient: '/pessoa/cliente/editar',
      deletePersonClient: id => `/pessoa/cliente/deletar/${id}`,
      updatePersonClientStatus: '/pessoa/cliente/status',
      updatePersonClientStatusKey: '/pessoa/cliente/status/chave',
      updateManyPersonClient: '/pessoas/cliente/editar/multiplos',
      verifyPersonClient: '/pessoa/cliente/verificar',
      getPersonKeyClient: '/pessoa/cliente/chave',
      getPersonAutoCompleteClient: '/pessoa/cliente/autoCompletar',
      getPersonExistClient: '/pessoa/cliente/buscarExistente',
      getAttendanceChangeClient: clienteId => `/mudancaAtendimento/buscar/${clienteId}`,
    },
    resale: {
      createPersonResale: '/pessoa/revenda/criar',
      getPersonResales: '/pessoa/revenda/buscar',
      getPersonResale: id => `/pessoa/revenda/${id}`,
      getPersonResaleNoImage: id => `/pessoa/revenda/${id}?isImage=false`,
      updatePersonResale: '/pessoa/revenda/editar',
      deletePersonResale: id => `/pessoa/revenda/deletar/${id}`,
      updatePersonResaleStatus: '/pessoa/revenda/status',
      updateManyPersonResale: '/pessoas/revenda/editar/multiplos',
      verifyPersonResale: '/pessoa/revenda/verificar',
      getPersonKeyResale: '/pessoa/revenda/chave',
      getPersonAutoCompleteResale: '/pessoa/revenda/autoCompletar',
      getPersonAllResales: '/pessoa/revenda/buscaCompleta',
    },
    employee: {
      createPersonEmployee: '/pessoa/colaborador/criar',
      getPersonEmployees: '/pessoa/colaborador/buscar',
      getPersonEmployee: id => `/pessoa/colaborador/${id}`,
      updatePersonEmployee: '/pessoa/colaborador/editar',
      deletePersonEmployee: id => `/pessoa/colaborador/deletar/${id}`,
      getPersonAutoCompleteEmployee: '/pessoa/colaborador/autoCompletar',
      updatePersonEmployeeStatus: '/pessoa/colaborador/status',
    },
    lineBusiness: {
      createLineBusiness: '/ramoAtividade/criar',
      getLineBusiness: '/ramoAtividade/buscar',
      updateLineBusiness: '/ramoAtividade/editar',
      deleteLineBusiness: id => `/ramoAtividade/deletar/${id}`,
    },
    channelRating: {
      createChannelRating: '/classificacao/criar',
      getChannelRating: '/classificacao/buscar',
      updateChannelRating: '/classificacao/editar',
      deleteChannelRating: id => `/classificacao/deletar/${id}`,
    },
    invitation: {
      createInvitationEmployee: '/convite/colaborador/criar',
      createInvitationResale: '/convite/revenda/criar',
      deletePersonInvitation: (cpfCnpj) => `/convite/pessoa/deletar/${cpfCnpj}`,
      findPersonInvitation: '/convite/pessoa/buscar',
    },
    localization: {
      getCityState: '/localizacao/cidade/estado/buscar'
    }
  },
  product: {
    software: {
      createProductSoftware: '/software/criar',
      getProductSoftwares: '/software/buscar',
      getProductSoftware: id => `/software/${id}`,
      updateProductSoftware: '/software/editar',
      deleteProductSoftware: id => `/software/deletar/${id}`,
    },
    modality: {
      createProductModality: '/modalidade/criar',
      getProductModalities: '/modalidade/buscar',
      getProductModality: id => `/modalidade/${id}`,
      updateProductModality: '/modalidade/editar',
      deleteProductModality: id => `/modalidade/deletar/${id}`,
    },
    module: {
      createProductModule: '/modulo/criar',
      getProductModules: '/modulo/buscar',
      getProductModule: id => `/modulo/${id}`,
      updateProductModule: '/modulo/editar',
      deleteProductModule: id => `/modulo/deletar/${id}`,
    },
  },
  commercial: {
    order: {
      createCommercialOrder: '/pedido/criar',
      createCommercialOrderDraft: '/pedido/rascunho',
      getCommercialOrders: '/pedido/buscar',
      getPaymentTypes: '/pedido/formasPagamento',
      getCommercialOrder: id => `/pedido/${id}`,
      updateCommercialOrder: '/pedido/editar',
      deleteCommercialOrder: id => `/pedido/deletar/${id}`,
      updateStatusOrder: '/pedido/status',
      verifyStatusOrder: id => `/pedido/${id}/verificar/status`,
      addAnexoOrder: '/pedido/anexo',
      getAnexoFile: id => `/pedido/anexo/${id}`,
    },
    solicitation: {
      createCommercialSolicitation: '/solicitacao/criar',
      getCommercialSolicitations: '/solicitacao/buscar',
      updateCommercialSolicitationStatus: '/solicitacao/status',
      createCommercialSolicitationMenssage: '/mensagem/criar',
      getAnexoFile: id => `/anexo/${id}`,
      updateExtraCommercialSolicitation: '/solicitacao/editar/extra',
    },
    reasonSolicitation: {
      createReasonSolicitation: '/motivoSolicitacao/criar',
      getReasonSolicitation: '/motivoSolicitacao/buscar',
      updateReasonSolicitation: '/motivoSolicitacao/editar',
      deleteReasonSolicitation: id => `/motivoSolicitacao/deletar/${id}`,
    },
    payment: {
      createPayments: '/pagamento/criar',
      getPayments: '/pagamento/buscar',
      updatePayments: '/pagamento/editar',
      getPayment: id => `/pagamento/${id}`,
      deletePayment: id => `/pagamento/deletar/${id}`,
      getGracePeriodPayment: '/pagamento/carencia/buscar',
      updateGracePeriodPayment: '/pagamento/carencia/ativar',
    }
  },
  goal: {
    craeteGoal: '/meta/criar',
    getGoals: '/meta/buscar',
    deleteGoals: '/meta/deletar',
    getItemGoals: '/meta/tipo/buscar',
    verifyCampaignGoals: '/meta/verificar/campanha',
  },
  form: {
    register: {
      createForm: '/formulario/criar',
      getForms: '/formulario/buscar',
      getForm: id => `/formulario/buscar/${id}`,
      updateForm: '/formulario/editar',
      disableForm: id => `/formulario/desativar/${id}`,
      deleteForm: id => `/formulario/deletar/${id}`,
    },
    maintenance: {
      createFormAnswer: '/resposta/formulario/criar',
      getFormsAnswer: '/resposta/formulario/buscar',
      getFormAnswer: id => `/resposta/formulario/buscar/${id}`,
      updateFormAnswer: '/resposta/formulario/editar',
      updateStatusFormAnswer: '/resposta/formulario/status',
      getResponsibleFormAnswer: '/resposta/formulario/responsaveis',
    },
  },
  license: {
    getLicenses: '/licenca/buscar',
    getChave: '/licenca/chave',
    getLogsChave: '/log/buscar/chave',
    getLogsSystem: '/log/buscar/sistema',

    getAllCodExtSoftware: '/licenca/codExt/buscar',
    fullLicenseCancellation: '/licenca/cancelar/total',
    partialLicenseCancellation: '/licenca/cancelar/parcial',

    getCancelledLicenses: '/cancelamentos/buscar',
    rejectLicenseCancellation: '/cancelamento/rejeitar',
    approveLicenseCancellation: '/cancelamento/aprovar',

    createReasonCancellation: '/motivoCancelamento/criar',
    getReasonCancellation: '/motivoCancelamento/buscar',
    updateReasonCancellation: '/motivoCancelamento/editar',
    deleteReasonCancellation: id => `/motivoCancelamento/deletar/${id}`,
  },
  admin: {
    createAccessGroup: '/grupoAcesso/criar',
    getAccessGroup: '/grupoAcesso/buscar',
    getGroup: id => `/grupoAcesso/${id}`,
    updateAccessGroup: '/grupoAcesso/editar',
    deleteAccessGroup: id => `/grupoAcesso/deletar/${id}`,
    getAccessGroupPermissions: '/grupoAcesso/permissoes',
    getSetting: '/configuracao/geral/buscar',
    updateSetting: '/configuracao/geral/editar',
    getLinkMarketing: '/configuracao/marketing/link/buscar',
    updateLinkMarketing: '/configuracao/marketing/link/editar',
  },
  file: {
    getDownloadFileS3: (path) => `/oportunidade/arquivo/baixar/${path}`,
  },
  opportunity: {
    getReasonsLosses: '/oportunidade/motivo/perda/buscar',
    getReasonLoss: id => `/oportunidade/motivo/perda/buscar/${id}`,
    createReasonLoss: '/oportunidade/motivo/perda/criar',
    updateReasonLoss: '/oportunidade/motivo/perda/editar',
    deleteReasonLoss: id => `/oportunidade/motivo/perda/deletar/${id}`,

    getLeadOrigins: '/origem/lead/buscar',
    getLeadOrigin: id => `/origem/lead/buscar/${id}`,
    createLeadOrigin: '/origem/lead/criar',
    updateLeadOrigin: '/origem/lead/editar',
    deleteLeadOrigin: id => `/origem/lead/deletar/${id}`,

    getFunis: '/funil/buscar',
    getFunisWithOpportunity: '/funil/oportunidades/buscar',
    getFunisMonitoring: '/funil/monitoramento/buscar',
    getFunil: id => `/funil/buscar/${id}`,
    getLocalizacoes: funilId => `/funil/${funilId}/localizacoes`,
    createFunil: '/funil/criar',
    updateFunil: '/funil/editar',
    deleteFunil: id => `/funil/deletar/${id}`,
    availabilityFunil: '/funil/disponibilidade',

    createOpportunity: '/oportunidade/criar',
    updateOpportunity: '/oportunidade/editar',
    getOpportunities: '/oportunidade/buscar',
    getOpportunity: id => `/oportunidade/buscar/${id}`,
    createOpportunityWin: '/oportunidade/ganha',
    updateOpportunityToLoss: '/oportunidade/perdida',
    updateBetweenPositionsOpportunity: '/oportunidade/editar/entre/posicoes',
    updateBetweenStepsOpportunity: '/oportunidade/editar/entre/etapas',

    createReminder: '/oportunidade/lembrete/criar',
    updateReminder: '/oportunidade/lembrete/editar',
    getReminders: '/oportunidade/lembrete/buscar',
    concludeReminder: '/oportunidade/lembrete/concluido',
    undoConcludeReminder: '/oportunidade/lembrete/concluido/desfazer',
    deleteReminder: (id, oppId) =>
      `/oportunidade/lembrete/deletar/${id}?oportunidadeId=${oppId}`,

    createNote: '/oportunidade/anotacao/criar',
    updateNote: '/oportunidade/anotacao/editar',
    updateAttachmentsNote: '/oportunidade/anotacao/editar/anexos',
    getNotes: '/oportunidade/anotacao/buscar',
    deleteNote: (id, oppId) =>
      `/oportunidade/anotacao/deletar/${id}?oportunidadeId=${oppId}`,

    createScheduleCommitment: '/oportunidade/agenda/compromissos/criar',
    updateScheduleCommitment: '/oportunidade/agenda/compromissos/editar',
    updateScheduleAttachmentsCommitment:
      '/oportunidade/agenda/compromissos/editar/anexos',
    getScheduleCommitments: '/oportunidade/agenda/compromissos/buscar',
    concludeScheduleCommitment: '/oportunidade/agenda/compromissos/concluido',
    deleteScheduleCommitment: (id, oppId) =>
      `/oportunidade/agenda/compromissos/deletar/${id}?oportunidadeId=${oppId}`,

    createScheduleTask: '/oportunidade/agenda/tarefas/criar',
    updateScheduleTask: '/oportunidade/agenda/tarefas/editar',
    updateScheduleAttachmentsTask: '/oportunidade/agenda/tarefas/editar/anexos',
    getScheduleTasks: '/oportunidade/agenda/tarefas/buscar',
    concludeScheduleTask: '/oportunidade/agenda/tarefas/concluido',
    deleteScheduleTask: (id, oppId) =>
      `/oportunidade/agenda/tarefas/deletar/${id}?oportunidadeId=${oppId}`,

    createDocuments: '/oportunidade/documento/criar',
    getDocuments: '/oportunidade/documento/buscar',
    updateAttachmentsDocuments: '/oportunidade/documento/editar/anexos',
    deleteDocuments: (id, oppId) =>
      `/oportunidade/documento/deletar/${id}?oportunidadeId=${oppId}`,
  },
};
