import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { formatCpfCnpj } from '~/util/stringutils';
import { CALENDARIO_MESES } from '~/util/domainutils';
import { FaTimes } from 'react-icons/fa';
import { TableShoppingCart } from '../../../Items/TableShoppingCart';
import FormViewer from '~/components/FormViewer';
import { RenderForm } from '../../../Items/RenderForm';
import Modal, { CustomModal } from '~/components/Modal';
import * as S from './styles';

function Conclude(props) {
  const {
    user,
    cliente,
    items,
    activeStep,
    softwares,
    mesInicio,
    formularios,
    pessoasFormulario,
    answerForms,
    formasPagamento,
    formaPagamentoId,
    planoPagamentoId,
    selectedModality,
    observation,
    register,
    watch,
    setValue,
    errors,
    reset,
    orderConfirmation,
    calculateTotalAdesao,
    calculateTotalMensalidade,
    setObservation,
    setOrderConfirmation,
    handleLinkToFormCurrent,
  } = props;
  const [formItem, setFormItem] = useState(null);

  function handleObservationChange(event) {
    const {
      target: { value },
    } = event;

    setObservation(value);
  }

  function handleCheckOrderConfirmationChange(event) {
    const {
      target: { value },
    } = event;

    setOrderConfirmation(value !== 'true');
  }

  const formaPagamento = (formasPagamento || []).filter(
    (item) => item.id === parseInt(formaPagamentoId)
  );

  let formaPagamentoNome = '';
  let planoPagamentoNome = '';

  if (formaPagamento.length) {
    const formaPlanoPagamentos = formaPagamento[0].formaPlanoPagamentos;

    if (formaPlanoPagamentos.length) {
      const formaPlanoPagamentosTemp = formaPlanoPagamentos.filter(
        (item) => item.id === parseInt(planoPagamentoId)
      );

      if (formaPlanoPagamentosTemp.length) {
        const planoPagamentoTemp = formaPlanoPagamentosTemp[0].planoPagamento;

        planoPagamentoNome = planoPagamentoTemp.nome ?? '';
      }
    }

    formaPagamentoNome = formaPagamento[0].nome ?? '';
  }

  const nomeMes = CALENDARIO_MESES.filter(
    (item) => item.value === parseInt(mesInicio)
  )[0].label;

  const totalAdesao = calculateTotalAdesao(items);
  const totalMensalidade = calculateTotalMensalidade(items);
  const nomeModalidade = selectedModality ? selectedModality.nome : '---';

  const onHandleViewer = (formId) => {
    const [formToRender] = formularios.filter((_) => _.formId === formId);
    setFormItem(formToRender);
    reset(formToRender.formData);
  };

  return (
    <S.ConcludeContainer>
      <S.ConcludeContent>
        <S.DataClientContainer>
          <div className="clientInfoHolder">
            <div className="header">
              <span>Cliente Selecionado: </span>
            </div>
            <div>
              {cliente.id ? (
                <div className="clientInfo">
                  <span>{cliente.nomeFantasia}</span>
                  <span>CPF/CNPJ: {formatCpfCnpj(cliente.cpfCnpj)}</span>
                  <span>
                    Ramo: {cliente.ramoAtividade ? cliente.ramoAtividade.nome : ''}
                  </span>
                  <span>
                    {cliente.endereco.cidade} - {cliente.endereco.estado}
                  </span>
                </div>
              ) : (
                <div className="clientInfo">
                  <span />
                  <span>CPF/CNPJ: </span>
                  <span>Ramo: </span>
                  <span />
                </div>
              )}
            </div>
          </div>
          <div className="clientInfoHolder">
            <div className="header">
              <span>Revenda:</span>
            </div>
            <div>
              {user ? (
                <div className="clientInfo">
                  <span>{user.revenda.nome}</span>
                  <span>CPF/CNPJ: {formatCpfCnpj(user.revenda.cpfCnpj)}</span>
                  <span>Usuário: {user.nome}</span>
                </div>
              ) : (
                <Fragment>
                  <span />
                  <span>CPF/CNPJ: </span>
                  <span>Usuário: </span>
                </Fragment>
              )}
            </div>
          </div>
        </S.DataClientContainer>
        <div className="faturamento">
          <span id="title">Faturamento:</span>
          <div id="infoHolder">
            <div id="adesaoHolder">
              <div id="priceHolder">
                <span>ADESÃO: </span>
                <S.CurrencyFormatPrice value={totalAdesao} />
              </div>
              <span>{formaPagamentoNome}: {planoPagamentoNome}</span>
            </div>
            <div id="mensalidadeHolder">
              <div id="priceHolder">
                <span>MENSALIDADE: </span>
                <S.CurrencyFormatPrice value={totalMensalidade} />
              </div>
              <span>Início: {nomeMes}</span>
            </div>
            <div id="modalidadeHolder">
              <div id="titleHolder">
                <span>MODALIDADE:</span>
              </div>
              <span>Tipo: {nomeModalidade}</span>
            </div>
          </div>
        </div>
        <div className="productsHolder">
          <span id="title">Produto(s):</span>
          <TableShoppingCart
            activeStep={activeStep}
            softwares={softwares}
            items={items}
            userCanEdit={false}
            calculateTotalAdesao={calculateTotalAdesao}
            calculateTotalMensalidade={calculateTotalMensalidade}
          />
        </div>
        <div className="formHolder">
          <div className="inputHolder defaultFlex">
            <span id="title">Formulários:</span>
            <div className="rowContainer noMarginTop">
              {answerForms.map((answer) => (
                <FormViewer
                  key={answer.idForm}
                  formName={answer.formName}
                  handleViewer={() => onHandleViewer(answer.idForm)}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="obsHolder">
          <div className="inputHolder defaultFlex">
            <span id="title">Observações:</span>
            <textarea
              name="observation"
              value={observation}
              onChange={handleObservationChange}
            />
          </div>
        </div>
        <div className="termHolder">
          <span id="title">Termo *</span>
          <div id="radioInputHolder">
            <input
              type="checkbox"
              value={orderConfirmation}
              checked={orderConfirmation}
              onChange={handleCheckOrderConfirmationChange}
            />
            <span id="radioLabel">
              Confirmo que revisei o pedido, e estou ciente que após o
              faturamento não será aceito cancelamento por motivo de erros,
              bem como prorrogação do início da cobrança das mensalidades.
            </span>
            <span className="checkmark">
              {orderConfirmation && (
                <div>
                  <div className="checkmark_stem" />
                  <div className="checkmark_kick" />
                </div>
              )}
            </span>
          </div>
        </div>
        <div id='endTermo' />
      </S.ConcludeContent>
      <Modal show={!!formItem} customWidth={'calc(90% - 200px)'}>
        <CustomModal>
          <S.ModalContentHeader>
            <div>
              <h3>Formulário</h3>
              <button
                type="button"
                className="buttonBaseOnly baseMain"
                onClick={() => {
                  handleLinkToFormCurrent(formItem.formId);
                  setFormItem(null);
                }}
              >
                EDITAR
              </button>
            </div>
            <FaTimes size={20} onClick={(e) => {
              e.preventDefault();
              setFormItem(null);
            }} />
          </S.ModalContentHeader>
          <S.ModalContentScroll>
            {formItem && (
              <RenderForm
                noBorder={true}
                canDisabledAllInputs={true}
                formPersons={pessoasFormulario}
                formId={formItem.formId}
                formViewer={formItem.formViewer}
                contentItemsFormAnswer={
                  formItem.contentItemsFormAnswer
                }
                showLabelFieldRequired
                register={register}
                watch={watch}
                setValue={setValue}
                errors={errors}
              />
            )}
          </S.ModalContentScroll>
        </CustomModal>
      </Modal>
    </S.ConcludeContainer>
  );
}

Conclude.propTypes = {
  activeStep: PropTypes.number.isRequired,
  softwares: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  cliente: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  mesInicio: PropTypes.number.isRequired,
  formularios: PropTypes.array.isRequired,
  answerForms: PropTypes.array.isRequired,
  formasPagamento: PropTypes.array.isRequired,
  formaPagamentoId: PropTypes.number.isRequired,
  planoPagamentoId: PropTypes.number.isRequired,
  selectedModality: PropTypes.object,
  pessoasFormulario: PropTypes.object,
  observation: PropTypes.string.isRequired,
  orderConfirmation: PropTypes.bool.isRequired,
  calculateTotalAdesao: PropTypes.func.isRequired,
  calculateTotalMensalidade: PropTypes.func.isRequired,
  setObservation: PropTypes.func.isRequired,
  setOrderConfirmation: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  reset: PropTypes.func.isRequired,
  handleLinkToFormCurrent: PropTypes.func.isRequired,
};

export { Conclude };
