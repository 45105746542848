import styled from 'styled-components';

export const StepperContent = styled.div`
  padding: 20px 20px 10px 20px;

  span {
    font-size: 18px;
    color: var(--cm-primary);
    font-weight: bold;

    .textNormal {
      font-weight: normal;
    }

    @media (max-width: 450px) {
      font-size: 14px !important;
    }

    @media (max-width: 900px) {
      font-size: 16px !important;
    }
  }

  .stepper-container {
    padding: 10px 0 0 0;
    overflow-x: auto;

    .stepper-item {
      display: flex;
      justify-content: flex-start;
      margin: 10px 0;

      .stepper-item-inner {
        height: 32px;
        width: 32px;
        background-color: white;
        border: 1px solid var(--cm-primary);
        opacity: 0.5;
        color: var(--cm-primary);
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        padding-bottom: 2px;
      }

      .stepper-item-inner-active {
        opacity: 1;
        color: white;
        background-color: var(--cm-primary);
      }

      .stepper-title {
        white-space: nowrap;
        font-size: 16px;
        color: var(--cm-primary);
        opacity: 0.5;
      }

      .stepper-title-active {
        opacity: 1;
      }
    }

    .stepper-item-outer {
      width: auto;
      flex-grow: 1;
      margin: 5px 20px 0 20px;
      min-width: 10px;
      max-width: 100px;
    }
  }
`;
