import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 100%;

  div {
    display: flex;
    margin: 5px 10px;

    textarea {
      resize: none;
    }

    span {
      font-size: 17px;
      color: #707070;

      :first-child {
        margin-right: 10px;
      }

      @media (max-width: 700px) {
        font-size: 14px;

        :first-child {
          margin-right: 5px;
        }
      }
    }
  }
`;
