import PropTypes from 'prop-types';
import React from 'react';

import { ModalContent } from '../../styles';

export default function Generating({
  formGenerateKey,
  inputPrazoChaveValue,
  handleInputPrazoChaveChange,
  inputControleValue,
  isDisabeldControle,
  isFirstKey,
  handleInputControleChange,
}) {
  const { cliente, software } = formGenerateKey;

  const handleValue = (text) => text ? text : '---';

  return (
    <ModalContent>
      <h3>DADOS DO CLIENTE</h3>
      <section>
        <span>
          <b>Razão Social: </b>
          {handleValue(cliente.razaoSocial)}
        </span>
        <span>
          <b>CPF / CNPJ: </b>
          {handleValue(cliente.cpfCnpj)}
        </span>
        <span>
          <b>Nome Fantasia: </b>
          {handleValue(cliente.nomeFantasia)}
        </span>
        <span>
          <b>Email: </b>
          {handleValue(cliente.email)}
        </span>
        <span>
          <b>Endereço: </b>
          {handleValue(cliente.endereco)}
        </span>
      </section>
      <h3 className='withMarginTop'>DADOS DO SOFTWARE</h3>
      <section>
        <div>
          <span>
            <b>Título: </b>
            {handleValue(software.titulo)}
          </span>
        </div>
        <div>
          <span>
            <b>Série: </b>
            {handleValue(software.serie)}
          </span>
          <span>
            <b>Versão: </b>
            {handleValue(software.versao)}
          </span>
          <span>
            <b>Controle: </b>
            {handleValue(software.controle)}
          </span>
        </div>
      </section>
      <section className='displayFlex'>
        {isFirstKey && (
          <div>
            <h3 className='subtitle'>
              Controle: *
            </h3>
            <input
              type='text'
              disabled={isDisabeldControle}
              value={inputControleValue}
              onChange={handleInputControleChange}
            />
          </div>
        )}
        <div>
          <h3 className='subtitle'>
            Prazo da Chave: *
          </h3>
          <input
            type='text'
            value={inputPrazoChaveValue}
            onChange={handleInputPrazoChaveChange}
          />
        </div>
      </section>
    </ModalContent>
  );
}

Generating.propTypes = {
  formGenerateKey: PropTypes.shape({
    cliente: PropTypes.shape({
      razaoSocial: PropTypes.string.isRequired,
      cpfCnpj: PropTypes.string.isRequired,
      nomeFantasia: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      endereco: PropTypes.string.isRequired,
    }).isRequired,
    software: PropTypes.shape({
      titulo: PropTypes.string.isRequired,
      serie: PropTypes.string.isRequired,
      versao: PropTypes.string.isRequired,
      controle: PropTypes.string,
    }).isRequired,
  }).isRequired,
  inputPrazoChaveValue: PropTypes.string.isRequired,
  handleInputPrazoChaveChange: PropTypes.func.isRequired,
  inputControleValue: PropTypes.string.isRequired,
  isDisabeldControle: PropTypes.bool.isRequired,
  isFirstKey: PropTypes.bool.isRequired,
  handleInputControleChange: PropTypes.func.isRequired,
};
