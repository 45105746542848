import PropTypes from 'prop-types';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { USER_TYPE, LOG_ACTIONS } from '~/util/domainutils';
import { PeriodInDate } from '~/components/PeriodInDate';
import { CollapsibleFilter } from '~/components/CollapsibleFilter';

function LogsSearch({
  countFilter,
  handleSubmit,
  revendaValue,
  usuarioValue,
  perfilValue,
  acaoValue,
  enderecoIpValue,
  dataInicioValue,
  dataFimValue,
  handleRevendaChange,
  handleUsuarioChange,
  handlePerfilChange,
  handleAcaoChange,
  handleEnderecoIpChange,
  handleDataInicioChange,
  handleDataFimChange,
}) {
  const REVENDA = USER_TYPE.REVENDA.charAt(0).toUpperCase()
    + USER_TYPE.REVENDA.slice(1);

  const COLABORADOR = USER_TYPE.COLABORADOR.charAt(0).toUpperCase()
    + USER_TYPE.COLABORADOR.slice(1);

  return (
    <CollapsibleFilter countFilters={countFilter}>
      <form className="formContainer" onSubmit={handleSubmit}>
        <label id="searchText">Pesquisar</label>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Usuário</span>
            <input
              type="text"
              name="usuario"
              value={usuarioValue}
              onChange={handleUsuarioChange}
            />
          </div>
          <div className="inputHolder">
            <span>Perfil</span>
            <select
              name="profile"
              value={perfilValue}
              onChange={handlePerfilChange}
            >
              <option value={''}>Selecione</option>
              <option value={USER_TYPE.REVENDA}>{REVENDA}</option>
              <option value={USER_TYPE.COLABORADOR}>{COLABORADOR}</option>
            </select>
          </div>
          <div className="inputHolder noMarginRight">
            <span>Endereço IP</span>
            <input
              type="text"
              name="addressIp"
              value={enderecoIpValue}
              onChange={handleEnderecoIpChange}
            />
          </div>
        </div>
        <div className="rowContainer rowJustifyContent">
          <div className="inputHolder defaultFlex">
            <span>Revenda</span>
            <input
              type="text"
              name="revenda"
              value={revendaValue}
              onChange={handleRevendaChange}
            />
          </div>
          <div className="inputHolder">
            <span>Ação</span>
            <select
              name="action"
              value={acaoValue}
              onChange={handleAcaoChange}
            >
              <option value={''}>Selecione</option>
              <option value={LOG_ACTIONS.LOG_CREATE.value}>
                {LOG_ACTIONS.LOG_CREATE.label}
              </option>
              <option value={LOG_ACTIONS.LOG_DELETE.value}>
                {LOG_ACTIONS.LOG_DELETE.label}
              </option>
              <option value={LOG_ACTIONS.LOG_UPDATE.value}>
                {LOG_ACTIONS.LOG_UPDATE.label}
              </option>
            </select>
          </div>
          <div className='rowContent'>
            <div className="inputHolder">
              <PeriodInDate
                dataInicio={dataInicioValue}
                handleDataInicioChange={handleDataInicioChange}
                dataFim={dataFimValue}
                handleDataFimChange={handleDataFimChange}
              />
            </div>
            <button
              className="buttonBaseOnly"
              id="baseButtonIcon"
              onClick={handleSubmit}
            >
              <FaSearch id="baseNameIcon" />
              <span>BUSCAR</span>
            </button>
          </div>
        </div>
      </form>
    </CollapsibleFilter>
  );
}

LogsSearch.propTypes = {
  countFilter: PropTypes.number,
  handleSubmit: PropTypes.func,
  revendaValue: PropTypes.string,
  usuarioValue: PropTypes.string,
  perfilValue: PropTypes.string,
  acaoValue: PropTypes.string,
  enderecoIpValue: PropTypes.string,
  dataInicioValue: PropTypes.string,
  dataFimValue: PropTypes.string,
  handleRevendaChange: PropTypes.func,
  handleUsuarioChange: PropTypes.func,
  handlePerfilChange: PropTypes.func,
  handleAcaoChange: PropTypes.func,
  handleEnderecoIpChange: PropTypes.func,
  handleDataInicioChange: PropTypes.func,
  handleDataFimChange: PropTypes.func,
};

export default LogsSearch;
