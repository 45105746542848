import styled from 'styled-components';

const getMinWidthIsCollapse =
  ({ isCollapse }) => isCollapse ? '250px' : '75px';

const getSidebarContainerOverflowYIsCollapse =
  ({ isCollapse }) => isCollapse ? 'auto' : 'hidden';

export const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 75px;
  min-width: ${getMinWidthIsCollapse};
  max-width: 250px;
  color: #77778c;
  background-color: white;
  border-right: 1px solid var(--cm-primary-shade);

  overflow-y: ${getSidebarContainerOverflowYIsCollapse};
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--cm-primary-tint); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid white; /* creates padding around scroll thumb */
  }
`;

const getListHolderJustifyContentIsCollapse =
  ({ isCollapse }) => isCollapse ? 'flex-start' : 'center';

const getListHolderPaddingIsCollapse =
  ({ isCollapse }) => isCollapse ? '12px 0 12px 10px' : '12px 15px';

const getSubMenuDsplayIsCollapse =
  ({ isCollapse }) => isCollapse ? 'block' : 'none';

export const SidebarMenu = styled.div`
  padding: 5px 10px 0 10px;
  width: 75px;
  min-width: ${getMinWidthIsCollapse};
  max-width: 250px;
  height: 100%;
  background-color: white;

  ul {
    list-style: none;
    border-right: none;

    li {
      display: flex;
      flex-direction: column;

      label {
        cursor: pointer;
      }

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        color: #828296;

        .listHolder {
          margin: 5px 0;

          .activeCollapseSidebar {
            display: flex;
            width: 100%;
          }

          .inactiveCollapseSidebar {
            display: none;
          }
        }

        .listHolder {
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: ${getListHolderJustifyContentIsCollapse};
          padding: ${getListHolderPaddingIsCollapse};
          border: 1px solid transparent;

          img {
            height: 22px;
            width: 22px;
          }

          label {
            margin-left: 15px;
          }

          .arrowIcon {
            flex-grow: 1;
            font-size: 16pt;
            display: flex;
            justify-content: flex-end;
            margin-right: 10px;
          }
        }

        .listHolder:hover {
          border-radius: 25px;
          color: var(--cm-primary-contrast);
          border: 1px solid var(--cm-primary-40);
          background-color: var(--cm-primary-5);

          img {
            color: var(--cm-primary-contrast);
          }
        }

        .activeMenu,
        .activeMenu:hover,
        .activeMenu.listHolder:hover {
          border-radius: 25px;
          border: 1px solid var(--cm-secondary);
          color: var(--cm-secondary-contrast);
          background-color: var(--cm-secondary-tint-20);

          label {
            font-weight: bold;
            color: var(--cm-secondary-contrast);
          }
        }

        .inactiveMenu.listHolder:hover {
          color: #828296;
        }
      }

      .submenu {
        display: ${getSubMenuDsplayIsCollapse};
      }

      .submenu {
        height: 100%;
        width: 100%;
        background-color: white;
        margin-top: 1px;

        ul {
          list-style-type: disc;
          border-top: 1px solid #d4d4e4;
          border-bottom: 1px solid #d4d4e4;
          padding: 5px 0;

          li {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 2px 5px;
            border: 1px solid transparent;

            a {
              padding: 2px;
              margin-left: 8px;
              color: var(--cm-primary);
              text-decoration: none;
            }

            .visibleIcon {
              font-size: 10pt;
              color: var(--cm-primary);
            }

            .hideIcon {
              visibility: hidden;
            }

            .active {
              font-weight: bold;
            }

            &:hover, &:active {
              border-radius: 25px;
              color: var(--cm-tertiary-contrast);
              border: 1px solid var(--cm-primary-40);
              background-color: var(--cm-primary-5);
              font-weight: 700;
            }
          }
        }
      }
    }
  }
`;
