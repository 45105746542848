import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '~/components/Modal';
import TableCell from '@material-ui/core/TableCell';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import SimpleTextSearch from '~/components/SimpleTextSearch';
import TableComponent from '~/components/TableComponent';
import PrintableComponent from '~/components/PrintableComponent';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { licensePaths } from '~/routes/paths';
import { ACCESS_DENIED } from '~/util/functionsutils';
import { ReasonCancellationService } from '~/services/license/reasonCancellation';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import { useFitler } from '~/hook/useFilter';
import './styles.scss';

export default function ReasonCancellation({ history }) {
  const { filters, setFilters } = useFitler();
  const [inputValue, setInputValue] = useState(filters?.inputValue ? filters?.inputValue : '');
  const [searchResponse, setSearchResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });

  let PrintableElement = React.useRef();

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions['create_motivo-cancelamento']),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions['edit_motivo-cancelamento']),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions['delete_motivo-cancelamento']),
    [userPermissions]
  );

  useEffect(() => {
    findAllByFilter(pageParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(event) {
    const {
      target: { value },
    } = event;

    setInputValue(value);
  }

  async function findAllByFilterReport() {
    setLoading(true);
    try {
      if (inputValue.length) { setCountFilter(1); } else { setCountFilter(0); }
      const result = await ReasonCancellationService
        .getReasonCancellation({ text: inputValue.trim() });

      if (result.resultado.length) {
        const motivos = result.resultado.filter((motivo) => motivo.ativo);
        setSearchResponseReport(motivos || []);
      } else {
        toast('Não encontramos nenhum Motivo!', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      if (inputValue.length) { setCountFilter(1); } else { setCountFilter(0); }
      const { page, rowsPerPage } = pageParams;
      const result = await ReasonCancellationService
        .getReasonCancellation({
          text: inputValue.trim(),
          limit: page * rowsPerPage + rowsPerPage,
          offset: page * rowsPerPage,
        });

      if (result.resultado.length) {
        const motivos = result.resultado.filter((motivo) => motivo.ativo);

        setTotalItems((motivos || []).length);
        setSearchResponse(motivos || []);
      } else {
        toast('Não encontramos nenhum Motivo!', {
          type: toast.TYPE.ERROR,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setFilters({ inputValue });

    await findAllByFilter(pageParameters);
  }

  async function handleDelete() {
    try {
      setLoading(true);
      const result = await ReasonCancellationService
        .deleteReasonCancellation(deleteItem.id);

      if (!result.data.ok) {
        toast('Erro ao deletar o Motivo!', {
          type: toast.TYPE.ERROR,
        });
      } else {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteItem.id
        );
        setTotalItems((oldTotal) => oldTotal - 1);
        setSearchResponse(newSearchResponse);
        toast(result.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o motivo!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  const renderRowComponent = row => {
    return [
      <TableCell key="row_reason-cancellation_0">{row.descricao}</TableCell>,
      <TableCell key="row_reason-cancellation_1">
        <div id="subReasonsHolder">
          {row.submotivoCancelamentos
            .filter((subReason) => subReason.ativo)
            .map((subReason, index) => (
              <ul key={String(index)}>{subReason.descricao}</ul>
            ))}
        </div>
      </TableCell>,
    ];
  };

  const handleEditItem = row => {
    return history.push(licensePaths.editReasonCancellation, {
      id: row.id,
      motivo: row.descricao
    });
  };

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Motivo de Cancelamento"
        handlePageUpdate={findAllByFilterReport}
        filters={[{ name: 'Motivo', value: inputValue }]}
        dataObjects={searchResponseReport}
        renderRowComponent={renderRowComponent}
        headerLabels={[
          { text: 'Motivo', width: '30%' },
          { text: 'Submotivo(s)', width: 'auto' },
        ]}
      />
      <div className="adminPageContainer">
        <Modal loading={loading} show={loading || !!deleteItem}>
          <div className="modalContainer">
            <h2>Confirmar exclusão</h2>
            <span>
              Tem certeza que deseja deletar o item
              <span id="elementName">
                {` ${!!deleteItem && deleteItem.descricao}`}
              </span>
              ?
            </span>
            <div>
              <button
                className="buttonBaseOnly addMargin"
                onClick={() => setDeleteItem(null)}
              >
                Cancelar
              </button>
              <button
                className="buttonBaseOnly baseMain"
                onClick={handleDelete}
              >
                Deletar
              </button>
            </div>
          </div>
        </Modal>
        <SidebarPageHeader
          mainMenu="Licença"
          pageName="Motivo do Cancelamento"
          button1={{
            label: 'Novo Motivo',
            link: licensePaths.addReasonCancellation,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await findAllByFilterReport(pageParameters);
          }}
          openButtonSelect
        />
        <SimpleTextSearch
          countFilter={countFilter}
          fieldLabel="Motivo"
          inputValue={inputValue}
          handleInputChange={handleChange}
          handleSubmit={handleSubmit}
        />
        <TableComponent
          headerLabels={[
            { text: 'Motivo', width: '30%' },
            { text: 'Submotivo(s)', width: 'auto' },
          ]}
          dataObjects={searchResponse}
          editPermission={userCanEdit}
          handleEdit={userCanEdit
            ? (row) => handleEditItem(row)
            : () => ACCESS_DENIED(history)
          }
          deletePermission={userCanDelete}
          handleDelete={userCanDelete
            ? (row) => setDeleteItem(row)
            : () => ACCESS_DENIED(history)
          }
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
          renderRowComponent={renderRowComponent}
        />
      </div>
    </>
  );
}

ReasonCancellation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
