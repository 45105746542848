import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import { MdShoppingCart } from 'react-icons/md';
import CurrencyFormat from 'react-currency-format';
import Select from 'react-select';

export const TableCellTotal = styled(TableCell)`
  background-color: #E4EAFC;

  span {
    color: black;
    font-weight: bold;
  }
`;

export const CustomContainer = styled.div`
  width: 100%;
`;

export const OrderSoftwareFormContainer = styled.form`
  border: 0;
  margin: 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;

  #title {
    color: var(--cm-primary);
    font-weight: bold;
  }

  #quantityHolder {
    display: flex;
    flex-direction: row;
    border: 1px solid #e1e1e9;
    border-radius: 5px;
    background-color: white;
    height: 30px;
    width: 100px;
    justify-content: center;
    align-items: center;

    #quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      min-width: 40px;
      text-align: center;
      border-left: 1px solid #e1e1e9;
      border-right: 1px solid #e1e1e9;
      padding-bottom: 4px;
    }

    #minus,
    #plus {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 25px;
      font-size: 18px;
      font-weight: bold;
      color: var(--cm-primary);
      padding-bottom: 4px;
      cursor: pointer;
    }
  }
`;

export const SoftwareSelectedContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 10px;

  .selectSoftware:first-child {
    margin-right: 20px;
  }

  .selectSoftware {
    margin: 10px 0 20px 0;

    @media screen and (max-width: 1160px) {
      .selectSoftware {
        margin: 0 0 20px 0;
      }
    }

    margin: 10px 0 20px 0;
    width: 100%;
    height: 100%;
    max-width: 425px;
    border-radius: 6px;
    border: 1px solid #e1e1e9;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    .softwareItem {
      display: flex;
      flex-direction: row;
      align-items: center;

      img { height: 45px; }

      #softwareName {
        margin-left: 0;
        color: var(--cm-primary);
        font-weight: 600;
      }
    }
  }
`;

export const ModulesContainer = styled.div`
  padding: 10px 0;
`;

export const ModulesContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModuleHeaderTitle = styled.div`
  margin: 10px 0 20px 0;
  height: 20px;
`;

export const ModuleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Divider = styled.div`
  height: 10px;
  background-color: #E5E5E5;
`;

export const HeaderTableComponent = styled.div`
  padding: 20px 20px 0 20px;

  #subtitle {
    margin-left: 6px;
    color: var(--cm-primary);
  }
`;

export const ShoppingCartContent = styled.div`
  width: 48px;
  height: 48px;
  padding: 0 10px;
  background-color: var(--cm-primary);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  margin: 20px;
  border: 1px solid #3F51B5;
  z-index: 1;
  cursor: pointer;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

  #priceHolder {
    margin-left: 6px;
    color: white;
    display: none;
    flex-direction: column;
    font-size: 12px;

    .price {
      margin-left: 6px;
      font-weight: bold;
    }
  }

  &:hover {
    width: auto;
    justify-content: space-evenly;

    #priceHolder {
      display: flex;
    }
  }
`;

export const ShoppingCartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ShoppingCartIcon = styled(MdShoppingCart)`
  margin-top: 6px;
`;

export const ShoppingCartBadge = styled.span`
  position: absolute;
  background-color: #FF9000;
  width: 18px;
  height: 18px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  font-size: 12px;
  margin-bottom: 16px;
`;

export const CountItemsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerContent = styled.div`
  padding: 0 20px;
`;

export const ContanierInfoModalModality = styled.div`
  display: flex;
  flex-direction: column;
  width: 425px;

  & > h2 {
    color: #707070;
    text-align: center;
  }

  & > span {
    margin: 16px 0;
    text-align: center;
  }
`;

export const ModalModalityActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  button {
    width: 114px;
    height: 34px;
    background: #E4EAFC;
    border: 1px solid var(--cm-primary);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 8px;

    & > span {
      font-weight: bold;
      color: var(--cm-primary);
    }
  }
`;

export const ContainerInputChackbox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > label {
    margin-left: 4px;
    color: var(--cm-primary);
  }
`;

export const InputSelect = styled(Select).attrs({
  styles: {
    option: (styles) => ({
      ...styles,
      cursor: 'pointer',
      backgroundColor: 'transparent',

      ':hover': {
        backgroundColor: '#DEEBFF',
      },

      ':active': {
        backgroundColor: '#B2D4FF',
      }
    }),
    control: (styles) => ({ ...styles, cursor: 'pointer' }),
  }
})``;

export const InputCurrencyFormat = styled(CurrencyFormat).attrs({
  displayType: 'text',
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  prefix: '',
})``;
