import React from 'react';
import { useSolicitacao } from '~/contexts/solicitation';

function SelectSolicitation() {
  const {
    inputAssunto,
    textareaTexto,
    reasonSolicitations,
    selectTipoSolicitacao,
    handleSelectTipoSolicitacaoChange,
    handleInputAssuntoChange,
    handleTextareaTextoChange,
  } = useSolicitacao();

  return (
    <form className="defaultForm" onSubmit={() => { }}>
      <div className="infoCard noPaddingTop">
        <div className="rowContainer">
          <div className="inputHolder noMarginRight">
            <span id="title">Tipo da Solicitação *</span>
            <select
              name="tipoSolicitacao"
              value={selectTipoSolicitacao}
              onChange={handleSelectTipoSolicitacaoChange}
            >
              <option value={''}>Selecione</option>
              {(reasonSolicitations || []).map(types => (
                <option key={types.id} value={types.id}>
                  {types.descricao}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="infoCard">
        <span id="title">Descrição</span>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Assunto *</span>
            <input
              type="text"
              name="assunto"
              value={inputAssunto}
              onChange={handleInputAssuntoChange}
            />
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Mensagem / Descrição *</span>
            <textarea
              name="texto"
              value={textareaTexto}
              rows={10}
              style={{
                resize: 'vertical',
                overflow: 'auto',
                maxHeight: 'none',
              }}
              onChange={handleTextareaTextoChange}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export { SelectSolicitation };
