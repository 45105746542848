export const USER_TYPE = {
  REVENDA: 'revenda',
  CLIENTE: 'cliente',
  COLABORADOR: 'colaborador',
  CONVITE: {
    REVENDA: 'revenda - convidado',
    COLABORADOR: 'colaborador - convidado',
  },
  MESSAGE: {
    REVENDA_CM: 'revenda cm',
    REVENDA: 'revenda',
    COLABORADOR_CM: 'colaborador cm',
    COLABORADOR_REVENDA: 'colaborador revenda',
  }
};
Object.freeze(USER_TYPE);

export const UNIDADES_FEDERATIVAS = [
  {
    nome: 'Acre',
    sigla: 'AC'
  },
  {
    nome: 'Alagoas',
    sigla: 'AL'
  },
  {
    nome: 'Amapá',
    sigla: 'AP'
  },
  {
    nome: 'Amazonas',
    sigla: 'AM'
  },
  {
    nome: 'Bahia',
    sigla: 'BA'
  },
  {
    nome: 'Ceará',
    sigla: 'CE'
  },
  {
    nome: 'Distrito Federal',
    sigla: 'DF'
  },
  {
    nome: 'Espírito Santo',
    sigla: 'ES'
  },
  {
    nome: 'Goiás',
    sigla: 'GO'
  },
  {
    nome: 'Maranhão',
    sigla: 'MA'
  },
  {
    nome: 'Mato Grosso',
    sigla: 'MT'
  },
  {
    nome: 'Mato Grosso do Sul',
    sigla: 'MS'
  },
  {
    nome: 'Minas Gerais',
    sigla: 'MG'
  },
  {
    nome: 'Pará',
    sigla: 'PA'
  },
  {
    nome: 'Paraíba',
    sigla: 'PB'
  },
  {
    nome: 'Paraná',
    sigla: 'PR'
  },
  {
    nome: 'Pernambuco',
    sigla: 'PE'
  },
  {
    nome: 'Piauí',
    sigla: 'PI'
  },
  {
    nome: 'Rio de Janeiro',
    sigla: 'RJ'
  },
  {
    nome: 'Rio Grande do Norte',
    sigla: 'RN'
  },
  {
    nome: 'Rio Grande do Sul',
    sigla: 'RS'
  },
  {
    nome: 'Rondônia',
    sigla: 'RO'
  },
  {
    nome: 'Roraima',
    sigla: 'RR'
  },
  {
    nome: 'Santa Catarina',
    sigla: 'SC'
  },
  {
    nome: 'São Paulo',
    sigla: 'SP'
  },
  {
    nome: 'Sergipe',
    sigla: 'SE'
  },
  {
    nome: 'Tocantins',
    sigla: 'TO'
  },
];
Object.freeze(UNIDADES_FEDERATIVAS);

export const STATUS_SOLICITATION_ENUM = {
  AGUARDANDO_ANALISE_CM: {
    label: 'Aguardando Análise CM',
    value: 'Aguardando Análise CM',
    label_small: 'Aguardando análise',
    showInManagement: false,
  },
  PENDENTE_RETORNO_CM: {
    label: 'Pendente Retorno CM',
    value: 'Pendente Retorno CM',
    label_small: 'Pendência CM',
    showInManagement: true,
  },
  PENDENTE_RETORNO_REVENDA: {
    label: 'Pendente Retorno REVENDA',
    value: 'Pendente Retorno REVENDA',
    label_small: 'Pendência Revenda',
    showInManagement: true,
  },
  EM_ANDAMENTO: {
    label: 'Em Andamento',
    value: 'Em Andamento',
    label_small: 'Em Andamento',
    showInManagement: true,
  },
  APROVADO: {
    label: 'Aprovado',
    value: 'Aprovado',
    label_small: 'Aprovado',
    showInManagement: true,
  },
  NEGADO: {
    label: 'Negado',
    value: 'Negado',
    label_small: 'Negado',
    showInManagement: true,
  },
};
Object.freeze(STATUS_SOLICITATION_ENUM);

export const STATUS_ORDER_ENUM = {
  PROPOSTA: {
    value: 'Proposta',
    label: 'Proposta',
    showInSearch: true,
    showInManagement: true,
  },
  ANALISE: {
    value: 'Análise',
    label: 'Análise',
    showInSearch: true,
    showInManagement: true,
  },
  PENDENTE_REVENDA: {
    value: 'Pendente Revenda',
    label: 'Pendente Revenda',
    showInSearch: true,
    showInManagement: true,
  },
  PENDENTE_CM: {
    value: 'Pendente CM',
    label: 'Pendente CM',
    showInSearch: true,
    showInManagement: true,
  },
  APROVADO: {
    value: 'Aprovado',
    label: 'Aprovado',
    showInSearch: false,
    showInManagement: false,
  },
  NEGADO: {
    value: 'Negado',
    label: 'Negado',
    showInSearch: true,
    showInManagement: true,
  },
  FATURADO: {
    value: 'Faturado',
    label: 'Faturado',
    showInSearch: false,
    showInManagement: false,
  },
  LIBERADO_LICENCA: {
    value: 'Liberado Licença',
    label: 'Liberado Licença',
    showInSearch: true,
    showInManagement: true,
  },
  CANCELADO: {
    value: 'Cancelado',
    label: 'Cancelado',
    showInSearch: true,
    showInManagement: true,
  },
  RASCUNHO: {
    value: 'Rascunho',
    label: 'Rascunho',
    showInSearch: true,
    showInManagement: false,
  },
};
Object.freeze(STATUS_ORDER_ENUM);

export const CALENDARIO_MESES = [
  { value: 0, label: 'Janeiro' },
  { value: 1, label: 'Fevereiro' },
  { value: 2, label: 'Março' },
  { value: 3, label: 'Abril' },
  { value: 4, label: 'Maio' },
  { value: 5, label: 'Junho' },
  { value: 6, label: 'Julho' },
  { value: 7, label: 'Agosto' },
  { value: 8, label: 'Setembro' },
  { value: 9, label: 'Outubro' },
  { value: 10, label: 'Novembro' },
  { value: 11, label: 'Dezembro' },
];
Object.freeze(CALENDARIO_MESES);

export const SOLICITATION_MESSAGE_COLORS = [
  '#e0f5ff',
  '#f7ffe3',
  '#ffe8e0',
  '#efe0ff',
  '#fffee3',
  '#e2e0ff',
  '#e2ffe0',
  '#fffde0',
  '#ffeee0',
  '#f0f0f0'
];
Object.freeze(SOLICITATION_MESSAGE_COLORS);

export const STATUS_LICENSE_ENUM = {
  APROVADO: { value: 'Aprovado', label: 'Aprovado' },
  ANALISE: { value: 'Pendente', label: 'Pendente' },
  NEGADO: { value: 'Negado', label: 'Negado' },
  TODOS: { value: 'Todos', label: 'Todos' },
};
Object.freeze(STATUS_LICENSE_ENUM);

export const TIPOS_LICENSE_ENUM = {
  TOTAL: { value: 'Total', label: 'Total' },
  PARCIAL: { value: 'Parcial', label: 'Parcial' },
};
Object.freeze(TIPOS_LICENSE_ENUM);

export const PERSON_STATUS_ENUM = {
  BLOCKED: {
    key: 'BLOCKED',
    label: 'Bloqueado',
    label2: 'Bloqueado',
    label3: 'Desativado',
    value: 'Bloqueado',
  },
  NOT_BLOCKED: {
    key: 'NOT_BLOCKED',
    label: 'Não Bloqueado',
    label2: 'Desbloqueado',
    label3: 'Ativado',
    value: 'Não Bloqueado',
  },
};
Object.freeze(PERSON_STATUS_ENUM);

export const FORM_FILLED_ENUM = {
  PREENCHIDO: 'Preenchido',
  NAO_PREENCHIDO: 'Não Preenchido',
};
Object.freeze(FORM_FILLED_ENUM);

export const FORM_STATUS_ENUM = {
  PENDENTE: { id: 1, label: 'Pendente', value: 'PENDENTE' },
  ATENDIDO: { id: 2, label: 'Atendido', value: 'ATENDIDO' },
  RASCUNHO: { id: 3, label: 'Rascunho', value: 'RASCUNHO' },
};
Object.freeze(FORM_STATUS_ENUM);

export const HELPER_STATUS_FORM = {
  IDS: {
    PENDENTE: 1,
    ATENDIDO: 2,
  },
  ID_TO_TAG: {
    1: 'Pendente',
    2: 'Atendido',
  },
  VALUES: {
    PENDENTE: 'Pendente',
    ATENDIDO: 'Atendido',
  },
  ROUTE: {
    1: 'pendente',
    2: 'atendido',
  },
};
Object.freeze(HELPER_STATUS_FORM);

export const FORM_ITEM_ASNWER_TYPE = {
  SHORT_ANSWER: {
    id: 1,
    tag: 'SHORT_ANSWER',
    value: 'Resposta Curta'
  },
  PARAGRAPH: {
    id: 2,
    tag: 'PARAGRAPH',
    value: 'Parágrafo'
  },
  MULTIPLE_CHOICE: {
    id: 3,
    tag: 'MULTIPLE_CHOICE',
    value: 'Múltipla Escolha'
  },
  SELECTION_BOX: {
    id: 4,
    tag: 'SELECTION_BOX',
    value: 'Caixa de Seleção'
  },
  TITLE: {
    id: 5,
    tag: 'TITLE',
    value: 'Titulo e Descrição'
  },
  IMAGE: {
    id: 6,
    tag: 'IMAGE',
    value: 'Imagem'
  },
  ANSWER: {
    id: 7,
    tag: 'ANSWER',
    value: 'Pergunta'
  },
  CLIENT_RESALE: {
    id: 8,
    tag: 'CLIENT_RESALE',
    value: 'Cliente e Revenda'
  },
};
Object.freeze(FORM_ITEM_ASNWER_TYPE);

export const HELPER_ITEMS = {
  ANSWERS: {
    TAG: {
      ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      SHORT_ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      PARAGRAPH: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      MULTIPLE_CHOICE: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      SELECTION_BOX: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      TITLE: FORM_ITEM_ASNWER_TYPE.TITLE.tag,
      IMAGE: FORM_ITEM_ASNWER_TYPE.IMAGE.tag,
      CLIENT_RESALE: FORM_ITEM_ASNWER_TYPE.CLIENT_RESALE.tag,
    },
    IDS: {
      ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      SHORT_ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      PARAGRAPH: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      MULTIPLE_CHOICE: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      SELECTION_BOX: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      TITLE: FORM_ITEM_ASNWER_TYPE.TITLE.id,
      IMAGE: FORM_ITEM_ASNWER_TYPE.IMAGE.id,
      CLIENT_RESALE: FORM_ITEM_ASNWER_TYPE.CLIENT_RESALE.id,
    }
  },
  DEFAULT: {
    TAG: {
      ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.tag,
      SHORT_ANSWER: FORM_ITEM_ASNWER_TYPE.SHORT_ANSWER.tag,
      PARAGRAPH: FORM_ITEM_ASNWER_TYPE.PARAGRAPH.tag,
      MULTIPLE_CHOICE: FORM_ITEM_ASNWER_TYPE.MULTIPLE_CHOICE.tag,
      SELECTION_BOX: FORM_ITEM_ASNWER_TYPE.SELECTION_BOX.tag,
      TITLE: FORM_ITEM_ASNWER_TYPE.TITLE.tag,
      IMAGE: FORM_ITEM_ASNWER_TYPE.IMAGE.tag,
      CLIENT_RESALE: FORM_ITEM_ASNWER_TYPE.CLIENT_RESALE.tag,
    },
    IDS: {
      ANSWER: FORM_ITEM_ASNWER_TYPE.ANSWER.id,
      SHORT_ANSWER: FORM_ITEM_ASNWER_TYPE.SHORT_ANSWER.id,
      PARAGRAPH: FORM_ITEM_ASNWER_TYPE.PARAGRAPH.id,
      MULTIPLE_CHOICE: FORM_ITEM_ASNWER_TYPE.MULTIPLE_CHOICE.id,
      SELECTION_BOX: FORM_ITEM_ASNWER_TYPE.SELECTION_BOX.id,
      TITLE: FORM_ITEM_ASNWER_TYPE.TITLE.id,
      IMAGE: FORM_ITEM_ASNWER_TYPE.IMAGE.id,
      CLIENT_RESALE: FORM_ITEM_ASNWER_TYPE.CLIENT_RESALE.id,
    }
  }
};
Object.freeze(HELPER_ITEMS);

export const FORM_ITEM_IMAGE_TYPE = {
  ALIGN_LEFT: {
    id: 1,
    tag: 'flex-start',
    value: 'Alinhar à Esquerda'
  },
  ALIGN_CENTER: {
    id: 2,
    tag: 'center',
    value: 'Alinhar ao Centro'
  },
  ALIGN_RIGHT: {
    id: 3,
    tag: 'flex-end',
    value: 'Alinhar à Direita'
  },
  ALTER_IMAGE: {
    id: 4,
    tag: 'alter-image',
    value: 'Alterar'
  },
};
Object.freeze(FORM_ITEM_ASNWER_TYPE);

export const FORM_ACTION_LOG_OPERATION_TYPE = {
  CREATE_RESPONSE_FORM: { TAG: 'CREATE_RESPONSE_FORM' },
  EDIT_VALUE_FIELD: { TAG: 'EDIT_VALUE_FIELD' },
  SING_FORM_RESPOSIBLE: { TAG: 'SING_FORM_RESPOSIBLE' },
  EDIT_ANALYSIS_DATE: { TAG: 'EDIT_ANALYSIS_DATE' },
  CHANGE_STATUS_FORM: { TAG: 'CHANGE_STATUS_FORM' },
};
Object.freeze(FORM_ITEM_ASNWER_TYPE);

export const SETTINGS_ENUM = {
  GET_MESSAGE_ATUAL: 'get_message_atual',
  GET_MESSAGE_CHAVE_TEST: 'get_message_chave_test',
  GET_MESSAGE_CHAVE_DESATIVADA: 'get_message_chave_desativada',
  GET_MESSAGE_CHAVE_ATIVADA: 'get_message_chave_ativada',
  GET_LOG_ROTATE_ANO: 'get_log_rotate_ano',
  GET_LOG_ROTATE_MES: 'get_log_rotate_mes',
  GET_LOG_ROTATE_DIA: 'get_log_rotate_dia',
  GET_ATIVAR_CHAVE: 'get_ativar_chave',
  GET_PRAZO_MAXIMO_GERAR_CHAVE: 'get_prazo_maximo_gerar_chave',
  GET_GERAR_COM: 'get_gerar_com',
  GET_TEST_CPF_CNPJ: 'get_test_cpf_cnpj',
  GET_TEST_CODE_SOFTWARE: 'get_test_code_software',
  GET_TEST_SERIE: 'get_test_serie',
  GET_TEST_CONTROLE: 'get_test_controle',
  GET_TEST_VERSAO: 'get_test_versao',
  GET_CARENCIA_PAGAMENTO: 'get_carencia_pagamento',
  GET_ATIVAR_CARENCIA_PAGAMENTO: 'get_ativar_carencia_pagamento',
  GET_CORES_METAS: 'get_cores_metas',
  GET_ATIVAR_MANUTENCAO_PORTAL: 'get_ativar_manutencao_portal',
};
Object.freeze(SETTINGS_ENUM);

export const FIELDS_ENUM = {
  FIELED_PERSON_STATUS: {
    label: 'Status do Usuário',
    value: 'FIELED_PERSON_STATUS',
  },
  FIELED_KEY_RENEWAL_TERM: {
    label: 'Prazo de Renovação de Chave',
    value: 'FIELED_KEY_RENEWAL_TERM',
  },
};
Object.freeze(FIELDS_ENUM);

export const LOG_ACTIONS = {
  LOG_CREATE: {
    label: 'Criar',
    value: 'logtype_create',
  },
  LOG_FIND: {
    label: 'Buscar',
    value: 'logtype_find',
  },
  LOG_DELETE: {
    label: 'Deletar',
    value: 'logtype_delete',
  },
  LOG_UPDATE: {
    label: 'Atualizar',
    value: 'logtype_update',
  },
  LOG_CANCEL: {
    label: 'Cancelar',
    value: 'logtype_cancel',
  },
};
Object.freeze(LOG_ACTIONS);

export const BOARD_COL_ENUM = {
  WIN: 'win',
  LOST: 'lost',
  DEFAULT: 'default',
};
Object.freeze(BOARD_COL_ENUM);

export const OPORTUNIDADE_ADD_TYPE_ENUM = {
  NOTES: 'notes',
  DOCUMENTS: 'docs',
  TASKS: 'tasks',
  COMMITMENTS: 'commitments',
};
Object.freeze(OPORTUNIDADE_ADD_TYPE_ENUM);

export const OPORTUNIDADE_MENSAGE_ENUM = {
  ACTION_CONCLUDE: 'action_conclude',
  ACTION_DELETE: 'action_delete',
};
Object.freeze(OPORTUNIDADE_ADD_TYPE_ENUM);

export const FUNIL_MODAL_ENUM = {
  REVENDA: 'revenda',
  RESPONSAVEL: 'responsável',
  LOCALIZACAO: 'localização',
  LEMBRETE: 'lembrete',
};
Object.freeze(FUNIL_MODAL_ENUM);

export const MODAL_COL_WIN_ENUM = {
  REVISION: 'revision',
  VALIDATED: 'validated',
};
Object.freeze(MODAL_COL_WIN_ENUM);

export const FUNIL_STATUS = {
  LATE: {
    label: 'Atrasado',
    value: 'late',
  },
  IN_DAY: {
    label: 'No dia',
    value: 'inDay',
  },
  LAST_ONE_DAY: {
    label: 'Falta 1 dia',
    value: 'lastOneDay',
  },
  ENUM: {
    LATE: 'late',
    IN_DAY: 'inDay',
    LAST_ONE_DAY: 'lastOneDay',
  }
};
Object.freeze(FUNIL_STATUS);

export const LEMBRETE_SECAO_ENUM = {
  ADD: 'add',
  EDIT: 'edit',
  LIST: 'list',
  CONCLUDES: 'concludes',
};
Object.freeze(LEMBRETE_SECAO_ENUM);
