import { getSameDate } from '~/util/dateutils';

import Address from '~/model/address';
import Contact from '~/model/contact';

export default class PersonEmployeeEdit {
  constructor(props) {
    const {
      id,
      grupoId,
      status,
      cpfCnpj,
      nomeSocial,
      endereco,
      contato,
      createdAt,
      dataCadastro,
      imagem,
      revendaId,
    } = props;

    this.id = id;
    this.grupoId = grupoId;
    this.status = status;
    this.cpfCnpj = cpfCnpj;
    this.nomeSocial = nomeSocial;

    if (endereco) {
      const address = new Address(endereco);
      this.estado = address.estado;
      this.cidade = address.cidade;
      this.cep = address.cep;
      this.bairro = address.bairro;
      this.endereco = address.endereco;
      this.complemento = address.complemento;
      this.pontoReferencia = address.pontoReferencia;
    }

    if (contato) {
      const contact = new Contact(contato);
      this.telefone1 = contact.telefone1;
      this.telefone2 = contact.telefone2;
      this.email = contact.email;
    }

    if (createdAt) {
      this.dataCadastro = getSameDate(createdAt, dataCadastro);
    }

    this.imagem = imagem;
    this.revendaId = revendaId;
  }
}

/*
POST
  {
    "id": 123,
    "grupoId": 123,
    "cpfCnpj": "1531646156614",
    "nomeSocial": "Teste S",
    "estado": "CEARÁ",
    "cidade": "Fortaleza",
    "cep": "60000000",
    "bairro": "Aldeota",
    "endereco": "Avenida X",
    "status": "Não Bloqueado",
    "complemento": "BL 84 apto 754",
    "pontoReferencia": "Aqui",
    "telefone1": "9999999999",
    "telefone2": "8888888888",
    "email": "teste@email.com",
    "dataCadastro": "JS Date",
    "imagem": "Base64 image",
    "revendaId": "1",
  }
**/
