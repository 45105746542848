import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Table = styled.table`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 10px;

  tr {
    display: flex;
    font-size: 15px;
    color: #979797;

    @media (max-width: 700px) {
      font-size: 10px;
    }

    th {
      box-shadow: 0 1px 3px rgb(0, 0, 0, 0.16);
      display: flex;
      flex: 1;
      justify-content: center;
      background: #fff;
      padding: 13px 5px;
    }

    td {
      border: 1px solid rgb(0, 0, 0, 0.1);
      border-top-width: 0px;
      display: flex;
      flex: 1;
      justify-content: center;
      padding: 13px 5px;

      :last-child {
        border-right-width: 0px;
      }

      :first-child {
        border-left-width: 0px;
      }
    }
  }
`;

export const Footer = styled.div`
  background: #e5e5e5;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
`;

export const TotalText = styled.span`
  color: #979797;
  font-size: 15px;

  @media (max-width: 700px) {
    font-size: 10px;
  }
`;

export const TotalValueText = styled.span`
  color: #8683f2;
  font-size: 15px;

  @media (max-width: 700px) {
    font-size: 10px;
  }
`;
