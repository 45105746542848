import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import Modal from '~/components/Modal';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import ProductSoftwareService from '~/services/product-software-service';
import ProductModuleService from '~/services/product-module-service';
import FormItemNew from '~/model/form-item-new';
import { formPaths } from '~/routes/paths';
import { v4 as uuid_v4 } from 'uuid';
import { FormDragDropContext } from '../../Items/FormDragDropContext';
import { FormActions } from '../../Items/FormActions';
import { FORM_ITEM_ASNWER_TYPE as TAGS } from '~/util/domainutils';
import { Container, ContainerForm } from '../../styles';
import '../../styles.scss';

function AddRegister({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [formName, setFormName] = useState('');
  const [softwares, setSoftwares] = useState([]);
  const [allSoftwares, setAllSoftwares] = useState([]);
  const [softwareSelectedId, setSoftwareSelectedId] = useState(0);
  const [modules, setModules] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [moduleSelectedId, setModuleSelectedId] = useState(0);

  const [form, setForm] = useState([
    new FormItemNew({ id: uuid_v4(), tag: 'IMAGE' }),
    new FormItemNew({ id: uuid_v4(), tag: 'CLIENT_RESALE' }),
    new FormItemNew({ id: uuid_v4(), tag: 'TITLE' }),
    new FormItemNew({ id: uuid_v4(), tag: 'ANSWER' }),
  ]);

  const [formData, setFormData] = useState({
    name: '',
    moduleId: null,
    moduleName: '',
    softwareId: null,
    softwareName: '',
    contentItemsForm: [],
  });

  const [errorName, setErrorName] = useState(false);
  const [errorModuleId, setErrorModuleId] = useState(false);
  const [errorSoftwareId, setErrorSoftwareId] = useState(false);
  const [errorFormItems, setErrorFormItems] = useState([]);

  useEffect(() => {
    let cleaningAsyncEffect = false;

    const asyncEffect = async () => {
      if (cleaningAsyncEffect) return;
      setLoading(true);
      try {
        const [softwares, modules] = await Promise.all([
          ProductSoftwareService.getProductSoftwares({}),
          ProductModuleService.getProductModules({}),
        ]);

        const modulesActives = modules.resultado
          .filter(module => module.ativo && module);

        setAllSoftwares(softwares.resultado);
        setSoftwares(softwares.resultado);
        setAllModules(modulesActives);
        setModules(modulesActives);
      } catch (error) {
        setAllSoftwares([]);
        setSoftwares([]);
        setAllModules([]);
        setModules([]);

        showMessageError(error);
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();

    return () => {
      cleaningAsyncEffect = true;
    };
  }, []);

  useEffect(() => {
    if (!location.state) return;

    if (location.state) {
      const {
        name,
        softwareId,
        softwareName,
        moduleId,
        moduleName,
        contentItemsForm,
      } = location.state.formViewer;

      setFormName(name);
      setSoftwareSelectedId(Number(softwareId));
      setModuleSelectedId(Number(moduleId));

      setFormData({
        ...formData,
        name, softwareId, softwareName, moduleId, moduleName,
      });

      setForm(contentItemsForm);

      location.state.formViewer = null;
    }

    return () => {
      location.state = null;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleAddNewTitle() {
    const titleCreated = new FormItemNew({
      id: uuid_v4(),
      tag: TAGS.TITLE.tag,
    });

    setForm([...form, titleCreated]);

    handleScroll('formEnd');
  }

  function handleAddNewAnswer() {
    const answerCreated = new FormItemNew({
      id: uuid_v4(),
      tag: TAGS.ANSWER.tag
    });

    setForm([...form, answerCreated]);

    handleScroll('formEnd');
  }

  function handleAddNewImage() {
    const imageCreated = new FormItemNew({
      id: uuid_v4(),
      tag: TAGS.IMAGE.tag
    });

    setForm([...form, imageCreated]);

    handleScroll('formEnd');
  }

  function handleFormName(event) {
    const {
      target: {
        value
      }
    } = event;

    setFormName(value);
    setFormData({ ...formData, name: value });
    setErrorName(false);
  }

  function handleSelectedSoftwareId(event) {
    const {
      target: {
        value: softwareId
      }
    } = event;

    let dataTemp = {};

    if (Number(softwareId) !== 0) {
      const modulesFilted = allModules.filter(module => {
        return module.softwareId === Number(softwareId);
      });

      const [software] = allSoftwares.filter(software => {
        return software.id === Number(softwareId);
      });

      setModules(modulesFilted);

      dataTemp = {
        ...dataTemp,
        softwareId: Number(softwareId),
        softwareName: software.nome,
      };
    } else {
      setModules(allModules);
      setModuleSelectedId(0);

      dataTemp = {
        ...dataTemp,
        softwareId: null,
        softwareName: '',
      };
    }

    setSoftwareSelectedId(Number(softwareId));
    setFormData({ ...formData, ...dataTemp });
    setErrorSoftwareId(false);
  }

  function handleSelectedModuleId(event) {
    const {
      target: {
        value: moduleId
      }
    } = event;

    let dataTemp = {};

    if (Number(moduleId) !== 0) {
      const [module] = allModules.filter(module => {
        return module.id === Number(moduleId);
      });

      const [software] = allSoftwares.filter(software => {
        return software.id === module.softwareId;
      });

      setSoftwareSelectedId(module.softwareId);

      dataTemp = {
        ...dataTemp,
        moduleId: Number(moduleId),
        moduleName: module.nome,
        softwareId: module.softwareId,
        softwareName: software.nome,
      };

      if (errorSoftwareId) {
        setErrorSoftwareId(false);
      }
    } else {
      setSoftwares(allSoftwares);

      dataTemp = {
        ...dataTemp,
        moduleId: Number(moduleId),
        moduleName: '',
        softwareId: formData.softwareId,
        softwareName: '',
      };

      setErrorSoftwareId(false);
    }

    setModuleSelectedId(Number(moduleId));
    setFormData({ ...formData, ...dataTemp });
    setErrorModuleId(false);
  }

  function handleRemoveItemForm(formId) {
    setForm([...form.filter(form => form.id !== formId)]);
  }

  function handleDuplicateItemForm(formId, itemIndex) {
    let previousItems = [];
    let successorItems = [];

    const [item] = form.filter((item, index) => {
      if (index <= itemIndex)
        previousItems.push(item);
      else
        successorItems.push(item);

      return item.id === formId;
    });

    let duplicateItem = null;

    if (item.itemTag === TAGS.IMAGE.tag) {
      duplicateItem = {
        id: uuid_v4(),
        itemId: TAGS.IMAGE.id,
        itemTag: TAGS.IMAGE.tag,
        itemData: {
          statement: null,
          description: null,
          itemContent: {
            align: item.itemData.itemContent.align,
            src: item.itemData.itemContent.src,
            object: item.itemData.itemContent.object
          },
          itemTypeId: item.itemData.itemTypeId,
          orderNumber: null,
          required: item.itemData.required
        },
        isTitle: false,
        showRequired: false,
        unshowActions: false,
      };
    }

    if (item.itemTag === TAGS.ANSWER.tag) {
      duplicateItem = {
        id: uuid_v4(),
        itemId: TAGS.ANSWER.id,
        itemTag: TAGS.ANSWER.tag,
        itemData: {
          statement: item.itemData.statement,
          description: item.itemData.description,
          itemContent: null,
          itemTypeId: item.itemData.itemTypeId,
          orderNumber: null,
          required: item.itemData.required
        },
        isTitle: false,
        showRequired: true,
        unshowActions: false,
      };
    }

    if (item.itemTag === TAGS.TITLE.tag) {
      duplicateItem = {
        id: uuid_v4(),
        itemId: TAGS.TITLE.id,
        itemTag: TAGS.TITLE.tag,
        itemData: {
          statement: item.itemData.statement,
          description: item.itemData.description,
          itemContent: null,
          itemTypeId: item.itemData.itemTypeId,
          orderNumber: null,
          required: item.itemData.required
        },
        isTitle: true,
        showRequired: true,
        unshowActions: false,
      };
    }

    setForm([...previousItems, duplicateItem, ...successorItems]);
  }

  function handleScroll(elementId) {
    setTimeout(() => {
      const element = document.getElementById(elementId);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 200);
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleClear() {
    setFormName('');
    setSoftwares([]);
    setAllSoftwares([]);
    setSoftwareSelectedId(0);
    setModules([]);
    setAllModules([]);
    setModuleSelectedId(0);
    setForm([]);

    setFormData({
      name: '',
      moduleId: null,
      moduleName: '',
      softwareId: null,
      softwareName: '',
      contentItemsForm: [],
    });

    setErrorName(false);
    setErrorModuleId(false);
    setErrorSoftwareId(false);
    setErrorFormItems([]);

    location.state = null;
  }

  const formatFormViewer = useCallback(async (success) => {
    const payloadViewer = {
      ...formData,
      contentItemsForm: form.map((f, index) => {
        f.itemData.orderNumber = index;

        return f;
      }),
    };

    await success(payloadViewer);
  }, [form, formData]);

  const handleViewer = useCallback(async () => {
    await formatFormViewer(formViewer => {
      setAllSoftwares([]);
      setSoftwares([]);
      setAllModules([]);
      setModules([]);
      history.push(formPaths.formViewer, {
        formViewer, pathReferenceToBack: formPaths.addFormRegister,
      });
    });
  }, [formatFormViewer, history]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function validateForm(success) {
    const formDataSubmit = {};
    let errors_temp = false;
    let error_at_least_one_asnwer = false;

    if (form.length) {
      const items = form.map(item => item.itemTag);

      if (!items.includes(TAGS.ANSWER.tag)) {
        error_at_least_one_asnwer = true;
        errors_temp = true;
      }

      const errorItemsTemp = [];
      form.forEach((item, itemIndex) => {
        if (item.itemTag === TAGS.CLIENT_RESALE.tag
          || item.itemTag === TAGS.IMAGE.tag
        ) {
          errorItemsTemp.push({
            itemIndex,
            itemId: item.id,
            errorSubItem: [],
            errorItem: false,
          });
        }

        let errors_title = false;

        if (item.itemTag === TAGS.TITLE.tag) {
          if (!item.itemData.statement || !item.itemData.statement.length) {
            errorItemsTemp.push({
              itemIndex,
              itemId: item.id,
              errorSubItem: [],
              errorItem: true,
            });

            errors_title = true;
          } else {
            errorItemsTemp.push({
              itemIndex,
              itemId: item.id,
              errorSubItem: [],
              errorItem: false,
            });
          }
        }

        let errors_answer = false;

        if (item.itemTag === TAGS.ANSWER.tag) {
          let errorTemp = {
            itemIndex,
            itemId: item.id,
            errorSubItem: [],
            errorItem: false,
          };

          if (!item.itemData.statement || !item.itemData.statement.length) {
            errorTemp = {
              itemIndex,
              itemId: item.id,
              errorSubItem: [],
              errorItem: true,
            };

            errors_answer = true;
          } else {
            errorTemp = {
              itemIndex,
              itemId: item.id,
              errorSubItem: [],
              errorItem: false,
            };

            errors_temp = false;
          }

          if (item.itemData.itemTypeId === 3 || item.itemData.itemTypeId === 4) {
            if (item.itemData.itemContent && item.itemData.itemContent.length) {
              const errorSubItemTemp = [];

              item.itemData.itemContent.forEach((subItem, subItemIndex) => {
                let errorSubItemValuesTemp = {};

                if ((!subItem.itemData.value || !subItem.itemData.value.length)) {
                  if (subItem.other) {
                    errorSubItemValuesTemp = {
                      subItemIndex,
                      subItemId: subItem.id,
                      errorSubItem: false,
                    };
                  } else {
                    errorSubItemValuesTemp = {
                      subItemIndex,
                      subItemId: subItem.id,
                      errorSubItem: true,
                    };

                    errors_answer = true;
                  }
                } else {
                  errorSubItemValuesTemp = {
                    subItemIndex,
                    subItemId: subItem.id,
                    errorSubItem: false,
                  };
                }

                errorSubItemTemp.push(errorSubItemValuesTemp);
              });

              const isOptionError = errorSubItemTemp.map(subItem => {
                return subItem.errorSubItem;
              });

              if (errors_answer && isOptionError.includes(true)) {
                errorTemp = {
                  itemIndex,
                  itemId: item.id,
                  errorSubItem: errorSubItemTemp,
                  errorItem: true,
                };

                errors_answer = true;
              } else {
                errorTemp = {
                  itemIndex,
                  itemId: item.id,
                  errorSubItem: errorSubItemTemp,
                  errorItem: false,
                };
              }
            }
          }

          errorItemsTemp.push(errorTemp);
        }

        const isErrorItem = errorItemsTemp.map(item => {
          return item.errorItem;
        });

        if (isErrorItem.includes(true)) {
          errors_temp = true;
        }

        if (errors_title || errors_answer) {
          errors_temp = true;
        }
      });

      setErrorFormItems(errorItemsTemp);
    }

    if (!formName || !formName.length) {
      setErrorName(true);
      errors_temp = true;
    }

    if (!formData.moduleId || formData.moduleId === 0) {
      setErrorModuleId(true);
      errors_temp = true;
    }

    if (!formData.softwareId || formData.softwareId === 0) {
      setErrorSoftwareId(true);
      errors_temp = true;
    }

    if (errors_temp) {
      if (error_at_least_one_asnwer) {
        toast.error('É preciso ter pelo menos uma pergunta');
      }

      setTimeout(() => {
        const element = document.getElementById('fieldsRequiredId');

        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);

      return;
    }

    formDataSubmit.name = formData.name;
    formDataSubmit.moduleId = formData.moduleId;
    formDataSubmit.contentItemsForm = form.map((f, index) => {
      let itemContentToJson = null;

      if (f.itemData.itemContent) {
        let objectFileToJson = null;

        if (f.itemData.itemContent.object) {
          const { object: fileObject } = f.itemData.itemContent;

          objectFileToJson = {
            lastModified: fileObject.lastModified,
            lastModifiedDate: fileObject.lastModifiedDate,
            name: fileObject.name,
            size: fileObject.size,
            type: fileObject.type,
            webkitRelativePath: fileObject.webkitRelativePath,
          };

          objectFileToJson = JSON.stringify(objectFileToJson);

          f.itemData.itemContent.object = objectFileToJson;
        }

        itemContentToJson = JSON.stringify(f.itemData.itemContent);
      }

      const formItem = {
        statement: f.itemData.statement,
        description: f.itemData.description,
        itemContent: itemContentToJson,
        itemTypeId: f.itemData.itemTypeId,
        orderNumber: index,
        required: f.itemData.required,
      };

      return formItem;
    });

    await success(formDataSubmit);
  }

  const handleSubmit = useCallback(async () => {
    await validateForm(async (form) => {
      setLoading(true);
      try {
        const { data } = await api.post(endpoints.form.register.createForm, form);

        if (data.data.ok) {
          toast.success(data.message);
          handleClear();
          setTimeout(() => {
            history.push(formPaths.formRegister);
          }, 100); // 1 milesegundo
        }
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });
  }, [validateForm, handleClear, history]);

  function handleBackRegister() {
    handleClear(); history.push(formPaths.formRegister);
  }

  return (
    <>
      <div id='formTop' />
      <Modal loading show={loading} />
      <Container>
        <SidebarPageHeader
          mainMenu="Formulário"
          pageName="Novo Formulário"
          breadcrumbs={[{
            label: 'Cadastro',
            onClick: () => handleBackRegister,
          }]}
          button1={{
            label: 'Salvar',
            onClick: () => handleSubmit,
            main: true,
          }}
          button2={{
            label: 'Visualizar',
            onClick: () => handleViewer,
            main: false,
          }}
          openButtonSelect
          buttonCancelHandler={handleBackRegister}
        />
        <div className="defaultFormContainer">
          <form className="defaultForm">
            <div className="infoCard">
              <div style={{ marginBottom: 20 }}>
                <span style={{ color: 'red' }}>
                  {'Campos com (*) são obrigatórios'}
                </span>
              </div>
              <span id="title">Dados do formulário</span>
              <div className="rowContainer">
                {errorName && <div id='fieldsRequiredId' />}
                <div className="inputHolder defaultFlex">
                  <span>Nome *</span>
                  <input
                    type="text"
                    name="formName"
                    value={formName}
                    onChange={handleFormName}
                  />
                  {errorName && (
                    <small style={{ color: 'red' }}>Campo obrigatório</small>
                  )}
                </div>
              </div>
              <div className="rowContainer">
                <div className="inputHolder widthSelectDefault">
                  <span>Software *</span>
                  {errorSoftwareId && <div id='fieldsRequiredId' />}
                  <select
                    name="software"
                    value={softwareSelectedId}
                    onChange={handleSelectedSoftwareId}
                  >
                    <option value={0}>Selecione</option>
                    {softwares.map(module => (
                      <option key={module.id} value={module.id}>
                        {module.nome}
                      </option>
                    ))}
                  </select>
                  {errorSoftwareId && (
                    <small style={{ color: 'red' }}>Campo obrigatório</small>
                  )}
                </div>
                <div className="inputHolder widthSelectDefault">
                  <span>Módulo *</span>
                  {errorModuleId && <div id='fieldsRequiredId' />}
                  <select
                    name="modulo"
                    value={moduleSelectedId}
                    onChange={handleSelectedModuleId}
                  >
                    <option value={0}>Selecione</option>
                    {modules.map(software => (
                      <option key={software.id} value={software.id}>
                        {software.nome}
                      </option>
                    ))}
                  </select>
                  {errorModuleId && (
                    <small style={{ color: 'red' }}>Campo obrigatório</small>
                  )}
                </div>
              </div>
            </div>
            <div id='itemsFormContainer' className="infoCard infoCardBase">
              <span id="title">Itens do formulário</span>
              <ContainerForm className='rowContainerWithActions'>
                <FormDragDropContext
                  form={form}
                  setForm={setForm}
                  errorForm={errorFormItems}
                  setErrorFormItems={setErrorFormItems}
                  handleRemoveItemForm={handleRemoveItemForm}
                  handleDuplicateItemForm={handleDuplicateItemForm}
                />
                <FormActions
                  handleAddNewAnswer={handleAddNewAnswer}
                  handleAddNewTitle={handleAddNewTitle}
                  handleAddNewImage={handleAddNewImage}
                  handleScrollTop={() => handleScroll('formTop')}
                  handleScrollEnd={() => handleScroll('formEnd')}
                />
              </ContainerForm>
            </div>
          </form>
        </div>
      </Container>
      <div id='formEnd' />
    </>
  );
}

AddRegister.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      formViewer: PropTypes.shape({}),
    }),
  }),
};

export default AddRegister;
