import PropTypes from 'prop-types';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { InputPerson } from '~/components/InputPerson';
import { CollapsibleFilter } from '~/components/CollapsibleFilter';
import { STATUS_LICENSE_ENUM, TIPOS_LICENSE_ENUM } from '~/util/domainutils';
import './styles.scss';

function PersonSearch({
  countFilter,
  userCanViewColumnRevenda,
  handleSubmit,
  inputClienteValue,
  handleClienteChange,
  inputRevendaValue,
  handleRevendaChange,
  serie,
  handleInputSerieChange,
  dataInicio,
  handleDataInicioChange,
  dataFim,
  handleDataFimChange,
  radioStatus,
  handleRadioStatusChange,
  radioTipo,
  handleRadioTipoChange,
  softwareValue,
  handleSoftwareChange,
  motivoValue,
  handleMotivoChange,
  softwares,
  motivosCancelamento,
}) {
  return (
    <CollapsibleFilter countFilters={countFilter}>
      <form className="formContainer" onSubmit={handleSubmit}>
        <label id="searchText">Pesquisar</label>
        <div className="rowContainer">
          <InputPerson
            title1='Cliente'
            inputPerson1={inputClienteValue}
            handlePerson1Change={handleClienteChange}
            title2='Revenda'
            inputPerson2={inputRevendaValue}
            handlePerson2Change={handleRevendaChange}
            userCanViewPerson2={userCanViewColumnRevenda}
          />
        </div>
        <div className="rowContainer">
          <div className="inputHolder inputSerieWidth">
            <span>Série</span>
            <input
              id="serie"
              type="text"
              name="serie"
              value={serie}
              onChange={handleInputSerieChange}
            />
          </div>
          <div className="inputHolder defaultFlex">
            <span>Software</span>
            <select
              name="software"
              value={softwareValue}
              onChange={handleSoftwareChange}
            >
              <option value="">Selecione</option>
              {softwares.map(item => (
                <option key={item.id} value={item.nome}>
                  {item.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Motivo</span>
            <select
              name="motivoCancelamento"
              value={motivoValue}
              onChange={handleMotivoChange}
            >
              <option value="">Selecione</option>
              {motivosCancelamento.map(motivo => (
                <option key={motivo.id} value={motivo.id}>
                  {motivo.descricao} {motivo.ativo ? '' : '(excluído)'}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder">
            <span>Status</span>
            <div id="statusHolder">
              <div className="radioHolder">
                <div className="inputBoxHolder">
                  <input
                    type="checkbox"
                    name="status"
                    value={STATUS_LICENSE_ENUM.APROVADO.value}
                    checked={radioStatus === STATUS_LICENSE_ENUM.APROVADO.value}
                    onChange={handleRadioStatusChange}
                  />
                  <span className="checkmark">
                    {radioStatus === STATUS_LICENSE_ENUM.APROVADO.value && (
                      <div className="markHolder">
                        <div className="checkmark_stem" />
                        <div className="checkmark_kick" />
                      </div>
                    )}
                  </span>
                </div>
                <span id="radioLabel">{STATUS_LICENSE_ENUM.APROVADO.label}</span>
              </div>
              <div className="radioHolder">
                <div className="inputBoxHolder">
                  <input
                    type="checkbox"
                    name="status"
                    value={STATUS_LICENSE_ENUM.ANALISE.value}
                    checked={radioStatus === STATUS_LICENSE_ENUM.ANALISE.value}
                    onChange={handleRadioStatusChange}
                  />
                  <span className="checkmark">
                    {radioStatus === STATUS_LICENSE_ENUM.ANALISE.value && (
                      <div className="markHolder">
                        <div className="checkmark_stem" />
                        <div className="checkmark_kick" />
                      </div>
                    )}
                  </span>
                </div>
                <span id="radioLabel">{STATUS_LICENSE_ENUM.ANALISE.label}</span>
              </div>
              <div className="radioHolder">
                <div className="inputBoxHolder">
                  <input
                    type="checkbox"
                    name="status"
                    value={STATUS_LICENSE_ENUM.NEGADO.value}
                    checked={radioStatus === STATUS_LICENSE_ENUM.NEGADO.value}
                    onChange={handleRadioStatusChange}
                  />
                  <span className="checkmark">
                    {radioStatus === STATUS_LICENSE_ENUM.NEGADO.value && (
                      <div className="markHolder">
                        <div className="checkmark_stem" />
                        <div className="checkmark_kick" />
                      </div>
                    )}
                  </span>
                </div>
                <span id="radioLabel">{STATUS_LICENSE_ENUM.NEGADO.label}</span>
              </div>
            </div>
          </div>
          <div className="inputHolder defaultFlex">
            <span>Tipo</span>
            <div id="statusHolder">
              <div className="radioHolder">
                <div className="inputBoxHolder">
                  <input
                    type="checkbox"
                    name="status"
                    value={TIPOS_LICENSE_ENUM.PARCIAL.value}
                    checked={radioTipo === TIPOS_LICENSE_ENUM.PARCIAL.value}
                    onChange={handleRadioTipoChange}
                  />
                  <span className="checkmark">
                    {radioTipo === TIPOS_LICENSE_ENUM.PARCIAL.value && (
                      <div className="markHolder">
                        <div className="checkmark_stem" />
                        <div className="checkmark_kick" />
                      </div>
                    )}
                  </span>
                </div>
                <span id="radioLabel">{TIPOS_LICENSE_ENUM.PARCIAL.label}</span>
              </div>
              <div className="radioHolder">
                <div className="inputBoxHolder">
                  <input
                    type="checkbox"
                    name="status"
                    value={TIPOS_LICENSE_ENUM.TOTAL.value}
                    checked={radioTipo === TIPOS_LICENSE_ENUM.TOTAL.value}
                    onChange={handleRadioTipoChange}
                  />
                  <span className="checkmark">
                    {radioTipo === TIPOS_LICENSE_ENUM.TOTAL.value && (
                      <div className="markHolder">
                        <div className="checkmark_stem" />
                        <div className="checkmark_kick" />
                      </div>
                    )}
                  </span>
                </div>
                <span id="radioLabel">{TIPOS_LICENSE_ENUM.TOTAL.label}</span>
              </div>
            </div>
          </div>
          <div className="inputHolder defaultFlex">
            <span>Período</span>
            <div id="dates">
              <InputMask
                mask="99/99/9999"
                name="initialDate"
                value={dataInicio}
                onChange={handleDataInicioChange}
              />
              <span id="dateSeparator">à</span>
              <InputMask
                mask="99/99/9999"
                style={{ paddingLeft: '10px' }}
                name="finalDate"
                value={dataFim}
                onChange={handleDataFimChange}
              />
            </div>
          </div>
          <button className="buttonBaseOnly" id="baseButtonIcon" onClick={handleSubmit}>
            <FaSearch id="baseNameIcon" />
            <span>BUSCAR</span>
          </button>
        </div>
      </form>
    </CollapsibleFilter>
  );
}

PersonSearch.propTypes = {
  countFilter: PropTypes.number,

  dataInicio: PropTypes.string,
  handleDataInicioChange: PropTypes.func,
  dataFim: PropTypes.string,
  handleDataFimChange: PropTypes.func,
  radioStatus: PropTypes.string,
  handleRadioStatusChange: PropTypes.func,
  radioTipo: PropTypes.string,
  handleRadioTipoChange: PropTypes.func,
  serie: PropTypes.string,
  handleInputSerieChange: PropTypes.func,
  softwareValue: PropTypes.string,
  handleSoftwareChange: PropTypes.func,
  motivoValue: PropTypes.string,
  handleMotivoChange: PropTypes.func,

  inputClienteValue: PropTypes.string,
  handleClienteChange: PropTypes.func,
  inputRevendaValue: PropTypes.string,
  handleRevendaChange: PropTypes.func,

  handleSubmit: PropTypes.func.isRequired,
  userCanViewColumnRevenda: PropTypes.bool.isRequired,
  softwares: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  motivosCancelamento: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default PersonSearch;
