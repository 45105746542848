import styled from 'styled-components';

export const ImageContent = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
  }
`;

export const ModalContent = styled.div`
  width: 422px;
  display: flex;
  flex-direction: column;
  justify-items: center;
  text-align: center;

  header {
    h3 {
      color: #707070
    };
  }

  p {
    margin: 16px 0;
  }

  div {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    button {
      width: 114px;
      justify-content: center;
    }
  }
`;
