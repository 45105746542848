import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FormMultipleOptionItem } from '../FormMultipleOptionItem';
import {
  DraggableItem,
  DroppableContainer,
  DraggableHandle,
  DraggableRow,
  DraggableContainerItem,
} from './styles';
import { DragIndicatorIcon, Delete2Icon } from '../styles';
import '../styles.scss';

function FormMultipleOptions({
  form,
  setForm,
  formItemIndex,
  options,
  setOptions,
  handleRemoveOption,
  errorForm,
  setErrorFormItems,
}) {
  function reorderDragDrop(list, startIndex, endIndex) {
    const result = Array.from(list);

    const optionOption = [];
    const resultWithoutOption = [];
    result.forEach(option => {
      if (!option.other) {
        resultWithoutOption.push(option);
      } else {
        optionOption.push(option);
      }
    });

    const [removed] = resultWithoutOption.splice(startIndex, 1);

    resultWithoutOption.splice(endIndex, 0, removed);

    if (optionOption.length) {
      resultWithoutOption.push(optionOption[0]);
    }

    const resultReorder = resultWithoutOption
      .map((optObj, itemIndex) => ({ ...optObj, itemIndex }));

    return resultReorder;
  }

  function onDragEnd(result) {
    if (!result.destination) return;

    if (result.type === 'form-multiple-option') {
      const newOrderOptionsItems = reorderDragDrop(
        options,
        result.source.index,
        result.destination.index
      );

      const formObj = [...form];
      formObj[formItemIndex].itemData.itemContent = newOrderOptionsItems;

      setForm(formObj);
      setOptions(newOrderOptionsItems);
    }
  }

  const handleItem = useCallback((_, index) => {
    return (
      <FormMultipleOptionItem
        form={form}
        setForm={setForm}
        formItemIndex={formItemIndex}
        options={options}
        setOptions={setOptions}
        optionIndex={index}
        optionName={_.other ? 'Outro' : `Opção ${index + 1}`}
        errorForm={errorForm}
        setErrorFormItems={setErrorFormItems}
      />
    );
  }, [
    options,
    setOptions,
    form,
    setForm,
    formItemIndex,
    errorForm,
    setErrorFormItems,
  ]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={'droppable-options'}
        direction={'vertical'}
        type={'form-multiple-option'}
      >
        {(provided, snapshot) => (
          <DroppableContainer
            ref={provided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
            {...provided.droppableProps}
          >
            {options.map((option, index) => (
              <Draggable
                key={`${option.id}`}
                draggableId={`${option.id}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <DraggableRow
                    isDragging={snapshot.isDragging}
                    ref={provided.innerRef}
                    style={{ ...provided.draggableProps.style }}
                    {...provided.draggableProps}
                  >
                    <DraggableHandle {...provided.dragHandleProps}>
                      {!option.other && <DragIndicatorIcon />}
                    </DraggableHandle>
                    <DraggableContainerItem>
                      <span id='label-option'>
                        {option.other ? 'Outro *' : `Opção ${index + 1} *`}
                      </span>
                      <DraggableItem>
                        {handleItem(option, index)}
                      </DraggableItem>
                    </DraggableContainerItem>
                    <Delete2Icon onClick={() => handleRemoveOption(option.id)} />
                  </DraggableRow>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </DroppableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
}

FormMultipleOptions.propTypes = {
  form: PropTypes.array,
  setForm: PropTypes.func,
  formItemIndex: PropTypes.number,
  options: PropTypes.array,
  setOptions: PropTypes.func,
  handleRemoveOption: PropTypes.func,
  errorForm: PropTypes.array,
  setErrorFormItems: PropTypes.func,
};

export { FormMultipleOptions };
