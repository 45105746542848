import React from 'react';
import PropTypes from 'prop-types';
import { CheckCircleIcon, BanIcon } from './styles';
import './styles.scss';

function StatusType({ ativo }) {
  return (
    <>
      {ativo ? (
        <div className="defaultStatusHolder defaultStatusActive">
          <CheckCircleIcon title="Ativado" className="statusIcon" />
          <span className="statusText">Disponível</span>
        </div>
      ) : (
        <div className="defaultStatusHolder defaultStatusInactive">
          <BanIcon title="Desativado" className="statusIcon" />
          <span className="statusText">Indisponível</span>
        </div>
      )}
    </>
  );
}

StatusType.propTypes = {
  ativo: PropTypes.bool.isRequired,
};

export { StatusType };
