import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import { Label, Input, Error } from './styles';

function FieldCPF(props) {
  const { control, name, label, errors, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
  });

  return (
    <>
      {label && (
        <Label htmlFor={name} style={{ paddingRight: '10px' }}>
          {label}
        </Label>
      )}
      <Input
        id={name}
        {...inputProps}
        {...rest}
        ref={ref}
        mask="999.999.999-99"
      />
      {errors && <ErrorMessage as={Error} errors={errors} name={name} />}
    </>
  );
}

FieldCPF.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
};

export default FieldCPF;
