/* eslint-disable no-undef */
import { LogLevel } from '@azure/msal-browser';

const clientId = process.env.REACT_APP_SSO_CLIENT_ID;
const tenantId = process.env.REACT_APP_SSO_TENANT_ID;

export const msalConfig = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri: 'https://cmportal.casamagalhaes.com.br/login/sso',
    postLogoutRedirectUri: 'https://cmportal.casamagalhaes.com.br/login',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message);
            return;
          case LogLevel.Info:
            // console.info(message);
            return;
          case LogLevel.Verbose:
            // console.debug(message);
            return;
          case LogLevel.Warning:
            // console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['profile', 'email']
};
