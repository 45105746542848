import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import { useSidebar } from '~/contexts/sidebar';
import { MdKeyboardArrowRight, MdMoreHoriz } from 'react-icons/md';
import { ButtonLink } from '~/components/ButtonLink';
import { ButtonSelect } from '~/components/ButtonSelect';
import * as S from './styles';

const GENERATE_REPORT_LABEL = 'Gerar Relatório';

function SidebarPageHeader(sidebarPageHeaderProps) {
  const {
    sidebarOpen,
    collapseSidebarProfile,
    onSetSidebarOpen,
    onSetSidebarClose,
  } = useSidebar();

  const {
    mainMenu,
    pageName,
    button1,
    button2,
    button3,
    breadcrumbs,
    buttonCancelHandler,
    buttonBackHandler,
    buttonBackMain,
    showReport,
    printableRef,
    onBeforePrintCallback,
    customShowReport,
    customButtonsAfterReport,
    customButtonsBeforeReport,
    openButtonSelect,
  } = sidebarPageHeaderProps;

  async function handleToggleSidebarIcon(e) {
    e.preventDefault();
    onSetSidebarOpen();
    onSetSidebarClose();
  }

  const getClassNameStyle = (isMain) => {
    return `buttonBase baseWebMobile addMargin ${isMain && 'baseMain'}`;
  };

  const customTypeButtonLink = (buttonObj) => (
    buttonObj.link ? (
      <ButtonLink
        label={buttonObj.label}
        linkTo={buttonObj.link}
        permission={buttonObj.permission}
        main={buttonObj.main}
      />
    ) : (
      <button
        type="button"
        className={getClassNameStyle(buttonObj.main)}
        onClick={buttonObj?.onClick?.()}
        title={buttonObj.label.toUpperCase()}
        disabled={buttonObj?.disabled}
        style={{
          opacity: buttonObj?.disabled ? 0.5 : 1,
          cursor: buttonObj?.disabled ? 'not-allowed' : 'pointer'
        }}
      >
        {buttonObj.label.toUpperCase()}
      </button>
    )
  );

  const actionButtons = () => (
    <>
      {(buttonBackHandler) && (
        <button
          type="button"
          className={getClassNameStyle(buttonBackMain)}
          onClick={buttonBackHandler}
          title='VOLTAR'
        >
          VOLTAR
        </button>
      )}
      {(buttonCancelHandler) && (
        <button
          type="button"
          className={getClassNameStyle(false)}
          onClick={buttonCancelHandler}
          title='CANCELAR'
        >
          CANCELAR
        </button>
      )}
      {customButtonsAfterReport
        && (customButtonsAfterReport || [])
          .map(customButtom => customButtom)}
      {customShowReport && customShowReport}
      {!customShowReport && showReport && (
        <ReactToPrint
          trigger={() => (
            <Link to="#"
              className="buttonBase baseWebMobile baseReport"
              style={{ cursor: 'pointer' }}
              title={GENERATE_REPORT_LABEL.toUpperCase()}
            >
              <div>{GENERATE_REPORT_LABEL.toUpperCase()}</div>
            </Link>
          )}
          content={printableRef}
          onBeforeGetContent={onBeforePrintCallback}
        />
      )}
      {customButtonsBeforeReport
        && (customButtonsBeforeReport || [])
          .map(customButtom => customButtom)}
      {button3 && button3.label && !button3.show && (
        <>
          {customTypeButtonLink(button3)}
        </>
      )}
      {button2 && button2.label && !button2.show && (
        <>
          {customTypeButtonLink(button2)}
        </>
      )}
      {button1 && button1.label && !button1.show && (
        <>
          {customTypeButtonLink(button1)}
        </>
      )}
    </>
  );

  const isLimitBreadcrumWebMobile = (breadcrumbs || []).length > 1;
  const indexLimit = ((breadcrumbs || []).length - 1) - 1;

  const getStyleBreadcumb = (index) => {
    return `breadcrum ${(index <= indexLimit) ? 'breadcumHidden' : ''}`;
  };

  const getStyleBreadcumbLink = (breadcrum) => {
    const baseStyle = 'mainLvlBreadcum buttonBreadcum';
    return `${baseStyle} ${breadcrum.noUnderline ? 'noUnderline' : ''}`;
  };

  const onHandleOnClick = (breadcrum) => {
    return !breadcrum.noUnderline ? breadcrum?.onClick?.() : undefined;
  };

  return (
    <S.PageHeaderContaner
      isCollapse={collapseSidebarProfile}
      isSidebarOpen={sidebarOpen}
    >
      <div className='sidebarHolder'>
        {!sidebarOpen ? (
          <S.IconContainer onClick={handleToggleSidebarIcon}>
            <S.BarsIcon className="sidebarIcon" />
          </S.IconContainer>
        ) : null}
        <div className="textHolder">
          {!breadcrumbs ? (
            <span className="mainLvl">
              {mainMenu}
            </span>
          ) : (
            <S.BreadcrumbContainer>
              <span className={`mainLvl ${isLimitBreadcrumWebMobile && 'mainLvlHidden'}`}>
                {mainMenu}
              </span>
              {(breadcrumbs || []).map((breadcrum, ind) => (
                <span
                  key={breadcrum.label}
                  className={getStyleBreadcumb(ind)}
                >
                  {isLimitBreadcrumWebMobile && (ind === (indexLimit + 1)) && (
                    <MdMoreHoriz className='more-horz' size={18} />
                  )}

                  <MdKeyboardArrowRight size={18} />
                  {breadcrum.link ? (
                    <Link to={(breadcrum.link || '#')}>
                      <span className={'mainLvlBreadcum'}>
                        {(breadcrum.label || '')}
                      </span>
                    </Link>
                  ) : (
                    <button
                      type="button"
                      className={getStyleBreadcumbLink(breadcrum)}
                      onClick={onHandleOnClick(breadcrum)}
                    >
                      <span className="mainLvlBreadcum">
                        {(breadcrum.label || '')}
                      </span>
                    </button>
                  )}
                </span>
              ))}
            </S.BreadcrumbContainer>
          )}
          <span className="pageName">{pageName}</span>
        </div>
      </div>
      {sidebarOpen && (
        <div className="buttonWrapper">
          {actionButtons()}
        </div>
      )}
      <ButtonSelect
        visible={!sidebarOpen && openButtonSelect}
        renderIcon={(
          <S.IconContainer className='left'>
            <S.MoreVertIcon className="sidebarIcon" />
          </S.IconContainer>
        )}
      >
        <S.ButtonSelectContaner>
          {actionButtons()}
        </S.ButtonSelectContaner>
      </ButtonSelect>
    </S.PageHeaderContaner>
  );
}

SidebarPageHeader.propTypes = {
  mainMenu: PropTypes.string.isRequired,
  pageName: PropTypes.string.isRequired,
  button1: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string,
    permission: PropTypes.bool,
    onClick: PropTypes.func,
    main: PropTypes.bool,
    show: PropTypes.bool,
    disabled: PropTypes.bool
  }),
  button2: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string,
    permission: PropTypes.bool,
    onClick: PropTypes.func,
    main: PropTypes.bool,
    show: PropTypes.bool,
  }),
  button3: PropTypes.shape({
    label: PropTypes.string.isRequired,
    link: PropTypes.string,
    permission: PropTypes.bool,
    onClick: PropTypes.func,
    main: PropTypes.bool,
    show: PropTypes.bool,
  }),
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
      noUnderline: PropTypes.bool,
      onClick: PropTypes.func,
    })
  ),
  buttonCancelHandler: PropTypes.func,
  buttonBackMain: PropTypes.bool,
  buttonBackHandler: PropTypes.func,
  openButtonSelect: PropTypes.bool,
  showReport: PropTypes.bool,
  printableRef: PropTypes.func,
  onBeforePrintCallback: PropTypes.func,
  customShowReport: PropTypes.any,
  customButtonsAfterReport: PropTypes.array,
  customButtonsBeforeReport: PropTypes.array,
};

export { SidebarPageHeader };
