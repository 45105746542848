import { orderBy } from 'lodash';
import { toast } from 'react-toastify';

/**
 * Está função verificar se existe dados em um array.
 * @param {*} collection array de itens
 * @param {*} by objeto a qual vai ser ordenado
 * @param {*} orders tipo de ordenação: `'asc'|'desc'`. Default: `'asc'`
 * @param {*} noMessage ativar/desativar mensagem para array vazio.
 * Default: `false (ativado)`
 * @param {*} customMessage costumizar mensagem para array vazio.
 * Default: `Não encontramos nenhum resultado!`
 * @returns retorna o novo array ordenado.
 */
export function orderResponse({
  collection,
  by,
  orders = ['asc'],
  noMessage = false,
  customMessage = 'Não encontramos nenhum resultado!',
}) {
  if (collection.length) {
    return orderBy(collection, by, orders);
  } else {
    if (!noMessage) {
      toast(customMessage, { type: toast.TYPE.ERROR });
    }
    return [];
  }
}
