import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FaCheck, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { RenderForm } from '../../../Items/RenderForm';
import * as S from './styles';

function FillForms(props) {
  const {
    forms,
    answerFormsIds,
    formCurrentIndex,
    setFormCurrentIndex,
    formularios,
    pessoasFormulario,
    setLoading,
    register,
    setValue,
    onSubmit,
    watch,
    errors,
    setFormCurrentId,
    onGetFormData,
    getValues,
    formCheckboxTemp,
    setFormCheckboxTemp,
  } = props;

  const [formToRender] = formularios.filter((_) => _.index === formCurrentIndex);

  useEffect(() => {
    handleUpdateFormCurrentIndex(formCurrentIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formCurrentIndex]);

  useEffect(() => {
    if (formToRender) { setFormCurrentId(formToRender.formId); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePreviousForm, handleNextForm]);

  function handleUpdateFormCurrentIndex(index) {
    const dataValues = {};

    if (formToRender) {
      const IS_SAME_UUID = (key) => {
        return formToRender.formViewer.uuid === key.split('---')[0];
      };

      const IS_CHECKED_GET_VALUE = (key) => {
        return !dataValues[key]
          || (getValues(key) && (dataValues[key] !== getValues(key)));
      };

      if (Object.keys(formToRender.formData || {}).length > 0) {
        for (const key in formToRender.formData) {
          if (IS_SAME_UUID(key)) {
            let valueData = formToRender.formData[key];

            if (key.includes('option_other')) {
              const valueDataOther = formToRender.formData[
                key.replace('_option_other', '')
              ];

              const isOptionOtherStr = () => {
                return typeof valueDataOther === 'string'
                  && valueDataOther !== 'Outro';
              };

              const isOptionOtherObj = () => {
                return typeof valueDataOther === 'object'
                  && !(valueDataOther || []).includes('Outro');
              };

              if (isOptionOtherStr() || isOptionOtherObj()) {
                valueData = '';
              }
            }

            dataValues[key] = valueData;
          }
        }
      }

      for (const key in getValues()) {
        if (IS_SAME_UUID(key)) {
          if (IS_CHECKED_GET_VALUE(key)) {
            if (typeof getValues(key) === 'string') {
              dataValues[key] = getValues(key);
            }

            if (typeof getValues(key) === 'object') {
              if (![null, undefined].includes(getValues(key))) {
                const dataValuesTemp = [...(dataValues[key] || [])];
                const getValuesTemp = [...(getValues(key) || [])];

                const TYPE_1 = () => {
                  return getValuesTemp.length === 0
                    && dataValuesTemp.length === 0;
                };

                const TYPE_1_HANDLE = () => {
                  dataValues[key] = [];
                };

                const TYPE_2 = () => {
                  return getValuesTemp.length === 0
                    && dataValuesTemp.length > 0;
                };

                const TYPE_2_HANDLE = () => {
                  const allCheckboxHasNoValue = formCheckboxTemp.find((item) => {
                    return item.formId === formToRender.formId;
                  });

                  const valueToCheck = allCheckboxHasNoValue.valuesData.find(
                    (item) => item.inputName === key,
                  );

                  if (valueToCheck.hasNoValue) {
                    dataValues[key] = [];
                  } else {
                    dataValues[key] = [...new Set([...dataValuesTemp])];
                  }
                };

                const TYPE_3 = () => {
                  return getValuesTemp.length >= 0
                    && dataValuesTemp.length > 0;
                };

                const TYPE_3_HANDLE = () => {
                  dataValues[key] = [...new Set([...getValuesTemp])];
                };

                const HANDLE_DEFAULT = () => {
                  dataValues[key] = [
                    ...new Set([...dataValuesTemp, ...getValuesTemp])
                  ];
                };

                switch (true) {
                  case TYPE_1():
                    TYPE_1_HANDLE();
                    break;

                  case TYPE_2():
                    TYPE_2_HANDLE();
                    break;

                  case TYPE_3():
                    TYPE_3_HANDLE();
                    break;

                  default:
                    HANDLE_DEFAULT();
                }
              } else {
                dataValues[key] = getValues(key) ?? '';
              }
            } else {
              dataValues[key] = getValues(key);
            }
          }
        }
      }

      onGetFormData(dataValues, index);
    }
  }

  function handlePreviousForm() {
    setFormCurrentIndex((oldIndex) => {
      let currentIndex = oldIndex;

      handleUpdateFormCurrentIndex(currentIndex);

      if (currentIndex === 0) {
        currentIndex = 0;
      } else {
        currentIndex -= 1;
      }

      return currentIndex;
    });
  }

  function handlePreviousButton() {
    return formCurrentIndex === 0;
  }

  function handleNextForm() {
    setFormCurrentIndex((oldIndex) => {
      let currentIndex = oldIndex;

      handleUpdateFormCurrentIndex(currentIndex);

      if ((currentIndex + 1) === forms.length) {
        currentIndex = oldIndex;
      } else {
        currentIndex += 1;
      }

      return currentIndex;
    });
  }

  function handleNextButton() {
    return (formCurrentIndex + 1) === forms.length;
  }

  const getClassName = (id, index) => {
    let classname = '';

    if (index !== formCurrentIndex) {
      classname = 'formDesativado';
    }

    if (getIdFilledForm(id, index)) {
      classname = 'formComplete';
    }

    return classname;
  };

  const getIdFilledForm = (id, index) => {
    return answerFormsIds.includes(id) && index !== formCurrentIndex;
  };

  return (
    <S.FillFormsContainer>
      <S.FillFormsHeader>
        <button
          onClick={handlePreviousForm}
          className={handlePreviousButton() ? 'desativer' : ''}
          disabled={handlePreviousButton()}
          type='submit'
        >
          <FaArrowLeft />
          <span>FORMULÁRIO ANTERIOR</span>
        </button>
        <S.FormsContentNav>
          {forms.map((form, index) => (
            <button key={index}
              type='submit'
              className={getClassName(form.idForm, index)}
              onClick={() => {
                setFormCurrentIndex(index);
                handleUpdateFormCurrentIndex(formCurrentIndex);
              }}
            >
              {getIdFilledForm(form.idForm, index) ? <FaCheck /> : (index + 1)}
            </button>
          ))}
        </S.FormsContentNav>
        <button
          onClick={handleNextForm}
          className={handleNextButton() ? 'desativer' : ''}
          disabled={handleNextButton()}
          type='submit'
        >
          <span>PRÓXIMO FORMULÁRIO</span>
          <FaArrowRight />
        </button>
      </S.FillFormsHeader>
      {formToRender && (
        <S.ContainerRenderForm>
          <RenderForm
            canDisabledAllInputs={false}
            formPersons={pessoasFormulario}
            formId={formToRender.formId}
            formViewer={formToRender.formViewer}
            contentItemsFormAnswer={
              formToRender.contentItemsFormAnswer
            }
            setValue={setValue}
            showLabelFieldRequired
            setLoading={setLoading}
            onSubmit={onSubmit}
            register={register}
            watch={watch}
            errors={errors}
            formCurrentIndex={formCurrentIndex}
            formCheckboxTemp={formCheckboxTemp}
            setFormCheckboxTemp={setFormCheckboxTemp}
          />
        </S.ContainerRenderForm>
      )}
    </S.FillFormsContainer>
  );
}

FillForms.propTypes = {
  forms: PropTypes.array.isRequired,
  answerFormsIds: PropTypes.array.isRequired,
  allFormsToAnswer: PropTypes.array.isRequired,
  formsToAnswer: PropTypes.array.isRequired,
  formularios: PropTypes.array.isRequired,
  pessoasFormulario: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  formCurrentIndex: PropTypes.number.isRequired,
  setFormCurrentIndex: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  getValues: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  setFormCurrentId: PropTypes.func.isRequired,
  onGetFormData: PropTypes.func.isRequired,
  formCheckboxTemp: PropTypes.array.isRequired,
  setFormCheckboxTemp: PropTypes.func.isRequired,
};



export { FillForms };
