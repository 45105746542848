import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import { FaBan, FaRegMinusSquare, FaTimes } from 'react-icons/fa';
import { MdFactCheck } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import PrintableComponent from '~/components/PrintableComponent';
import { licensePaths } from '~/routes/paths';
import { accessDenied } from '~/util/accessDenied';
import ProductSoftwareService from '~/services/product-software-service';
import PersonResaleService from '~/services/person-resale-service';
import { LicenseService } from '~/services/license';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import { formatCpfCnpj, inputNumber, unFormatCpfCnpj } from '~/util/stringutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';
import { TableModuleActives } from './TableModuleActives';
import SearchComponent from './SearchComponent';
import { useFitler } from '~/hook/useFilter';
import * as S from './styles';

function ActiveLicense({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(false);
  const [inputCpfCnpj, setInputCpfCnpj] = useState('');
  const [inputSerie, setInputSerie] = useState('');
  const [inputSoftware, setInputSoftware] = useState('');
  const [inputRazaoSocial, setInputRazaoSocial] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [codExtSoftwares, setCodExtSoftwares] = useState([]);
  const [allResales, setAllResales] = useState([]);
  const [inputCpfCnpjRevenda, setInputCpfCnpjRevenda] = useState(null);
  const [licenseItem, setLicenseItem] = useState(null);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });

  const { permissions: userPermissions, revenda } = useSelector(
    state => state.user.data
  );

  const userCanManageLicenses = useMemo(
    () => userPermissions.includes(permissions.manage_licencas),
    [userPermissions]
  );

  const userCanViewInputRevenda = useMemo(
    () => userPermissions.includes(
      permissions['view_coluna-revenda']
    ),
    [userPermissions]
  );

  let PrintableElement = React.useRef();

  const asyncEffect = async () => {
    setLoading(true);
    try {
      const requestEffect = [ProductSoftwareService.getProductSoftwares({})];

      if (userCanViewInputRevenda) {
        const filterForFindAll = filters
          ? handleFilters()
          : {
            cpfCnpjRevenda: unFormatCpfCnpj(revenda.cpfCnpj),
          };

        requestEffect.push(
          PersonResaleService.getPersonResales({
            revendaId: revenda.id,
          }),
          findAllByFilter(pageParameters, filterForFindAll),
        );

        setCountFilter(1);
      }

      const [
        resultCodeSoftware,
        resultResales
      ] = await Promise.all(requestEffect);

      if (resultResales?.resultado?.length) {
        const allResalesTemp = resultResales.resultado.map(resale => {
          const nome = resale.nomeFantasia || resale.razaoSocial || '';
          return {
            value: unFormatCpfCnpj(resale.cpfCnpj),
            label: `${formatCpfCnpj(resale.cpfCnpj)} - ${nome}`,
          };
        });

        const cpfCnpjDefault = allResalesTemp
          .find(_ => _.value === unFormatCpfCnpj(revenda.cpfCnpj));

        setAllResales(allResalesTemp);
        setInputCpfCnpjRevenda(cpfCnpjDefault);
      }

      if (resultCodeSoftware?.resultado?.length) {
        const allCodeSoftware = resultCodeSoftware.resultado.map(software => ({
          nome: software.nome, codeERP: (software?.codigoSoftwareERP || ''),
        }));

        setCodExtSoftwares(allCodeSoftware);
      } else {
        toast.error('Nenhum softwares encontrado');
      }

    } catch (error) {
      toast.error('Erro ao consultar cod externo dos softwares, tente realizar a busca novamente, em caso de erro contate o administrador!');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilters() {
    let count = 0;

    if (filters.cpfCnpjRevenda && filters.objectCpfCnpjRevenda) {
      setInputCpfCnpjRevenda(filters.objectCpfCnpjRevenda);
      count += 1;
    }
    if (filters.cpfCnpj) {
      setInputCpfCnpj(filters.cpfCnpj);
      count += 1;
    }
    if (filters.razaoSocial) {
      setInputRazaoSocial(filters.razaoSocial);
      count += 1;
    }
    if (filters.serie) {
      setInputSerie(filters.serie);
      count += 1;
    }
    if (filters.codExtSoftware && filters.stringInputSoftware) {
      setInputSoftware(filters.stringInputSoftware);
      count += 1;
    }

    setCountFilter(count);

    return filters;
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleInputSerieChange(event) {
    const {
      target: { value },
    } = event;

    setInputSerie(inputNumber(value));
  }

  function handleInputSoftwareChange(event) {
    const {
      target: { value },
    } = event;

    setInputSoftware(value);
  }

  function handleInputCpfCnpjChange(event) {
    const {
      target: { value },
    } = event;

    setInputCpfCnpj(inputNumber(value));
  }

  function handleInputRazaoSocialChange(event) {
    const {
      target: { value },
    } = event;

    setInputRazaoSocial(value);
  }

  async function validateForm(success) {
    let count = 0;
    const formData = {};

    if (userCanViewInputRevenda && inputCpfCnpjRevenda) {
      formData.cpfCnpjRevenda = unFormatCpfCnpj(inputCpfCnpjRevenda.value);
      count += 1;
    }
    if (inputCpfCnpj) {
      formData.cpfCnpj = unFormatCpfCnpj(inputCpfCnpj);
      count += 1;
    }
    if (inputRazaoSocial.length) {
      formData.razaoSocial = inputRazaoSocial.trim();
      count += 1;
    }
    if (inputSerie.length) {
      formData.serie = inputSerie;
      count += 1;
    }
    if (inputSoftware.length) {
      const titleSoftware = inputSoftware.trim().toUpperCase();

      const [software] = codExtSoftwares.filter(software => {
        return software.nome === titleSoftware;
      });

      if (software && software.codeERP) {
        formData.codExtSoftware = software.codeERP;
        count += 1;
      } else {
        toast('Código software ERP não encontrado', {
          type: toast.TYPE.ERROR,
        });
        setTimeout(() => {
          handleSetSearchResponse();
        }, 100);
        return;
      }
    }

    setCountFilter(count);
    setFilters({
      ...formData,
      objectCpfCnpjRevenda: inputCpfCnpjRevenda,
      stringInputSoftware: inputSoftware,
    });
    await success(formData);
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  async function findAllByFilterReport(pageParams, formDataParam) {
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const resultLicense = await LicenseService.getLicenses(
        formDataParam, page, rowsPerPage,
      );

      const result = resultLicense.resultado;
      if (result.length) {
        handleSetSearchResponseReport(result);
        setTotalItems(resultLicense.total);
      } else {
        handleSetSearchResponseReport();
        toast.success('Não encontramos licenças ativas para esta pesquisa!');
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams, formData) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const resultLicense = await LicenseService.getLicenses(
        formData, page, rowsPerPage,
      );

      const result = resultLicense.resultado;
      if (result.length) {
        handleSetSearchResponse(result, resultLicense.total);
      } else {
        handleSetSearchResponse();
        toast.success('Não encontramos licenças ativas para esta pesquisa!');
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  const renderRowComponent = (row, isPrint) => {
    return [
      <TableCell key='row_active_1'>
        {row.serie}
      </TableCell>,
      <TableCell key='row_active_2'>
        {row.software}
      </TableCell>,
      <TableCell key='row_active_3'>
        {formatCpfCnpj(row.cpfCnpjCliente)}
      </TableCell>,
      <TableCell key='row_active_4'>
        {row.razaoSocialCliente}
      </TableCell>,
      !isPrint ? (
        <TableCell key='row_active_5' className="actionCell">
          <div className="actionButtonsHolder">
            <MdFactCheck
              className="icon"
              onClick={() => { setLicenseItem({ ...row }); }}
              title="Módulos Ativos"
            />
            <hr />
            <FaBan
              className="icon"
              disabled={!userCanManageLicenses}
              onClick={userCanManageLicenses
                ? () => history.push(
                  licensePaths.totalCancellation, { data: row })
                : () => accessDenied(history)
              }
              title="Cancelamento Total"
            />
            <hr />
            <FaRegMinusSquare
              className="icon"
              disabled={!userCanManageLicenses}
              onClick={userCanManageLicenses
                ? () => history.push(
                  licensePaths.partialCancellation, { data: row })
                : () => accessDenied(history)
              }
              title="Cancelamento Parcial"
            />
          </div>
        </TableCell>
      ) : null,
    ];
  };

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Ativas"
        handlePageUpdate={() => findAllByFilterReport(pageParameters)}
        filters={[
          { name: 'Razão Social', value: inputRazaoSocial },
          { name: 'CPF/CNPJ', value: inputCpfCnpj },
          { name: 'Série', value: inputSerie },
          { name: 'Software', value: inputSoftware },
        ]}
        headerLabels={[
          { text: 'Série', width: '10%' },
          { text: 'Software', width: '20%' },
          { text: 'CPF/CNPJ', width: '24%' },
          { text: 'Razão Social', width: 'auto' },
        ]}
        dataObjects={searchResponseReport}
        renderRowComponent={row => renderRowComponent(row, true)}
      />
      <S.Container>
        <SidebarPageHeader
          mainMenu="Licença"
          pageName="Ativas"
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await validateForm(async data => {
              await findAllByFilterReport(pageParameters, data);
            });
          }}
          openButtonSelect
        />
        <SearchComponent
          countFilter={countFilter}
          handleSubmit={handleSubmit}
          cpfCnpjValue={inputCpfCnpj}
          handleCpfCnpjChange={handleInputCpfCnpjChange}
          razaoSocialValue={inputRazaoSocial}
          handleRazaoSocialChange={handleInputRazaoSocialChange}
          serieValue={inputSerie}
          handleSerieChange={handleInputSerieChange}
          softwareValue={inputSoftware}
          handleSoftwareChange={handleInputSoftwareChange}
          allResales={allResales}
          inputCpfCnpjRevenda={inputCpfCnpjRevenda}
          setInputCpfCnpjRevenda={setInputCpfCnpjRevenda}
          userCanViewInputRevenda={userCanViewInputRevenda}
        />
        <TableComponent
          headerLabels={[
            { text: 'Série', width: '10%' },
            { text: 'Software', width: '20%' },
            { text: 'CPF/CNPJ', width: '17%' },
            { text: 'Razão Social', width: 'auto' },
            { text: 'Ação', width: '150px', align: 'center' },
          ]}
          dataObjects={searchResponse}
          renderRowComponent={renderRowComponent}
          useCustomActions
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
        />
      </S.Container>
      <Modal
        loading={loading}
        show={loading || !!licenseItem}
        customWidth={loading ? 'none' : '80%'}
      >
        {(licenseItem) ? (
          <S.Container>
            <S.ModalContainerHeader>
              <h3>Módulos ativos</h3>
              <FaTimes size={20} onClick={() => setLicenseItem(null)} />
            </S.ModalContainerHeader>
            <TableModuleActives dataObject={licenseItem} />
          </S.Container>
        ) : undefined}
      </Modal>
    </>
  );
}

ActiveLicense.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default ActiveLicense;
