import styled from 'styled-components';

export const Container = styled.div``;

export const TableCellContent = styled.div`
  display: flex;
`;

export const TableCellValue = styled.div`
  flex: 1;
  width: 1px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
`;
