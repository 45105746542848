import { FORM_ITEM_ASNWER_TYPE as TAGS } from '~/util/domainutils';
import { v4 as uuid_v4 } from 'uuid';

export class FormGenerate {
  #contentItemsFormAnswerTemp = [];
  #dataFormViewer;

  constructor(formResponse, clientId, resaleId) {
    const getClientResaleTag = (item, clientId, resaleId) => {
      const itemContent = JSON.parse(item.itemContent);

      const clientResaleType = {
        id: item.id,
        itemId: item.itemType.id,
        itemTag: item.itemType.tag,
        itemData: {
          statement: item.statement,
          description: item.description,
          itemContent: {
            clientId,
            resaleId,
            showData: itemContent.showData,
            showDefaultData: itemContent.showDefaultData,
          },
          itemTypeId: item.itemType.id,
          orderNumber: item.orderNumber,
          required: item.required,
        },
        isTitle: false,
        showRequired: false,
        unshowActions: true,
      };

      return clientResaleType;
    };

    const getTitleTag = (item) => {
      const titleType = {
        id: item.id,
        itemId: TAGS.TITLE.id,
        itemTag: TAGS.TITLE.tag,
        itemData: {
          statement: item.statement,
          description: item.description,
          itemContent: item.itemContent,
          itemTypeId: item.itemType.id,
          orderNumber: item.orderNumber,
          required: item.required,
        },
        isTitle: true,
        showRequired: true,
        unshowActions: false,
      };

      return titleType;
    };

    const getAnswerTag = (item, index) => {
      let itemContent = null;

      if (item.itemContent) {
        itemContent = JSON.parse(item.itemContent);
      }

      const answerType = {
        id: item.id,
        itemId: TAGS.ANSWER.id,
        itemTag: TAGS.ANSWER.tag,
        itemData: {
          statement: item.statement,
          description: item.description,
          itemContent: itemContent ? itemContent : item.itemContent,
          itemTypeId: item.itemType.id,
          orderNumber: item.orderNumber,
          required: item.required,
        },
        isTitle: false,
        showRequired: true,
        unshowActions: false,
      };

      getAnswerContent(item.id, item.statement, item.required, index);

      return answerType;
    };

    const getImageTag = (item) => {
      const itemContent = JSON.parse(item.itemContent);
      const imageObject = JSON.parse(itemContent.object);

      const imageType = {
        id: item.id,
        itemId: item.itemType.id,
        itemTag: item.itemType.tag,
        itemData: {
          statement: item.statement,
          description: item.description,
          itemContent: {
            align: itemContent.align,
            src: itemContent.src,
            object: imageObject
          },
          itemTypeId: item.itemType.id,
          orderNumber: item.orderNumber,
          required: item.required,
        },
        isTitle: false,
        showRequired: false,
        unshowActions: false,
      };

      return imageType;
    };

    const getAnswerContent = (itemFormId, statement, required, index) => {
      let titleGroup = '';

      titleGroup += String(statement)
        .toLowerCase()
        .trim()
        .replace(/\s/g, '_');

      titleGroup = `${index}_${titleGroup}`;

      titleGroup = titleGroup
        .normalize('NFD')
        .replace(/([\u0300-\u036f]|[^_0-9a-zA-Z])/g, '');

      this.#contentItemsFormAnswerTemp.push({
        required,
        itemFormId,
        answerContent: {
          attrName: titleGroup,
        },
      });
    };

    const itemForms = formResponse.itemForms.map((item, index) => {
      switch (item.itemType.tag) {
        case TAGS.TITLE.tag:
          return getTitleTag(item);

        case TAGS.SHORT_ANSWER.tag:
          return getAnswerTag(item, index);

        case TAGS.PARAGRAPH.tag:
          return getAnswerTag(item, index);

        case TAGS.SELECTION_BOX.tag:
          return getAnswerTag(item, index);

        case TAGS.MULTIPLE_CHOICE.tag:
          return getAnswerTag(item, index);

        case TAGS.IMAGE.tag:
          return getImageTag(item);

        case TAGS.CLIENT_RESALE.tag:
          return getClientResaleTag(item, clientId, resaleId);

        default:
          return null;
      }
    });

    const contentItemsForm = itemForms.filter((item) => item && item);

    this.#dataFormViewer = {
      uuid: uuid_v4(),
      formName: formResponse.name.trim(),
      softwareName: formResponse.softwareName.trim(),
      moduleName: formResponse.moduleName.trim(),
      moduleId: formResponse.moduleId,
      createdAt: formResponse.createdAt,
      contentItemsForm,
    };
  }

  getFormViewerAnswer() {
    return [this.#dataFormViewer, this.#contentItemsFormAnswerTemp];
  }
}
