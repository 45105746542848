/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import marcaPreferencialPositiva from
  '~/assets/marca/reduzida/SVG/CM-marca_reduzida-positiva-RGB.svg';
import FiltersReport from '../FiltersReport';
import TableReportComponent from '../TableReportComponent';
import { PrintableBodyWrapper, PageHeaderReport } from './styles';

const PrintableComponent = React.forwardRef((
  {
    dataObjects,
    handlePageUpdate,
    filters,
    headerLabels,
    fieldsArray,
    title,
    renderRowComponent,
    renderCustomHeader,
    newLayout,
  }, ref) => {
  const currentData = moment(new Date());
  const schedule = moment(currentData).format('DD/MM/YYYY');

  return (
    <PrintableBodyWrapper ref={ref}>
      <PageHeaderReport>
        <div className="left">
          <h2>{title}</h2>
          <h4>Gerado em <strong>{schedule}</strong></h4>
        </div>
        <img src={marcaPreferencialPositiva} alt="Casa Magalhães" />
      </PageHeaderReport>
      <FiltersReport filters={filters} />
      <TableReportComponent
        style={{ flex: 1 }}
        headerLabels={headerLabels}
        dataObjects={dataObjects}
        fieldsArray={fieldsArray}
        handlePageUpdate={handlePageUpdate}
        renderRowComponent={renderRowComponent}
        renderCustomHeader={renderCustomHeader}
        filters={filters}
        newLayout={newLayout}
      />
    </PrintableBodyWrapper>
  );
});

PrintableComponent.propTypes = {
  dataObjects: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    || PropTypes.shape({}).isRequired,
  filters: PropTypes.arrayOf(PropTypes.shape({})),
  headerLabels: PropTypes.arrayOf(PropTypes.shape({})),
  fieldsArray: PropTypes.arrayOf(PropTypes.string),
  handlePageUpdate: PropTypes.func,
  renderRowComponent: PropTypes.func,
  renderCustomHeader: PropTypes.func,
  newLayout: PropTypes.bool,
  title: PropTypes.string,
};

export default PrintableComponent;
