import React, { useState } from 'react';
import { FaMinusSquare } from 'react-icons/fa';
import { SiAddthis } from 'react-icons/si';
import * as S from './styles';

/* eslint-disable react/prop-types */
function CollapsibleFilter({ children, countFilters = 0 }) {
  const [open, setOpen] = useState(true);

  return (
    <S.Container>
      <S.FilterContentButton onClick={() => setOpen(!open)}>
        {open ? <FaMinusSquare /> : <SiAddthis />}
        <span className='title-filter'>FILTROS ({countFilters})</span>
      </S.FilterContentButton>
      {open && (<>{children}</>)}
    </S.Container>
  );
}

export { CollapsibleFilter };
