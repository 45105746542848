import api, { endpoints } from '~/services/api';

class AdminGroupAccessService {
  static getAccessGroupPermissions = async () => {
    const { data: response } = await api.get(
      endpoints.admin.getAccessGroupPermissions,
    );

    return response.data;
  }

  static createAccessGroup = async (resultData) => {
    const { data: response } = await api.post(
      endpoints.admin.createAccessGroup,
      { ...resultData },
    );

    return response;
  }

  static updateAccessGroup = async (resultData) => {
    const { data: response } = await api.put(
      endpoints.admin.updateAccessGroup,
      { ...resultData },
    );

    return response;
  }

  static getAccessGroup = async (text, pager) => {
    const { data: response } = await api.post(
      endpoints.admin.getAccessGroup,
      pager
        ? {
          text: text,
          ...pager,
        }
        : {
          text: text,
        }
    );
    return response.data;
  };

  static getGroup = async (groupId) => {
    const { data: response } = await api.get(
      endpoints.admin.getGroup(groupId)
    );

    return response.data;
  };

  static deleteAccessGroup = async (groupId) => {
    const { data: response } = await api.delete(
      endpoints.admin.deleteAccessGroup(groupId)
    );

    return response;
  };
}

export { AdminGroupAccessService };
