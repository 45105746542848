import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  form {
    span {
      font-size: 16px;
      color: #707070;
    }
  }
`;

export const ContainerRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 6px;
    margin-top: -6px;
  }

  input {
    color: #707070;
  }

  input.inputSmall {
    width: 64px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    padding: 12px 30px;
    border-radius: 4px;
    border-color: #b1aeae;
    border-width: 1px;
    font-size: 16px;
    box-shadow: 0 1px 3px rgb(0, 0, 0, 0.16);
    background: #eaeaea;

    @media (max-width: 450px) {
      font-size: 12px;
      padding: 6px 15px;
    }
  }
`;

export const OutRowContainer = styled.div`
  padding: 0 !important;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  div {
    width: 100%;
  }
`;

export const RowContainer = styled.div`
  padding: 0 !important;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding-left: 15px;

    &:first-child {
      padding-left: 0;
    }

    @media (max-width: 450px) {
      padding-left: 0;
    }
  }
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImageContent = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
  }
`;

export const OpcoesHeader = styled.div`
  padding: 15px 0px;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #c9c7c7;
  border-style: solid;

  span {
    font-size: 22px !important;
    color: #9f9f9f !important;

    @media (max-width: 450px) {
      font-size: 14px !important;
    }
  }
`;

export const DiscountContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 20% !important;

  span {
    font-size: 26px !important;
  }
  small {
    font-size: 16px;
    color: #707070;
  }
`;

