import api, { endpoints } from '~/services/api';

class AccessGroupService {
  static getAccessGroup = async (text, pager) => {
    const { data: response } = await api.post(
      endpoints.admin.getAccessGroup,
      pager
        ? {
          text: text,
          ...pager,
        }
        : {
          text: text,
        }
    );
    return response.data;
  };

  static getGroup = async (groupId) => {
    const { data: response } = await api.get(
      endpoints.admin.getGroup(groupId)
    );

    return response.data;
  };
}

export default AccessGroupService;
