/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { signInRequestMfa, changeLoading } from '~/store/modules/auth/actions';
import { toast } from 'react-toastify';
import { useReCaptcha } from '~/hook/useReCaptcha';
import { Copyright } from '~/components/Copyright';
import { showMessageError } from '~/util/errorutils';
import api, { endpoints } from '~/services/api';
import { authenticationPaths } from '~/routes/paths';
import Modal from '~/components/Modal';
import { ButtonBase } from '~/components/ButtonBase';
import { BackgroundLogin } from '~/components/BackgroundLogin';
import { hiddenChatMovidesk } from '~/styles/movidesk';
import marcaPreferencialNegativa from
  '~/assets/marca/preferencial/SVG/CM-marca_preferencial-negativa-RGB.svg';
import './styles.scss';

const RESEND_CODE_IN_SECONDS = 60;

function ValidateMfa({ history, location }) {
  const dispatch = useDispatch();

  const loading = useSelector(state => state.auth.loading);

  const [tokenMfa, setTokenMfa] = useState('');
  const [count, setCount] = useState(RESEND_CODE_IN_SECONDS);
  const [intervalTime, setIntervalTime] = useState(null);

  const login = (location?.state?.login || null);
  const senha = (location?.state?.senha || null);
  const token = (location?.state?.token || null);
  const userInfo = (location?.state?.userInfo || null);
  const isSingleSingOn = (location?.state?.isSingleSingOn || null);

  const reCaptcha = useReCaptcha();

  useEffect(() => {
    reCaptcha.handleLoadReCaptcha();
    hiddenChatMovidesk();

    return () => reCaptcha.handleRemoveReCaptcha();
  }, []);

  useEffect(() => {
    if (count > 0) {
      handleStartInterval();
    }

    return () => intervalTime && clearInterval(intervalTime);
  }, []);

  useEffect(() => {
    if (count <= 0) {
      clearInterval(intervalTime);
      setIntervalTime(null);
    }
  }, [count]);

  const handleStartInterval = () => {
    const intervalTime = setInterval(() => {
      setCount((oldCount) => oldCount - 1);
    }, 1000);

    setIntervalTime(intervalTime);
  };

  const handleTokenMfaChange = (event) => {
    const {
      target: { value },
    } = event;

    setTokenMfa(value);
  };

  const maskEmail = (email) => {
    if (!email || !email.includes('@')) return '';
    const prefix = email.split('@')[0];
    const suffix = email.split('@')[1];

    if (prefix.length <= 2) {
      return email;
    }

    const start = prefix.charAt(0);
    const middle = prefix.length - 2;
    const end = prefix.charAt(prefix.length - 1);

    return `${start}${'*'.repeat(middle)}${end}@${suffix}`;
  };

  const handleResendCodeMfa = async () => {
    if (!isSingleSingOn) {
      if (!login || !senha) {
        toast.error('Erro ao reenviar token de autenticação');
        history.push(authenticationPaths.login);
        return;
      }
    }

    dispatch(changeLoading(true));

    try {
      if (isSingleSingOn) {
        await api.post(endpoints.authentication.sso, {
          email: userInfo.contato.email
        });
      } else {
        if (!reCaptcha.enabled) {
          await api.post(endpoints.authentication.signin, {
            login,
            senha,
            reCaptchaToken: null
          });
        } else {
          await reCaptcha.handleLoginReCaptcha({
            login,
            password: senha
          });
        }
      }

      setCount(RESEND_CODE_IN_SECONDS);
      setTokenMfa('');
      handleStartInterval();
    } catch (error) {
      showMessageError('Erro ao reenviar token de autenticação');
    } finally {
      dispatch(changeLoading(false));
    }
  };

  async function onValidateCode() {
    if (!tokenMfa) {
      toast.error('Digite o código de autenticação');
      return;
    }

    if (!token || !userInfo) {
      toast.error('Erro ao validar token de autenticação');
      history.push(authenticationPaths.login);
      return;
    }

    try {
      dispatch(signInRequestMfa(tokenMfa, token, userInfo));
    } catch (error) {
      // TODO: entender como funciona o MS de login
      toast.error('Erro ao efetuar login mfa');
      setTokenMfa('');
    }
  }

  return (
    <BackgroundLogin>
      <div className="container">
        <Modal show={loading} loading />
        <div className="containerWrapper">
          <img
            src={marcaPreferencialNegativa}
            alt="Marca Preferencial Negativa Casa Maganhães"
            loading='lazy'
          />
          <div className="content">
            <header>
              <h4>Múltiplo Fator de Autenticação</h4>
              <ButtonBase
                label='VOLTAR'
                outlined
                onClick={() => history.push(authenticationPaths.login)}
              />
            </header>
            <div>
              <p className="description">
                Um código com validade de 10 minutos foi enviado para o e-mail:
                {' '}<strong>{maskEmail(userInfo?.contato?.email)}</strong>
              </p>
              <div className="defaultFormContainer">
                <form className="defaultForm background-form">
                  <div className="rowContainer">
                    <div className="inputHolder defaultFlex noMarginRight">
                      <span className="label-code">
                        Acesse seu e-mail e digite o código
                      </span>
                      <input
                        type="text"
                        name="token_mfa"
                        placeholder='Código de autenticação'
                        value={tokenMfa}
                        onChange={handleTokenMfaChange}
                      />
                    </div>
                  </div>
                  <div className="rowContainer actions">
                    <ButtonBase
                      disabled={count > 0}
                      label={`REENVIAR CÓDIGO${count > 0 ? ` (${count}s)` : ''}`}
                      outlined
                      onClick={handleResendCodeMfa}
                    />
                    <ButtonBase label='VALIDAR' onClick={onValidateCode} />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <Copyright />
        </div>
      </div>
    </BackgroundLogin>
  );
}

ValidateMfa.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      userInfo: PropTypes.any,
      token: PropTypes.string,
      login: PropTypes.string,
      senha: PropTypes.string,
      isSingleSingOn: PropTypes.bool,
    }).isRequired,
  }).isRequired,
};

export default ValidateMfa;
