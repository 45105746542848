import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '~/components/Modal';
import { Stepper } from '~/components/Stepper';
import { commercialPaths } from '~/routes/paths';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { SolicitacaoProvider, useSolicitacao } from '~/contexts/solicitation';
import { Steps } from './Steps';

function NewAddSolicitationConsumer({ history }) {
  const {
    loading,
    activeStep,
    handleStepDataObject,
    handleAsyncEffectAdd,
    HANDLE_ON_CLICK_SUBMIT,
  } = useSolicitacao();

  useEffect(() => {
    handleAsyncEffectAdd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="defaultFormId" className="defaultFormContainer">
      <Modal show={loading} loading />
      <div id='solicitationTop' />
      <SidebarPageHeader
        mainMenu="Comercial"
        pageName="Nova Solicitação"
        breadcrumbs={[{
          label: 'Solicitação',
          link: commercialPaths.commercialSolicitation,
        }]}
        button1={{
          label: activeStep === 3 ? 'FINALIZAR SOLICITAÇÃO' : 'AVANÇAR',
          onClick: () => HANDLE_ON_CLICK_SUBMIT[activeStep + 1],
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={history.goBack}
      />
      <Stepper
        customHeader={(<span>Etapas da solicitação:</span>)}
        stepsDataObjects={(handleStepDataObject || [])}
      />
      <Steps index={activeStep} />
    </div>
  );
}

NewAddSolicitationConsumer.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

function NewAddSolicitation({ history }) {
  return (
    <SolicitacaoProvider history={history}>
      <NewAddSolicitationConsumer history={history} />
    </SolicitacaoProvider>
  );
}

NewAddSolicitation.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
};

export default NewAddSolicitation;
