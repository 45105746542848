import styled from 'styled-components';

import { FaPenSquare, FaTrashAlt } from 'react-icons/fa';
import { VpnKeyOutline, VpnKeySolidBloqued } from '~/components/SvgIcons';

export const Container = styled.div``;

export const ModalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    color: #707070;
    padding-bottom: 10px;
  }

  span {
    font-size: 18px !important;
  }

  div {
    display: flex;
    flex: 1;
    width: 100%;
    padding-top: 25px;
    justify-content: center;
  }
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;

  @media (max-width: 450px) {
    flex-direction: column;
  }

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 16px;
    color: #707070;
  }
`;

export const ButtonContainer = styled.div`
  padding: 2% 0px;
  display: flex;
  justify-content: flex-end;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #c9c7c7;
  border-style: solid;
`;

export const BaseButton = styled.button.attrs({
  className: 'buttonBaseOnly'
})``;

export const CancelButton = styled(BaseButton)`
  margin-right: 15px;
`;

export const DeleteButton = styled(BaseButton).attrs({
  className: 'buttonBaseOnly baseMain'
})`
  margin-left: 15px;
`;

export const ResultsTable = styled.table`
  border-collapse: collapse;
  margin: 5px 0px;

  tr {
    th {
      box-shadow: 0 1px 6px rgb(0, 0, 0, 0.16);
      background: white;
      width: 100%;
      color: #979797;
      font-weight: normal;
      font-size: 16px;
      text-align: start;
      padding: 15px 22px;

      @media (max-width: 450px) {
        font-size: 14px;
        padding: 6px 15px;
      }
    }
    th:first-child {
      border-right: 1px solid #cccccc;
    }

    td {
      :first-child {
        padding: 15px 22px;
        @media (max-width: 450px) {
          padding: 6px 15px;
        }
      }
      padding: 10px;
      text-align: start;
      border-bottom: 1px solid #cccccc;
      border-right: 1px solid #cccccc;
      color: #979797;
      font-size: 15px;
      @media (max-width: 450px) {
        padding: 6px;
        font-size: 13px;
      }
    }

    td:last-child {
      border-right: 0px;
    }
  }
`;

export const EditIcon = styled(FaPenSquare)`
  color: #707070;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 26px;
  height: 26px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  &:hover {
    opacity: 0.5;
  }
`;

export const DeleteIcon = styled(FaTrashAlt)`
  color: #707070;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 26px;
  height: 26px;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  &:hover {
    opacity: 0.5;
  }
`;

export const TableCellContent = styled.div`
  display: flex;
`;

export const TableCellValue = styled.div`
  flex: 1;
  width: 1px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
`;

export const KeyBloquedIcon = styled(VpnKeySolidBloqued)`
  &.icon {
    width: 18px;
    padding: 0;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const KeyNotBloquedIcon = styled(VpnKeyOutline)`
  &.icon {
    width: 18px;
    padding: 0;
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;
