/* eslint-disable react/prop-types */
import React from 'react';
import { TableCell } from '@material-ui/core';
import { Container, ObsContainer } from './styles';
import TableComponent from '~/components/TableComponent';
import '../../styles.scss';

export default function Cancellation({ cancellation }) {
  const renderRowComponent = row => {
    const comp = [
      <TableCell key={0}>{row.nomeModulo}</TableCell>,
      <TableCell key={1}>{row.nomeSoftware}</TableCell>,
      <TableCell key={2}>{row.quantidade}</TableCell>,
      <TableCell key={3}>{row.motivoCancelamento.descricao}</TableCell>,
    ];

    if (row.submotivoCancelamento) {
      comp.push(<TableCell key={4}>{row.submotivoCancelamento.descricao}</TableCell>);
    } else {
      comp.push(<TableCell key={4} />);
    }

    return comp;
  };
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
        }}
      >
        <TableComponent
          style={{ width: '100%' }}
          headerLabels={[
            { text: 'Módulo' },
            { text: 'Software' },
            { text: 'Quantidade' },
            { text: 'Motivo' },
            { text: 'Sub-Motivo' },
          ]}
          dataObjects={cancellation.moduloCancelados}
          renderRowComponent={renderRowComponent}
          useCustomActions
        />
        <ObsContainer>
          {!!cancellation.assunto && (
            <div>
              <span>
                <b>Assunto:</b>
              </span>
              <span className='containerObs'>{cancellation.assunto}</span>
            </div>
          )}
          {!!cancellation.observacao && (
            <div>
              <span>
                <b>Observação:</b>
              </span>
              <span className='containerObs'>{cancellation.observacao}</span>
            </div>
          )}
        </ObsContainer>
      </div>
    </Container>
  );
}
