/* eslint-disable no-undef */
import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { useReCaptcha } from '~/hook/useReCaptcha';
import { loginRequest } from '~/config/auth-sso-config';
import Field from '~/components/Field';
import { Copyright } from '~/components/Copyright';
import FieldPassword from '~/components/FieldPassword';
import Modal from '~/components/Modal';
import { BackgroundLogin } from '~/components/BackgroundLogin';
import { signInRequest } from '~/store/modules/auth/actions';
import { unFormatCpfCnpj } from '~/util/stringutils';
import { setAttemptInit } from '~/util/localstorageutils';
import { authenticationPaths } from '~/routes/paths';
import MarcaReduzidaPositiva from
  '~/assets/marca/reduzida/SVG/CM-marca_reduzida-positiva-RGB.svg';
import marcaPreferencialNegativa from
  '~/assets/marca/preferencial/SVG/CM-marca_preferencial-negativa-RGB.svg';
import { hiddenChatMovidesk } from '~/styles/movidesk';
import schema from './schema';
import './styles.scss';

function SignIn() {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);
  const reCaptcha = useReCaptcha();

  const { handleSubmit, errors, control } = useForm({
    defaultValues: { login: '', password: '' },
    resolver: yupResolver(schema),
  });

  async function onSubmit(formData) {
    try {
      const { login, password } = formData;

      if (!reCaptcha.enabled) {
        dispatch(signInRequest(unFormatCpfCnpj(login), password, null));
      } else {
        await reCaptcha.handleLoginReCaptcha({
          login,
          password
        });
      }
    } catch (error) {
      // TODO: entender como funciona o MS de login
      toast.error('Erro ao efetuar login');
    }
  }

  async function handleAuthSSOLogin() {
    try {
      instance.loginRedirect(loginRequest);
    } catch (error) {
      toast.error('Erro ao efetuar o login com SSO!');
    }
  }

  useEffect(() => {
    reCaptcha.handleLoadReCaptcha();
    hiddenChatMovidesk();
    setAttemptInit();

    return () => reCaptcha.handleRemoveReCaptcha();
  }, []);

  return (
    <BackgroundLogin>
      <div className="siginContainer">
        <Modal loading show={loading} />
        <div>
          <img
            src={marcaPreferencialNegativa}
            alt="Marca Preferencial Negativa Casa Maganhães"
            loading='lazy'
          />
          <div className="content">
            <div className="header">
              <img
                src={MarcaReduzidaPositiva}
                alt="Marca Reduzida Casa Magalhães"
                loading='lazy'
              />
              <div>Portal de Relacionamento</div>
            </div>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <Field
                name="login"
                control={control}
                placeholder={'CPF/CNPJ'}
                label="Login"
                type="text"
                errors={errors}
                autoComplete="cpfCnpj"
              />
              <FieldPassword
                name="password"
                control={control}
                placeholder={'Senha'}
                label="Senha"
                type="password"
                errors={errors}
                autoComplete="current-password"
              />
              <div>
                <Link to={authenticationPaths.requestPasswordReset}>
                  <button type="button" className="resetPassword">
                    ESQUECI A SENHA
                  </button>
                </Link>
                <button className="sigin" type="submit">
                  ENTRAR
                </button>
              </div>
              <div className="auth-sso-container">
                <p className="auth-sso-paragraphs">ou</p>

                <button
                  type="button"
                  className="auth-sso-button"
                  onClick={() => handleAuthSSOLogin()}
                >
                  LOGIN COM @GRUPOBOTICARIO
                </button>
              </div>
            </form>
          </div>
          <Copyright />
        </div>
      </div>
    </BackgroundLogin>
  );
}

export default withRouter(SignIn);
