import styled from 'styled-components';

export const ContainerContent = styled.div`
  border: 1px solid var(--cm-primary);
  width: 60%;
  border-radius: 8px;
  padding: 16px 24px;
  background: white;
  margin: 0 auto 8px auto;

  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;
