import { Fragment } from 'react';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';

export const ConcludeContainer = Fragment;

export const ConcludeContent = styled.form`
  background-color: #fcfcfc;
  padding: 0 20px 20px 20px;
  border: none;

  #title {
    font-weight: bold;
    color: black;
  }

  .faturamento {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;

    #infoHolder {
      display: flex;
      margin-top: 10px;
      flex-direction: column;

      @media (min-width: 990px) {
        flex-direction: row;
      }

      #adesaoHolder,
      #mensalidadeHolder {
        flex: 1;

        @media (max-width: 990px) {
          margin-bottom: 10px;
        }

        @media (min-width: 990px) {
          max-width: 280px;
        }

        @media (min-width: 1152px) {
          max-width: 320px;
        }

        #priceHolder {
          color: #6d6d6d;
          font-weight: bold;
        }
      }

      #modalidadeHolder {
        flex: 1;

        #titleHolder {
          color: #6d6d6d;
          font-weight: bold;
        }
      }
    }
  }

  .productsHolder {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;
  }

  .obsHolder {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;

    .inputHolder {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      min-width: 160px;

      textarea {
        border-radius: 6px;
        border: 1px solid #dadae3;
        padding: 8px 15px;
        margin-top: 5px;
        max-height: none;
        resize: vertical;
        overflow: auto;
      }
    }
  }

  .formHolder {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;

    .inputHolder {
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      min-width: 160px;
    }
  }

  .termHolder {
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 15px;
    margin-top: 20px;

    #radioInputHolder {
      display: block;
      flex-direction: row;
      position: relative;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      padding: 5px;
      margin-right: 10px;
      margin-top: 10px;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        width: 17px !important;
        height: 17px;
        margin: 0;
        left: 4px;
        top: 7px;
        z-index: 2;
      }

      #radioLabel {
        padding-left: 25px;
      }
    }

    .checkmark {
      position: absolute;
      top: 4px;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: white;
      border: 1px solid #d4d4df;
      border-radius: 4px;
    }

    .checkmark_stem {
      position: absolute;
      width: 2px;
      height: 15px;
      background-color: var(--cm-primary);
      left: 13px;
      top: 3px;
      -ms-transform: rotate(45deg); /* IE 9 */
      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
      transform: rotate(45deg);
    }

    .checkmark_kick {
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: var(--cm-primary);
      left: 1px;
      top: 11px;
      -ms-transform: rotate(45deg); /* IE 9 */
      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
      transform: rotate(45deg);
    }

    #radioInputHolder:hover input ~ .checkmark {
      border: 1px solid #4c4e9e;
      background-color: #eee;
    }

    #radioInputHolder input:checked ~ .checkmark {
      border: 1px solid var(--cm-primary);
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    #radioInputHolder input:checked ~ .checkmark:after {
      display: block;
    }
  }
`;

export const DataClientContainer = styled.div`
  padding: 0 !important;
  display: flex;
  flex: 1 1 100%;
  flex-wrap: wrap;
  flex-direction: row;

  .clientInfoHolder:nth-child(1) {
    margin-right: 20px;
  }

  .clientInfoHolder {
    flex: 1;
    background-color: #f6f6f6;
    border-radius: 10px;
    padding: 15px;
    max-width: 100%;

    .header {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;

      span {
        flex-grow: 1;
        font-weight: bold;
      }
    }

    .clientInfo {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const CurrencyFormatPrice = styled(CurrencyFormat).attrs({
  displayType: 'text',
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  prefix: 'R$ ',
})`
  margin-left: 5px;
`;

export const ModalContentHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--cm-primary);

  & > svg {
    cursor: pointer;
  }

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    h3 {
      margin-right: 12px;
    }
  }
`;

export const ModalContentScroll = styled.main`
  margin-top: 20px;
  overflow-y: auto;
  height: 500px;
  border: 1px solid #C5C5D4;
  border-radius: 12px 0 0 12px;

  @media (min-height: 700px) {
    height: 720px;
  }

  @media (max-height: 500px) {
    height: 300px;
  }

  &::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #616161e5; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid white; /* creates padding around scroll thumb */
  }
`;
