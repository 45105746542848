import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import {
  FaPaperclip,
  FaRegEdit,
  FaRegFileAlt,
  FaRegTrashAlt,
  FaEye,
  FaTimes,
} from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import PrintableComponent from '~/components/PrintableComponent';
import { USER_TYPE } from '~/util/domainutils';
import { CancelButton, ModalContainer } from '~/pages/Product/Module/styles';
import { commercialPaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import { accessDenied } from '~/util/accessDenied';
import { CHECK_USER_BLOCK } from '~/util/functionsutils';
import { formatCpfCnpj, inputNumber } from '~/util/stringutils';
import { SUPORT_FILES } from '~/util/fileutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { CommercialOrderService } from '~/services/commercial-order-service';
import PersonEmployeeService from '~/services/person-employee-service';
import PersonResaleService from '~/services/person-resale-service';
import TableComponent from '~/components/TableComponent';
import { AttachmentDownload } from '~/components/AttachmentDownload';
import Attachments from '~/components/Attachments';
import SearchComponent from './SearchComponent';
import { useFitler } from '~/hook/useFilter';
import * as S from './styles';
import './styles.scss';

function CommercialOrder({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(false);
  const [inputCliente, setInputCliente] = useState('');
  const [inputRevenda, setInputRevenda] = useState('');
  const [inputId, setInputId] = useState('');
  const [inputDataInicio, setInputDataInicio] = useState('');
  const [inputDataFim, setInputDataFim] = useState('');
  const [radioStatus, setRadioStatus] = useState([]);
  const [searchResponse, setSearchResponse] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [anexos, setAnexos] = useState([]);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    id: '',
    textCliente: '',
    textRevenda: '',
    dataInicio: null,
    dataFim: null,
    statusPedido: '',
  });

  const currentUser = useSelector(state => state.user.data);
  const userPermissions = currentUser.permissions;

  const userCanViewColumnRevenda = useMemo(
    () => userPermissions.includes(permissions['view_coluna-revenda']),
    [userPermissions]
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions.create_pedido),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions.edit_pedido),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions.delete_pedido),
    [userPermissions]
  );

  let PrintableElement = React.useRef();

  const asyncEffect = async () => {
    setLoading(true);
    try {
      const [userRevenda, userColaborador] = await Promise.all([
        PersonResaleService.getPersonResale(currentUser.revenda.id),
        currentUser.tipo === USER_TYPE.COLABORADOR
          ? PersonEmployeeService.getPersonEmployee(currentUser.id)
          : null,
        findAllByFilter(pageParameters, handleFilters()),
      ]);

      await CHECK_USER_BLOCK(userRevenda, history);
      await CHECK_USER_BLOCK(userColaborador, history);
    } catch (error) {
      showMessageError(error, 'Erro ao verificar revenda');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilters() {
    let count = 0;

    if (filters.id) {
      setInputId(filters.id);
      count += 1;
    }
    if (filters.textCliente) {
      setInputCliente(filters.textCliente);
      count += 1;
    }
    if (filters.textRevenda) {
      setInputRevenda(filters.textRevenda);
      count += 1;
    }
    if (filters.dataInicio) {
      setInputDataInicio(filters.dataInicio);
      count += 1;
    }
    if (filters.dataFim) {
      setInputDataFim(filters.dataFim);
      count += 1;
    }
    if (filters.statusPedido) {
      setRadioStatus(filters.statusPedido);
      count += 1;
    }

    setCountFilter(count);

    return filters;
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleInputClienteChange(event) {
    const {
      target: { value },
    } = event;

    setInputCliente(value);
  }

  function handleInputRevendaChange(event) {
    const {
      target: { value },
    } = event;

    setInputRevenda(value);
  }

  function handleInputIdChange(event) {
    const {
      target: { value },
    } = event;

    setInputId(inputNumber(value));
  }

  function handleInputDataInicioChange(event) {
    const {
      target: { value },
    } = event;

    setInputDataInicio(value);
  }

  function handleInputDataFimChange(event) {
    const {
      target: { value },
    } = event;

    setInputDataFim(value);
  }

  function handleRadioStatusChange(event) {
    const {
      target: { value },
    } = event;

    if (radioStatus.some(elem => elem === value)) {
      setRadioStatus(old => old.filter(elem => elem !== value));
    } else {
      setRadioStatus(old => [...old, value]);
    }
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};

    if (inputCliente.length) {
      data.textCliente = inputCliente.trim();
      count += 1;
    }
    if (inputRevenda.length) {
      data.textRevenda = inputRevenda.trim();
      count += 1;
    }
    if (inputId.length) {
      data.id = inputId;
      count += 1;
    }
    if (inputDataInicio.length) {
      data.dataInicio = inputDataInicio;
      count += 1;
    }
    if (inputDataFim.length) {
      data.dataFim = inputDataFim;
      count += 1;
    }
    if (radioStatus.length) {
      data.statusPedido = radioStatus;
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    setFilters(data);
    await success(data);
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  async function findAllByFilterReport(pageParams, formDataParam) {
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const result = await CommercialOrderService
        .getOrders({
          ...formDataParam,
          limit: page * rowsPerPage + rowsPerPage,
          offset: page * rowsPerPage,
        });

      if (result.resultado.length) {
        handleSetSearchResponseReport(result.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos pedidos para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams, data) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;

      const result = await CommercialOrderService
        .getOrders({
          ...(data || formData),
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        });

      if (result.resultado.length) {
        handleSetSearchResponse(result.resultado, result.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos pedidos para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    await validateForm(async (data) => {
      await findAllByFilter(pageParameters, data);
    });
  }

  async function handleDownloadFile(event, itemId, fileName) {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await api.get(
        endpoints.commercial.order.getAnexoFile(itemId),
        { responseType: 'blob' }
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.style = 'display: none';
      a.href = url;
      a.download = fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        toast('Download de Arquivo realizado com sucesso!', {
          type: toast.TYPE.SUCCESS,
        });
        setLoading(false);
      }, 0);
    } catch (error) {
      setLoading(false);
      showMessageError(error, 'Ocorreu um erro inesperado no sistema.');
    }
  }

  function handleVerifyForDeleteOrder(row) {
    if (userCanDelete) {
      setDeleteItem(row);
      return;
    }

    const isResale = currentUser.tipo === USER_TYPE.REVENDA;

    if (isResale) {
      const isDraft = !!row.rascunho;
      const canDeleteDraft = userPermissions.includes(permissions.revenda_deletar_rascunho);

      if (isDraft && canDeleteDraft) {
        setDeleteItem(row);
        return;
      }
    }

    accessDenied(history);
    return;
  }

  async function handleDelete(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const { data: response } = await api.delete(
        endpoints.commercial.order.deleteCommercialOrder(deleteItem.id)
      );
      const newSearchResponse = searchResponse.filter(
        resp => resp.id !== deleteItem.id
      );
      handleSetSearchResponse(newSearchResponse, totalItems - 1);
      toast(response.message, { type: toast.TYPE.SUCCESS });
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  const handleInputAnexosChange = event => {
    const {
      target: { files },
    } = event;

    if (![...Object.values((files || {}))].every((_) => {
      return SUPORT_FILES.ANEXOS.includes(_.type);
    })) {
      toast.error('Arquivos não suportados foram encontrados');
      return;
    }

    const attachments = [];

    for (const key in anexos) {
      if (typeof anexos[key] === 'object') {
        attachments.push(anexos[key]);
      }
    }

    for (const key in files) {
      if (typeof files[key] === 'object') {
        attachments.push(files[key]);
      }
    }

    if (attachments && attachments.length) {
      setAnexos(attachments);
    } else {
      setAnexos([]);
    }
  };

  const handleSubmitAnexos = async event => {
    if (anexos.length === 0) {
      toast('Selecione o(s) arquivo(s) para anexar ao pedido', {
        type: toast.TYPE.ERROR,
      });
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append('pedidoId', selectedItem.id);
      for (let key = 0; key < anexos.length; key++) {
        formData.append('anexos', anexos[key]);
      }
      const { data } = await api.post(
        endpoints.commercial.order.addAnexoOrder,
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      toast(data.message, { type: toast.TYPE.SUCCESS });
      setAnexos([]);
      setSelectedItem(null);
      setLoading(false);
      handleSubmit(event);
    } catch (error) {
      setLoading(false);
      showMessageError(error);
    }
  };

  const renderRowComponent = (row, isPrint = false) => {
    const isDraft = !!row.rascunho;

    const date = moment(row.data).format('DD/MM/YYYY');
    const lastUpdate = moment(row.updatedAt).format('DD/MM/YYYY');
    const { responsavel } = row.statusAtual;

    let nomeResponsavel = '';
    if (responsavel) {
      nomeResponsavel = responsavel.nomeSocial
        || responsavel.razaoSocial
        || responsavel.nomeFantasia
        || '---';
    } else nomeResponsavel = 'Sistema';

    let nomeCliente = '';
    if (row.cliente) {
      const { razaoSocial, nomeFantasia } = row.cliente;
      nomeCliente = razaoSocial || nomeFantasia || '---';
    }

    let nomeRevenda = '';
    if (row.revenda) {
      const { razaoSocial, nomeFantasia } = row.revenda;
      nomeRevenda = razaoSocial || nomeFantasia || '---';
    }

    return [
      <TableCell key='row_order_1'>{row.id}</TableCell>,
      <TableCell key='row_order_2'>
        {row.cliente ? (
          <S.TableCellContent>
            <S.TableCellValue>
              <span title={nomeCliente}>{nomeCliente}</span>
              <br />
              <small>{formatCpfCnpj(row.cliente.cpfCnpj) || '---'}</small>
            </S.TableCellValue>
          </S.TableCellContent>
        ) : '---'}
      </TableCell>,
      userCanViewColumnRevenda && (
        <TableCell key='row_order_3'>
          {row.revenda ? (
            <S.TableCellContent>
              <S.TableCellValue>
                <span title={nomeRevenda}>{nomeRevenda}</span>
                <br />
                <small>{formatCpfCnpj(row.revenda.cpfCnpj) || '---'}</small>
              </S.TableCellValue>
            </S.TableCellContent>
          ) : '---'}
        </TableCell>
      ),
      <TableCell key='row_order_6'>{row.statusAtual.status}</TableCell>,
      <TableCell key='row_order_4'>{date}</TableCell>,
      <TableCell key='row_order_4_4'>{lastUpdate}</TableCell>,
      <TableCell key='row_order_5'>{nomeResponsavel}</TableCell>,
      !isPrint && (
        <TableCell key='row_order_7' className="actionCell">
          <div className="actionButtonsHolder">
            {row.pedidoAnexos.length ? (
              <FaRegFileAlt
                className="icon"
                title="Anexos"
                disabled={!userCanEdit}
                onClick={userCanEdit
                  ? () => setSelectedItem(row)
                  : () => accessDenied(history)
                }
              />
            ) : (
              <FaPaperclip
                className="icon"
                title="Anexar"
                disabled={!userCanEdit}
                onClick={userCanEdit
                  ? () => setSelectedItem(row)
                  : () => accessDenied(history)
                }
              />
            )}
            <hr />
            {isDraft ? (
              <FaRegEdit
                className="icon"
                title="Editar Rascunho"
                disabled={!userCanEdit}
                onClick={userCanEdit
                  ? () => handleEditDraft(row)
                  : () => accessDenied(history)
                }
              />
            ) : (
              <FaEye
                className="icon"
                title="Monitorar"
                disabled={!userCanEdit}
                onClick={userCanEdit
                  ? () => handleManageOrder(row)
                  : () => accessDenied(history)
                }
              />
            )}
            <hr />
            <FaRegTrashAlt
              className="icon"
              title="Deletar"
              disabled={!userCanDelete}
              onClick={() => handleVerifyForDeleteOrder(row)}
            />
          </div>
        </TableCell>
      ),
    ];
  };

  const handleHeaderLabels = (isPrint = false) => {
    const labels = [
      {
        text: 'Nr.',
        width: '5%'
      },
      {
        text: 'Cliente',
        width: 'auto'
      },
      {
        text: 'Revenda',
        width: 'auto'
      },
      {
        text: 'Status',
        width: '64px'
      },
      {
        text: 'Data de criação',
        width: '12%'
      },
      {
        text: 'Última atualização',
        width: '13%'
      },
      {
        text: 'Atualizado por',
        width: '15%',
        nowrap: true,
      },
    ];

    const labelAction = {
      text: 'Ação',
      width: '150px',
      align: 'center',
    };

    // Removendo Revenda
    const LabelsWithoutRevenda = labels.filter((label) => {
      return label.text !== 'Revenda';
    });

    let isAction;

    // Labels com Revenda
    isAction = isPrint ? [...labels] : [...labels, labelAction];
    const labelsWithAction1 = isAction;

    // Labels sem Revenda
    isAction = isPrint ? [
      ...LabelsWithoutRevenda
    ] : [...LabelsWithoutRevenda, labelAction];

    const labelsWithAction2 = isAction;

    return userCanViewColumnRevenda ? labelsWithAction1 : labelsWithAction2;
  };

  function handleGetStatus() {
    return radioStatus.length ? radioStatus.join(', ') : '';
  }

  function handleManageOrder(row) {
    return history.push(
      commercialPaths.managementCommercialOrder,
      { id: row.id }
    );
  }

  function handleEditDraft(row) {
    return history.push(commercialPaths.editCommercialOrder, {
      draftOrder: row.rascunho,
      isDraft: !!row.rascunho,
      idClient: row.cliente.id,
      idOrder: row.id,
    });
  }

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Pedido"
        handlePageUpdate={findAllByFilterReport}
        filters={[
          { name: 'Nr.', value: inputId },
          { name: 'Cliente', value: inputCliente },
          { name: 'Revenda', value: inputRevenda },
          { name: 'De', value: inputDataInicio },
          { name: 'Até', value: inputDataFim },
          { name: 'Status', value: handleGetStatus() },
        ]}
        headerLabels={handleHeaderLabels(true)}
        dataObjects={searchResponseReport}
        fieldsArray={['nome', 'descricao']}
        renderRowComponent={row => renderRowComponent(row, true)}
      />
      <S.Container>
        <SidebarPageHeader
          mainMenu="Comercial"
          pageName="Pedido"
          button1={{
            label: 'Novo Pedido',
            link: commercialPaths.addCommercialOrder,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await validateForm(async data => {
              await findAllByFilterReport(pageParameters, data);
            });
          }}
          openButtonSelect
        />
        <SearchComponent
          idValue={inputId}
          handleIdChange={handleInputIdChange}
          countFilter={countFilter}
          handleSubmit={handleSubmit}
          inputClienteValue={inputCliente}
          handleClienteChange={handleInputClienteChange}
          inputRevendaValue={inputRevenda}
          handleRevendaChange={handleInputRevendaChange}
          userCanViewColumnRevenda={userCanViewColumnRevenda}
          dataInicio={inputDataInicio}
          handleDataInicioChange={handleInputDataInicioChange}
          dataFim={inputDataFim}
          handleDataFimChange={handleInputDataFimChange}
          radioStatus={radioStatus}
          handleRadioStatusChange={handleRadioStatusChange}
        />
        <TableComponent
          headerLabels={handleHeaderLabels()}
          dataObjects={searchResponse}
          totalItems={totalItems}
          handlePageUpdate={async (newFilterPerPage) => {
            await validateForm(async (data) => {
              await findAllByFilter(newFilterPerPage, data);
            });
          }}
          renderRowComponent={renderRowComponent}
          useCustomActions
        />
      </S.Container>
      <Modal loading={false} show={!!selectedItem}>
        <div className="attachmentOrderModal">
          <div className="attachmentOrderModalHeader">
            <span>Anexos</span>
            <FaTimes
              className="closeIcon"
              onClick={() => {
                setSelectedItem(null);
                setAnexos([]);
              }}
            />
          </div>
          <div className="attachmentOrderModalId">
            Pedido número: <span>{(selectedItem || {}).id}</span>
          </div>
          {((selectedItem || {}).pedidoAnexos || []).length > 0 && (
            <div className="anexosHolder">
              {((selectedItem || {}).pedidoAnexos || []).map(({ anexo }) => (
                <AttachmentDownload
                  key={anexo.id}
                  attachmentName={anexo.nome}
                  handleDownloadFile={
                    (e) => handleDownloadFile(e, anexo.id, anexo.nome)
                  }
                />
              ))}
            </div>
          )}
          <div className="imagePicker justifyContentStart">
            <div className="buttonHolder">
              <span>SELECIONAR ANEXO(S)</span>
              <input
                name="anexos"
                type="file"
                multiple
                onChange={handleInputAnexosChange}
                accept={SUPORT_FILES.ACCEPT}
              />
            </div>
            <label>
              {!anexos.length
                ? 'Selecione o(s) arquivo(s)'
                : anexos.length === 1
                  ? '1 arquivo'
                  : `${anexos.length} arquivos`}
            </label>
          </div>
          <S.ContainerAttachments>
            {anexos && (
              <div className="rowContainer noMarginTop">
                <Attachments
                  files={anexos}
                  handleSetAnexos={setAnexos}
                  dividerTwoMarginRight
                />
              </div>
            )}
          </S.ContainerAttachments>
          <div className="sendOrderAnexosButtonHolder">
            <button
              className="buttonBaseOnly baseMain"
              onClick={handleSubmitAnexos}
            >
              <span>ENVIAR ANEXO(S)</span>
            </button>
          </div>
        </div>
      </Modal>
      <Modal loading={loading} show={loading || !!deleteItem}>
        <ModalContainer>
          <h2>Confirmar cancelamento</h2>
          <span>
            Tem certeza que deseja cancelar o pedido:
            {` ${!!deleteItem &&
              ((!!deleteItem.nome && deleteItem.nome) || deleteItem.id)}?`}
          </span>
          <div>
            <CancelButton onClick={() => setDeleteItem(null)}>
              Fechar
            </CancelButton>
            <S.DeleteButton onClick={handleDelete}>
              Sim
            </S.DeleteButton>
          </div>
        </ModalContainer>
      </Modal>
    </>
  );
}

CommercialOrder.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default CommercialOrder;
