import React from 'react';
import PropTypes from 'prop-types';
import { FORM_ITEM_ASNWER_TYPE } from '~/util/domainutils';
import { FormTitle } from '../FormTitle';
import { FormAnswerContainer } from './styles';
import '../styles.scss';

function FormAnswer({
  itemTypeId = 1, form, formItem, setForm, formItemIndex, errorForm,
  setErrorFormItems
}) {
  return (
    <FormAnswerContainer>
      <FormTitle
        itemTypeId={itemTypeId}
        itemType={FORM_ITEM_ASNWER_TYPE.ANSWER.tag}
        form={form}
        formItem={formItem}
        setForm={setForm}
        formItemIndex={formItemIndex}
        errorForm={errorForm}
        setErrorFormItems={setErrorFormItems}
      />
    </FormAnswerContainer>
  );
}

FormAnswer.propTypes = {
  itemTypeId: PropTypes.number,
  formItemIndex: PropTypes.number,
  form: PropTypes.array,
  formItem: PropTypes.object,
  setForm: PropTypes.func,
  errorForm: PropTypes.array,
  setErrorFormItems: PropTypes.func,
};

export { FormAnswer };
