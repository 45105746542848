import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TOKEN_KEY } from '~/util/auth';

import Home from '../pages/Homepage/Home';
import Profile from '../pages/Homepage/Profile';
import RouteWrapper from './Route';

import { RUNTIME_API } from '~/services/apiConfig';
import { initialPaths, authenticationPaths } from './paths';

import adminRoutes from './admin';
import personRoutes from './person';
import productRoutes from './product';
import commercialRoutes from './commercial';
import licenseRoutes from './license';
import formRoutes from './form';
// import goalRoutes from './goal';
// import opportunityRoutes from './opportunity';

const allRoutes = [
  ...adminRoutes,
  ...personRoutes,
  ...productRoutes,
  ...commercialRoutes,
  ...licenseRoutes,
  ...formRoutes,
  // ...goalRoutes,
  // ...opportunityRoutes,
];

export default function Routes({ location }) {
  const { signed } = useSelector(state => state.auth);

  const authenticated = localStorage.getItem(TOKEN_KEY) || signed;

  if (!authenticated) {
    return <Redirect to={{
      pathname: authenticationPaths.login,
      state: { from: location },
    }} />;
  }

  return (
    <Switch>
      <Route exact path={initialPaths.pageInitial} component={Home} />
      <Route exact path={initialPaths.pageProfile} component={Profile} />
      {allRoutes.map((route, index) => (
        <RouteWrapper key={index} {...route} />
      ))}
      <Route
        exact
        path={RUNTIME_API.BASE_URL_WEB}
        component={() => <Redirect to={initialPaths.pageInitial} />}
      />
      <Route path="*" component={() => <h1>Page not found</h1>} />
    </Switch>
  );
}

Routes.propTypes = {
  location: PropTypes.shape({
    key: PropTypes.string,
    pathname: PropTypes.string,
  }),
};
