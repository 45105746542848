import styled from 'styled-components';

export const CopyrightContent = styled.footer`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  text-align: center;

  small {
    font-size: 12px;
    font-weight: bold;
    color: var(--cm-primary);
  }
`;
