import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  padding: 50px;
  display: ${props => !props.show && 'none'};
`;

export const Content = styled.div`
  position: fixed;
  background: white;
  height: auto;
  width: ${props => props.customWidth ? props.customWidth : 'auto'};
  top: 50%;
  left: 50%;
  padding: ${props => !props.noPadding && '20px !important'};
  border-radius: 4px;
  transform: translate(-50%, -50%);
`;
