import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import { Error, Input, Label } from './styles';

function FieldPhone(props) {
  const { control, name, label, errors, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({
    name,
    control,
  });

  return (
    <>
      {label && <Label htmlFor={name}>{label}</Label>}
      <Input
        id={name}
        {...inputProps}
        {...rest}
        mask={'(99) 99999-9999'
          || '(99) 99999-999_'
          || '(99)99999-9999'
          || '(99)99999-999_'
        }
      >
        {inputProps => <input ref={ref} {...inputProps} />}
      </Input>
      {errors && <ErrorMessage as={Error} errors={errors} name={name} />}
    </>
  );
}

FieldPhone.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
};

export default FieldPhone;

/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
const InputPhoneMask = React.forwardRef((props, ref) => {
  const { name, value, onChange, ...rest } = props;
  return (
    <Input
      id={name}
      {...rest}
      value={value}
      onChange={onChange}
      mask={'(99) 99999-9999'
        || '(99) 99999-999_'
        || '(99)99999-9999'
        || '(99)99999-999_'
      }
    >
      {inputProps => <input ref={ref} {...inputProps} />}
    </Input>
  );
});

export { InputPhoneMask };
