import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from '~/components/Modal';
import { toast } from 'react-toastify';
import { person } from '~/routes/paths';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { LineBusinessService } from '~/services/person/linebusiness';
import { showMessageError } from '~/util/errorutils';

export default function AddLineBusiness({ history }) {
  const [loading, setLoading] = useState(false);
  const [lineBusinessName, setLineBusinessName] = useState('');
  const [error_lineBusinessName, setErrorLineBusinessName] = useState(false);

  function handleChange(event) {
    const {
      target: { value },
    } = event;
    setLineBusinessName(value);
    setErrorLineBusinessName(false);
  }

  function validateForm(success) {
    if (!lineBusinessName || !lineBusinessName.length) {
      setErrorLineBusinessName(true);
      return;
    }
    success();
  }

  async function handleSubmit(event) {
    event.preventDefault();
    validateForm(async () => {
      setLoading(true);
      try {
        const result = await LineBusinessService
          .createLineBusiness({ nome: lineBusinessName.trim() });

        toast(result.message, { type: toast.TYPE.SUCCESS });
        setLoading(false);

        setTimeout(() => history.push(person.lineBusiness), 100); // 0.1s
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    });
  }

  return (
    <div className="defaultFormContainer">
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Pessoa"
        pageName="Incluir Atividade"
        breadcrumbs={[{
          label: 'Ramo de Atividade',
          link: person.lineBusiness,
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => handleSubmit,
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={() => history.goBack()}
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <span id="title">Ramo de Atividade</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Nome da Atividade *</span>
              <input
                type="text"
                name="nome"
                value={lineBusinessName}
                onChange={handleChange}
              />
              {error_lineBusinessName && (
                <small style={{ color: 'red' }}>
                  Campo obrigatório
                </small>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

AddLineBusiness.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};
