import TableCell from '@material-ui/core/TableCell';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { FaRegEdit } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import DownloadReportSelect from '~/components/DownloadReportSelect';
import PrintableComponent from '~/components/PrintableComponent';
import { licensePaths } from '~/routes/paths';
import { MonitorKeyService } from '~/services/license/monitorKey';
import { SoftwareService } from '~/services/commercial/software';
import { ReasonCancellationService } from '~/services/license/reasonCancellation';
import { UNIDADES_FEDERATIVAS } from '~/util/domainutils';
import { showMessageError } from '~/util/errorutils';
import { formatCpfCnpj, inputNumber } from '~/util/stringutils';
import permissions from '~/util/permissions';
import TableComponent from '~/components/TableComponent';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import SearchComponent from './SearchComponent';
import { useFitler } from '~/hook/useFilter';
import * as S from './styles';
import './styles.scss';

function MonitorRequests({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(true);
  const [inputDataInicio, setInputDataInicio] = useState('');
  const [inputDataFim, setInputDataFim] = useState('');
  const [radioStatus, setRadioStatus] = useState('');
  const [radioTipo, setRadioTipo] = useState('');
  const [inputCliente, setInputCliente] = useState('');
  const [inputRevenda, setInputRevenda] = useState('');
  const [productSoftwares, setProductSoftwares] = useState([]);
  const [inputSelectSoftware, setInputSelectSoftware] = useState('');
  const [motivosCancelamento, setMotivosCancelamento] = useState([]);
  const [inputSelectMotivosId, setInputSelectMotivosId] = useState('');
  const [searchResponse, setSearchResponse] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [searchResponseReportExcel, setSearchResponseReportExcel] = useState([]);
  const [inputSerie, setInputSerie] = useState('');
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    textCliente: '',
    textRevenda: '',
    dataInicio: null,
    dataFim: null,
    status: '',
    serie: '',
    tipo: '',
    software: '',
    motivo: '',
  });

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      try {
        const [
          { resultado: responseSoftware },
          { resultado: responseMotivo },
        ] = await Promise.all([
          SoftwareService.getProductSoftwares({}),
          ReasonCancellationService.getReasonCancellation({}),
          findAllByFilter(pageParameters, handleFilters()),
        ]);

        setProductSoftwares([...responseSoftware]);
        setMotivosCancelamento([...responseMotivo]);
      } catch (error) {
        showMessageError(error, 'Erro ao dados para pesquisa.');
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { permissions: userPermissions } = useSelector(state => state.user.data);

  const userCanViewColumnRevenda = useMemo(
    () => userPermissions.includes(permissions['view_coluna-revenda']),
    [userPermissions]
  );

  let PrintableElement = React.useRef();

  function handleFilters() {
    let count = 0;

    if (filters.textCliente) {
      setInputCliente(filters.textCliente);
      count += 1;
    }
    if (filters.textRevenda) {
      setInputRevenda(filters.textRevenda);
      count += 1;
    }
    if (filters.dataInicio) {
      setInputDataInicio(filters.dataInicio);
      count += 1;
    }
    if (filters.dataFim) {
      setInputDataFim(filters.dataFim);
      count += 1;
    }
    if (filters.status) {
      setRadioStatus(filters.status);
      count += 1;
    }
    if (filters.tipo) {
      setRadioTipo(filters.tipo);
      count += 1;
    }
    if (filters.serie) {
      setInputSerie(filters.serie);
      count += 1;
    }
    if (filters.software) {
      setInputSelectSoftware(filters.software);
      count += 1;
    }
    if (filters.motivoCancelamentoId) {
      setInputSelectMotivosId(filters.motivoCancelamentoId);
      count += 1;
    }

    setCountFilter(count);

    return filters;
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleInputClienteChange(event) {
    const {
      target: { value },
    } = event;

    setInputCliente(value);
  }

  function handleInputRevendaChange(event) {
    const {
      target: { value },
    } = event;

    setInputRevenda(value);
  }

  function handleInputDataInicioChange(event) {
    const {
      target: { value },
    } = event;

    setInputDataInicio(value);
  }

  function handleInputDataFimChange(event) {
    const {
      target: { value },
    } = event;

    setInputDataFim(value);
  }

  function handleInputSerieChange(event) {
    const {
      target: { value },
    } = event;

    setInputSerie(inputNumber(value));
  }

  function handleRadioStatusChange(event) {
    const {
      target: { value },
    } = event;
    if (radioStatus === value) {
      setRadioStatus('');
    } else {
      setRadioStatus(value);
    }
  }

  function handleRadioTipoChange(event) {
    const {
      target: { value },
    } = event;
    if (radioTipo === value) {
      setRadioTipo('');
    } else {
      setRadioTipo(value);
    }
  }

  function handleInputSelectSoftwareChange(event) {
    const {
      target: { value },
    } = event;

    setInputSelectSoftware(value);
  }

  function handleInputSelectMotivoIdChange(event) {
    const {
      target: { value },
    } = event;

    setInputSelectMotivosId(value);
  }

  async function validateForm(success) {
    let count = 0;

    const data = {};
    if (inputCliente) {
      data.textCliente = inputCliente.trim();
      count += 1;
    }
    if (inputRevenda) {
      data.textRevenda = inputRevenda.trim();
      count += 1;
    }
    if (inputDataInicio.length) {
      data.dataInicio = inputDataInicio;
      count += 1;
    }
    if (inputDataFim.length) {
      data.dataFim = inputDataFim;
      count += 1;
    }
    if (radioStatus.length) {
      data.status = radioStatus;
      count += 1;
    }
    if (radioTipo.length) {
      data.tipo = radioTipo;
      count += 1;
    }
    if (inputSerie.length) {
      data.serie = inputSerie.trim();
      count += 1;
    }
    if (inputSelectSoftware.length) {
      data.software = inputSelectSoftware;
      count += 1;
    }
    if (inputSelectMotivosId.length) {
      data.motivoCancelamentoId = inputSelectMotivosId;
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    setFilters(data);
    await success(data);
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
      setSearchResponseReportExcel(
        generateExcelData(result)
      );
    } else {
      setSearchResponseReport([]);
      setSearchResponseReportExcel([]);
    }
  }

  async function findAllByFilterReport(pageParams, formDataParam) {
    setLoading(true);
    try {
      const resultData = await MonitorKeyService
        .getCancelledLicenses(formDataParam);

      if (resultData.resultado.length) {
        handleSetSearchResponseReport(resultData.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos solicitações para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams, data) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const resultData = await MonitorKeyService
        .getCancelledLicenses({
          ...(data || formData),
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        });

      if (resultData.resultado.length) {
        handleSetSearchResponse(resultData.resultado, resultData.total);
      } else {
        handleSetSearchResponse();
        toast('Não encontramos solicitações para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  const renderRowComponent = (row, isPrint, isNewLayout = false) => {
    const date = moment(row.data).format('DD/MM/YYYY, HH:mm:ss');
    const dateSolicitation = moment(row.dataSolicitacao)
      .format('DD/MM/YYYY, HH:mm:ss');

    const nomeCliente = row.nomeFantasiaCliente
      || row.razaoSocialCliente
      || '---';

    const nomeRevenda = row.nomeFantasiaRevenda
      || row.razaoSocialRevenda
      || '---';

    const oldLayout = [
      <TableCell key='row_0'>
        {row.serie || '---'}
      </TableCell>,
      <TableCell key='row_0_1'>
        {row.software || '---'}
      </TableCell>,
      <TableCell key='row_1'>
        {nomeCliente ? (
          <S.TableCellContent>
            <S.TableCellValue>
              <span title={nomeCliente}>{nomeCliente}</span>
              <br />
              <small>{formatCpfCnpj(row.cpfCnpjCliente) || '---'}</small>
            </S.TableCellValue>
          </S.TableCellContent>
        ) : '---'}
      </TableCell>,
      (userCanViewColumnRevenda && (
        <TableCell key='row_2'>
          {nomeRevenda ? (
            <S.TableCellContent>
              <S.TableCellValue>
                <span title={nomeRevenda}>{nomeRevenda}</span>
                <br />
                <small>{formatCpfCnpj(row.cpfCnpjRevenda) || '---'}</small>
              </S.TableCellValue>
            </S.TableCellContent>
          ) : '---'}
        </TableCell>
      )),
      <TableCell key='row_3'>
        {(row.motivoCancelamento && row.motivoCancelamento.descricao) || '---'}
      </TableCell>,
      <TableCell key='row_4'>
        {date || '---'}
      </TableCell>,
      <TableCell key='row_5' align='center'>
        {row.status || '---'}
      </TableCell>,
      <TableCell key='row_6' align='center'>
        {row.tipo || '---'}
      </TableCell>,
      !isPrint && (
        <TableCell key='row_7'>
          <div className="iconHolder">
            <FaRegEdit
              className="EditIconOnTable"
              disabled
              onClick={() =>
                history.push(licensePaths.editRequests, {
                  data: row,
                })
              }
              title="Editar"
            />
          </div>
        </TableCell>
      ),
    ];

    const newLayout = [
      <TableCell key='row_print_0'>
        {nomeCliente ? (
          <S.TableCellContent>
            <S.TableCellValue>
              <span title={nomeCliente}>{nomeCliente}</span>
              <br />
              <small>{formatCpfCnpj(row.cpfCnpjCliente) || '---'}</small>
            </S.TableCellValue>
          </S.TableCellContent>
        ) : '---'}
      </TableCell>,
      <TableCell key='row_print_1'>
        {row.serie || '---'}
      </TableCell>,
      <TableCell key='row_print_2'>
        {row.numeroUsuarios || '---'}
      </TableCell>,
      <TableCell key='row_print_3'>
        {row.numeroCaixas || '---'}
      </TableCell>,
      <TableCell key='row_print_4'>
        {(row.motivoCancelamento && row.motivoCancelamento.descricao) || '---'}
        <small><br />
          {row.submotivoCancelamento && row.submotivoCancelamento.descricao}
        </small>
      </TableCell>,
      <TableCell key='row_print_5'>
        {dateSolicitation || '---'}
      </TableCell>,
      <TableCell key='row_print_6' align='center'>
        {row.status || '---'}
      </TableCell>,
      <TableCell key='row_print_7' align='center'>
        {row.tipo || '---'}
      </TableCell>,
    ];

    return isNewLayout ? newLayout : oldLayout;
  };

  function generateExcelData(result) {
    if (!result.length) return [];

    const renderNameRevenda = (person) => {
      const name = `${person.nomeFantasiaRevenda || person.razaoSocialRevenda || ''}`;
      const cpfCnpj = `${formatCpfCnpj(person.cpfCnpjRevenda)}`;

      return `${name || '---'} - ${cpfCnpj || '---'}`;
    };

    const renderNameCliente = (person) => {
      const name = `${person.nomeFantasiaCliente || person.razaoSocialCliente || ''}`;
      const cpfCnpj = `${formatCpfCnpj(person.cpfCnpjCliente)}`;

      return `${name || '---'} - ${cpfCnpj || '---'}`;
    };

    const arrJsonToExcel = result.map((cancelation) => {
      const estado = UNIDADES_FEDERATIVAS.find(
        uf => uf.nome === cancelation.estado
      );

      const quantidade = cancelation.tipo.trim() === 'Parcial'
        ? cancelation.moduloCancelados.length : '---';

      return {
        amountModules: quantidade,
        resale: renderNameRevenda(cancelation),
        client: renderNameCliente(cancelation),
        uf: estado ? estado.sigla : '---',
        day: `${moment(cancelation.data).format('DD/MM/YYYY')}`,
        lineBusiness: (cancelation.ramoAtividade || '---'),
        serie: (cancelation.serie || '').trim(),
        software: (cancelation.software || '---'),
        amountUsers: (cancelation.numeroUsuarios || '---'),
        amountBoxs: (cancelation.numeroCaixas || '---'),
        reason: cancelation.motivoCancelamento
          ? cancelation.motivoCancelamento.descricao : '---',
        subReason: cancelation.submotivoCancelamento
          ? cancelation.submotivoCancelamento.descricao : '---',
        requested: `${moment(cancelation.dataSolicitacao).format('DD/MM/YYYY')}`,
        status: (cancelation.status || '---'),
        type: (cancelation.tipo || '---'),
        modality: (cancelation.modalidade || '---'),
      };
    });

    return arrJsonToExcel;
  }

  const handleHeaderLabels = (isPrint = false, isNewLayout = false) => {
    if (isNewLayout) {
      const labels = [
        { text: 'Cliente', width: '20%' },
        { text: 'Série', width: '5%' },
        { text: 'Usuários', width: '5%' },
        { text: 'Caixas', width: '5%' },
        { text: 'Motivo', width: 'auto' },
        { text: 'Solicitado', width: '10%' },
        { text: 'Status', width: '10%', align: 'center' },
        { text: 'Tipo', width: '8%', align: 'center' },
      ];

      return labels;
    }

    const labels = [
      { text: 'Série', width: '5%' },
      { text: 'Software', width: '5%' },
      { text: 'Cliente', width: 'auto' },
      { text: 'Revenda', width: '18%' },
      { text: 'Motivo', width: '20%' },
      { text: 'Solicitado', width: '10%' },
      { text: 'Status', width: '7%', align: 'center' },
      { text: 'Tipo', width: '7%', align: 'center' },
    ];

    const labelAction = { text: 'Ação', width: '50px', align: 'center' };

    // Removendo Revenda
    const LabelsWithoutRevenda = labels
      .filter(label => label.text !== 'Revenda');

    let isAction;
    // Labels com Revenda
    isAction = isPrint ? [...labels] : [...labels, labelAction];
    const labelsWithAction1 = isAction;

    // Labels sem Revenda
    isAction = isPrint
      ? [...LabelsWithoutRevenda]
      : [...LabelsWithoutRevenda, labelAction];

    const labelsWithAction2 = isAction;

    return userCanViewColumnRevenda ? labelsWithAction1 : labelsWithAction2;
  };

  function handleGetMotivo() {
    const motivoCancelamento = motivosCancelamento.filter(motivo => {
      return motivo.id === Number(inputSelectMotivosId);
    });

    return motivoCancelamento.length
      ? motivoCancelamento[0].descricao
      : '';
  }

  function handleFormatPeriodo() {
    return inputDataInicio && inputDataFim
      ? `${inputDataInicio} à ${inputDataFim}`
      : '';
  }

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Cancelamento de Licenças"
        handlePageUpdate={findAllByFilterReport}
        filters={[
          { name: 'Cliente', value: inputCliente },
          { name: 'Série', value: inputSerie },
          { name: 'Revenda', value: inputRevenda },
          { name: 'Tipo', value: radioTipo },
          { name: 'Período', value: handleFormatPeriodo() },
          { name: 'Status', value: radioStatus },
          { name: 'Sotfware', value: inputSelectSoftware },
          { name: 'Motivo', value: handleGetMotivo() },
        ]}
        renderRowComponent={row => renderRowComponent(row, true, true)}
        headerLabels={handleHeaderLabels(true, true)}
        dataObjects={searchResponseReport}
        newLayout={true}
      />
      <S.Container>
        <Modal loading={loading} show={loading} />
        <SidebarPageHeader
          mainMenu="Licença"
          pageName="Monitorar Cancelamentos"
          customShowReport={(
            <DownloadReportSelect
              key={'file_pdf'}
              printableRef={() => PrintableElement}
              onBeforePrintCallback={async () => {
                await validateForm(async data => {
                  await findAllByFilterReport(pageParameters, data);
                });
              }}
              loading={loading}
              excelDataRowsJson={searchResponseReportExcel}
              excelDataColumnsJson={[
                { header: 'Quantidade', key: 'amountModules' },
                { header: 'Revenda', key: 'resale' },
                { header: 'Cliente', key: 'client' },
                { header: 'UF', key: 'uf' },
                { header: 'Dia', key: 'day' },
                { header: 'Ramo', key: 'lineBusiness' },
                { header: 'Série', key: 'serie' },
                { header: 'Licença Software', key: 'software' },
                { header: 'Usuários', key: 'amountUsers' },
                { header: 'Caixas', key: 'amountBoxs' },
                { header: 'Motivo', key: 'reason' },
                { header: 'Sub Motivo', key: 'subReason' },
                { header: 'Solicitado', key: 'requested' },
                { header: 'Status', key: 'status' },
                { header: 'Tipo', key: 'type' },
                { header: 'Modalidade', key: 'modality' },
              ]}
            />
          )}
          openButtonSelect
        />
        <SearchComponent
          countFilter={countFilter}
          userCanViewColumnRevenda={userCanViewColumnRevenda}
          handleSubmit={handleSubmit}
          inputClienteValue={inputCliente}
          handleClienteChange={handleInputClienteChange}
          inputRevendaValue={inputRevenda}
          handleRevendaChange={handleInputRevendaChange}
          serie={inputSerie}
          handleInputSerieChange={handleInputSerieChange}
          dataInicio={inputDataInicio}
          handleDataInicioChange={handleInputDataInicioChange}
          dataFim={inputDataFim}
          handleDataFimChange={handleInputDataFimChange}
          radioStatus={radioStatus}
          handleRadioStatusChange={handleRadioStatusChange}
          radioTipo={radioTipo}
          handleRadioTipoChange={handleRadioTipoChange}
          softwareValue={inputSelectSoftware}
          handleSoftwareChange={handleInputSelectSoftwareChange}
          motivoValue={inputSelectMotivosId}
          handleMotivoChange={handleInputSelectMotivoIdChange}
          softwares={productSoftwares}
          motivosCancelamento={motivosCancelamento}
        />
        <TableComponent
          headerLabels={handleHeaderLabels()}
          dataObjects={searchResponse}
          totalItems={totalItems}
          handlePageUpdate={async (newFilterPerPage) => {
            await validateForm(async (data) => {
              await findAllByFilter(newFilterPerPage, data);
            });
          }}
          renderRowComponent={renderRowComponent}
          useCustomActions
        />
      </S.Container>
    </>
  );
}

MonitorRequests.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default MonitorRequests;
