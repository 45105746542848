import { commercialPaths } from '~/routes/paths';
import permissions from '~/util/permissions';

import CommercialSolicitation from '~/pages/Commercial/Solicitation';
import AddCommercialSolicitation from '~/pages/Commercial/Solicitation/AddSolicitation';
import EditCommercialSolicitation from '~/pages/Commercial/Solicitation/EditSolicitation';

import CommercialOrder from '~/pages/Commercial/Order';
import AddCommercialOrder from '~/pages/Commercial/Order/AddOrder';
import EditCommercialOrder from '~/pages/Commercial/Order/EditOrder';
import ManagementCommercialOrder from '~/pages/Commercial/Order/OrderManagement';

import ReasonSolicitation from '~/pages/Commercial/ReasonSolicitation';
import AddReasonSolicitation from
  '~/pages/Commercial/ReasonSolicitation/AddReasonSolicitation';
import EditReasonSolicitation from
  '~/pages/Commercial/ReasonSolicitation/EditReasonSolicitation';

import Payments from '~/pages/Commercial/Payments';
import AddPayments from '~/pages/Commercial/Payments/AddPayments';
import EditPayments from '~/pages/Commercial/Payments/EditPayments';

const commercialRoutes = [
  {
    key: 1,
    exact: true,
    hideSidebar: false,
    name: 'Pedido',
    path: commercialPaths.commercialOrder,
    component: CommercialOrder,
    permission: permissions.view_pedido,
  },
  {
    key: 2,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Pedido',
    path: commercialPaths.addCommercialOrder,
    component: AddCommercialOrder,
    permission: permissions.create_pedido,
  },
  {
    key: 3,
    exact: false,
    hideSidebar: true,
    name: 'Editar Pedido',
    path: commercialPaths.editCommercialOrder,
    component: EditCommercialOrder,
    permission: permissions.edit_pedido,
  },
  {
    key: 4,
    exact: false,
    hideSidebar: true,
    name: 'Gerenciar Pedido',
    path: commercialPaths.managementCommercialOrder,
    component: ManagementCommercialOrder,
    permission: permissions.view_pedido,
  },
  {
    key: 5,
    exact: true,
    hideSidebar: false,
    name: 'Solicitação',
    path: commercialPaths.commercialSolicitation,
    component: CommercialSolicitation,
    permission: permissions.view_solicitacao,
  },
  {
    key: 6,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Solicitação',
    path: commercialPaths.addCommercialSolicitation,
    component: AddCommercialSolicitation,
    permission: permissions.create_solicitacao,
  },
  {
    key: 7,
    exact: false,
    hideSidebar: true,
    name: 'Editar Solicitação',
    path: commercialPaths.editCommercialSolicitation,
    component: EditCommercialSolicitation,
    permission: permissions.edit_solicitacao,
  },
  {
    key: 8,
    exact: true,
    hideSidebar: false,
    name: 'Motivo Solicitação',
    path: commercialPaths.reasonSolicitation,
    component: ReasonSolicitation,
    permission: permissions['view_motivo-solicitacao'],
  },
  {
    key: 9,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Motivo Solicitação',
    path: commercialPaths.addReasonSolicitation,
    component: AddReasonSolicitation,
    permission: permissions['create_motivo-solicitacao'],
  },
  {
    key: 10,
    exact: false,
    hideSidebar: true,
    name: 'Editar Motivo Solicitação',
    path: commercialPaths.editReasonSolicitation,
    component: EditReasonSolicitation,
    permission: permissions['edit_motivo-solicitacao'],
  },
  {
    key: 11,
    exact: true,
    hideSidebar: false,
    name: 'Pagamento',
    path: commercialPaths.payments,
    component: Payments,
    permission: permissions.view_pagamento,
  },
  {
    key: 12,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Pagamento',
    path: commercialPaths.addPayments,
    component: AddPayments,
    permission: permissions.create_pagamento,
  },
  {
    key: 13,
    exact: false,
    hideSidebar: true,
    name: 'Editar Pagamento',
    path: commercialPaths.editPayments,
    component: EditPayments,
    permission: permissions.edit_pagamento,
  },
];

export default commercialRoutes;
