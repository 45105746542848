import styled from 'styled-components';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiPlusCircle } from 'react-icons/fi';

export const ContainerRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  span {
    margin-left: 6px;
    margin-top: -6px;
  }
`;

export const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  max-width: 424px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  background-color: #ffffff;

  h3 {
    color: #707070;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  b {
    word-break: break-all;
    margin: 10px 0;
  }
`;

export const ModalContentButtons = styled.footer`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 16px;

  button {
    width: 114px;
    text-decoration: none;
    padding: 5px 15px;
    border-radius: 6px;
    border: 1px solid var(--cm-primary);
    font-size: 12pt;
    font-weight: bold;
    color: var(--cm-primary);
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

    @media (max-width: 450px) {
      font-size: 12px;
      padding: 6px 15px;
    }
  }

  button + button {
    margin-left: 20px;
    background-color: #e4eafc;
  }

  button.showColor {
    background-color: #e4eafc;
  }
`;

export const AddIcon = styled(FiPlusCircle)`
  color: var(--cm-primary);
  width: 19px;
  height: 19px;
  cursor: pointer;
`;

export const DeleteIcon = styled(FaRegTrashAlt)`
  color: var(--cm-primary);
  width: 24px;
  height: 24px;
  margin-bottom: 2px;
  cursor: pointer;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid var(--cm-primary);
  border-radius: 8px;
  padding: 8px;
  margin: 10px 40px 10px 0;
  color: var(--cm-primary);
  width: 158px;
  height: auto;
  cursor: pointer;

  span {
    font-size: 10pt;
    font-weight: bold;
    margin-left: 10px;
  }
`;

export const ContainerColors = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`;

export const ContentColors = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const ContentColor = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;

  span {
    color: var(--cm-primary);
  }

  input.percentage {
    width: 98px;
    border: 1px solid #dadae3;
  }

  input.colorName {
    width: 130px;
    border: 1px solid #dadae3;
  }
`;

export const ContainerDelete = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 14px;
  margin-right: 12px;
`;

export const DividerVertical = styled.div`
  width: 1px;
  height: 46px;
  background-color: #C6C6DC;
`;

export const CircleColor = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  margin-top: 9px;
  background: transparent;
  z-index: 2;
`;

export const Color = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${(props) => `rgba(
    ${props.rgba.r},
    ${props.rgba.g},
    ${props.rgba.b},
    ${props.rgba.a})`};
  border-radius: 12px;
  border: 1px solid #0000008A;
  z-index: 1;
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 5;
  margin-top: 64px;

  .sketch-picker {
    width: 200px;
    padding: 10px 10px 0px;
    box-sizing: initial;
    background: rgb(255, 255, 255);
    border-radius: 4px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;

    margin-bottom: 20px;
  }
`;

export const Cover = styled.div`
  padding: 20px;
  margin-top: -44px;
`;
