import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  width: 100%;

  div {
    display: flex;
    margin: 5px 10px;

    textarea {
      margin-top: 0; 
      border-width: 1px; 
      border-color: #dadae3; 
      border-style: solid; 
      border-radius: 6px; 
      padding: 8px 15px;
      resize: vertical;
    }

    span {
      font-size: 17px;
      color: #707070;

      :first-child {
        margin-right: 10px;
      }

      @media (max-width: 700px) {
        font-size: 14px;

        :first-child {
          margin-right: 5px;
        }
      }
    }
  }
`;
