/**
 * This function handles keydown events and triggers corresponding actions based on
 * the key pressed.
 * @param onKeyDownParams - an object containing the following properties:
 */
export function onKeyDown(onKeyDownParams) {
  const {
    event,
    onClick,
    arrowUp,
    arrowDown,
    onBeforeClick,
    onAfterClick,
    onPressTab,
  } = onKeyDownParams;

  if (event.keyCode) {
    if (onBeforeClick) onBeforeClick();

    if (event.keyCode === 13 && onClick) {
      event.preventDefault();
      onClick();
    }

    else if (event.keyCode === 38 && arrowUp) {
      arrowUp();
    }

    else if (event.keyCode === 40 && arrowDown) {
      arrowDown();
    }

    else if ((!event.shiftKey && event.keyCode === 9) && onPressTab) {
      onPressTab();
    }

    if (onAfterClick) onAfterClick();
  }
}
