/* eslint-disable react/prop-types */
import React from 'react';
import { Container } from '../styles';
import { formatCpfCnpj, formatPhone } from '~/util/stringutils';

export default function Creator({ creator }) {
  return (
    <Container>
      <div>
        <span>Nome:</span>
        <span>{creator?.nomeSocial || creator?.nomeFantasia || creator?.razaoSocial || ''}</span>
      </div>
      <div>
        <span>CPF/CNPJ:</span>
        <span>{formatCpfCnpj(creator?.cpfCnpj || '')}</span>
      </div>
      <div>
        <span>Telefone:</span>
        <span>{formatPhone(creator?.contato?.telefone1 || creator?.contato?.telefone2 || '')}</span>
      </div>
      <div>
        <span>E-mail:</span>
        <span>{creator?.contato?.email || ''}</span>
      </div>
    </Container>
  );
}
