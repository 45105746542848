import styled from 'styled-components';

export const Container = styled.div``;

export const ModalContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 580px;
  width: 580px;
  border-radius: 10px;

  flex-direction: column;
  align-items: center;
  text-align: center;

  h3 {
    font-size: 16px;
    color: var(--cm-primary);
    line-height: 24px;
  }

  span {
    color: #7e7e7e;
  }

  @media (max-width: 800px) {
    width: auto;
  }
`;

export const ModalContentButtons = styled.footer`
  padding-right: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  background-color: #fcfcfc;
  margin-top: -1px;
`;

export const ModalContentHeader = styled.header`
  height: 56px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 16px 24px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: #ffffff;
  border-bottom: 1px solid #c6c6dc;
`;

export const ModalContent = styled.div`
  width: 100%;
  flex-direction: column;
  text-align: start;
  display: flex;
  padding: 24px 24px 0 24px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #fcfcfc;

  .withMarginTop {
    margin-top: 20px;
  }

  .subtitle {
    font-weight: normal;
  }

  section {
    display: flex;
    width: 100%;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: column;

    span {
      margin-top: 8px;
    }

    span.withDisplayFlex {
      display: flex;
      align-items: center;

      b {
        margin-right: 5px;
      }

      small {
        margin: 0 6px;
      }

      button {
        margin-left: 5px;
      }
    }

    span.noMarginTop {
      margin-top: 0;
    }

    div {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      margin: 8px 0;

      span {
        margin-top: 0px;
        margin-right: 24px;
      }
    }

    div.noMarginBottom {
      margin-bottom: 0;
    }

    div.duplicateMarginBottom {
      margin-bottom: 16px;
    }

    a {
      width: fit-content;
      margin: 8px 0;
    }
  }

  section.withMarginBottom {
    margin-bottom: 8px;
  }

  section.displayFlex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    div {
      display: block;
      width: auto;
      height: auto;
    }

    div + div {
      margin-left: 30px;
    }
  }

  input[type=text] {
    height: 35px;
    width: 150px;
    border-radius: 6px;
    border: 0.25px solid var(--cm-primary);
    margin-top: 0;
  }
`;
