import { TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import PersonClientService from '~/services/person-client-service';
import TableComponent from '~/components/TableComponent';
import { licensePaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import {
  formatCpfCnpj,
  validateEmail,
  validePhone,
  unFormatPunctuation,
} from '~/util/stringutils';
import ModuleForm from './ModuleForm';
import ComponentClient from './ComponentClient';
import ComponentObservation from './ComponentObservation';

function PartialCancellation({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [responsavelNomeError, setResponsavelNomeError] = useState(false);
  const [responsavelTelefone1Error, setResponsavelTelefone1Error] = useState(false);
  const [responsavelTelefone2Error, setResponsavelTelefone2Error] = useState(false);
  const [responsavelEmailError, setResponsavelEmailError] = useState(false);
  const [observacaoError, setObservacaoError] = useState(false);
  const [assuntoError, setAssuntoError] = useState(false);
  const [telefone1ErrorMessage, setTelefone1ErrorMessage] = useState('Campo obrigatório');
  const [emailErrorMessage, setEmailErrorMessage] = useState('Campo obrigatório');
  const [modulosError, setModulosError] = useState(false);
  const [motivosCancelamento, setMotivosCancelamento] = useState([]);
  const [formData, setFormData] = useState({
    serie: '',
    data: '',
    software: '',
    modalidade: '',
    cpfCnpjCliente: '',
    razaoSocialCliente: '',
    nomeFantasiaCliente: '',
    telefoneCliente: '',
    cpfCnpjRevenda: '',
    razaoSocialRevenda: '',
    nomeFantasiaRevenda: '',
    observacao: '',
    assunto: '',
    modulos: [],
    responsavelNome: '',
    responsavelTelefone1: '',
    responsavelTelefone2: '',
    responsavelEmail: '',
  });

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      const {
        serie,
        data,
        modalidade,
        cpfCnpjCliente,
        razaoSocialCliente,
        nomeFantasiaCliente,
        telefoneCliente,
        cpfCnpjRevenda,
        razaoSocialRevenda,
        nomeFantasiaRevenda,
        software,
        codExtSoftware,
        observacao,
        responsavelNome,
        responsavelTelefone1,
        responsavelTelefone2,
        responsavelEmail,
      } = location.state.data;

      setFormData(state => {
        return {
          ...state,
          serie,
          data,
          modalidade,
          cpfCnpjCliente,
          razaoSocialCliente,
          nomeFantasiaCliente,
          telefoneCliente,
          cpfCnpjRevenda,
          razaoSocialRevenda,
          nomeFantasiaRevenda,
          observacao,
          software,
          codExtSoftware,
          responsavelNome,
          responsavelTelefone1,
          responsavelTelefone2,
          responsavelEmail,
        };
      });

      const [
        { resultado: [findCliente] },
        {
          data: {
            data: {
              resultado: findMotivos
            }
          }
        }
      ] = await Promise.all([
        await PersonClientService.getPersonClients({ cpfCnpj: cpfCnpjCliente }),
        await api.post(endpoints.license.getReasonCancellation),
      ]);

      setInfoResponsavel(findCliente);
      setMotivosCancelamento(findMotivos);
      setLoading(false);
    };

    asyncEffect();
  }, [location.state.data]);

  function setInfoResponsavel(findCliente) {
    if (findCliente) {
      const responsavel = {
        responsavelNome: findCliente.responsavel,
        responsavelTelefone1: findCliente.contato.telefone1,
        responsavelTelefone2: findCliente.contato.telefone2,
        responsavelEmail: findCliente.contato.email,
      };

      setFormData(state => {
        return { ...state, ...responsavel };
      });
    }
  }

  function handleInputAssuntoChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setFormData({ ...formData, assunto: value });
    setAssuntoError(false);
  }

  function handleInputObservacaoChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setFormData({ ...formData, observacao: value });
    setObservacaoError(false);
  }

  function handleInputResponsavelNomeChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setFormData({ ...formData, responsavelNome: value });
    setResponsavelNomeError(false);
  }

  function handleInputResponsavelTelefone1Change(event) {
    const {
      target: {
        value,
      }
    } = event;

    setFormData({
      ...formData,
      responsavelTelefone1: unFormatPunctuation(value),
    });
    setResponsavelTelefone1Error(false);
  }

  function handleInputResponsavelTelefone2Change(event) {
    const {
      target: {
        value,
      }
    } = event;

    setFormData({
      ...formData,
      responsavelTelefone2: unFormatPunctuation(value),
    });
    setResponsavelTelefone2Error(false);
  }

  function handleInputResponsavelEmailChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setFormData({ ...formData, responsavelEmail: value });
    setResponsavelEmailError(false);
  }

  const renderRowComponent = row => {
    let motivoIndex;
    return [
      <TableCell key={'cancelamento-parcial-0'}>
        {`${row.nomeModulo} - ${formData.software}`}
      </TableCell>,
      <TableCell key={'cancelamento-parcial-1'}>
        {row.quantidade}
      </TableCell>,
      <TableCell key={'cancelamento-parcial-2'}>
        {motivosCancelamento.reduce((accumulator, item, index) => {
          if (parseInt(item.id) === parseInt(row.motivoCancelamentoId)) {
            motivoIndex = index;
            return item.descricao;
          }
          return accumulator;
        }, '---')}
      </TableCell>,
      <TableCell key={'cancelamento-parcial-3'}>
        {motivosCancelamento[motivoIndex].submotivoCancelamentos.reduce(
          (accumulator, item) => {
            if (parseInt(item.id) === parseInt(row.submotivoCancelamentoId)) {
              return item.descricao;
            }

            return accumulator;
          },
          '---'
        )}
      </TableCell>,
      <TableCell key={'cancelamento-parcial-4'}>
        <div className="iconHolder">
          <FaRegTrashAlt
            className="EditIconOnTable"
            disabled
            title="Excluir"
            onClick={() => {
              setFormData({
                ...formData,
                id: Math.random(),
                modulos: formData.modulos.filter(item => item.id !== row.id),
              });
            }}
          />
        </div>
      </TableCell>,
    ];
  };

  const addLicense = obj => {
    setFormData({
      ...formData,
      modulos: [
        ...formData.modulos,
        {
          ...obj,
          id: Math.random(),
        },
      ],
    });
    setModulosError(false);
  };

  async function validateForm(success) {
    let isValid = true;
    let focus = '';

    if (!formData.modulos.length) {
      setModulosError(true);
      isValid = false;
      if (focus === '') focus = 'modulos';
    }

    if (!formData.responsavelNome || !formData.responsavelNome.length) {
      setResponsavelNomeError(true);
      isValid = false;
      if (focus === '') focus = 'responsavelNome';
    }

    let error_phone1 = false;
    if (!formData.responsavelTelefone1 && !formData.responsavelTelefone1.length) {
      setResponsavelTelefone1Error(true);
      isValid = false;
      error_phone1 = true;
      if (focus === '') focus = 'responsavelTelefone1';
    }

    if (!error_phone1) {
      if (!validePhone(formData.responsavelTelefone1)) {
        setResponsavelTelefone1Error(true);
        setTelefone1ErrorMessage('Digite um número válido');
        isValid = false;
        if (focus === '') focus = 'responsavelTelefone1';
      }
    }

    if (formData.responsavelTelefone2 && !validePhone(formData.responsavelTelefone2)) {
      setResponsavelTelefone2Error(true);
      isValid = false;
      if (focus === '') focus = 'responsavelTelefone2';
    }

    let error_email = false;
    if (!formData.responsavelEmail && !formData.responsavelEmail.length) {
      setResponsavelEmailError(true);
      isValid = false;
      error_email = true;
      if (focus === '') focus = 'responsavelEmail';
    }

    if (!error_email) {
      if (!validateEmail(formData.responsavelEmail)) {
        setResponsavelEmailError(true);
        setEmailErrorMessage('Digite um email válido');
        isValid = false;
        if (focus === '') focus = 'responsavelEmail';
      }
    }

    if (!formData.assunto || !formData.assunto.trim().length) {
      setAssuntoError(true);
      isValid = false;
      if (focus === '') focus = 'assunto';
    }

    if (!formData.observacao || !formData.observacao.trim().length) {
      setObservacaoError(true);
      isValid = false;
      if (focus === '') focus = 'observacao';
    }

    if (focus.length > 0) handleToFocus();

    if (isValid) {
      success();
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const { data } = await api.post(
        endpoints.license.partialLicenseCancellation,
        {
          ...formData,
          modulos: formData.modulos.map(item => {
            return {
              codExternoModulo: item.codExternoModulo,
              quantidade: item.quantidade,
              nomeSoftware: formData.software,
              nomeModulo: item.nomeModulo,
              motivoCancelamentoId: Number(item.motivoCancelamentoId),
              submotivoCancelamentoId: item.submotivoCancelamentoId
                ? Number(item.submotivoCancelamentoId)
                : undefined,
            };
          }),
          data: new Date().toISOString(),
          assunto: (formData.assunto || '').trim(),
          observacao: (formData.observacao || '').trim(),
          responsavelNome: (formData.responsavelNome || '').trim(),
          responsavelTelefone1: (formData.responsavelTelefone1 || '').trim(),
          responsavelTelefone2: (formData.responsavelTelefone2 || '').trim(),
          responsavelEmail: (formData.responsavelEmail || '').trim(),
        }
      );

      toast(data.message, { type: toast.TYPE.SUCCESS });
      if (data.data?.messages?.error) {
        const { error } = data.data?.messages;

        error.forEach((msg, index) => {
          if (typeof msg === 'string') {
            setTimeout(() => {
              toast(msg, { type: toast.TYPE.ERROR });
            }, 500 * (index + 1)); // 0.5s, 1s e 1.5s
          }
        });
      }

      setTimeout(() => {
        history.push(licensePaths.activeLicenses);
      }, 100); // 0.10 s
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  };

  function handleToFocus() {
    const element = document.getElementById('focusContato');

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  return (
    <div className="defaultFormContainer">
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Licença"
        pageName="Cancelamento Parcial"
        breadcrumbs={[{
          label: 'Ativas',
          link: licensePaths.activeLicenses,
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => (() => validateForm(handleSubmit)),
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={history.goBack}
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <ComponentClient
            cpfCnpjValue={formatCpfCnpj(formData.cpfCnpjCliente)}
            nomeFantasiaValue={formData.nomeFantasiaCliente}
            razaoSocialValue={formData.razaoSocialCliente}
          />
        </div>
        <div className="infoCard">
          <ModuleForm
            serie={formData.serie}
            software={formData.software}
            motivosCancelamento={motivosCancelamento}
            modulos={location.state.data.modulos}
            addLicense={addLicense}
            handleSubmit={addLicense}
          />
          <TableComponent
            style={{ margin: '20px 0' }}
            headerLabels={[
              { text: 'Módulo' },
              { text: 'Quantidade' },
              { text: 'Motivo' },
              { text: 'Sub-Motivo' },
              { text: 'Ações' },
            ]}
            dataObjects={formData.modulos}
            renderRowComponent={renderRowComponent}
            useCustomActions
          />
          {modulosError && (
            <small style={{ color: 'red' }}>
              Cadastre pelo menos um módulo para prosseguir
            </small>
          )}
        </div>
        <div className="infoCard">
          <ComponentObservation
            responsavelNome={formData.responsavelNome}
            responsavelTelefone1={formData.responsavelTelefone1}
            responsavelTelefone2={formData.responsavelTelefone2}
            responsavelEmail={formData.responsavelEmail}
            assunto={formData.assunto}
            observacao={formData.observacao}
            responsavelNomeError={responsavelNomeError}
            responsavelTelefone1Error={responsavelTelefone1Error}
            responsavelTelefone2Error={responsavelTelefone2Error}
            responsavelEmailError={responsavelEmailError}
            assuntoError={assuntoError}
            observacaoError={observacaoError}
            telefone1ErrorMessage={telefone1ErrorMessage}
            emailErrorMessage={emailErrorMessage}
            handleInputResponsavelNomeChange={handleInputResponsavelNomeChange}
            handleInputResponsavelTelefone1Change={handleInputResponsavelTelefone1Change}
            handleInputResponsavelTelefone2Change={handleInputResponsavelTelefone2Change}
            handleInputResponsavelEmailChange={handleInputResponsavelEmailChange}
            handleInputAssuntoChange={handleInputAssuntoChange}
            handleInputObservacaoChange={handleInputObservacaoChange}
          />
          <div id='focusContato' />
        </div>
      </form>
    </div>
  );
}

PartialCancellation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({
        modulos: PropTypes.arrayOf(PropTypes.shape({})),
      })
    })
  }).isRequired,
};

export default PartialCancellation;
