import { FORM_ITEM_ASNWER_TYPE as TAGS } from '~/util/domainutils';
import { image_default_base64 } from './utils/defaultImageBase64';

export default class FormItemDataNew {
  constructor({ tag }) {
    this.statement = null;
    this.description = null;
    this.orderNumber = null;
    this.required = false;

    this.itemTypeId = TAGS[tag].id;

    if (tag === TAGS.ANSWER.tag) {
      this.itemTypeId = TAGS.SHORT_ANSWER.id;
    }

    this.itemContent = null;

    if (tag === TAGS.IMAGE.tag) {
      this.itemContent = {
        align: 'center',
        src: image_default_base64,
        object: new File(
          ['~/assets/marca/preferencial/PNG/CM-marca_preferencial-positiva-RGB.png'],
          'CM-marca_preferencial-positiva-RGB.png',
          { type: 'image/png', },
        )
      };
    }

    if (tag === TAGS.CLIENT_RESALE.tag) {
      this.itemContent = {
        clientId: 0,
        resaleId: 0,
        showData: true,
        showDefaultData: true,
      };
    }
  }
}
