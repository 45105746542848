import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { admin } from '~/routes/paths';
import Modal from '~/components/Modal';
import permissions from '~/util/permissions';
import { accessDenied } from '~/util/accessDenied';
import { showMessageError } from '~/util/errorutils';
import TableComponent from '~/components/TableComponent';
import SimpleTextSearch from '~/components/SimpleTextSearch';
import { AdminGroupAccessService } from '~/services/admin-group-access-service';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import PrintableComponent from '~/components/PrintableComponent';
import { useFitler } from '~/hook/useFilter';
import '../styles.scss';

export default function AccessGroup({ history }) {
  const { filters, setFilters } = useFitler();
  const [inputValue, setInputValue] = useState(filters?.inputValue ? filters?.inputValue : '');
  const [searchResponse, setSearchResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [totalItems, setTotalItems] = useState(null);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });

  let PrintableElement = React.useRef();

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions['create_grupo-acesso']),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions['edit_grupo-acesso']),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions['delete_grupo-acesso']),
    [userPermissions]
  );

  useEffect(() => {
    findAllByFilter(pageParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(event) {
    const {
      target: { value },
    } = event;

    setInputValue(value);
  }

  async function findAllByFilterReport() {
    setLoading(true);
    try {
      if (inputValue.length) { setCountFilter(1); } else { setCountFilter(0); }
      const data = await AdminGroupAccessService
        .getAccessGroup(inputValue.trim());

      if (data.resultado.length) {
        setSearchResponseReport(data.resultado);
      } else {
        toast('Não encontramos nenhum Grupo de Acesso!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      if (inputValue.length) { setCountFilter(1); } else { setCountFilter(0); }
      const { page, rowsPerPage } = pageParams;
      const responseAccessGroups = await AdminGroupAccessService
        .getAccessGroup(inputValue.trim(), {
          limit: page * rowsPerPage + rowsPerPage,
          offset: page * rowsPerPage,
        });

      if (responseAccessGroups.resultado.length) {
        let groupsFilted = responseAccessGroups.resultado;
        let groupsTotalFilted = responseAccessGroups.total;

        setSearchResponse(groupsFilted);
        setTotalItems(groupsTotalFilted);
      } else {
        toast('Não encontramos nenhum Grupo de Acesso!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setFilters({ inputValue });

    await findAllByFilter(pageParameters);
  }

  async function handleDelete() {
    try {
      setLoading(true);
      const responseDeleteAccessGroup = await AdminGroupAccessService
        .deleteAccessGroup(deleteItem.id);

      if (!responseDeleteAccessGroup.data.ok) {
        toast('Erro ao deletar o Grupo!', {
          type: toast.TYPE.ERROR,
        });
      } else {
        const newSearchResponse = searchResponse
          .filter(resp => resp.id !== deleteItem.id);

        setSearchResponse(newSearchResponse);
        setTotalItems(totalItems - 1);
        toast.success(responseDeleteAccessGroup.message);
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o grupo!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Grupo de Acesso"
        handlePageUpdate={findAllByFilterReport}
        headerLabels={[
          { text: 'Nome do Grupo', width: '30%' },
          { text: 'Descrição', width: 'auto' },
        ]}
        dataObjects={searchResponseReport}
        fieldsArray={['nome', 'descricao']}
        filters={[{ name: 'Grupo de Acesso', value: inputValue }]}
      />
      <div className="adminPageContainer">
        <Modal loading={loading} show={loading || !!deleteItem}>
          <div className="modalContainer">
            <h2>Confirmar exclusão</h2>
            <span>
              Tem certeza que deseja deletar o item
              <span id="elementName">
                {` ${!!deleteItem && deleteItem.nome}`}
              </span>
              ?
            </span>
            <div>
              <button
                className="buttonBaseOnly addMargin"
                onClick={() => setDeleteItem(null)}
              >
                Cancelar
              </button>
              <button
                className="buttonBaseOnly baseMain"
                onClick={handleDelete}
              >
                Deletar
              </button>
            </div>
          </div>
        </Modal>
        <SidebarPageHeader
          mainMenu="Administrador"
          pageName="Grupo de Acesso"
          button1={{
            label: 'Novo Grupo',
            link: admin.addAccessGroup,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await findAllByFilterReport(pageParameters);
          }}
          openButtonSelect
        />
        <SimpleTextSearch
          countFilter={countFilter}
          fieldLabel="Grupo de Acesso"
          inputValue={inputValue}
          handleInputChange={handleChange}
          handleSubmit={handleSubmit}
        />
        <TableComponent
          headerLabels={[
            { text: 'Nome do Grupo', width: '30%' },
            { text: 'Descrição', width: 'auto' },
          ]}
          dataObjects={searchResponse}
          fieldsArray={['nome', 'descricao']}
          editPermission={userCanEdit}
          handleEdit={row => history.push(
            admin.editAccessGroup,
            {
              id: row.id,
              name: row.nome,
              description: row.descricao,
              permissions: row.grupoPermissaos,
              grupoAcessos: row.grupoAcessos,
            })
          }
          deletePermission={userCanDelete}
          handleDelete={userCanDelete
            ? (row) => setDeleteItem(row)
            : () => accessDenied(history)
          }
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
        />
      </div>
    </>
  );
}

AccessGroup.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
