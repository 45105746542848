import styled from 'styled-components';
import TableCell from '@material-ui/core/TableCell';
import { MdShoppingCart } from 'react-icons/md';
import CurrencyFormat from 'react-currency-format';

export const TableCellTotal = styled(TableCell)`
  background-color: #E4EAFC;

  span {
    color: black;
    font-weight: bold;
  }
`;

export const CustomContainer = styled.div`
  width: 100%;
`;

export const OrderSoftwareFormContainer = styled.form`
  border: 0;
  margin: 0;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  background-color: #fcfcfc;
`;

export const SoftwareSelectedContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  .selectSoftware + .selectSoftware {
    margin-left: 20px;
  }

  .selectSoftware {
    margin: 10px 0 20px 0;
    width: 100%;
    max-width: 425px;
    border-radius: 6px;
    border: 1px solid #e1e1e9;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    cursor: pointer;

    .softwareItem {
      display: flex;
      flex-direction: row;
      align-items: center;

      img { height: 45px; }

      #softwareName {
        margin-left: 0;
        color: var(--cm-primary);
        font-weight: 600;
      }
    }
  }
`;

export const ModulesContainer = styled.div`
  padding: 10px 0;
`;

export const ModulesContent = styled.div`
  display: flex;
  flex-direction: column;

  .moduleHolder {
    display: flex;
    width: 45%;
    min-width: 400px;
    flex-direction: column;
    background-color: #f6f6f6;
    border-radius: 10px;
    margin: 10px 20px 10px 0;
    padding: 15px;

    @media (max-width: 1160px) {
      width: 100%;
      min-width: none;
    }

    @media (min-width: 1580px) {
      width: 30%;
      min-width: 400px;
    }

    @media (min-width: 2560px) {
      width: 24%;
      min-width: 500px;
    }

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      #moduleName {
        font-weight: bold;
        color: #707070;
        white-space: nowrap;
        margin-right: 6px;
      }
    }
  }
`;

export const ModuleHeaderTitle = styled.div`
  margin: 10px 0 20px 0;
  height: 20px;
`;

export const ModuleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ModuleTitle = styled.div`
  display: flex;
`;

export const ModuleNameContent = styled.div`
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ModuleDefault = styled.span`
  font-style: italic;
  color: #707070;
`;

export const ModulePriceHolderContainer = styled.span`
  display: flex;
  color: #707070;
`;

export const ModuleDivider = styled.div`
  margin: 0 10px;
`;

export const Divider = styled.div`
  height: 10px;
  background-color: #E5E5E5;
`;

export const HeaderTableComponent = styled.div`
  padding: 20px 20px 0 20px;

  #subtitle {
    margin-left: 6px;
    color: var(--cm-primary);
  }
`;

export const ShoppingCartContent = styled.div`
  width: 48px;
  height: 48px;
  padding: 0 10px;
  background-color: var(--cm-primary);
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 0;
  margin: 20px;
  border: 1px solid #3F51B5;
  z-index: 1;
  cursor: pointer;

  #priceHolder {
    margin-left: 6px;
    color: white;
    display: none;
    flex-direction: column;
    font-size: 12px;

    .price {
      margin-left: 6px;
      font-weight: bold;
    }
  }

  &:hover {
    width: auto;
    justify-content: space-evenly;

    #priceHolder {
      display: flex;
    }
  }
`;

export const ShoppingCartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ShoppingCartIcon = styled(MdShoppingCart)`
  margin-top: 6px;
`;

export const ShoppingCartBadge = styled.span`
  position: absolute;
  background-color: #FF9000;
  width: 18px;
  height: 18px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  font-size: 12px;
  margin-bottom: 16px;
`;

export const CountItemsContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TableCellPriceTotal = styled(TableCell)`
  padding: 0;

  div.priceTotalContainer {
    padding: 0 4px;

    input {
      border: 1px solid #dadae3;
    }
  }
`;

export const CurrencyFormatPrice = styled(CurrencyFormat).attrs({
  displayType: 'text',
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  prefix: 'R$ ',
})``;

export const CurrencyFormatPriceTotal = styled(CurrencyFormatPrice)`
  margin: 0;
  padding: 5px;
  height: 30px;
`;
