import PropTypes from 'prop-types';
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import { FieldCpfCnpj } from '~/components/FieldCpfCnpj';
import { WarningText } from '~/components/WarningText';
import { SelectAutoComplete } from '~/components/SelectAutoComplete';
import { unFormatCpfCnpj } from '~/util/stringutils';
import { CollapsibleFilter } from '~/components/CollapsibleFilter';
import './styles.scss';

function PersonSearch(licenseSearchParams) {
  const {
    page,
    countFilter,
    messageHelp,
    handleSubmit,
    cpfCnpjValue,
    handleCpfCnpjChange,
    razaoSocialValue,
    handleRazaoSocialChange,
    serieValue,
    softwareValue,
    handleSerieChange,
    handleSoftwareChange,
    allResales,
    inputCpfCnpjRevenda,
    setInputCpfCnpjRevenda,
    userCanViewInputRevenda,
  } = licenseSearchParams;

  return (
    <>
      {page === 'KEY' && <WarningText description={messageHelp || ''} />}
      <CollapsibleFilter countFilters={countFilter}>
        <form className="formContainer" onSubmit={handleSubmit}>
          <label id="searchText">Pesquisar</label>
          <div className="rowContainer">
            <div className="inputHolder">
              <span>CPF / CNPJ</span>
              <FieldCpfCnpj
                type="text"
                name="cpfCnpj"
                cpfCnpjValue={cpfCnpjValue}
                handleCpfCnpjChange={handleCpfCnpjChange}
              />
            </div>
            <div className="inputHolder defaultFlex">
              <span>Nome / Razão Social</span>
              <input
                type="text"
                name="razao"
                value={razaoSocialValue}
                onChange={handleRazaoSocialChange}
              />
            </div>
            {userCanViewInputRevenda && (
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Revenda</span>
                <SelectAutoComplete
                  options={allResales}
                  customLabel={(data) => (
                    <span>{data.label}</span>
                  )}
                  value={
                    inputCpfCnpjRevenda
                      ? {
                        value: inputCpfCnpjRevenda.value,
                        label: inputCpfCnpjRevenda.label,
                      }
                      : null
                  }
                  onChange={(item) => {
                    setInputCpfCnpjRevenda(
                      allResales.find((_) => _.value === unFormatCpfCnpj(item.value))
                    );
                  }}
                />
              </div>
            )}
          </div>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex">
              <span>Software</span>
              <input
                type="text"
                name="software"
                value={softwareValue}
                onChange={handleSoftwareChange}
              />
            </div>
            <div className="inputHolder defaultFlex">
              <span>Série</span>
              <input
                type="text"
                name="serie"
                value={serieValue}
                onChange={handleSerieChange}
              />
            </div>
            <button className="buttonBaseOnly" id="baseButtonIcon" onClick={handleSubmit}>
              <FaSearch id="baseNameIcon" />
              <span>BUSCAR</span>
            </button>
          </div>
        </form>
      </CollapsibleFilter>
    </>
  );
}

PersonSearch.propTypes = {
  page: PropTypes.string,
  countFilter: PropTypes.number,
  messageHelp: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  cpfCnpjValue: PropTypes.string.isRequired,
  handleCpfCnpjChange: PropTypes.func.isRequired,
  razaoSocialValue: PropTypes.string.isRequired,
  handleRazaoSocialChange: PropTypes.func.isRequired,
  serieValue: PropTypes.string.isRequired,
  softwareValue: PropTypes.string.isRequired,
  handleSerieChange: PropTypes.func.isRequired,
  setInputCpfCnpjRevenda: PropTypes.func.isRequired,
  handleSoftwareChange: PropTypes.func.isRequired,
  userCanViewInputRevenda: PropTypes.bool.isRequired,
  inputCpfCnpjRevenda: PropTypes.object,
  allResales: PropTypes.array,
};

export default PersonSearch;
