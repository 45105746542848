import styled from 'styled-components';
import { FaBan, FaRegCheckCircle } from 'react-icons/fa';

export const CheckCircleIcon = styled(FaRegCheckCircle)`
  color: green;
  width: 26px;
  height: 26px;

  &:hover {
    opacity: 0.5;
  }
`;

export const BanIcon = styled(FaBan)`
  color: red;
  width: 26px;
  height: 26px;

  &:hover {
    opacity: 0.5;
  }
`;
