import { RUNTIME_API } from '~/services/apiConfig';

const pathWeb = RUNTIME_API.BASE_URL_PATH;

export const initialPaths = {
  pageInitial: `${pathWeb}/home`,
  pageProfile: `${pathWeb}/profile`,
};

export const authenticationPaths = {
  login: `${pathWeb}/login`,
  logout: `${pathWeb}/logout`,
  singleSignOn: `${pathWeb}/login/sso`,
  invitation: `${pathWeb}/invitation`,
  validateMfa: `${pathWeb}/validateMfa`,
  requestPasswordReset: `${pathWeb}/requestPasswordReset`,
  recoverPasswordRequest: `${pathWeb}/recoverPasswordRequest`,
};

export const admin = {
  accessGroup: `${pathWeb}/admin/accessgroup`,
  addAccessGroup: `${pathWeb}/admin/accessgroup/add`,
  editAccessGroup: `${pathWeb}/admin/accessgroup/edit`,
  settings: `${pathWeb}/admin/settings`,
  logs: `${pathWeb}/admin/logs`,
};

export const person = {
  personClient: `${pathWeb}/person/client`,
  addPersonClient: `${pathWeb}/person/client/add`,
  editPersonClient: `${pathWeb}/person/client/edit`,

  personResale: `${pathWeb}/person/resale`,
  addPersonResale: `${pathWeb}/person/resale/add`,
  editPersonResale: `${pathWeb}/person/resale/edit`,
  addPersonInvitationResale: `${pathWeb}/person/resale/invite/add`,

  personEmployee: `${pathWeb}/person/employee`,
  addPersonEmployee: `${pathWeb}/person/employee/add`,
  editPersonEmployee: `${pathWeb}/person/employee/edit`,
  addPersonInvitationEmployee: `${pathWeb}/person/employee/invite/add`,

  personMassActions: `${pathWeb}/person/massActions`,

  lineBusiness: `${pathWeb}/person/linebusiness`,
  addLineBusiness: `${pathWeb}/person/linebusiness/add`,
  editLineBusiness: `${pathWeb}/person/linebusiness/edit`,

  channelRating: `${pathWeb}/person/channelrating`,
  addChannelRating: `${pathWeb}/person/channelrating/add`,
  editChannelRating: `${pathWeb}/person/channelrating/edit`,
};

export const productPaths = {
  productSoftware: `${pathWeb}/product/software`,
  addProductSoftware: `${pathWeb}/product/software/add`,
  editProductSoftware: `${pathWeb}/product/software/edit`,

  productModality: `${pathWeb}/product/modality`,
  addProductModality: `${pathWeb}/product/modality/add`,
  editProductModality: `${pathWeb}/product/modality/edit`,

  productModule: `${pathWeb}/product/module`,
  addProductModule: `${pathWeb}/product/module/add`,
  editProductModule: `${pathWeb}/product/module/edit`,
};

export const commercialPaths = {
  commercialOrder: `${pathWeb}/commercial/order`,
  addCommercialOrder: `${pathWeb}/commercial/order/add`,
  editCommercialOrder: `${pathWeb}/commercial/order/edit`,
  managementCommercialOrder: `${pathWeb}/commercial/order/management`,

  commercialSolicitation: `${pathWeb}/commercial/solicitation`,
  addCommercialSolicitation: `${pathWeb}/commercial/solicitation/add`,
  editCommercialSolicitation: `${pathWeb}/commercial/solicitation/edit`,

  reasonSolicitation: `${pathWeb}/commercial/reasonsolicitation`,
  addReasonSolicitation: `${pathWeb}/commercial/reasonsolicitation/add`,
  editReasonSolicitation: `${pathWeb}/commercial/reasonsolicitation/edit`,

  payments: `${pathWeb}/commercial/payments`,
  addPayments: `${pathWeb}/commercial/payments/add`,
  editPayments: `${pathWeb}/commercial/payments/edit`,
};

export const licensePaths = {
  activeLicenses: `${pathWeb}/license/active`,
  canceledLicenses: `${pathWeb}/license/cancelled`,
  totalCancellation: `${pathWeb}/license/active/totalcancellation`,
  partialCancellation: `${pathWeb}/license/active/partialcancellation`,

  monitorRequests: `${pathWeb}/license/monitorcancellation`,
  editRequests: `${pathWeb}/license/monitorcancellation/edit`,

  monitorKey: `${pathWeb}/license/monitorkey`,
  generateKey: `${pathWeb}/license/generatekey`,

  reasonCancellation: `${pathWeb}/license/reasoncancellation`,
  addReasonCancellation: `${pathWeb}/license/reasoncancellation/add`,
  editReasonCancellation: `${pathWeb}/license/reasoncancellation/edit`,
  addSubReasonCancellation: `${pathWeb}/license/subreasoncancellation/add`,
};

export const formPaths = {
  formRegister: `${pathWeb}/form/register`,
  addFormRegister: `${pathWeb}/form/register/add`,
  editFormRegister: `${pathWeb}/form/register/edit`,

  formMaintenance: `${pathWeb}/form/maintenance`,
  editFormMaintenance: `${pathWeb}/form/maintenance/edit`,

  formViewer: `${pathWeb}/form/viewer`,
  formAnswerViewerFilled: `${pathWeb}/form/viewer/response/filled`,
};

export const goalPaths = {
  goalMaintenance: `${pathWeb}/goal/maintenance`,
  addGoalMaintenance: `${pathWeb}/goal/maintenance/add`,
  editGoalMaintenance: `${pathWeb}/goal/maintenance/edit`,

  goalMonitoring: `${pathWeb}/goal/monitoring`,
  monitorGoalsMonitoring: `${pathWeb}/goal/monitoring/monitorGoals`,
};

export const opportunityPaths = {
  reasonLoss: `${pathWeb}/opportunity/reasonloss`,
  addReasonLoss: `${pathWeb}/opportunity/reasonloss/add`,
  editReasonLoss: `${pathWeb}/opportunity/reasonloss/edit`,

  leadOrigin: `${pathWeb}/opportunity/leadorigin`,
  addLeadOrigin: `${pathWeb}/opportunity/leadorigin/add`,
  editLeadOrigin: `${pathWeb}/opportunity/leadorigin/edit`,

  maintenance: `${pathWeb}/opportunity/maintenance`,
  addMaintenance: `${pathWeb}/opportunity/maintenance/funil/add`,
  editMaintenance: `${pathWeb}/opportunity/maintenance/funil/edit`,

  monitoring: `${pathWeb}/opportunity/monitoring`,
  funilMonitoring: `${pathWeb}/opportunity/monitoring/funil`,
  addOpportunityFunil: `${pathWeb}/opportunity/monitoring/funil/add`,
  editOpportunityFunil: `${pathWeb}/opportunity/monitoring/funil/edit`,
};
