import React from 'react';
import PropTypes from 'prop-types';
import { formatPhone } from '~/util/stringutils';

function UserInfoResale(props) {
  const {
    nomeFantasia = '',
    razaoSocial = '',
    responsavel = '',
    telefone = '',
    email = '',
  } = props;

  return (
    <>
      <label>Nome da Revenda: <b>{nomeFantasia || razaoSocial}</b></label>
      <label>Responsável pelo formulário: <b>{responsavel}</b></label>
      <label>Telefone: <b>{formatPhone(telefone)}</b></label>
      <label>E-mail: <b>{email}</b></label>
    </>
  );
}

UserInfoResale.propTypes = {
  nomeFantasia: PropTypes.string,
  razaoSocial: PropTypes.string,
  responsavel: PropTypes.string,
  telefone: PropTypes.string,
  email: PropTypes.string,
};

export { UserInfoResale };
