import React, { useEffect, useState } from 'react';
import Popover from '@material-ui/core/Popover';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import { ModalPropoverContainer } from './styles';
import { useWindowDimensions } from '~/hook/useWindowDimensions';
import { MQL_MATCH_MEDIA } from '~/styles';
import * as XLSX from 'xlsx';

function DownloadReportSelect(downloadReportSelectProps) {
  const {
    onBeforePrintCallback,
    printableRef,
    excelDataColumnsJson,
    excelDataRowsJson,
    excelCustonName,
    loading,
  } = downloadReportSelectProps;

  const { width: DIMENSION_WIDTH_PAGE_WEB } = useWindowDimensions();
  const [isWebMobile, setIsWebMobile] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    onBeforePrintCallback();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handlerDownloadXLSX = async (e) => {
    e.preventDefault();

    const PAGE = 'Monitorar Cancelamentos';

    const columns = excelDataColumnsJson
      .map((column) => column.key)
      .map((key) => {
        const max_width = excelDataRowsJson.reduce((w, r) => {
          const width = Math.max(w, (r[key] || '').length);

          return !isNaN(width) ? width : 10;
        }, 10);

        return { key, max_width: { wch: max_width } };
      });

    const ws = XLSX.utils.json_to_sheet(excelDataRowsJson);

    XLSX.utils.sheet_add_aoa(ws, [
      excelDataColumnsJson.map((column) => column.header)
    ], { origin: 'A1' });

    ws['!cols'] = [...columns.map((w) => w.max_width)];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, PAGE);

    XLSX.writeFile(wb, `${(excelCustonName || PAGE)}.xlsx`);
  };

  const renderPropoverContainer = () => (
    <ModalPropoverContainer>
      <ReactToPrint
        trigger={() => (
          <button
            className="buttonBaseDownloadReport colorMainButton"
            onClick={handleClose}
          >
            {isWebMobile ? 'PDF' : 'RELATÓRIO EM PDF'}
          </button>
        )}
        content={printableRef}
      />
      <div />
      <button onClick={handlerDownloadXLSX}>
        {isWebMobile ? 'XLSX' : 'RELATÓRIO EM XLSX'}
      </button>
    </ModalPropoverContainer>
  );

  useEffect(() => {
    const isWebFull = MQL_MATCH_MEDIA <= DIMENSION_WIDTH_PAGE_WEB;
    if (isWebFull) { setIsWebMobile(isWebFull); }
  }, [DIMENSION_WIDTH_PAGE_WEB]);

  return (
    <>
      {isWebMobile ? (
        <>
          <button
            className="buttonBase reportButton addMargin"
            onClick={handleClick}
          >
            {'GERAR RELATÓRIO'}
          </button>
          {!loading && (
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {renderPropoverContainer()}
            </Popover>
          )}
        </>
      ) : (
        <>
          {renderPropoverContainer()}
        </>
      )}
    </>
  );
}

DownloadReportSelect.propTypes = {
  printableRef: PropTypes.func,
  onBeforePrintCallback: PropTypes.func,
  excelDataColumnsJson: PropTypes.array,
  excelDataRowsJson: PropTypes.array,
  excelCustonName: PropTypes.string,
  loading: PropTypes.bool,
};

export default DownloadReportSelect;
