import styled from 'styled-components';

export const InfoHolder = styled.div`
  flex: 1;
  display: flex;
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 15px;
  max-width: 100%;
  border: 1px solid #C5C5D4;
  margin-top: ${({ addMarginTop }) => addMarginTop ? '20px' : 0};
`;

export const DataInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  .info {
    display: flex;
    flex-direction: column;

    &.col2 {
      width: calc(50% - 10px);

      @media screen and (max-width: 1080px) {
        width: 100%;
      }
    }

    span {
      strong {
        margin-right: 6px;
      }
    }

    .description {
      width: 100%;
      overflow-wrap: break-word;
      word-wrap: break-word;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

