import moment from 'moment';

export default class PersonResaleNew {
  constructor(props) {
    const {
      grupoId,
      tipoPessoa,
      cpfCnpj,
      nomeFantasia,
      razaoSocial,
      inscricaoEstadual,
      optanteSimples,
      ramoAtividadeId,
      estado,
      cidade,
      cep,
      bairro,
      endereco,
      complemento,
      pontoReferencia,
      telefone1,
      telefone2,
      email,
      cnae,
      dataFundacao,
      microempresa,
      retemICMS,
      segmentos,
      classificacaoId,
      descontosRevenda,
      comissionada = false,
      status,
      prazoRenovacaoChave,
      statusChave,
      responsavelExecutivoId,
    } = props;

    this.grupoId = grupoId;
    this.tipoPessoa = tipoPessoa ? tipoPessoa : 'JURÍDICA';
    this.cpfCnpj = cpfCnpj;
    this.nomeFantasia = nomeFantasia;
    this.razaoSocial = razaoSocial;
    this.inscricaoEstadual = inscricaoEstadual;
    this.optanteSimples = optanteSimples || false;
    this.ramoAtividadeId = ramoAtividadeId;

    this.estado = estado;
    this.cidade = cidade;
    this.cep = cep;
    this.bairro = bairro;
    this.endereco = endereco || '';
    this.complemento = complemento;
    this.pontoReferencia = pontoReferencia;

    this.telefone1 = telefone1;
    this.telefone2 = telefone2;
    this.email = email;

    this.cnae = cnae;
    this.dataCadastro = moment().format('YYYY-MM-DD');
    this.dataFundacao = dataFundacao;
    this.microempresa = microempresa || false;
    this.retemICMS = retemICMS || false;
    this.segmentos = segmentos || false;
    this.classificacaoId = classificacaoId;
    this.descontosRevenda = descontosRevenda || [];
    this.comissionada = comissionada || false;

    this.status = status || 'Não Bloqueado';
    this.prazoRenovacaoChave = prazoRenovacaoChave || '30';
    this.statusChave = statusChave || 'Não Bloqueado';
    this.responsavelExecutivoId = responsavelExecutivoId || null;
  }
}

/*
  {
    POST {
      "grupoId": 61,
      "tipoPessoa": "JURIDICA",
      "cpfCnpj": "1531646156614",
      "razaoSocial": "Teste R",
      "nomeFantasia": "Teste F",
      "inscricaoEstadual": "213130045160",
      "optanteSimples": true,
      "estado": "CEARÁ",
      "cidade": "Fortaleza",
      "cep": "60000000",
      "bairro": "Aldeota",
      "endereco": "Avenida X",
      "complemento": "BL 84 apto 754",
      "pontoReferencia": "Aqui",
      "telefone1": "9999999999",
      "telefone2": "8888888888",
      "email": "teste@email.com",
      "cnae": "CNAE",
      "dataCadastro": "moment().format('YYYY-MM-DD')",
      "dataFundacao": "moment().format('YYYY-MM-DD')",
      "imagem": "base64 image",
      "microempresa": true,
      "retemICMS": false,
      "segmentos": true,
      "classificacaoId": 123,
      "descontosRevenda": [
        3,
        5,
        4
      ],
      "status": "Não Bloqueado",
      "prazoRenovacaoChave": "30",
      "statusChave": "Não Bloqueado",
      "responsavelExecutivo": 635,
    }
  }
*/
