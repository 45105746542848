const API_CONFIG = {
  prod: {
    BASE_URL_WEB: '/',
    BASE_URL_PATH: '',
    BASE_URL_IO_HOST: '',
    BASE_URL_IO_PATH: '',
    BASE_URL_API: 'https://cmportal.casamagalhaes.com.br/api/v1',
  }
};

export const RUNTIME_API = API_CONFIG['prod'];
Object.freeze(RUNTIME_API);
