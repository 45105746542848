import React, { useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { CheckmarkContainer, FormRequiredContainer, HandleHoverAction } from './styles';
import { ContainerRow, CopyIcon, DeleteIcon } from '../styles';

function FormRequired({
  form,
  setForm,
  formItemIndex,
  formItemRequiredValue,
  isTitle,
  showRequired = true,
  handleDuplicateItemForm,
  handleRemoveItemForm,
  handleRemoveActions = false,
}) {
  const [isTitleValue, setIsTitleValue] = useState(formItemRequiredValue);
  const isTitleRef = useRef();

  const handleUpdateIsTitle = useCallback(() => {
    const value = isTitleRef.current.checked;

    form[formItemIndex].itemData.required = value;

    setIsTitleValue(value);

    setForm(form);
  }, [form, setForm, formItemIndex, setIsTitleValue]);

  return (
    <FormRequiredContainer>
      {!handleRemoveActions && (
        <ContainerRow>
          {showRequired && (
            <CheckmarkContainer>
              <div className="rowContainer noMarginTop">
                <input
                  ref={isTitleRef}
                  className='required-item-form'
                  type="checkbox"
                  name="nome"
                  value={isTitleValue}
                  checked={isTitleValue}
                  onChange={handleUpdateIsTitle}
                />
                {!isTitle ? (
                  <span id='label-checkbox'>Obrigatório</span>
                ) : (
                  <span id='label-checkbox'>Definir como cabeçalho</span>
                )}
              </div>
            </CheckmarkContainer>
          )}
          <div className="rowContainer rowContainerEnd noMarginTop">
            <HandleHoverAction>
              <CopyIcon title='Duplicar' onClick={handleDuplicateItemForm} />
            </HandleHoverAction>
            <HandleHoverAction>
              <DeleteIcon title='Excluir' onClick={handleRemoveItemForm} />
            </HandleHoverAction>
          </div>
        </ContainerRow>
      )}
    </FormRequiredContainer>
  );
}

FormRequired.propTypes = {
  form: PropTypes.any.isRequired,
  setForm: PropTypes.func.isRequired,
  formItemIndex: PropTypes.number.isRequired,
  formItemRequiredValue: PropTypes.bool.isRequired,
  isTitle: PropTypes.bool.isRequired,
  showRequired: PropTypes.bool.isRequired,
  handleDuplicateItemForm: PropTypes.func.isRequired,
  handleRemoveItemForm: PropTypes.func.isRequired,
  handleRemoveActions: PropTypes.bool.isRequired,
};

export { FormRequired };
