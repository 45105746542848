import styled from 'styled-components';
import {
  MdPostAdd,
  MdTextFields,
  MdPhotoLibrary,
  MdArrowCircleDown,
  MdArrowCircleUp,
} from 'react-icons/md';

export const HoverContainer = styled.div``;

export const ContainerActions = styled.div`
  position: fixed;
  top: ${(props) => (`${props.top ? props.top : 470}px`)};
  right: 38px;
  margin-left: 20px;
  width: auto;
  height: min-content;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 8px;

    svg {
      width: 24px;
      height: 24px;
      color: #0000008A;
      cursor: pointer;
    }
  }

  div:hover {
    background-color: #E5E5E5;
  }

  div:nth-child(1) {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  div:nth-child(2), div:nth-child(3) {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  div:nth-child(4) {
    height: 16px;
    background-color: #fff;
  }

  div:nth-child(5) {
    svg {
      opacity: ${(props) => (props.disabled_action_up ? 0.5 : 1)};
    }
  }

  div:nth-child(6) {
    svg {
      opacity: ${(props) => (props.disabled_action_down ? 0.5 : 1)};
    }
  }

  div:nth-child(5):hover {
    background-color: ${(props) => (`#${props.disabled_action_up ? 'fff' : 'E5E5E5'}`)};
  }

  div:nth-child(6):hover {
    background-color: ${(props) => (`#${props.disabled_action_down ? 'fff' : 'E5E5E5'}`)};
  }

  div.divider-horizontal {
    height: 1px;
    margin: 0 7.5px;
    background-color: #0000008A;
  }

  div.divider-horizontal:hover {
    background-color: #0000008A;
  }

  div + div {
    margin-top: 2.5px;
  }
`;

export const DividerHorizontal = styled.div`
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
`;

export const PostAddIcon = styled(MdPostAdd)``;

export const TextFieldsIcon = styled(MdTextFields)``;

export const PhotoLibraryIcon = styled(MdPhotoLibrary)``;

export const ArrowDownIcon = styled(MdArrowCircleDown)``;

export const ArrowUpIcon = styled(MdArrowCircleUp)``;
