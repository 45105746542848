import styled from 'styled-components';
import Popover from '@material-ui/core/Popover';

export const ModalPropoverContainer = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};

  .MuiPopover-root {
    z-index: ${({ visible }) => visible ? 1300 : -1};
  }
`;

export const ModalPropover = styled(Popover)`
  right: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .MuiPaper-rounded {
    border-radius: 0;
  }

  .MuiPaper-root {
    color: none;
    transition: none;
    background-color: transparent;
  }
`;
