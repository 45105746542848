import { toast } from 'react-toastify';
import { USERINFO_KEY } from './auth';
import { PERSON_STATUS_ENUM } from './domainutils';
import { initialPaths, authenticationPaths } from '~/routes/paths';

/**
 * If the user doesn't have permission, show a toast message and redirect the user to
 * the home page.
 * @param history - The history object is used to programmatically navigate between
 * routes.
 */
export function ACCESS_DENIED(history) {
  try {
    toast.error('Acesso Negado!');
    setTimeout(() => {
      return history.push(initialPaths.pageInitial);
    }, 100); // 0.10 s
  } catch (error) {
    toast.error('Erro ao redirecionar usuário.');
  }
}

/**
 * It checks if the user is blocked and if so, it logs the user out and redirects
 * to the login page
 * @param user - The user object that you get from the API.
 */
export async function CHECK_USER_BLOCK(user, history) {
  if (user && user.status === PERSON_STATUS_ENUM.BLOCKED.value) {
    try {
      toast.error('Você está bloqueado para usar o Portal de Relacinamento!');
      localStorage.clear();
      setTimeout(() => {
        history.push(authenticationPaths.login);
      }, 100); // 0.10 s
    } catch (err) {
      toast.error('Erro ao verificar status do usuário.');
    }
  }
}

/**
 * It redirects the user to the login page.
 */
export function RECONNECT_USER(history) {
  try {
    setTimeout(() => {
      history.push(authenticationPaths.login);
    }, 100); // 0.10 s
  } catch (err) {
    toast.error('Erro ao reconectar usuário.');
  }
}

/**
 * This function checks if user information is authenticated and returns it if it
 * exists.
 * @returns An array containing two values: the parsed JSON object of the user
 * information stored in the local storage and a boolean indicating whether the
 * user information exists or not. If the user information does not exist in the
 * local storage, null is returned.
 */
export function IS_USER_INFO_AUTH() {
  const userInfo = localStorage.getItem(USERINFO_KEY);
  if (!userInfo) return null;
  return [JSON.parse(userInfo), !!userInfo];
}
