import api, { endpoints } from '~/services/api';

class CommercialOrderService {
  static getPaymentTypes = async () => {
    const { data: response } = await api.get(
      endpoints.commercial.order.getPaymentTypes
    );

    return response.data;
  };

  static getOrder = async (id) => {
    const { data: response } = await api.get(
      endpoints.commercial.order.getCommercialOrder(id),
    );

    return response.data;
  };

  static getOrders = async (params) => {
    const { data: response } = await api.post(
      endpoints.commercial.order.getCommercialOrders,
      { ...params }
    );

    return response.data;
  };

  static createOrder = async (formData, formAnswerData) => {
    const { data: response } = await api.post(
      endpoints.commercial.order.createCommercialOrder,
      { ...formData, formAnswerData }
    );

    return response;
  };

  static updateOrder = async (formData, formAnswerData) => {
    const { data: response } = await api.put(
      endpoints.commercial.order.updateCommercialOrder,
      { ...formData, formAnswerData }
    );

    return response;
  };

  static updateStatusOrder = async (pedidoId, newStatus, observacao) => {
    const { data: response } = await api.put(
      endpoints.commercial.order.updateStatusOrder,
      { pedidoId, newStatus, observacao }
    );

    return response.data;
  };

  static createDraftOrder = async (formData, formAnswerData) => {
    const { data: response } = await api.post(
      endpoints.commercial.order.createCommercialOrderDraft,
      { ...formData, formAnswerData }
    );

    return response;
  };

  static isVerifyStatusOrder = async (pedidoId) => {
    const { data: response } = await api.get(
      endpoints.commercial.order.verifyStatusOrder(pedidoId),
    );

    return response.data;
  };


  static deleteOrder = async (id) => {
    const { data: response } = await api.delete(
      endpoints.commercial.order.deleteCommercialOrder(id)
    );

    return response;
  };

  static addAnexoOrder = async (formData) => {
    const { data: response } = await api.post(
      endpoints.commercial.order.addAnexoOrder,
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      }
    );

    return response;
  }

  static getAnexoFile = async (itemId) => {
    const response = await api.get(
      endpoints.commercial.order.getAnexoFile(itemId),
      { responseType: 'blob' }
    );

    return response;
  }
}

export { CommercialOrderService };
