import api, { endpoints } from '~/services/api';

class FormRegisterService {
  static getForm = async (id) => {
    const { data: response } = await api.get(
      endpoints.form.register.getForm(id)
    );

    return response.data;
  };

  static getFormsById = async (id) => {
    const { data: response } = await api.post(
      endpoints.form.register.getForms, { id }
    );

    return response.data;
  };

  static getFormsByModuleId = async (moduleId) => {
    const { data: response } = await api.post(
      endpoints.form.register.getForms, { moduleId }
    );

    return response.data;
  };

  static getPeopleFormsById = async (clienteId, revendaId) => {
    const { data: response } = await api.post(
      endpoints.person.personForms, { ids: [clienteId, revendaId] }
    );

    return response.data;
  };
}

export { FormRegisterService };
