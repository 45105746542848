import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MdContentCopy } from 'react-icons/md';
import { FiCheckCircle } from 'react-icons/fi';
import moment from 'moment';
import { licensePaths } from '~/routes/paths';
import { unFormatCpfCnpj } from '~/util/stringutils';
import { ModalContent } from '../../styles';
import { ButtonCopy, Copied } from './styles';
import copy from 'copy-to-clipboard';

export default function Generated({
  formGeneratingKey,
  formGeneratedKeyResponse,
}) {
  const [isCopied, setIsCopied] = useState(false);
  const { cliente, software } = formGeneratingKey;
  const chaveResponse = formGeneratedKeyResponse;

  const handleValue = (text) => text ? text : '---';
  const day = (data) => moment(data).format('DD/MM/YYYY');
  const hour = (data) => moment(data).format('HH:mm:ss');

  function copyTextToClipboard() {
    copy(chaveResponse.chave);
    setIsCopied(true);
  }

  function downloadFileCHV(event) {
    event.preventDefault();
    function download(filename, text) {
      const KEY_TEXT_PLAIN = 'data:text/plain;charset=utf-8';
      const KEY_ENCODE_URI = encodeURIComponent(text);
      var element = document.createElement('a');
      element.setAttribute('href', `${KEY_TEXT_PLAIN},${KEY_ENCODE_URI}`);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
    const F_CPF_CNPJ = unFormatCpfCnpj(cliente.cpfCnpj);
    const F_SOFTWARE = software.titulo;
    const F_DATA_GERACAO = moment(chaveResponse.geradaEm).format('DD.MM.YYYY');
    const FILENAME_KEY = `${F_CPF_CNPJ}-${F_SOFTWARE}-${F_DATA_GERACAO}`;
    download(`${FILENAME_KEY}.chv`, chaveResponse.chave);
  }

  return (
    <ModalContent>
      <section className='withMarginBottom'>
        <span className='noMarginTop'>
          <b>Título: </b>
          {handleValue(software.titulo)}
        </span>
        <div className='noMarginBottom'>
          <span>
            <b>Versão: </b>
            {handleValue(chaveResponse.versao)}
          </span>
          <span>
            <b>Controle: </b>
            {handleValue(chaveResponse.controle)}
          </span>
          <span>
            <b>Série: </b>
            {handleValue(software.serie)}
          </span>
        </div>
        <span>
          <b>Gerada por: </b>
          {handleValue(chaveResponse.geradaPor)}
        </span>
        <span>
          <b>Empresa: </b>
          {handleValue(cliente.nomeFantasia || cliente.razaoSocial)}
        </span>
        <div className='noMarginBottom'>
          <span>
            <b>Data: </b>
            {handleValue(day(chaveResponse.geradaEm))}
          </span>
          <span>
            <b>Horário: </b>
            {handleValue(hour(chaveResponse.geradaEm))}
          </span>
        </div>
        <span className='withDisplayFlex'>
          <b>Chave: </b>
          <small>{chaveResponse.chave}</small>
          <ButtonCopy onClick={copyTextToClipboard}>
            <MdContentCopy />Copiar
          </ButtonCopy>
        </span>
        <a href={licensePaths.generateKey} onClick={downloadFileCHV}>
          Clique aqui para baixar o arquivo de chave
        </a>
        {isCopied && (
          <Copied className='duplicateMarginBottom'>
            <FiCheckCircle />Chave copiada com sucesso!
          </Copied>
        )}
      </section>
    </ModalContent>
  );
}

Generated.propTypes = {
  formGeneratingKey: PropTypes.shape({
    cliente: PropTypes.shape({
      nomeFantasia: PropTypes.string.isRequired,
      razaoSocial: PropTypes.string.isRequired,
      cpfCnpj: PropTypes.string.isRequired,
    }).isRequired,
    software: PropTypes.shape({
      titulo: PropTypes.string.isRequired,
      serie: PropTypes.string.isRequired,
    }).isRequired,
  }),
  formGeneratedKeyResponse: PropTypes.shape({}),
};
