import React, { useEffect, useState } from 'react';
import { useEvent } from 'react-use';
import PropTypes from 'prop-types';
import {
  PostAddIcon,
  TextFieldsIcon,
  PhotoLibraryIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  ContainerActions,
  HoverContainer,
  DividerHorizontal,
} from './styles';

function FormActions({
  handleAddNewTitle,
  handleAddNewAnswer,
  handleAddNewImage,
  handleScrollTop,
  handleScrollEnd,
}) {
  const [scrollActions, setScrollActions] = useState(0);
  const [scrollDirection, setScrollDirection] = useState('bottom');

  useEffect(() => {
    const itemsForm = document.getElementById('itemsFormContainer');

    if (itemsForm) {
      const positionY = itemsForm.getBoundingClientRect().y;

      setScrollActions(positionY);
    }
  }, []);

  useEvent('scroll', () => {
    const itemsForm = document.getElementById('itemsFormContainer');
    const formEnd = document.getElementById('formEnd');

    if (itemsForm && formEnd) {
      const positionY = itemsForm.getBoundingClientRect().y;

      if (scrollDirection === 'bottom') {
        if (positionY <= 95) {
          setScrollActions(95 - 1);
          setScrollDirection('top');
          return;
        }
      }

      if (scrollDirection === 'top') {
        if (positionY >= 95) {
          setScrollActions(398 + 1);
          setScrollDirection('bottom');
          return;
        }
      }
    }
  }, window, { capture: true });

  return (
    <ContainerActions top={scrollActions}>
      <HoverContainer>
        <PostAddIcon
          title='Nova Pergunta'
          onClick={handleAddNewAnswer}
        />
      </HoverContainer>
      <HoverContainer>
        <TextFieldsIcon
          title='Novo título e descrição'
          onClick={handleAddNewTitle}
        />
      </HoverContainer>
      <HoverContainer>
        <PhotoLibraryIcon
          title='Nova imagem'
          onClick={handleAddNewImage}
        />
      </HoverContainer>
      <DividerHorizontal>
        <div className='divider-horizontal' />
      </DividerHorizontal>
      <HoverContainer>
        <ArrowUpIcon
          title='Ir para topo'
          onClick={handleScrollTop}
        />
      </HoverContainer>
      <HoverContainer>
        <ArrowDownIcon
          title='Ir para rodapé'
          onClick={handleScrollEnd}
        />
      </HoverContainer>
    </ContainerActions>
  );
}

FormActions.propTypes = {
  handleAddNewTitle: PropTypes.func,
  handleAddNewAnswer: PropTypes.func,
  handleAddNewImage: PropTypes.func,
  handleScrollTop: PropTypes.func,
  handleScrollEnd: PropTypes.func,
};

export { FormActions };
