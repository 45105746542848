import { getSameDate } from '~/util/dateutils';

import Contact from '~/model/contact';
import Address from '~/model/address';
import { identifyCpfCnpj } from '~/util/stringutils';

export default class PersonClientEdit {
  constructor(props) {
    const {
      id,
      tipoPessoa,
      cpfCnpj,
      nomeFantasia,
      razaoSocial,
      responsavel,
      inscricaoEstadual,
      optanteSimples,
      ramoAtividadeId,
      endereco,
      contato,
      cnae,
      dataCadastro,
      createdAt,
      microempresa,
      retemICMS,
      segmentos,
      status,
      prazoRenovacaoChave,
      statusChave,
      revendaId,
    } = props;

    const getTipoPessoa = (tipoPessoaObj, cpfCnpjObj) => {
      if (cpfCnpjObj) {
        if (identifyCpfCnpj(cpfCnpjObj) === 'CPF') {
          return 'FÍSICA';
        }

        return 'JURÍDICA';
      }

      return tipoPessoaObj ? tipoPessoaObj : 'FÍSICA';
    };

    this.id = id;
    this.tipoPessoa = getTipoPessoa(tipoPessoa, (cpfCnpj || ''));
    this.cpfCnpj = cpfCnpj || '';
    this.nomeFantasia = nomeFantasia || '';
    this.razaoSocial = razaoSocial || '';
    this.responsavel = responsavel || '';
    this.inscricaoEstadual = inscricaoEstadual || '';
    this.optanteSimples = optanteSimples || false;
    this.ramoAtividadeId = ramoAtividadeId || '';

    if (endereco) {
      const address = new Address(endereco);
      this.estado = address.estado || '';
      this.cidade = address.cidade || '';
      this.cep = address.cep || '';
      this.bairro = address.bairro || '';
      this.endereco = address.endereco || '';
      this.complemento = address.complemento || '';
      this.pontoReferencia = address.pontoReferencia || '';
    }

    if (contato) {
      const contact = new Contact(contato);
      this.telefone1 = contact.telefone1 || '';
      this.telefone2 = contact.telefone2 || '';
      this.email = contact.email || '';
    }

    this.cnae = cnae || '';

    if (createdAt) {
      this.dataCadastro = getSameDate(createdAt, dataCadastro);
    }

    this.microempresa = microempresa || false;
    this.retemICMS = retemICMS || false;
    this.segmentos = segmentos || false;
    this.status = status || '';
    this.statusChave = statusChave || '';

    this.prazoRenovacaoChave = prazoRenovacaoChave || '30';
    this.revendaId = revendaId;
  }
}

/*
{
  POST {
    "tipoPessoa": "JURÍDICA",
    "cpfCnpj": "1531646156614",
    "razaoSocial": "Teste R",
    "nomeFantasia": "Teste F",
    "responsavel": "Teste V",
    "inscricaoEstadual": "213130045160",
    "optanteSimples": true,
    "ramoAtividadeId": 42,

    "estado": "CEARÁ",
    "cidade": "Fortaleza",
    "cep": "60000000",
    "bairro": "Aldeota",
    "endereco": "Avenida X",
    "complemento": "BL 84 apto 754",
    "pontoReferencia": "Aqui",

    "telefone1": "9999999999",
    "telefone2": "8888888888",
    "email": "teste@email.com",

    "cnae": "CNAE",
    "dataCadastro": "JS Date",
    "microempresa": true,
    "retemICMS": false,
    "segmentos": true,

    "status": "Não Bloqueado";
    "prazoRenovacaoChave": ""
    "statusChave": "Não Bloqueado";
  }
}
*/
