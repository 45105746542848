import React, { useEffect, useState } from 'react';
import { InputPassword } from '~/components/FieldPassword';
import { useWindowDimensions } from '~/hook/useWindowDimensions';
import { MQL_MATCH_MEDIA } from '~/styles';
import * as S from './styles';
import { PasswordInformation } from '~/components/PasswordInformation';

function FormRegister(params) {
  const { width: DIMENSION_WIDTH_PAGE_WEB } = useWindowDimensions();
  const [isWebFull, setIsWebMobile] = useState(MQL_MATCH_MEDIA <= DIMENSION_WIDTH_PAGE_WEB);

  const {
    handleSubmit,
    errors,
    password,
    confirmPassword,
    handleInputPasswordChange,
    handleInputConfirmPasswordChange,
  } = params;

  useEffect(() => {
    const isWebFull = MQL_MATCH_MEDIA <= DIMENSION_WIDTH_PAGE_WEB;
    if (isWebFull) { setIsWebMobile(isWebFull); }
  }, [DIMENSION_WIDTH_PAGE_WEB]);

  return (
    <form onSubmit={handleSubmit}>
      <S.ContainerContent>
        <label id='title'>Segurança</label>
        <div className="rowContainer">
          <div className={`inputHolder defaultFlex ${!isWebFull && 'noMarginRight'}`}>
            <span>Senha *</span>
            <InputPassword
              autoComplete="new-password"
              type="password"
              name="password"
              value={password}
              onChange={handleInputPasswordChange}
            />
            {errors.errorSenha && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Confirmar senha *</span>
            <InputPassword
              autoComplete="new-confirm-password"
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleInputConfirmPasswordChange}
            />
            {errors.errorConfirmarSenha && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
        </div>

        <PasswordInformation password={password} />
      </S.ContainerContent>
    </form>
  );
}

export { FormRegister };
