import api, { endpoints } from '~/services/api';

class MonitorKeyService {
  static getCancelledLicenses = async (params) => {
    const { data: response } = await api.post(
      endpoints.license.getCancelledLicenses,
      { ...params }
    );

    return response.data;
  };
}

export { MonitorKeyService };
