export const actionTypes = {
  USER_UPDATE_DATA: '@user/USER_UPDATE_DATA',
};

export function userUpdateData(data) {
  return {
    type: actionTypes.USER_UPDATE_DATA,
    payload: {
      data,
    },
  };
}
