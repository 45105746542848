import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import './styles.scss';

function PeriodInDate({
  customTitle,
  customMask,
  dataInicio,
  handleDataInicioChange,
  dataFim,
  handleDataFimChange,
}) {
  return (
    <>
      <span>{customTitle || 'Período'}</span>
      <div id="dates">
        <InputMask
          mask={customMask || '99/99/9999'}
          name="initialDate"
          value={dataInicio}
          onChange={handleDataInicioChange}
          style={{ textAlign: 'center' }}
        />
        <span id="dateSeparator">à</span>
        <InputMask
          mask={customMask || '99/99/9999'}
          style={{ paddingLeft: '10px', textAlign: 'center' }}
          name="finalDate"
          value={dataFim}
          onChange={handleDataFimChange}
        />
      </div>
    </>
  );
}

PeriodInDate.propTypes = {
  customMask: PropTypes.string,
  customTitle: PropTypes.string,
  dataFim: PropTypes.string.isRequired,
  dataInicio: PropTypes.string.isRequired,
  handleDataFimChange: PropTypes.func.isRequired,
  handleDataInicioChange: PropTypes.func.isRequired,
};

export { PeriodInDate };
