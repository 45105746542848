const SUPORT_FILES = {
  ANEXOS: [
    'image/png',
    'image/jpeg',
    'text/plain',
    'text/csv',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ],
  IMAGES: ['image/png', 'image/jpeg'],
  ACCEPT: 'image/png, image/jpeg, text/plain, text/csv, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

Object.freeze(SUPORT_FILES);

export { SUPORT_FILES };
