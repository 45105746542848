import queryString from 'query-string';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BackgroundLogin } from '~/components/BackgroundLogin';
import { InputPassword } from '~/components/FieldPassword';
import { Copyright } from '~/components/Copyright';
import Modal from '~/components/Modal';
import MarcaReduzidaPositiva from
  '~/assets/marca/reduzida/SVG/CM-marca_reduzida-positiva-RGB.svg';
import marcaPreferencialNegativa from
  '~/assets/marca/preferencial/SVG/CM-marca_preferencial-negativa-RGB.svg';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import { hiddenChatMovidesk } from '~/styles/movidesk';
import { authenticationPaths } from '~/routes/paths';
import './styles.scss';
import { PASSWORD_ATTEMPTS, passwordCheck } from '~/util/validationsutils';
import { PasswordInformation } from '~/components/PasswordInformation';

class RecoverPasswordRequest extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    location: PropTypes.shape({
      search: PropTypes.string
    }).isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      cpfCnpj: '',
      token: queryString.parse(props.location.search).token,
      password: '',
      passwordConfirmation: '',
      history: props.history,
      passwordAttempts: {
        key: PASSWORD_ATTEMPTS.KEY,
        maximum: PASSWORD_ATTEMPTS.MAXIMUM,
        time: PASSWORD_ATTEMPTS.TIME,
      },
      buttonBlockedAfterAttempts: false,
      countdownBlockedAfterAttempts: `(${PASSWORD_ATTEMPTS.TIME / 1000}s)`
    };
  }

  componentDidMount() {
    hiddenChatMovidesk();

    const { token } = this.state;
    if (!token) {
      toast.error('Não foi possível verificar o token!');
      this.setState({ loading: false });
    }

    this.setState({ loading: true });

    api
      .post(endpoints.authentication.verifyTokenPasswordReset, { token })
      .then(
        ({ data: response }) => {
          this.setState({ user: response.data.data });
        },
        error => {
          showMessageError(error, 'Erro ao solicitar recuperação de senha!');
        }
      )
      .finally(() => {
        this.setState({ loading: false });
      });
  }


  setButtonBlockedAfterAttempts = b => this.setState({ buttonBlockedAfterAttempts: b });

  setCountdownBlockedAfterAttempts = b => this.setState({ countdownBlockedAfterAttempts: b });

  handleSResetPassword = async e => {
    e.preventDefault();
    const { password, passwordConfirmation, token, history } = this.state;

    if (!password || !passwordConfirmation) {
      toast('Preencha os campos!', { type: toast.TYPE.ERROR });

      return;
    }

    if (password !== passwordConfirmation) {
      toast('Senha tem que ser igual a confirmação de senha!', { type: toast.TYPE.ERROR });

      return;
    }

    const passwordChecked = passwordCheck(password);

    if (!passwordChecked.success) {
      toast(passwordChecked.message, { type: toast.TYPE.ERROR });

      const attemptsKey = this.state.passwordAttempts.key;
      const attempts = parseInt(localStorage.getItem(attemptsKey) || 0) + 1;

      localStorage.setItem(attemptsKey, attempts);

      if (attempts >= this.state.passwordAttempts.maximum) {
        this.setButtonBlockedAfterAttempts(true);

        let limit = this.state.passwordAttempts.time / 1000;
        const interval = setInterval(() => {
          if (limit === 1) {
            this.setButtonBlockedAfterAttempts(false);
            localStorage.setItem(attemptsKey, 0);
            clearInterval(interval);
            return;
          }

          limit = limit - 1;

          this.setCountdownBlockedAfterAttempts(`(${limit}s)`);
        }, 1000);
      }

      return;
    }

    this.setButtonBlockedAfterAttempts(false);
    localStorage.setItem(this.state.passwordAttempts.key, 0);

    try {
      this.setState({ loading: true });

      await api.post(endpoints.authentication.passwordReset, {
        token,
        novaSenha: password,
      });

      toast('A senha foi atualizada com sucesso!', {
        type: toast.TYPE.SUCCESS,
      });

      history.push(authenticationPaths.login);
    } catch (error) {
      showMessageError(error, 'Erro ao solicitar recuperação de senha!');
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;

    return (
      <BackgroundLogin>
        <div className="recoverContainerRequest">
          <Modal loading show={loading} />
          <div>
            <img
              src={marcaPreferencialNegativa}
              alt="Marca Preferencial Negativa Casa Maganhães"
              loading='lazy'
            />
            <div className="content">
              <div className="header">
                <img
                  src={MarcaReduzidaPositiva}
                  alt="Marca Reduzida Casa Magalhães"
                  loading='lazy'
                />
                <div>Cadastre a nova Senha</div>
              </div>
              <form className="form" onSubmit={this.handleSResetPassword}>
                <div className='formInput'>
                  <span className='title'>Nova Senha</span>
                  <InputPassword
                    autoComplete="new-password"
                    type="password"
                    name="password"
                    placeholder={'Senha'}
                    onChange={e => this.setState({
                      password: e.target.value,
                    })}
                    addMarginBottom={true}
                    addMarginTop={true}
                  />
                </div>
                <div className='formInput'>
                  <span className='title'>
                    Confirmação da Nova Senha
                  </span>
                  <InputPassword
                    autoComplete="new-password"
                    type="password"
                    name="passwordConfirmation"
                    placeholder={'Confirmação'}
                    onChange={e => this.setState({
                      passwordConfirmation: e.target.value,
                    })}
                    addMarginBottom={true}
                    addMarginTop={true}
                  />
                </div>
                <PasswordInformation password={this.state.password} />
                <div>
                  <button
                    className="sigin"
                    type="submit"
                    disabled={this.state.buttonBlockedAfterAttempts}
                    style={{
                      opacity: this.state.buttonBlockedAfterAttempts ? 0.5 : 1,
                      cursor: this.state.buttonBlockedAfterAttempts ? 'not-allowed' : 'pointer'
                    }}
                  >
                    ALTERAR SENHA {this.state.buttonBlockedAfterAttempts && this.state.countdownBlockedAfterAttempts}
                  </button>
                </div>
              </form>
            </div>
            <Copyright />
          </div>
        </div>
      </BackgroundLogin>
    );
  }
}

export default withRouter(RecoverPasswordRequest);
