import api, { endpoints } from '~/services/api';
import { unFormatCpfCnpj } from '~/util/stringutils';

class AuthenticationService {
  static signin = async (login, senha) => {
    const { data: response } = await api.post(
      endpoints.authentication.signin,
      { login: unFormatCpfCnpj(login), senha }
    );

    return response;
  }

  static requestPasswordReset = async (cpfCnpj) => {
    const { data: response } = await api.post(
      endpoints.authentication.requestPasswordReset,
      { cpfCnpj: unFormatCpfCnpj(cpfCnpj) },
    );

    return response.data;
  }

  static verifyTokenPasswordReset = async (token) => {
    const { data: response } = await api.post(
      endpoints.authentication.verifyTokenPasswordReset,
      { token }
    );

    return response.data;
  }

  static passwordReset = async (token, novaSenha) => {
    const { data: response } = await api.post(
      endpoints.authentication.passwordReset,
      { token, novaSenha },
    );

    return response;
  }
}

export { AuthenticationService };
