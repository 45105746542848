import React from 'react';
import { SelectClient } from '~/components/SelectClient';
import { useSolicitacao } from '~/contexts/solicitation';

function SelectClientSolicitation() {
  const {
    client,
    setClient,
    setClienteId,
    inputClienteValue,
    setInputClienteValue,
  } = useSolicitacao();

  return (
    <SelectClient
      client={client}
      setClient={setClient}
      setClienteId={setClienteId}
      inputClienteValue={inputClienteValue}
      setInputValue={setInputClienteValue}
      showDataClient
      messageUserBlock={'bloqueado para criação de solicitações!'}
    />
  );
}

export { SelectClientSolicitation };
