import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { passwordCriteria, passwordCheck } from '~/util/validationsutils';
import './styles.scss';

function PasswordInformation({ password }) {
  const [errors, setErrors] = useState([]);

  const handleOnChangePasswordCheck = () => {
    if (password.length) {
      const passwordChecked = passwordCheck(password);

      if (!passwordChecked.success) {
        const types = passwordChecked?.errors?.map((error) => error.type) || [];

        setErrors(types);
      } else {
        setErrors([]);
      }
    }
  };

  const handleHighlightVerifiedPassword = (type) => {
    return password.length ? errors.includes(type) ? 'passwordUnverified' : 'passwordVerified' : 'passwordUnverified';
  };

  useEffect(() => {
    handleOnChangePasswordCheck(password);
  }, [password, handleOnChangePasswordCheck]);

  return (
    <div className='passwordInformationContainer'>
      <p className='passwordInformationTitle'>Para definir uma senha segura é necessário:</p>
      <ul>
        {
          passwordCriteria.map((criteria) => (
            <li
              key={criteria.type}
              className={handleHighlightVerifiedPassword(criteria.type)}
            >
              {criteria.description}
            </li>
          ))
        }
      </ul>
    </div>
  );
}

PasswordInformation.propTypes = {
  password: PropTypes.string.isRequired,
};

export { PasswordInformation };
