import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formPaths } from '~/routes/paths';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { FORM_ITEM_ASNWER_TYPE } from '~/util/domainutils';
import { FormClientResale } from '../../Items/FormClientResale';
import {
  Container, ViewerContainer, Viewer, ViewerContainerItem,
  ViewerSoftwareModule, ViewerClientResale, ViewerImage, ViewerTitle,
  ViewerAnswer, ViewerTitle1, ViewerTitle2, ViewerDescription, ViewerOptions,
  ViewerOptionsContainer, ViewerOption, ViewerOther, ViewerOptionOther,
  ViewerOptionContainer, ViewerAnswerContainer, ViewerDescriptionContainer,
} from './styles';

function FormViewer({ history, location }) {
  const [formViewer, setFormViewer] = useState(null);

  useEffect(() => {
    if (!location.state) {
      setFormViewer(null);
      history.push(location.state.pathReferenceToBack);
    } else {
      setFormViewer(location.state.formViewer);
    }
  }, [history, location.state]);

  function handleViewerClientResale(item) {
    const { clientId, resaleId } = item.itemData.itemContent;

    return (
      <ViewerClientResale>
        <ViewerSoftwareModule>
          <span><b>Software:</b> {formViewer.softwareName}</span>
          <span><b>Módulo:</b> {formViewer.moduleName}</span>
        </ViewerSoftwareModule>
        <FormClientResale
          clientId={clientId}
          resaleId={resaleId}
          showData={true}
          showDefaultData={true}
          withBorder={true}
        />
      </ViewerClientResale>
    );
  }

  function handleViewerImage(item, index) {
    const { src, align } = item.itemData.itemContent;

    return (
      <ViewerImage justifyContent={align}>
        <img src={src} alt={`posição ${index}`} />
      </ViewerImage>
    );
  }

  function handleViewerTitle(item) {
    const { statement, description, required } = item.itemData;

    let formatDescription = [];

    if (description) {
      formatDescription = description.split('\n');
    }

    return (
      <ViewerTitle>
        {required ? (
          <ViewerTitle1>
            {statement && (
              <h1>{statement.toUpperCase()}</h1>
            )}
          </ViewerTitle1>
        ) : (
          <ViewerTitle2>
            {statement && (
              <b><u>{statement.toUpperCase()}</u></b>
            )}
          </ViewerTitle2>
        )}
        {formatDescription.map((description, index) => (
          <ViewerDescription key={index}>
            {description}
          </ViewerDescription>
        ))}
      </ViewerTitle>
    );
  }

  function handleViewerAnswer(item, index) {
    const {
      statement,
      description,
      required,
      itemTypeId,
      itemContent,
    } = item.itemData;

    const optionOther = [];
    const optionWithoutOther = [];

    if (itemContent) {
      itemContent.forEach((option) => {
        if (!option.other)
          optionWithoutOther.push(option);
        else
          optionOther.push(option);
      });
    }

    const radio = { 3: 'radio' };
    const checkbox = { 4: 'checkbox' };

    const HELPERS_TYPE = {
      ...radio,
      ...checkbox,
    };

    return (
      <ViewerAnswerContainer>
        <label htmlFor={`title-${index}`}>
          <ViewerAnswer>
            {statement}{required && '  *'}
          </ViewerAnswer>
        </label>
        {description && (
          <ViewerDescriptionContainer>
            <ViewerDescription>
              {description}
            </ViewerDescription>
          </ViewerDescriptionContainer>
        )}
        {itemTypeId === FORM_ITEM_ASNWER_TYPE.SHORT_ANSWER.id && (
          <input
            id={`input-${index}`}
            type='text'
          />
        )}
        {itemTypeId === FORM_ITEM_ASNWER_TYPE.PARAGRAPH.id && (
          <textarea
            id={`input-${index}`}
            type='text'
          />
        )}
        {(itemTypeId === FORM_ITEM_ASNWER_TYPE.MULTIPLE_CHOICE.id
          || itemTypeId === FORM_ITEM_ASNWER_TYPE.SELECTION_BOX.id)
          && (
            <ViewerOptions>
              {optionWithoutOther && (
                <ViewerOptionsContainer>
                  {optionWithoutOther.map((option, index) => (
                    <ViewerOption key={option.id}>
                      {!option.other && (
                        <ViewerOptionContainer>
                          <input
                            id={'input-type'}
                            className={'viewer-option'}
                            name={'input-type'}
                            type={HELPERS_TYPE[itemTypeId]}
                            value={index}
                          />
                          <label htmlFor={`input-${option.itemData.label}`}>
                            {option.itemData.value}
                          </label>
                        </ViewerOptionContainer>
                      )}
                    </ViewerOption>
                  ))}
                </ViewerOptionsContainer>
              )}
              {optionOther[0] && (
                <ViewerOther>
                  <ViewerOptionOther>
                    <input
                      id={'input-type'}
                      name={'input-type'}
                      className={'viewer-option'}
                      type={HELPERS_TYPE[itemTypeId]}
                      value={optionWithoutOther.length}
                    />
                    <label htmlFor={`input-${optionOther[0].itemData.label}`}>
                      {'Outro:'}
                    </label>
                  </ViewerOptionOther>
                  <input
                    id={'input-type-other'}
                    type={'text'}
                    disabled={true}
                  />
                </ViewerOther>
              )}
            </ViewerOptions>
          )}
      </ViewerAnswerContainer>
    );
  }

  function handleBackForm() {
    setFormViewer(null);
    return history.push(location.state.pathReferenceToBack, {
      formViewer, isFirstRefreshToForm: false,
    });
  }

  const getBreadcrumbsPathReference = () => {
    return (location.state.pathReferenceToBack || '').includes('add')
      ? 'Novo Formulário' : 'Editar Formulário';
  };

  return (
    <Viewer>
      <SidebarPageHeader
        mainMenu="Formulário"
        pageName="Visualização"
        breadcrumbs={[{
          label: 'Cadastro',
          onClick: () => (() => {
            setFormViewer(null);
            return history.push(formPaths.formRegister);
          }),
        }, {
          label: getBreadcrumbsPathReference(),
          onClick: () => handleBackForm,
        }]}
        buttonBackHandler={handleBackForm}
        buttonBackMain
        openButtonSelect
      />
      <Container>
        <ViewerContainer>
          <span className='title-required'>
            {'Campos com (*) são obrigatórios'}
          </span>
          {formViewer && (
            formViewer.contentItemsForm.map((item, index) => (
              <ViewerContainerItem key={item.id}>
                {item.itemTag === FORM_ITEM_ASNWER_TYPE.CLIENT_RESALE.tag && (
                  handleViewerClientResale(item)
                )}
                {item.itemTag === FORM_ITEM_ASNWER_TYPE.IMAGE.tag && (
                  handleViewerImage(item, index)
                )}
                {item.itemTag === FORM_ITEM_ASNWER_TYPE.TITLE.tag && (
                  handleViewerTitle(item)
                )}
                {item.itemTag === FORM_ITEM_ASNWER_TYPE.ANSWER.tag && (
                  handleViewerAnswer(item, index)
                )}
              </ViewerContainerItem>
            ))
          )}
        </ViewerContainer>
      </Container>
    </Viewer>
  );
}

FormViewer.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      formViewer: PropTypes.shape({}),
      pathReferenceToBack: PropTypes.string,
      handleBackRegister: PropTypes.func,
    }),
  }),
};

export default FormViewer;
