import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

function Stepper({ customHeader, stepsDataObjects }) {
  const handleIsActive = (step) => {
    return step.handleIsStep
      ? 'stepper-item-inner-active'
      : step.handleEnabledStep
        ? 'stepper-item-inner-completed'
        : 'stepper-item-inner-future';
  };

  const handleTitleIsActive = (step) => {
    return step.handleIsStep && 'stepper-title-active';
  };

  const onClick = (step) => {
    if (step.handleEnabledStep) { step.handleClick(); }
  };

  const handleOnMouseDownStep = (step) => {
    if (step.handleMouseDown) { step.handleMouseDown(); }
  };

  return (
    <S.StepperContent>
      {customHeader && customHeader}
      <div className='stepper-container'>
        {stepsDataObjects.map((step, index) => (
          <Fragment key={index}>
            {step.active && (
              <Fragment>
                <div
                  onMouseDown={() => handleOnMouseDownStep(step)}
                  onClick={() => onClick(step)}
                  className="stepper-item"
                >
                  <div className={`stepper-item-inner ${handleIsActive(step)}`}>
                    {step.number}
                  </div>
                  <span className={`stepper-title ${handleTitleIsActive(step)}`}>
                    {step.title}
                  </span>
                </div>
                {step.showDivider && (<div className="stepper-item-outer" />)}
              </Fragment>
            )}
          </Fragment>
        ))}
      </div>
    </S.StepperContent>
  );
}

Stepper.propTypes = {
  customHeader: PropTypes.element,
  stepsDataObjects: PropTypes.array.isRequired,
};

export { Stepper };
