/* eslint-disable react/prop-types */
import React from 'react';
import { formatPhone } from '~/util/stringutils';

import { Container } from '../styles';

export default function Client({ client }) {
  return (
    <Container>
      {client ? (
        <>
          <div>
            <span>Código:</span>
            <span>{client.id || ''}</span>
          </div>
          <div>
            <span>Ramo:</span>
            <span>
              {client.ramoAtividade && client.ramoAtividade.nome}
            </span>
          </div>
          <div>
            <span>Razão Social:</span>
            <span>{client.razaoSocial}</span>
          </div>
          <div>
            <span>E-mail:</span>
            <span>{client.contato && client.contato.email}</span>
          </div>
          <div>
            <span>Nome Fantasia:</span>
            <span>{client.nomeFantasia}</span>
          </div>
          <div>
            <span>Endereço:</span>
            <span>
              {client.endereco && client.endereco.endereco}
            </span>
          </div>
          <div>
            <span>CPF/CNPJ:</span>
            <span>{client.cpfCnpj}</span>
          </div>
          <div>
            <span>Cep:</span>
            <span>
              {client.endereco && client.endereco.cep}
            </span>
          </div>
          <div>
            <span>IE:</span>
            <span>{client.inscricaoEstadual}</span>
          </div>
          <div>
            <span>Bairro:</span>
            <span>
              {client.endereco && client.endereco.bairro}
            </span>
          </div>
          <div>
            <span>Telefone:</span>
            <span>{client.contato && formatPhone(client.contato.telefone1)}</span>
          </div>
          <div>
            <span>Cidade:</span>
            <span>
              {client.endereco && client.endereco.cidade}
            </span>
          </div>
          <div>
            <span>Celular:</span>
            <span>{client.contato && formatPhone(client.contato.telefone2)}</span>
          </div>
          <div>
            <span>Estado:</span>
            <span>
              {client.endereco && client.endereco.estado}
            </span>
          </div>
          <div>
            <span>Responsável:</span>
            <span>
              {client.responsavel && client.responsavel}
            </span>
          </div>
        </>
      ) : (
        <Container>
          <span>Cliente não encontrado na base de dados</span>
        </Container>
      )}
    </Container>
  );
}
