/* eslint-disable react/prop-types */

import React, { useState, useMemo } from 'react';

import Field from '~/components/Field';
import FieldCEP from '~/components/FieldCEP';
import FieldCNPJ from '~/components/FieldCNPJ';
import FieldCPF from '~/components/FieldCPF';
import FieldPhone from '~/components/FieldPhone';

import { toast } from 'react-toastify';
import Select from '~/components/Select';

import { useSelector } from 'react-redux';
import permissions from '~/util/permissions';

import { AutoCompleteOn } from '~/components/AutoCompleteOn';
import PersonClientService from '~/services/person-client-service';
import PersonResaleService from '~/services/person-resale-service';
import { showMessageError } from '~/util/errorutils';

import { PERSON_STATUS_ENUM, UNIDADES_FEDERATIVAS } from '~/util/domainutils';
import {
  inputNumber,
  inputToLowerCase,
  unFormatCpfCnpj,
  onlyText,
  formatCpfCnpj,
} from '~/util/stringutils';

function FieldsClient(props) {
  const {
    lineBusinesses,
    useFormObjects: {
      register,
      control,
      watch,
      errors,
      clearErrors,
      setValue,
    },
    sameClient,
    setLoading,
    resaleDefault,
    showPermissionStatusClient,
    noVerifyClientInBase,
    handleCheckSameCpfCnpj,
  } = props;

  const [isRequested, setIsRequested] = useState(false);
  const [resales, setResales] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [handleTimeout, setHandleTimeout] = useState(null);
  const [
    inputResaleValue,
    setInputValue,
  ] = useState(handleResaleDefault(resaleDefault));

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanChangeAttendance = useMemo(
    () => userPermissions.includes(
      permissions['edit_mudanca-atendimento-revenda']
    ),
    [userPermissions]
  );

  const userCanBlockUser = useMemo(
    () => userPermissions.includes(permissions.block_user),
    [userPermissions]
  );

  function handleResaleDefault(resale) {
    if (resale) {
      setValue('revendaId', resale.id);
      return renderSuggestion(resale);
    }

    return '';
  }

  function handleCnpjChange(value) {
    clearErrors('cpfCnpj');

    const valueUnFormated = unFormatCpfCnpj(value);

    if (!noVerifyClientInBase) {
      let isRequestNow = isRequested;

      if (valueUnFormated.length === 14) {
        isRequestNow = true;
      }

      if (valueUnFormated.length === 14 && isRequestNow) {
        handleVerifyClient(valueUnFormated);
        setIsRequested(false);

        return setValue('cpfCnpj', value);
      }

      if (!isRequested && valueUnFormated.length === 13) {
        setIsRequested(true);

        return setValue('cpfCnpj', value);
      }

      if (valueUnFormated.length < 14) {
        return setValue('cpfCnpj', value);
      }
    } else {
      if (handleCheckSameCpfCnpj && sameClient && (valueUnFormated.length === 14)) {
        handleCheckSameCpfCnpj(sameClient, { cpfCnpj: valueUnFormated });
      }

      return setValue('cpfCnpj', value);
    }
  }

  function handleCpfChange(value) {
    clearErrors('cpfCnpj');

    const valueUnFormated = unFormatCpfCnpj(value);

    if (!noVerifyClientInBase) {
      let isRequestNow = isRequested;

      if (valueUnFormated.length === 11) {
        isRequestNow = true;
      }

      if (valueUnFormated.length === 11 && isRequestNow) {
        handleVerifyClient(valueUnFormated);
        setIsRequested(false);

        return setValue('cpfCnpj', value);
      }

      if (!isRequested && valueUnFormated.length === 10) {
        setIsRequested(true);

        return setValue('cpfCnpj', value);
      }

      if (valueUnFormated.length < 11) {
        return setValue('cpfCnpj', value);
      }

    } else {
      if (handleCheckSameCpfCnpj && sameClient && (valueUnFormated.length === 11)) {
        handleCheckSameCpfCnpj(sameClient, { cpfCnpj: valueUnFormated });
      }

      return setValue('cpfCnpj', value);
    }
  }

  async function handleVerifyClient(cpfCnpj) {
    setLoading(true);
    const asyncSetTimeOut = async () => {
      try {
        const { data } = await PersonClientService
          .verifyPersonClient(cpfCnpj);

        if (data.resultado.length) {
          const cliente = data.resultado[0];

          if (cliente.razaoSocialCliente) {
            setValue('razaoSocial', cliente.razaoSocialCliente);
          }

          if (cliente.nomeFantasiaCliente) {
            setValue('nomeFantasia', cliente.nomeFantasiaCliente);
          }

          toast.success('Cliente já cadastrado no ERP da Casa Magalhães.');
        } else {
          toast.success('Cliente ainda não cadastrado no ERP da Casa Magalhães.');
        }
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    };

    asyncSetTimeOut();
  }

  function handleSelectedStatusOutline(field, value) {
    return watch(field) === PERSON_STATUS_ENUM[value].value
      ? 'checkradio_outline' : '';
  }

  const handleSetStatusCliente = () => {
    return setValue('statusChave', PERSON_STATUS_ENUM.BLOCKED.value);
  };

  const handleStatusIsBlocked = () => {
    return watch('status') === PERSON_STATUS_ENUM.BLOCKED.value;
  };

  function handleVerifyRevenda(resale) {
    setInputValue(renderSuggestion(resale));
    setValue('revendaId', resale.id);
  }

  async function getResales(value) {
    try {
      const data = await PersonResaleService.getPersonResaleAutoComplete({
        cpfCnpj: unFormatCpfCnpj(value.trim().toLowerCase()) || null,
        nome: onlyText(value.trim().toLowerCase()) || null,
        offset: 0,
        limit: 0,
      });

      return data.resultado;
    } catch (error) {
      showMessageError(error);
      return [];
    }
  }

  async function getResalesChange(event) {
    const {
      target: { value: valueToFind }
    } = event;

    if (valueToFind === '') {
      setResales([]);
      setShowSuggestions(false);
      setValue('revendaId', '');
    } else {
      clearTimeout(handleTimeout);

      const timer = setTimeout(() => {
        const asyncSetTimeOut = async () => {
          const resales = await getResales(valueToFind);
          if (resales.length) {
            setResales(resales);
            setShowSuggestions(true);
          } else {
            setResales([]);
            setShowSuggestions(true);
          }
        };

        asyncSetTimeOut();
      }, 500); // 0.5 segundos

      setHandleTimeout(timer);
    }

    setInputValue(valueToFind);
  }

  function getSuggestionLabel(suggestion) {
    if (suggestion) {
      return renderSuggestion(suggestion);
    } else {
      return '';
    }
  }

  function getSuggestionKey(suggestion) {
    if (suggestion) {
      return suggestion.id;
    } else {
      return '';
    }
  }

  function renderSuggestion(suggestion) {
    return `${formatCpfCnpj(suggestion.cpfCnpj)} - ${suggestion.nomeFantasia}`;
  }

  function handleOnClick(suggestion) {
    const [resaleSelected] = resales.filter(
      suggItem => suggItem.id === suggestion.id
    );

    handleVerifyRevenda(resaleSelected);
    setShowSuggestions(false);
  }

  function renderCardPermissions(permission) {
    if (!showPermissionStatusClient) return 'none';
    return showInfoCard(permission);
  }

  const showInfoCard = (permission) => {
    return permission ? 'block' : 'none';
  };

  return (
    <div className="defaultForm">
      <div
        className="infoCard noMarginTop"
        style={{ display: renderCardPermissions(userCanBlockUser) }}
      >
        <span id="title">Permissões</span>
        <div className="rowContainer">
          <div className="inputHolder addMarginTop">
            <span id="subtitle">Status do Cliente *</span>
            <div className="rowContainer">
              <div className='radioHolder'>
                <div className='inputBoxHolder'>
                  <Field
                    type="radio"
                    name="status"
                    value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}
                    control={control}
                  />
                  <span className={
                    `checkradio ${handleSelectedStatusOutline(
                      'status', PERSON_STATUS_ENUM.NOT_BLOCKED.key
                    )}`
                  }>
                    {watch('status') === PERSON_STATUS_ENUM.NOT_BLOCKED.value
                      && (
                        <div className="markRadioHolder">
                          <div className="checkradio_stem" />
                        </div>
                      )}
                  </span>
                </div>
                <span id='radioLabel'>
                  {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
                </span>
              </div>
              <div className='radioHolder'>
                <div className='inputBoxHolder'>
                  <Field
                    type="radio"
                    name="status"
                    value={PERSON_STATUS_ENUM.BLOCKED.value}
                    control={control}
                    onClick={handleSetStatusCliente}
                  />
                  <span className={
                    `checkradio ${handleSelectedStatusOutline(
                      'status', PERSON_STATUS_ENUM.BLOCKED.key
                    )}`
                  }>
                    {watch('status') === PERSON_STATUS_ENUM.BLOCKED.value
                      && (
                        <div className="markRadioHolder">
                          <div className="checkradio_stem" />
                        </div>
                      )}
                  </span>
                </div>
                <span id='radioLabel'>
                  {PERSON_STATUS_ENUM.BLOCKED.label}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`infoCard ${!showPermissionStatusClient && 'noMarginTop'}`}>
        <span id="title">Geração de Chave</span>
        <div className="rowContainer">
          <div className="inputHolder addMarginTop marginRightX4">
            <span id="subtitle">Status Geração de Chave *</span>
            <div className="rowContainer">
              <div className='radioHolder'>
                <div className='inputBoxHolder'>
                  <Field
                    type="radio"
                    name="statusChave"
                    value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}
                    control={control}
                    disabled={handleStatusIsBlocked()}
                    onClick={() => {
                      if (!handleStatusIsBlocked()) {
                        setValue(
                          'statusChave',
                          PERSON_STATUS_ENUM.NOT_BLOCKED.value,
                        );
                      }
                    }}
                  />
                  <span className={
                    `checkradio ${handleSelectedStatusOutline(
                      'statusChave', PERSON_STATUS_ENUM.NOT_BLOCKED.key
                    )}`
                  }>
                    {watch('statusChave') === PERSON_STATUS_ENUM.NOT_BLOCKED.value
                      && (
                        <div className="markRadioHolder">
                          <div className="checkradio_stem" />
                        </div>
                      )}
                  </span>
                </div>
                <span id='radioLabel'>
                  {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
                </span>
              </div>
              <div className='radioHolder'>
                <div className='inputBoxHolder'>
                  <Field
                    type="radio"
                    name="statusChave"
                    value={PERSON_STATUS_ENUM.BLOCKED.value}
                    control={control}
                    disabled={handleStatusIsBlocked()}
                    onClick={() => {
                      if (!handleStatusIsBlocked()) {
                        setValue(
                          'statusChave',
                          PERSON_STATUS_ENUM.BLOCKED.value,
                        );
                      }
                    }}
                  />
                  <span className={
                    `checkradio ${handleSelectedStatusOutline(
                      'statusChave', PERSON_STATUS_ENUM.BLOCKED.key
                    )}`
                  }>
                    {watch('statusChave') === PERSON_STATUS_ENUM.BLOCKED.value
                      && (
                        <div className="markRadioHolder">
                          <div className="checkradio_stem" />
                        </div>
                      )}
                  </span>
                </div>
                <span id='radioLabel'>
                  {PERSON_STATUS_ENUM.BLOCKED.label}
                </span>
              </div>
            </div>
          </div>
          <div className="inputHolder addMarginTop">
            <span id="subtitle">Prazo de Renovação de Chave</span>
            <div className="rowContainer rowContainerCenter">
              <div className='inputHolder noMarginRight inputHolderSmall'>
                <Field
                  type="text"
                  name="prazoRenovacaoChave"
                  labelHorizontal="dias"
                  className="widthPrazoRenovacaoChave"
                  control={control}
                  errors={errors}
                  onChange={(e) =>
                    setValue(
                      'prazoRenovacaoChave',
                      inputNumber(e.target.value)
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="infoCard">
        <span id="title">Dados Gerais</span>
        <div className="rowContainer">
          <div className="inputHolder">
            <Select
              name="tipoPessoa"
              label="Tipo de Pessoa *"
              control={control}
              errors={errors}
            >
              <option value="FÍSICA">FÍSICA</option>
              <option value="JURÍDICA">JURÍDICA</option>
            </Select>
          </div>
          {watch('tipoPessoa') === 'FÍSICA' ? (
            <div className="inputHolder defaultFlex">
              <FieldCPF
                control={control}
                name="cpfCnpj"
                label="CPF *"
                onChange={(e) => handleCpfChange(e.target.value)}
                errors={errors}
              />
            </div>
          ) : (
            <div className="inputHolder defaultFlex">
              <FieldCNPJ
                control={control}
                name="cpfCnpj"
                label="CNPJ *"
                errors={errors}
                onChange={(e) => handleCnpjChange(e.target.value)}
              />
            </div>
          )}
          <div className="inputHolder defaultFlex noMarginRight">
            <Field
              type="text"
              name="razaoSocial"
              placeholder="Razão Social"
              label="Razão Social *"
              control={control}
              errors={errors}
            />
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <Field
              type="text"
              name="nomeFantasia"
              placeholder="Nome Fantasia"
              label="Nome Fantasia *"
              control={control}
              errors={errors}
            />
          </div>
          <div className={`
            inputHolder
            defaultFlex
            ${watch('tipoPessoa') === 'FÍSICA' && 'noMarginRight'}
          `}>
            <Field
              type="text"
              name="inscricaoEstadual"
              placeholder={watch('tipoPessoa') === 'FÍSICA'
                ? 'RG'
                : 'Inscrição Estadual'
              }
              label={watch('tipoPessoa') === 'FÍSICA'
                ? 'RG *'
                : 'Inscrição Estadual *'
              }
              control={control}
              errors={errors}
            />
          </div>
          {watch('tipoPessoa') === 'JURÍDICA' && (
            <div className="inputHolder noMarginRight">
              <Select
                name="optanteSimples"
                label="Optante do SIMPLES"
                control={control}
                errors={errors}
              >
                <option value={false}>Não</option>
                <option value>Sim</option>
              </Select>
            </div>
          )}
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <Select
              name="ramoAtividadeId"
              label="Ramo de Atividade *"
              control={control}
              errors={errors}
            >
              <option value="" disabled>
                Selecione
              </option>
              {(lineBusinesses || []).map(ramo => (
                <option key={ramo.id} value={ramo.id}>
                  {ramo.nome}
                </option>
              ))}
            </Select>
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <Field
              type="text"
              name="responsavel"
              placeholder="Responsável"
              label="Responsável *"
              control={control}
              errors={errors}
            />
          </div>
          <div />
        </div>
      </div>

      <div className="infoCard" style={{ display: showInfoCard(userCanChangeAttendance) }}>
        <span id="title">Revenda</span>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex noMarginRight">
            <span>De qual revenda?</span>
            <span id="helpText">
              Ao digitar, o campo irá auto completar. A busca da revenda
              poderá ser feita pelo CNPJ ou Nome Fantasia.
            </span>
            <AutoCompleteOn
              {...register('revendaId')}
              inputDataValue={inputResaleValue}
              suggestions={resales}
              isShowSuggestions={showSuggestions}
              handleDataChange={getResalesChange}
              handleGetSuggestionKey={getSuggestionKey}
              handleGetSuggestionLabel={getSuggestionLabel}
              handleOnClick={handleOnClick}
              isDisabled={!userCanChangeAttendance}
            />
          </div>
        </div>
      </div>

      <div className="infoCard">
        <span id="title">Endereço</span>
        <div className="rowContainer">
          <div className="inputHolder">
            <Select
              name="estado"
              label="Estado *"
              control={control}
              errors={errors}
            >
              <option value="" disabled>
                Selecione
              </option>
              {UNIDADES_FEDERATIVAS.map(uf => (
                <option key={uf.sigla} value={uf.nome}>
                  {uf.nome}
                </option>
              ))}
            </Select>
          </div>
          <div className="inputHolder">
            <Field
              type="text"
              name="cidade"
              placeholder="Cidade"
              label="Cidade *"
              control={control}
              errors={errors}
            />
          </div>
          <div className="inputHolder">
            <FieldCEP
              control={control}
              name="cep"
              label="CEP *"
              errors={errors}
            />
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <Field
              type="text"
              name="bairro"
              placeholder="Bairro"
              label="Bairro *"
              control={control}
              errors={errors}
            />
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <Field
              type="text"
              name="endereco"
              label="Endereço Completo *"
              control={control}
              errors={errors}
            />
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <Field
              type="text"
              name="complemento"
              label="Complemento"
              control={control}
              errors={errors}
            />
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex noMarginRight">
            <Field
              type="text"
              name="pontoReferencia"
              label="Ponto de Referência"
              control={control}
              errors={errors}
            />
          </div>
        </div>
      </div>
      <div className="infoCard">
        <span id="title">Contato</span>
        <div className="rowContainer">
          <div className="inputHolder">
            <FieldPhone
              control={control}
              name="telefone1"
              label="Telefone(1) *"
              errors={errors}
            />
          </div>
          <div className="inputHolder">
            <FieldPhone
              control={control}
              name="telefone2"
              label="Telefone(2)"
              errors={errors}
            />
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <Field
              type="email"
              name="email"
              label="E-mail *"
              placeholder="email@email.com"
              control={control}
              errors={errors}
              onChange={(e) =>
                setValue(
                  'email',
                  inputToLowerCase(e.target.value),
                  { shouldValidate: true }
                )
              }
            />
          </div>
        </div>
      </div>

      <div className="infoCard">
        <span id="title">Outros</span>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex noMarginRight">
            <Field
              readOnly
              disabled
              type="date"
              name="dataCadastro"
              label="Data de Cadastro"
              control={control}
              errors={errors}
            />
          </div>
        </div>
      </div>

      <div className="infoCard">
        <span id="title">Opções</span>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <Select
              name="microempresa"
              label="Microempresa"
              control={control}
              errors={errors}
            >
              <option value={false}>Não</option>
              <option value={true}>Sim</option>
            </Select>
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <Select
              name="retemICMS"
              label="Retém ICMS"
              control={control}
              errors={errors}
            >
              <option value={false}>Não</option>
              <option value={true}>Sim</option>
            </Select>
          </div>
        </div>
      </div>
    </div>
  );
}

export { FieldsClient };
