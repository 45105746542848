import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import { PERSON_STATUS_ENUM } from '~/util/domainutils';
import { USER_TYPE } from '~/util/domainutils';
import { InputPerson } from '~/components/InputPerson';
import '../styles.scss';

function AdvancedSearch({
  title1,
  inputPessoa1Value,
  handlePessoa1Change,
  inputTitle1,
  inputTitle2,
  inputPessoa2Value,
  handlePessoa2Change,
  userCanViewColumnRevenda,
  handleSubmit,
  codeValue,
  handleCodeChange,
  noFieldStatus,
  statusValue,
  handleStatusChange,
  noFieldStatusChave,
  statusChaveValue,
  handleStatusChaveChange,
  userType,
}) {
  function handleInputs() {
    return (!noFieldStatus && !noFieldStatusChave);
  }

  return (
    <>
      <div>
        <label id="searchText">{title1}</label>
        <div className="rowContainer">
          <div id="code" className="inputHolder">
            <span>Código</span>
            <input
              type="text"
              name="code"
              value={codeValue}
              onChange={handleCodeChange}
            />
          </div>
          <InputPerson
            title1={inputTitle1}
            inputPerson1={inputPessoa1Value}
            handlePerson1Change={handlePessoa1Change}
            title2={inputTitle2}
            inputPerson2={inputPessoa2Value}
            handlePerson2Change={handlePessoa2Change}
            userCanViewPerson2={userCanViewColumnRevenda}
            noMarginRight={userType !== USER_TYPE.COLABORADOR}
            userType={userType}
          />
          {!handleInputs() && (
            <button
              className="buttonBaseOnly"
              id="baseButtonIcon"
              onClick={handleSubmit}
            >
              <FaSearch id="baseNameIcon" />
              <span>BUSCAR</span>
            </button>
          )}
        </div>
      </div>
      {handleInputs() && (
        <div className="rowContainer rowJustifyContent">
          <div className="rowContainer defaultFlex">
            {!noFieldStatus && (
              <div className="inputHolder">
                <span>Status do Cliente</span>
                <select
                  name="status"
                  value={statusValue}
                  onChange={handleStatusChange}
                >
                  <option value={''}>Selecione</option>
                  <option value={PERSON_STATUS_ENUM.BLOCKED.value}>
                    {PERSON_STATUS_ENUM.BLOCKED.label}
                  </option>
                  <option value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}>
                    {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
                  </option>
                </select>
              </div>
            )}
            {!noFieldStatusChave && (
              <div className="inputHolder">
                <span>Status Geração de Chave</span>
                <select
                  name="statusChave"
                  value={statusChaveValue}
                  onChange={handleStatusChaveChange}
                >
                  <option value={''}>Selecione</option>
                  <option value={PERSON_STATUS_ENUM.BLOCKED.value}>
                    {PERSON_STATUS_ENUM.BLOCKED.label}
                  </option>
                  <option value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}>
                    {PERSON_STATUS_ENUM.NOT_BLOCKED.label2}
                  </option>
                </select>
              </div>
            )}
          </div>
          <button
            className="buttonBaseOnly"
            id="baseButtonIcon"
            onClick={handleSubmit}
          >
            <FaSearch id="baseNameIcon" />
            <span>BUSCAR</span>
          </button>
        </div>
      )}
    </>
  );
}

AdvancedSearch.propTypes = {
  handleSubmit: PropTypes.func,
  statusValue: PropTypes.string,
  handleStatusChange: PropTypes.func,
  statusChaveValue: PropTypes.string,
  handleStatusChaveChange: PropTypes.func,
  codeValue: PropTypes.string,
  handleCodeChange: PropTypes.func,
  title1: PropTypes.string,
  inputPessoa1Value: PropTypes.string,
  handlePessoa1Change: PropTypes.func,
  inputTitle1: PropTypes.string,
  inputPessoa2Value: PropTypes.string,
  handlePessoa2Change: PropTypes.func,
  inputTitle2: PropTypes.string,
  userType: PropTypes.string,
  noFieldStatus: PropTypes.bool,
  noFieldStatusChave: PropTypes.bool,
  userCanViewColumnRevenda: PropTypes.bool,
};

export default AdvancedSearch;
