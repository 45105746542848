import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useCallback } from 'react';
import { ReasonSolicitationService } from '~/services/commercial/reasonsolicitation';
import { LineBusinessService } from '~/services/person/linebusiness';
import { SolicitationService } from '~/services/commercial/solicitation';
import { handleMoveElement } from '~/util/scrollutils';
import { showMessageError } from '~/util/errorutils';
import { toast } from 'react-toastify';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import schema, { defaultValues } from '~/schemas/client-schema';
import { PERSON_STATUS_ENUM } from '~/util/domainutils';
import { unFormatCpfCnpj, validePhone, unFormatPunctuation } from '~/util/stringutils';
import { isObjectEmpty, countObject } from '~/util/objectutils';
import { SUPORT_FILES } from '~/util/fileutils';
import { commercialPaths } from '~/routes/paths';

const SolicitacaoContext = createContext(null);

function SolicitacaoProvider({ children, history }) {
  const [activeStep, setActiveStep] = useState(0);
  const [menuActive, setMenuActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(null);
  const [clienteId, setClienteId] = useState(null);
  const [inputClienteValue, setInputClienteValue] = useState('');
  const [newClient, setNewClient] = useState(null);
  const [newClienteId, setNewClienteId] = useState(null);
  const [newInputClienteValue, setNewInputClienteValue] = useState('');
  const {
    register,
    control,
    watch,
    reset,
    getValues,
    handleSubmit,
    setError,
    errors,
    clearErrors,
    setValue,
  } = useForm({ defaultValues, resolver: yupResolver(schema) });
  const [createNewClient, setCreateNewClient] = useState(null);
  const [lineBusinesses, setLineBusinesses] = useState([]);
  const [reasonSolicitations, setReasonSolicitations] = useState([]);
  const [reasonSelected, setReasonSelected] = useState(null);
  const [selectTipoSolicitacao, setSelectTipoSolicitacao] = useState('');
  const [inputAssunto, setInputAssunto] = useState('');
  const [textareaTexto, setTextareaTexto] = useState('');
  const [anexos, setAnexos] = useState([]);
  const [confirm, setConfirm] = useState(false);

  /**
   *    EFFECTS INIT
   */

  const handleAsyncEffectAdd = useCallback(
    async () => {
      setLoading(true);
      try {
        const { resultado } = await ReasonSolicitationService
          .getReasonsSolicitation();

        if (resultado?.length) {
          const reasonSolicitations = resultado
            .filter((reason) => reason.ativo);

          setReasonSolicitations([...reasonSolicitations]);
        } else {
          setReasonSolicitations([]);
        }
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    }, [],
  );

  /**
   *    VALIDAÇÕES
   */

  function validateClient() {
    const { BLOCKED } = PERSON_STATUS_ENUM;

    if (clienteId === null) {
      toast('Escolha um cliente é obrigatório!', { type: toast.TYPE.ERROR });
      return false;
    }

    if (client.status === BLOCKED.value) {
      toast('Cliente bloqueado!', { type: toast.TYPE.ERROR });
      return false;
    }

    return true;
  }

  function handleValidateSelectClient(isActive = false) {
    if (!validateClient()) {
      return false;
    }

    setClient(client);
    if (isActive) {
      setActiveStep(1);
    }

    return true;
  }

  function validateSolicitation() {
    const messageValidateError = 'Os Campos com (*) são obrigatórios';

    if (!selectTipoSolicitacao) {
      toast(messageValidateError, { type: toast.TYPE.ERROR });
      return false;
    }

    if (!inputAssunto || !inputAssunto.trim().length) {
      toast(messageValidateError, { type: toast.TYPE.ERROR });
      return false;
    }

    if (!textareaTexto || !textareaTexto.trim().length) {
      toast(messageValidateError, { type: toast.TYPE.ERROR });
      return false;
    }

    return true;
  }

  function handleValidateSelectSolicitation(isActive = false) {
    if (!handleValidateSelectClient()) return;
    if (!validateSolicitation()) {
      return false;
    }

    if (isActive) {
      setActiveStep(2);
    }

    return true;
  }

  const handleGetDataCreateNewClient = useCallback(
    async () => {
      if (!lineBusinesses.length) {
        setLoading(true);
        try {
          const { resultado } = await LineBusinessService.getLineBusiness('');
          if (resultado) {
            setLineBusinesses(resultado);
          } else {
            setLineBusinesses([]);
          }
        } catch (error) {
          showMessageError(error);
        } finally {
          setLoading(false);
        }
      }
    }, [lineBusinesses.length],
  );

  const validateFormCreateNew = useCallback((actions, success) => {
    const { formData, clientSelected } = actions;

    handleValidateCpfCnpj(clientSelected, formData);

    if (formData['telefone1'] && !validePhone(formData['telefone1'])) {
      return setError('telefone1', {
        type: 'valueAsNumber',
        message: 'Digite um número válido',
        shouldFocus: true,
      });
    }

    if (formData['telefone2'] && !validePhone(formData['telefone2'])) {
      return setError('telefone2', {
        type: 'valueAsNumber',
        message: 'Digite um número válido',
        shouldFocus: true,
      });
    }

    setCreateNewClient(formData);
    return success(formData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGetDataCreateNewClient = useCallback(
    async (formData) => {
      validateFormCreateNew({
        formData, clientSelected: client,
      }, (formDataClient) => {
        if (formDataClient) {
          setActiveStep(3);
          reset(formDataClient);
        }
      });
    }, [client, reset, validateFormCreateNew],
  );

  async function handleValidateNewClient() {
    if (!reasonSelected?.existeCliente) return;
    if (!handleValidateSelectClient()) return;

    switch (menuActive) {
      case 0: {
        if (!newClient) {
          toast.error('Selecione um cliente existente');
          return;
        }

        const { BLOCKED } = PERSON_STATUS_ENUM;
        if (newClient.status === BLOCKED.value) {
          toast('Cliente bloqueado!', { type: toast.TYPE.ERROR });
          return false;
        }

        if (handleValidateCpfCnpj(client, newClient)) {
          setActiveStep(3);
        }

        break;
      }
      case 1: {
        await handleSubmit(
          async (form) => await onGetDataCreateNewClient(form),
          (err) => {
            if (err) { toast.error('Os Campos com (*) são obrigatórios'); }
          }
        )();
        setLoading(false);
        break;
      }
      default:
        toast.error('Erro ao validar novo cliente');
        break;
    }
  }

  const handleValidateCpfCnpj = useCallback((clientStep1, clientStep3) => {
    const msgErrSame = 'O cliente da etapa 3 não pode ser o mesmo da etapa 1';
    switch (menuActive) {
      case 0: {
        const clientCurrent = unFormatCpfCnpj((clientStep1?.cpfCnpj || ''));
        const clientCreateNew = unFormatCpfCnpj((clientStep3?.cpfCnpj || ''));

        if (clientCurrent === clientCreateNew) {
          toast.error(msgErrSame);
          return false;
        }

        return true;
      }
      case 1: {
        const clientCurrent = unFormatCpfCnpj((clientStep1?.cpfCnpj || ''));
        const clientCreateNew = unFormatCpfCnpj((clientStep3?.['cpfCnpj'] || ''));

        if (clientCurrent === clientCreateNew) {
          toast.error(msgErrSame);
          setError('cpfCnpj', {
            type: 'sameCpfCnpj',
            message: 'Digite outro cpf/cnpj',
            shouldFocus: true,
          });
          return false;
        }

        return true;
      }
      default:
        toast.error('Erro ao validar novo cliente');
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleValidateFormOnSubmit(showPermissionStatusClient, success) {
    if (!confirm) {
      toast.error('Confirmação de revisão da solcitação é obrigatório!');
      handleMoveElement('endTerm');
      return;
    }

    const formData = getDataNewClient();
    const data = formatDataNewClient(formData, showPermissionStatusClient);
    const solicitation = getSelectedSolicitation();

    if (solicitation.existeCliente && !data) {
      toast.error('Erro ao formatar novo cliente!');
      return;
    }

    const dataTemp = {
      inputCpfCnpj: client.cpfCnpj,
      inputAssunto,
      inputTextareaTexto: textareaTexto,
      inputTipoMotivoId: solicitation.id,
      tipoMotivoDescricao: solicitation.descricao,
      inputAnexos: anexos,
      inputNovoCliente: data,
    };

    success(dataTemp);
  }

  function handleGenerateFormData(inputData, success) {
    const formData = new FormData();

    formData.append('cpfCnpj', unFormatCpfCnpj((inputData.inputCpfCnpj || '').trim()));
    formData.append('tipoMotivoDescricao', inputData.tipoMotivoDescricao.trim());
    formData.append('tipoMotivoId', Number(inputData.inputTipoMotivoId));
    formData.append('assunto', (inputData.inputAssunto || '').trim());
    formData.append('texto', (inputData.inputTextareaTexto || '').trim());

    if (inputData.inputNovoCliente) {
      formData.append('extra', JSON.stringify({
        paraCriar: {
          clienteExistente: menuActive === 0 ? true : false,
          cliente: inputData.inputNovoCliente,
        }
      }));
    } else {
      formData.append('extra', null);
    }

    if (inputData.inputAnexos && inputData.inputAnexos.length) {
      for (let key = 0; key < inputData.inputAnexos.length; key += 1) {
        formData.append('anexos', inputData.inputAnexos[key]);
      }
    }

    success(formData);
  }

  /**
   *    INPUTS
   */

  const handleSelectTipoSolicitacaoChange = useCallback(
    (event) => {
      const { target: { value } } = event;

      setSelectTipoSolicitacao(value);

      const reason = reasonSolicitations
        .find((_) => _.id === parseInt(value));

      setReasonSelected(reason);
    }, [reasonSolicitations],
  );

  const handleInputAssuntoChange = useCallback((event) => {
    const { target: { value } } = event;
    setInputAssunto(value);
  }, []);

  const handleTextareaTextoChange = useCallback((event) => {
    const { target: { value } } = event;
    setTextareaTexto(value);
  }, []);

  const handleInputAnexosChange = useCallback((event) => {
    const { target: { files } } = event;

    if (![...Object.values((files || {}))].every((_) => {
      return SUPORT_FILES.ANEXOS.includes(_.type);
    })) {
      toast.error('Arquivos não suportados foram encontrados');
      return;
    }

    const attachments = [];

    for (const key in anexos) {
      if (typeof anexos[key] === 'object') {
        attachments.push(anexos[key]);
      }
    }

    for (const key in files) {
      if (typeof files[key] === 'object') {
        attachments.push(files[key]);
      }
    }

    if (attachments && attachments.length) {
      setAnexos(attachments);
    } else {
      setAnexos([]);
    }

    setTimeout(() => {
      handleMoveElement('attachmentsId');
    }, 200); // 2 milissegundos
  }, [anexos]);

  const handleInputConfirmChange = useCallback((event) => {
    const { target: { value } } = event;
    setConfirm(value !== 'true');
  }, []);

  const handleSaveCreateClientNew = useCallback(() => {
    if (getValues() === null && createNewClient === null) return;
    if (isObjectEmpty(getValues()) && isObjectEmpty(createNewClient)) return;

    let dataValue = (getValues() || createNewClient);

    let sameCount = countObject(dataValue) !== countObject(defaultValues);
    if (isObjectEmpty(dataValue) && sameCount) {
      dataValue = createNewClient;
    }

    reset(dataValue);
    setCreateNewClient(dataValue);
  }, [createNewClient, getValues, reset]);

  /**
   *    GLOBALS
   */

  const getSelectedSolicitation = useCallback(() => {
    return reasonSolicitations
      .find((_) => _.id === parseInt(selectTipoSolicitacao));
  }, [reasonSolicitations, selectTipoSolicitacao]);

  const getDataNewClient = useCallback(() => {
    switch (menuActive) {
      case 0:
        return newClient;
      case 1:
        return (createNewClient || getValues());
      default:
        return null;
    }
  }, [newClient, createNewClient, getValues, menuActive]);

  function formatDataNewClient(formData, showPermissionStatusClient) {
    if (reasonSelected?.existeCliente) {
      const dataTemp = {};

      if (showPermissionStatusClient) {
        dataTemp.status = formData['status'];
      } else {
        dataTemp.status = PERSON_STATUS_ENUM.NOT_BLOCKED.value;
      }

      if (!formData['revendaId'] || !formData['revendaId'].length) {
        dataTemp.revendaId = null;
      } else {
        dataTemp.revendaId = (formData['revendaId'] || null);
      }

      switch (menuActive) {
        case 0: {
          const getAddressValue = (key) => (formData.endereco[key] || '');
          const getContactValue = (key) => (formData.contato[key] || '');
          return {
            bairro: getAddressValue('bairro').trim(),
            cep: getAddressValue('cep').trim(),
            cidade: getAddressValue('cidade').trim(),
            complemento: getAddressValue('complemento').trim(),
            estado: getAddressValue('estado').trim(),
            endereco: getAddressValue('endereco').trim(),
            pontoReferencia: getAddressValue('pontoReferencia').trim(),
            razaoSocial: (formData.razaoSocial || '').trim(),
            nomeFantasia: (formData.nomeFantasia || '').trim(),
            tipoPessoa: formData.tipoPessoa,
            cpfCnpj: unFormatCpfCnpj(formData.cpfCnpj),
            responsavel: (formData.responsavel || '').trim(),
            inscricaoEstadual: (formData.inscricaoEstadual || '').trim(),
            dataCadastro: formData.dataCadastro,
            email: getContactValue('email'),
            telefone1: unFormatPunctuation(getContactValue('telefone1').trim()),
            telefone2: unFormatPunctuation(getContactValue('telefone2').trim()),
            microempresa: formData.microempresa,
            prazoRenovacaoChave: formData.prazoRenovacaoChave,
            ramoAtividadeId: formData.ramoAtividadeId,
            retemICMS: formData.retemICMS,
            optanteSimples: formData.optanteSimples,
            statusChave: formData.statusChave,
            status: dataTemp.status,
            revendaId: dataTemp.revendaId,
            cnae: '',
          };
        }
        case 1: {
          const data = {};

          data.bairro = formData['bairro'].trim();
          data.cep = unFormatPunctuation(formData['cep']);
          data.cidade = formData['cidade'].trim();
          data.complemento = formData['complemento'].trim();
          data.estado = formData['estado'].trim();
          data.endereco = formData['endereco'].trim();
          data.pontoReferencia = formData['pontoReferencia'].trim();
          data.razaoSocial = formData['razaoSocial'].trim();
          data.nomeFantasia = formData['nomeFantasia'].trim();
          data.tipoPessoa = formData['tipoPessoa'];
          data.cpfCnpj = unFormatCpfCnpj(formData['cpfCnpj']);
          data.responsavel = formData['responsavel'].trim();
          data.inscricaoEstadual = formData['inscricaoEstadual'].trim();
          data.dataCadastro = moment(formData['dataCadastro']).format('DD/MM/YYYY');
          data.email = formData['email'].toLowerCase().trim();
          data.microempresa = formData['microempresa'];
          data.prazoRenovacaoChave = formData['prazoRenovacaoChave'].trim();
          data.ramoAtividadeId = formData['ramoAtividadeId'];
          data.retemICMS = formData['retemICMS'];
          data.optanteSimples = formData['optanteSimples'];
          data.statusChave = formData['statusChave'];
          data.telefone1 = unFormatPunctuation(formData['telefone1'].trim());
          data.telefone2 = unFormatPunctuation(formData['telefone2'].trim());
          data.status = dataTemp.status;
          data.revendaId = dataTemp.revendaId;
          data.cnae = '';

          return { ...data };
        }
        default:
          return null;
      }
    }

    return null;
  }

  const HANDLE_ON_CLICK_SUBMIT = {
    0: () => {
      setActiveStep(0);
      handleSaveCreateClientNew();
      setConfirm(false);
    },
    1: () => {
      handleValidateSelectClient(true);
      handleSaveCreateClientNew();
      setConfirm(false);
    },
    2: () => {
      handleValidateSelectSolicitation(true);
      handleSaveCreateClientNew();
      setConfirm(false);
    },
    3: async () => {
      if (!handleValidateSelectSolicitation()) return;
      if (reasonSelected?.existeCliente) {
        await handleValidateNewClient();
      } else {
        setActiveStep(3);
      }
    },
    4: async () => {
      handleValidateFormOnSubmit(false, (inputData) => {
        handleGenerateFormData(inputData, async (formData) => {
          setLoading(true);
          try {
            const resultCreate = await SolicitationService
              .createSolicitation(formData);

            toast(resultCreate.message, { type: toast.TYPE.SUCCESS });
            setTimeout(() => {
              history.push(commercialPaths.commercialSolicitation);
            }, 200); // 0.2 segundos
          } catch (error) {
            showMessageError(error);
            return null;
          } finally {
            setLoading(false);
          }
        });
      });
    },
  };

  const handleStepDataObject = [{
    title: 'Cliente',
    number: 1,
    handleEnabledStep: true,
    handleIsStep: activeStep === 0,
    handleOnMouseDown: null,
    handleClick: HANDLE_ON_CLICK_SUBMIT[0],
    active: true,
    showDivider: true,
  }, {
    title: 'Solicitação',
    number: 2,
    handleEnabledStep: true,
    handleIsStep: activeStep === 1,
    handleOnMouseDown: null,
    handleClick: HANDLE_ON_CLICK_SUBMIT[1],
    active: true,
    showDivider: true,
  }, {
    title: 'Anexos',
    number: 3,
    handleEnabledStep: true,
    handleIsStep: activeStep === 2,
    handleOnMouseDown: null,
    handleClick: HANDLE_ON_CLICK_SUBMIT[2],
    active: true,
    showDivider: true,
  }, {
    title: 'Resumo',
    number: 4,
    handleEnabledStep: true,
    handleIsStep: activeStep === 3,
    handleOnMouseDown: null,
    handleClick: HANDLE_ON_CLICK_SUBMIT[3],
    active: true,
    showDivider: false,
  }];

  const getSolicitacaoContext = useCallback(
    () => ({
      loading,
      setLoading,
      client,
      setClient,
      clienteId,
      setClienteId,
      lineBusinesses,
      reasonSolicitations,
      inputClienteValue,
      setInputClienteValue,
      selectTipoSolicitacao,
      setSelectTipoSolicitacao,
      inputAssunto,
      setInputAssunto,
      textareaTexto,
      setTextareaTexto,
      anexos,
      setAnexos,
      activeStep,
      setActiveStep,
      handleStepDataObject,
      getSelectedSolicitation,
      getDataNewClient,
      handleAsyncEffectAdd,
      handleSelectTipoSolicitacaoChange,
      handleInputAssuntoChange,
      handleTextareaTextoChange,
      handleInputAnexosChange,
      HANDLE_ON_CLICK_SUBMIT,
      handleSaveCreateClientNew,
      handleGetDataCreateNewClient,
      handleValidateCpfCnpj,
      confirm,
      handleInputConfirmChange,
      menuActive,
      setMenuActive,
      newClient,
      setNewClient,
      newClienteId,
      setNewClienteId,
      newInputClienteValue,
      setNewInputClienteValue,
      getValues,
      register,
      control,
      watch,
      errors,
      clearErrors,
      setValue,
    }),
    [
      loading,
      setLoading,
      client,
      setClient,
      clienteId,
      setClienteId,
      lineBusinesses,
      reasonSolicitations,
      inputClienteValue,
      setInputClienteValue,
      selectTipoSolicitacao,
      setSelectTipoSolicitacao,
      inputAssunto,
      setInputAssunto,
      textareaTexto,
      setTextareaTexto,
      anexos,
      setAnexos,
      activeStep,
      setActiveStep,
      handleStepDataObject,
      getSelectedSolicitation,
      getDataNewClient,
      handleAsyncEffectAdd,
      handleSelectTipoSolicitacaoChange,
      handleInputAssuntoChange,
      handleTextareaTextoChange,
      handleInputAnexosChange,
      HANDLE_ON_CLICK_SUBMIT,
      handleSaveCreateClientNew,
      handleGetDataCreateNewClient,
      handleValidateCpfCnpj,
      confirm,
      handleInputConfirmChange,
      menuActive,
      setMenuActive,
      newClient,
      setNewClient,
      newClienteId,
      setNewClienteId,
      newInputClienteValue,
      setNewInputClienteValue,
      getValues,
      register,
      control,
      watch,
      errors,
      clearErrors,
      setValue,
    ],
  );

  return (
    <SolicitacaoContext.Provider
      value={getSolicitacaoContext()}
    >
      {children}
    </SolicitacaoContext.Provider>
  );
}

function useSolicitacao() {
  const context = useContext(SolicitacaoContext);
  return context;
}

SolicitacaoProvider.propTypes = {
  children: PropTypes.any,
  history: PropTypes.any,
};

export { SolicitacaoProvider, useSolicitacao };
