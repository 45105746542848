/* eslint-disable react/prop-types */
import React from 'react';

import { Container } from './styles';

export default function Observation({
  handleObservationChange, observationValue, isDisabled = false
}) {
  let observation = '';
  if (observationValue) {
    observation = observationValue;
  }

  return (
    <Container>
      <div>
        <textarea
          disabled={isDisabled}
          rows={4}
          value={observation}
          onChange={handleObservationChange}
        />
      </div>
    </Container>
  );
}
