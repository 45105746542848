import api, { endpoints } from '~/services/api';

class PersonResaleService {
  static getPersonResales = async (filter) => {
    const { data: response } = await api.post(
      endpoints.person.resale.getPersonResales,
      filter
    );

    return response.data;
  };

  static getPersonResale = async (id) => {
    const { data: response } = await api.get(
      endpoints.person.resale.getPersonResale(id),
    );

    return response.data;
  };

  static getPersonResaleNoImage = async (id) => {
    const { data: response } = await api.get(
      endpoints.person.resale.getPersonResaleNoImage(id)
    );

    return response.data;
  };

  static updatePersonResaleStatus = async (id, status) => {
    const { data: response } = await api.put(
      endpoints.person.resale.updatePersonResaleStatus,
      { id, status }
    );

    return response;
  };

  static verifyPersonResale = async (cpfCnpj) => {
    const { data: response } = await api.post(
      endpoints.person.resale.verifyPersonResale,
      { cpfCnpj }
    );

    return response;
  };

  static getPersonKeyResale = async (cpfCnpj) => {
    const { data: response } = await api.post(
      endpoints.person.resale.getPersonKeyResale,
      { cpfCnpj }
    );

    return response.data;
  };

  static getPersonResaleAutoComplete = async ({ cpfCnpj, nome }) => {
    const { data: response } = await api.post(
      endpoints.person.resale.getPersonAutoCompleteResale,
      { cpfCnpj, nome }
    );

    return response.data;
  };

  static getPersonAllResales = async () => {
    const { data: response } = await api.post(
      endpoints.person.resale.getPersonAllResales,
      null
    );

    return response.data;
  };
}

export default PersonResaleService;
