import React from 'react';
import PropTypes from 'prop-types';
import { CollapsibleFilter } from '~/components/CollapsibleFilter';
import { FaSearch } from 'react-icons/fa';
import './styles.scss';

function SimpleTextSearch({
  countFilter,
  fieldLabel,
  inputValue,
  handleInputChange,
  handleSubmit,
}) {
  return (
    <CollapsibleFilter countFilters={countFilter}>
      <form className="formContainer" onSubmit={handleSubmit}>
        <label id="searchText">Pesquisar</label>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>{fieldLabel}</span>
            <input
              type="text"
              name="groupName"
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <button className="buttonBaseOnly" id="baseButtonIcon" onClick={handleSubmit}>
            <FaSearch id="baseNameIcon" />
            <span>BUSCAR</span>
          </button>
        </div>
      </form>
    </CollapsibleFilter>
  );
}

SimpleTextSearch.propTypes = {
  countFilter: PropTypes.number,
  fieldLabel: PropTypes.string.isRequired,
  inputValue: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default SimpleTextSearch;
