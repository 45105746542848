import TableCell from '@material-ui/core/TableCell';
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import Modal from '~/components/Modal';
import { Radio } from '~/components/Radio';
import { commercialPaths } from '~/routes/paths';
import { PaymentsService } from '~/services/commercial/payment';
import TableComponent from '~/components/TableComponent';
import { accessDenied } from '~/util/accessDenied';
import { SETTINGS_ENUM } from '~/util/domainutils';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import { ContainerButtonNewPayment } from './styles.js';
import './styles.scss';

function Payments({ history }) {
  const [loading, setLoading] = useState(false);
  const [inputAtivo, setInputAtivo] = useState(false);
  const [inputCarencia, setInputCarencia] = useState(1);
  const [searchResponse, setSearchResponse] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions.create_pagamento),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions.edit_pagamento),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions.delete_pagamento),
    [userPermissions]
  );

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      try {
        const { resultado } = await PaymentsService.getPayments();

        if (resultado.pagamento.length) {
          setSearchResponse(resultado.pagamento);
        } else {
          toast('Não encontramos nenhum registro'
            + ' para Formas de Pagamento!', { type: toast.TYPE.ERROR });
        }

        resultado.carencia.forEach((setting) => {
          if (SETTINGS_ENUM.GET_ATIVAR_CARENCIA_PAGAMENTO === setting.code) {
            setInputAtivo(setting.value === 'true');
          }
          if (SETTINGS_ENUM.GET_CARENCIA_PAGAMENTO === setting.code) {
            setInputCarencia(setting.value);
          }
        });
      } catch (error) {
        showMessageError(error);
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();
  }, []);

  function handleInputAtivoChange(event) {
    const {
      target: {
        value
      }
    } = event;

    setInputAtivo(value === 'true');
  }

  function handleInputCarenciaChange(event) {
    const {
      target: {
        value
      }
    } = event;

    if (value >= 1 && value <= 6) {
      setInputCarencia(value);
    }
  }

  const renderRowComponent = row => {
    return [
      <TableCell key="row_payment_0">{row.nome}</TableCell>,
      <TableCell key="row_payment_2">
        <div id="subHolder">
          {row.formaPlanoPagamentos.map((plano) => (
            <ul key={plano.id}>{plano.planoPagamento.nome}</ul>
          ))}
        </div>
      </TableCell>,
    ];
  };

  async function handleDelete() {
    try {
      setLoading(true);
      const result = await PaymentsService.deletePayment(deleteItem.id);

      if (!result.data.ok) {
        toast('Erro ao deletar Pagamento!', {
          type: toast.TYPE.ERROR,
        });
      } else {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteItem.id
        );
        setSearchResponse(newSearchResponse);
        toast(result.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar Pagamento!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    try {
      const result = await PaymentsService
        .updateGracePeriodPayment(inputAtivo, inputCarencia);

      if (result.data.ok) {
        toast(result.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function onHandleSave() {
    return userCanCreate
      ? (event) => handleSubmit(event)
      : () => accessDenied(history);
  }

  return (
    <div className="defaultFormContainer">
      <SidebarPageHeader
        mainMenu="Comercial"
        pageName="Pagamento"
        button1={{
          label: 'Salvar',
          onClick: onHandleSave,
          permission: userCanCreate,
          main: true,
        }}
        openButtonSelect
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <ContainerButtonNewPayment>
            <span id="title">Formas de pagamento</span>
            <button className="buttonBaseOnly baseMain"
              onClick={userCanCreate
                ? () => history.push(commercialPaths.addPayments)
                : () => accessDenied(history)
              }
            >
              <span>NOVO PAGAMENTO</span>
            </button>
          </ContainerButtonNewPayment>
          <TableComponent
            headerLabels={[
              { text: 'Forma', width: '50%' },
              { text: 'Plano(s)', width: '50%' },
            ]}
            renderRowComponent={renderRowComponent}
            dataObjects={searchResponse}
            handleEdit={userCanEdit
              ? (row) => history.push(
                commercialPaths.editPayments, { id: row.id },
              )
              : () => accessDenied(history)
            }
            handleDelete={userCanDelete
              ? (row) => setDeleteItem(row)
              : () => accessDenied(history)
            }
            style={{ margin: '20px 0 0 0' }}
          />
        </div>
        <div className="infoCard">
          <span id="title">Carência do pagamento</span>
          <div className="rowContainer">
            <Radio
              name="statusPedido"
              labelHorizontal={'Disponível'}
              value={true}
              onChange={handleInputAtivoChange}
              outlineSelect={inputAtivo === true}
            />
            <Radio
              name="statusPedido"
              labelHorizontal={'Indisponível'}
              value={false}
              onChange={handleInputAtivoChange}
              outlineSelect={inputAtivo === false}
            />
          </div>
          {inputAtivo && (
            <div className="rowContainer">
              <div className="inputHolder">
                <span>Quantidade máxima de meses (1 a 6) *</span>
                <input
                  type="number"
                  name="grace-period"
                  value={inputCarencia}
                  onChange={handleInputCarenciaChange}
                  style={{ width: 120 }}
                />
              </div>
            </div>
          )}
        </div>
        <div className='adminPageContainer'>
          <Modal loading={loading} show={loading || !!deleteItem}>
            <div className="modalContainer">
              <h2>Confirmar exclusão</h2>
              <span>
                Tem certeza que deseja deletar o item
                <span id="elementName">
                  {` ${!!deleteItem && (deleteItem.nome || deleteItem.id)}`}
                </span>
                ?
              </span>
              <div>
                <button
                  className="buttonBaseOnly addMargin"
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteItem(null);
                  }}
                >
                  Cancelar
                </button>
                <button
                  className="buttonBaseOnly baseMain"
                  onClick={handleDelete}
                >
                  Deletar
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </form>
    </div>
  );
}

Payments.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default Payments;
