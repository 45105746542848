import api, { endpoints } from '~/services/api';

class ChannelRatingService {
  static getChannelRating = async (text, pager) => {
    const { data: response } = await api.post(
      endpoints.person.channelRating.getChannelRating,
      pager
        ? {
          text: text,
          ...pager,
        }
        : {
          text: text,
        }
    );
    return response.data;
  };

  static createChannelRating = async (channelData) => {
    const { data: response } = await api.post(
      endpoints.person.channelRating.createChannelRating,
      { ...channelData },
    );

    return response;
  }

  static updateChannelRating = async ({ id, nome, descontos }) => {
    const { data: response } = await api.put(
      endpoints.person.channelRating.updateChannelRating,
      { id, nome, descontos },
    );

    return response;
  }

  static deleteChannelRating = async (id) => {
    const { data: response } = await api.delete(
      endpoints.person.channelRating.deleteChannelRating(id),
    );

    return response;
  }
}

export { ChannelRatingService };
