import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

function FormMultipleOptionItem({
  form,
  setForm,
  formItemIndex,
  options,
  setOptions,
  optionIndex,
  optionName,
  errorForm,
  setErrorFormItems,
}) {
  const DEFAULT_OPTION_VALUE = options[optionIndex].itemData.value || '';
  const [optionValue, setOptionValue] = useState(DEFAULT_OPTION_VALUE);
  const optionRef = useRef();

  const handleUpdateOption = useCallback((optObj, onSetOptions) => {
    const formObj = [...form];
    const optionsObj = [...(optObj || [])];

    if (optionsObj && onSetOptions) {
      optionsObj[optionIndex].itemIndex = optionIndex;
      optionsObj[optionIndex].itemData.label = optionRef.current.name;
      optionsObj[optionIndex].itemData.value = optionRef.current.value;

      onSetOptions(optionsObj);
      setOptionValue(optionRef.current.value);

      formObj[formItemIndex].itemData.itemContent = optionsObj;

      if (errorForm[formItemIndex]) {
        let errosOptions = [...errorForm];

        if (errorForm[formItemIndex].errorSubItem.length) {
          const optionTemp = errosOptions[formItemIndex].errorSubItem;

          if (optionTemp[optionIndex]) {
            optionTemp[optionIndex].errorSubItem = false;
          }

          const isOptionError = optionTemp.map(_ => _.errorSubItem);

          if (!isOptionError.includes(true)) {
            errosOptions[formItemIndex].errorItem = false;
          } else {
            errosOptions[formItemIndex].errorItem = true;
          }
        }

        setErrorFormItems(errosOptions);
      }

      setForm(formObj);
    }
  },
  [
    form,
    setForm,
    optionIndex,
    formItemIndex,
    errorForm,
    setErrorFormItems,
  ]);

  useEffect(() => {
    handleUpdateOption();
  }, [handleUpdateOption]);

  return (
    <input
      type="text"
      name={optionName}
      ref={optionRef}
      value={optionValue}
      disabled={optionName === 'Outro'}
      onChange={() => {
        handleUpdateOption(options, setOptions);
      }}
    />
  );
}

FormMultipleOptionItem.propTypes = {
  form: PropTypes.array,
  setForm: PropTypes.func,
  formItemIndex: PropTypes.number,
  options: PropTypes.array,
  setOptions: PropTypes.func,
  optionName: PropTypes.string,
  optionIndex: PropTypes.number,
  errorForm: PropTypes.array,
  setErrorFormItems: PropTypes.func,
};

export { FormMultipleOptionItem };
