/* eslint-disable react/prop-types */
import React from 'react';
import { Container, ContainerFull } from '../styles';
import '../../styles.scss';

export default function Cancellation({ cancellation }) {
  return (
    <ContainerFull>
      <Container>
        <div>
          <span>Motivo:</span>
          <span>{cancellation.motivo.descricao}</span>
        </div>
        <div>
          <span>Nº de Caixas:</span>
          <span>{cancellation.numeroCaixas || '---'}</span>
        </div>
        <div>
          <span>Submotivo:</span>
          <span>
            {cancellation.submotivo ? cancellation.submotivo.descricao : '---'}
          </span>
        </div>
        <div>
          <span>Nº de Usuários:</span>
          <span>{cancellation.numeroUsuarios || '---'}</span>
        </div>
      </Container>
      <Container className='observation-default'>
        <div>
          <span>Observação:</span>
          <span className='containerObs'>{cancellation.observacao || '---'}</span>
        </div>
      </Container>
    </ContainerFull>
  );
}
