import api, { endpoints } from '~/services/api';

class PersonClientService {
  static getPersonClients = async (filter) => {
    const { data: response } = await api.post(
      endpoints.person.client.getPersonClients,
      filter
    );

    return response.data;
  };

  static getPersonClient = async (id) => {
    const { data: response } = await api.get(
      endpoints.person.client.getPersonClient(id)
    );

    return response.data;
  };

  static updatePersonClientStatus = async (id, status) => {
    const { data: response } = await api.put(
      endpoints.person.client.updatePersonClientStatus,
      { id, status }
    );

    return response;
  };

  static updatePersonClientStatusKey = async (id, statusChave) => {
    const { data: response } = await api.put(
      endpoints.person.client.updatePersonClientStatusKey,
      { id, statusChave }
    );

    return response;
  };

  static verifyPersonClient = async (cpfCnpj) => {
    const { data: response } = await api.post(
      endpoints.person.client.verifyPersonClient,
      { cpfCnpj }
    );

    return response;
  };

  static getPersonKeyClient = async (cpfCnpj) => {
    const { data: response } = await api.post(
      endpoints.person.client.getPersonKeyClient,
      { cpfCnpj }
    );

    return response.data;
  };

  static getAttendanceChangeClient = async (clientId) => {
    const { data: response } = await api.get(
      endpoints.person.client.getAttendanceChangeClient(clientId),
    );

    return response.data;
  };

  static getPersonExistClient = async (cpfCnpj) => {
    const { data: response } = await api.post(
      endpoints.person.client.getPersonExistClient,
      { cpfCnpj }
    );

    return response;
  };
}

export default PersonClientService;
