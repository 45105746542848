import TableCell from '@material-ui/core/TableCell';
import { find } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import { MdChatBubbleOutline } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import PersonResaleService from '~/services/person-resale-service';
import PersonEmployeeService from '~/services/person-employee-service';
import { ReasonSolicitationService } from '~/services/commercial/reasonsolicitation';
import { SolicitationService } from '~/services/commercial/solicitation';
import PrintableComponent from '~/components/PrintableComponent';
import { commercialPaths } from '~/routes/paths';
import { CHECK_USER_BLOCK } from '~/util/functionsutils';
import { USER_TYPE } from '~/util/domainutils';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import { formatCpfCnpj } from '~/util/stringutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';
import SearchComponent from './SearchComponent';
import { useFitler } from '~/hook/useFilter';
import * as S from './styles';
import './styles.scss';

function CommercialSolicitation({ history }) {
  const { filters, setFilters } = useFitler();
  const [loading, setLoading] = useState(false);
  const [inputCliente, setInputCliente] = useState('');
  const [inputRevenda, setInputRevenda] = useState('');
  const [selectTipoSolicitacao, setSelectTipoSolicitacao] = useState('');
  const [inputDataInicio, setInputDataInicio] = useState('');
  const [inputDataFim, setInputDataFim] = useState('');
  const [radioStatus, setRadioStatus] = useState([]);
  const [searchResponse, setSearchResponse] = useState([]);
  const [allReasonSolicitations, setAllReasonSolicitations] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });
  const [formData, setFormData] = useState({
    id: null,
    textCliente: '',
    textRevenda: '',
    tipo: '',
    dataInicio: null,
    dataFim: null,
    status: '',
  });

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const currentUser = useSelector(state => state.user.data);

  const userCanViewColumnRevenda = useMemo(
    () => userPermissions.includes(permissions['view_coluna-revenda']),
    [userPermissions]
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions.create_solicitacao),
    [userPermissions]
  );

  let PrintableElement = React.useRef();

  useEffect(() => {
    setLoading(true);
    const asyncEffect = async () => {
      try {
        const [
          { resultado: allReasonSolicitations },
          userRevenda,
          userColaborador,
        ] = await Promise.all([
          ReasonSolicitationService.getReasonsSolicitation(),
          PersonResaleService.getPersonResale(currentUser.revenda.id),
          currentUser.tipo === USER_TYPE.COLABORADOR
            ? PersonEmployeeService.getPersonEmployee(currentUser.id)
            : null,
          findAllByFilter(pageParameters, handleFilters()),
        ]);

        if (allReasonSolicitations.length) {
          setAllReasonSolicitations(allReasonSolicitations);
        } else {
          setAllReasonSolicitations([]);
        }

        await CHECK_USER_BLOCK(userRevenda, history);
        await CHECK_USER_BLOCK(userColaborador, history);
      } catch (error) {
        showMessageError(error, 'Erro ao verificar revenda');
      } finally {
        setLoading(false);
      }
    };

    asyncEffect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleFilters() {
    let count = 0;

    if (filters.textCliente) {
      setInputCliente(filters.textCliente);
      count += 1;
    }
    if (filters.textRevenda) {
      setInputRevenda(filters.textRevenda);
      count += 1;
    }
    if (filters.tipoMotivoId) {
      setSelectTipoSolicitacao(filters.tipoMotivoId);
      count += 1;
    }
    if (filters.dataInicio) {
      setInputDataInicio(filters.dataInicio);
      count += 1;
    }
    if (filters.dataFim) {
      setInputDataFim(filters.dataFim);
      count += 1;
    }
    if (filters.status) {
      setRadioStatus(filters.status);
      count += 1;
    }

    setCountFilter(count);

    return filters;
  }

  function handleSetSearchResponse(result, total) {
    if (result && result.length) {
      setSearchResponse(result);
      setTotalItems(total);
    } else {
      setSearchResponse([]);
      setTotalItems(0);
    }
  }

  function handleInputClienteChange(event) {
    const {
      target: { value },
    } = event;

    setInputCliente(value);
  }

  function handleInputRevendaChange(event) {
    const {
      target: { value },
    } = event;

    setInputRevenda(value);
  }

  function handleSelectTipoSolicitacaoChange(event) {
    const {
      target: { value },
    } = event;

    setSelectTipoSolicitacao(value);
  }

  function handleInputDataInicioChange(event) {
    const {
      target: { value },
    } = event;

    setInputDataInicio(value);
  }

  function handleInputDataFimChange(event) {
    const {
      target: { value },
    } = event;

    setInputDataFim(value);
  }

  function handleRadioStatusChange(event) {
    const {
      target: { value },
    } = event;

    if (radioStatus.some(elem => elem === value)) {
      setRadioStatus(old => old.filter(elem => elem !== value));
    } else {
      setRadioStatus(old => [...old, value]);
    }
  }

  async function validateForm(success) {
    let count = 0;
    const data = {};
    if (inputCliente.length) {
      data.textCliente = inputCliente.trim();
      count += 1;
    }
    if (inputRevenda.length) {
      data.textRevenda = inputRevenda.trim();
      count += 1;
    }
    if (selectTipoSolicitacao.length) {
      data.tipoMotivoId = selectTipoSolicitacao;
      count += 1;
    }
    if (inputDataInicio.length) {
      data.dataInicio = inputDataInicio;
      count += 1;
    }
    if (inputDataFim.length) {
      data.dataFim = inputDataFim;
      count += 1;
    }
    if (radioStatus.length) {
      data.status = radioStatus;
      count += 1;
    }

    setCountFilter(count);
    setFormData(data);
    setFilters(data);
    await success(data);
  }

  function handleSetSearchResponseReport(result) {
    if (result && result.length) {
      setSearchResponseReport(result);
    } else {
      setSearchResponseReport([]);
    }
  }

  async function findAllByFilterReport(pageParams, formDataParam) {
    setLoading(true);
    try {
      const resultData = await SolicitationService
        .getSolicitations(formDataParam);

      const { data } = resultData;
      if (data.resultado.length) {
        handleSetSearchResponseReport(data.resultado);
      } else {
        handleSetSearchResponseReport();
        toast('Não encontramos solicitações para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams, data) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      const { page, rowsPerPage } = pageParams;
      const { data: response } = await SolicitationService
        .getFilterSolicitations({
          formDataParams: (data || formData),
          limit: rowsPerPage,
          offset: page * rowsPerPage,
        });

      if (response.resultado.length) {
        handleSetSearchResponse(
          response.resultado.map(res => {
            const type = find(allReasonSolicitations, { tag: res.tipo });
            return { ...res, tipo: (type || {}).descricao };
          }),
          response.total
        );
      } else {
        handleSetSearchResponse();
        toast('Não encontramos solicitações para esta pesquisa!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    validateForm(async data => {
      await findAllByFilter(pageParameters, data);
    });
  }

  const renderRowComponent = (row, isPrint) => {
    const date = moment(row.data).format('DD/MM/YYYY, HH:mm:ss');
    const lastUpdate = moment(row.statusAtual.updatedAt).format('DD/MM/YYYY, HH:mm:ss');

    const helpersStatus = {
      'Em Andamento': 'Em Andamento',
      'Aguardando Análise CM': 'Aguardando Análise',
      'Pendente Retorno CM': 'Pendência CM',
      'Pendente Retorno REVENDA': 'Pendência Revenda',
      'Aprovado': 'Aprovado',
      'Negado': 'Negado',
      // status antigo
      'Novo': 'Novo',
      'Encerrado': 'Encerrado',
      'Em Análise': 'Em Análise',
      'Pendente': 'Pendente',
    };

    const helpersStatusResponse = () => {
      return helpersStatus[`${row.statusAtual.status}`] || '---';
    };

    const [typeSolicitation] = allReasonSolicitations.filter(
      (reason) => reason.id === row.tipoMotivoId
    );

    let nomeManifestante = '---';
    if (row.manifestante) {
      const { razaoSocial, nomeFantasia } = row.manifestante;
      nomeManifestante = razaoSocial || nomeFantasia || '---';
    }

    let nomeRevenda = '---';
    if (row.revenda) {
      const { razaoSocial, nomeFantasia } = row.revenda;
      nomeRevenda = razaoSocial || nomeFantasia || '---';
    }

    let nomeStatusAtual = '---';
    if (row.statusAtual.responsavel) {
      const {
        razaoSocial,
        nomeFantasia,
        nomeSocial,
      } = row.statusAtual.responsavel;
      nomeStatusAtual = razaoSocial || nomeFantasia || nomeSocial || '---';
    }

    return [
      <TableCell key={1}>
        {row.manifestante ? (
          <S.TableCellContent>
            <S.TableCellValue>
              <span title={nomeManifestante}>{nomeManifestante}</span>
              <br />
              <small>
                {formatCpfCnpj(row.manifestante.cpfCnpj) || '---'}
              </small>
            </S.TableCellValue>
          </S.TableCellContent>
        ) : '---'}
      </TableCell>,
      userCanViewColumnRevenda && (
        <TableCell key={2}>
          {row.revenda ? (
            <S.TableCellContent>
              <S.TableCellValue>
                <span title={nomeRevenda}>{nomeRevenda}</span>
                <br />
                <small>{formatCpfCnpj(row.revenda.cpfCnpj) || '---'}</small>
              </S.TableCellValue>
            </S.TableCellContent>
          ) : '---'}
        </TableCell>
      ),
      <TableCell key={3}>
        {(typeSolicitation?.descricao || '---')}
      </TableCell>,
      <TableCell key={4} align='center'>{helpersStatusResponse()}</TableCell>,
      <TableCell key={0}>{date}</TableCell>,
      <TableCell key={8}>{lastUpdate}</TableCell>,
      <TableCell key={5}>{nomeStatusAtual}</TableCell>,
      !isPrint && (
        <TableCell key={7}>
          <div className="iconHolder">
            <MdChatBubbleOutline
              className="EditIconOnTable"
              disabled
              onClick={() =>
                history.push(commercialPaths.editCommercialSolicitation, {
                  id: row.id
                })
              }
              title="Ver Mensagens"
            />
          </div>
        </TableCell>
      ),
    ];
  };

  function handleHeaderLabels(pdf = false) {
    const getWidth = userCanViewColumnRevenda ? '20%' : 'auto';

    const labels = [
      { text: 'Cliente', width: getWidth },
      { text: 'Revenda', width: '20%' },
      { text: 'Solicitação', width: '20%' },
      { text: 'Status', width: '100px' },
      { text: 'Data de criação', width: '110px' },
      { text: 'Última atualização', width: '170px' },
      { text: 'Atualizado por', width: '170px' },
    ];

    const labelAction = { text: 'Ação', width: '80px', align: 'center' };

    // Removendo Revenda
    const labelsWithoutRevenda = labels.filter(label => label.text !== 'Revenda');

    let isAction;
    // Labels com Revenda
    isAction = pdf ? [...labels] : [...labels, labelAction];
    const labelsWithAction1 = isAction;

    // Labels sem Revenda
    isAction = pdf ? [...labelsWithoutRevenda] : [...labelsWithoutRevenda, labelAction];
    const labelsWithAction2 = isAction;

    return userCanViewColumnRevenda ? labelsWithAction1 : labelsWithAction2;
  }

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Solicitação"
        handlePageUpdate={findAllByFilterReport}
        filters={[
          { name: 'Cliente', value: inputCliente },
          { name: 'Revenda', value: inputRevenda },
          { name: 'Tipo de Solicitação', value: selectTipoSolicitacao },
          { name: 'De', value: inputDataInicio },
          { name: 'Até', value: inputDataFim },
          { name: 'Status', value: radioStatus.join(', ') },
        ]}
        renderRowComponent={row => renderRowComponent(row, true)}
        headerLabels={handleHeaderLabels({ pdf: true })}
        dataObjects={searchResponseReport}
      />
      <S.Container>
        <Modal loading={loading} show={loading} />
        <SidebarPageHeader
          mainMenu="Comercial"
          pageName="Solicitação"
          button1={{
            label: 'Nova Solicitação',
            link: commercialPaths.addCommercialSolicitation,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await validateForm(async (data) => {
              await findAllByFilterReport(pageParameters, data);
            });
          }}
          openButtonSelect
        />
        <SearchComponent
          countFilter={countFilter}
          allReasonSolicitations={allReasonSolicitations}
          userCanViewColumnRevenda={userCanViewColumnRevenda}
          handleSubmit={handleSubmit}
          inputClienteValue={inputCliente}
          handleClienteChange={handleInputClienteChange}
          inputRevendaValue={inputRevenda}
          handleRevendaChange={handleInputRevendaChange}
          selectedTipoSolicitacao={selectTipoSolicitacao}
          handleSelectTipoSolicitacaoChange={handleSelectTipoSolicitacaoChange}
          dataInicio={inputDataInicio}
          handleDataInicioChange={handleInputDataInicioChange}
          dataFim={inputDataFim}
          handleDataFimChange={handleInputDataFimChange}
          radioStatus={radioStatus}
          handleRadioStatusChange={handleRadioStatusChange}
        />
        <TableComponent
          headerLabels={handleHeaderLabels()}
          dataObjects={searchResponse}
          totalItems={totalItems}
          handlePageUpdate={async (newFilterPerPage) => {
            await validateForm(async (data) => {
              await findAllByFilter(newFilterPerPage, data);
            });
          }}
          renderRowComponent={renderRowComponent}
          useCustomActions
        />
      </S.Container>
    </>
  );
}

CommercialSolicitation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default CommercialSolicitation;
