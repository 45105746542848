import PropTypes from 'prop-types';
import React from 'react';
import { PeriodInDate } from '~/components/PeriodInDate';
import { MultipleChoices } from '~/components/MultipleChoices';
import { FORM_STATUS_ENUM } from '~/util/domainutils';
import { InputPerson } from '~/components/InputPerson';
import { CollapsibleFilter } from '~/components/CollapsibleFilter';
import { FaSearch } from 'react-icons/fa';
import './styles.scss';

function MaintenanceSearch({
  countFilter,
  handleSubmit,
  handleCheckboxStatusChange,
  checkboxStatus,
  formName,
  handleFormNameChange,
  clientValue,
  handleClientChange,
  orderValue,
  handleOrderChange,
  selectedSoftware,
  handleSoftwareChange,
  softwares,
  selectedModule,
  handleModuleChange,
  modules,
  dataInicio,
  handleDataInicioChange,
  dataFim,
  handleDataFimChange,
}) {
  return (
    <CollapsibleFilter countFilters={countFilter}>
      <form className="formContainer" onSubmit={handleSubmit}>
        <label id="searchText">Pesquisar</label>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Nome</span>
            <input
              type="text"
              name="formName"
              value={formName}
              onChange={handleFormNameChange}
            />
          </div>
          <div className="inputHolder defaultFlex">
            <span>Software</span>
            <select
              name="software"
              value={selectedSoftware}
              onChange={handleSoftwareChange}
            >
              <option value={0}>Selecione</option>
              {softwares.map(item => (
                <option key={item.id} value={item.id}>
                  {item.nome}
                </option>
              ))}
            </select>
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Módulo</span>
            <select
              name="module"
              value={selectedModule}
              onChange={handleModuleChange}
            >
              <option value={0}>Selecione</option>
              {modules.map(item => (
                <option key={item.id} value={item.id}>
                  {item.nome}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="rowContainer">
          <InputPerson
            title1={'Cliente'}
            inputPerson1={clientValue}
            handlePerson1Change={handleClientChange}
          />
          <div className="inputHolder noMarginRight">
            <span>Nº Pedido</span>
            <input
              type="text"
              name="order"
              value={orderValue ? orderValue : ''}
              onChange={handleOrderChange}
            />
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <MultipleChoices
              title={'Status'}
              type={'checkbox'}
              dataObjects={[
                {
                  label: FORM_STATUS_ENUM.ATENDIDO.label,
                  value: FORM_STATUS_ENUM.ATENDIDO.value,
                },
                {
                  label: FORM_STATUS_ENUM.PENDENTE.label,
                  value: FORM_STATUS_ENUM.PENDENTE.value,
                },
              ]}
              checkedChoices={checkboxStatus}
              handleChoicesChange={handleCheckboxStatusChange}
            />
          </div>
          <div className="inputHolder">
            <PeriodInDate
              dataInicio={dataInicio}
              handleDataInicioChange={handleDataInicioChange}
              dataFim={dataFim}
              handleDataFimChange={handleDataFimChange}
            />
          </div>
          <button className="buttonBaseOnly" id="baseButtonIcon" onClick={handleSubmit}>
            <FaSearch id="baseNameIcon" />
            <span>BUSCAR</span>
          </button>
        </div>
      </form>
    </CollapsibleFilter>
  );
}

MaintenanceSearch.propTypes = {
  countFilter: PropTypes.number,
  handleSubmit: PropTypes.func.isRequired,
  formName: PropTypes.string,
  handleFormNameChange: PropTypes.func,
  clientValue: PropTypes.string,
  handleClientChange: PropTypes.func,
  orderValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  handleOrderChange: PropTypes.func,
  checkboxStatus: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.number,
  ]),
  handleCheckboxStatusChange: PropTypes.func,
  softwares: PropTypes.array,
  selectedSoftware: PropTypes.string,
  handleSoftwareChange: PropTypes.func,
  modules: PropTypes.array,
  selectedModule: PropTypes.string,
  handleModuleChange: PropTypes.func,
  dataInicio: PropTypes.string,
  handleDataInicioChange: PropTypes.func,
  dataFim: PropTypes.string,
  handleDataFimChange: PropTypes.func,
};

export default MaintenanceSearch;
