import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';

export const ModuleHolder = styled.div`
  display: flex;
  width: 45%;
  min-width: 400px;
  flex-direction: column;
  background-color: #f6f6f6;
  border-radius: 10px;
  margin: 10px 20px 10px 0;
  padding: 15px;
  cursor: default;

  @media (max-width: 1160px) {
    width: 100%;
    min-width: none;
  }

  @media (min-width: 1580px) {
    width: 30%;
    min-width: 400px;
  }

  @media (min-width: 2560px) {
    width: 24%;
    min-width: 500px;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 100%;

    #moduleName {
      font-weight: bold;
      color: #707070;
      white-space: nowrap;
      margin-right: 6px;
    }
  }
`;

export const ModuleHeaderTitle = styled.div`
  margin: 10px 0 20px 0;
  height: 20px;
`;

export const ModuleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ModuleTitle = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 280px;

  @media screen and (max-width: 1160px) {
    max-width: 100%;
  }

  @media screen and (max-width: 550px) {
    max-width: 280px;
  }
`;

export const ModuleNameContent = styled.div`
  @media screen and (max-width: 1160px) {
    max-width: 100%;
  }

  max-width: ${({ isDefault }) => isDefault ? '200px' : '100%'} ;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const ModuleDefault = styled.span`
  font-style: italic;
  color: #707070;
`;

export const ModulePriceHolderContainer = styled.span`
  display: flex;
  color: #707070;
`;

export const ModuleDivider = styled.div`
  margin: 0 10px;
`;

export const InputCurrencyFormat = styled(CurrencyFormat).attrs({
  displayType: 'text',
  thousandSeparator: '.',
  decimalSeparator: ',',
  decimalScale: 2,
  fixedDecimalScale: true,
  prefix: '',
})``;
