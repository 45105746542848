import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { FaRegTrashAlt } from 'react-icons/fa';
import TableCell from '@material-ui/core/TableCell';
import { ChannelRatingService } from '~/services/person/channelrating';
import { person } from '~/routes/paths';
import Modal from '~/components/Modal';
import { showMessageError } from '~/util/errorutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import TableComponent from '~/components/TableComponent';

export default function AddChannelRating({ history }) {
  const [channelName, setChannelName] = useState('');
  const [discountName, setDiscountName] = useState('');
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [discounts, setDiscounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error_nomeCanal, setErrorNomeCanal] = useState(false);
  const [error_nomeDesconto, setErrorNomeDesconto] = useState(false);
  const [error_percentege, setErrorPercentage] = useState(false);

  function handleChannelNameChange(event) {
    const {
      target: { value },
    } = event;
    setChannelName(value);
    setErrorNomeCanal(false);
  }

  function handleDiscountNameChange(event) {
    const {
      target: { value },
    } = event;
    setDiscountName(value);
    setErrorNomeDesconto(false);
  }

  function handleDiscountPercentageChange(event) {
    const {
      target: { value },
    } = event;
    setDiscountPercentage(value);
    setErrorPercentage(false);
  }

  async function addNewDiscount() {
    let error_temp = false;

    if (!discountName.length) {
      error_temp = true;
      setErrorNomeDesconto(true);
    }

    if (discountPercentage < 0) {
      error_temp = true;
      setErrorPercentage(true);
    }

    if (error_temp) {
      return;
    }

    const index = Date.now();

    setDiscounts([
      ...discounts,
      {
        index,
        nome: discountName.trim(),
        valor: discountPercentage,
        agrupamento: false,
      },
    ]);

    setDiscountName('');
    return setDiscountPercentage(0);
  }

  function removeDiscount(id) {
    const newDiscounts = discounts.filter(discount => discount.index !== id);
    setDiscounts(newDiscounts);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    let error_temp = false;

    if (!channelName.length) {
      error_temp = true;
      setErrorNomeCanal(true);
    }

    if (error_temp) {
      return;
    }

    const channelData = {
      nome: channelName.trim(),
      descontos: discounts,
    };

    setLoading(true);
    try {
      const result = await ChannelRatingService
        .createChannelRating(channelData);

      if (result) {
        toast(result.message, { type: toast.TYPE.SUCCESS });
        setLoading(false);
        setTimeout(() => history.push(person.channelRating), 100); // 0.1s
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  function renderRowComponent(row) {
    return [
      <TableCell key={`row_add-channel-rating_0_${row.nome}`}>
        {row.nome}
      </TableCell>,
      <TableCell key={`row_add-channel-rating_1_${row.nome}`}>
        {row.valor}
      </TableCell>,
      <TableCell
        key={`row_add-channel-rating_2_${row.nome}`}
        className="iconHolder"
      >
        <FaRegTrashAlt
          className="iconOnTable trashIcon"
          onClick={() => removeDiscount(row.index)}
        />
      </TableCell>,
    ];
  }

  return (
    <div className="defaultFormContainer">
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Pessoa"
        pageName="Incluir Classificação"
        breadcrumbs={[{
          label: 'Classificação do Canal',
          link: person.channelRating,
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => handleSubmit,
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={() => history.goBack()}
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <span id="title">Dados Gerais</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex noMarginRight">
              <span>Nome da Classificação *</span>
              <input
                type="text"
                name="channelName"
                value={channelName}
                onChange={handleChannelNameChange}
              />
              {error_nomeCanal && (
                <small style={{ color: 'red' }}>
                  Campo obrigatório
                </small>
              )}
            </div>
          </div>
        </div>

        <div className="infoCard">
          <span id="title">Tipos de Desconto</span>
          <div className="rowContainer">
            <div className="inputHolder defaultFlex">
              <span>Nome do Desconto *</span>
              <input
                type="text"
                name="discountName"
                value={discountName}
                onChange={handleDiscountNameChange}
              />
              {error_nomeDesconto && (
                <small style={{ color: 'red' }}>
                  Campo obrigatório
                </small>
              )}
            </div>
            <div className="inputHolder noMarginRight">
              <span>Porcentagem</span>
              <input
                type="number"
                name="discountPercentage"
                value={discountPercentage}
                onChange={handleDiscountPercentageChange}
              />
              {error_percentege && (
                <small style={{ color: 'red' }}>
                  Digite um valor positivo
                </small>
              )}
            </div>
          </div>
          <div className="rowContainer">
            <button
              className="buttonBase"
              type="button"
              onClick={addNewDiscount}
            >
              ADICIONAR
            </button>
          </div>
        </div>

        <div className="infoCard">
          <span id="title">Dados do desconto</span>
          <TableComponent
            headerLabels={[
              { text: 'Nome do desconto', width: 'auto' },
              { text: 'Porcentagem', width: '10%' },
              { text: 'Ação', width: '5%', align: 'center' },
            ]}
            dataObjects={discounts}
            fieldsArray={['nome', 'valor']}
            renderRowComponent={renderRowComponent}
            useCustomActions
          />
        </div>
      </form>
    </div>
  );
}

AddChannelRating.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
};
