import { Fragment } from 'react';
import styled from 'styled-components';

export const ContainerAddRegister = Fragment;
export const Container = styled.div``;

export const ModalContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h2 {
    color: #707070;
    padding-bottom: 10px;
  }

  span {
    font-size: 18px !important;
  }

  div {
    display: flex;
    flex: 1;
    width: 100%;
    padding-top: 25px;
    justify-content: center;
  }
`;

export const BaseButton = styled.button.attrs({
  className: 'buttonBaseOnly'
})``;

export const CancelButton = styled(BaseButton)`
  margin-right: 15px;
`;

export const DeleteButton = styled(BaseButton).attrs({
  className: 'buttonBaseOnly baseMain'
})`
  margin-left: 15px;
`;

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TableCellContent = styled.div`
  display: flex;
`;

export const TableCellValue = styled.div`
  flex: 1;
  width: 1px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
`;
