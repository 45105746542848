import styled from 'styled-components';

export const MenuHeaderContainer = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;
  position: sticky;
  z-index: 2;
`;

const getBorderMenuContent = (active, color = '#00000061') => {
  return active ? '1px solid var(--cm-primary)' : `1px solid ${color}`;
};

const getBoxShadowChild1 = (active) => {
  return active ? '15px 7px 10px -4px rgba(0, 0, 0, 0.25)' : 'none';
};

const getBoxShadowChild2 = (active) => {
  return active ? '-15px 7px 10px -4px rgba(0, 0, 0, 0.25)' : 'none';
};

export const MenuContent = styled.div`
  width: 50%;
  height: 48px;
  margin-bottom: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-top: ${({ active }) => getBorderMenuContent(active)};
  border-left: ${({ active }) => getBorderMenuContent(active)};
  border-right: ${({ active }) => getBorderMenuContent(active)};
  border-bottom: ${({ active }) => getBorderMenuContent(!active, 'white')};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #FCFCFC;

  font-weight: ${({ active }) => active ? 'bold' : 'normal'};
  color: ${({ active }) => active ? 'var(--cm-primary)' : '#00000061'};
  cursor: ${({ active }) => active ? 'default' : 'pointer'};

  &:nth-child(1) {
    box-shadow: ${({ active }) => getBoxShadowChild1(active)};
    clip-path: inset(0px -15px 0px 0px round 0 15px 0 0);
    z-index: ${({ active }) => active ? 3 : 2};
  }

  &:nth-child(2) {
    box-shadow: ${({ active }) => getBoxShadowChild2(active)};
    clip-path: inset(0px 0px 0px -15px round 15px 0 0 0);
    z-index: ${({ active }) => active ? 3 : 2};
  }
`;

export const ClientContainer = styled.div`
  border: 1px solid var(--cm-primary);
  border-top: none;
`;
