import styled from 'styled-components';
import { FaRegTrashAlt } from 'react-icons/fa';
import { FiPlusCircle } from 'react-icons/fi';

export const ContainerButtonNewPayment = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerPayments = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -5px;
`;

export const ContainerInputDeleteRow = styled.div`
  display: flex;
  align-items: center;
`;

export const AddIcon = styled(FiPlusCircle)`
  color: var(--cm-primary);
  width: 19px;
  height: 19px;
  cursor: pointer;
`;

export const DeleteIcon = styled(FaRegTrashAlt)`
  color: var(--cm-primary);
  width: 24px;
  height: 24px;
  margin-bottom: 6px;
  margin-left: 20px;
  cursor: pointer;
`;

export const ContainerError = styled.small`
  color: red;
  margin: -5px 0 5px 0;
`;

export const ContainerButton = styled.div`
  display: flex;
  justify-content: center;
  border: 1px solid var(--cm-primary);
  border-radius: 8px;
  padding: 8px;
  margin: 0 40px 10px 0;
  color: var(--cm-primary);
  width: 133px;
  height: auto;
  cursor: pointer;

  span {
    font-size: 10pt;
    font-weight: bold;
    margin-left: 10px;
  }
`;

