import { Fragment } from 'react';
import styled from 'styled-components';

export const FormRequiredContainer = Fragment;
export const CheckmarkContainer = Fragment;

export const HandleHoverAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 8px;

  svg {
    width: 24px;
    height: 24px;
    color: #0000008A;
    cursor: pointer;
    margin-right: 0;
  }

  :hover {
    background-color: #E5E5E5;
  }

  :nth-child(1) {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  + div {
    margin-left: 2px;
    margin-right: 10px;
  }
`;
