import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useWindowDimensions } from '~/hook/useWindowDimensions';
import { MQL_MATCH_MEDIA } from '~/styles';
import * as S from './styles';

function ButtonSelect({ renderIcon, children, visible }) {
  const { width: DIMENSION_WIDTH_PAGE_WEB } = useWindowDimensions();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleZIndex = (zIndezDefault = 1300) => {
    const element = document.getElementById('simple-popover');
    if (element) { element.style.zIndex = zIndezDefault; }
  };

  const handleClick = (event) => {
    if (visible) {
      handleZIndex();
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (open && (MQL_MATCH_MEDIA <= DIMENSION_WIDTH_PAGE_WEB)) {
      setAnchorEl(null);
    }
  }, [open, DIMENSION_WIDTH_PAGE_WEB]);

  return (
    <S.ModalPropoverContainer visible={visible}>
      <div onClick={handleClick}>
        {renderIcon}
      </div>
      <S.ModalPropover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onMouseUp={() => handleZIndex(-1)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ top: 30 }}
      >
        {children}
      </S.ModalPropover>
    </S.ModalPropoverContainer>
  );
}

ButtonSelect.propTypes = {
  renderIcon: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
  visible: PropTypes.bool,
};

export { ButtonSelect };
