/* eslint-disable indent */
import PropTypes from 'prop-types';
import React from 'react';
import moment from 'moment';
import FileBase64 from 'react-file-base64';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Modal from '~/components/Modal';
import { InputPhoneMask } from '~/components/FieldPhone';
import { AutoCompleteOn } from '~/components/AutoCompleteOn';
import PersonEmployeeNew from '~/model/person-employee-new';
import { person } from '~/routes/paths';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import permissions from '~/util/permissions';
import { AdminGroupAccessService } from '~/services/admin-group-access-service';
import PersonResaleService from '~/services/person-resale-service';
import AccessGroupService from '~/services/access-group';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import {
  UNIDADES_FEDERATIVAS,
  PERSON_STATUS_ENUM,
} from '~/util/domainutils';
import {
  validaCpf,
  validateEmail,
  unFormatCpfCnpj,
  validePhone,
  formatCpfCnpj,
  onlyText,
  unFormatPunctuation,
} from '~/util/stringutils';
import { SUPORT_FILES } from '~/util/fileutils';
import { ImageContent } from './styles';

class AddPersonEmployee extends React.Component {
  static propTypes = {
    history: PropTypes.shape({
      push: PropTypes.func,
    }).isRequired,
    user: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    const { history } = props;
    this.history = history;
    this.accessGroups = [];
    this.user = props.user;

    this.userCanEditChangeEmployee = props.permissions
      .includes(permissions['edit_mudanca-colaborador-revenda']);

    this.refTelefone1 = React.createRef();
    this.refTelefone2 = React.createRef();
    this.refEmail = React.createRef();

    this.state = {
      showSuggestions: false,
      timeout: null,
      loading: true,
      idRevenda: 0,
      inputResaleValue: '',
      resales: [],
      resale: {},
      imageName: '',
      person: new PersonEmployeeNew({}),

      // errors
      error_grupoId: false,
      error_revendaId: false,
      error_cpfCnpj: false,
      error_razaoSocial: false,
      error_telefone1: false,
      error_telefone2: false,
      error_email: false,

      // errors message
      error_revendaId_message: 'Campo obrigatório',
      error_cpfCnpj_message: 'Campo obrigatório',
      error_telefone1_message: 'Campo obrigatório',
      error_telefone2_message: 'Campo obrigatório',
      error_email_message: 'Campo obrigatório',
    };
  }

  /* LifeCicle:: Inicializa quando monta a DOM */
  componentDidMount() {
    Promise.all([
      AdminGroupAccessService.getAccessGroup(''),
      AdminGroupAccessService.getGroup(this.user.grupo.id),
      PersonResaleService.getPersonResales({
        cpfCnpj: this.user.revenda.cpfCnpj,
      }),
    ])
      .then(values => {
        if (values.length) {
          const [allGroups, group, userResale] = values;

          if (allGroups.resultado.length) {
            const groupsIds = [...allGroups.resultado.map((_) => _.id)];
            const groups = [...allGroups.resultado];

            if ((!!group) && !groupsIds.includes(group.id)) {
              groups.push(group);
            }

            this.accessGroups.push(...groups);
          }

          if (userResale.resultado.length) {
            const resale = userResale.resultado[0];

            this.handleVerifyRevenda(resale);
            this.setRevendaId(resale.id);
          }
        }
      })
      .catch(() => {
        toast('Erro ao carregar informações.', { type: toast.TYPE.ERROR });
        setTimeout(() => {
          this.history.push(person.personEmployee);
        }, 100);
      })
      .finally(() => this.setLoading(false));
  }

  setInputValue = b => this.setState({ inputResaleValue: b });

  setIdRevenda = b => {
    this.setRevendaId(b);
    this.setState({ idRevenda: b });
  }

  setLoading = b => this.setState({ loading: b });

  setSelectGrupo = b => {
    const person = this.state.person;
    person.grupoId = b;
    this.setState({ person: person });
  };

  setRevendaId = b => {
    const person = this.state.person;
    person.revendaId = b;
    this.setState({ person: person });
  };

  setInputCpfCnpj = b => {
    const person = this.state.person;
    person.cpfCnpj = b;
    this.setState({ person: person });
  };

  setInputNomeSocial = b => {
    const person = this.state.person;
    person.nomeSocial = b;
    this.setState({ person: person });
  };

  setSelectEstado = b => {
    const person = this.state.person;
    person.estado = b;
    this.setState({ person: person });
  };

  setInputCidade = b => {
    const person = this.state.person;
    person.cidade = b;
    this.setState({ person: person });
  };

  setInputCep = b => {
    const person = this.state.person;
    person.cep = b;
    this.setState({ person: person });
  };

  setInputBairro = b => {
    const person = this.state.person;
    person.bairro = b;
    this.setState({ person: person });
  };

  setInputEndereco = b => {
    const person = this.state.person;
    person.endereco = b;
    this.setState({ person: person });
  };

  setInputComplemento = b => {
    const person = this.state.person;
    person.complemento = b;
    this.setState({ person: person });
  };

  setInputPontoReferencia = b => {
    const person = this.state.person;
    person.pontoReferencia = b;
    this.setState({ person: person });
  };

  setInputTelefone1 = b => {
    const person = this.state.person;
    person.telefone1 = b;
    this.setState({ person: person });
  };

  setInputTelefone2 = b => {
    const person = this.state.person;
    person.telefone2 = b;
    this.setState({ person: person });
  };

  setInputEmail = b => {
    const person = this.state.person;
    person.email = b.toLowerCase();
    this.setState({ person: person });
  };

  setInputImagem = b => {
    if (b.type && SUPORT_FILES.IMAGES.includes(b.type)) {
      const { person } = this.state;
      person.imagem = b.base64;
      this.setState({ person: person, imageName: b.name });
    } else {
      toast.error('Tipo de arquivo não é suportado');
    }
  };

  handleSelectGrupoChange = event => {
    const {
      target: { value },
    } = event;
    this.setSelectGrupo(value);
    this.handleErrorsGrupoId(false);
  };

  handleInputCpfCnpjChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCpfCnpj(value);
    this.handleErrorsCpfCnpj(false, 'Campo obrigatório');
  };

  handleInputNomeSocialChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputNomeSocial(value);
    this.handleErrorsNomeSocial(false);
  };

  handleSelectEstadoChange = event => {
    const {
      target: { value },
    } = event;
    this.setSelectEstado(value);
  };

  handleInputCidadeChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCidade(value);
  };

  handleInputCepChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputCep(value);
  };

  handleInputBairroChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputBairro(value);
  };

  handleInputEnderecoChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputEndereco(value);
  };

  handleInputComplementoChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputComplemento(value);
  };

  handleInputPontoReferenciaChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputPontoReferencia(value);
  };

  handleInputTelefone1Change = event => {
    const {
      target: { value },
    } = event;
    this.setInputTelefone1(value);
    this.handleErrorsTelefone1(false, 'Digite um número válido');
  };

  handleInputTelefone2Change = event => {
    const {
      target: { value },
    } = event;
    this.setInputTelefone2(value);
    this.handleErrorsTelefone2(false, 'Digite um número válido');
  };

  handleInputEmailChange = event => {
    const {
      target: { value },
    } = event;
    this.setInputEmail(value);
    this.handleErrorsEmail(false, 'Campo obrigatório');
  };

  handleInputStatusColaboradorChange = event => {
    const {
      target: { value },
    } = event;

    this.setState(oldState => ({
      person: { ...oldState.person, status: value },
    }));
  };

  handleSelectedStatusOutline(field, value) {
    return this.state.person[field] === PERSON_STATUS_ENUM[value].value
      ? 'checkradio_outline' : '';
  }

  handleErrorsGrupoId = (error_grupoId) => {
    this.setState({ error_grupoId });
  }

  handleErrorsRevendaId = (error_revendaId) => {
    this.setState({ error_revendaId });
  }

  handleErrorsCpfCnpj = (error_cpfCnpj, error_cpfCnpj_message) => {
    this.setState({ error_cpfCnpj, error_cpfCnpj_message });
  }

  handleErrorsNomeSocial = (error_razaoSocial) => {
    this.setState({ error_razaoSocial });
  }

  handleErrorsTelefone1 = (error_telefone1, error_telefone1_message) => {
    this.setState({ error_telefone1, error_telefone1_message });
  }

  handleErrorsTelefone2 = (error_telefone2, error_telefone2_message) => {
    this.setState({ error_telefone2, error_telefone2_message });
  }

  handleErrorsEmail = (error_email, error_email_message) => {
    this.setState({ error_email, error_email_message });
  }

  handleErrorsFocus = (focus) => {
    switch (focus) {
      case 'telefone1':
        this.refTelefone1.current.focus();
        return;
      case 'telefone2':
        this.refTelefone2.current.focus();
        return;
      case 'email':
        this.refEmail.current.focus();
        return;
      default:
        return;
    }
  }

  validateForm(success) {
    let errors_temp = false;
    let cpfCnpj_temp = false;
    let email_temp = false;
    let focus = '';

    const formData = new PersonEmployeeNew(this.state.person);

    if (!formData.grupoId) {
      this.handleErrorsGrupoId(true);
      errors_temp = true;
      if (focus === '') focus = 'grupoId';
    }

    if (!formData.revendaId && this.userCanEditChangeEmployee) {
      this.handleErrorsRevendaId(true);
      errors_temp = true;
      if (focus === '') focus = 'revendaId';
    }

    if (!formData.cpfCnpj || !formData.cpfCnpj.length) {
      this.handleErrorsCpfCnpj(true, 'Campo obrigatório');
      errors_temp = true;
      cpfCnpj_temp = true;
      if (focus === '') focus = 'cfpCnpj';
    }

    if (!cpfCnpj_temp) {
      formData.cpfCnpj = unFormatCpfCnpj(formData.cpfCnpj);
      if (!validaCpf(formData.cpfCnpj)) {
        this.handleErrorsCpfCnpj(true, 'Digite um CPF válido');
        errors_temp = true;
        if (focus === '') focus = 'cfpCnpj';
      }
      cpfCnpj_temp = false;
    }

    if (!formData.nomeSocial || !formData.nomeSocial.length) {
      this.handleErrorsNomeSocial(true);
      errors_temp = true;
      if (focus === '') focus = 'nomeSocial';
    }

    if (formData.telefone1 && !validePhone(formData.telefone1)) {
      this.handleErrorsTelefone1(true, 'Digite um número válido');
      errors_temp = true;
      if (focus === '') focus = 'telefone1';
    }

    if (formData.telefone2 && !validePhone(formData.telefone2)) {
      this.handleErrorsTelefone2(true, 'Digite um número válido');
      errors_temp = true;
      if (focus === '') focus = 'telefone2';
    }

    if (!formData.email || !formData.email.length) {
      this.handleErrorsEmail(true, 'Campo obrigatório');
      errors_temp = true;
      email_temp = true;
      if (focus === '') focus = 'email';
    }

    if (!email_temp) {
      if (!validateEmail(formData.email)) {
        this.handleErrorsEmail(true, 'Digite um Email válido');
        errors_temp = true;
        if (focus === '') focus = 'email';
      }
      email_temp = false;
    }

    if (formData.dataCadastro) {
      formData.dataCadastro = moment(formData.dataCadastro).format('DD/MM/YYYY');
    }

    if (formData.dataFundacao) {
      formData.dataFundacao = moment(formData.dataFundacao).format('DD/MM/YYYY');
    }

    if (errors_temp) {
      this.handleErrorsFocus(focus);
      return;
    }

    if (formData.telefone1) {
      formData.telefone1 = unFormatPunctuation(formData.telefone1.trim());
    }

    if (formData.telefone2) {
      formData.telefone2 = unFormatPunctuation(formData.telefone2.trim());
    }

    if (formData.nomeSocial)
      formData.nomeSocial = formData.nomeSocial.trim();

    if (formData.cidade)
      formData.cidade = formData.cidade.trim();

    if (formData.cep)
      formData.cep = formData.cep.trim();

    if (formData.bairro)
      formData.bairro = formData.bairro.trim();

    if (formData.endereco)
      formData.endereco = formData.endereco.trim();

    if (formData.complemento)
      formData.complemento = formData.complemento.trim();

    if (formData.pontoReferencia)
      formData.pontoReferencia = formData.pontoReferencia.trim();

    success(formData);
  }

  handleSubmit = async event => {
    event.preventDefault();
    this.validateForm(async formData => {
      this.setLoading(true);
      try {
        const { data } = await api.post(
          endpoints.person.employee.createPersonEmployee,
          formData
        );
        toast(data.message, { type: toast.TYPE.SUCCESS });
        setTimeout(() => {
          this.history.push(person.personEmployee);
        }, 100);
      } catch (error) {
        showMessageError(error);
      } finally {
        this.setLoading(false);
      }
    });
  };

  handleVerifyRevenda = resale => {
    this.setState({ resale });
    this.setIdRevenda(resale.id);

    this.setInputValue(
      this.renderSuggestion(resale),
    );

    this.setLoading(true);
  };

  handleAccessGroupResale = resale => {
    Promise.all([
      AccessGroupService.getGroup(resale.grupoId),
      AccessGroupService.getAccessGroup(null, { usuarioId: resale.usuarioId })
    ]).then((values) => {
      if (values.length) {
        const accessGroupsResale = [];
        const idsAdded = [values[0].id];
        accessGroupsResale.push(values[0]);

        if (values[1].resultado) {
          values[1].resultado.forEach(group => {
            if (!idsAdded.includes(group.id))
              accessGroupsResale.push(group);
          });
        }

        this.accessGroups = [];
        this.accessGroups.push(...accessGroupsResale);
      }
    })
      .catch(() => {
        toast('Erro ao carregar grupos de acesso', {
          type: toast.TYPE.ERROR,
        });
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  getResales = async (value) => {
    try {
      const data = await PersonResaleService.getPersonResales({
        cpfCnpj: unFormatCpfCnpj(value.trim().toLowerCase()) || null,
        nome: onlyText(value.trim().toLowerCase()) || null,
        offset: 0,
        limit: 0,
      });

      return data.resultado;
    } catch (error) {
      showMessageError(error);

      return [];
    }
  }

  getResalesChange = async (event) => {
    const {
      target: { value: valueToFind }
    } = event;

    if (valueToFind === '') {
      this.setState({
        resales: [],
        showSuggestions: false,
      });

      this.setRevendaId(undefined);
    } else {
      clearTimeout(this.state.timeout);

      const timer = setTimeout(() => {
        const asyncSetTimeOut = async () => {
          const resales = await this.getResales(valueToFind);
          if (resales.length) {
            this.setState({
              resales,
              showSuggestions: true,
            });
          } else {
            this.setState({
              resales: [],
              showSuggestions: true,
            });
          }
        };

        asyncSetTimeOut();
      }, 500); // 0.5 segundos

      this.setState({ timeout: timer });
    }

    this.setInputValue(valueToFind);
  };

  getSuggestionLabel = suggestion => {
    if (suggestion) {
      return this.renderSuggestion(suggestion);
    } else {
      return '';
    }
  }

  getSuggestionValue = suggestion => {
    if (suggestion) {
      return suggestion;
    } else {
      return '';
    }
  }

  getSuggestionKey = suggestion => {
    if (suggestion) {
      return suggestion.id;
    } else {
      return '';
    }
  }

  renderSuggestion = suggestion => {
    return `${formatCpfCnpj(suggestion.cpfCnpj)} - ${suggestion.nomeFantasia}`;
  }

  handleOnClick = suggestion => {
    const [resaleSelected] = this.state.resales.filter(
      suggItem => suggItem.id === suggestion.id
    );

    this.handleVerifyRevenda(resaleSelected);
    this.handleAccessGroupResale(resaleSelected);
    this.handleErrorsRevendaId(false);

    this.setState({ showSuggestions: false });
  }

  render() {
    const {
      showSuggestions,
      loading,
      inputResaleValue,
      resales,
      imageName,
    } = this.state;

    const {
      error_cpfCnpj,
      error_cpfCnpj_message,
      error_email,
      error_email_message,
      error_grupoId,
      error_razaoSocial,
      error_revendaId,
      error_revendaId_message,
      error_telefone1,
      error_telefone1_message,
      error_telefone2,
      error_telefone2_message,
    } = this.state;

    return (
      <div className="defaultFormContainer">
        <Modal show={loading} loading />
        <SidebarPageHeader
          mainMenu="Pessoa"
          pageName="Novo Colaborador"
          breadcrumbs={[{
            label: 'Colaborador',
            link: person.personEmployee,
          }]}
          button1={{
            label: 'Salvar',
            onClick: () => this.handleSubmit,
            main: true,
          }}
          openButtonSelect
          buttonCancelHandler={() => this.history.goBack()}
        />
        <form className="defaultForm" onSubmit={this.handleSubmit}>
          <div className="infoCard">
            <span id="title">Grupo</span>
            <div className="rowContainer">
              <div className="inputHolder marginRightX4">
                <span>Grupo de Acesso *</span>
                <select
                  name="grupoId"
                  value={this.state.person.grupoId}
                  onChange={this.handleSelectGrupoChange}
                >
                  <option value="">Selecione</option>
                  {this.accessGroups.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.nome}
                    </option>
                  ))}
                </select>
                {error_grupoId && (
                  <small style={{ color: 'red' }}>
                    Campo obrigatório
                  </small>
                )}
              </div>
              <div className="inputHolder">
                <span>Status do Colaborador *</span>
                <div className="rowContainer">
                  <div className="radioHolder">
                    <div className="inputBoxHolder">
                      <input
                        type="radio"
                        name="status"
                        value={PERSON_STATUS_ENUM.NOT_BLOCKED.value}
                        onChange={this.handleInputStatusColaboradorChange}
                      />
                      <span className={
                        `checkradio ${this.handleSelectedStatusOutline(
                          'status', PERSON_STATUS_ENUM.NOT_BLOCKED.key
                        )}`
                      }>
                        {this.state.person.status ===
                          PERSON_STATUS_ENUM.NOT_BLOCKED.value && (
                            <div className="markRadioHolder">
                              <div className="checkradio_stem" />
                            </div>
                          )}
                      </span>
                    </div>
                    <span id="radioLabel">
                      {PERSON_STATUS_ENUM.NOT_BLOCKED.label3}
                    </span>
                  </div>
                  <div className="radioHolder">
                    <div className="inputBoxHolder">
                      <input
                        type="radio"
                        name="status"
                        value={PERSON_STATUS_ENUM.BLOCKED.value}
                        onChange={this.handleInputStatusColaboradorChange}
                      />
                      <span className={
                        `checkradio ${this.handleSelectedStatusOutline(
                          'status', PERSON_STATUS_ENUM.BLOCKED.key
                        )}`
                      }>
                        {this.state.person.status ===
                          PERSON_STATUS_ENUM.BLOCKED.value && (
                            <div className="markRadioHolder">
                              <div className="checkradio_stem" />
                            </div>
                          )}
                      </span>
                    </div>
                    <span id="radioLabel">
                      {PERSON_STATUS_ENUM.BLOCKED.label3}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.userCanEditChangeEmployee && (
            <div className="infoCard">
              <span id="title">Revenda</span>
              <div className="rowContainer">
                <div className="inputHolder defaultFlex noMarginRight">
                  <span>De qual revenda? *</span>
                  <span id="helpText">
                    Ao digitar, o campo irá auto completar. A busca da revenda
                    poderá ser feita pelo CNPJ ou Nome Fantasia.
                  </span>
                  <AutoCompleteOn
                    inputDataValue={inputResaleValue}
                    suggestions={resales}
                    isShowSuggestions={showSuggestions}
                    handleDataChange={this.getResalesChange}
                    handleGetSuggestionKey={this.getSuggestionKey}
                    handleGetSuggestionLabel={this.getSuggestionLabel}
                    handleOnClick={this.handleOnClick}
                    isDisabled={!this.userCanEditChangeEmployee}
                  />
                  {error_revendaId && (
                    <small style={{ color: 'red' }}>
                      {error_revendaId_message}
                    </small>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="infoCard">
            <span id="title">Dados Gerais</span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>CPF *</span>
                <InputMask
                  mask="999.999.999-99"
                  name="cpfCnpj"
                  value={this.state.person.cpfCnpj}
                  onChange={this.handleInputCpfCnpjChange}
                />
                {error_cpfCnpj && (
                  <small style={{ color: 'red' }}>
                    {error_cpfCnpj_message}
                  </small>
                )}
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Nome Completo *</span>
                <input
                  type="text"
                  name="razaoSocial"
                  value={this.state.person.nomeSocial}
                  onChange={this.handleInputNomeSocialChange}
                />
                {error_razaoSocial && (
                  <small style={{ color: 'red' }}>Campo obrigatório</small>
                )}
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Endereço</span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>Estado</span>
                <select
                  name="estado"
                  value={this.state.person.estado}
                  onChange={this.handleSelectEstadoChange}
                >
                  <option value={''}>Selecione</option>
                  {UNIDADES_FEDERATIVAS.map(uf => (
                    <option key={uf.sigla} value={uf.nome}>
                      {uf.nome}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inputHolder">
                <span>Cidade</span>
                <input
                  type="text"
                  name="cidade"
                  value={this.state.person.cidade}
                  onChange={this.handleInputCidadeChange}
                />
              </div>
              <div className="inputHolder">
                <span>CEP</span>
                <InputMask
                  mask="99.999-999"
                  name="cep"
                  value={this.state.person.cep}
                  onChange={this.handleInputCepChange}
                />
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Bairro</span>
                <input
                  type="text"
                  name="bairro"
                  value={this.state.person.bairro}
                  onChange={this.handleInputBairroChange}
                />
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex">
                <span>Endereço Completo</span>
                <input
                  type="text"
                  name="endereco"
                  value={this.state.person.endereco}
                  onChange={this.handleInputEnderecoChange}
                />
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Complemento</span>
                <input
                  type="text"
                  name="complemento"
                  value={this.state.person.complemento}
                  onChange={this.handleInputComplementoChange}
                />
              </div>
            </div>
            <div className="rowContainer">
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Ponto de Referência</span>
                <input
                  type="text"
                  name="pontoReferencia"
                  value={this.state.person.pontoReferencia}
                  onChange={this.handleInputPontoReferenciaChange}
                />
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Contato</span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>Telefone(1)</span>
                <InputPhoneMask
                  type="text"
                  name="telefone1"
                  ref={this.refTelefone1}
                  value={this.state.person.telefone1}
                  onChange={this.handleInputTelefone1Change}
                />
                {error_telefone1 && (
                  <small style={{ color: 'red' }}>
                    {error_telefone1_message}
                  </small>
                )}
              </div>
              <div className="inputHolder">
                <span>Telefone(2)</span>
                <InputPhoneMask
                  type="text"
                  name="telefone2"
                  ref={this.refTelefone2}
                  value={this.state.person.telefone2}
                  onChange={this.handleInputTelefone2Change}
                />
                {error_telefone2 && (
                  <small style={{ color: 'red' }}>
                    {error_telefone2_message}
                  </small>
                )}
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>E-mail *</span>
                <input
                  ref={this.refEmail}
                  type="email"
                  name="email"
                  value={this.state.person.email}
                  onChange={this.handleInputEmailChange}
                />
                {error_email && (
                  <small style={{ color: 'red' }}>
                    {error_email_message}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="infoCard">
            <span id="title">Outros</span>
            <div className="rowContainer">
              <div className="inputHolder">
                <span>Data de Cadastro</span>
                <input
                  readOnly
                  disabled
                  type="date"
                  name="dataCadastro"
                  value={this.state.person.dataCadastro}
                />
              </div>
              <div className="inputHolder defaultFlex noMarginRight">
                <span>Foto do Colaborador</span>
                <ImageContent>
                  <div className="imagePicker">
                    <div className="buttonHolder">
                      <span>ESCOLHER IMAGEM</span>
                      <FileBase64
                        multiple={false}
                        onDone={this.setInputImagem.bind(this)}
                      />
                    </div>
                    <label>
                      {' '}
                      {imageName || 'Selecione um arquivo de imagem'}{' '}
                    </label>
                  </div>
                </ImageContent>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const getUser = state => state.user.data;
const getPermissions = state => state.user.data.permissions;

export default connect(state => ({
  user: getUser(state),
  permissions: getPermissions(state),
}))(AddPersonEmployee);
