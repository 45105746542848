import styled from 'styled-components';

export const LabelIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: var(--cm-primary);
    cursor: pointer;
    margin-left: 6px;
  }
`;
