import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

function ModuleForm({
  handleSubmit,
  serie,
  motivosCancelamento,
  modulos,
  software,
}) {
  const [subMotivosCancelamento, setSubMotivosCancelamento] = useState([]);

  const [moduleSoftware, setModuleSoftware] = useState({
    id: '',
    codExternoModulo: null,
    serie,
    quantidade: 1,
    nomeModulo: '',
    motivoCancelamentoId: null,
    submotivoCancelamentoId: null,
  });

  const handleModuloSoftwareChange = e => {
    if (e.target.value)
      setModuleSoftware({
        ...moduleSoftware,
        serie,
        codExternoModulo: modulos[e.target.value].codExternoModulo,
        nomeModulo: modulos[e.target.value].nomeModulo,
      });
    else {
      setModuleSoftware({
        ...moduleSoftware,
        serie,
        codExternoModulo: null,
        nomeModulo: '',
      });
    }
  };

  function handleSelectMotivoCancelamento(event) {
    const {
      target: { value },
    } = event;

    if (value) {
      setModuleSoftware({
        ...moduleSoftware,
        serie,
        motivoCancelamentoId: value,
        submotivoCancelamentoId: null,
      });
      setSubMotivosCancelamento(
        motivosCancelamento.reduce((accumulator, item) => {
          if (String(item.id) === String(value))
            return [...accumulator, ...item.submotivoCancelamentos];
          return [...accumulator];
        }, [])
      );
    } else {
      setModuleSoftware({
        ...moduleSoftware,
        serie,
        motivoCancelamentoId: null,
        submotivoCancelamentoId: null,
      });
    }
  }

  const handleSelectSubMotivoCancelamento = e => {
    setModuleSoftware({
      ...moduleSoftware,
      serie,
      submotivoCancelamentoId: e.target.value,
    });
  };

  const handleQuantidadeChange = e =>
    setModuleSoftware({
      ...moduleSoftware,
      serie,
      quantidade:
        e.target.value && Number(e.target.value) >= 0
          ? Number(e.target.value)
          : 0,
    });

  async function validateForm(success) {
    let isValid = true;
    if (!moduleSoftware.nomeModulo) {
      toast('Informe um Módulo - Software!', {
        type: toast.TYPE.ERROR,
      });
      isValid = false;
      return;
    }
    if (!moduleSoftware.motivoCancelamentoId) {
      toast('Informe um motivo de cancelamento!', {
        type: toast.TYPE.ERROR,
      });
      isValid = false;
      return;
    }
    if (!moduleSoftware.submotivoCancelamentoId) {
      moduleSoftware.submotivoCancelamentoId = null;
      toast('Informe um sub-motivo de cancelamento!', {
        type: toast.TYPE.ERROR,
      });
      isValid = false;
      return;
    }
    if (isValid) {
      success();
    }
  }

  return (
    <>
      <label id="title">Dados da Licença</label>
      <div className="rowContainer">
        <div className="inputHolder">
          <span>Série</span>
          <input disabled id="serie" type="text" name="serie" value={serie} />
        </div>
        <div className="inputHolder">
          <span>Software</span>
          <input disabled id="serie" type="text" value={software} />
        </div>
        <div className="inputHolder defaultFlex">
          <span>Módulo *</span>
          <select name="moduloSoftware" onChange={handleModuloSoftwareChange}>
            <option value="">Selecione</option>
            {modulos.map((modulo, index) => (
              <option key={index} value={index}>
                {`${modulo.nomeModulo} [${modulo.quantidade}]`}
              </option>
            ))}
          </select>
        </div>
        <div className="inputHolder noMarginRight">
          <span>Quantidade</span>
          <input
            onChange={handleQuantidadeChange}
            type="number"
            id="quantidade"
            name="quantidade"
            value={moduleSoftware.quantidade}
          />
        </div>
      </div>
      <div className="rowContainer rowAlignItemsEnd">
        <div className="inputHolder defaultFlex">
          <span>Motivo *</span>
          <select
            name="motivoCancelamento"
            onChange={handleSelectMotivoCancelamento}
          >
            <option value="">Selecione</option>
            {motivosCancelamento
              .filter(motivo => motivo.ativo)
              .map(motivo => (
                <option key={motivo.id} value={motivo.id}>
                  {motivo.descricao}
                </option>
              ))}
          </select>
        </div>
        {!!subMotivosCancelamento.length && (
          <div className="inputHolder defaultFlex">
            <span>Sub-Motivo *</span>
            <select
              name="motivoCancelamento"
              onChange={handleSelectSubMotivoCancelamento}
            >
              <option value="">Selecione</option>
              {subMotivosCancelamento
                .filter(submotivo => submotivo.ativo)
                .map(submotivo => (
                  <option key={submotivo.id} value={submotivo.id}>
                    {submotivo.descricao}
                  </option>
                ))}
            </select>
          </div>
        )}
        <button
          type="button"
          className="buttonBaseOnly"
          onClick={() => {
            validateForm(() => handleSubmit(moduleSoftware));
          }}
          style={{
            margin: '5px 0 10px 0'
          }}
        >
          <span>ADICIONAR</span>
        </button>
      </div>
    </>
  );
}

ModuleForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  serie: PropTypes.string.isRequired,
  motivosCancelamento: PropTypes.array.isRequired,
  modulos: PropTypes.array.isRequired,
  software: PropTypes.any,
};

export default ModuleForm;
