import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { person } from '~/routes/paths';
import Modal from '~/components/Modal';
import { accessDenied } from '~/util/accessDenied';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import SimpleTextSearch from '~/components/SimpleTextSearch';
import TableComponent from '~/components/TableComponent';
import { LineBusinessService } from '~/services/person/linebusiness';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import PrintableComponent from '~/components/PrintableComponent';
import { useFitler } from '~/hook/useFilter';
import './styles.scss';

export default function AdminLineBusiness({ history }) {
  const { filters, setFilters } = useFitler();
  const [inputValue, setInputValue] = useState(filters?.inputValue ? filters?.inputValue : '');
  const [searchResponse, setSearchResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });

  let PrintableElement = React.useRef();

  useEffect(() => {
    findAllByFilter(pageParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions['create_ramo-atividade']),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions['edit_ramo-atividade']),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions['delete_ramo-atividade']),
    [userPermissions]
  );

  function handleChange(event) {
    const {
      target: { value },
    } = event;
    setInputValue(value);
  }

  async function findAllByFilterReport() {
    setLoading(true);
    try {
      if (inputValue.length) { setCountFilter(1); } else { setCountFilter(0); }
      const result = await LineBusinessService
        .getLineBusiness(inputValue.trim());

      if (result.resultado.length) {
        setSearchResponseReport(result.resultado);
      } else {
        toast('Não encontramos nenhum Ramo de Atividade!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      if (inputValue.length) { setCountFilter(1); } else { setCountFilter(0); }
      const { page, rowsPerPage } = pageParams;
      const result = await LineBusinessService
        .getLineBusiness(inputValue.trim(), {
          limit: page * rowsPerPage + rowsPerPage,
          offset: page * rowsPerPage,
        });

      if (result.resultado.length) {
        setTotalItems(result.total);
        setSearchResponse(result.resultado);
      } else {
        toast('Não encontramos nenhum Ramo de Atividade!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setFilters({ inputValue });

    await findAllByFilter(pageParameters);
  }

  async function handleDelete() {
    try {
      setLoading(true);
      const result = await LineBusinessService
        .deleteLineBusiness(deleteItem.id);

      if (!result.data.ok)
        toast('Erro ao deletar o Ramo!', {
          type: toast.TYPE.ERROR,
        });
      else {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteItem.id
        );
        setSearchResponse(newSearchResponse);
        toast(result.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o Ramo!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Ramo de Atividade"
        handlePageUpdate={findAllByFilterReport}
        filters={[{ name: 'Classificação', value: inputValue }]}
        headerLabels={[{ text: 'Ramo de Atividade' }]}
        fieldsArray={['nome']}
        dataObjects={searchResponseReport}
      />
      <div className="lineBusinessContainer">
        <Modal loading={loading} show={loading || !!deleteItem}>
          <div className="modalContainer">
            <h2>Confirmar exclusão</h2>
            <span>
              Tem certeza que deseja deletar o item
              <span id="elementName">{` ${!!deleteItem &&
                deleteItem.nome}`}</span>
              ?
            </span>
            <div>
              <button
                className="cancelButton buttonBaseOnly"
                onClick={() => setDeleteItem(null)}
              >
                Cancelar
              </button>
              <button
                className="buttonBaseOnly baseMain"
                onClick={handleDelete}
              >
                Deletar
              </button>
            </div>
          </div>
        </Modal>
        <SidebarPageHeader
          mainMenu="Pessoa"
          pageName="Ramo de Atividade"
          button1={{
            label: 'Novo Ramo',
            link: person.addLineBusiness,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await findAllByFilterReport(pageParameters);
          }}
          openButtonSelect
        />
        <SimpleTextSearch
          countFilter={countFilter}
          fieldLabel="Atividade"
          inputValue={inputValue}
          handleInputChange={handleChange}
          handleSubmit={handleSubmit}
        />
        <TableComponent
          headerLabels={[{ text: 'Ramo de Atividade' }]}
          dataObjects={searchResponse}
          fieldsArray={['nome']}
          editPermission={!userCanEdit}
          handleEdit={row => {
            history.push(person.editLineBusiness, {
              id: row.id,
              name: row.nome,
            });
          }}
          deletePermission={!userCanDelete}
          handleDelete={userCanDelete
            ? (row) => setDeleteItem(row)
            : () => accessDenied(history)
          }
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
        />
      </div>
    </>
  );
}

AdminLineBusiness.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
