import styled from 'styled-components';

export const FormsContainer = styled.div`
  display: flex;
  height: 50px;
  background-color: #ffffff;
  width: ${(props) => `${props.width}px`};
  border-radius: 8px;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--cm-primary);
  margin: 20px 20px 0 0;

  span {
    width: 150px;
    max-width: 90%;
    display: -webkit-box;
    overflow: hidden; /* remove o estouro do elemento */
    text-overflow: ellipsis; /* adiciona “...” no final */
    -webkit-line-clamp: 2; /* quantidade de linhas */
    -webkit-box-orient: vertical; /* conteúdo será apresentado verticalmente */
  }
`;

export const Tooltip = styled.div``;
