import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Label = styled.label`
  color: var(--cm-primary);
  font-size: 12pt;
`;

export const Input = styled(InputMask)`
  height: 30px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 0 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  &::placeholder {
    color: #999;
  }
`;

export const Error = styled.small`
  color: red;
`;
