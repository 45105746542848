import { ErrorMessage } from '@hookform/error-message';
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import {
  Label, LabelHorizontal, Input, InputWithLabelHorizontal, Error,
} from './styles';

function Field(props) {
  const { control, name, labelHorizontal, label, errors, ...rest } = props;

  const { field } = useController({
    name,
    control,
  });

  return (
    <>
      {label && (
        <Label htmlFor={name} style={{ paddingRight: '10px' }}>
          {label}
        </Label>
      )}
      <>
        {labelHorizontal ? (
          <InputWithLabelHorizontal>
            <Input id={name} {...field} {...rest} style={{ marginRight: 6 }} />
            <LabelHorizontal htmlFor={name} style={{ paddingRight: '10px' }}>
              {labelHorizontal}
            </LabelHorizontal>
          </InputWithLabelHorizontal>
        ) : (
          <Input id={name} {...field} {...rest} />
        )}
      </>
      {errors && <ErrorMessage as={Error} errors={errors} name={name} />}
    </>
  );
}

Field.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  labelHorizontal: PropTypes.string,
};

export default Field;
