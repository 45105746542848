import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Sidebar from 'react-sidebar';
import SidebarHeader from '~/components/SidebarHeader';
import SidebarContent from '~/components/SidebarContent';
import { useSidebar } from '~/contexts/sidebar';
import * as S from './styles';

function SidebarPageBody({ children }) {
  const {
    sidebarDocked,
    sidebarOpen,
    onSetSidebarOpen,
    collapseSidebarProfile,
    onHandleMouseOver,
    onHandleMouseLeave,
  } = useSidebar();

  const [profileOpen, setProfileOpen] = useState(false);

  const onSetOpenProfile = () => setProfileOpen((oldState) => !oldState);

  function getMarginTopSidebar() {
    // with notifications = ? 279 : 202
    return profileOpen && collapseSidebarProfile ? 220 : 146;
  }

  function getMarginLeftSidebar() {
    return sidebarOpen ? 75 : 0;
  }

  const getSidebarStylesDefault = () => ({
    content: {
      marginTop: 75,
      position: 'sticky',
      height: 'calc(100vh - 75px)',
      marginLeft: getMarginLeftSidebar(),
    },
    sidebar: {
      paddingTop: getMarginTopSidebar(),
      zIndex: collapseSidebarProfile ? 3 : 0,
      boxShadow: collapseSidebarProfile
        ? `
          0px 8px 10px -5px rgba(0, 0, 0, 0.2),
          0px 16px 24px 2px rgba(0, 0, 0, 0.14),
          0px 6px 30px 5px rgba(0, 0, 0, 0.12)`
        : 'none',
    }
  });

  return (
    <S.PageBody>
      <SidebarHeader
        sidebarOpen={sidebarOpen}
        openProfile={profileOpen}
        onSetOpenProfile={onSetOpenProfile}
        onSetCloseProfile={() => setProfileOpen(false)}
        collapseSidebarProfile={collapseSidebarProfile}
        onHandleMouseOver={onHandleMouseOver}
        onHandleMouseLeave={onHandleMouseLeave}
      />
      <Sidebar
        sidebar={(
          <SidebarContent
            onSetCloseProfile={() => setProfileOpen(false)}
            collapseSidebarProfile={collapseSidebarProfile}
            onHandleMouseOver={onHandleMouseOver}
            onHandleMouseLeave={onHandleMouseLeave}
          />
        )}
        open={sidebarOpen}
        docked={sidebarDocked}
        onSetOpen={onSetSidebarOpen}
        touch={!sidebarOpen}
        transitions
        shadow={false}
        styles={getSidebarStylesDefault()}
      >
        <S.Content>
          {children}
        </S.Content>
      </Sidebar>
    </S.PageBody>
  );
}

SidebarPageBody.propTypes = {
  children: PropTypes.element.isRequired
};

export default SidebarPageBody;
