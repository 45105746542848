import styled from 'styled-components';
import BackgroundImageLogin from '~/assets/background_login.png';
import BackgroundImageMobileLogin from '~/assets/background_login_mobile.png';

export const BackgroundCover = styled.div`
  background-image: url(${BackgroundImageLogin});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;

  @media screen and (max-width: 450px) {
    background-image: url(${BackgroundImageMobileLogin});
    padding: 20px;
  }
`;
