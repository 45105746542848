/* eslint-disable react/prop-types */
import React from 'react';
import { Container } from '../styles';

export default function Adhesion({ adhesion, modality }) {
  const showTextModality = () => {
    return modality.length > 0 ? modality[0].modulo.modalidade.nome : '';
  };

  return (
    <Container>
      <div>
        <span>Plano de Pagamento:</span>
        <span>{adhesion.planoPagamento.nome}</span>
      </div>
      <div>
        <span>Meio de Pagamento:</span>
        <span>{adhesion.formaPagamento.nome}</span>
      </div>
      <div id="fullTextContainer">
        <span>Modalidade:</span>
        <span id="textContent">{showTextModality()}</span>
      </div>
    </Container>
  );
}
