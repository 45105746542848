import styled from 'styled-components';

export const ContentLog = styled.div`
  background-color: #F6F7FB;
  padding: 20px;
  border: 1px solid #C5C5D4;
  color: #444444;

  #title-log {
    font-weight: bold;
  }
`;

export const ContainerLog = styled.span`
  display: flex;
  align-items: center;
  margin-top: 6px;
  color: #444444;

  svg {
    width: 6px;
    height: 6px;
    margin-right: 10px;
  }
`;
