import api, { endpoints } from '~/services/api';

class PersonEmployeeService {
  static getPersonEmployees = async (filter) => {
    const { data: response } = await api.post(
      endpoints.person.employee.getPersonEmployees,
      filter
    );

    return response.data;
  };

  static getPersonEmployee = async (id) => {
    const { data: response } = await api.get(
      endpoints.person.employee.getPersonEmployee(id),
    );

    return response.data;
  };

  static getPersonEmployeeAutoComplete = async ({ cpfCnpj, nome }) => {
    const { data: response } = await api.post(
      endpoints.person.employee.getPersonAutoCompleteEmployee,
      { cpfCnpj, nome }
    );

    return response.data;
  };

  static updatePersonEmployeeStatus = async (id, status) => {
    const { data: response } = await api.put(
      endpoints.person.employee.updatePersonEmployeeStatus,
      { id, status }
    );

    return response;
  };
}

export default PersonEmployeeService;
