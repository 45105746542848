import styled from 'styled-components';
import BackgroundImageLogin from '~/assets/background_login.png';

export const ContainerBackground = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  background-image: url(${BackgroundImageLogin});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: var(--cm-primary);
  width: 100%;

  span {
    color: #FFFFFF;
  }

  img {
    padding: 10px;
    width: 100px;
    height: 72px;
  }

  div {
    padding: 10px;
    width: 100px;
    height: 72px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 32px 80px;

  @media screen and (max-width: 450px) {
    margin: 32px 20px;
  }

  form {
    width: 100%;
    background: transparent;
    border: none;
  }
`;

export const MarcaContentImg = styled.img`
  margin-top: -100px;
  height: 175px;
  width: 400px;
`;

export const TitleContent = styled.span`
  color: var(--cm-primary-contrast);
  font-weight: bold;
  font-size: 22px;

  @media (max-width: 900px) {
    font-size: 16px;
  }

  @media (max-width: 450px) {
    font-size: 22px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    padding: 12px 30px;
    border-radius: 8px;
    border-width: 1px;
    font-size: 16px;
    box-shadow: 0 1px 3px rgb(0, 0, 0, 0.16);
    border: 1px solid var(--cm-primary-shade);
    background-color: var(--cm-primary);

    span {
      color: var(--cm-primary-contrast);
      font-weight: bold;
    }

    @media (max-width: 450px) {
      font-size: 16px;
      padding: 8px 15px;
    }
  }
`;

export const ImageContent = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 12px;
  }
`;
