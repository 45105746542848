import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Modal from '~/components/Modal';
import { licensePaths } from '~/routes/paths';
import api, { endpoints } from '~/services/api';
import { showMessageError } from '~/util/errorutils';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import {
  formatCpfCnpj,
  inputNumber,
  validateEmail,
  validePhone,
} from '~/util/stringutils';
import PersonClientService from '~/services/person-client-service';
import SearchComponentClient from './SearchComponentClient';
import SearchComponentLicense from './SearchComponentLicense';
import SearchComponentObservation from './SearchComponentObservation';

function TotalCancellation({ history, location }) {
  const [loading, setLoading] = useState(false);
  const [responsavelNomeError, setResponsavelNomeError] = useState(false);
  const [responsavelTelefone1Error, setResponsavelTelefone1Error] = useState(false);
  const [responsavelTelefone2Error, setResponsavelTelefone2Error] = useState(false);
  const [responsavelEmailError, setResponsavelEmailError] = useState(false);
  const [motivoError, setMotivoError] = useState(false);
  const [subMotivoError, setSubMotivoError] = useState(false);
  const [telefone1ErrorMessage, setTelefone1ErrorMessage] = useState('Campo obrigatório');
  const [emailErrorMessage, setEmailErrorMessage] = useState('Campo obrigatório');
  const [observacaoErrorMessage, setObservacaoErrorMessage] = useState(false);
  const [motivosCancelamento, setMotivosCancelamento] = useState([]);
  const [subMotivosCancelamento, setSubMotivosCancelamento] = useState([]);
  const [formData, setFormData] = useState({
    cpfCnpjCliente: null,
    motivoCancelamentoId: null,
    submotivoCancelamentoId: null,
    motivoCancelamentoDescricao: '',
    subMotivoCancelamentoDescricao: '',
    numeroCaixas: '',
    numeroUsuarios: '',
    razaoSocialCliente: '',
    nomeFantasiaCliente: '',
    serie: '',
    modalidade: '',
    software: '',
    telefoneCliente: '',
    cpfCnpjRevenda: '',
    razaoSocialRevenda: '',
    nomeFantasiaRevenda: '',
    observacao: '',
    responsavelNome: '',
    responsavelTelefone1: '',
    responsavelTelefone2: '',
    responsavelEmail: '',
  });

  useEffect(() => {
    const asyncEffect = async () => {
      setLoading(true);
      try {
        const { cpfCnpjCliente } = location.state.data;
        setFormData(state => {
          return { ...state, ...location.state.data };
        });

        const [
          { resultado: [findCliente] },
          {
            data: {
              data: {
                resultado: findMotivos
              }
            }
          }
        ] = await Promise.all([
          await PersonClientService.getPersonClients({ cpfCnpj: cpfCnpjCliente }),
          await api.post(endpoints.license.getReasonCancellation),
        ]);

        setInfoResponsavel(findCliente);
        setMotivosCancelamento(findMotivos);
      } catch (error) {
        showMessageError(error);
        history.push(licensePaths.activeLicenses);
      } finally {
        setLoading(false);
      }
    };
    asyncEffect();
  }, [location.state.data, history]);

  function setInfoResponsavel(findCliente) {
    if (findCliente) {
      const responsavel = {
        responsavelNome: findCliente.responsavel,
        responsavelTelefone1: findCliente.contato.telefone1,
        responsavelTelefone2: findCliente.contato.telefone2,
        responsavelEmail: findCliente.contato.email,
      };

      setFormData(state => {
        return { ...state, ...responsavel };
      });
    }
  }

  function handleSelectMotivoCancelamento(event) {
    const {
      target: { value },
    } = event;

    setFormData({
      ...formData,
      motivoCancelamentoId: value,
      submotivoCancelamentoId: null,
    });
    setMotivoError(false);
    setSubMotivoError(false);
    setSubMotivosCancelamento(
      motivosCancelamento.reduce((accumulator, item) => {
        if (parseInt(item.id) === parseInt(value))
          return [...accumulator, ...item.submotivoCancelamentos];
        return [...accumulator];
      }, [])
    );
  }

  function handleSelectSubMotivoCancelamento(event) {
    const {
      target: { value },
    } = event;

    setFormData({ ...formData, submotivoCancelamentoId: value });
  }

  function handleObservacaoChange(event) {
    const {
      target: { value },
    } = event;

    setFormData({ ...formData, observacao: value });
  }

  function handleNumeroCaixassChange(event) {
    const {
      target: { value },
    } = event;

    setFormData({ ...formData, numeroCaixas: inputNumber(value) });
  }

  function handleNumeroUsuariosChange(event) {
    const {
      target: { value },
    } = event;

    setFormData({ ...formData, numeroUsuarios: inputNumber(value) });
  }

  function handleInputResponsavelNomeChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setFormData({ ...formData, responsavelNome: value });
    setResponsavelNomeError(false);
  }

  function handleInputResponsavelTelefone1Change(event) {
    const {
      target: {
        value,
      }
    } = event;

    setFormData({ ...formData, responsavelTelefone1: value });
    setResponsavelTelefone1Error(false);
  }

  function handleInputResponsavelTelefone2Change(event) {
    const {
      target: {
        value,
      }
    } = event;

    setFormData({ ...formData, responsavelTelefone2: value });
    setResponsavelTelefone2Error(false);
  }

  function handleInputResponsavelEmailChange(event) {
    const {
      target: {
        value,
      }
    } = event;

    setFormData({ ...formData, responsavelEmail: value });
    setResponsavelEmailError(false);
  }

  async function validateForm(success) {
    let isValid = true;
    let focus = '';

    if (!formData.motivoCancelamentoId) {
      setMotivoError(true);
      isValid = false;
    }

    if (!formData.submotivoCancelamentoId) {
      formData.submotivoCancelamentoId = null;
      setSubMotivoError(true);
      isValid = false;
    } else {
      setSubMotivoError(false);
    }

    if (!formData.responsavelNome || !formData.responsavelNome.length) {
      setResponsavelNomeError(true);
      isValid = false;
      if (focus === '') focus = 'responsavelNome';
    }

    let error_phone1 = false;
    if (!formData.responsavelTelefone1 && !formData.responsavelTelefone1.length) {
      setResponsavelTelefone1Error(true);
      isValid = false;
      error_phone1 = true;
      if (focus === '') focus = 'responsavelTelefone1';
    }

    if (!error_phone1) {
      if (!validePhone(formData.responsavelTelefone1)) {
        setResponsavelTelefone1Error(true);
        setTelefone1ErrorMessage('Digite um número válido');
        isValid = false;
        if (focus === '') focus = 'responsavelTelefone1';
      }
    }

    if (formData.responsavelTelefone2 && !validePhone(formData.responsavelTelefone2)) {
      setResponsavelTelefone2Error(true);
      isValid = false;
      if (focus === '') focus = 'responsavelTelefone2';
    }

    let error_email = false;
    if (!formData.responsavelEmail && !formData.responsavelEmail.length) {
      setResponsavelEmailError(true);
      isValid = false;
      error_email = true;
      if (focus === '') focus = 'responsavelEmail';
    }

    if (!error_email) {
      if (!validateEmail(formData.responsavelEmail)) {
        setResponsavelEmailError(true);
        setEmailErrorMessage('Digite um email válido');
        isValid = false;
        if (focus === '') focus = 'responsavelEmail';
      }
    }

    if (!formData.observacao || !formData.observacao.trim().length) {
      setObservacaoErrorMessage(true);
      isValid = false;
      if (focus === '') focus = 'observacao';
    } else {
      setObservacaoErrorMessage(false);
    }

    if (focus.length > 0) handleToFocus();

    if (isValid) {
      success();
    }
  }

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const numeroCaixas = formData.numeroCaixas
        ? Number(formData.numeroCaixas) : null;

      const numeroUsuarios = formData.numeroUsuarios
        ? Number(formData.numeroUsuarios) : null;

      const motCancel = motivosCancelamento
        .find(_ => _.id === Number(formData.motivoCancelamentoId));

      const subMotCancel = subMotivosCancelamento
        .find(_ => _.id === Number(formData.submotivoCancelamentoId));

      const { data } = await api.post(
        endpoints.license.fullLicenseCancellation,
        {
          ...formData,
          motivoCancelamentoId: Number(formData.motivoCancelamentoId),
          motivoCancelamentoDescricao: (motCancel?.descricao || '---'),
          submotivoCancelamentoId: formData.submotivoCancelamentoId,
          subMotivoCancelamentoDescricao: (subMotCancel?.descricao || '---'),
          numeroCaixas,
          numeroUsuarios,
          data: new Date().toISOString(),
        }
      );

      toast(data.message, { type: toast.TYPE.SUCCESS });
      if (data.data?.messages?.error) {
        const { error } = data.data?.messages;

        error.forEach((msg, index) => {
          if (typeof msg === 'string') {
            setTimeout(() => {
              toast(msg, { type: toast.TYPE.ERROR });
            }, 500 * (index + 1)); // 0.5s, 1s e 1.5s
          }
        });
      }

      setLoading(false);
      setTimeout(() => {
        history.push(licensePaths.activeLicenses);
      }, 100); // 0.10 s
    } catch (error) {
      setLoading(false);
      showMessageError(error);
    }
  };

  function handleToFocus() {
    const element = document.getElementById('focusContato');

    element.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className="defaultFormContainer">
      <Modal show={loading} loading />
      <SidebarPageHeader
        mainMenu="Licença"
        pageName="Cancelamento Total"
        breadcrumbs={[{
          label: 'Ativas',
          link: licensePaths.activeLicenses,
        }]}
        button1={{
          label: 'Salvar',
          onClick: () => (() => validateForm(handleSubmit)),
          main: true,
        }}
        openButtonSelect
        buttonCancelHandler={history.goBack}
      />
      <form className="defaultForm" onSubmit={handleSubmit}>
        <div className="infoCard">
          <SearchComponentClient
            cpfCnpjValue={formatCpfCnpj(formData.cpfCnpjCliente)}
            nomeFantasiaValue={formData.nomeFantasiaCliente}
            razaoSocialValue={formData.razaoSocialCliente}
            telefoneCliente={formData.telefoneCliente}
          />
        </div>
        <div className="infoCard">
          <SearchComponentLicense
            serie={formData.serie}
            modalidade={formData.modalidade}
            software={formData.software}
            motivoError={motivoError}
            subMotivoError={subMotivoError}
            motivosCancelamento={motivosCancelamento}
            handleSelectMotivoCancelamento={handleSelectMotivoCancelamento}
            subMotivosCancelamento={subMotivosCancelamento}
            handleSelectSubMotivoCancelamento={handleSelectSubMotivoCancelamento}
            observacao={formData.observacao}
            handleObservacaoChange={handleObservacaoChange}
            numeroCaixas={formData.numeroCaixas}
            handleNumeroCaixasChange={handleNumeroCaixassChange}
            numeroUsuarios={formData.numeroUsuarios}
            handleNumeroUsuariosChange={handleNumeroUsuariosChange}
          />
        </div>
        <div className="infoCard">
          <SearchComponentObservation
            responsavelNome={formData.responsavelNome}
            responsavelTelefone1={formData.responsavelTelefone1}
            responsavelTelefone2={formData.responsavelTelefone2}
            responsavelEmail={formData.responsavelEmail}
            observacao={formData.observacao}
            responsavelNomeError={responsavelNomeError}
            responsavelTelefone1Error={responsavelTelefone1Error}
            responsavelTelefone2Error={responsavelTelefone2Error}
            responsavelEmailError={responsavelEmailError}
            telefone1ErrorMessage={telefone1ErrorMessage}
            emailErrorMessage={emailErrorMessage}
            observacaoErrorMessage={observacaoErrorMessage}
            handleInputResponsavelNomeChange={handleInputResponsavelNomeChange}
            handleInputResponsavelTelefone1Change={handleInputResponsavelTelefone1Change}
            handleInputResponsavelTelefone2Change={handleInputResponsavelTelefone2Change}
            handleInputResponsavelEmailChange={handleInputResponsavelEmailChange}
            handleInputObservacaoChange={handleObservacaoChange}
          />
          <div id='focusContato' />
        </div>
      </form>
    </div>
  );
}

TotalCancellation.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
    goBack: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.shape({}),
    }),
  }),
};

export default TotalCancellation;
