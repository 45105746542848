import styled from 'styled-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const Label = styled.label`
  color: var(--cm-primary);
  font-size: 12pt;
  padding-right: 10px;
`;

export const ContainerInput = styled.div`
  display: flex;
  position: relative;
  margin-bottom: ${({ addMarginBottom }) => addMarginBottom ? '-20px' : '0'};
`;

export const Input = styled.input`
  height: 30px;
  margin-bottom: 10px;
  margin-top: 5px;
  padding: 0 40px 0 20px;
  color: #777;
  font-size: 15px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;

  &::placeholder {
    color: #999;
  }
`;

export const IconEye = styled(FaEye)`
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 12px;
  color: #999;
`;

export const IconEyeSlash = styled(FaEyeSlash)`
  position: absolute;
  cursor: pointer;
  right: 10px;
  top: 12px;
  color: #999;
`;

export const CapsLock = styled.small`
  display: none;
  color: red;
  margin-top: ${({ addMarginTop }) => addMarginTop ? '-10px' : '0'};
`;

export const Error = styled.small`
  color: red;
`;
