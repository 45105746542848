import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { ChannelRatingService } from '~/services/person/channelrating';
import { person } from '~/routes/paths';
import Modal from '~/components/Modal';
import { accessDenied } from '~/util/accessDenied';
import TableCell from '@material-ui/core/TableCell';
import { SidebarPageHeader } from '~/components/SidebarPageHeader';
import SimpleTextSearch from '~/components/SimpleTextSearch';
import TableComponent from '~/components/TableComponent';
import { showMessageError } from '~/util/errorutils';
import permissions from '~/util/permissions';
import PrintableComponent from '~/components/PrintableComponent';
import { useFitler } from '~/hook/useFilter';
import './styles.scss';

export default function AccessGroup({ history }) {
  const { filters, setFilters } = useFitler();
  const [inputValue, setInputValue] = useState(filters?.inputValue ? filters?.inputValue : '');
  const [searchResponse, setSearchResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [searchResponseReport, setSearchResponseReport] = useState([]);
  const [totalItems, setTotalItems] = useState(null);
  const [countFilter, setCountFilter] = useState(0);
  const [pageParameters, setPageParams] = useState({
    page: 0, rowsPerPage: 50,
  });

  let PrintableElement = React.useRef();

  const { permissions: userPermissions } = useSelector(
    state => state.user.data
  );

  const userCanCreate = useMemo(
    () => userPermissions.includes(permissions['create_classificacao-canal']),
    [userPermissions]
  );

  const userCanEdit = useMemo(
    () => userPermissions.includes(permissions['edit_classificacao-canal']),
    [userPermissions]
  );

  const userCanDelete = useMemo(
    () => userPermissions.includes(permissions['delete_classificacao-canal']),
    [userPermissions]
  );

  useEffect(() => {
    findAllByFilter(pageParameters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChange(event) {
    const {
      target: { value },
    } = event;

    setInputValue(value);
  }

  async function findAllByFilterReport() {
    setLoading(true);
    try {
      if (inputValue.length) { setCountFilter(1); } else { setCountFilter(0); }
      const data = await ChannelRatingService
        .getChannelRating(inputValue.trim());

      if (data.resultado.length) {
        setSearchResponseReport(data.resultado);
      } else {
        setSearchResponseReport([]);
        toast('Não encontramos nenhum Canal!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function findAllByFilter(pageParams) {
    setPageParams(pageParams);
    setLoading(true);
    try {
      if (inputValue.length) { setCountFilter(1); } else { setCountFilter(0); }
      const { page, rowsPerPage } = pageParams;
      const data = await ChannelRatingService
        .getChannelRating(inputValue.trim(), {
          limit: page * rowsPerPage + rowsPerPage,
          offset: page * rowsPerPage,
        });

      if (data.resultado.length) {
        setTotalItems(data.total);
        setSearchResponse(data.resultado);
      } else {
        setSearchResponse([]);
        setTotalItems(0);
        toast('Não encontramos nenhum Canal!', {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (error) {
      showMessageError(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    setFilters({ inputValue });

    await findAllByFilter(pageParameters);
  }

  async function handleDelete() {
    try {
      setLoading(true);
      const result = await ChannelRatingService
        .deleteChannelRating(deleteItem.id);

      if (!result.data.ok) {
        toast('Erro ao deletar o Canal!', { type: toast.TYPE.ERROR });
      } else {
        const newSearchResponse = searchResponse.filter(
          resp => resp.id !== deleteItem.id
        );

        setSearchResponse(newSearchResponse);
        toast(result.message, { type: toast.TYPE.SUCCESS });
      }
    } catch (error) {
      showMessageError(error, 'Erro ao deletar o Canal!');
    } finally {
      setLoading(false);
      setDeleteItem(null);
    }
  }

  const renderRowComponent = row => {
    return [
      <TableCell key="row_channel-rating_0">{row.nome}</TableCell>,
      <TableCell key="row_channel-rating_1">
        <div id="discountHolder">
          {row.descontos.map((discount, index) => (
            <ul
              key={String(index)}
            >{`${discount.nome}: ${discount.valor}%`}</ul>
          ))}
        </div>
      </TableCell>,
    ];
  };

  return (
    <>
      <PrintableComponent
        ref={ref => (PrintableElement = ref)}
        title="Classificação do Canal"
        handlePageUpdate={findAllByFilterReport}
        fieldsArray={['nome', 'descricao']}
        filters={[{ name: 'Classificação', value: inputValue }]}
        headerLabels={[
          { text: 'Nome do Canal', width: '30%' },
          { text: 'Tipos de Descontos', width: 'auto' },
        ]}
        dataObjects={searchResponseReport}
        renderRowComponent={renderRowComponent}
      />
      <div className="channelRatingContainer">
        <Modal loading={loading} show={loading || !!deleteItem}>
          <div className="modalContainer">
            <h2>Confirmar exclusão</h2>
            <span>
              Tem certeza que deseja deletar o item
              <span id="elementName">
                {` ${!!deleteItem && deleteItem.nome}`}
              </span>?
            </span>
            <div>
              <button
                className="cancelButton buttonBase"
                onClick={() => setDeleteItem(null)}
              >
                Cancelar
              </button>
              <button
                className="buttonBase baseMain"
                onClick={handleDelete}
              >
                Deletar
              </button>
            </div>
          </div>
        </Modal>
        <SidebarPageHeader
          mainMenu="Pessoa"
          pageName="Classificação do Canal"
          button1={{
            label: 'Nova Classificação',
            link: person.addChannelRating,
            permission: userCanCreate,
            main: true,
          }}
          showReport
          printableRef={() => PrintableElement}
          onBeforePrintCallback={async () => {
            await findAllByFilterReport(pageParameters);
          }}
          openButtonSelect
        />
        <SimpleTextSearch
          countFilter={countFilter}
          fieldLabel="Classificação"
          inputValue={inputValue}
          handleInputChange={handleChange}
          handleSubmit={handleSubmit}
        />
        <TableComponent
          headerLabels={[
            { text: 'Nome do Canal', width: '30%' },
            { text: 'Tipos de Descontos', width: 'auto' },
          ]}
          dataObjects={searchResponse}
          editPermission={userCanEdit}
          handleEdit={row =>
            history.push(person.editChannelRating, {
              id: row.id,
              name: row.nome,
              value: row.valor,
              discounts: row.descontos,
            })
          }
          deletePermission={userCanDelete}
          handleDelete={userCanDelete
            ? (row) => setDeleteItem(row)
            : () => accessDenied(history)
          }
          totalItems={totalItems}
          handlePageUpdate={findAllByFilter}
          renderRowComponent={renderRowComponent}
        />
      </div>
    </>
  );
}

AccessGroup.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};
