import { person } from '~/routes/paths';
import permissions from '~/util/permissions';

import PersonClient from '~/pages/Person/Client';
import AddPersonClient from '~/pages/Person/Client/AddClient';
import EditPersonClient from '~/pages/Person/Client/EditClient';

import PersonEmployee from '~/pages/Person/Employee';
import AddPersonEmployee from '~/pages/Person/Employee/AddEmployee';
import EditPersonEmployee from '~/pages/Person/Employee/EditEmployee';
import AddPersonInvitationEmployee from
  '~/pages/Person/Employee/AddInvitationEmployee';

import PersonResale from '~/pages/Person/Resale';
import AddPersonResale from '~/pages/Person/Resale/AddResale';
import EditPersonResale from '~/pages/Person/Resale/EditResale';
import AddPersonInvitationResale from
  '~/pages/Person/Resale/AddInvitationResale';

import PersonMassActions from '~/pages/Person/PersonMassActions';

import PersonLineBusiness from '~/pages/Person/LineBusiness';
import PersonAddLineBusiness from '~/pages/Person/LineBusiness/AddLineBusiness';
import PersonEditLineBusiness from '~/pages/Person/LineBusiness/EditLineBusiness';

import PersonChannelRating from '~/pages/Person/ChannelRating';
import PersonAddChannelRating from '~/pages/Person/ChannelRating/AddChannelRating';
import PersonEditChannelRating from
  '~/pages/Person/ChannelRating/EditChannelRating';

const personRoutes = [
  {
    key: 1,
    exact: true,
    hideSidebar: false,
    name: 'Cliente',
    path: person.personClient,
    permission: permissions.view_cliente,
    component: PersonClient,
  },
  {
    key: 2,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Cliente',
    path: person.addPersonClient,
    permission: permissions.create_cliente,
    component: AddPersonClient,
  },
  {
    key: 3,
    exact: false,
    hideSidebar: true,
    name: 'Editar Cliente',
    path: person.editPersonClient,
    permission: permissions.edit_cliente,
    component: EditPersonClient,
  },
  {
    key: 4,
    exact: true,
    hideSidebar: false,
    name: 'Revenda',
    path: person.personResale,
    permission: permissions.view_revenda,
    component: PersonResale,
  },
  {
    key: 5,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Revenda',
    path: person.addPersonResale,
    permission: permissions.create_revenda,
    component: AddPersonResale,
  },
  {
    key: 6,
    exact: false,
    hideSidebar: true,
    name: 'Editar Revenda',
    path: person.editPersonResale,
    permission: permissions.edit_revenda,
    component: EditPersonResale,
  },
  {
    key: 7,
    exact: true,
    hideSidebar: false,
    name: 'Colaborador',
    path: person.personEmployee,
    permission: permissions.view_colaborador,
    component: PersonEmployee,
  },
  {
    key: 8,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Colaborador',
    path: person.addPersonEmployee,
    permission: permissions.create_colaborador,
    component: AddPersonEmployee,
  },
  {
    key: 9,
    exact: false,
    hideSidebar: true,
    name: 'Editar Colaborador',
    path: person.editPersonEmployee,
    permission: permissions.edit_colaborador,
    component: EditPersonEmployee,
  },
  {
    key: 10,
    exact: false,
    hideSidebar: true,
    name: 'Ações em Massa',
    path: person.personMassActions,
    permission: permissions['edit_mass-actions'],
    component: PersonMassActions,
  },
  {
    key: 11,
    exact: true,
    hideSidebar: false,
    name: 'Ramo de Atividade',
    path: person.lineBusiness,
    component: PersonLineBusiness,
    permission: permissions['view_ramo-atividade'],
  },
  {
    key: 12,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Ramo de Atividade',
    path: person.addLineBusiness,
    component: PersonAddLineBusiness,
    permission: permissions['create_ramo-atividade'],
  },
  {
    key: 13,
    exact: false,
    hideSidebar: true,
    name: 'Editar Ramo de Atividade',
    path: person.editLineBusiness,
    component: PersonEditLineBusiness,
    permission: permissions['edit_ramo-atividade'],
  },
  {
    key: 14,
    exact: true,
    hideSidebar: false,
    name: 'Classificação Canal',
    path: person.channelRating,
    component: PersonChannelRating,
    permission: permissions['view_classificacao-canal'],
  },
  {
    key: 15,
    exact: false,
    hideSidebar: true,
    name: 'Adicionar Classificação Canal',
    path: person.addChannelRating,
    component: PersonAddChannelRating,
    permission: permissions['create_classificacao-canal'],
  },
  {
    key: 16,
    exact: false,
    hideSidebar: true,
    name: 'Editar Classificação Canal',
    path: person.editChannelRating,
    component: PersonEditChannelRating,
    permission: permissions['edit_classificacao-canal'],
  },
  {
    key: 17,
    exact: false,
    hideSidebar: true,
    name: 'Novo Convite Colaborador',
    path: person.addPersonInvitationEmployee,
    component: AddPersonInvitationEmployee,
    permission: permissions['create_convite-pessoa'],
  },
  {
    key: 18,
    exact: false,
    hideSidebar: true,
    name: 'Novo Convite Revenda',
    path: person.addPersonInvitationResale,
    component: AddPersonInvitationResale,
    permission: permissions['create_convite-pessoa'],
  },
];

export default personRoutes;
