import { getDatePerson, getSameDate } from '~/util/dateutils';

import Address from '~/model/address';
import Contact from '~/model/contact';

export default class PersonResaleEdit {
  constructor(props) {
    const {
      id,
      grupoId,
      tipoPessoa,
      cpfCnpj,
      nomeFantasia,
      razaoSocial,
      inscricaoEstadual,
      optanteSimples,
      ramoAtividadeId,
      endereco,
      contato,
      cnae,
      dataCadastro,
      createdAt,
      dataFundacao,
      microempresa,
      retemICMS,
      segmentos,
      classificacaoId,
      descontosRevenda,
      comissionada = false,
      status,
      prazoRenovacaoChave,
      statusChave,
      responsavelExecutivoId,
    } = props;

    this.id = id;
    this.grupoId = grupoId;
    this.tipoPessoa = tipoPessoa ? tipoPessoa : 'JURÍDICA';
    this.cpfCnpj = cpfCnpj || '';
    this.nomeFantasia = nomeFantasia || '';
    this.razaoSocial = razaoSocial || '';
    this.inscricaoEstadual = inscricaoEstadual || '';
    this.optanteSimples = optanteSimples || false;
    this.ramoAtividadeId = ramoAtividadeId;

    if (endereco) {
      const address = new Address(endereco);
      this.estado = address.estado || '';
      this.cidade = address.cidade || '';
      this.cep = address.cep || '';
      this.bairro = address.bairro || '';
      this.endereco = address.endereco || '';
      this.complemento = address.complemento || '';
      this.pontoReferencia = address.pontoReferencia || '';
    }

    if (contato) {
      const contact = new Contact(contato);
      this.telefone1 = contact.telefone1 || '';
      this.telefone2 = contact.telefone2 || '';
      this.email = contact.email || '';
    }

    this.cnae = cnae || '';

    if (createdAt) {
      this.dataCadastro = getSameDate(createdAt, dataCadastro);
    }

    this.dataFundacao = dataFundacao ? getDatePerson(dataFundacao) : '';
    this.microempresa = microempresa || false;
    this.retemICMS = retemICMS || false;
    this.segmentos = segmentos || false;
    this.classificacaoId = classificacaoId;
    this.descontosRevenda = descontosRevenda || [];
    this.comissionada = comissionada || false;

    this.status = status;
    this.prazoRenovacaoChave = prazoRenovacaoChave || '30';
    this.statusChave = statusChave;
    this.responsavelExecutivoId = responsavelExecutivoId || null;
  }
}

/*
  {
    POST {
      "grupoId": 61,
      "tipoPessoa": "JURIDICA",
      "cpfCnpj": "1531646156614",
      "razaoSocial": "Teste R",
      "nomeFantasia": "Teste F",
      "inscricaoEstadual": "213130045160",
      "optanteSimples": true,
      "estado": "CEARÁ",
      "cidade": "Fortaleza",
      "cep": "60000000",
      "bairro": "Aldeota",
      "endereco": "Avenida X",
      "complemento": "BL 84 apto 754",
      "pontoReferencia": "Aqui",
      "telefone1": "9999999999",
      "telefone2": "8888888888",
      "email": "teste@email.com",
      "cnae": "CNAE",
      "dataCadastro": "moment().format('YYYY-MM-DD')",
      "dataFundacao": "moment().format('YYYY-MM-DD')",
      "imagem": "base64 image",
      "microempresa": true,
      "retemICMS": false,
      "segmentos": true,
      "classificacaoId": 123,
      "descontosRevenda": [
        3,
        5,
        4
      ],
      "status": "Não Bloqueado",
      "prazoRenovacaoChave": "30",
      "statusChave": "Não Bloqueado",
      "responsavelExecutivo": 34,
    }
  }
*/
