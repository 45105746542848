import { toast } from 'react-toastify';
import { initialPaths } from '~/routes/paths';

/**
 * If the user doesn't have permission, show a toast message and redirect the user to
 * the home page.
 * @param history - The history object is used to programmatically navigate between
 * routes.
 */
export function accessDenied(history) {
  toast.error('Acesso Negado!');
  setTimeout(() => {
    return history.push(initialPaths.pageInitial);
  }, 100); // 0.10 s
}
