import React from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { InputPerson } from '~/components/InputPerson';
import { STATUS_SOLICITATION_ENUM } from '~/util/domainutils';
import { CollapsibleFilter } from '~/components/CollapsibleFilter';
import './styles.scss';

function SearchComponent({
  countFilter,
  userCanViewColumnRevenda,
  handleSubmit,
  inputClienteValue,
  handleClienteChange,
  inputRevendaValue,
  handleRevendaChange,
  selectedTipoSolicitacao,
  handleSelectTipoSolicitacaoChange,
  dataInicio,
  handleDataInicioChange,
  dataFim,
  handleDataFimChange,
  radioStatus,
  handleRadioStatusChange,
  allReasonSolicitations,
}) {
  return (
    <CollapsibleFilter countFilters={countFilter}>
      <form className="formContainer" onSubmit={handleSubmit}>
        <label id="searchText">Pesquisar</label>
        <div className="rowContainer">
          <InputPerson
            title1='Cliente'
            inputPerson1={inputClienteValue}
            handlePerson1Change={handleClienteChange}
            title2='Revenda'
            inputPerson2={inputRevendaValue}
            handlePerson2Change={handleRevendaChange}
            userCanViewPerson2={userCanViewColumnRevenda}
          />
          <div className="inputHolder defaultFlex">
            <span>Tipo da Solicitação</span>
            <select
              name="solicitationType"
              value={selectedTipoSolicitacao || ''}
              onChange={handleSelectTipoSolicitacaoChange}
            >
              <option value={''}>Selecione</option>
              {allReasonSolicitations.map(reason => (
                <option key={reason.id} value={reason.id}>
                  {reason.descricao}
                </option>
              ))}
            </select>
          </div>
          <div className="inputHolder noMarginRight">
            <span>Período</span>
            <div id="dates">
              <InputMask
                mask="99/99/9999"
                name="initialDate"
                value={dataInicio}
                onChange={handleDataInicioChange}
              />
              <span id="dateSeparator">à</span>
              <InputMask
                mask="99/99/9999"
                style={{ paddingLeft: '10px' }}
                name="finalDate"
                value={dataFim}
                onChange={handleDataFimChange}
              />
            </div>
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Status</span>
            <div id="statusHolder">
              {Object.keys(STATUS_SOLICITATION_ENUM).map((key) => (
                <div key={key} className="radioHolder">
                  <div className="inputBoxHolder">
                    <input
                      type="radio"
                      name="status"
                      value={STATUS_SOLICITATION_ENUM[key].value}
                      checked={radioStatus.some(
                        elem => elem === STATUS_SOLICITATION_ENUM[key].value
                      )}
                      onClick={handleRadioStatusChange}
                      readOnly
                    />
                    <span className="checkmark">
                      {radioStatus.some(elem =>
                        elem === STATUS_SOLICITATION_ENUM[key].value
                      )
                        && (
                          <div className="markHolder">
                            <div className="checkmark_stem" />
                            <div className="checkmark_kick" />
                          </div>
                        )}
                    </span>
                  </div>
                  <span id="radioLabel">
                    {STATUS_SOLICITATION_ENUM[key].label_small}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <button className="buttonBaseOnly" id="baseButtonIcon" onClick={handleSubmit}>
            <FaSearch id="baseNameIcon" />
            <span>BUSCAR</span>
          </button>
        </div>
      </form>
    </CollapsibleFilter>
  );
}

SearchComponent.propTypes = {
  countFilter: PropTypes.number,
  userCanViewColumnRevenda: PropTypes.bool,
  handleSubmit: PropTypes.func,
  selectedTipoSolicitacao: PropTypes.string,
  handleSelectTipoSolicitacaoChange: PropTypes.func,
  dataInicio: PropTypes.string,
  handleDataInicioChange: PropTypes.func,
  dataFim: PropTypes.string,
  handleDataFimChange: PropTypes.func,
  radioStatus: PropTypes.array,
  handleRadioStatusChange: PropTypes.func,
  allReasonSolicitations: PropTypes.array,
  inputClienteValue: PropTypes.string,
  handleClienteChange: PropTypes.func,
  inputRevendaValue: PropTypes.string,
  handleRevendaChange: PropTypes.func,
};

export default SearchComponent;
