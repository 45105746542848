import moment from 'moment';

export default class PersonEmployeeNew {
  constructor(props) {
    const {
      grupoId,
      status,
      cpfCnpj,
      nomeSocial,
      estado,
      cidade,
      cep,
      bairro,
      endereco,
      complemento,
      pontoReferencia,
      telefone1,
      telefone2,
      email,
      imagem,
      revendaId,
    } = props;

    this.grupoId = grupoId;
    this.status = status || 'Não Bloqueado';
    this.cpfCnpj = cpfCnpj;
    this.nomeSocial = nomeSocial;

    this.estado = estado;
    this.cidade = cidade;
    this.cep = cep;
    this.bairro = bairro;
    this.endereco = endereco || '';
    this.complemento = complemento;
    this.pontoReferencia = pontoReferencia;

    this.telefone1 = telefone1;
    this.telefone2 = telefone2;
    this.email = email;

    this.dataCadastro = moment().format('YYYY-MM-DD');
    this.imagem = imagem;

    this.revendaId = revendaId;
  }
}

/*
POST
  {
    "grupoId": 123,
    "cpfCnpj": "1531646156614",
    "nomeSocial": "Teste S",
    "estado": "CEARÁ",
    "cidade": "Fortaleza",
    "cep": "60000000",
    "bairro": "Aldeota",
    "endereco": "Avenida X",
    "complemento": "BL 84 apto 754",
    "pontoReferencia": "Aqui",
    "telefone1": "9999999999",
    "telefone2": "8888888888",
    "email": "teste@email.com",
    "status": "Não Bloqueado",
    "dataCadastro": "JS Date",
    "imagem": "Base64 image",
    "revendaId": "1",
    "responsavelExecutivo": "Teste V",
  }
*/
