import React from 'react';
import InputMask from 'react-input-mask';
import FileBase64 from 'react-file-base64';
import { InputPassword } from '~/components/FieldPassword';
import { InputPhoneMask } from '~/components/FieldPhone';
import { PasswordInformation } from '~/components/PasswordInformation';
import { UNIDADES_FEDERATIVAS } from '~/util/domainutils';
import { formatCpfCnpj } from '~/util/stringutils';
import * as S from './styles';

function FormResale(params) {
  const {
    handleSubmit,
    person,
    errors,
    imageName,
    password,
    confirmPassword,
    handleInputTelefone1Change,
    handleInputTelefone2Change,
    handleSelectEstadoChange,
    handleInputCidadeChange,
    handleInputCepChange,
    handleInputEnderecoChange,
    handleInputBairroChange,
    handleInputPontoReferenciaChange,
    handleInputComplementoChange,
    handleInputNomeFantasiaChange,
    handleInputRazaoSocialChange,
    handleInputInscricaoEstadualChange,
    handleInputOptanteSimplesChange,
    handleInputDataFundacaoChange,
    handleInputMicroempresaChange,
    handleInputRetemICMSChange,
    handleInputSegmentosChange,
    setInputImagem,
    handleInputPasswordChange,
    handleInputConfirmPasswordChange,
  } = params;

  return (
    <form onSubmit={handleSubmit}>
      <S.ContainerContent>
        <label id='title'>Dados Gerais</label>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>CNPJ</span>
            <input
              type="text"
              name="cpfCnpj"
              disabled
              defaultValue={formatCpfCnpj(person.cpfCnpj)}
            />
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Email</span>
            <input
              type="text"
              name="email"
              disabled
              defaultValue={person.email}
            />
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Razão Social *</span>
            <input
              type="text"
              name="nomeCompleto"
              value={person.razaoSocial}
              onChange={handleInputRazaoSocialChange}
            />
            {errors.errorRazaoSocial && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Nome Fantasia *</span>
            <input
              type="text"
              name="nomeCompleto"
              value={person.nomeFantasia}
              onChange={handleInputNomeFantasiaChange}
            />
            {errors.errorNomeFantasia && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Inscrição Estadual</span>
            <input
              type="text"
              name="nomeCompleto"
              value={person.inscricaoEstadual}
              onChange={handleInputInscricaoEstadualChange}
            />
          </div>
          <div className="inputHolder defaultFlex">
            <span>Optante do SIMPLES *</span>
            <select
              name="optanteSimples"
              value={person.optanteSimples}
              onChange={handleInputOptanteSimplesChange}
            >
              <option value={false}>Não</option>
              <option value>Sim</option>
            </select>
            {errors.errorOptanteSimples && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
          <div className="inputHolder defaultFlex">
            <span>Data de Fundação</span>
            <input
              type="date"
              name="dataFundacao"
              value={person.dataFundacao}
              onChange={handleInputDataFundacaoChange}
            />
          </div>
          <div className="inputHolder defaultFlex">
            <span>Telefone (1) *</span>
            <InputPhoneMask
              type="text"
              name="telefone1"
              value={person.telefone1}
              onChange={handleInputTelefone1Change}
            />
            {errors.errorTelefone1.state && (
              <small style={{ color: 'red' }}>
                {errors.errorTelefone1.message}
              </small>
            )}
          </div>
          <div className="inputHolder noMarginRight">
            <span>Telefone (2)</span>
            <InputPhoneMask
              type="text"
              name="telefone2"
              value={person.telefone2}
              onChange={handleInputTelefone2Change}
            />
            {errors.errorTelefone2 && (
              <small style={{ color: 'red' }}>
                Digite um número válido
              </small>
            )}
          </div>
        </div>
      </S.ContainerContent>
      <S.ContainerContent>
        <label id='title'>Endereço</label>
        <div className="rowContainer">
          <div className="inputHolder">
            <span>Estado *</span>
            <select
              name="estado"
              value={person.estado}
              onChange={handleSelectEstadoChange}
            >
              <option value={''}>Selecione</option>
              {UNIDADES_FEDERATIVAS.map(uf => (
                <option key={uf.sigla} value={uf.nome}>
                  {uf.nome}
                </option>
              ))}
            </select>
            {errors.errorEstado && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
          <div className="inputHolder defaultFlex">
            <span>Cidade *</span>
            <input
              type="text"
              name="cidade"
              value={person.cidade}
              onChange={handleInputCidadeChange}
            />
            {errors.errorCidade && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
          <div className="inputHolder noMarginRight">
            <span>CEP *</span>
            <InputMask
              mask="99.999-999"
              name="cep"
              value={person.cep}
              onChange={handleInputCepChange}
            />
            {errors.errorCEP && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Endereço Completo *</span>
            <input
              type="text"
              name="endereco"
              value={person.endereco}
              onChange={handleInputEnderecoChange}
            />
            {errors.errorEndereco && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Bairro *</span>
            <input
              type="text"
              name="bairro"
              value={person.bairro}
              onChange={handleInputBairroChange}
            />
            {errors.errorBairro && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
        </div>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Ponto de Referência</span>
            <input
              type="text"
              name="pontoReferencia"
              value={person.pontoReferencia}
              onChange={handleInputPontoReferenciaChange}
            />
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Complemento</span>
            <input
              type="text"
              name="complemento"
              value={person.complemento}
              onChange={handleInputComplementoChange}
            />
          </div>
        </div>
      </S.ContainerContent>
      <S.ContainerContent>
        <label id='title'>Outros</label>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Microempresa *</span>
            <select
              name="optanteSimples"
              value={person.microempresa}
              onChange={handleInputMicroempresaChange}
            >
              <option value={false}>Não</option>
              <option value={true}>Sim</option>
            </select>
            {errors.errorMicroempresa && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
          <div className="inputHolder defaultFlex">
            <span>Retém ICMS *</span>
            <select
              name="optanteSimples"
              value={person.retemICMS}
              onChange={handleInputRetemICMSChange}
            >
              <option value={false}>Não</option>
              <option value={true}>Sim</option>
            </select>
            {errors.errorRetemICMS && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Segmentos *</span>
            <select
              name="optanteSimples"
              value={person.segmentos}
              onChange={handleInputSegmentosChange}
            >
              <option value={false}>Não</option>
              <option value={true}>Sim</option>
            </select>
            {errors.errorSegmentos && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
        </div>
      </S.ContainerContent>
      <S.ContainerContent>
        <S.ImageContainer>
          <S.ImageContent>
            <label id='title'>Foto da Empresa</label>
            <div className="imagePicker">
              <div className="buttonHolder">
                <span>ESCOLHER IMAGEM</span>
                <FileBase64
                  multiple={false}
                  onDone={setInputImagem}
                />
              </div>
              <label>{imageName || 'Selecione um arquivo de imagem'}</label>
            </div>
          </S.ImageContent>
        </S.ImageContainer>
      </S.ContainerContent>
      <S.ContainerContent>
        <label id='title'>Segurança</label>
        <div className="rowContainer">
          <div className="inputHolder defaultFlex">
            <span>Senha *</span>
            <InputPassword
              autoComplete="new-password"
              type="password"
              name="password"
              value={password}
              onChange={handleInputPasswordChange}
            />
            {errors.errorSenha && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
          <div className="inputHolder defaultFlex noMarginRight">
            <span>Confirmar senha *</span>
            <InputPassword
              autoComplete="new-confirm-password"
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleInputConfirmPasswordChange}
            />
            {errors.errorConfirmarSenha && (
              <small style={{ color: 'red' }}>
                Campo obrigatório
              </small>
            )}
          </div>
        </div>

        <PasswordInformation password={password} />
      </S.ContainerContent>
    </form>
  );
}

export { FormResale };
