import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
`;

export const FilterContentButton = styled.div`
  margin-left: 20px;
  width: fit-content;
  padding: 5px 0;
  color: var(--cm-primary);
  background-color: transparent;
  font-weight: bold;

  display: flex;
  align-items: center;

  .title-filter {
    margin-left: 6px;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  cursor: pointer;
`;
