import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;

  div {
    display: flex;
    margin: 5px 10px;

    span {
      font-size: 17px;
      color: #707070;

      :first-child {
        margin-right: 10px;
      }

      @media (max-width: 700px) {
        font-size: 14px;

        :first-child {
          margin-right: 5px;
        }
      }
    }
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

export const ContainerFull = styled.div`
  display: flex;
  flex-direction: column;

  div.observation-default {
    grid-template-columns: 1fr;
  }
`;
