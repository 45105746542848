/* eslint-disable react/prop-types */
import React from 'react';
import moment from 'moment';
import './styles.scss';

export default function Occurrence({ statusPedidos, criadoPor }) {
  function getResponsavel(responsavel) {
    if (!responsavel) return 'Sistema';
    if (responsavel.nomeSocial) return responsavel.nomeSocial;
    if (responsavel.nomeFantasia) return responsavel.nomeFantasia;
    if (responsavel.razaoSocial) return responsavel.razaoSocial;
    return 'Sistema';
  }

  return (
    <div className="orderManagementOldStatusContainer">
      {statusPedidos
        .sort(
          (statusA, statusB) =>
            moment(statusA.data).unix() -
            moment(statusB.data).unix()
        )
        .map((status, index) => (
          <div key={index} className="orderManagementOldStatusSeparatorHelper">
            <div className={
              'orderManagementOldStatusHolder' +
              (index === statusPedidos.length - 1
                ? ' orderManagementCurrentStatus'
                : '')
            }>
              <div className="orderManagementOldStatusHeader">
                <span className="orderManagementStatusDate">
                  {moment(status.data).format('DD/MM/YYYY, HH:mm')}
                </span>
                <span> - </span>
                <span className="orderManagementStatus">
                  {status.status}
                </span>
                <span> - </span>
                <span className="orderManagementStatusOwner">
                  {status.responsavel && index !== 0
                    ? getResponsavel(status.responsavel)
                    : getResponsavel(criadoPor) === 'Sistema'
                      ? getResponsavel(status.responsavel)
                      : 'Sistema'}
                </span>
              </div>
              <span className="orderManagementOldStatusObs">
                {status.observacao && status.observacao
                  .split('\n')
                  .map((elem, index) => (
                    <section className='containerObs' key={index}>
                      {elem}
                    </section>
                  ))}
              </span>
            </div>
            {index !== statusPedidos.length - 1 && (
              <div className="orderManagementOldStatusSeparator" />
            )}
          </div>
        ))}
    </div>
  );
}
